<div bsModal #getStartedModal="bs-modal" class="border-0">
	<div class="">
		<div class="modal-content">
			<div class="modal-header bg-info border-0" style="">
				<h4 class="modal-title">{{CB.title | localize | async}}</h4>
			</div>
			<div class="modal-body">

				<h4 class="text-primary font-weight-bold">{{CB.first | localize | async}}</h4>
				<ol>
					<li>{{CB.listZero | localize | async}}</li>
					<li>{{CB.listOne | localize | async}}</li>
					<li>{{CB.listTwo | localize | async}}</li>
					<li>{{CB.listThree | localize | async}}</li>
				</ol>
				<h6 class="font-weight-bold">{{CB.footer | localize | async}}</h6>
			</div>
			<button style="margin:auto;" type="button" class="btn btn-primary mb-4" (click)="onClick()">{{CB.continue | localize | async}}</button>
		</div>
	</div>

</div>

