import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {MakeProvider} from "../../../base/AbstractValueAccessor";
import {Localization} from "../../../data/Localization";
import {PublishedResourceRef} from "../../../domain/resources/PublishedResourceRef";
import {BaseComponent} from "../../BaseComponent";
import {PublishedResourceRepository} from "../../../services/repository/PublishedResourceRepository";

@Component({
	selector:'select-resource-refs',
	templateUrl:'./select-resource-refs.component.html',
	styleUrls:['./select-resource-refs.component.scss'],
	providers:[MakeProvider(SelectResourceRefsComponent)] //Allows ngModel
})
export class SelectResourceRefsComponent extends BaseComponent implements OnInit {

	@Input()
	public selectedResourceRefs:Array<PublishedResourceRef> = []; //array of refs that are already selected

	@Input()
	public selectableResourceRefs:Array<PublishedResourceRef> = []; //array of all possible refs that can be selected

	@Input()
	public cbTooltip; //tooltip that will be displayed on the label as cbContext

	@Output()
	public onResourceRefsChange:EventEmitter<PublishedResourceRef[]> = new EventEmitter<PublishedResourceRef[]>();

	public tempArray:Array<PublishedResourceRef> = []; //temporary array for UI purposes in this subcomponent only
	public resourcesSearchFn:(term:string, publishedResourceRef:PublishedResourceRef) => boolean = null; //allows searching

	public localize = {
		resourceRef: (ref:PublishedResourceRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "title")
	};
	private cbContext = Localization.template.common.select_resource_refs;
	public CB = {
		label: this.cbContext.label,
		placeholder: this.cbContext.placeholder,
	}

	public constructor(protected injector:Injector,
					   private repo:PublishedResourceRepository) {
		super(injector);
		this.localizationService.registerAndLocalize("SelectResourceRefsComponent", this.CB);

		// re-build the search function whenever the site language is switched
		this.trackSubscription(this.localizationService.language$.subscribe(() => this.resourcesSearchFn = this.localizationService.LocalizeTools.buildRefSearchFn("title")));
	}

	public ngOnInit():void {
	}

	public emitSelectedResourceRefs():void {
		this.selectedResourceRefs.push(...this.tempArray);
		this.tempArray = []; //empty temp array for UI
		// this.selectableResourceRefs = this.selectableResourceRefs.filter(ref => (this.selectedResourceRefs.find(ref2 => ref2.guid == ref.guid) == null)); //remove newly-selected refs from selectable refs
		console.log(`emitting ${this.selectedResourceRefs.length} resources to parent component!`);
		this.onResourceRefsChange.emit(this.selectedResourceRefs); //emit new array of selected resource refs to parent component
	}

}
