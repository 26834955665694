import {Component, Injector, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {Collection} from "../../../../domain/collections/Collection";
import {
	CollectionOrderType,
	CollectionOrderTypePretty,
	CollectionOwnerType,
	CollectionOwnerTypePretty,
	CollectionType,
	CollectionTypePretty,
	CollectionVisibilityType,
	CollectionVisibilityTypeIcon,
	CollectionVisibilityTypePretty
} from "../../../../domain/collections/CollectionConfigurationEnums";
import {CollectionConfiguration} from "../../../../domain/collections/CollectionConfiguration";
import {Localization} from "../../../../data/Localization";
import {BaseComponent} from "../../../BaseComponent";
import {CollectionService} from "../../../../services/CollectionService";
import {DocumentTextContentService} from "../../../../services/common/DocumentTextContentService";
import {CollectionOwner} from "../../../../domain/collections/CollectionOwner";
import {CollectionTextContent} from "../../../../domain/collections/CollectionTextContent";
import {SupportedLanguage, SupportedLanguagePretty, SupportedLanguages} from "../../../../domain/SupportedLanguages";
import {PermissionType} from "../../../../domain/user/Permission";
import {CropperComponent, ImageType} from "../../../common/cropper/cropper.component";
import {Observable, Subject, Subscription} from "rxjs";
import {UploadService} from "../../../../services/UploadService";
import {ToastType} from "../../../../services/common/NotificationService";
import {ValidationException} from "../../../../services/exceptions/ValidationException";
import {WaihonaUserRef} from "../../../../domain/user/WaihonaUserRef";
import {WaihonaUserService} from "../../../../services/WaihonaUserService";
import {isEqual} from "lodash";
import {PopoverDirective} from "ngx-bootstrap/popover";
import {OrganizationRef} from "../../../../domain/organization/OrganizationRef";
import {OrganizationService} from "../../../../services/OrganizationService";
import {RoleType} from "../../../../domain/user/Role";
import {WaihonaUserOrganization} from "../../../../domain/user/WaihonaUserOrganization";
import {WaihonaUser} from "../../../../domain/user/WaihonaUser";
import {PublishedResourceService} from "../../../../services/PublishedResourceService";
import {Options} from "sortablejs";
import {FormArray, FormControl} from "@angular/forms";
import {filter} from 'rxjs/operators';
import {NupepafyStringUtils} from "../../../../util/NupepafyStringUtils";
import {PublishedResourceRef} from "../../../../domain/resources/PublishedResourceRef";
import {ValidationError} from "class-validator";
import {ViewType} from "../../../common/view-switcher/view-switcher.component";
import {IFieldMaxLengthMap, ValidationMetadataService} from "../../../../services/common/ValidationMetadataService";
import {Organization} from "../../../../domain/organization/Organization";
import {sort} from "../../../../helper/SortTools";

@Component({
	selector: 'app-collection-edit',
	templateUrl: './collection-edit.component.html',
	styleUrls: ['./collection-edit.component.scss']
})
export class CollectionEditComponent extends BaseComponent implements OnInit {

	private commonContext = Localization.template.common;
	private cbContext = Localization.template.collections.edit;
	public CB = {
		required: this.commonContext.required,
		loading: this.commonContext.card_list.loading,
		oho_field_tooltip: this.commonContext.oho.field.tooltip,

		button_save: this.cbContext.buttons.save,
		button_viewCollection: this.cbContext.buttons.view_collection,
		button_viewSeries: this.cbContext.buttons.view_series,
		button_cancel: Localization.template.buttons.cancel,
		button_continue: Localization.template.buttons.continue,
		button_language: this.cbContext.buttons.language_switcher.header,

		header_title: this.cbContext.headers.title,
		header_title_block: this.cbContext.headers.title_block,
		header_item_block: this.cbContext.headers.item_block,
		header_cover_image: this.cbContext.headers.cover_image,
		header_settings_block: this.cbContext.headers.settings_block,
		header_manage_block: this.cbContext.headers.manage_block,
		header_ownership: this.cbContext.headers.ownership,
		header_authorizedUsers: this.cbContext.headers.authorized_users,

		text_ownership: this.cbContext.text.ownership,

		formLabel_collectionName: this.cbContext.formContent.labels.collectionName,
		formLabel_summary: this.cbContext.formContent.labels.summary,
		formLabel_logo: this.cbContext.formContent.labels.logo,
		formLabel_visibilityType: this.cbContext.formContent.labels.visibilityType,
		formLabel_orderType: this.cbContext.formContent.labels.orderType,
		formLabel_collectionType: this.cbContext.formContent.labels.collectionType,
		formLabel_ownerType: this.cbContext.formContent.labels.ownerType,
		formLabel_selectNewOwnerOrg: this.cbContext.formContent.labels.selectNewOwnerOrg,
		formLabel_selectNewOwnerUser: this.cbContext.formContent.labels.selectNewOwnerUser,
		formLabel_collaborators: this.cbContext.formContent.labels.collaborators,
		formLabel_sharedTo: this.cbContext.formContent.labels.sharedTo,
		formLabel_addItems: this.cbContext.formContent.labels.addItems,
		formLabel_reorderItems: this.cbContext.formContent.labels.reorderItems,
		formLabel_listItems: this.cbContext.formContent.labels.listItems,
		// formLabel_sortItemsSection: this.cbContext.formContent.labels.removeItems,

		placeholder_collectionName: this.cbContext.formContent.placeholders.collectionName,
		placeholder_summary: this.cbContext.formContent.placeholders.summary,
		placeholder_selectNewOwnerOrg: this.cbContext.formContent.placeholders.selectNewOwnerOrg,
		placeholder_selectNewOwnerUser: this.cbContext.formContent.placeholders.selectNewOwnerUser,
		placeholder_collaborators: this.cbContext.formContent.placeholders.collaborators,
		placeholder_sharedTo: this.cbContext.formContent.placeholders.sharedTo,

		tooltip_language: this.cbContext.formContent.tooltips.language,
		tooltip_visibilityType: this.cbContext.formContent.tooltips.visibilityType,
		tooltip_orderType: this.cbContext.formContent.tooltips.orderType,
		tooltip_collectionType: this.cbContext.formContent.tooltips.collectionType,
		tooltip_ownerType: this.cbContext.formContent.tooltips.ownerType,
		tooltip_collaborators: this.cbContext.formContent.tooltips.collaborators,
		tooltip_sharedTo: this.cbContext.formContent.tooltips.sharedTo,
		tooltip_resource_select: this.cbContext.formContent.tooltips.resource_select,
		tooltip_addItems: this.cbContext.formContent.tooltips.add_items,
		tooltip_reorderItems: this.cbContext.formContent.tooltips.reorder_items,
		tooltip_removeItems: this.cbContext.formContent.tooltips.remove_items,
		tooltip_sortItemsSection: this.cbContext.formContent.tooltips.remove_items,
		tooltip_coverImage: this.cbContext.formContent.tooltips.cover_image,

		alert_language_mismatch_title: Localization.template.alert_headers_reusable.language_mismatch,
		coverImage_warning: this.cbContext.cover_image.warning,
		collectionItems_warning: this.cbContext.collection_items.warning,

		confirmDeletePopover_title: this.cbContext.popovers.delete.title,
		confirmDeletePopover_confirm: this.cbContext.popovers.delete.confirm,
		confirmDeletePopover_decline: this.cbContext.popovers.delete.decline,
	};

	public mode:CollectionEditMode = null;
	public collectionViewMode:ViewType = ViewType.list;
	private _collection:Collection = null;

	private allResourceRefs:Array<PublishedResourceRef> = []; //store all possible resource refs for re-filtering when deleting a selected ref
	public selectableResources:Array<PublishedResourceRef> = []; //array of resources that users can choose from when selecting resources to add to this collection
	public collectionItemsSnapshot:Array<PublishedResourceRef> = [];
	private collectionItemsSorted:Subject<Array<PublishedResourceRef>> = new Subject<Array<PublishedResourceRef>>();
	public sortableElements:FormArray = new FormArray([]);

	public urls = {
		resourceImage: (resourceRef:PublishedResourceRef):string => {
			return this.urlService.resourceImage.published(resourceRef.guid);
		}
		//add more urls here
	}
	public sortableJsOptions:Options = {
		swapThreshold: 1,
		animation: 280,
		direction: 'horizontal',
		ghostClass: "sortable-ghost", // Class name for the drop placeholder
		chosenClass: "sortable-chosen", // Class name for the chosen item
		dragClass: "sortable-drag", // Class name for the dragging item
		disabled: false
	};

	public isSaving:boolean = false;

	@ViewChild("cropperComponent")
	public cropperComponent:CropperComponent;

	public collectionTextContent:CollectionTextContent = null;
	private _currentTextLanguage:SupportedLanguage = null;
	private _currentTextLanguagePretty:SupportedLanguagePretty = null;

	public maxLengths:IFieldMaxLengthMap<CollectionTextContent> = null;

	public selectedOwnerType:CollectionOwnerType = null;
	public selectedVisibilityType:CollectionVisibilityType = null;
	public selectedOrderType:CollectionOrderType = null;

	public userHasGenericPermissionToEdit:boolean = null;
	public userIsEditingAsOwner:boolean = null;
	public selectedNewOwnerUser:WaihonaUserRef = null;
	public userIsCollaborator:boolean = null;
	public userIsOrgAdminForSomeOrg:boolean = null;
	public selectedOrgRef:OrganizationRef = null;
	public selectableOrgRefs:OrganizationRef[] = [];
	public waihonaUserRefs:WaihonaUserRef[] = [];

	@ViewChildren(PopoverDirective) public popoverHosts:QueryList<PopoverDirective>;
	public buttonPopovers:Array<PopoverDirective> = [];
	@ViewChild("deletePopoverTemplate") public deletePopoverTemplate:TemplateRef<any>;

	public popover = {
		delete: {
			show: (index:number) => {
				this.buttonPopovers[index].show();
			},
			hide: (index:number) => {
				this.buttonPopovers[index].hide();
			}
		}
	};

	public localize = {
		title: (ref:PublishedResourceRef)=> this.LocalizeTools.ref(ref.localization, this.localizationService.language, "title"),
	};

	public CollectionOwnerType:any = null;
	// public CollectionOrderType:any = null;
	public CollectionVisibilityTypeIcon:any = null;
	public RoleType:any = null;

	public collectionOwnerTypes:Array<{ prettyType:CollectionOwnerTypePretty, type:CollectionOwnerType }> = [];
	public collectionVisibilityTypes:Array<{ prettyType:CollectionVisibilityTypePretty, type:CollectionVisibilityType }> = [];
	public collectionVisibilityTypeOptions:Array<{ prettyType:CollectionVisibilityTypePretty, type:CollectionVisibilityType }> = [];
	public collectionOrderTypes:Array<{ prettyType:CollectionOrderTypePretty, type:CollectionOrderType }> = [];
	public collectionTypes:Array<{ prettyType:CollectionTypePretty, type:CollectionType }> = [];

	public resourcesSearchFn:(term:string, resourceRef:PublishedResourceRef) => boolean = null;
	private subscribedToLanguageChange:boolean = false;

	constructor(protected injector:Injector,
	            private collectionService:CollectionService,
	            private waihonaUserService:WaihonaUserService,
	            private organizationService:OrganizationService,
	            private publishedResourceService:PublishedResourceService,
	            private uploadService:UploadService,
				private validationMetadataService:ValidationMetadataService,
	            private docTextContentService:DocumentTextContentService) {
		super(injector);

		this.localizationService.registerAndLocalize("CollectionEditComponent", this.CB)

		this.maxLengths = this.validationMetadataService.getMaxLengths(CollectionTextContent);

		this.CollectionOwnerType = CollectionOwnerType;
		// this.CollectionOrderType = CollectionOrderType;
		this.CollectionVisibilityTypeIcon = CollectionVisibilityTypeIcon;
		this.RoleType = RoleType;
		this.populateTypeArrays();

		const collectionGuid:string = this.route.snapshot.params['collectionId'] != "" ? this.route.snapshot.params['collectionId'] : null;
		const action:string = this.route.snapshot.data['action'];
		this.logger.log(`CollectionEditComponent::${action}, collectionGuid: ${collectionGuid}`);

		if (collectionGuid !== undefined && collectionGuid !== null) {
			this.mode = CollectionEditMode.edit;
			console.log("collection edit mode is edit");
		} else if (!collectionGuid && action == "create") {
			this.mode = CollectionEditMode.create;
			console.log("collection edit mode is create");
		}

		this.initializeCollection(collectionGuid);

		this.collectionItemsSorted
			.debounceTime(1000) // wait 1 sec after the last event before emitting last event
			.distinctUntilChanged() // only emit if value is different from previous value
			.subscribe((newCollectionItems:Array<PublishedResourceRef>) => {
				let currentValue = JSON.stringify(this.collectionItemsSnapshot);
				let newValue = JSON.stringify(newCollectionItems);
				if (!isEqual(currentValue, newValue)) {
					console.log(newCollectionItems);
					this.saveCollectionItems(newCollectionItems);
				}
			});

		this.sortableElements.valueChanges.subscribe(() => {
			console.log("sortableElements changed");
			this.collectionItemsSorted.next(this.sortableElements.value);
		});
	}

	ngOnInit() {
		this.trackSubscription(this.waihonaUserService.watchListAsRef$().subscribe(waihonaUserRefList => {
			this.waihonaUserRefs = waihonaUserRefList;
		}));

		// get list of user orgs to select from
		this.authService?.currentUser$.filter((currentUser:WaihonaUser) => currentUser !== null)
			.subscribe((currentUser:WaihonaUser) => {
				this.userHasGenericPermissionToEdit = this.roleService.hasPermissionFor(PermissionType.edit_collection);
				this.userIsOrgAdminForSomeOrg = this.currentUser.isRoleTypeForAny(RoleType.organizationAdmin);
				console.log("user is org admin for some org? " + this.userIsOrgAdminForSomeOrg);

				if (this.userIsOrgAdminForSomeOrg) {
					this.selectableOrgRefs = currentUser.organizations
						.filter((userOrg:WaihonaUserOrganization) => this.isOrgAdminFor(userOrg.organizationGuid))
						.map(userOrg => this.conversionService.convert(userOrg, OrganizationRef))

					this.logger.info(`The list of selectableOrgRefs is ready!  This user has ${this.selectableOrgRefs.length} orgs to select from.`);
				}
			});

		//get all resource refs to populate ng-select for user to choose from
		this.trackSubscription(this.publishedResourceService.store.listAsRef$(false).subscribe((resourceRefs:PublishedResourceRef[]) => {
			this.allResourceRefs = this.collectionService.sortResourceRefs(resourceRefs);
			this.filterOutSelectedItems();
		}));
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.popoverHosts.changes.subscribe((newPopoverHosts) => this.buttonPopovers = newPopoverHosts.toArray());
	}

	public filterOutSelectedItems() {
		if (this.collection) {
			// filter so that selectableResources does not contain resources that have already been selected
			this.selectableResources = this.allResourceRefs.filter(ref => (this.collection.items.find(ref2 => ref2.guid == ref.guid) == null));
			console.info(`We have resources for the user to add as collection items!  There are ${this.selectableResources.length} sorted resources in this list.`);
		}
	}

	public get ViewType() {return ViewType};

	private getTypeArray(baseType:any, prettyType:any) {
		return Object.keys(baseType).map((key:string) => {
			return {prettyType: prettyType[key], type: baseType[key]}
		});
	}

	private populateTypeArrays() {
		this.collectionOwnerTypes = this.getTypeArray(CollectionOwnerType, CollectionOwnerTypePretty);
		this.collectionOrderTypes = this.getTypeArray(CollectionOrderType, CollectionOrderTypePretty);
		this.collectionVisibilityTypes = this.getTypeArray(CollectionVisibilityType, CollectionVisibilityTypePretty);
		this.collectionTypes = this.getTypeArray(CollectionType, CollectionTypePretty);
	}

	public initializeCollection(guid?:string) {

		if (this.mode == CollectionEditMode.create) {
			this.logger.info(`Initializing a new collection!`);
			//the user is creating a new collection.  Create a Collection object!
			this.collection = new Collection();
			// make the owner the current user by default, it can be switched by the user later.
			this.collection.owner = new CollectionOwner(this.authService.currentUserRef);
			this.userIsEditingAsOwner = this.isEditingAsOwner();
			this.logger.info("user is editing as owner? " + this.userIsEditingAsOwner);
			//If we find them in the collaborators array by their guid
			this.userIsCollaborator = this.collection.collaborators.find((collaborator:WaihonaUserRef) => {
				return collaborator.guid == this.authService.currentUser.guid;
			}) != null;
			this.logger.info("user is collaborator? " + this.userIsCollaborator);

			this.collectionItemsSnapshot = [];
			this.selectedOwnerType = this.collection.configuration.ownerType;
			this.selectedOrderType = this.collection.configuration.orderType;
			this.selectedVisibilityType = this.collection.configuration.visibilityType;
			this.setVisibilityTypeOptions();

			//create text content based on the currently-selected language
			this.currentTextLanguage = this.localizationService.language;
			this.collection.documentTextContent.generate(this.currentTextLanguage); //generate default language
			this.collectionTextContent = this.collection.documentTextContent[this.currentTextLanguage];
			this.logger.info(`We have document text content in ${this.currentTextLanguage}`);

			this.updateSortableOptions();

			// filter so that selectableResources does not contain resources that have already been selected
			this.filterOutSelectedItems();

		} else if (this.mode == CollectionEditMode.edit) {

			this.logger.info(`Initializing an existing collection: ${guid}`);
			//the user is editing an existing collection.  Get the existing collection object!
			this.trackSubscription(this.collectionService.store.watch$(guid).pipe(filter((collection:Collection) => collection != null)).subscribe((collection:Collection) => {
				this.collection = collection;
				if (this.collection.configuration.orderType === CollectionOrderType.alphabetical) {
					if (!this.subscribedToLanguageChange) {
						let languageSub = this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
							if (this.collection.configuration.orderType === CollectionOrderType.alphabetical) {
								this.collection.items = sort(this.collection.items, "title", {nupepafy: true, localizedField: true, ref: true, lang: newLang}).ascending();
								this.updateSortableElementsView();
							} else {
								languageSub.unsubscribe();
								this.subscribedToLanguageChange = false;
							}
						});
						this.trackSubscription(languageSub);
						this.subscribedToLanguageChange = true;
					}
				}

				if (this.collection.owner && this.collection.owner.guid != undefined) {
					this.userIsEditingAsOwner = this.isEditingAsOwner();
					this.logger.info("user is editing as owner? " + this.userIsEditingAsOwner);
				}

				//If we find them in the collaborators array by their guid
				this.userIsCollaborator = this.collection.collaborators.find((collaborator:WaihonaUserRef) => {
					return collaborator.guid == this.authService.currentUser.guid;
				}) != null;
				this.logger.info("user is collaborator? " + this.userIsCollaborator);

				this.collectionItemsSnapshot = collection.items;
				this.updateSortableElementsView();

				this.selectedOwnerType = this.collection.configuration.ownerType;
				this.selectedOrderType = this.collection.configuration.orderType;
				this.selectedVisibilityType = this.collection.configuration.visibilityType;
				this.setVisibilityTypeOptions();
				this.logger.info(`Existing collection ${this.collection.guid} is loaded`);

				if (this.collection.owner.userRef == null && this.collection.owner.orgRef == null) {
					//TODO: This SHOULDNT be necessary
					if (this.collection.configuration.ownerType == CollectionOwnerType.user) {
						this._collection.owner.userRef = this.authService.currentUserRef;
					} else {
						this._collection.owner.orgRef = this.selectedOrgRef
					}
				}

				// if we don't have a currentTextLanguage set yet, then set it, otherwise don't change anything
				if (!this.currentTextLanguage) {
					// If we have the site-wide language for this collection, start with that by default.
					this.currentTextLanguage = this.docTextContentService.defaultLanguage(this.collection);
					this.collectionTextContent = this.collection.documentTextContent[this.currentTextLanguage];
					this.logger.info(`We have document text content in ${this.currentTextLanguage}`);
				}

				if (this.collection.configuration.ownerType === CollectionOwnerType.organization) {
					this.trackSubscription(this.organizationService.store.getAsRef$(this.collection.owner.guid).subscribe(orgRef => {
						this.selectedOrgRef = orgRef;
					}));
				}

				this.updateSortableOptions();

				// filter so that selectableResources does not contain resources that have already been selected
				this.filterOutSelectedItems();
			}));
		}
	}

	public get CollectionTextContent() {
		return CollectionTextContent;
	}
	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}

	public get SupportedLanguage() {
		return SupportedLanguage;
	}

	public get SupportedLanguages() {
		return SupportedLanguages;
	}

	public get currentTextLanguage():SupportedLanguage {
		return this._currentTextLanguage;
	}

	public set currentTextLanguage(target:SupportedLanguage) {
		this._currentTextLanguage = target;
		if (!this.collection.documentTextContent[target]) {
			this.collection.documentTextContent[target] = new CollectionTextContent();
		}
		this.collectionTextContent = this.collection.documentTextContent[target];
		this.currentTextLanguagePretty = SupportedLanguagePretty[this._currentTextLanguage];
	}

	public get currentTextLanguagePretty():SupportedLanguagePretty {
		return this._currentTextLanguagePretty;
	}

	public set currentTextLanguagePretty(newPrettyLang:SupportedLanguagePretty) {
		this._currentTextLanguagePretty = newPrettyLang;
	}

	public get collection():Collection {
		return this._collection;
	}

	public set collection(newValue:Collection) {
		this._collection = newValue;
	}

	public get CollectionEditMode() {
		return CollectionEditMode;
	}

	public get CollectionType() {
		return CollectionType;
	}

	public get CollectionTypePretty() {
		return CollectionTypePretty;
	}

	public get CollectionVisibilityType() {
		return CollectionVisibilityType;
	}

	public get CollectionOrderType() {
		return CollectionOrderType;
	}

	public canCreateCollection():boolean {
		return this.roleService.hasPermissionFor(PermissionType.create_collection);
	}

	public isEditingAsOwner():boolean {
		if (this.collection.configuration.ownerType === CollectionOwnerType.organization) {
			// if owner is org
			return this.isOrgAdminFor(this.collection.owner.guid)
		} else if (this.collection.configuration.ownerType === CollectionOwnerType.user) {
			// if owner is user
			return this.currentUser.guid === this.collection.owner.guid;
		}
	}

	public isOrgAdminFor(orgGuid:string):boolean {
		return this.currentUser.isRoleTypeForOrg(RoleType.organizationAdmin, orgGuid);
	}

	public setVisibilityTypeOptions() {
		if (this.selectedOwnerType === CollectionOwnerType.organization) {
			this.collectionVisibilityTypeOptions = this.collectionVisibilityTypes.filter((visibilityOption:{ prettyType:string, type:CollectionVisibilityType }) => visibilityOption.type !== CollectionVisibilityType.privateToUser);
		} else {
			this.collectionVisibilityTypeOptions = this.collectionVisibilityTypes.filter((visibilityOption:{ prettyType:string, type:CollectionVisibilityType }) => visibilityOption.type !== CollectionVisibilityType.privateToOrg);
		}
	}

	public selectedOwnerTypeChange() {
		this.setVisibilityTypeOptions();
		if (this.selectedOwnerType === CollectionOwnerType.organization && this.selectedVisibilityType === CollectionVisibilityType.privateToUser) {
			this.selectedVisibilityType = CollectionVisibilityType.privateToOrg;
		} else if (this.selectedOwnerType === CollectionOwnerType.user && this.selectedVisibilityType === CollectionVisibilityType.privateToOrg) {
			this.selectedVisibilityType = CollectionVisibilityType.privateToUser;
		}
	}

	public isAllowedToEditOwnerType():boolean {
		if (this.collection.configuration.ownerType === CollectionOwnerType.organization) {
			return this.userIsEditingAsOwner || this.userHasGenericPermissionToEdit;
		} else if (this.collection.configuration.ownerType === CollectionOwnerType.user) {
			return (this.userIsEditingAsOwner && this.userIsOrgAdminForSomeOrg) || this.userHasGenericPermissionToEdit;
		} else {
			this.logger.error("ownerType should not be something besides organization or user");
			return null;
		}
	}

	public resourceRefSubmitterName(resourceRef:PublishedResourceRef):string {
		if (resourceRef.configuration?.hideContributorName) {
			return resourceRef.organization.title;
		} else {
			return (resourceRef.submitter.altFirstName ? resourceRef.submitter.altFirstName : resourceRef.submitter.firstName) + " " + resourceRef.submitter.lastName;
		}
	}

	public onView() {
		if (this.mode === CollectionEditMode.create) {
			this.logger.error("Must save before navigating to preview");
			return;
		}
		try {
			this.router.navigate(["/collections/", this.collection.guid]).then(
				(result) => { this.logger.info("navigated to collection: " + result)}
			);
		} catch (routerErr) {
			console.error("could not navigate to collection: " + routerErr);
		}
	}

	public transferOwnership(newOwner) {
		if (newOwner) {
			// if the current user is not already a collaborator, add them as a collaborator
			if (!this.collection.collaborators.find((user:WaihonaUserRef) => user.guid == this.currentUserRef.guid)) {
				this.collection.collaborators.push(this.currentUserRef);
			}
			// transfer ownership to the newOwner
			this.collection.owner = new CollectionOwner(newOwner);
		}
	}

	public setOwner() {
		if (this.selectedOwnerType !== this.collection.configuration.ownerType) {
			this.collection.configuration.ownerType = this.selectedOwnerType;
			if (this.collection.configuration.ownerType === CollectionOwnerType.organization && this.selectedOrgRef) {
				this.collection.owner = new CollectionOwner(this.selectedOrgRef);
			} else if (this.collection.configuration.ownerType === CollectionOwnerType.user && !this.selectedNewOwnerUser) {
				this.collection.owner = new CollectionOwner(this.currentUserRef);
			}
		}
	}

	public resourceRefTitle(resourceRef:PublishedResourceRef):string {
		return this.localizationService.LocalizeTools.ref(resourceRef.localization, this.localizationService.language, "title");
	}
	public updateSortableElementsView() {
		if (isEqual(this.sortableElements.value, this.collection.items) == false) {
			this.sortableElements.clear();
			this.collection.items.forEach(
				(item:PublishedResourceRef) => this.sortableElements.push(new FormControl(item))
			);
		}
	}

	public updateSortableOptions() {
		let newSortableOptions:Options = {};
		Object.assign(newSortableOptions, this.sortableJsOptions);

		if (this.collection.configuration.orderType === CollectionOrderType.manual) {
			newSortableOptions.disabled = false;
			// this.CB.formLabel_sortItemsSection = this.CB.formLabel_reorderItems;
			this.CB.tooltip_sortItemsSection = this.CB.tooltip_reorderItems;
		} else {
			newSortableOptions.disabled = true;
			// this.CB.formLabel_sortItemsSection = this.CB.formLabel_removeItems;
			this.CB.tooltip_sortItemsSection = this.CB.tooltip_removeItems;
		}
		this.sortableJsOptions = newSortableOptions;
	}

	public onIndexChange($event, itemUpdated:PublishedResourceRef, currentIndex) {
		$event.preventDefault();
		$event.stopPropagation();
		let newIndex:number = $event.target.innerHTML - 1;
		if (newIndex <= 0) {
			newIndex = 0
		} else if (newIndex >= this.collection.items.length) {
			newIndex = this.collection.items.length - 1;
		}
		this.logger.info(`moving item ${itemUpdated.guid} from index ${currentIndex} to index ${newIndex}`);
		let movedItem:PublishedResourceRef = this.collection.items.splice(currentIndex, 1)[0];
		this.collection.items.splice(newIndex, 0, movedItem);
		this.saveCollectionItems(this.collection.items);
	}

	// changed from ng-select
	public onCollectionItemsChanged(newItems:Array<PublishedResourceRef>) {
		console.log(`received ${newItems.length} from child component`);
		this.collection.items = newItems;
		this.filterOutSelectedItems();
		this.sortCollectionItems();
		this.saveCollectionItems(this.collection.items);
	}

	public onCollectionItemsSorted(newCollectionItems) {
		this.collectionItemsSorted.next(newCollectionItems);
	}

	public removeItem(indexToDelete:number) {
		this.popover.delete.hide(indexToDelete)
		this.sortableElements.removeAt(indexToDelete);
		this.filterOutSelectedItems();
	}

	public removeRelatedResource(resource:PublishedResourceRef):void {
		let index:number = this.collection.items.indexOf(resource);
		this.collection.items.splice(index, 1);
		this.filterOutSelectedItems();
		this.saveCollectionItems(this.collection.items);
	}

	public sortCollectionItems() {
		// if sort order for the collection is alphabetical, sort items alphabetically
		if (this.collection.configuration.orderType === CollectionOrderType.alphabetical) {
			this.collection.items = this.collectionService.sortResourceRefs(this.collection.items);
		}
	}

	private saveCollectionItems(itemsToSave:Array<PublishedResourceRef>) {
		this.isSaving = true;
		this.logger.info(`saving the collection items...`);
		// if sort order for the collection is alphabetical, sort items after adding the new ones
		if (this.collection.configuration.orderType === CollectionOrderType.alphabetical) {
			this.collectionService.sortResourceRefs(this.collection.items);
		}
		let toSave:Collection = new Collection();
		toSave.guid = this.collection.guid;
		toSave.items = itemsToSave;
		toSave.lastSavedBy = this.currentUser.guid;

		let s:Subscription = this.collectionService.repo.updatePartial$(toSave, {
			guid: true,
			items: true,
			lastSavedBy: true
		}).filter((collection:Collection) => collection != null).subscribe((collection:Collection) => {
			this.logger.info(`successfully saved collection: ${this.collection.guid}`);
			this.isSaving= false;
			this.notificationService.displayToast(this.cbContext.toast.saved, ToastType.info);
			s.unsubscribe();
		});
	}

	public get ImageType() {
		return ImageType;
	}

	public uploadImage$(base64Png:string):Observable<string> {
		if (this.mode == CollectionEditMode.edit) {
			this.logger.info(`this collection is in edit mode and has received a new image from the cropper.  Now uploading the image...`);
			let imageUploading$:Observable<string> = this.uploadService.uploadCropperImage(ImageType.collection, base64Png, this.collection).filter(downloadUrl => !!downloadUrl);
			let s:Subscription = this.trackSubscription(imageUploading$.take(1).subscribe(downloadUrl => {
				s.unsubscribe();
				this.cropperComponent.url = downloadUrl;

				let s2:Subscription = this.trackSubscription(this.collectionService.updateCover(this.collection.guid, true).take(1).subscribe((value:Collection) => {
					s2.unsubscribe();
					this.collection.configuration.hasImage = value.configuration.hasImage;
					this.notificationService.displayToast(this.cbContext.toast.saved, ToastType.info);
					console.log("Database has been told cover has been updated.");
				}));
			}));
			return imageUploading$;
		} else if (this.mode == CollectionEditMode.create) {
			this.logger.info(`This is a new collection, which has received a new image from the cropper and will save the image when the collection is saved.`)
		}
	}

	public updateImageMetadata$():Observable<any> {
		let imageUpdating$:Observable<any> = this.uploadService.updateCoverImageMetadata(ImageType.collection, this.collection).filter(result => !!result);
		return imageUpdating$;
	}

	public notifyOnCropperFail():void {
		this.notificationService.displayToast(Localization.template.common.cropper.upload.fail, ToastType.error);
	}

	/** Submits the collection data */
	public submit():void {
		// clear validation errors and warnings
		this.validationErrorService.clearErrors();
		this.validationErrorService.clearWarnings();

		try {
			this.saveCollection();
		} catch (ex) {
			if (ex instanceof ValidationException) {
				this.validationErrorService.updateErrors(ex.errors);
			} else {
				this.logger.error(`Errors on submit: ${ex}`);
			}
			this.notificationService.displayToast(this.cbContext.toast.fail, ToastType.error);
			this.isSaving = false;
		}
	}

	private saveCollection() {
		this.validationErrorService.validateTextContentObject(this.collection, this.validateSelectedOrg);
		let selectedConfiguration:CollectionConfiguration = new CollectionConfiguration();
		Object.assign(selectedConfiguration, this.collection.configuration);
		selectedConfiguration.ownerType = this.selectedOwnerType;
		selectedConfiguration.orderType = this.selectedOrderType;
		selectedConfiguration.visibilityType = this.selectedVisibilityType;
		this.validationErrorService.validate(selectedConfiguration);

		this.isSaving = true;
		this.logger.info(`saving the collection...`);

		if (this.selectedNewOwnerUser) {
			this.transferOwnership(this.selectedNewOwnerUser);
		} else {
			this.setOwner(); //important that this comes before full configuration is set in the next line, because we need to know if there's a difference between the selectedOwnerType and the current configuration.ownerType.
		}
		this.collection.configuration = selectedConfiguration;
		this.updateSortableOptions();
		this.sortCollectionItems();
		this.collection.indexedTitle = NupepafyStringUtils.nupepafy(this.LocalizeTools.document(this.collection, CollectionTextContent, SupportedLanguage.en).title).toLowerCase();

		try {
			let s:Subscription = this.collectionService.save$(this.collection).filter((collection:Collection) => collection != null).subscribe((collection:Collection) => {
				s.unsubscribe();

				if (collection?.guid) {
					this.mode = CollectionEditMode.edit; //change mode to allow image to update
				}

				if (collection.configuration.hasImage) {
					let s2:Subscription = this.trackSubscription(this.updateImageMetadata$().take(1).subscribe(result => {
						s2.unsubscribe();
						this.logger.info(`successfully saved collection: ${this.collection.guid}`);
						this.isSaving = false;
						this.notificationService.displayToast(this.cbContext.toast.saved, ToastType.info);
					}));
				} else {
					this.logger.info(`successfully saved collection: ${this.collection.guid}`);
					this.isSaving = false;
					this.notificationService.displayToast(this.cbContext.toast.saved, ToastType.info);
				}

				try {
					this.router.navigate(["/collections", this.collection.guid, "edit"]).then(
						(result) => { result ? (this.logger.info("navigated to collection edit: " + result)) : this.logger.info("no need to navigate")}
					);
				} catch (routerErr) {
					console.error("could not navigate to collection edit: " + routerErr);
				}
			});
		} catch(err) {
			this.logger.error(`Errors on save: ${err}`);
			this.isSaving = false;
		}

	}

	private validateSelectedOrg:() => ValidationError[] = () => {
		if (this.selectedOwnerType === CollectionOwnerType.organization) {
			// check if the user has selected an organization to be the owner
			if (!this.selectedOrgRef && !this.collection.owner.orgRef) {
				let validationError = new ValidationError();
				validationError.property = "selectedOrgRef";
				validationError.constraints = {isNotEmpty:"Please select an organization to own this collection."};
				return [validationError];
			} else {
				return [];
			}
		}
	}
}

enum CollectionEditMode {
	/** When the user is creating a new collection, but the collection does not yet exist in the database */
	create="create",
	/** When the collection exists in the database and we're making updates to the data */
	edit="edit"
}
