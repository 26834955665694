<!--++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
This is a subcomponent of the edit-resource component.  These are the TD blocks within the table
representing the already-uploaded lesson assets, as well as the popover to add details to a single asset.
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->

<!--column 1: status icon-->
<td *ngIf="asset.hasConflictWith ==''; else hasConflict" class="text-center col-xs-1"><i class="fas fa-check-circle text-primary"></i></td>
<ng-template #hasConflict>
	<td class="text-center col-xs-1"><i class="fas fa-exclamation-triangle text-warning"></i></td>
</ng-template>

<!--column 2: file type icon-->
<td class="text-center col-xs-1"><i class="fas {{fontAwesomeFileIcon}}"></i></td>

<!--column 3: file name, messages, and popover button-->
<td class="col-xs-9">

	<!--filename with optional warnings-->
	<div>
		<span>
			<strong>
				<ng-container *ngIf="hasAnyErrorForAsset(asset)"><i class="fas fa-exclamation-triangle text-warning"></i></ng-container>
				{{friendlyFileName}}
				<span *ngIf="asset.assetSource == AssetSource.fanContribution">&nbsp;{{CB.append_fan | localize | async}}</span>
				<span *ngIf="asset.beingOverwritten">&nbsp;<i class="fab fa-rev text-white"></i>&nbsp;{{CB.append_overwritten}}</span>
			</strong>
		</span>
	</div>

	<!--conflict handler-->
	<div *ngIf="asset.assetSource==AssetSource.fanContribution && asset.hasConflictWith!=''">
		<p class="font-weight-bold font-italic text-warning">{{CB.fan_conflict | localize | async}}</p>
		<button type="button" class="btn btn-sm btn-primary" (click)="onRenameClick()" [class.active]="mode == LearningAssetComponentMode.rename">{{CB.button_rename | localize | async}}</button>
		<button type="button" class="btn btn-sm btn-warning" (click)="onOverwriteClick()">{{CB.button_overwrite | localize | async}}</button>
		<button type="button" class="btn btn-sm btn-light" (click)="onDeleteFanContributionClick()">{{CB.button_delete | localize | async}}</button>
	</div>

	<!--conflict options-->
	<div>
		<!--rename handler-->
		<ng-container *ngIf="this.mode == LearningAssetComponentMode.rename">
			<div class="form-row align-items-center mt-3">
				<label for="conflictingFileName" class="col-sm-5 col-form-label">{{CB.filename_conflict | localize | async}}</label>
				<div class="col-sm-5">
					<input type="text"
						   class="form-control"
						   disabled
						   id="conflictingFileName" name="conflictingFileName"
						   data-field-attr="conflictingFileName"
						   [(ngModel)]="conflictingFileNameNoExtension">
				</div>
				<label class="col-sm-2 col-form-label">.{{fileExtension}}</label>
			</div>
			<div class="form-row align-items-center mt-1">
				<label for="fileName" class="col-sm-5 col-form-label">{{CB.new_filename | localize | async}}</label>
				<div class="col-sm-5">
					<input type="text"
						   class="form-control"
						   id="fileName" name="fileName"
						   data-field-attr="fileName"
						   [(ngModel)]="userInputNewFileNameNoExtension">
				</div>
				<label class="col-sm-2 col-form-label">.{{fileExtension}}</label>
			</div>
			<br>
			<button class="btn btn-sm btn-primary" (click)="onConfirmRename()">{{CB.button_rename | localize | async}}</button>
			<button class="btn btn-sm btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
		</ng-container>

		<!--overwrite handler-->
		<ng-container *ngIf="this.mode == LearningAssetComponentMode.overwrite">
			<br>
			<span>{{CB.confirm_askOverwrite | localize | async}}</span><br/>
			<!--<input type="checkbox" [(ngModel)]="fanContribution.keepExistingMetadata"/>Keep existing metadata-->
			<button class="btn btn-sm btn-primary" (click)="onSelectionConfirm()">{{CB.confirm_overwrite | localize | async}}</button>
			<button class="btn btn-sm btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
		</ng-container>

		<!--discard handler-->
		<ng-container *ngIf="this.mode == LearningAssetComponentMode.discard">
			<br>
			<span>{{CB.confirm_askDelete | localize | async}}</span><br/>
			<button class="btn btn-sm btn-primary" (click)="onSelectionConfirm()">{{CB.confirm_delete | localize | async}}</button>
			<button class="btn btn-sm btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
		</ng-container>
	</div>

	<!--popover button-->
	<div>
		<button type="button"
				class="btn btn-sm btn-outline-secondary"
				*ngIf="asset.hasConflictWith==''"
				[popover]="popTemplate"
				data-placement="left"
				container="body"
				containerClass="asset-popover"
				[popoverTitle]="getTitle()"
				[outsideClick]="true" triggers="click">
			<ng-container *ngIf="hasAnyErrorForAsset(asset); else noAssetError">{{CB.popover_needsInfo | localize | async}}</ng-container>
			<ng-template #noAssetError>{{CB.popover_updateInfo | localize | async}}</ng-template>
		</button>
	</div>
</td>

<!--column 4: delete icon-->
<td class="table-col-right">
	<asset-menu-dropdown type="asset" (onDelete)="this.onDeleteClick()"></asset-menu-dropdown>
</td>


<!--asset details popover-->
<ng-template #popTemplate let-message="message" class="popover" role="tooltip">
	<form>
		<div class="form-row align-items-center row my-2">
			<label for="summary" class="col-sm-2 font-weight-bold col-form-label">{{CB.popover_summary | localize | async}}</label>
			<div class="col-sm-10">
				<input type="text"
					   class="form-control"
					   id="summary" name="summary"
					   data-field-attr="summary"
					   [(ngModel)]="asset.title"
					   placeholder="{{CB.popover_placeholder | localize | async}}">
			</div>
		</div>
	</form>
	<h6 class="font-weight-bold card-title my-2">{{CB.popover_types | localize | async}}</h6>
	<div class="options">
		<ng-container *ngFor="let checkbox of checkboxes">
			<label class="checkbox-con">{{checkbox.label}}
				<input type="checkbox" [attr.checked]="checkbox.checked ? true : null"
					   [ngModel]="checkbox.checked" (ngModelChange)="onCheckboxClick(checkbox)">
				<span class="checkmark"></span>
			</label>
		</ng-container>
		<br>
	</div>
</ng-template>
