import {Converter} from "../ConversionService";
import {Organization} from "../../domain/organization/Organization";
import {OrganizationRef} from "../../domain/organization/OrganizationRef";

//TODO: This is probably no longer relevant
export class OrganizationToOrganizationRefConverter implements Converter {

    public from:typeof Organization;
    public to:typeof OrganizationRef;

    constructor() {
        this.from = Organization;
        this.to = OrganizationRef;
    }

    public convert(source:Organization):OrganizationRef {

        //Create the target object
        let organizationRef:OrganizationRef = new OrganizationRef();
            organizationRef.guid = source.guid;
            organizationRef.title = source.title;

        return organizationRef;
    }
}
