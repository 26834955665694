import {Injectable} from "@angular/core";
import {ValidationException} from "../exceptions/ValidationException";
import {validateSync, ValidationError, ValidatorOptions} from "class-validator";
import {BehaviorSubject} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {ITextContentObject} from "../../domain/ITextContentObject";
import {SupportedLanguage, SupportedLanguages} from "../../domain/SupportedLanguages";


@Injectable({
	providedIn: 'root',
} as any)
/** Performs processing of a domain object and handles errors */
export class ValidationErrorService {

	private errors:BehaviorSubject<ValidationError[]> = new BehaviorSubject<ValidationError[]>([]);
	// Observable streams
	public errors$ = this.errors.asObservable();
	private warnings:BehaviorSubject<ValidationError[]> = new BehaviorSubject<ValidationError[]>([]);
	public warnings$ = this.warnings.asObservable();

	constructor(protected logger:NGXLogger) {
	}


	// Broadcast new errors
	public updateErrors(errors:ValidationError[]) {
		this.errors.next(errors);
	}

	// Broadcast new warnings
	public updateWarnings(warnings:ValidationError[]) {
		this.warnings.next(warnings);
	}

	public clearErrors() {
		this.updateErrors([]);
	}

	public clearWarnings() {
		this.updateWarnings([]);
	}

	public validateField(object:object, field:string):Array<ValidationError> {
		let validationError:Array<ValidationError> = validateSync(object);
		let filteredValidationError:Array<ValidationError> = validationError.filter(item => {
			return item.property == field;
		});

		return filteredValidationError;
	}

	/**
	 *
	 * @param name
	 * The field to be checked for a problem (error or warning)
	 * @param array
	 */
	public hasProblem(name:string, array:Array<object>) {
		const matching:ValidationError[] = array.filter((error:ValidationError) => {
			return (error.property == name);
		}) as ValidationError[];

		return (matching.length > 0);
	}

	public extractTextFor(name:string, array:Array<object>):string {
		const matching:any[] = array.filter((error:any) => {
			return (error.property == name);
		});

		if (matching.length > 0) {
			const error = matching[0].error;
			for (const key in error) {
				if (error.hasOwnProperty(key)) {
					let errorMessage:string = error[key];

					errorMessage = errorMessage
					// insert a space before all caps
						.replace(/([A-Z])/g, ' $1')
						// uppercase the first character
						.replace(/^./, function (str) {
							return str.toUpperCase();
						});
					let replaced = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
					return replaced.split("  ").join(" "); //remove double spaces
				}
			}
		}
		return "";
	}


	public validate(objectToValidate:Object, validatorOptions?:ValidatorOptions) {
		const errors:ValidationError[] = validateSync(objectToValidate, validatorOptions);

		if (errors.length > 0) {
			this.logger.error("validation failed. errors: ", errors);
			throw new ValidationException(errors);
		}
	}

	public validateTextContentObject(objectToValidate:ITextContentObject<any>, extraValidation?:() => ValidationError[], validatorOptions?:ValidatorOptions) {
		const errors:ValidationError[] = validateSync(objectToValidate, validatorOptions);
		errors.push(...this.validateSubObject(objectToValidate.documentTextContent as any, validatorOptions));
		if (extraValidation) {
			let extraErrors:ValidationError[] = extraValidation();
			if (extraErrors && extraErrors.length > 0) {
				errors.push(...extraErrors);
			}
		}

		if (errors.length > 0) {
			this.logger.error("validation failed. errors: ", errors);
			throw new ValidationException(errors);
		}
	}

	public validateSubObject(object:{ [key:string]:{} }, validatorOptions?:ValidatorOptions):Array<ValidationError> {

		let objectOfKeyedProperties:{ [key:string]:{} } = object;
		let keys:Array<string> = Object.keys(objectOfKeyedProperties);

		let allErrors:Array<ValidationError> = [];
		for (let i:number = 0; i < keys.length; i++) {
			let key:SupportedLanguage = keys[i] as SupportedLanguage;
			let objectToValidate:{} = objectOfKeyedProperties[key];

			let validationErrors:Array<ValidationError> = validateSync(objectToValidate, validatorOptions);
			validationErrors.map(validationError => {
				let constraintKeys:Array<string> = Object.keys(validationError.constraints);
				for (let j:number = 0; j < constraintKeys.length; j++) {
					let constraintKey:string = constraintKeys[j];
					let constraintValue:string = validationError.constraints[constraintKey];
					validationError.constraints[constraintKey] = `${SupportedLanguages.toPretty(key)}: ${constraintValue}`;
				}
			});
			allErrors.push(...validationErrors);
		}
		return allErrors;
	}
}
