import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {SiteIsUndergoingMaintenanceModalBlockGuard} from "../../../guards/SiteIsUndergoingMaintenanceModalBlockGuard";
import {Localization} from "../../../data/Localization";


@Component({
	selector: 'app-site-undergoing-maintenance-modal',
	templateUrl: './site-undergoing-maintenance-modal.component.html'
})
export class SiteUndergoingMaintenanceModalComponent implements OnInit {

	private cbContext = Localization.template.modal.maintenance.site_locked_for_maintenance;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body
	};

	public component:SiteIsUndergoingMaintenanceModalBlockGuard;


	constructor(public bsModalRef:BsModalRef,
				private localizationService:LocalizationService) {
		this.localizationService.registerAndLocalize("SiteUndergoingMaintenanceModalComponent", this.CB);

	}

	ngOnInit() {
	}

}
