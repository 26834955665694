import {from, Observable, ObservableInput, Subject, Subscription} from "rxjs";
import {
	AngularFirestore,
	AngularFirestoreCollection,
	DocumentChangeAction,
	Action,
	DocumentSnapshotDoesNotExist,
	DocumentSnapshotExists,
	QueryFn,
	SetOptions,
	AngularFirestoreDocument,
	Query,
	DocumentSnapshot,
	QuerySnapshot,
	QueryDocumentSnapshot, CollectionReference, FieldPath,
} from "@angular/fire/firestore";
import {classToPlain, Exclude, plainToClass, Type} from "class-transformer";
import {IRepository, IRepositoryAccessors} from "./IRepository";
import {map, take} from 'rxjs/operators';
import {ConversionService} from "../ConversionService";
import {generateAllPropertyKeys, getIndexedPropertyKeys, IPropertyType} from "../../domain/IndexProperty";
import {firestore} from "firebase";
import {DocumentChange, DocumentData} from "@angular/fire/firestore/interfaces";
import * as firebase from "firebase";
import {orderBy, slice} from "lodash";

export type ComparisonFunction = (a,b)=>-1|0|1;

export interface ICollectionRefContainer<T, R=any> {
	type: AngularFirestoreCollection<T>;
	ref?: AngularFirestoreCollection<R>
}

export interface ICacheMap<T, R=any> {
	objects:Map<string, T>;
	refs:Map<string, R>;
}
export interface IAbstractFirestoreRepositorySettings<T,R=any> {
	caching: {
		cacheObjects: boolean,
		cacheRefs: boolean,
		resolveRefs: boolean,
		refResolverFunction?: (object:T) => void;
	},
}
export abstract class AbstractFirestoreRepository<T, R=any> implements IRepository<T>, IndexableFirestoreRepository {

	public Type:new () => T;
	public RefType:new () => R;
	protected db:AngularFirestore;
	protected collection:AngularFirestoreCollection<T>;
	protected collectionForRef:AngularFirestoreCollection<R>;
	protected hasCollectionForRef:boolean = false;
	protected identifierProperty:string = "guid";
	protected conversionService:ConversionService;
	protected cacheReplaceRefs:boolean = false;
	private _updates$:Subject<number> = new Subject<number>();

	public _updateCount:number = 0;

	public indexes:{ [key:string]:() => FirestoreIndex } = {};

	protected cache:ICacheMap<T, R> = {
		objects: new Map<string, T>(),
		refs: new Map<string, R>(),
	}

	public settings:IAbstractFirestoreRepositorySettings<T,R> = {
		caching: {
			cacheObjects: false,
			cacheRefs: false,
			resolveRefs: false,
			refResolverFunction: null,
		},
	}
	public counts = {};
	protected refUpdates$:Subject<R[]>;

	public readonly accessors = () => {
		let self = this;
		return {
			//useful conversion
			convertToRef: (value:T):R => {
				return self.convertToRef.apply(self, [value]);
			},
			//singular
			get$: (guid:string, preferCache:boolean = true):Observable<T> => {
				return self.get$.apply(self, [guid, preferCache]);
			},
			getAsRef$(guid:string, preferCache:boolean = true):Observable<R> {
				return self.getAsRef$.apply(self, [guid, preferCache]);
			},
			list$:(preferCache:boolean = true):Observable<T[]> => {
				return self.list$.apply(self, [preferCache]);
			},
			listAsRef$: (preferCache:boolean = true):Observable<R[]> => {
				return self.listAsRef$.apply(self, [preferCache]);
			},
			listFilter$: (filterFunction:FilterFunction, preferCache:boolean = true):Observable<T[]> => {
				return self.listFilter$(filterFunction, preferCache);
			},
			listFilterAsRef$: (filterFunction:FilterFunction, preferCache:boolean = true):Observable<R[]> =>{
				return self.listFilterAsRef$(filterFunction, preferCache);
			},
			query$: (queryInstructions:QueryFn|QueryCriteria, preferCache:boolean = false):Observable<T[]> => {
				return self.query$.apply(self, [queryInstructions, preferCache]);
			},
			queryAsRef$: (queryFn?:QueryFn):Observable<R[]> => {
				return self.queryAsRef$.apply(self, [queryFn]);
			},
			queryBatched$: (queryInstructions:Array<QueryFn|QueryCriteria>, preferCache:boolean = false):Array<Observable<T[]>> => {
				return self.queryBatched$.apply(self, [queryInstructions, preferCache]);
			},
			watch$: (guid:string):Observable<T> => {
				return self.watch$.apply(self, [guid]);
			},
			watchAsRef$: (guid:string):Observable<R> => {
				return self.watchAsRef$.apply(self, [guid]);
			},
			watchQuery$: (queryFn:QueryFn):Observable<T[]> => {
				return self.watchQuery$.apply(self, [queryFn]);
			},
			watchQueryAsRef$: (queryFn?:QueryFn):Observable<R[]> => {
				return self.watchQueryAsRef$.apply(self, [queryFn]);
			},
			watchList$: ():Observable<T[]> => {
				return self.watchList$.apply(self, []);
			},
			watchListAsRef$: ():Observable<R[]> => {
				return self.watchListAsRef$.apply(self, []);
			},
			watchListFilter$: (filterFunction:FilterFunction, preferCache:boolean):Observable<T[]> => {
				return self.watchListFilter$.apply(self, [filterFunction, preferCache]);
			},
			watchListFilterAsRef$: (filterFunction:FilterFunction, preferCache:boolean):Observable<R[]> => {
				return self.watchListFilterAsRef$.apply(self, [filterFunction, preferCache]);
			},
		}
	};

	constructor( Type:new (...parameters:any[]) => T, db:AngularFirestore,  collectionRef:AngularFirestoreCollection<T>|ICollectionRefContainer<T, R>,  identifierProperty:string = "guid", conversionService:ConversionService=null, RefType:new({}?)=>R=null) {
		this.Type = Type;
		this.RefType = RefType;
		this.db = db;

		if(typeof collectionRef == "object" && collectionRef != null && collectionRef['type'] != null) {
			this.collection = (collectionRef as ICollectionRefContainer<T,R>).type;
			this.collectionForRef = (collectionRef as ICollectionRefContainer<T,R>).ref;

			this.hasCollectionForRef = (this.collectionForRef != null);

		} else {
			this.collection = collectionRef as AngularFirestoreCollection<T>;
		}
		this.identifierProperty = identifierProperty;
		this.conversionService = conversionService;
	}
	public get updates$():Subject<number> {
		return this._updates$;
	}
	public broadcastUpdate():void {
		this._updates$.next(++this._updateCount);
	}

	public get label():string {
		return `${this.constructor.name}${this.RefType ? `<${this.Type.name},${this.RefType.name}>` : `<${this.Type.name}>`}`;
	}

	public toIndexConfiguration():FirestoreIndexConfiguration {
		let keys:Array<string> = Object.keys(this.indexes);

		let configuration:FirestoreIndexConfiguration = new FirestoreIndexConfiguration();
		for (let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			let value:FirestoreIndex = this.indexes[key]();
			configuration.indexes.push(value);
			for(let field of value.fields) {
				let hasAnAt:boolean = field.fieldPath.indexOf("@") != -1;
				if(hasAnAt) {
					let stringUpToAt:string = field.fieldPath.substring(0, field.fieldPath.indexOf("@"));
					let tempString:string = field.fieldPath.substring(field.fieldPath.indexOf("@"), field.fieldPath.length);
					let atString:string = tempString.substring(tempString.indexOf("@"), (tempString.indexOf(".") != -1 ? tempString.indexOf(".") : tempString.length));
					let stringAfterAt:string = (tempString.indexOf(".") != -1 ? tempString.substring(tempString.indexOf(".")) : "");
					field.fieldPath = `${stringUpToAt}\`${atString}\`${stringAfterAt}`;
				}
			}
		}
		return configuration;
	}

	public get typeName():string {
		return this.Type.name;
	}
	public get refTypeName():string {
		return this.RefType? this.RefType.name : "";
	}
	public get identifier():string {
		return this.identifierProperty;
	}

	protected setCollection(collection:AngularFirestoreCollection<T>):void {
		this.collection = collection;
	}
	protected getCollection():AngularFirestoreCollection<T> {
		return this.collection;
	}
	protected setCollectionForRef(collectionForRef:AngularFirestoreCollection<R>):void {
		this.collectionForRef = collectionForRef;
	}
	protected getCollectionForRef():AngularFirestoreCollection<R> {
		return this.collectionForRef;
	}
	public subscribeToRefUpdates$():Observable<R[]> {
		if(this.refUpdates$ == null) {
			this.refUpdates$ = new Subject<R[]>();
		}
		return this.refUpdates$;
	}

	/* ---------------------------------------------------------------------
	 * CONVERSION
	 * -------------------------------------------------------------------*/
	/** Gets an object ready for writing (sending to database) */
	public convertToWritable(value:(T|R)):{} {
		//If the value contains starts with an at an is an array ... index the related property there as a string array
		if(value instanceof this.Type) {
			generateAllPropertyKeys(value);
		}

		let plainObject:any = classToPlain<T|R>(value as any); //Ignore indexes when going to plain
			plainObject = JSON.parse(JSON.stringify(plainObject));

		return plainObject;
	}
	/** Gets an object ready for reading (sending back to requestor) */
	public convertToReadable(data:{}, id:string):T {
		const document:T = plainToClass<T, any>(this.Type, data, { excludePrefixes: ["@"] }) as any;
		if(document != null) {
			document[this.identifierProperty] = id;
		}
		return document;
	}
	public processObjectForRefReplacement(o:T):void {
		if(this.settings.caching.resolveRefs) {
			this.settings.caching.refResolverFunction(o);
		}
	}
	/** Take the object and return a Ref (Requires a Converter to be registered) */
	public convertToRef(value:T):R {
		return this.conversionService.convert(value as any, this.RefType);
	}
	/** Take the Array of Objects and return and Array of Refs */
	public convertToArrayOfRefs(values:Array<T>):Array<R> {
		return values.map((value:T) => this.convertToRef(value));
	}

	/** Creates a writable object with only the desired properties on it if passed as a Type with instructions, otherwise merges the passed (should be plain) object with the database value. In both cases, identifier must be provided according to db model */
	private convertToUpdatePartialWritable(value:object|T, instructions?:{[key:string]:IPartialInstruction|{}|boolean}):object {
		let propertyKeys:Array<IPropertyType>;
		if(value instanceof this.Type) {
			propertyKeys = getIndexedPropertyKeys(value);
			this.convertPropertiesWithKeys(value); //@IndexProperty conversions
			value = classToPlain(value);
			value = JSON.parse(JSON.stringify(value)); //firestore is dumb and will crash if you leave undefined properties on it
		} else {
			value = JSON.parse(JSON.stringify(value)); //make value a copy
		}

		//apply the instructions to delete properties and sub-object hierarchies if they were pass
		if(instructions != null) {
			//Copy relevant generated properties from @IndexProperty annotations
			propertyKeys.forEach(propertyKey => {
				//if the instructions contain the property, then add the generated property to the instructions
				propertyKey = propertyKey[0];
				let instructionsAsObj:{} = instructions as {};
				if(instructionsAsObj[propertyKey.property] == true) {
					instructionsAsObj[propertyKey.generatedProperty] = true;
				}
			});
			this.generatePartialSub(value, instructions);//this modifies the original object
		}

		//Add whatever the identifier was back
		value[this.identifierProperty] = value[this.identifierProperty];

		return value as object;  //return the forgazy...maybe you were imagining those other properties were once there.
	}
	// For each property with a property name.
	// Create a property @propertyName with ["guid1", "guid2"] kind of an array
	private convertPropertiesWithKeys(object:any):void {
		//First do the automated stuff for all the indexed properties
		generateAllPropertyKeys(object);
	}
	/** Stores the Values and Refs to caches as the settings dictate (only if applicable) */
	public reviewForCacheStorage(value:T):void {
		if(value == null) {
			return;
		}
		if(this.settings.caching.cacheObjects) {
			this.cache.objects.set(value[this.identifierProperty], value);
		}
		if(this.settings.caching.cacheRefs) {
			this.cache.refs.set(value[this.identifierProperty], this.convertToRef(value));
		}
	}
	/** Converts then saves the Ref to a Ref Collection (only if applicable) */
	public reviewForRefSaving(value:T):void {
		console.log(`AbstractFirestoreRepository::reviewForRefSaving`);
		if(this.hasCollectionForRef) {
			this.saveRef$(value);
		}
	}
	/** Used by saveAll to generate firestore ids for elements with a null identifier property (new items) */
	protected generateUniqueFirestoreId():string {
		// Alphanumeric characters
		const chars =  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let autoId:string = '';
		for (let i:number = 0; i < 20; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;

	}
	/** Used by paging functions to reduce code-repeat */
	public generatePageResults(direction:PageDirection, docChanges:Array<DocumentChange<DocumentData>>, snapshot:firestore.QuerySnapshot<firestore.DocumentData>, pageCursor:PageCursor<T>, results:Array<T>, queryCriteria:QueryCriteria = null):PageResults<T> {
		let pageResults:PageResults<T> = new PageResults<T>(pageCursor.resultsPerPage);
		if(queryCriteria != null) {
			pageResults.pageCursor.criteria = queryCriteria;
		} else {
			pageResults.pageCursor.criteria = pageCursor.criteria;
		}

		this.processPreviousAndNextPageExists(direction, docChanges, pageResults.pageCursor, results);

		pageResults.pageCursor.cursorStart = docChanges[0] != null ? this.convertToReadable(docChanges[0].doc.data(), docChanges[0].doc.id) : null;
		pageResults.pageCursor.cursorEnd = docChanges[docChanges.length-1] != null ? this.convertToReadable(docChanges[docChanges.length-1].doc.data(), docChanges[docChanges.length-1].doc.id) : null;

		pageResults.pageCursor.cursor = snapshot;
		pageResults.pageCursor.queryFn = pageCursor.queryFn;
		pageResults.pageCursor.resultsPerPage = pageCursor.resultsPerPage;
		pageResults.resultsPerPage = pageCursor.resultsPerPage;
		pageResults.results = results;

		return pageResults;
	}
	public convertValuesForOrderFields(pageCursor:PageCursor<any>):Array<string> {
		let plainCursorEnd:{} = classToPlain(pageCursor.cursorEnd);

		let valuesForOrderFields:Array<string> = pageCursor.criteria.orderByCriteria.map((orderByCriterion:OrderByCriterion) => {
			let properties:Array<string> = orderByCriterion.fieldPath.split(".");
			return properties.reduce((accumulator, currentVal:string) => {return accumulator[currentVal]}, plainCursorEnd);
		});

		return valuesForOrderFields;
	}


	public resolveAndConvertRefs(t:T) {
		if(this.settings.caching.resolveRefs) {
			this.settings.caching.refResolverFunction(t);
		}
	}
	/* ---------------------------------------------------------------------
	 * CREATE / UPDATE
	 * -------------------------------------------------------------------*/
	/** Save the item individually (create/update) */
	public save$(object:T):Observable<T> {
		console.log(`AbstractFirestoreRepository::save$`);
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let returnObservable$:Observable<T>;

		if (object[this.identifierProperty] == null) {
			returnObservable$ = this.create$(object);
		} else {
			returnObservable$ = this.update$(object);
		}

		return returnObservable$;
	}

	protected saveRef$(value:T|R):Observable<R> {
		console.log(`AbstractFirestoreRepository::saveRef$`);
		if(!this.hasCollectionForRef) {
			console.error("Won't save ref.  There is no collection location for refs defined.");
			return;
		}
		let saveRefObservable$:Subject<R> = new Subject<R>();

		let identifier:string = value[this.identifierProperty];
		let ref:R = value as R;
		if(value instanceof this.Type) {
			ref = this.convertToRef(value);
		}

		let plainRef:any = this.convertToWritable(ref);
		this.collectionForRef.doc(identifier).set(plainRef).then(docRef => {
			saveRefObservable$.next(plainRef);
			saveRefObservable$.complete();
		});

		return saveRefObservable$;
	}
	public create$(value:T):Observable<T> {
		console.log(`AbstractFirestoreRepository::create$`);
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let createObservable$:Subject<T> = new Subject<T>();
		let plainObject:any = this.convertToWritable(value);
		this.collection
			.add(plainObject)
			.then(docRef => {
				value[this.identifierProperty] = docRef.id;
				this.reviewForRefSaving(value);
				this.reviewForCacheStorage(value);
				createObservable$.next(value)
				createObservable$.complete();
			});


		return createObservable$;
	}


	/** Saves all items as one - If adding a new item; you may not like what the identifier is unless you set it (making sure it doesnʻt already exist) */
	public saveAll$(values:T[]):Observable<T[]> {
		console.log(`AbstractFirestoreRepository::saveAll$`);
		let saveAllObservable$:Subject<T[]> = new Subject<T[]>();
		let multipleBatches:Array<T[]> = [];
		let cloneValues:Array<T> = [...values];

		// Handle bad cases ----------------------------------------------------
		if(values.length == 0) {
			console.error("Nothing to Save!");
			saveAllObservable$.next([]);
			saveAllObservable$.complete();
			return saveAllObservable$;
		}

		// Setup batch for processing ------------------------------------------
		while(cloneValues.length > 0) {
			if(this.hasCollectionForRef) {
				//maximum size 250 because 250 for refs
				multipleBatches.push(cloneValues.splice(0,250));
			} else {
				//maximum size 500
				multipleBatches.push(cloneValues.splice(0,500));
			}
		}

		// All batches ---------------------------------------------------------
		let batches:Array<firebase.firestore.WriteBatch> = [];
		let collectionRef:AngularFirestoreCollection = this.getCollection();

		for(let batchValues of multipleBatches) {
			let batch:firebase.firestore.WriteBatch = this.db.firestore.batch();
			batches.push(batch);

			for(let value of batchValues) {
				//Handle the "add" case for the pure Type------------------------------
				if (value[this.identifierProperty] == null) {
					value[this.identifierProperty] = this.generateUniqueFirestoreId();
				}
				let plain:{} = this.convertToWritable(value);

				//---Set the one or two writes on the batch ---------------------------
				//Set the write for the batch for the Type
				let documentRef:AngularFirestoreDocument = collectionRef.doc(value[this.identifierProperty]);
					batch.set(documentRef.ref, plain);

				//If applicable, set the write for the batch for the Ref (batch already appropriately sized)
				if(this.hasCollectionForRef) {
					let refRef:AngularFirestoreDocument = this.collectionForRef.doc(value[this.identifierProperty]);
					let plainRef:{} = this.convertToRef(value);
					batch.set(refRef.ref, plainRef);
				}
			}
		}

		//Write however many batches we have then complete together
		Promise.all(batches.map(batch=>batch.commit())).then(() => {
			saveAllObservable$.next(values);
			saveAllObservable$.complete();
		}).catch(error => {
			saveAllObservable$.error(error);
		});

		return saveAllObservable$;
	}

	public update$(value:T):Observable<T> {
		console.log(`AbstractFirestoreRepository::update$`);
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let updateObservable$:Subject<T> = new Subject<T>();

		let plainObject:any = this.convertToWritable(value);
		let identifier:string = value[this.identifierProperty];

		this.collection.doc(identifier).set(plainObject).then(docRef => {
			this.reviewForCacheStorage(value);
			this.reviewForRefSaving(value);
			updateObservable$.next(value);
			updateObservable$.complete();
		});
		return updateObservable$;
	}


	/**
	 * Iterates over the object and deletes the elements not present in instructions
	 * @param obj  a complete, complex, object, containing everything from arrays, nested objects, and boolean values
	 * @param instructions an simple yet nested object containing only objects or boolean true values, indicating the value to keep
	 */
	private generatePartialSub(obj:{},instructions:IPartialInstruction):any {
		Object.keys(obj).forEach(e => {
			let objProperty:any = obj[e];

			let instructionsProperty:IPartialInstruction|boolean = instructions[e];
			if(instructionsProperty === undefined || instructionsProperty == false) {
				delete obj[e]; //if it doesnt exist in instructions its okay to delete
			} else if (typeof objProperty == "object" && Array.isArray(objProperty)) {
				//ignore..this is an array..we want it
			} else if(typeof objProperty == "object") {
				return this.generatePartialSub(objProperty, instructionsProperty as IPartialInstruction);
			}
		});
	}

	/**
	 * Updates part of an object
	 * @param value - Can be a Class or an object, will be converted appropriately
	 * @param instructions - a simple object deep hierarchy containing only object or boolean values which are true as a marker for what stays
	 */
	public updatePartial$(value:object|T, instructions?:{[key:string]:IPartialInstruction|{}|boolean}):Observable<T> {
		console.log(`AbstractFirestoreRepository::updatePartial$`);
		//TODO: There is an issue with instructions not working for objects with unknown properties underneath..
		let updatePartialObservable$:Subject<T> = new Subject<T>();
		let identifier:string = value[this.identifierProperty];		//convert it to plain if needed
		let plainToWrite:{} = this.convertToUpdatePartialWritable(value, instructions);
		plainToWrite[this.identifierProperty] = identifier;

		//Now we can get started....
		console.log(`AbstractFirestoreRepository::updatePartial$: Saving the partial object...`);

		let setOptions:SetOptions = {
			merge: true
		};

		let self:AbstractFirestoreRepository<T> = this;

		//Update the value...but also when it sets; go ahead and fetch the whole thing...and later update the observable this thing returns...
		delete plainToWrite[this.identifierProperty];

		from(this.collection.doc(identifier).set(plainToWrite, setOptions)).subscribe(()=> {
			self._get$(identifier, false).subscribe(result => {
				updatePartialObservable$.next(result);
				updatePartialObservable$.complete();
			});
		});

		console.log(`AbstractFirestoreRepository::updatePartial$: complete`);
		return updatePartialObservable$;
	}


	/** Delete the object by its identifier.  WARNING: THIS IS DESTRUCTIVE! Please remember any related resources that may reference the document that is being deleted! */
	public delete$(guidOrObject:string|T):Observable<T> {
		console.log(`AbstractFirestoreRepository::delete$`);
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let deleteObservable$:Subject<T> = new Subject<T>();

		let isString:boolean = typeof guidOrObject == 'string' || guidOrObject instanceof String;
		let guid:string = isString ? guidOrObject : (guidOrObject as any)[this.identifierProperty];
		let value:T;

		if(!isString) {
			value = (guidOrObject as any)[this.identifierProperty];
		} else {
			//TODO:WTH?

		}

		let documentRef:AngularFirestoreDocument = this.collection.doc(guid);
		this._get$(guid, false).subscribe((item:T) => {
			value = item;
			if(item != null) {
				documentRef.delete().then(() => {
					deleteObservable$.next(item);
					deleteObservable$.complete();
				})
			} else {
				console.error(`Unable to delete ${guid}. It did not exist`);
				deleteObservable$.error(`Unable to delete ${guid}. It did not exist`);
			}
		});

		return deleteObservable$;
	}
	public processPreviousAndNextPageExists(directionOrContext:PageDirection, docChanges:Array<DocumentChange<DocumentData>>, pageCursor:PageCursor<T>, results:Array<T>):void {
		if(directionOrContext == PageDirection.Start) {
			pageCursor.prevPageExists = false;
			if (results.length > pageCursor.resultsPerPage) {
				pageCursor.nextPageExists = true;
				results.pop();
				docChanges.pop();
			} else {
				// otherwise, there is no next page
				pageCursor.nextPageExists = false;
			}

		} else if(directionOrContext == PageDirection.Backward) {
			// since we are going backwards, there is a page after this
			pageCursor.nextPageExists = true;
			// by looking back 1 extra, if there are more than resultsPerPage results, then there is a previous page
			if (results.length > pageCursor.resultsPerPage) {
				pageCursor.prevPageExists = true;
				results.shift();
				docChanges.shift();
			} else {
				// otherwise, there is no previous page
				pageCursor.prevPageExists = false;
			}
		} else if(directionOrContext == PageDirection.Forward) {
			// since we are going forwards, there is a page before this
			pageCursor.prevPageExists = true;
			// by looking ahead 1, if there are more than resultsPerPage results, then there is a next page
			if (results.length > pageCursor.resultsPerPage) {
				pageCursor.nextPageExists = true;
				results.pop();
				docChanges.pop();
			} else {
				// otherwise, there is no next page
				pageCursor.nextPageExists = false;
			}
		}
	}
	public hasCachedRef(guid:string):boolean {
		return this.cache.refs.has(guid);
	}
	public getCachedRef(guid:string):R {
		if(this.cache.refs.has(guid)) {
			return this.cache.refs.get(guid);
		}
		return null;
	}
	public hasCachedType(guid:string):boolean {
		return this.cache.objects.has(guid);
	}
	public getCachedType(guid:string):T {
		if(this.cache.objects.has(guid)) {
			return this.cache.objects.get(guid);
		}
		return null;
	}
	/* ---------------------------------------------------------------------
	 * READ
	 * -------------------------------------------------------------------*/


	public get$(guid:string, preferCache:boolean = true):Observable<T> {
		return this._get$(guid, preferCache);
	}
	private _get$(guid:string, preferCache:boolean = true):Observable<T> {
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		if(preferCache && this.hasCachedType(guid)) {
			let t:T = this.getCachedType(guid);
			return Observable.of(t);
		}
		let self = this;
		const result$ = this.collection.doc<T>(guid)
			.get({source: "server"})
			.pipe(
				take(1),
				map((doc:firestore.DocumentSnapshot<firestore.DocumentData>) => {
					let t:T = self.convertToReadable(doc.data(), doc.id);
					self.resolveAndConvertRefs(t);
					self.reviewForCacheStorage(t);
					return t;
				}, this),
			);
		return result$;
	}
	public getAsRef$(guid:string, preferCache:boolean = true):Observable<R> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		if(preferCache && this.cache.refs.has(guid)) {
			return Observable.of(this.cache.refs.get(guid));
		}

		let value$:Observable<T> = this._get$(guid);
		let convertedValue$:Observable<R> = value$.map<T,R>((t:T) => {
			return this.convertToRef(t);
		});
		return convertedValue$;
	}


	public list$(preferCache:boolean = true):Observable<T[]> {
		return this._list$(preferCache);
	}
	private _list$(preferCache:boolean = true):Observable<T[]> {
		let self = this;
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		if(preferCache && this.cache.objects.size > 0) {
			return Observable.of(Array.from(this.cache.objects, ([name, value]) => value));
		}
		let subscriptionToList$:Observable<T[]> = this.collection
			.get({source: 'server'})
			.pipe(
				take(1),
				map((docs:QuerySnapshot<T>) => {
					return docs.docs.map((a:QueryDocumentSnapshot<T>) => {
						//Get document data
						let t:T = self.convertToReadable(a.data(), a.id);
						this.resolveAndConvertRefs(t);
						this.reviewForCacheStorage(t);
						return t;
					}) as T[];
				}),
			);

		/** Update if listening */
		if(this.RefType != null && this. refUpdates$!= null) {
			subscriptionToList$.take(1).subscribe(values => {
				this.refUpdates$.next(this.convertToArrayOfRefs(values));
			});
		}
		return subscriptionToList$;
	}
	public listAsRef$(preferCache:boolean = true):Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let valuesList$:Observable<T[]> = this._list$(preferCache);
		let results = valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));

		return results;
	}

	public listFilter$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<T[]> {
		return this._listFilter$(filterFunction, preferCache);
	}
	public _listFilter$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<T[]> {
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let unfiltered$ = this._list$(preferCache);
		let filtered$ = unfiltered$.map(results => results.filter(filterFunction));

		return filtered$;
	}
	public listFilterAsRef$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let valuesList$:Observable<T[]> = this._listFilter$(filterFunction, preferCache);
		return valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));
	}


	public page$(resultsPerPage:number, queryFn:QueryFn, queryCriteria:QueryCriteria):Observable<PageResults<T>> {
		if (this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let pageSubject$:Subject<PageResults<T>> = new Subject<PageResults<T>>();
		if(resultsPerPage < 0) {
			console.log(`resultsPerPage needs to be greater than or equal to (>=) 0`);
			return;
		}

		let query:Query = queryFn(this.collection.ref).limit(resultsPerPage + 1);

		let results:Array<T> = [];
		query.get({source: "server"}).then(snapshot => {
			let docChanges:Array<DocumentChange<DocumentData>> = snapshot.docChanges();
			let pageCursor:PageCursor<T> = new PageCursor<T>(resultsPerPage);
				pageCursor.queryFn = queryFn;

			for(let payload of docChanges) {
				let t:T = this.convertToReadable(payload.doc.data(), payload.doc.id);
				this.resolveAndConvertRefs(t);
				this.reviewForCacheStorage(t);
				results.push(t);
			}

			let pageResults:PageResults<T> = this.generatePageResults(PageDirection.Start, 	docChanges, snapshot, pageCursor, results, queryCriteria);
				pageResults.resultsPerPage = resultsPerPage;
				pageResults.results = results;

			pageSubject$.next(pageResults);
			pageSubject$.complete();
		});
		return pageSubject$;
	}



	public previousPage$(pageCursor:PageCursor<T>):Observable<PageResults<T>> {
		//Getting previous page in firebase has issues because endBefore does not work as it should:.
		//See: https://github.com/angular/angularfire2/issues/1353

		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let pageSubject$:Subject<PageResults<T>> = new Subject<PageResults<T>>();
		if(pageCursor.resultsPerPage < 0) {
			console.log(`resultsPerPage needs to be greater than or equal to 1.`);
			return;
		}

		let query:Query = pageCursor.queryFn(this.collection.ref).endBefore(...this.convertValuesForOrderFields(pageCursor)).limitToLast(pageCursor.resultsPerPage + 1);

		if (!pageCursor?.prevPageExists) {
			console.info("Previous page does not exist");
			return;
		}

		let results:Array<T> = [];
		query.get({source: "server"}).then(snapshot => {
			let docChanges:Array<DocumentChange<DocumentData>> = snapshot.docChanges();
			docChanges = docChanges.reverse();

			for(let payload of docChanges) {
				let t:T = this.convertToReadable(payload.doc.data(), payload.doc.id);
				this.resolveAndConvertRefs(t);
				this.reviewForCacheStorage(t);
				results.push(t);
			}

			let pageResults:PageResults<T> = this.generatePageResults(PageDirection.Backward, docChanges, snapshot, pageCursor, results, pageCursor.criteria);
			pageSubject$.next(pageResults);
		});
		return pageSubject$.take(1);
	}



	public nextPage$(pageCursor:PageCursor<T>):Observable<PageResults<T>> {
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let pageSubject$:Subject<PageResults<T>> = new Subject<PageResults<T>>();
		if(pageCursor.resultsPerPage < 0) {
			console.log(`resultsPerPage needs to be greater than (>) 1`);
			return;
		}

		if (!pageCursor?.nextPageExists) {
			console.info("Next page does not exist");
			//TODO: Review.  Not really sure this should be a return..it could exist by the time of the request.
			return;
		}

		let query:Query = pageCursor.queryFn(this.collection.ref).startAfter(...this.convertValuesForOrderFields(pageCursor)).limit(pageCursor.resultsPerPage + 1);

		let results:Array<T> = [];
			query.get({source: "server"}).then(snapshot => {
				let docChanges:Array<DocumentChange<DocumentData>> = snapshot.docChanges();
				for(let payload of docChanges) {
					let t:T = this.convertToReadable(payload.doc.data(), payload.doc.id);
					this.resolveAndConvertRefs(t);
					this.reviewForCacheStorage(t);
					results.push(t);
				}
				let pageResults:PageResults<T> = this.generatePageResults(PageDirection.Forward, docChanges, snapshot, pageCursor, results, pageCursor.criteria);
				pageSubject$.next(pageResults);
			});


		return pageSubject$.take(1);
	}

	/* ---------------------------------------------------------------------
	 * WATCH
	 * -------------------------------------------------------------------*/
	public watch$(guid:string):Observable<T> {
		return this._watch$(guid);
	}
	public _watch$(guid:string):Observable<T> {
		let self = this;
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		const result = this.collection.doc(guid)
			.snapshotChanges()
			.pipe(
				map((doc:Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
					let t:T = self.convertToReadable(doc.payload.data(), doc.payload.id);
					this.resolveAndConvertRefs(t);
					this.reviewForCacheStorage(t);
					return t;
				}),
			);
		return result;
	}
	public watchAsRef$(guid:string):Observable<R> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let value$:Observable<T> = this._watch$(guid);
		return value$.map<T,R>((t:T) => this.convertToRef(t));
	}

	public watchList$():Observable<T[]> {
		return this._watchList$();
	}
	private _watchList$():Observable<T[]> {
		let self = this;
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let subscriptionToWatchList$:Observable<T[]> = this.collection
			.snapshotChanges()
			.pipe(
				map((docs:DocumentChangeAction<T>[]) => {
					return docs.map((a:DocumentChangeAction<T>) => {
						//Get document data
						let t:T = self.convertToReadable(a.payload.doc.data(), a.payload.doc.id);
						this.resolveAndConvertRefs(t);
						this.reviewForCacheStorage(t);
						return t;
					}) as T[];
				}),
			);
		//TODO: dont know how to handle unsubscription from releated varialbes for purposes of conversion.

		return subscriptionToWatchList$;
	}
	public watchListAsRef$():Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let valuesList$:Observable<T[]> = this._watchList$();
		return valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));
	}


	/* ---------------------------------------------------------------------
	 * QUERY
	 * -------------------------------------------------------------------*/
	public query$(queryInstructions:QueryFn|QueryCriteria, preferCache:boolean = false):Observable<T[]> {
		return this._query$(queryInstructions, preferCache);
	}
	private _query$(queryInstructions:QueryFn|QueryCriteria, preferCache:boolean = false):Observable<T[]> {
		let self = this;
		let isQueryFn:boolean = !(queryInstructions instanceof QueryCriteria);

		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		if(!isQueryFn && preferCache) {
			console.error("QueryCriteria must be passed if preferCache is true");
			return;
		}
		let queryCriteria:QueryCriteria = queryInstructions as QueryCriteria;
		let queryFn:QueryFn = isQueryFn ? queryInstructions as QueryFn : new QueryCriteriaBuilder(queryCriteria).toQueryFn();

		if(preferCache && this.cache.objects.size > 0) {
			let allCachedObjects:Array<T> = Array.from(this.cache.objects, ([name, value]) => {
				return value;
			});
			let allCachedObjectsWhichPassedCriteria:Array<T> = allCachedObjects.filter(cachedObject => queryCriteria.verify(cachedObject));
			return Observable.of(allCachedObjectsWhichPassedCriteria);
		}
		return this.db.collection(this.collection.ref, queryFn)
			.get({source: 'server'})
			.pipe(
				take(1),
				map((docs:QuerySnapshot<T>) => {
					return docs.docs.map((a:QueryDocumentSnapshot<T>) => {
						//Get document data
						let t:T = self.convertToReadable(a.data(), a.id);
						this.resolveAndConvertRefs(t);
						this.reviewForCacheStorage(t);
						return t;
					}) as T[];
				}),
			);
	}
	public queryAsRef$(queryFn?:QueryFn):Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let valuesList$:Observable<T[]> = this._query$(queryFn);
		return valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));
	}

	public queryBatched$(queryInstructions:Array<QueryFn|QueryCriteria>, preferCache:boolean = false):Array<Observable<T[]>> {
		let queryObservables:Array<Observable<T[]>> = [];
		for (let instructions of queryInstructions) {
			queryObservables.push(this._query$(instructions, preferCache));
		}
		return queryObservables;
	}

	public watchQuery$(queryFn:QueryFn):Observable<T[]> {
		return this._watchQuery$(queryFn);
	}
	public _watchQuery$(queryFn:QueryFn):Observable<T[]> {
		let self = this;
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		return this.db.collection(this.collection.ref, queryFn)
			.snapshotChanges()
			.pipe(
				map((docs:DocumentChangeAction<T>[]) => {
					return docs.map((a:DocumentChangeAction<T>) => {
						//Get document data
						let t:T = self.convertToReadable(a.payload.doc.data(), a.payload.doc.id);
						this.resolveAndConvertRefs(t);
						this.reviewForCacheStorage(t);
						return t;
					}) as T[];
				}),
			);
	}

	public watchQueryAsRef$(queryFn?:QueryFn):Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}
		let valuesList$:Observable<T[]> = this._watchQuery$(queryFn);
		return valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));
	}

	public watchListFilter$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<T[]> {
		return this._watchListFilter$(filterFunction, preferCache);
	}
	public _watchListFilter$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<T[]> {
		return this._watchList$().filter(filterFunction);
	}
	public watchListFilterAsRef$(filterFunction:FilterFunction, preferCache:boolean = true):Observable<R[]> {
		if(this.RefType == null) {
			console.error("Need to initialize RefType properly!");
			return;
		}
		if(this.collection == null) {
			console.error("Need to initialize repository properly!");
			return;
		}

		let valuesList$:Observable<T[]> = this._watchListFilter$(filterFunction, preferCache);
		return valuesList$.map<T[],R[]>((ts:T[]) => this.convertToArrayOfRefs(ts));
	}
}

export interface IPartialInstruction {
	[key:string]:boolean|{}|IPartialInstruction;
}
/** We need this in order to stop getting issues with easily using (searching options) for where queries */
export enum WhereFilterOperations {
	LessThan= '<',
	LessThanOrEqualTo='<=',
	EqualTo= '==',
	GreaterThanOrEqualTo= '>=',
	GreaterThan= '>',
	ArrayContains= 'array-contains',
	In = 'in',
	ArrayContainsAny= 'array-contains-any'
}

export enum OrderByDirections {
	Descending='desc',
	Ascending='asc'
}

export enum PageDirection {
	Start="Start",
	Backward="Backward",
	Forward="Forward"
}

export class PageResults<T> {
	public results:Array<T> = [];
	public pageCursor:PageCursor<T>;
	public resultsPerPage:number = 20;

	constructor(resultsPerPage:number = 20) {
		this.pageCursor = new PageCursor<T>(resultsPerPage);
		this.resultsPerPage = resultsPerPage;
	}
}

export class PageCursor<T> {
	public cursorStart:T;
	public cursorEnd:T;
	public nextPageExists:boolean = false;
	public prevPageExists:boolean = false;
	public cursor:QuerySnapshot<any>;
	public criteria:QueryCriteria;
	public queryFn:QueryFn;
	public resultsPerPage:number = 20;


	constructor(resultsPerPage:number = 20) {
		this.resultsPerPage = resultsPerPage;
	}
}

export class QueryCriterion {
	public fieldPath:string/* | FieldPath*/;
	public opStr:WhereFilterOperations;
	public value:any;

	public static create(fieldPath:string/* | FieldPath*/, opStr:WhereFilterOperations, value:any):QueryCriterion {
		let queryCriteria:QueryCriterion = new QueryCriterion();
			queryCriteria.fieldPath = fieldPath;
			queryCriteria.opStr = opStr;
			queryCriteria.value = value;
		return queryCriteria;
	}

	public verify(obj:{}):boolean {
		//Function to get value at fieldPath
		const fieldNameValueResolver = () => {
			return this.fieldPath.split('.').reduce((prev, curr) => {
				return prev ? prev[curr] : null
			}, obj || self)
		};
		let objectValue:any = fieldNameValueResolver();

		const operationExecutor = ():boolean => {
			switch (this.opStr) {
				case WhereFilterOperations.LessThan:
					return objectValue < this.value;
				case WhereFilterOperations.LessThanOrEqualTo:
					return objectValue <= this.value;
				case WhereFilterOperations.EqualTo:
					return objectValue == this.value;
				case WhereFilterOperations.GreaterThanOrEqualTo:
					return objectValue >= this.value;
				case WhereFilterOperations.GreaterThan:
					return objectValue > this.value;
				case WhereFilterOperations.ArrayContains:
					return objectValue.indexOf(this.value) != -1;
				case WhereFilterOperations.In:
					return this.value.indexOf(objectValue) != -1;
				case WhereFilterOperations.ArrayContainsAny:
					return objectValue.some(r => this.value.indexOf(r) >= 0);
			}
			return false;
		}
		return operationExecutor();
	}
}

export class OrderByCriterion {
	public fieldPath: string;//dont accept FieldPath..issue with turning it into a string (for a use needs a string) |FieldPath;
	public directionStr:OrderByDirections = OrderByDirections.Ascending;

	public static create(fieldPath:string/*|FieldPath*/, directionStr:OrderByDirections = OrderByDirections.Ascending):OrderByCriterion {
		let orderByCriteria:OrderByCriterion = new OrderByCriterion();
			orderByCriteria.fieldPath = fieldPath;
			orderByCriteria.directionStr = directionStr;

		return orderByCriteria;
	}
}

export type FilterFunction = (item)=>boolean;

export class QueryCriteria {
	@Type(()=> QueryCriterion)
	public whereCriteria:Array<QueryCriterion> = [];
	@Exclude()
	public whereClientFilter:FilterFunction = null

	@Type(()=> OrderByCriterion)
	public orderByCriteria:Array<OrderByCriterion> = []

	public filter<T>(items:Array<T>):Array<T> {
		let filteredItems:Array<T> = items;

		//If no client filter use the server filter to filter
		if(this.whereClientFilter == null) {
			for(let where of this.whereCriteria) {
				filteredItems = items.filter(item => {
					return where.verify(item);
				});
			}
		}


		return filteredItems;
	}
	public setClientFilter(filterFunction:FilterFunction):QueryCriteria {
		this.whereClientFilter = filterFunction;
		return this;
	}

	/**
	 * Adds all of the criterion in the passed QueryCriteria
	 * @param criteriaWeAreAdding  The criteria to add
	 */
	public addCriteria(criteriaWeAreAdding:QueryCriteria):void {
		for(let whereCriteriaWeAreAdding of criteriaWeAreAdding.whereCriteria) {
			this.whereCriteria.push(whereCriteriaWeAreAdding);
		}
		for(let orderByCriteriaWeAreAdding of criteriaWeAreAdding.orderByCriteria) {
			this.orderByCriteria.push(orderByCriteriaWeAreAdding);
		}
	}
	/** Adds whatever criterion that is passed to the queryCriteria */
	public addCriterion(criterion:QueryCriterion|OrderByCriterion):void {
		if(criterion instanceof QueryCriterion) {
			this.whereCriteria.push(criterion);
		}
		if(criterion instanceof OrderByCriterion) {
			this.orderByCriteria.push(criterion);
		}
	}

	/** Verifies that all where criteria PASS.  If so, returns true. Otherwise, returns false. */
	public verify(o:{}):boolean {
		return this.whereCriteria.every(criterion => criterion.verify(o));
	}
	public sort<T>(items:T[]):T[] {
		let order = this.toSortObject();
		return orderBy<T>(items, order.fields, order.sorting);
	}
	public toSortObject():{fields: Array<any>, sorting: Array<any>} {
		let sortObject = {fields: [], sorting: []};
		for(let orderBy of this.orderByCriteria) {
			sortObject.fields.push(orderBy.fieldPath)
			sortObject.sorting.push(orderBy.directionStr == OrderByDirections.Ascending ? "asc" : "desc");
		}
		return sortObject;
	}
}

export class QueryCriteriaBuilder {
	protected queryCriteria:QueryCriteria = new QueryCriteria();

	constructor(queryCriteria?:QueryCriteria) {
		if(queryCriteria) {
			this.queryCriteria = queryCriteria;
		}

	}

	public filterFunction(f:FilterFunction):QueryCriteriaBuilder {
		this.queryCriteria.whereClientFilter=f;
		return this;
	}

	public where(fieldPath:string|QueryCriterion, opStr?:WhereFilterOperations, value?:any):QueryCriteriaBuilder {
		let queryCriteria:QueryCriterion;
		if(fieldPath instanceof QueryCriterion) {
			queryCriteria = fieldPath;
		} else {
			queryCriteria = QueryCriterion.create(fieldPath, opStr, value);
		}
		this.queryCriteria.whereCriteria.push(queryCriteria);
		return this;
	}


	/**
	 * Additionally sort by the specified field, optionally in descending order instead of ascending.
	 * @param fieldPath The field to sort by.
	 * @param directionStr Optional direction to sort by (`asc` or `desc`). If
	 * not specified, order will be ascending.
	 * @return The created Query.
	 */
	public orderBy(fieldPath: string|OrderByCriterion, directionStr:OrderByDirections = OrderByDirections.Ascending):QueryCriteriaBuilder {
		let orderByCriteria:OrderByCriterion;
		if(fieldPath instanceof OrderByCriterion) {
			orderByCriteria = fieldPath;
		} else {
			orderByCriteria = OrderByCriterion.create(fieldPath, directionStr);
		}

		this.queryCriteria.orderByCriteria.push(orderByCriteria);
		return this;
	}
	public toCriteria():QueryCriteria {
		//TODO: Better to make a copy.
		return this.queryCriteria;
	}

	public toQueryFn():QueryFn {
		let self = this;
		let queryFn:QueryFn = (ref:CollectionReference):firebase.firestore.Query<DocumentData> => {
			//totally ignore ref param due to fact we are currying function
			let query:firebase.firestore.Query<DocumentData> = ref;

			for(let where of self.queryCriteria.whereCriteria) {
				query = query.where(where.fieldPath, where.opStr, where.value);
			}
			for(let orderBy of self.queryCriteria.orderByCriteria) {
				query = query.orderBy(orderBy.fieldPath, orderBy.directionStr);
			}
			return query;
		};
		return queryFn;
	}



	public toQueryFnBatch():QueryFn[] {
		let self = this;
		let queriesToReturn:Array<QueryFn> = [];
		let queryCriteriaToBatch:Array<QueryCriterion> = [];
		let slicesToBatch:Array<any[]> = new Array<any[]>();
		let batches:Array<Array<[]>> = [];

		/**
		 * Calculates "Cartesian Product" sets.
		 * @example
		 *   cartesianProduct([[1,2], [4,8], [16,32]])
		 *   Returns:
		 *   [
		 *     [1, 4, 16],
		 *     [1, 4, 32],
		 *     [1, 8, 16],
		 *     [1, 8, 32],
		 *     [2, 4, 16],
		 *     [2, 4, 32],
		 *     [2, 8, 16],
		 *     [2, 8, 32]
		 *   ]
		 * @see https://stackoverflow.com/a/49335807 answer by aliep
		 * @see https://en.wikipedia.org/wiki/Cartesian_product
		 * @param args {T[][]}
		 * @returns {T[][]}
		 */
		const moveThreadForwardAt = (t, tCursor) => {
			if (tCursor < 0) {
				return true;
			} // reached end of first array

			const newIndex = (t[tCursor][0] + 1) % t[tCursor][1];
			t[tCursor][0] = newIndex;

			if (newIndex == 0) {
				return moveThreadForwardAt(t, tCursor - 1);
			}

			return false;
		}

		const cartesianProduct = (...args) => {
			let result = [];
			const t = Array.from(Array(args.length)).map((x, i) => [0, args[i].length]);
			let reachedEndOfFirstArray = false;

			while (false == reachedEndOfFirstArray) {
				result.push(t.map((v, i) => args[i][v[0]]));

				reachedEndOfFirstArray = moveThreadForwardAt(t, args.length - 1);
			}

			return result;
		}

		for(let where of self.queryCriteria.whereCriteria) {
			let value:any = where.value;
			if (value instanceof Array) {
				for (let i = 0; i < value.length; i += 10) {
					if (i === 0) {
						queryCriteriaToBatch.push(where);
						slicesToBatch[queryCriteriaToBatch.length - 1] = [];
					}
					let valueSlice:any[] = value.slice(i, i + 10 > value.length ? value.length : i + 10);
					slicesToBatch[queryCriteriaToBatch.length - 1].push(valueSlice);
				}
			}
		}

		console.log(queryCriteriaToBatch);
		console.log(slicesToBatch);

		if (slicesToBatch.length > 0) {
			// Do Cartesian Product of slices to get array of batches.
			batches = cartesianProduct(slicesToBatch);
			console.log(batches);

			batches.forEach((batch:[][]) => {
				let queryFn:QueryFn = (ref:CollectionReference):firebase.firestore.Query<DocumentData> => {
					//totally ignore ref param due to fact we are currying function
					let query:firebase.firestore.Query<DocumentData> = ref;

					for(let where of self.queryCriteria.whereCriteria) {
						if (!(where.value instanceof Array)) {
							query = query.where(where.fieldPath, where.opStr, where.value);
						}
					}
					for (let i = 0; i < queryCriteriaToBatch.length; i++) {
						let queryCriterion = queryCriteriaToBatch[i];
						query = query.where(queryCriterion.fieldPath, queryCriterion.opStr, batch[i]);
					}

					for(let orderBy of self.queryCriteria.orderByCriteria) {
						query = query.orderBy(orderBy.fieldPath, orderBy.directionStr);
					}
					return query;
				};
				queriesToReturn.push(queryFn);
			});
		} else {
			// just do the default single queryFn
			let defaultQueryFn:QueryFn = (ref:CollectionReference):firebase.firestore.Query<DocumentData> => {
				//totally ignore ref param due to fact we are currying function
				let query:firebase.firestore.Query<DocumentData> = ref;

				for(let where of self.queryCriteria.whereCriteria) {
					query = query.where(where.fieldPath, where.opStr, where.value);
				}

				for(let orderBy of self.queryCriteria.orderByCriteria) {
					query = query.orderBy(orderBy.fieldPath, orderBy.directionStr);
				}
				return query;
			};
			queriesToReturn.push(defaultQueryFn);
		}

		return queriesToReturn;
	}

	/**
	 * localCollectionName
	 * @param localCollectionName just the last part of the name after the last /
	 */
	public toFirestoreIndex(collectionPath:string):FirestoreIndex {
		let item:FirestoreIndex = new FirestoreIndex();
			item.collectionGroup = collectionPath.substring(collectionPath.lastIndexOf("/") + 1); //Firestore can only index the collection name not the total path
			item.queryScope = "COLLECTION";

			//item.fields below (its complicated) ---------------
			let usedOrderByCriterion:Array<string> = [];
			for(let whereCriterion of this.queryCriteria.whereCriteria) {
				// (3) Three properties to set
				let field:FirestoreIndexField = new FirestoreIndexField();

				//(1) Field Path
				field.fieldPath = whereCriterion.fieldPath;
				//This thing combines the order by of the index so if the where criteria uses the index..be sure to add it.

				//(2) ...if applicable, order
				let missingOrder:boolean = true;

				let matchingOrderBy:OrderByCriterion = this.queryCriteria.orderByCriteria.find(orderBy => orderBy.fieldPath == whereCriterion.fieldPath);
				if(matchingOrderBy && usedOrderByCriterion.indexOf(matchingOrderBy.fieldPath) == -1) {
					field.order = matchingOrderBy.directionStr == OrderByDirections.Ascending ? "ASCENDING" : "DESCENDING";
					usedOrderByCriterion.push(matchingOrderBy.fieldPath);
					missingOrder = false;
				}

				let missingContains:boolean = true;
				//(3) ...if applicable arrayConfig (which must be "CONTAINS")
				if([WhereFilterOperations.ArrayContains,WhereFilterOperations.ArrayContainsAny].includes(whereCriterion.opStr) ) {
					field.arrayConfig = "CONTAINS";
					missingContains = false;
					delete field.order;
				} else {
					delete field.arrayConfig;
				}
				if(missingContains && missingOrder) {
					field.order = "ASCENDING";
				}

				item.fields.push(field);
			}
			for (let orderByCriterion of this.queryCriteria.orderByCriteria) {
				const alreadyAddedOrderField:boolean = usedOrderByCriterion.indexOf(orderByCriterion.fieldPath) != -1;
				if(!alreadyAddedOrderField) {
					let field:FirestoreIndexField = new FirestoreIndexField();

					//(1) Field Path
					field.fieldPath = orderByCriterion.fieldPath;
					field.order = orderByCriterion.directionStr == OrderByDirections.Ascending ? "ASCENDING" : "DESCENDING";
					delete field.arrayConfig;
					item.fields.push(field);
				}
			}

		return item;
	}
}

export class FirestoreIndexField {
	public fieldPath:string = null;
	public order?:"ASCENDING" | "DESCENDING";
	public arrayConfig?:"CONTAINS" = null;

	constructor() {

	}
}
export class FirestoreIndex {
	public collectionGroup:string; // Labeled "Collection ID" in the Firebase console
	public queryScope:"COLLECTION" | "COLLECTION_GROUP" = "COLLECTION";
	public fields:Array<FirestoreIndexField> = [];
}
export class FirestoreIndexConfiguration {
	public indexes:Array<FirestoreIndex> = [];
}
export interface IndexableFirestoreRepository {
	indexes:{[key:string]:()=>FirestoreIndex};
	toIndexConfiguration():FirestoreIndexConfiguration;
}

