import {
	AbstractFirestoreRepository, FirestoreIndex, IndexableFirestoreRepository,
	OrderByCriterion,
	OrderByDirections,
	QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {RefCachingService} from "../RefCachingService";
import {ConversionService} from "../ConversionService";
import {Collection} from "../../domain/collections/Collection";
import {CollectionOwnerType, CollectionVisibilityType} from "../../domain/collections/CollectionConfigurationEnums";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {Role, RoleType} from "../../domain/user/Role";
import {LocalizationService} from "../LocalizationService";
import {CollectionRef} from "../../domain/collections/CollectionRef";

@Injectable({
	providedIn: 'root',
} as any)
export class CollectionRepository extends AbstractFirestoreRepository<Collection, CollectionRef> implements IndexableFirestoreRepository {

	public by = {
		criterion: {
			isPublic: () =>
				QueryCriterion.create("configuration.visibilityType", WhereFilterOperations.EqualTo, CollectionVisibilityType.public),
			ownerTypeIsUser: () =>
				QueryCriterion.create("configuration.ownerType" , WhereFilterOperations.EqualTo, CollectionOwnerType.user),
			userIsOwner: (userGuid:string) =>
				QueryCriterion.create("owner.userRef.guid" , WhereFilterOperations.EqualTo, userGuid),
			userIsCollaborator: (userGuid:string) =>
				QueryCriterion.create("@collaborators" , WhereFilterOperations.ArrayContains, userGuid),
			userIsSharedTo: (userGuid:string) =>
				QueryCriterion.create("@sharedTo" , WhereFilterOperations.ArrayContains, userGuid),
			ownerTypeIsOrg: () =>
				QueryCriterion.create("configuration.ownerType" , WhereFilterOperations.EqualTo, CollectionOwnerType.organization),
			userIsOrgMember: (user:WaihonaUser) =>
				QueryCriterion.create("owner.orgRef.guid" , WhereFilterOperations.In, user.organizations
					.filter((userOrg:WaihonaUserOrganization) => userOrg.currentlyAt === true)
					.map((userOrg:WaihonaUserOrganization) => userOrg.organizationGuid)),
			userIsOrgAdmin: (user:WaihonaUser) => {
				let arrayToSearch:string[] = user.roles
					.filter((userRole:Role) => userRole.type === RoleType.organizationAdmin && !!userRole.organization)
					.map((userRole:Role) => userRole.organization);
				// in the case user has no orgAdmin roles, just provide a one item, empty string array to search
				return QueryCriterion.create("owner.orgRef.guid" , WhereFilterOperations.In, arrayToSearch.length !== 0 ? arrayToSearch : [""]);
			},
			userIsOrgAdminOfOrg: (user:WaihonaUser, org:string) => {
				let arrayToSearch:string[] = user.roles
					.filter((userRole:Role) => userRole.type === RoleType.organizationAdmin && userRole.organization === org)
					.map((userRole:Role) => userRole.organization);
				// in the case user has no orgAdmin roles, just provide a one item, empty string array to search
				return QueryCriterion.create("owner.orgRef.guid" , WhereFilterOperations.EqualTo, arrayToSearch.length !== 0 ? arrayToSearch[0] : "");
			},
			ownerIsOrg: (orgGuid:string) =>
				QueryCriterion.create("owner.orgRef.guid", WhereFilterOperations.EqualTo, orgGuid),
			containsResource: (resourceGuid:string) =>
				QueryCriterion.create("@items", WhereFilterOperations.ArrayContains, resourceGuid)
		},
		order: {
			byTitle: ()=> OrderByCriterion.create("indexedTitle", OrderByDirections.Ascending),
		},
		criteria: {
			all: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.byTitle()).toCriteria(),
			userIsOwner:(userGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.ownerTypeIsUser())
				.where(this.by.criterion.userIsOwner(userGuid))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			isPublic:() => new QueryCriteriaBuilder()
				.where(this.by.criterion.isPublic())
				.orderBy(this.by.order.byTitle()).toCriteria(),
			userIsMemberOfOwningOrg:(user:WaihonaUser) => new QueryCriteriaBuilder()
				.where(this.by.criterion.ownerTypeIsOrg())
				.where(this.by.criterion.userIsOrgMember(user))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			userIsAdminOfOwningOrg:(user:WaihonaUser) => new QueryCriteriaBuilder()
				.where(this.by.criterion.ownerTypeIsOrg())
				.where(this.by.criterion.userIsOrgAdmin(user))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			userIsCollaborator:(userGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.userIsCollaborator(userGuid))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			userIsSharedTo:(userGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.userIsSharedTo(userGuid))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			ownerIsOrgAndUserIsOrgAdminOfOwningOrg: (orgGuid:string, user:WaihonaUser) => new QueryCriteriaBuilder()
				.where(this.by.criterion.ownerTypeIsOrg())
				.where(this.by.criterion.ownerIsOrg(orgGuid))
				.where(this.by.criterion.userIsOrgAdminOfOrg(user, orgGuid))
				.orderBy(this.by.order.byTitle()).toCriteria(),
			containsResource: (resourceGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.containsResource(resourceGuid))
				.orderBy(this.by.order.byTitle()).toCriteria(),
		},
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn(),
			userIsOwner:  (userGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.userIsOwner(userGuid)).toQueryFn(),
			isPublic:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.isPublic()).toQueryFn(),
			userIsMemberOfOwningOrg:  (user:WaihonaUser):QueryFn[] => new QueryCriteriaBuilder(this.by.criteria.userIsMemberOfOwningOrg(user)).toQueryFnBatch(),
			userIsAdminOfOwningOrg:  (user:WaihonaUser):QueryFn[] => new QueryCriteriaBuilder(this.by.criteria.userIsAdminOfOwningOrg(user)).toQueryFnBatch(),
			userIsCollaborator:  (userGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.userIsCollaborator(userGuid)).toQueryFn(),
			userIsSharedTo:  (userGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.userIsSharedTo(userGuid)).toQueryFn(),
			ownerIsOrgAndUserIsOrgAdminOfOwningOrg:  (orgGuid:string, user:WaihonaUser):QueryFn => new QueryCriteriaBuilder(this.by.criteria.ownerIsOrgAndUserIsOrgAdminOfOwningOrg(orgGuid, user)).toQueryFn(),
			containsResource:  (resourceGuid:string) => new QueryCriteriaBuilder(this.by.criteria.containsResource(resourceGuid)).toQueryFn(),
		},
	};
	//TODO: Look at parameter of function being requirement of criteria of function ...
	public indexes = {
		isPublic:    ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.isPublic()).toFirestoreIndex(CollectionRepository.PATH),
		userIsOwner:    ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.userIsOwner("")).toFirestoreIndex(CollectionRepository.PATH),
		userIsMemberOfOwningOrg:    ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.userIsMemberOfOwningOrg(new WaihonaUser())).toFirestoreIndex(CollectionRepository.PATH),
		userIsCollaborator:     ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.userIsCollaborator("")).toFirestoreIndex(CollectionRepository.PATH),
		userIsSharedTo:     ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.userIsSharedTo("")).toFirestoreIndex(CollectionRepository.PATH),
		containsResource:     ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.containsResource("")).toFirestoreIndex(CollectionRepository.PATH),
	}

	public static get PATH():string {
		return "Collections"
	};

	constructor(protected db:AngularFirestore,
				protected resolver:RefCachingService,
				protected conversionService:ConversionService,
				protected localizationService:LocalizationService) {
		super(Collection, db,
			{
				type:db.collection("Collections"),
				ref:db.collection("Refs/collections/All")
			},
			"guid", conversionService, CollectionRef);
			/*this.settings.caching.cacheObjects = false;
			this.resolver.registerProcessor(this);*/
	}
}
