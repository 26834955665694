import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit, Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import {ITreeState, TreeComponent} from "angular-tree-component";

import { ITreeOptions } from 'angular-tree-component';
import {SelectableFrameworkRef} from "../../../../../../../domain/frameworks/SelectableFrameworkRef";
import {Observable} from "rxjs";
import {ITreeNode} from "angular-tree-component/dist/defs/api";
import {FrameworkNode} from "../../../../../../../domain/frameworks/FrameworkNode";
import {NGXLogger} from "ngx-logger";


@Component({
	selector: 'app-framework-select',
	templateUrl: './framework-select.component.html',
	styleUrls: ['./framework-select.component.scss']
})
export class FrameworkSelectComponent implements OnInit, OnChanges {

	@Input("selectableFrameworkRef")
	public selectableFrameworkRef:SelectableFrameworkRef;


	@Input()
	public availableFrameworks:Array<SelectableFrameworkRef>;

	@ViewChild("treeComponent")
	public treeComponent:TreeComponent;

	public selectedNodeText:string = "";
	public state:ITreeState = {};

	public options: ITreeOptions = {
		useCheckbox: true,
		/*getChildren: this.getChildren.bind(this)*/
	};

	constructor(protected logger:NGXLogger) {


	}
	public ngOnInit() {
		let self = this;

		if(this.selectableFrameworkRef != null) {
			this.state.selectedLeafNodeIds = self.selectableFrameworkRef.toObjectKeys() as any;

			this.logger.info("toObjectKEys: " + JSON.stringify(self.selectableFrameworkRef.toObjectKeys()));
			this.logger.info("state: " + JSON.stringify(self.state));

		}
	}

	ngOnChanges(changes:SimpleChanges): void {
		//TODO...determine property
		if(this.selectableFrameworkRef != null) {
			this.state.selectedLeafNodeIds = this.selectableFrameworkRef.toObjectKeys() as any;

			this.logger.info("toObjectKeys: " + JSON.stringify(this.selectableFrameworkRef.toObjectKeys()));
			this.logger.info("state: " + JSON.stringify(this.state));

		}
	}

	public get nodes() {
		return this.selectableFrameworkRef.actual.elements;
	}
	public get seletedDraftCurrently() {
		if(this.treeComponent && this.treeComponent.treeModel) {
			return this.treeComponent.treeModel.selectedLeafNodes;
		}
		return this.nodes; //fallback
	}
	public onNodeFocus($event) {
		let node:ITreeNode = $event.node;
		let nodeAsType:FrameworkNode = node.data;
		this.logger.info("name: " + $event.eventName + ", node: "  + nodeAsType.name + ", description: " + nodeAsType.description);

		this.selectedNodeText = nodeAsType.description;

	}
	public saveChanges():void {
		this.selectableFrameworkRef.fromObjectKeys(this.state.selectedLeafNodeIds);

	}

	public onNodeChange($event: Event) {
		console.log(`tree updated ${this.seletedDraftCurrently.length}`);
	}
}
