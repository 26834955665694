import {Converter} from "../ConversionService";
import {ResourceSubmissionTextContent} from "../../domain/resources/ResourceSubmissionTextContent";
import {DocumentTextContent} from "../../domain/DocumentTextContent";
import {SupportedLanguage} from "../../domain/SupportedLanguages";
import {PublishedResource} from "../../domain/resources/PublishedResource";
import {IPublishedResourceRefConfig, PublishedResourceRef} from "../../domain/resources/PublishedResourceRef";


export class PublishedResourceToPublishedResourceRefConverter implements Converter {

	public from:typeof PublishedResource;
	public to:typeof PublishedResourceRef;

	constructor() {
		this.from = PublishedResource;
		this.to = PublishedResourceRef;
	}

	public convert(source:PublishedResource) {

		let resourceSubmissionTextContent:DocumentTextContent<ResourceSubmissionTextContent> = source.resourceSubmission.documentTextContent;
		let languages:Array<string> = Object.keys(resourceSubmissionTextContent);
		let title:{[key:string]:string} = {};
		let summary:{[key:string]:string} = {};

		for(let i:number = 0; i < languages.length; i++) {
			let languageKey:SupportedLanguage = languages[i] as SupportedLanguage;
			let languageValue:ResourceSubmissionTextContent = resourceSubmissionTextContent[languageKey];
			title[languageKey] = languageValue.title;
			summary[languageKey] = languageValue.summary;
		}

		let configuration:IPublishedResourceRefConfig = {
			hasImage: source.resourceSubmission.hasImage,
			hideContributorName: source.resourceSubmission.configuration.hideSubmitterName
		};

		let publishedResourceRef:PublishedResourceRef = PublishedResourceRef.create(source.guid, title, summary, source.resourceSubmission.submitter, source.reviewers, source.resourceSubmission.organization, configuration);
		return publishedResourceRef;
	}
}

