
export class LocalUserSettings {
	public guid:string = "";
	public loads:number = 0; //number of times site has been loaded
	public oleloHawaiiPreference:IOleloHawaiiSettings = {
		hasPreference: false,
		isOleloHawaii: false
	}
}

export interface IOleloHawaiiSettings {
	hasPreference:boolean;
	isOleloHawaii:boolean;
}