export enum RegistrationExceptions {
	UNKNOWN_ERROR = "UNKNOWN_ERROR",
	VALIDATION = "VALIDATION",
	DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
	INVALID_EMAIL = "INVALID_EMAIL",
	MISSING_INVITATION_CODE = "MISSING_INVITATION_CODE",
	INVALID_INVITATION_CODE = "INVALID_INVITATION_CODE",
	DUPLICATE_NAME = "DUPLICATE_NAME",
	LOGGED_IN_CANT_REGISTER = "LOGGED_IN_CANT_REGISTER",
}

export class RegistrationException extends Error {

	public static readonly EXCEPTION_TO_MESSAGE_MAP:{[key:string]:string} = {
		LOGGED_IN_CANT_REGISTER: "Can't register when currently logged in!"
	};

	public static readonly EXCEPTION_TO_UI_MAP:{[key:string]:IUIDisplayForException} = {
		VALIDATION: {fields: null, errorOrWarning: "error"},
		DUPLICATE_EMAIL: {fields: "email", errorOrWarning: "error"},
		INVALID_EMAIL: {fields: "email", errorOrWarning: "error"},
		MISSING_INVITATION_CODE: {fields: "inviteCode", errorOrWarning: "error"},
		INVALID_INVITATION_CODE: {fields: "inviteCode", errorOrWarning: "error"},
		DUPLICATE_NAME: {fields: ["firstName", "lastName"], errorOrWarning: "warning"},
		EMAIL_INVITE_NOT_FOUND: {fields: null, errorOrWarning: "error"},
	};

	public static readonly s = RegistrationExceptions;

	public error:RegistrationExceptions;
	public data:any;

  constructor(error:RegistrationExceptions, data?:any, message?:string) {
	super("RegistrationException"); // 'Error' breaks prototype chain here
	Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain=

	this.error = error;
	this.data = data;
	if (message == null) {
	  this.message = RegistrationException.EXCEPTION_TO_MESSAGE_MAP[error];
	}
  }

}

export interface IUIDisplayForException {
	fields:string | Array<string>,
	errorOrWarning: "error" | "warning",
}
