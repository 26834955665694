import {AbstractCompoundFirestoreRepository} from "./AbstractCompoundFirestoreRepository";
import {AngularFirestore, Query, QueryFn, QueryGroupFn} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {
	FirestoreIndex,
	OrderByCriterion, OrderByDirections,
	PageCursor,
	PageResults, QueryCriteria, QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {MessageRef} from "../../domain/inbox/MessageRef";
import {InboxFilterType} from "../../domain/inbox/InboxFilterType";

export class SentRepository extends AbstractCompoundFirestoreRepository<MessageRef> {

	public by = {
		criterion: {
			sortingTagsContains: (filterType:InboxFilterType) =>
				QueryCriterion.create("sortingTags", WhereFilterOperations.ArrayContains, filterType),
		},
		order: {
			sentDescending: ()=> OrderByCriterion.create("sent", OrderByDirections.Descending),
			guidAscending: () => OrderByCriterion.create("guid",  OrderByDirections.Ascending)
		},
		criteria:{
			all: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.sentDescending())
				.orderBy(this.by.order.guidAscending())
				.toCriteria(),
			filterType:(filterType:InboxFilterType):QueryCriteria => new QueryCriteriaBuilder()
				.where(this.by.criterion.sortingTagsContains(filterType))
				.orderBy(this.by.order.sentDescending()).toCriteria(),
		},
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn(),
			sent: ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.sent)).toQueryFn(),
			trash: ():QueryFn =>new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.trash)).toQueryFn()
		}
	};

	public indexes = {
		all:  ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.all()).toFirestoreIndex(SentRepository.PATH),
	}

	public static get PATH():string {
		return "Users/%1$s/Sent"
	};

	constructor(protected db:AngularFirestore) {
		super(MessageRef, db, SentRepository.PATH);
	}

}


