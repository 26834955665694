import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../../environments/environment";
import {RouterOutlet} from "@angular/router";
import {HomeComponent} from "../../home/home.component";
import {DeviceDetectorService} from "ngx-device-detector";
import {Localization} from "../../../data/Localization";

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
	styleUrls: [
		'./full-layout.component.scss'
	],
})
export class FullLayoutComponent implements OnInit, AfterViewInit {

    @ViewChild(RouterOutlet) outlet;
    public childIsHomepage:boolean = null;
    public routerActivated:boolean = null;
    public showIEAlert:boolean = null;
    public usingIE:boolean = null;

    private cbAlertHeaderContext =  Localization.template.alert_headers_reusable;
    public CB = {
        ie_alert: this.cbAlertHeaderContext.ie_alert,
    };

    public constructor(private cookieService:CookieService,
                       protected deviceDetectorService:DeviceDetectorService) {
    }

    ngOnInit() {
        if (this.deviceDetectorService.browser == "IE") {
            this.usingIE = true;
            this.showIEAlert = true;
        }
    }

    ngAfterViewInit() {
        if (this.routerActivated) {
            setTimeout(() => {
                this.childIsHomepage = this?.outlet?.component instanceof HomeComponent;
            });
        }
    }

    public onDismissIEAlert() {
        this.showIEAlert = false;
    }

    public showExtras():boolean {
        return this.cookieService.check("all");
    }

    public get env() {
        return environment.env;
    }
    public get prettyEnv() {
        switch(this.env) {
            case 'dev':
                return "Development";
            case 'stg':
                return "Staging";
            case 'pubstg':
                return "Public Staging / Preproduction";
            case 'prd':
                return "Production";
            case 'sandbox':
                return "Sandbox Training";
        }
    }
    public get isProduction():boolean {
        return environment.env == "prd";
    }
}
