import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {DomainType, RoleService, UserAction} from "../services/common/RoleService";
import {Observable, Subject} from "rxjs";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";
import {NGXLogger} from "ngx-logger";
import {AuthService} from "../services/AuthService";


@Injectable({
	providedIn: 'root',
})
export class PermissionGuard extends SubscriptionCleaner implements CanActivate {

	constructor(private router: Router,
	            private authService:AuthService,
	            protected logger:NGXLogger,
	            private roleService:RoleService) {
		super();
	}
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
		this.logger.info("PermissionGuard::canActivate");
		let self = this;
		let allowIn:Subject<boolean> = new Subject<boolean>();

		(async () => {
			let url: string = state.url;
			//what is the guid being used for the operation.  Its held on the data object and usually == to "guid".

			const action:UserAction = route.data["action"];
			const domainType:DomainType = route.data["domainType"];
			const propertyOnRouteContainingGuid:string = route.data["guid"];//usually also equal to "guid"
			const guidValue:string = route.params[propertyOnRouteContainingGuid]; //"harry-potter" for example

			// this was just a test to see if a Class could be passed in through route.data. It can be.
			// let classToCheck = route.data['classToCheck'];
			// console.log(new classToCheck().configuration.visibilityType);

			await self.authService.checkIfLoggedIn();
			self.checkForPermission(domainType, action, guidValue).subscribe((shouldAllow:boolean) => {
				allowIn.next(shouldAllow);
			});
		})();

		return allowIn;
	}

	public checkForPermission(domainType:DomainType, action:UserAction, guid?:string):Observable<boolean> {
		let permissionResult:Subject<boolean> = new Subject<boolean>();

		if(guid != null) {
			try {
				this.trackSubscription(this.roleService.domainToServiceMap[domainType].store.get$(guid).subscribe((object:any) => {
					let result = this.roleService.hasGeneralOrACLPermissionsFor?.[domainType]?.[action]?.(object);
					permissionResult.next(result);
				}));
			} catch (error) {
				console.error(error);
				permissionResult.error(error);
			}
		} else {
			permissionResult.next(false);
		}
		return permissionResult;
	}
}
