import {ITextContent} from "../domain/ITextContent";
import {classToPlain, plainToClass} from "class-transformer";
import {ITextContentObject} from "../domain/ITextContentObject";
import {SupportedLanguage} from "../domain/SupportedLanguages";


export function toClassDocTextTransformFactory(targetClass: new () => ITextContent) {
	return (value:object, obj, type) => {
		return toClassDocTextTransform({plainObject: value, targetClass: targetClass}, obj, type);
	}
}

export function toClassDocTextTransform(data:{plainObject:object, targetClass:new() => ITextContent}, obj, type) {
	//From object to object map
	let keys:Array<string> = Object.keys(data.plainObject);
	let returnObject:{ [key:string]:ITextContent} = {};

	for (let iKey in keys) {
		let key:string = keys[iKey];
		let textContentPlain:any = data.plainObject[key];
		let textContent:ITextContent = plainToClass(data.targetClass, textContentPlain);
		returnObject[key] = textContent;
	}
	return returnObject;
}


export function toPlainDocTextTransform(value:{ [key:string]:ITextContent }, obj, type) {
	//From object map to a plain object map
	let keys:Array<string> = Object.keys(value);
	if(keys.indexOf("Clazz") != -1) {
		keys.splice(keys.indexOf("Clazz"),1);
	}
	let returnObject:{ [key:string]:{} } = {};

	for (let iKey in keys) {
		let key:string = keys[iKey];
		let textContent:ITextContent = value[key];
		let textContentPlain:any = classToPlain(textContent);
		returnObject[key] = textContentPlain;
	}
	return returnObject;
}
