<div class="modal-header bg-secondary">
	<h4 class="modal-title text-white">{{CB.modal_title | localize | async}}</h4>
	<button type="button" class="close" (click)="onCancel()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">

	<div *ngIf="!inCreateNewCollectionMode" class="d-flex justify-content-center">
		<button type="submit" class="btn btn-lg btn-light text-secondary font-weight-bold w-75 mb-2 mb-md-4" (click)="onEnterNewCollectionMode()">{{CB.create_new | localize | async}}</button>
	</div>

	<!--new collection form input-->
	<div *ngIf="inCreateNewCollectionMode" id="new-collection-form-container">
		<form autocomplete="off">
			<div class="form-row">
				<div class="form-group has-feedback">
					<label for="title" class="sr-only">{{CB.label_title | localize | async}}</label>
					<input [(ngModel)]="collectionTextContent.title"
					  [isInput]="true" class="form-control" data-field-attr="title"
					  field="title" id="title" name="title" autocomplete="off"
					  placeholder="{{CB.new_collection_title_placeholder | localize | async}}"
					  type="text" waih-validation-color>
					<waih-validation-text field="title"></waih-validation-text>
				</div>
				<div class="form-group has-feedback">
					<label for="summary" class="sr-only">{{CB.label_summary | localize | async}}</label>
					<textarea [(ngModel)]="collectionTextContent.summary"
							  [isInput]="true" class="form-control" data-field-attr="summary"
							  field="summary" id="summary" name="summary" autocomplete="off"
							  placeholder="{{CB.new_collection_description_placeholder | localize | async}}"
							  type="text" waih-validation-color></textarea>
					<waih-validation-text field="summary"></waih-validation-text>
				</div>
			</div>

			<div class="button-wrapper">
				<button type="button" class="btn btn-secondary" (click)="inCreateNewCollectionMode = false">{{CB.modal_cancel | localize | async}}</button>
				<button type="submit" class="btn btn-primary" (click)="onCreateNewCollection()">{{CB.create_collection | localize | async}}</button>
			</div>
		</form>
	</div>

	<div *ngIf="!inCreateNewCollectionMode">
		<select-collection [selectableCollections]="availableCollections"
						   [selectedCollections]="selectedCollections"
						   (onSelectionChange)="selectedCollections = $event">
		</select-collection>
	</div>


</div>
<!--end modal body-->

<div *ngIf="!inCreateNewCollectionMode" class="modal-footer border-0">
	<button type="button" class="btn btn-dark" (click)="bsModalRef.hide()">{{CB.modal_cancel | localize | async}}</button>
	<button [disabled]="selectedCollections.length < 1" type="submit" class="btn btn-primary" (click)="onConfirmAddToCollection()">{{CB.modal_submit | localize | async}}</button>
</div>
