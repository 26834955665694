<div class="animated fadeIn">
	<div class="row d-flex align-items-center">
		<div class="col-md-8 col-lg-9 col-xl-10">
			<h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.title | localize | async}}
				<!--expand/collapse button-->
				<span>
					<button id="collapseBtn" class="btn btn-secondary btn-sm rollover-btn" (click)="onClickExpand()">
						<span *ngIf="state.isExpanded === false"><i class="fas fa-angle-double-down"></i><span class="rollover-label">{{CB.expand | localize | async}}</span></span>
						<span *ngIf="state.isExpanded === true"><i class="fas fa-angle-double-up"></i><span class="rollover-label">{{CB.collapse | localize | async}}</span></span>
					</button>
				</span>
			</h1><br>
		</div>

		<!--search bar, used with listFilter pipe below-->
		<div class="col-md-4 col-lg-3 col-xl-2 my-auto search-bar-column">
			<app-search [searchTerm]="currentSearchValue" (searchValue)="setSearchValue($event)"></app-search>
		</div>
	</div>
	<div class="vertical-card-deck">
		<!--Iterated card deck-->
		<div class="card-deck">

			<div>
				<div class="card my-sm-1 my-lg-3 bg-transparent border-0 p-3" style="display: block;">
					<a href="javascript:void(0);" style="text-decoration: none;" [routerLink]="['create']">
						<div class="card-body text-center">
							<i class="fas fa-plus-circle fa-4x center-plus-button"></i>
							<h4>{{CB.createNew | localize | async}}</h4>
						</div>
					</a>
				</div>
			</div>

			<!--iterates over the filtered array to create a card for each item -->
			<app-collection-list-card
					*ngFor="let collection of collections |
						listFilter:currentSearchValue:['owner.name', 'documentTextContent.en.title', 'documentTextContent.en.summary', 'documentTextContent.haw.title', 'documentTextContent.haw.summary',
						'configuration.collectionType', 'configuration.visibilityType', {array: true, prop:'collaborators', subProps:['fullName']}, {array: true, prop: 'items', subProps:['localization.title.en', 'localization.title.haw']}]:filteredResultCount"
					[collection]="collection"
					[isExpanded]="state.isExpanded"
					[currentSearchValue]="currentSearchValue">
			</app-collection-list-card>

			<!--page loading card-->
			<loading-card *ngIf="collections.length === 0 || state.isLoading" [itemsLoading]="state.isLoading" listType="collections"></loading-card>
		</div>
	</div>
</div>
