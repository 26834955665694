export class DoomEmail {



	public subject:string = "";
	public date:string = "";
	public to:string = "";
	public from:string = "";
	public how:string = "";
	public body:string = "";



	constructor() {


	}

	public static parse():Array<DoomEmail> {

		//parse bodies
		let emailBodies:Array<string> = DoomEmail.bodies;

		emailBodies.map(part => {
			part.replace("</p>", "");
		});

		let subjectSource:Array<string> = DoomEmail.subjects;
		let doomEmails:Array<DoomEmail> = [];

		subjectSource.map(email => {
			let splitEmail = email.split('\t');
			let doomEmail:DoomEmail = new DoomEmail()
			doomEmail.subject = splitEmail[0];
			doomEmail.date = splitEmail[1];
			doomEmail.to = splitEmail[2];
			doomEmail.from = splitEmail[3];
			doomEmail.how = splitEmail[4];


			doomEmails.push(doomEmail);
		});

		for(let i:number = 0; i < doomEmails.length; i++) {
			let email:DoomEmail = doomEmails[i];
			if(emailBodies[i] != null) {
				email.body = emailBodies[i];
			}

		}


		/*console.log("Doom Subjects: ");
		for(let email of doomEmails) {
			console.log(`${email.subject}`);
		}*/

		return doomEmails;

	}
	public static subjects:Array<string> =
		[
		"Welcome Message	11-15-2145	You	Director W. Banks	Is on the PDA issued to you by H. Feiner",
		"Report to Marine HQ	11-15-2145	You	Sergeant Kelly	Is on the PDA issued to you by H. Feiner",
		"Safety Awareness Month	11-15-2145	You	Safety committee	Download from the Information Terminal in the Departure Lounge",
		"Code-Black: Missing Staff	11-15-2145	You	UAC Security	Download from the Information Terminal in the Mars City Reception",
		"New High Score!	12-12-2145	You	HR Coordinator	Get 25,000 points playing Super Turbo Turkey Puncher 3",
		"Volunteer to earn extra credits	11-15-2145	You	HR Coordinator	Download from the Information Terminal in the Marine Command Access",
		"Lost PDA	11-08-2145	Adam Berneche	Ethan Peterson	Is on the PDA of Adam E. Berneche",
		"Maintenance Report Needed	11-10-2145	Adam Berneche	Ethan Peterson	Is on the PDA of Adam E. Berneche",
		"Volunteer Services	11-13-2145	Adam Berneche	Delta HR	Is on the PDA of Adam E. Berneche",
		"Tired of working the crane?	11-15-2145	Daniel Young	Frank Delahue	Is on the PDA of Daniel M. Young",
		"You're not gonna believe this :)	11-13-2145	Grant Baston	T. Brooks	Is on the PDA of Grant F. Baston",
		"UPDATED SECURITY CODE	11-14-2145	Grant Baston	UAC Security	Is on the PDA of Grant F. Baston",
		"Got a new guy on the way	11-15-2145	Mark Ryan	T. Brooks	Is on the PDA of Mark B. Ryan",
		"Party in the Delta Labs	11-13-2145	Frank Delahue	Brian Mora	Is on the PDA of Frank A. Delahue",
		"RE: Request Security	11-14-2145	Frank Delahue	UAC Security	Is on the PDA of Frank A. Delahue",
		"Would you believe - chainsaws?	11-12-2145	Marcus Stanton	David Robbins	Is on the PDA of Marcus C. Stanton",
		"Farewell for now	11-14-2145	Marcus Stanton	Ray Gerhardt	Is on the PDA of Marcus C. Stanton",
		"FW: Farewell for now	11-14-2145	Marcus Stanton	Duncan Mathews	Is on the PDA of Marcus C. Stanton",
		"RE: Patient Attack	11-09-2145	Mark Caseon	Jordan Kenedy	Is on the PDA of Marcus T. Caseon",
		"Leaving?	11-15-2145	Mark Caseon	Sarah Holsten	Is on the PDA of Marcus T. Caseon",
		"Sentry Bot Security	11-02-2145	SSgt Tyson	PFC Hagar	Is on the PDA of Sergeant Bill Tyson",
		"New Arrival	11-10-2145	SSgt Tyson	Sergeant Kelly	Is on the PDA of Sergeant Bill Tyson",
		"Security Code Change	11-11-2145	SSgt Tyson	Central Security	Is on the PDA of Sergeant Bill Tyson",
		"Farewell for now	11-14-2145	Duncan Mathews	Ray Gerhardt	Is on the PDA of Duncan A. Mathews",
		"RE: Equipment Request	11-15-2145	Duncan Mathews	UAC Security	Is on the PDA of Duncan A. Mathews",
		"Armaments in the cabinet	11-12-2145	Alan Dorweiler	Ethan Peterson	Is on the PDA of Alan E. Dorweiler",
		"UAC Corporate	11-14-2145	Alan Dorweiler	Ethan Peterson	Is on the PDA of Alan E. Dorweiler",
		"Lightspeed Pharmacy	11-14-2145	Alan Dorweiler	martianbuddy.com	Is on the PDA of Alan E. Dorweiler",
		"Free Stuff!!	10-09-2145	Jonathan Moses	martianbuddy.com	Is on the PDA of Jonathan A. Moses",
		"Storage Cabinet	11-07-2145	Jonathan Moses	Jim Bowier	Is on the PDA of Jonathan A. Moses",
		"RE: Storage Cabinet	11-08-2145	Jonathan Moses	Jim Bowier	Is on the PDA of Jonathan A. Moses",
		"Religious Artifacts	10-02-2145	William Banks	Malcolm Betruger	Is on the PDA of William L. Banks",
		"Missing Person	11-08-2145	William Banks	Matthew White	Is on the PDA of William L. Banks",
		"New Web Site	11-10-2145	Banks	UA-Corp.com	Is on the PDA of William L. Banks",
		"Network Breach	11-02-2145	Paul Simons	IT Security	Is on the PDA of Paul M. Simons",
		"Email Spam	11-13-2145	Paul Simons	Russell Weilder	Is on the PDA of Paul M. Simons",
		"EPD status	11-04-2145	Kyle Berger	William Landow	Is on the PDA of Kyle C. Berger",
		"EPD Collection Chamber status	11-10-2145	Kyle Berger	UAC Maintenance	Is on the PDA of Kyle C. Berger",
		"New Safety Protocols	11-11-2145	Jack Smith	Brian Jenkins	Is on the PDA of Jack T. Smith",
		"Buddy system?!	11-13-2145	Jack Smith	Ari Braden	Is on the PDA of Jack T. Smith",
		"Lost PDA	11-13-2145	Dr. George Krietman	Dr. Pat Harvey	Is on the PDA of George Krietman",
		"Time off	10-24-2145	Bernie Lipsitz	John McDermott	Is on the PDA of Bernie Lipsitz",
		"Hydrocon O2 leak	11-14-2145	Bernie Lipsitz	Fredric Anubus	Is on the PDA of Bernie Lipsitz",
		"Play Uanet's DracoWyzard now!	10-11-2145	Andrew Chin	martianbuddy.com	Is on the PDA of Andrew Chin",
		"8 sided die	10-12-2145	Andrew Chin	Walter Connors	Is on the PDA of Andrew Chin",
		"The E-Rock Has Landed	11-08-2145	Dr. Andrew Chin	Erik Reeves	Is on the PDA of Andrew Chin",
		"Storage cabinet 038	11-12-2145	Dr. Andrew Chin	Brian Wellington	Is on the PDA of Andrew Chin",
		"Collection	10-12-2145	Walter Connors	Walter Connors	Is on the PDA of Walter I. Connors",
		"Note to self	10-16-2145	Walter Connors	Walter Connors	Is on the PDA of Walter I. Connors",
		"MFS Compressor Numbers	11-02-2145	Walter Connors	Derek Wayland	Is on the PDA of Walter I. Connors",
		"Won't be in today	10-28-2145	Mark Lamia	George Poota	Is on the PDA of Mark B. Lamia",
		"Storage lockers	10-31-2145	Mark Lamia	Graham Fuchs	Is on the PDA of Mark B. Lamia",
		"Doctor appointment	11-02-2145	Mark Lamia	George Poota	Is on the PDA of Mark B. Lamia",
		"Crates moved	11-12-2145	Mark Lamia	Dusty Welch	Is on the PDA of Mark B. Lamia",
		"Hours of operation	10-28-2145	George Poota	Mark Lamia	Is on the PDA of George Poota",
		"++FIRE YOUR BOSS!!++	10-28-2145	George Poota	martianbuddy.com	Is on the PDA of George Poota",
		"Chamber Calibration	11-1-2145	Larry Kaczynski	David Carter	Is on the PDA of Larry Kaczynski",
		"Hello	11-9-2145	Larry Kaczynski	John Okonkwo	Is on the PDA of Larry Kaczynski",
		"Security Sweep Request	10-26-2145	Michael Abrams	Henry Nelson	Is on the PDA of Michael G. Abrams",
		"Personnel Request	11-10-2145	Michael Abrams	Human Resources	Is on the PDA of Michael G. Abrams",
		"Invalid Number in code	11-15-2145	Michael Abrams	I.T. Security	Is on the PDA of Michael G. Abrams",
		"Strange Voices	10-24-2145	Henry Nelson	Jeff Dickens	Is on the PDA of Henry Q. Nelson",
		"Missing Tools	11-13-2145	Henry Nelson	Greg O'Brian	Is on the PDA of Henry Q. Nelson",
		"The New Taboo!	11-10-2145	Paul Raad	martianbuddy.com	Is on the PDA of Paul E. Raad",
		"Ice Cream Social	11-12-2145	Paul Raad	Social Committee	Is on the PDA of Paul E. Raad",
		"Plasma Storage Capacity	11-02-2145	Theresa Chasar	Lloyd Renstrom	Is on the PDA of Theresa M. Chasar",
		"Your Work	11-05-2145	Theresa Chasar	Lloyd Renstrom	Is on the PDA of Theresa M. Chasar",
		"RE: Lloyd is driving me nuts!	11-10-2145	Theresa Chasar	Elizabeth McNeil	Is on the PDA of Theresa M. Chasar",
		"More on Swensen	10-25-2145	Steve Hammer	Mathew Morton	Is on the PDA of Steve L. Hammer",
		"Lets help this poor girl!	11-15-2145	Steve Hammer	Eric Grossman	Is on the PDA of Steve L. Hammer",
		"Those Damn Chainsaws!	11-13-2145	James Holiday	Ron Ridge	Is on the PDA of James A. Holiday",
		"Site 1 needs	11-15-2145	James Holiday	Shipping Authority	Is on the PDA of James A. Holiday",
		"Maintenance Lift Access Restrictions	11-14-2145	Ron Ridge	UAC Authority	Is on the PDA of Ron I. Ridge",
		"RE: Those Damn Chainsaws!	11-14-2145	Ron Ridge	James Holiday	Is on the PDA of Ron I. Ridge",
		"Your receipt # 9318691220	10-10-2145	Seamus Blake	Crater Music	Is on the PDA of Seamus J. Blake",
		"Question??	10-11-2145	Seamus Blake	Karl Roper	Is on the PDA of Seamus J. Blake",
		"Join Now and Save 20%	11-08-2145	Ben Wolfe	martianbuddy.com	Is on the PDA of Ben B. Wolfe",
		"RE: Request Security Access	11-12-2145	Ben Wolfe	Security Authority	Is on the PDA of Ben B. Wolfe",
		"Quiz Night	11-14-2145	Rob Finch	Brent Davis	Is on the PDA of Robert J. Finch",
		"On your way home!!	11-15-2145	Rob Finch	Steven Finch	Is on the PDA of Robert J. Finch",
		"Pump Malfunction	11-02-2145	Nicholas Sadowayj	Henry Varela	Is on the PDA of Nick Sadowayj",
		"Locked Armaments	11-10-2145	Nicholas Sadowayj	Scott Johnson	Is on the PDA of Nick Sadowayj",
		"New Rocket Launcher	10-30-2145	Anthony Graza	Michael Abrams	Is on the PDA of Anthony R. Garza",
		"RE: New Rocket Launcher	11-04-2145	Anthony Grza	Michael Abrams	Is on the PDA of Anthony R. Garza",
		"Sleep Away!!	11-06-2145	Tony Graza	martianbuddy.com	Is on the PDA of Anthony R. Garza",
		"Pump Malfunction	11-04-2145	Jeff Moen	Henry Varela	Is on the PDA of Jeffery R. Moen",
		"Toxic Waste Storage	11-09-2145	Jeff Moen	Gregg Brandenburg	Is on the PDA of Jeffery R. Moen",
		"RE: Locked Armaments	11-11-2145	Scott Johnson	Nicholas Sadowayj	Is on the PDA of Scott P. Johnson",
		"Door Repair	10-23-2145	Sam Harding	James Torbin	Is on the PDA of Samuel J. Harding",
		"Turret Gun Mounting Brackets	11-03-2145	Sam Harding	Karl Cullen	Is on the PDA of Samuel J. Harding",
		"RE: Turret Gun Mounting Brackets	11-05-2145	Sam Harding	Karl Cullen	Is on the PDA of Samuel J. Harding",
		"ADS operational	10-23-2145	Gary Ross	Delta Security	Is on the PDA of Gary Ross",
		"RE: Airlock Request	11-04-2145	Gary Ross	Central Security	Is on the PDA of Gary Ross",
		"Site 2 Closure	10-11-2145	Charles Hollies	Yon Brady	Is on the PDA of Charles L. Hollies",
		"Remaining Ammunition	10-13-2145	Charles Hollies	Yon Brady	Is on the PDA of Charles L. Hollies",
		"Artifact Inventory Tracking	10-27-2145	Charles Hollies	Phil Wilson	Is on the PDA of Charles L. Hollies",
		"New Automated Defense System	10-06-2145	Karl Cullen	Security Authority	Is on the PDA of Karl P. Cullen",
		"RE: Turret Shutdown Codes	10-12-2145	Karl Cullen	Chris Baughman	Is on the PDA of Karl P. Cullen",
		"Extra Medical Supplies	11-02-2145	Karl Cullen	Lee Pommeroy	Is on the PDA of Karl P. Cullen",
		"Mounting brackets fixed	11-04-2145	Karl Cullen	Sam Harding	Is on the PDA of Karl P. Cullen",
		"Additional Security Needed	11-09-2145	Karl Cullen	Mathew Gaiser	Is on the PDA of Karl P. Cullen",
		"Did you get it?!!	11-13-2145	Robert Price	Price@kpjNET	Is on the PDA of Robert M. Price",
		"RE: You guys experiencing this?	11-14-2145	Robert Price	Ryan S.	Is on the PDA of Robert M. Price",
		"Reactor Safety	11-14-2145	Brian Mora	Delta Authority	Is on the PDA of Brian Karr Mora",
		"BECOME A BETTER PERSON NOW!	11-12-2145	Brian Mora	martianbuddy.com	Is on the PDA of Brian Karr Mora",
		"Anti psychotics shipment in today	11-09-2145	Peter Raleigh	Ben Peterson	Is on the PDA of Peter N. Raleigh",
		"Overburdened	11-12-2145	Peter Raleigh	Phil Wilson	Is on the PDA of Peter N. Raleigh",
		"The Soul Cube	11-14-2145	Yours	Research Archive	Is downloaded to your PDA from I. McCormick's laptop",
		"Baklava bake oven on sale now!	10-28-2145	Frank Cinders	martianbuddy.com	Is on the PDA of Frank A. Cinders",
		"your lunch	11-15-2145	Frank Cinders	Guy Harollson	Is on the PDA of Frank A. Cinders",
		"Patient work load	10-24-2145	Phil Wilson	Dr. Peter Raleigh	Is on the PDA of Phil W. Wilson",
		"Locker code change request	11-02-2145	Francis Erikson	Delta Security	Is on the PDA of Francis R. Erikson",
		"Martian Buddy Cabinet	10-30-2145	Francis Erikson	Corbin Hues	Is on the PDA of Francis R. Erikson",
		"Specimen 197 -Imp	11-15-2145	You	Research Archive	Download from stasis chamber 1 computer in Delta Labs Level 2B: Stasis Chamber",
		"Specimen 42 -Living Dead (partial)	11-15-2145	You	Research Archive	Download from stasis chamber 3 computer in Delta Labs Level 2B: Stasis Chamber",
		"Specimen 27 - Imp (partial)	11-15-2145	You	Research Archive	Download from stasis chamber 4 computer in Delta Labs Level 2B: Stasis Chamber",
		"Specimen 31 - Hellknight (partial)	11-15-2145	You	Research Archive	Download from stasis chamber 5 computer in Delta Labs Level 2B: Stasis Chamber",
		"Specimen 218 - Revenant	11-15-2145	You	Research Archive	Download from stasis chamber 6 computer in Delta Labs Level 2B: Stasis Chamber",
		"Storage Locker Update	11-13-2145	Jacob Stemmons	Thomas Franks	Is on the PDA of Jacob L. Stemmons",
		"RE: Power out!!	11-15-2145	Jacob Stemmons	Maintenance	Is on the PDA of Jacob L. Stemmons",
		"RE: Soul Cube as a Weapon	10-20-2145	Larry Bullman	Malcolm Betruger	Is on the PDA of Larry A. Bullman",
		"You may be a winner!	10-22-2145	Larry Bullman	martianbuddy.com	Is on the PDA of Larry A. Bullman",
		"Volunteer today	11-01-2145	Larry Bullman	HR Coordinator	Is on the PDA of Larry A. Bullman",
		"It is a weapon!	11-14-2145	Larry Bullman	Ian McCormick	Is on the PDA of Larry A. Bullman",
		"Investigation Report	10-02-2145	Liz McNeil	Elliot Swann	Is on the PDA of Elizabeth A. McNeil",
		"Storage room code changes	10-08-2145	Liz McNeil	Michael Abrams	Is on the PDA of Elizabeth A. McNeil",
		"Level 3 Access Code Change	10-15-2145	Liz McNeil	Michael Abrams	Is on the PDA of Elizabeth A. McNeil",
		"Immediate medical attention	10-23-2145	Frank Cerano	Delta Control	Is on the PDA of Frank Z. Cerano, Ph.D",
		"Assistance Request	11-02-2145	Frank Cerano	Delta Control	Is on the PDA of Frank Z. Cerano, Ph.D",
		"Security Team info	10-23-2145	Han Lee	Delta Security	Is on the PDA of Han M. Lee",
		"Teleportation Transfers	11-10-2145	Marten Shultz	Frank Cerano	Is on the PDA of Marten R. Shultz, Ph.D",
		"Ammunition Cabinet	11-05-2145	Jack Gilbert	Paul Downing	Is on the PDA of Jack Gilbert",
		"Expedition Expectations	08-06-2145	Simon Garlick	Heather Elaine	Is on the PDA of Simon R. Garlick",
		"Dimensional Uniqueness	08-06-2145	Simon Garlick	Earl Besch	Is on the PDA of Simon R. Garlick",
		"Re: Your application	xx-xx-2145	Joshua Hebert	Brian	Is on the PDA of Joshua P. Hebert",
		"Drowning in Debt?	xx-xx-2145	Joshua Hebert	Brian	Is on the PDA of Joshua P. Hebert",
		"Mars Research Post	09-23-2145	Elliot Swann	UAC Board	Is on the PDA of Elliot Swann",
		"Looking forward to your arrival	10-02-2145	Elliot Swann	Liz McNeil	Is on the PDA of Elliot Swann",
		"UAC Board Review	10-03-2145	Elliot Swann	Malcolm Betruger	Is on the PDA of Elliot Swann",
		"Drowning in Debt?	11-04-2145	Bruce Jackson	martianbuddy.com	Is on the PDA of Bruce L. Jackson",
		"Security Assignment	11-14-2145	Bruce Jackson	Michael Abrams	Is on the PDA of Bruce L. Jackson",
		"Valuable Sacrificial Pointers	11-16-2145	You	Research Archive	Download from the Information Terminal in Delta Complex: CPU Access",
		"Security Concerns	10-22-2145	Tony Bates	Brian Franko	Is on the PDA of Tony F. Bates",
		"Increase your Pen1s size!	11-04-2145	Tony Bates	Martian Buddy	Is on the PDA of Tony F. Bates",
		"Your Test Results	11-08-2145	Tony Bates	Edward Sorrenson	Is on the PDA of Tony F. Bates",
		"Mail Delivery Failure	11-13-2145	Charlie Haskell	System Admin	Is on the PDA of Charlie D. Haskell",
		"How have you been?	11-15-2145	Charlie Haskell	John Bianga	Is on the PDA of Charlie D. Haskell",
		"Storage Problem on Server	11-04-2145	Steve Tooloose	Henry Bielefeldt	Is on the PDA of Steve H. Tooloose",
		"BFG Info Video	11-14-2145	Steve Tooloose	Brian Franko	Is on the PDA of Steve H. Tooloose",
		"Discovery in the Ruins	08-10-2145	Richard Davis	Malcolm Betruger	Is on the PDA of Richard S. Davis, Ph.D",
		"Ruins safety concerns	10-16-2145	Richard Davis	Lowell Foshay	Is on the PDA of Richard S. Davis, Ph.D",
		"Tablets are language	11-10-2145	Richard Davis	Pierce Rogers	Is on the PDA of Richard S. Davis, Ph.D",
		"Scanner Results	10-17-2145	Pierce Rogers	Richard Davis	Is on the PDA of Pierce M. Rogers, Ph.D",
		"Thanks!	11-02-2145	Pierce Rogers	Timmy Rogers	Is on the PDA of Pierce M. Rogers, Ph.D",
		"The Soul Cube is the answer	11-15-2145	Pierce Rogers	Server: Mail Failure	Is on the PDA of Pierce M. Rogers, Ph.D",
		"Generator Repairs Needed	10-07-2145	Robert Cody	Maint. Control	Is on the PDA of Robert O. Cody",
		"Sorry old pal	10-08-2145	Robert Cody	Mark Robertson	Is on the PDA of Robert O. Cody",
		"Thanks	11-16-2145	Doom Player	Adrian Carmack	Is on the PDA of id Software",
		"Thank You	11-16-2145	Doom Player	John Carmack	Is on the PDA of id Software",
		"Thank You	11-16-2145	Doom Player	Kevin Cloud	Is on the PDA of id Software",
		"It's DONE bay-bee!	11-16-2145	Doom Player	Todd Hollenshead	Is on the PDA of id Software",
		"Thanks Everyone!!	11-16-2145	Doom Player	Tim Willits	Is on the PDA of id Software",
		"Special Thanks	11-16-2145	Doom Player	Christian Antkow	Is on the PDA of id Software",
		"Thanks..	11-16-2145	Doom Player	Timothee Besset	Is on the PDA of id Software",
		"DONE! WOOOO!	11-16-2145	Doom Player	Mal Blackwell	Is on the PDA of id Software",
		"Dirt	11-16-2145	Doom Player	Andy Chang	Is on the PDA of id Software",
		"Thanks	11-16-2145	Doom Player	Jim Dose	Is on the PDA of id Software",
		"I see you found the brick...	11-16-2145	Doom Player	Pat Duffy	Is on the PDA of id Software",
		"Martian Buddy Dot Com	11-16-2145	Doom Player	Robert Duffy	Is on the PDA of id Software",
		"Thank You	11-16-2145	Doom Player	Matt Hooper	Is on the PDA of id Software",
		"Thanks	11-16-2145	Doom Player	James Houska	Is on the PDA of id Software",
		"Thanks Everyone	11-16-2145	Doom Player	Donna Jackson	Is on the PDA of id Software",
		"Many thanks!	11-16-2145	Doom Player	Jerry Keehan	Is on the PDA of id Software",
		"Thank You	11-16-2145	Doom Player	Seneca Menard	Is on the PDA of id Software",
		"Thanks	11-16-2145	Doom Player	Fredrik Nilsson	Is on the PDA of id Software",
		"Thank You	11-16-2145	Doom Player	Steve Rescoe	Is on the PDA of id Software",
		"Mixom.co.jp order has shipped	11-16-2145	Doom Player	Kenneth Scott	Is on the PDA of id Software",
		"SUSPECTED SPAM: B1G TH4NK5	11-16-2145	Doom Player	Marty Stratton	Is on the PDA of id Software",
		"Thanks	11-16-2145	Doom Player	Patrick Thomas	Is on the PDA of id Software",
		"Did you find the MrE behind DOOM 3 ?	11-16-2145	Doom Player	JMP van Waveren	Is on the PDA of id Software",
		"Special Thanks	11-16-2145	Doom Player	Eric Webb	Is on the PDA of id Software",
		"Did somebody say free food?	11-16-2145	Doom Player	The Interns	Is on the PDA of id Software"];

	public static bodies:Array<string> = [
		"Welcome to Mars Marine.<br><br>I would like to take a brief moment of your time\n" +
		"and welcome you to what we call our 'home away from home'. We will make every\n" +
		"effort to make your stay here as pleasant as possible, and if you need\n" +
		"something, please feel free to contact your supervisor for immediate assistance.\n" +
		"I hope that you can find the time to take advantage of some of our great staff\n" +
		"amenities.  As a reminder, we engage in technical, highly secure testing and\n" +
		"research here, please refrain from wandering into unauthorized areas.  Any\n" +
		"security team member will assist you if help is needed.<br><br>Sincerely,<br>\n" +
		"Director William Banks<br>\n" +
		"\n"
,
		"Marine,<br>Report to Marine command as soon as possible.  Don't waste any time\n" +
		"getting here.  We're short on troops and I need you out on patrol as soon as\n" +
		"possible.<br><br>Sergeant Kelly<br>\n" +
		"\n"
,
		"Safety is critical to everything we do on Mars.  We will review all standard\n" +
		"safety protocols during the month of November - Safety Awareness month.<br><br>\n" +
		"\n" +
		"It is mandatory that you schedule time to attend each of these valuable\n" +
		"workshops.<br><br>\n" +
		"\n" +
		"Proper Plasma Handling: Review of the proper storage of plasma, how to handle\n" +
		"plasma leaks, and the best ways to treat plasma burns.<br><br>\n" +
		"\n" +
		"Emergency First Aid:  Learn the location and correct use for all of the Health\n" +
		"Packs placed throughout the facility.  Special attention will be given to waste\n" +
		"prevention methods. Health resources are squandered if a large pack is used when\n" +
		"only a small pack is needed.<br><br>\n" +
		"\n" +
		"Storage of Weapons and Ammo:  Special lecture on the safe storage of weapons and\n" +
		"ammo.  Fred Nilsson will be giving hands-on lessons in proper targeting and\n" +
		"strafing.<br><br>\n" +
		"\n" +
		"Emergency Loss of Environment:  This lecture will focus on what to do if you are\n" +
		"faced with an air leak, structural failure, or airlock failure.  Special care\n" +
		"will be paid to the locations of emergency air tanks.<br><br><br>\n" +
		"\n" +
		"See postings outside UAC HR for times and locations.<br><br>\n" +
		"\n"
,
		"Dr. Jonathan Ishii from the Delta Science Team is missing.  Security is\n" +
		"concerned that he may be injured and unable to respond to pages or return to his\n" +
		"post.  Please keep an eye out for Dr. Ishii and report to UAC Security if he is\n" +
		"located.<br><br>\n" +
		"\n" +
		"He was last seen in Mars City Underground approaching the airlock leading to the\n" +
		"old communications array.<br><br><br>\n" +
		"\n"
,
		"\n" +
		"Congratulations! You set a new high score in Super Turbo Turkey Puncher\n" +
		"3!!!<br><br>\n" +
		"\n" +
		"Your parents can rest easier knowing they have raised another shining example of\n" +
		"humanity.<br><br>\n" +
		"\n" +
		"Due to the incredible amount of time you wasted punching poor defenseless\n" +
		"turkeys, your vacation time has been docked two days.<br><br>Have a nice\n" +
		"day.<br><br>\n" +
		"\n"
,
		"\n" +
		"Delta Labs is looking for individuals to fill critical positions.  You can help\n" +
		"with our exciting break-through work and earn credits at the same time.<br><br>\n" +
		"\n" +
		"Volunteers are needed in the following areas:<br><br>\n" +
		"\n" +
		"Teleportation:  We're looking for test subjects to stress-test the new\n" +
		"teleporters.  Experience tomorrow's travel technology, today!<br><br>\n" +
		"\n" +
		"Medical Research:  Our advanced pharmaceutical team needs volunteers to\n" +
		"participate in exciting medical studies.  Help us find cures to everything from\n" +
		"the common cold to Ebola 7.<br><br>\n" +
		"\n" +
		"Specimen Handling: We need volunteers to help handle and track the specimens\n" +
		"gathered through Delta Labs experiments.  Health test and liability waiver will\n" +
		"be required.<br><br><br>\n" +
		"\n" +
		"For more information about any of these opportunities or to post openings in\n" +
		"your department, please see an HR Coordinator.<br><br><br>\n" +
		"\n"
,
		"Adam,<br><br>Please take more caution in securing your personal data\n" +
		"assistant. It's turned up in lost and found three times this month. As you know,\n" +
		"your base security clearances are stored on that and it becomes a serious\n" +
		"security issue any time a PDA is lost. Failure to secure your PDA could\n" +
		"eventually result in your termination.<br><br>Mr. Peterson<br>Maintenance\n" +
		"Supervisor\n" +
		"\n"
,
		"Adam,<br>Your report is long overdue.  Record an audio log for what you've\n" +
		"done today on your maintenance shift and send it to me immediately.  I know you\n" +
		"recoded the cabinet today, and it is imperative that I update my master list\n" +
		"with the new code.<br><br>I do not want to tell you again how important it is\n" +
		"to record those codes.<br><br>Mr. Peterson<br>Maintenance Supervisor\n" +
		"\n"
,
		"Mr. Berneche,<br><br>I have some great news for you, your name came up in\n" +
		"our last management meeting as a possible candidate for our Delta Labs outreach\n" +
		"program. As you have probably heard, we are conducting some very exciting and\n" +
		"important experiments here in Delta.<br>Your supervisor recommended you as a\n" +
		"good candidate and in fact he insisted you would be perfect for the job. I've\n" +
		"reviewed your file and I agree with his assessment, you will make a perfect test\n" +
		"subject.<br>Our work here is very important to the UAC and you should feel\n" +
		"privileged that you have a chance to be a part of it.<br><br>\n" +
		"\n" +
		"Mr. K. Clearsky<br>Delta Future Systems<br><br>p.s. Remember, volunteering is\n" +
		"the best choice!  I'd hate to invoke clause 12.A of section 543 of your\n" +
		"employment contract; which if you are not familiar with, allows us to appoint\n" +
		"volunteers.\n" +
		"\n"
,
		"Daniel,<br><br>I'm considering a promotion for you if you're interested.\n" +
		"I'm down an engineer in Reclamation and I think you would be the perfect\n" +
		"replacement.<br><br>Give it some thought over the next few days and let me know\n" +
		"what you think.<br><br>Frank Delahue,<br>Engineering Manager\n" +
		"\n"
,
		"You will not believe this... actually you probably will.  The late night\n" +
		"security detail couldn't figure out how to open the security cabinets from the\n" +
		"security office?!?!  I spent the next morning walking them through the steps in\n" +
		"the security panels.  They didn't realize that you could open both cabinets from\n" +
		"the one security panel.  Where do they find these people?<br><br>-Brooks\n" +
		"\n"
,
		"Unit Supervisor,<br><br>November Security Update for your area, UNDERGROUND\n" +
		"MAINTENANCE, has been completed.<br>Please update the security cabinet code in\n" +
		"the Energy Stabilization Unit to 531.<br><br>Thanks,<br>UAC Security\n" +
		"\n"
,
		"Hey Mark,<br>We had a new marine just come through the security checkpoint.\n" +
		"He's gonna check out the old comm building for that missing scientist.  Can you\n" +
		"make sure he doesn't get lost... last thing we need is another missing\n" +
		"person.<br><br>Thanks Bro<br>-Brooks\n" +
		"\n"
,
		"Yo Frank,<br>Don't forget that we're starting the party early this time...\n" +
		"8:15.<br><br>--BMora--\n" +
		"\n"
,
		"UAC Employee,<br><br>We have received your complaint and will consider your\n" +
		"request.  Safety is important to us and will do everything to insure security\n" +
		"throughout the UAC.<br><br>Sincerly,<br>UAC Security<br>----Original Message-\n" +
		"---<br>Sent: 11-12-2145<br>To: UAC Security<br><br>I'm becoming increasingly\n" +
		"concerned for the safety of myself and my co-workers down here in Underground.\n" +
		"We've had guys getting stuck outside of the airlock that leads to the old comm\n" +
		"building and there seems to be a build up of tension over the past few weeks.\n" +
		"I've seen friendly co-workers change...<br><br>I formally request additional\n" +
		"security as well as an investigation as to what's causing all the angst.<br>-\n" +
		"Frank Delahue\n" +
		"\n"
,
		"Marcus,<br>Those morons at Mixom have done it again.  I requisitioned a\n" +
		"crate of jackhammers.  What we got were several crates of chainsaws and no\n" +
		"jackhammers at all.  What the hell were they thinking?  Chainsaws serve no\n" +
		"purpose on Mars!<br><br>\n" +
		"\n" +
		"I'm sending these back as soon as I get the time.  Meanwhile, I would appreciate\n" +
		"it if you could lodge another complaint with Mixom and expedite my jackhammers\n" +
		"when they do arrive.<br><br>\n" +
		"\n" +
		"David Robbins<br>Industrial Complex\n" +
		"\n"
,
		"If you've received this e-mail, you are someone I worked with while here on\n" +
		"Mars and have made an impression on my life.  I want you to know I'm leaving\n" +
		"Mars and UAC to pursue other opportunities after one last assignment tomorrow in\n" +
		"Delta Labs.<br><br>\n" +
		"\n" +
		"I will miss you terribly and will think of you often.  I'm sure the friendships\n" +
		"I've made here will last the rest of my life and I will be sure to keep in\n" +
		"touch.  As soon as I have a new address I will forward it along.<br><br>\n" +
		"\n" +
		"I hope you will join me in the Mars City kitchen for a drink tomorrow evening.\n" +
		"My shuttle leaves first thing on 11/16 and I'd hate to miss my last chance to\n" +
		"see you... until next time.<br><br>Ray Gerhardt\n" +
		"\n"
,
		"Any idea who this guy is?  I think he may be the funny looking dweeb from\n" +
		"Alpha Labs, but I always thought his name was Alan.<br><br>\n" +
		"\n" +
		"Are you going to go have that drink with him?  I wouldn't normally... but if\n" +
		"he's buying and other people will be there, it might be fun.<br><br>----\n" +
		"Original Message----<br>Sent: 11-14-2145<br>To: Duncan Mathews<br><br>If\n" +
		"you've received this e-mail, you are someone I worked with while here on Mars\n" +
		"and have made an impression on my life.  I want you to know I'm leaving Mars and\n" +
		"UAC to pursue other opportunities after one last assignment tomorrow in Delta\n" +
		"Labs.<br><br>\n" +
		"\n" +
		"I will miss you terribly and will think of you often.  I'm sure the friendships\n" +
		"I've made here will last the rest of my life and I will be sure to keep in\n" +
		"touch.  As soon as I have a new address I will forward it along.<br><br>\n" +
		"\n" +
		"I hope you will join me in the Mars City kitchen for a drink tomorrow evening.\n" +
		"My shuttle leaves first thing on 11/16 and I'd hate to miss my last chance to\n" +
		"see you... until next time.<br><br>Ray Gerhardt\n" +
		"\n"
,
		"Mark,<br>Thank you for sending me the audio report.  I wish the news was\n" +
		"better.<br><br>\n" +
		"\n" +
		"I will coordinate with our colleagues here on Earth to find additional staff and\n" +
		"resources to assist.  Given the situation there, it isn't going to be easy.  You\n" +
		"may be on your own for a while.<br><br>\n" +
		"\n" +
		"My advice to you, continue to document everything you can, attempt to find the\n" +
		"root of the problem, and for goodness sakes, stash weapons and medical supplies\n" +
		"for yourself in case things get worse.  I'm glad to hear you've already taken\n" +
		"some precautions by locking some supplies in that cabinet.  I hope it is enough.\n" +
		"I appreciate you including the code in your report and will keep it\n" +
		"secure.<br><br>\n" +
		"\n"
,
		"Mark,<br><br>I hear that you're leaving Mars.  I'm happy that you'll be\n" +
		"able to spend more time with your family, but I am very worried about the\n" +
		"deteriorating situation here.  We're short staffed already.  Your departure will\n" +
		"be a big loss.<br><br>\n" +
		"\n" +
		"Speaking of which, I've noticed that Zach in Operations has been behaving more\n" +
		"and more erratically.  Since talking with you I'm not sure if I'm being overly\n" +
		"sensitive, or if he has caught the bug going around.  I swear he tried to bite\n" +
		"me today.<br><br>\n" +
		"\n" +
		"In your absence is there anything I should do?<br><br>-Sarah\n" +
		"\n"
,
		"Staff Sergeant Tyson,<br><br>I have been a major supporter of putting\n" +
		"Sentry Bots on routine patrols.  In the past, they have proven to be very\n" +
		"reliable.  That changed today.<br><br>I witnessed two of our guys doing\n" +
		"maintenance on a bot in Mars City.  While having it only partially assembled,\n" +
		"they activated its sensors to test their repairs.  All of the sudden, the Sentry\n" +
		"locked onto one of the marines as an enemy and began firing at him.  If the ammo\n" +
		"had been loaded, that marine would have been killed.  Thankfully the other\n" +
		"marine was able to immobilize it quickly.<br><br>The Sentry Bot is powerful -\n" +
		"but maybe too powerful.  Why do we need that much firepower in Mars City?\n" +
		"Except for the odd report of individuals going a little crazy, we don't have\n" +
		"many problems.  Do we really expect the Sentry Bots to know the difference\n" +
		"between someone having a bad day and someone gone mad?<br><br>I hope you can\n" +
		"talk to Sarge about this before something worse happens.<br>-PFC Hagar\n" +
		"\n"
,
		"SSgt Tyson,<br>We have a new troop inbound to replace Allen. He will be\n" +
		"arriving on the next transport.<br><br>Be sure to get the manifest filled out\n" +
		"for his first issue.<br>Sergeant Kelly\n" +
		"\n"
,
		"SSgt Tyson,<br>Due to the upcoming personnel transfer, we will be changing\n" +
		"the Weapons Storage code in Marine Command to 584.  The change will take effect\n" +
		"on Nov 15, 2145.<br>Thank You,<br><br>Central Security\n" +
		"\n"
,
		"If you've received this e-mail, you are someone I worked with while here on\n" +
		"Mars and have made an impression on my life.  I want you to know I'm leaving\n" +
		"Mars and UAC to pursue other opportunities after one last assignment tomorrow in\n" +
		"Delta Labs.<br><br>\n" +
		"\n" +
		"I will miss you terribly and will think of you often.  I'm sure the friendships\n" +
		"I've made here will last the rest of my life and I will be sure to keep in\n" +
		"touch.  As soon as I have a new address I will forward it along.<br><br>\n" +
		"\n" +
		"I hope you will join me in the Mars City kitchen for a drink tomorrow evening.\n" +
		"My shuttle leaves first thing on 11/16 and I'd hate to miss my last chance to\n" +
		"see you... until next time.<br><br>Ray Gerhardt\n" +
		"\n"
,
		"We understand the pressures and concerns of civilians in Mars City.  I want\n" +
		"to assure you that there is nothing to worry about.  We in UAC Security have\n" +
		"everything under control.<br><br>\n" +
		"\n" +
		"I've left the armor you requested on your desk.  I hope this helps you sleep a\n" +
		"bit easier.  We will not be able to fulfill your request for a plasma gun at\n" +
		"this time.  I would like to take this opportunity to remind you that civilians\n" +
		"are not allowed the possession of weapons.  If you ask again, I will have no\n" +
		"choice but to report you to UAC HR.<br><br>\n" +
		"\n" +
		"If you do happen to find equipment, it is your duty to return it to UAC Security\n" +
		"immediately.<br><br>Sincerely,<br>UAC Security<br>----Original Message----\n" +
		"<br>Sent: 11-15-2145<br>To: UAC Security<br><br>\n" +
		"\n" +
		"Please requisition to my department armor and one plasma gun with ammo.  With\n" +
		"all that has been happening around here, I don't feel safe.  I haven't been able\n" +
		"to sleep and it has been difficult to work.<br><br>\n" +
		"\n" +
		"If it would be easier, I could always just help myself to some of the armor I've\n" +
		"seen security leave carelessly around the base.\n" +
		"\n"
,
		"Alan,<br>Per our earlier conversation I've stocked the storage cabinet in\n" +
		"your area with some ammunition and security armor. The door code is 586. I don't\n" +
		"know what's going on around here but we can't be too careful. I hope to God we\n" +
		"never have to use that stuff.<br><br>If I hear anything else I'll email\n" +
		"you.<br>Ethan\n" +
		"\n"
,
		"Alan,<br>\n" +
		"\n" +
		"I heard that UAC Corporate is sending someone up here to check on Betruger. His\n" +
		"name is Elliot Swann, I don't know who he is but John said he's a real ball\n" +
		"buster. He said that he always travels with a bodyguard. This can't be a good\n" +
		"situation. I hope this Swann guy doesn't shut us down. We are so close to\n" +
		"solving the stability problems and the transfer errors are exceptionally low\n" +
		"now.<br><br>I'll keep you posted if anything goes down.<br>\n" +
		"\n" +
		"Ethan\n" +
		"\n"
,
		"Mr. Dorweiler,<br><br>Online Pharmacy - No Doctor visit needed.<br><br>We\n" +
		"believe that ordering medication should be as simple as ordering anything else\n" +
		"on the Net. We can ship drugs anywhere in the galaxy....<br>Embargos, Banned\n" +
		"Pharmaceuticals, NO PROBLEM!!<br><br>Goto:\n" +
		"<a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">\n" +
		"www.martianbuddy.com</a> for my details.\n" +
		"\n"
,
		"Mr. Moses,<br><br>Congratulations, You have been selected to receive one of\n" +
		"our free promotional storage cabinets loaded with useful things that we think\n" +
		"you'll need. To access your new storage cabinet visit us on-line.<br><br>\n" +
		"Martian Buddy<br>---and remember: You're always a winner with Martian Buddy!\n" +
		"\n"
,
		"Mr. Moses,<br><br>We've recently received your storage cabinet from Martian\n" +
		"Buddy, where would you like us to put it?<br><br>Thank You,<br>Jim\n" +
		"Bowier<br>Shipping Authority\n" +
		"\n"
,
		"Mr. Moses,<br>Thank you for the quick reply, we will have it delivered to\n" +
		"your office by the end of the week.<br><br>----Original Message----<br>Sent:\n" +
		"11-07-2145<br>To: Jim Bowier<br>Excellent, I can't believe it showed up so\n" +
		"soon. Please have someone deliver it to my office in the North Hallway of\n" +
		"Administration. I can't wait to see what they've sent me.<br><br>-Jonathan\n" +
		"\n"
,
		"\n" +
		"Director Banks, I need your urgent attention on this matter.<br><br>It is very\n" +
		"important to my research that all crates containing religious artifacts arriving\n" +
		"from earth, with a co-sign for Delta Labs, go directly there with no\n" +
		"interruption or delay.  I can't have my precious cargo sitting around the hangar\n" +
		"waiting for your personnel to process it.<br>I expect your immediate attention\n" +
		"on this matter.<br><br>Dr. Malcolm Betruger\n" +
		"\n"
,
		"\n" +
		"Director Banks,<br><br>Research Assistant Eric Raffel has not reported for work\n" +
		"all this week.  I've called his quarters repeatedly without response.  No one\n" +
		"has seen him in days and I don't know what to do, he is normally a very reliable\n" +
		"individual.  I've contacted Mars Security with hope that they will try and find\n" +
		"him.  There aren't a lot of areas to go on this base... man, I hope he's\n" +
		"alright.<br>If I hear anything I'll send word.<br><br>Thank You,<br>Matthew\n" +
		"White\n" +
		"\n"
,
		"\n" +
		"Director Banks,<br><br>We're happy to report the roll out of the new coporate\n" +
		"web page.  Please take a moment of your time to review it.<br><br>Thank\n" +
		"You,<br>UA-Corp.com Web Team\n" +
		"\n"
,
		"Mr. Simons.<br><br>\n" +
		"\n" +
		"We have been unable to find any problems with the network test that we ran on\n" +
		"your system. Everything checks out on our end, your firewall seems to be\n" +
		"functioning properly. Other IT managers have reported similar intrusions in\n" +
		"their networks. Whomever is doing this must be good or someone with very high\n" +
		"level security clearance. We will continue to scan the network and if anything\n" +
		"odd appears we will inform you immediately.<br><br>\n" +
		"\n" +
		"Central IT Security\n" +
		"\n"
,
		"Paul-<br>The email spam lately is getting out of control. The worst company\n" +
		"is that Martian Buddy place -I can't believe all the things they send me. I\n" +
		"don't know how they got my email address. Can't you do anything to stop the\n" +
		"spam? I spend all morning deleting junk from my inbox.<br><br>Thank\n" +
		"You,<br>Russ\n" +
		"\n"
,
		"Dr. Berger,<br><br>Everything is going exceptionaly well as we prepare the\n" +
		"EPD for its next round of tests.  Roy Patterson is setting up the baseline now\n" +
		"and we should be able to start testing later this afternoon.  If your schedule\n" +
		"allows please feel free to join us down here in the lab around 2:00.  Should be\n" +
		"exciting!<br><br>Bill Landow<br>\n" +
		"\n"
,
		"Dr. Kyle Berger,<br><br>The maintenance team has just finished cleaning the\n" +
		"EPD collection chamber.  All bio matter has been scrubbed from the area.  Deep\n" +
		"scan also confirms a clean sector as per your request.  14 collection cones and\n" +
		"5 trays have also been replaced.  Please be sure to stop by Maintenance as we\n" +
		"will need your signature for the parts requisition forms.<br><br>Thank You,<br>\n" +
		"UAC Maintenance\n" +
		"\n"
,
		"Mr. Smith,<br><br>In light of the recent increase of work-related accidents\n" +
		"in the Alpha Labs, we feel it is necessary to implement a buddy system when\n" +
		"routine maintenance is being performed on some of the heavier equipment. We feel\n" +
		"this should cut down on some of the claims being filed in your department.\n" +
		"Please get back to me if you have any other ideas on how to keep Alpha Labs as\n" +
		"safe as it can be.<br><br>Thank You,<br>Brian Jenkins<br>Safety Coordinator\n" +
		"\n"
,
		"Jack-<br>Please tell me UAC Corporate is kidding.  How the hell is a buddy\n" +
		"system going to do anything with accident claims except double them?  Tell me\n" +
		"how a buddy system would have prevented Joe 'TorsoBoy' Moss from having his arms\n" +
		"and legs hacked off by the Albuquerck Capacitator? Maybe his buddy would have\n" +
		"heard the thing growl and engage without power or a CRF module?  Maybe his\n" +
		"buddy's hair would have burst into flames instead and saved us the trouble of\n" +
		"needing to run and find water to put him out.<br><br>\n" +
		"\n" +
		"Next thing they'll do is try to fix the problem by having us fill out more MAR\n" +
		"forms or reforecast our ODF.<br><br>\n" +
		"\n"
,
		"George<br><br>I seem to have misplaced my PDA with my security clearance.\n" +
		"I was adjusting the oxygen intake on the Hydrocon the other day and recall using\n" +
		"it at that time but I can't seem to remember where the heck I put that thing.\n" +
		"If you see it around while on your shift could you put it someplace safe for\n" +
		"me?<br>Thank you,<br><br>Dr. Harvey<br>\n" +
		"\n"
,
		"\n" +
		"Hey Bern,<br><br>Some of the boys and I are headed over to Mars City tonight\n" +
		"after the shift for some beers.  You, as always, are welcome to come along with\n" +
		"us, buddy.  I WILL see you there!<br><br>John<br><br>\n" +
		"\n"
,
		"Lipsitz,<br><br>A siphon hose in the oxygen displacement valve is forming a\n" +
		"leak due to the lowered temperatures required to boost production in the\n" +
		"Hydrocon Labs.  If this hose were to rupture, it would cause severe instability\n" +
		"in the Hydrocon systems and could result in a very dangerous situation.  It\n" +
		"should really be replaced.<br><br>Dr. Anubus<br>\n" +
		"\n"
,
		"\n" +
		"On-Line Gamer,<br><br>The quest for the Chalice of Sanguine Drak begins now!\n" +
		"The evil Vloxomire has cast a dark cloud of evil across the lands of Nozgath .\n" +
		"Who will rise above and challenge his legion of Wyldwulves and\n" +
		"Berserkotrolls?<br><br>Raise your scimitars aloft and strike back! Join Uanet's\n" +
		"hottest RPG DracoWyzard today for only 29 credits per month!<br><br>\n" +
		"\n"
,
		"Dear Chin,<br><br>I am writing in regard to the 8 sided die you borrowed\n" +
		"from me.  If you are not going to invite me to the game, then I would like my 8\n" +
		"sided die back.  It has been 2 weeks and I am very irritated.<br><br>Walter\n" +
		"Connors\n" +
		"\n"
,
		"Andy,<br><br>Just got in.  That ride sucked.  I flew in with the new\n" +
		"systems analyst, Jaime Mendoza.  He was always getting into it with these two\n" +
		"security goons, Jim Daly and Mark Walters.  They would argue for hours (and\n" +
		"sometimes days) about ANY topic.  A month and a half in that sardine can will\n" +
		"make anyone bug out after a while.<br><br>Anwyay, let's get some lunch.  I need\n" +
		"some real food!<br><br>-Erik\n" +
		"\n"
,
		"Mr. Chin,<br>The repairs to storage cabinet 038 have been completed.  The\n" +
		"door is no longer stuck and we pulled the dent from the side.  It would be a\n" +
		"good idea in the future to remember your cabinet codes instead of trying to\n" +
		"force it open with a wrench.  Just for your records the new code for cabinet\n" +
		"#038 is 409, might be a good idea to write it down.<br><br>Maintenance\n" +
		"\n"
,
		"\n" +
		"Note to self:<br>Need to remember to get my eight-sided die back from Chin.\n" +
		"Should never have lent it to him in the first place.  He never invited me to\n" +
		"play.  He is not my friend.<br>\n" +
		"\n"
,
		"\n" +
		"Note to self:<br>Need to remember my code for cabinet #039 - 102<br>\n" +
		"\n"
,
		"\n" +
		"Walter,<br><br>I wanted to personally thank you for your latest modification\n" +
		"proposal for the dilation matrix.  The boys here at CPU have crunched your\n" +
		"numbers and after some deliberation we've agreed that this is the most\n" +
		"fantastically bogus theory that we've ever seen.<br><br>If you are remotely\n" +
		"interested in not getting your gnome-loving dragon-slaying power-leveling ass\n" +
		"fired, I'd suggest you lay off the role-playing and learn some basic\n" +
		"math.<br><br>Good luck on your next review :)<br>-Derek\n" +
		"\n"
,
		"Mr Lamia,<br><br>I am not feeling very well today.  I don't think I will be\n" +
		"able to come in to work today.  Could you get someone to cover my shift?<br><br>\n" +
		"Thank you,<br>George Poota\n" +
		"\n"
,
		"Mr. Lamia,<br><br>What is the code to the storage lockers here?  I know you\n" +
		"told me a few days ago, but I can't remember.  None of my usual codes\n" +
		"work.<br><br>\n" +
		"\n" +
		"By the way, I believe I'm going to be late with my Q4 Analysis.  I had a late\n" +
		"start this morning due to that party in Mars City.  I should have known better\n" +
		"than to drink that much during the week.<br><br>Graham Fuchs\n" +
		"\n"
,
		"Mr. Lamia,<br><br>I am feeling under the weather again today, and have an\n" +
		"appointment with the base physician this afternoon and won't be able to make my\n" +
		"shift.  Could you find someone to cover for me?<br><br>Thank you,<br>George\n" +
		"Poota\n" +
		"\n"
,
		"Mr. Lamia,<br><br>\n" +
		"\n" +
		"I just want to tell you again how happy I am to be working here on Mars in your\n" +
		"department.  I don't usually go on like this, but I'm just so excited by this\n" +
		"opportunity.  My last employer didn't appreciate my skills the way you\n" +
		"do.<br><br>\n" +
		"\n" +
		"I've taken the crates from your office to your quarters as you requested and I\n" +
		"organized your closet to your specifications.  I'd like to add that you have\n" +
		"excellent taste in music.<br><br>\n" +
		"\n" +
		"Once again, please let me know if there is anything I can do to better serve the\n" +
		"UAC.<br><br>\n" +
		"\n"
,
		"George,<br><br>It has come to my attention yet again, that you did not show\n" +
		"up for your shift on Wednesday.  This is the fourth time this month that you\n" +
		"have missed a shift.  I am getting tired of your pathetic excuses.  You had two\n" +
		"prostate exams last month and I will not fall for that excuse again!  Consider\n" +
		"yourself on notice, Mr. Poota.<br><br>Mark Lamia<br>Operations Chief Alpha\n" +
		"Labs, Sector 3\n" +
		"\n"
,
		"Tired of your boss getting you down?  Do you want to make your own hours?\n" +
		"Looking for the chance to double or even triple your income?!!  Well here's your\n" +
		"big opportunity!  It's as easy as logging onto\n" +
		"<a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">\n" +
		"www.martianbuddy.com</a>! Come find out how easy it is to work from home making\n" +
		"the BIG$$$$!<br><br>\n" +
		"<a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">\n" +
		"www.martianbuddy.com</a>\n" +
		"\n"
,
		"Dr. Kaczynski,<br><br>I checked the chamber calibration as you requested,\n" +
		"and at first found everything to be within acceptable limits.  As I was leaving\n" +
		"the chamber though, I started to hear a low whispering sound that seemed to be\n" +
		"eminating from the main coupler.  Though when I stooped down for a closer look\n" +
		"the sound stopped.  This happened every time I was leaving the chamber.  You may\n" +
		"want to check into this matter yourself as I can't seem to pinpoint the source\n" +
		"of the sound.  As far as everything else is concerned, it all seems to check\n" +
		"out.  All graphs are running at 2.4 or higher so I don't foresee any other\n" +
		"problems.<br><br>David Carter<br>\n" +
		"\n"
,
		"\n" +
		"Good Day<br><br>I am DR.John Okonkwo. Civil Servant in the Ministry of Health.\n" +
		"I know this proposal will come to you as a surprise because we have not met\n" +
		"before either physically or through correspondence. I got your contact from our\n" +
		"chamber of commerce here in Nigeria and have no doubt in your ability to handle\n" +
		"this proposal involving huge sum of money. My father Chief Isama OKONKWO. (Now\n" +
		"Late) was the Royal Head of my community, ELEME (an oil rich town) in Nigeria.\n" +
		"My community produces 5.8% of the total crude oil production in Nigeria and 0.5%\n" +
		"of the Dollar value of each barrel is paid to my father as royalty by the\n" +
		"Federal Government. My father was also the Chairman of ISAMA Special Oil Trust\n" +
		"Fund. In his position as the Royal head and Chairman of the Oil Trust Fund, he\n" +
		"made some money which he left for me as the only heir to inherit.<br><br>The\n" +
		"money is Eighteen Million, Five Hundred Thousand US Dollars(US$18.5). This Money\n" +
		"originated from the accumulated royalties between 1976-1998 . Due to poor\n" +
		"banking system in Nigeria and political instability as a result of past Military\n" +
		"rules (1985-1999) , he deposited this Money in a Strong Room/ Just before my\n" +
		"father died he called my attention to the money and charged me to look for a\n" +
		"foreigner who would assist me in the transfer/investment of the funds abroad. So\n" +
		"l would be very grateful if you could accept to help me achieve this great\n" +
		"objective. I promise to give you 25% of the total funds transferred to your\n" +
		"vital bank account as compensation for your assistance. Five percent (5%)has\n" +
		"been set aside to take care of all expenses we may incur during the transaction.\n" +
		"To indicate your interest, contact me urgently and confidentially for more\n" +
		"information and the roles you will play in this business.<br><br>All the legal\n" +
		"Documents concerning this Money will be sent to you as soon as we agreed\n" +
		"together. Please I will appreciate that you send your telephone/fax numbers to\n" +
		"enable me contact you immediately for discussions.<br><br>Yours\n" +
		"faithfully,<br><br>John Okonkwo<br>\n" +
		"\n"
,
		"Mr. Abrams,<br><br>We've been getting a lot of reports about strange noises\n" +
		"(voices?) down here.  My team is on edge and most are unable to work.  It might\n" +
		"help if you could send a security detachment to Alpha Sector 4 to do a sweep of\n" +
		"the area.<br><br>I know this sounds crazy but it would make myself and my team\n" +
		"feel a lot better.<br><br>Henry Nelson\n" +
		"\n"
,
		"Security Chief Abrams<br><br>As you requested we are sending more security\n" +
		"guards to your security unit. The events of Gormen's death were tragic and we\n" +
		"hope that any future incidents can be dealt with safely.<br><br>Expect the new\n" +
		"rotation of personnel in about 10 working days.<br><br>Director Kellsie\n" +
		"Krisch<br>Human Resources....'We make working on Mars feel like home'\n" +
		"\n"
,
		"Mr. Abrams,<br><br>We are unable to process your door code request because\n" +
		"you included a 0 in the number sequence and as you should have known 0 is not a\n" +
		"valid number on door codes. Because of the importance of your request we've\n" +
		"decided to take it upon ourselves and replace the 0 with a 3.<br>Please advise\n" +
		"if this causes a problem.<br><br>I.T. Security\n" +
		"\n"
,
		"Henry,<br><br>I haven't slept in three days and can't concentrate on work.\n" +
		"Ever since Hal's 'accident' everyone's been pretty freaked out.  I really\n" +
		"thought the guys saying they heard voices were full of shit, but lately I've\n" +
		"been hearing them too.  I don't know how UAC expects us to work like\n" +
		"this.<br><br>J.\n" +
		"\n"
,
		"Henry,<br><br>The tools in storage cabinet 064 were missing again today.\n" +
		"I'm going to change the combination to 651 this afternoon.  Please do me a favor\n" +
		"and don't tell Seneca the new combination.  I believe he's the one taking my\n" +
		"tools all the time...the guy thinks he's a real riot.<br><br>Thanks,<br>Greg\n" +
		"\n"
,
		"New Clone Porn movies posted daily!  Is this the new taboo?  Come and see\n" +
		"what everyone is talking about.  See gorgeous identical clones in\n" +
		"action.<br><br>\n" +
		"\n" +
		"Only 15 credits per month!<br><br>\n" +
		"\n"
,
		"All Staff,<br><br>As a reminder, tomorrow is our Ice Cream Social in the\n" +
		"Mars City Kitchen.  We will have the typical flavors of vanilla, chocolate,\n" +
		"strawberry, blueberry... and a few surprises.<br><br>\n" +
		"\n" +
		"I really hope you'll be at this event.  The Social Committee is working hard to\n" +
		"find fun things for us to do on our meager budget.  It is a real downer when\n" +
		"people don't show up.<br><br>\n" +
		"\n" +
		"I mean, c'mon... only 7 of you came to our pizza social last month.  If you've\n" +
		"got better ideas for the Social Committee, please forward them along.<br><br>\n" +
		"\n" +
		"We hope to see you tomorrow!<br><br>\n" +
		"\n"
,
		"Theresa,<br>Please take a few minutes in the next day or so to create an\n" +
		"audio report based on your plasma storage findings. The board is looking forward\n" +
		"to hearing about your accomplishments.<br><br>As you know, security has been\n" +
		"tight lately with all the accidents and as a result we can't be too careful.\n" +
		"Please make sure you lock up your test weapon and add the cabinet code into your\n" +
		"report.<br><br>Great Job,<br>Lloyd\n" +
		"\n"
,
		"\n" +
		"Theresa,<br>Hope you like your new office.  I think you'll find it much easier\n" +
		"to concentrate on the plasma gun project without those dweebs in engineering\n" +
		"drooling all over you.  How pathetic, right?<br><br>Anyway I'll be down there\n" +
		"from time to time if you ever want to talk or get something to eat.  You're\n" +
		"probably sick of hearing it, but I think seeing you every day is more valuable\n" +
		"to me than any amount of money I've gotten from working here.  Believe me, being\n" +
		"a supervisor I make a lot!<br><br>Your pal,<br>Lloyd\n" +
		"\n"
,
		"-T.<br>I know what you're talking about, when I was still there I felt like\n" +
		"I was the only woman surrounded by a bunch of drooling men. I miss the work but\n" +
		"I don't miss that place, I feel like I'm lucky to be out of there.<br><br>Keep\n" +
		"your eyes open for trouble; I don't trust some of the executives up\n" +
		"there.<br><br>I can't wait to see you back on Earth when your research is\n" +
		"completed.<br><br>Your Friend,<br>Liz<br><br>----Original Message----<br>\n" +
		"Sent: 11-07-2145<br>To: Elizabeth McNeil<br><br>Liz,<br>Lloyd has really\n" +
		"outdone himself this time.  He's moved me into the office next to the trench in\n" +
		"Enpro.  Does he really think by moving me to the most remote office on this\n" +
		"wretched base that I'll be more attracted to him? Wait...it might be working.\n" +
		"Being isolated for this long is getting to me.  I'm a chatter-box whenever I get\n" +
		"the chance to talk to a real person.<br><br>Ok, I've got to get back to\n" +
		"work.<br>-T.\n" +
		"\n"
,
		"Steve,<br>I'm still shaken from that incident with Swensen.  When I close\n" +
		"my eyes I still see that look on his face in the instant between the impact of\n" +
		"the plasma, and when his head turned to blue vapor.  I don't think I'll ever be\n" +
		"able to sleep again.<br><br>\n" +
		"\n" +
		"I never liked him, I don't know who was crazy enough to give him a plasma gun,\n" +
		"but no one should go that way.  The worst of it is I've seen a lot of folks who\n" +
		"seem more on edge than Swensen was.  Something needs to be done.<br><br>\n" +
		"\n" +
		"We should lock up the ammo in one of the storage lockers for starters.  You pick\n" +
		"the code.  Make sure you file a report too.<br><br>\n" +
		"\n"
,
		"guys..... this isn't a chain letter, but a choice for all of us to save a\n" +
		"little girl that's going to die of a serious and fatal form of cancer.  Please\n" +
		"send this to everyone you know...or don't know at that.  This little girl has 6\n" +
		"months left to live her life, and as her last wish, she wanted to send a chain\n" +
		"letter telling everyone to live their life to fullest, since she never will.\n" +
		"She'll never make it to prom, graduate school, or get married and have a family\n" +
		"of her own. By you sending this to as many people as possible, you can give her\n" +
		"and her family a little hope, because with every name that this is sent to, the\n" +
		"UAC will donate 3 credits per name to her treatment and recovery plan.  One guy\n" +
		"sent this to 500 people!!!!  So, I know that we can send it to at least 5 or 6.\n" +
		"Come on you guys.... and if you're too selfish to waste 10-15 minutes and\n" +
		"scrolling this and forwarding it to EVERYONE, just think it could be you one\n" +
		"day....and it's not even your credits, just your time. Please help this little\n" +
		"girl out guys, I know you can do it!! I love you guys!\n" +
		"\n"
,
		"Hey James,<br>What are we gonna do with all those chainsaws?  We've got two\n" +
		"shipments of excess medical equipment coming in very soon and we're gonna need\n" +
		"the space.  I can't believe someone mis-shipped those things.  I can't think of\n" +
		"a more useless piece of equipment then a chainsaw on Mars!?!  Anyway you gotta\n" +
		"help me get rid of these Beavertooths.<br><br>Thanks,<br>-RR\n" +
		"\n"
,
		"The UAC has terminated all research at site 1.  This includes the McNeil\n" +
		"Projects.  Arrangements for transportation and storage of useful equipment\n" +
		"should be made immediately.<br><br>-Shipping Authority\n" +
		"\n"
,
		"Ron,<br><br>We need you to enforce Maintenance Lift Access Restrictions\n" +
		"from this point forward.  Several of these lifts can be used to gain entry into\n" +
		"areas that are off-limits to the personnel that might only have maintenance\n" +
		"access.  Please see to it that this is enforced.<br><br>Sincerely,<br>UAC\n" +
		"Authority\n" +
		"\n"
,
		"Ron,<br>I don't have any idea what to do with those things.  Why don't you\n" +
		"throw them down the chasm near enpro like you did that other shit?<br><br>-\n" +
		"James<br><br>----Original Message----<br>Sent: 11-13-2145<br>To: James\n" +
		"Holiday<br><br>Hey James,<br>What are we gonna do with all those chainsaws?\n" +
		"We've got two shipments of excess medical equipment coming in very soon and\n" +
		"we're gonna need the space.  I can't believe someone mis-shipped those things.\n" +
		"I can't think of a more useless piece of equipment then a chainsaw on Mars!?!\n" +
		"Anyway you gotta help me get rid of these Beavertooths.<br><br>Thanks,<br>-RR\n" +
		"\n"
,
		"\n" +
		"Order Number: M8909848<br>Receipt Date:  10/10/2145<br>Order Total:\n" +
		"$269.83<br>Billed To:       Universal Credit<br><br>Crater Classic Music\n" +
		"thanks you for your order<br><br>Those who purchased your selection (Champagne\n" +
		"Tomiko by The ZSecs)<br>also purchased:<br>The Head On My Tongue - Hellish\n" +
		"Dragons<br>20th Century Classics - Various Artists<br><br>Please retain for\n" +
		"your records<br><br>The entire Crater team thanks you\n" +
		"\n"
,
		"Hey Seamus...<br><br>What the hell is up with the Sentry Bots that are\n" +
		"stationed in Engineering.  I thought you told me this was a better place to work\n" +
		"than Admin.  Why do you get to hang around the Main Communications room while\n" +
		"I'm stuck down here?<br><br>later,<br>-Karl\n" +
		"\n"
,
		"Dear Radar Enthusiast,<br><br>You're being offered a one time exclusive\n" +
		"opportunity to become part of the most exciting subject in the Solar System,\n" +
		"Radar Technology.  Please take this opportunity to save 20% off of our normal\n" +
		"prices.<br><br>If you feel this is for you, please respond immediately.<br><br>\n" +
		"Martian Buddy - Where you save!!\n" +
		"\n"
,
		"Your request for Communications Security Access is granted.  Officers are\n" +
		"now allowed access to Level 2 Security areas.  Communication Cabinet Code is\n" +
		"246.<br><br>----Original Message----<br>Sent: 11-11-2145<br>To: Security\n" +
		"Authority<br><br>I hereby formally request access to secure areas in the\n" +
		"Communications Facility.  As an officer I feel this is necessary.<br><br>\n" +
		"Sincerely,<br>Ben Wolfe\n" +
		"\n"
,
		"Finchy,<br><br>Don't forget that Trivia Quiz Night is this Wednesday.  Do\n" +
		"yourself a favor and brush up on the Shakespeare, mate.  If we lose to those\n" +
		"twits in administration again, we'll never hear the end of it.<br><br>Brent\n" +
		"\n"
,
		"Hey Bro,<br>Mom told me you were heading home in a week or so... I bet you\n" +
		"can't wait for that, can ya?<br><br>I've been pretty busy down here... Little\n" +
		"Annie is almost 9 now.  Can you believe that?  Dianne and I are thinking about\n" +
		"another one soon, we're gonna have to move into a double-wide orbiter :)<br><br>\n" +
		"Anyway, I won't keep ya... see ya soon<br>Flapper\n" +
		"\n"
,
		"Nicholas,<br><br>I've redirected control for the main pump to the flow service \n" +
		"terminal located on the maintenance platform above this area. If the main pump \n" +
		"should crack or malfunction and cause a spill the only way to shut it down would \n" +
		"be from there. Entry into the pump room would just be too dangerous. If you have \n" +
		"any questions please see me.<br><br>Thank You,<br>Henry\n" +
		"\n"
,
		"Mr. Sadowayj,<br><br>I fear that the rumors I've been hearing might actually be \n" +
		"true. The other day someone from my team was cleaning up a toxic spill in Delta \n" +
		"and they said they actually saw some sort of creature down there, he didn't know \n" +
		"what it was but it really scared him and got us thinking.<br>We can't be too \n" +
		"careful; I've locked some ammunition and combat armor in the cabinet by the \n" +
		"monorail airlock door here in Sector 2. If you ever need to get in it the code \n" +
		"is: 483<br><br>-Scott\n" +
		"\n"
,
		"Tony-<br>I'm sending you one of the new rocket launchers that we've received \n" +
		"from the marines. Please take some of the surplus toxic barrels lying around \n" +
		"outside and blow them up. Be careful not to hurt yourself - the area damage is \n" +
		"much larger than previous rocket launcher models.<br><br>Thank You,<br>Michael \n" +
		"Abrams\n" +
		"\n"
,
		"\n" +
		"I put in a request for more rocket ammo, I didn't realize how many barrels we\n" +
		"needed destroyed.<br><br>----Original Message----<br>Sent: 10-31-2145<br>To:\n" +
		"Michael Abrams<br><br>Mr. Abrams,<br>Thank you for the rocket launcher but I\n" +
		"will need more than the pre-installed five rockets for it. We have quite a few\n" +
		"barrels here that need to be destroyed. I've placed it on my weapons rack for\n" +
		"now and will begin destroying the barrels once I have more ammo.<br><br>Thank\n" +
		"You,<br>Tony\n" +
		"\n"
,
		"Dear Valued Customer,<br><br>Are you tired at work often?<br>Do you feel\n" +
		"like you have less energy?<br>Introducing the new and improved Sleep Away Pill.\n" +
		"Now better than ever the new Sleep Away Pill will keep you feeling refreshed and\n" +
		"going strong all day.<br>For more information visit:\n" +
		"<a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">\n" +
		"www.martianbuddy.com</a>\n" +
		"\n"
,
		"Jeff,<br>The main waste processing unit here in Sector 1 is running at a\n" +
		"higher pressure level than it was rated for. I'm very concerned about the\n" +
		"stability of the whole system. One fault or stress fracture in any of the lines\n" +
		"could cause doom for the whole mechanism. This processing system here was never\n" +
		"designed to process that much waste but they keep shipping it to us.<br>We\n" +
		"urgently need a new pump here before this one fails.<br><br>Thank You,<br>Henry\n" +
		"\n"
,
		"Mr. Moen,<br><br>It is very important that you remember to store toxic\n" +
		"waste barrels in their proper location.  Having volatile substances so close to\n" +
		"where people work is very dangerous, especially around doorways and narrow\n" +
		"passages.  If one of those barrels exploded it could cause a lot of damage and\n" +
		"someone might be seriously hurt, or worse - killed!  Please address this problem\n" +
		"immediately!<br><br>\n" +
		"\n" +
		"Brandenburg\n" +
		"\n"
,
		"Scott,<br>That is an excellent idea, we can't be too careful around \n" +
		"here...<br><br>----Original Message----<br>Sent: 11-10-2145<br>To: Nicolas \n" +
		"Sadowayj<br><br>Mr. Sadowayj,<br><br>I fear that the rumors I've been hearing \n" +
		"might actually be true. The other day someone from my team was cleaning up a \n" +
		"toxic spill in Delta and they said they actually saw some sort of creature down \n" +
		"there, he didn't know what it was but it really scared him and got us \n" +
		"thinking.<br>We can't be too careful; I've locked some ammunition and combat \n" +
		"armor in the cabinet by the monorail airlock door here in Sector 2. If you ever \n" +
		"need to get in it the code is: 483<br><br>-Scott\n" +
		"\n"
,
		"Sam,<br>I completed the repairs on door A32 today it should open more \n" +
		"smoothly.<br><br>On a separate note, did you see those artifacts that the \n" +
		"science team recently shipped into the Delta Complex?  I don't know what they \n" +
		"were but I certainly don't like them being transported outside of the cargo \n" +
		"crates, it just creeps me out seeing them, knowing that we are working above \n" +
		"some sort of alien ruins.  Just the thought of what might be lurking in those \n" +
		"caves gives me shivers.<br>I don't know, maybe I'm just over-\n" +
		"reacting....<br><br>Jim\n" +
		"\n"
,
		"Harding,<br><br>I need you to check the mounting brackets on the turret gun\n" +
		"here in security, they seem to be rattling when the turret moves.<br><br>Karl\n" +
		"\n"
,
		"Thank you for repairing that so quickly.<br><br>----Original Message----\n" +
		"<br>Sent: 11-04-2145<br>To: Karl Cullen<br><br>Karl,<br>I fixed the mounting\n" +
		"brackets this morning; it was those damn Mixom bolts again.  I really wish the\n" +
		"UAC would find a better sub-contractor.<br><br>Thanks,<br>Sam\n" +
		"\n"
,
		"\n" +
		"Mr. Ross,<br><br>This is a quick note to remind you that the new Automated\n" +
		"Defense System, at the Delta Labs entrance, is fully operational.  Please\n" +
		"contact Delta Security with any questions.<br><br>Thank You,<br>Delta Security\n" +
		"\n"
,
		"\n" +
		"As you requested, the Junction 5, Site 2 Airlock code has been updated to 826,\n" +
		"this will allow you manually override automated rail sensor systems allowing you\n" +
		"to open any of the track doors.<br><br>----Original Message----<br>Sent:\n" +
		"11-03-2145<br>To:      Central Security<br>From:    Gary Ross<br>Subject:\n" +
		"Airlock Request<br><br>The Site 2 engineering team will be conducting a few\n" +
		"tests on the J5 airlock system next week.  We will need to manually override the\n" +
		"ARSS for these tests, and request the input code be updated to 826, to conduct\n" +
		"the tests.<br><br>Thank You.\n" +
		"\n"
,
		"Mr. Hollies,<br><br>I would like to report that the main tunnel passage to\n" +
		"Site 2 has been sealed. I will transfer the door codes to the archaeological\n" +
		"division today.<br>For all practical purposes that dig site is now closed for\n" +
		"good.<br><br>Thank You,<br>Yon Brady\n" +
		"\n"
,
		"Mr. Hollies,<br><br>As you requested I've placed all remaining ammunition\n" +
		"from the security team stationed at Site 2 in storage cabinet 054 with door\n" +
		"code: 142.<br><br>Thank You,<br>Yon Brady\n" +
		"\n"
,
		"Charlie Hollies,<br><br>We've received a few artifacts from Site 2, without\n" +
		"proper discovery and time-date information on them.  It is very difficult\n" +
		"processing all these pieces when we don't know where or when they were\n" +
		"uncovered. Please check your records and send the requested information back to\n" +
		"me for artifacts 34-x91, 21-x23, and 86-y12.<br><br>Phil Wilson<br>Delta\n" +
		"Research Team\n" +
		"\n"
,
		"Mr. Cullen,<br><br>Please inform your team that an automated security\n" +
		"turret gun will be installed at the main entrance to Delta. This is due to the\n" +
		"increased sensitive nature of the experiments and the security problems we've\n" +
		"recently had.<br>Please ensure that your team members have correct security\n" +
		"clearance and are in the IFF database. We don't want a repeat of the problems we\n" +
		"had when the guns were installed at Marine Command.<br><br>Thank You,<br>\n" +
		"Central Security Authority\n" +
		"\n"
,
		"Thanks, I freaking hate loaded guns with an automatic IFF.<br><br>----\n" +
		"Original Message----<br>Sent: 11-11-2145<br>To: All Security Personnel<br><br>\n" +
		"Guys,<br>I've encoded the shutdown sequence for the turret gun into my PDA, you\n" +
		"can use it at the security terminal near the metal detectors if there are any\n" +
		"problems with the gun.<br>I would rather have it off-line then killing\n" +
		"anyone....<br><br>Thanks,<br>Karl\n" +
		"\n"
,
		"Karl,<br><br>As the medical supervisor in this section I feel it's my\n" +
		"obligation to be proactive in regards to safety especially now that we have a\n" +
		"turret gun in a heavy traffic area. As a precaution I've stocked medical\n" +
		"supplies in the storage cabinets adjacent to the security office. Both cabinet\n" +
		"codes are 364. I hope we never need them.<br><br>Lee Pommeroy<br>Medical\n" +
		"Supervisor\n" +
		"\n"
,
		"Karl,<br>I fixed the mounting brackets this morning; it was those damn\n" +
		"Mixom bolts again.  I really wish the UAC would find a better sub-\n" +
		"contractor.<br><br>Thanks,<br>Sam\n" +
		"\n"
,
		"Mr. Cullen,<br><br>We are going to be transporting a large specimen from\n" +
		"Delta.  Normally we would not be using the Monorail, but we need to get it to\n" +
		"Phobos Lab and this is our only option.<br><br>The specimen will be sedated,\n" +
		"however given both the importance and the hazardous nature of it, we feel it is\n" +
		"prudent to have an enhanced security presence.  You and your men should be\n" +
		"prepared with full armor and plasma guns.  However, lethal force should be only\n" +
		"used as a last resort.  Bringing this thing alive through the teleporter cost\n" +
		"seven men their lives and it would be a shame to waste that effort.<br><br>\n" +
		"Also, as you know there are plenty of rumors about our specimens.  I do not want\n" +
		"this to get out of control.  Information about this transport must be kept to\n" +
		"only those that need to know what is in the container.<br><br>Thank you for\n" +
		"your cooperation in this.\n" +
		"\n"
,
		"Hey Rob... it's me again :)  why haven't you written me back yet?  I sent\n" +
		"all your old books and shit to you via the UAC... Did you get them? It sure is\n" +
		"nice to get all that crap out of the living area.  Donated a lot of other stuff\n" +
		"to some charity place too.  In six months when you come back you'll see the\n" +
		"difference.<br><br>Anyway, PLEASE!! write back soon... I'm lonely down\n" +
		"here.<br><br>See Ya,<br>Tanya\n" +
		"\n"
,
		"Robert,<br><br>I know exactly what you're talking about, although not\n" +
		"nearly as frequently as what you're describing.  I'll follow up with you on this\n" +
		"after I do a little investigating but it certainly is weird.<br><br>-\n" +
		"Ryan<br><br>----Original Message----<br>Sent: 11-13-2145<br>To: Ryan\n" +
		"S.<br><br>Hi Ryan,<br>Are you guys getting strange sounds coming through walls\n" +
		"and floors?  We've been getting this all over the place the last couple of days.\n" +
		"I think it's worse in the lower levels of Delta but we can't pinpoint what the\n" +
		"origin is.<br><br>Anyway, I'm just checking in with others around the\n" +
		"UAC.<br><br>Thanks,<br>-Robert\n" +
		"\n"
,
		"Mr. Mora,<br>Your complaint has been filed with Central Authority, they\n" +
		"will be handling your case from this point forward.<br><br>----Original\n" +
		"Message----<br>Sent: 11-9-2145<br>To: Delta Authority<br>As the engineer\n" +
		"responsible for the Reactor Upper Level Maintenance, I feel it necessary to\n" +
		"bring the current safety issues to your attention.  If we are going to be\n" +
		"required to take readings from the upper level while the reactor is online then\n" +
		"there will eventually be accidents.  I recommend remote sensors be used for\n" +
		"these readings and that the upper level be closed while the Reactor is\n" +
		"online.<br><br>Sincerely,<br>Brian Mora\n" +
		"\n"
,
		"LOWEST PRICE!<br>FREE SHIPPING<br>DISCREET PACKAGING<br><br>Are you Sad?\n" +
		"We'll make you happy!<br>Are you Lethargic?  We'll perk you up!<br>Do you lack\n" +
		"Concentration?  We'll help you focus!<br><br>We carry all brands of the latest\n" +
		"Bioengineered Neurological Drugs.<br>Contact our salespersons this week and\n" +
		"save 15% off of our already low advertised prices.<br><br>Thanks from Martian\n" +
		"Buddy\n" +
		"\n"
,
		"Dr Raleigh,<br><br>Just a reminder to let you know your order of anti\n" +
		"psychotics have arrived from pharmaceuticals.  There are quite a few large boxes\n" +
		"here, and we are a bit short-handed at the moment.  If you could have someone\n" +
		"from your department come and retrieve these packages it would be much\n" +
		"appreciated.<br><br>Ben Peterson<br>UAC Supply Coordinator\n" +
		"\n"
,
		"Dr Raleigh,<br><br>We are filling up the available infirmary beds faster\n" +
		"then we can get patients released.  I don't see myself releasing any at all\n" +
		"actually.  I am gravely concerned about where we expect to put more men if the\n" +
		"current trend in testing isn't relaxed before we can determine the cause of this\n" +
		"psychosis.<br><br>Sincerely,<br>Medical Technician Phil Wilson\n" +
		"\n"
,
		"Report filed by Ian McCormick:<br><br>\n" +
		"\n" +
		"I've been working on the Soul Cube for a few weeks now. It is a fascinating\n" +
		"artifact and as it turns out, it has some amazing properties. I wrote a new\n" +
		"glyph pattern query yesterday and let it run all night. With the new query, the\n" +
		"linguistics computer finally cracked the remaining glyphs!<br><br>\n" +
		"\n" +
		"The Soul Cube is by every definition a weapon, and it seems to have been built\n" +
		"to repel whatever type of creatures invaded this ancient culture. If I am\n" +
		"reading them right, during a battle the cube gains power with each life the\n" +
		"wielder takes. When it is fully charged it can be used to slay even the\n" +
		"strongest of creatures. Even more impressive is that when it kills a creature it\n" +
		"transfers that creature's life force back to who is using the cube!<br><br>\n" +
		"\n" +
		"I know this sounds like magic and I have not been able to activate it to prove\n" +
		"the theory but this is what the glpyhs illustrate.<br><br>\n" +
		"\n" +
		"The glyphs specifically point out that you must kill five creatures before it\n" +
		"becomes charged and then amazingly it will tell you it is ready. It is almost\n" +
		"like a sentient being; this object is a fantastic find.<br><br>\n" +
		"\n" +
		"Ian McCormick<br><br>\n" +
		"\n" +
		"cc: Research Archive Storage\n" +
		"\n"
,
		"Tired of the same old , same old when it comes to snacking?  Store bought\n" +
		"treats getting you down?  Then you need the NEW Martian Buddy Li'l Baklava oven!\n" +
		"You heard right BAKLAVA!  how many times have you said MMMMBoy! I could really\n" +
		"go for some scrumptious Baklava right now?  Well now its quick and easy anytime!\n" +
		"come to <a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">www.martianbuddy.com</a> for more info on the NEW\n" +
		"Martian Buddy Li'l Baklava oven, and hundreds of other great products!<br><br>\n" +
		"Remember, Martian Buddy is Your Buddy!<br><br>\n" +
		"<a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">\n" +
		"www.martianbuddy.com</a>\n" +
		"\n"
,
		"Hey Frank,<br><br>You left your lunch on my desk, again.  I put it in the\n" +
		"storage cabinet, number 112.  The combination is 5-3-8.  Try to remember to pick\n" +
		"it up, last time you forgot it was in there for a week, and that stuff stunk to\n" +
		"high heaven.  What the hell you got in there? ;)<br><br>Guy\n" +
		"\n"
,
		"Phil,<br><br>I am going to need you to help me next week with my work.\n" +
		"I've got so many files and reports to enter into the computer that I don't have\n" +
		"time to actually see my patients.<br>I will box up some of my paper work and\n" +
		"send it over to you, if you could enter the reports into the computer that would\n" +
		"surely help me.<br><br>Thank You,<br>Dr. Peter Raleigh<br>\n" +
		"\n"
,
		"Francis,<br>A few extra supplies for the Central Security Team will be sent to\n" +
		"your area.  Please store them in Locker 103, and change the code to 259.<br><br>\n" +
		"Thank You,<br>Thomas Sandersen<br>Delta Security, Clerk\n" +
		"\n"
,
		"Francis,<br><br>Shipping recieved one of those dumb Martian Buddy storage\n" +
		"cabinets again but no one here can figure out how to open it. Can you go on-line\n" +
		"and see if you can find the code? I'll have the guys put it in one of the\n" +
		"storage rooms upstairs for you.<br><br>Thanks for the help<br>-Corbin\n" +
		"\n"
,
		"Specimen 197 -Imp<br><br>\n" +
		"\n" +
		"This humanoid's long and muscular limbs make it extremely agile as a biped or\n" +
		"quadruped.  Its long and sharp claws allow for climbing steep surfaces. A\n" +
		"configuration of 10 eyes provides this creature with a wide field of vision and\n" +
		"the ability to see with clarity in low-light environments.<br><br>\n" +
		"\n"
,
		"Specimen 42 -Living Dead (partial)<br><br>\n" +
		"\n" +
		"Able to function without recognizable brain activity, this creature has little\n" +
		"regard for self-preservation. Though slow moving, its behavior is aggressive and\n" +
		"tenacious.<br><br>\n" +
		"\n"
,
		"Specimen 27 - Imp (partial)<br><br>\n" +
		"\n" +
		"-Left forearm amputated for biological study.  <br>Researchers are currently\n" +
		"studying how this creature is able to manifest and throw an explosive plasma\n" +
		"projectile from its hands.<br>\n" +
		"\n"
,
		"Specimen 31 - Hellknight (partial)<br><br>\n" +
		"\n" +
		"The cranial mass of this bipedal specimen indicates that it is one of the\n" +
		"largest creatures inhabiting the site.  Overpowering its prey with sheer might,\n" +
		"the Hellknight is one of the most ferocious predators known to our researchers.\n" +
		"Like the imp, this creature is able to defend itself at long-range with thrown\n" +
		"plasma projectiles.<br><br>\n" +
		"\n"
,
		"Specimen 218 - Revenant<br><br>\n" +
		"\n" +
		"This creature's endoskeleton is visible through a translucent non-solid skin.\n" +
		"Despite the absence of any visible muscles, this creature is able to strike at\n" +
		"close-range targets with enormous strength.  Two shoulder-mounted cannons\n" +
		"controlled by its nervous system allow this creature to fire guided rocket-\n" +
		"propelled missiles.<br><br>\n" +
		"\n"
,
		"Jacob,<br>We have recently changed the code on the storage locker near the\n" +
		"stasis transfer control to: 3 7 1.  Please be sure to annotate this on the\n" +
		"Security Report for the local security detail.<br><br>Thank You,<br>Thomas\n" +
		"Franks<br>Delta Security, Clerk\n" +
		"\n"
,
		"Mr Stemmons,<br>We are aware of the power problems in your area. Presently, we\n" +
		"are dealing with several power issues throughout the entire Delta complex. It\n" +
		"would be a great help if you would check some of the circuit breaker boxes in\n" +
		"Maint-04 to determine if it is simply a breaker issue. Please file a report with\n" +
		"the help desk and we will be out to your department as soon as possible.<br><br>\n" +
		"Thank You,<br>Maintenance Division<br><br>----Original Message----<br>Sent:\n" +
		"11-13-2145<br>The damn power has been fluctuating all week in the Specimen\n" +
		"Transfer Area. These conditions make it extremely frustrating to work! Can you\n" +
		"please send someone down here to check on the situation.<br><br>Thank You,<br>\n" +
		"Jacob Stemmons<br><br>\n" +
		"\n"
,
		"Dr. Bullman,<br>I received your report and I find it rather perplexing.\n" +
		"Given how little we know about this object I cannot imagine how you've come to\n" +
		"such a bizarre conclusion, let alone any conclusion at all.  Send me all your\n" +
		"notes for immediate review.<br><br>\n" +
		"\n" +
		"In the meantime, for your own sake, do not share these findings with anyone\n" +
		"else.  I would hate people to think one of my highly regarded scientists is\n" +
		"prone to flights of fantasy.<br><br>\n" +
		"\n"
,
		"Dr. Bullman,<br>I hope you will find this e-mail.  I have hidden my true\n" +
		"name and address by disguising this email.  He may be reading your\n" +
		"messages.<br><br>\n" +
		"\n" +
		"Your boss (I will not mention his name) is far more dangerous than you can\n" +
		"imagine.  I believe he is using you and corrupting your work.<br><br>\n" +
		"\n" +
		"I believe your findings are correct and he knows it.  He wants to hide the\n" +
		"findings from the UAC and use the object for himself.  I don't know why yet, but\n" +
		"I will find out.<br><br>\n" +
		"\n" +
		"Do what you can to stall him.  Do not let him take possession of the\n" +
		"object!<br><br>\n" +
		"\n"
,
		"The UAC Mars facility is looking for volunteers.  We would like to encourage\n" +
		"people from your team to help in the following areas:<br><br>\n" +
		"\n" +
		"Safety committee: Work with teams to update and inform UAC staff on the\n" +
		"importance of safety on Mars.  Check placement of Health Packs around\n" +
		"facilities.  Plan events for safety awareness month.  Commitment here will earn\n" +
		"volunteers 5 extra credits per month.<br><br>\n" +
		"\n" +
		"Research assistants:  Delta Labs is looking for individuals to help with our\n" +
		"exciting break-through work.  Projects vary, see research coordinator for more\n" +
		"information.  Health test and liability waiver will be required.  Commitment\n" +
		"here will earn volunteers 25 extra credits per month.<br><br>\n" +
		"\n" +
		"For more information about any of these opportunities or to post an opening in\n" +
		"your department, please see Sierra Olson, Volunteer Coordinator, in UAC HR.\n" +
		"\n"
,
		"Larry,<br>You were correct, the Soul Cube is a weapon.  I've translated the\n" +
		"glyphs and now I understand how it works!<br><br>The Soul Cube must be charged\n" +
		"before it can be used.  The souls within it must sense at least five deaths\n" +
		"before the spiritual potentiality matrix reaches positronic astriction.  Based\n" +
		"on our interpretation of the glyphs, the Cube has a way of acknowledging its\n" +
		"readiness for action, but it's unclear how this happens.  More importantly, we\n" +
		"still don't know what will happen when it's released.<br><br>This has gone way\n" +
		"beyond the purview of Mars station or even the UAC.  We can't trust Betruger,\n" +
		"he's gone mad! I'm preparing a report to send to the highest authorities on\n" +
		"Earth.<br><br>Good luck and God help us.<br>Ian\n" +
		"\n"
,
		"Ms. McNeil<br><br>I have received your formal request for an inquiry into the\n" +
		"activities on Mars. As requested by the Board, I will be departing soon to\n" +
		"investigate your claims. Your charges against Dr. Malcolm Betruger are being\n" +
		"taken very seriously.  As you may already know, the UAC, as a matter of policy,\n" +
		"will not acknowledge or comment on questions or statements advanced by the press\n" +
		"based on rumor or heresay. I have been asked to explain that you are not to\n" +
		"discuss this situation with anyone outside of our legal department.<br><br>\n" +
		"Thank You,<br>Elliot Swann<br>UAC Executive Counsel\n" +
		"\n"
,
		"All Delta Level 2 Personnel,<br><br>In an effort to minimize supply requirement\n" +
		"errors,  I'm having all the access codes to secure storage areas changed.  We\n" +
		"must continue to keep tight records for all inventory kept in storage. Please\n" +
		"update your records with the new code for Storage area 07, to: 7 2 5.<br><br>\n" +
		"Thank You,<br>Mike Abrams<br>Delta Security, Chief\n" +
		"\n"
,
		"All Delta Level 2 Personnel,<br><br>Level 3 access codes have been changed due\n" +
		"to the six month rotation.  The new code is 463<br><br>Thank You,<br>Mike\n" +
		"Abrams<br>Delta Security, Chief\n" +
		"\n"
,
		"Dr. Cerano,<br><br>Recently, Delta science team members have reported serious\n" +
		"cases of irrational behavior and insomnia.  They believe these issues are\n" +
		"directly linked to the recent teleportation experiments.  Please report to Delta\n" +
		"Level 3, ASAP, and assist with medical needs.<br><br>Thank You<br>\n" +
		"\n"
,
		"Dr Cerano,<br><br>We currently cannot process your request for additional\n" +
		"medical personnel in Delta level 3.  As you are aware, the medical staff has\n" +
		"experienced serious HR shortages within the past two weeks and cannot take on\n" +
		"the additional workload.  There are surplus medical kits located in storage room\n" +
		"D3-1, across from the lower terminal offices.  The code to storage cabinet #317\n" +
		"is 841.<br><br>Delta Control<br>\n" +
		"\n"
,
		"\n" +
		"Han,<br><br>Due to the incident last week, the Delta Security team will be\n" +
		"storing additional supplies in cabinets 386 and 387 near the first teleport\n" +
		"chamber.  We will update the access codes to 836 as soon as the equipment\n" +
		"arrives.<br><br>Thank You,<br>Thomas Palena<br>Delta Security Clerk\n" +
		"\n"
,
		"Marten,<br>Our systems can only build enough of a charge to have the\n" +
		"portals open for approximately 10 to 15 seconds. Enough time to get a team\n" +
		"through, but not enough time to send in some of the heavier equipment. Do you\n" +
		"have any idea how we can route supplemental power from the stabilizers into the\n" +
		"converters? I think if we can increase the hold field by 13% we could achieve\n" +
		"full transfer power and open the field for a longer amount of time.<br><br>Any\n" +
		"help you can give will be greatly appreciated.<br><br>Thank You,<br>Frank\n" +
		"Cerano\n" +
		"\n"
,
		"Jack,<br><br>Just a little reminder, they changed the security clearances\n" +
		"again today.  The new code for locker 104 is 5-7-9, though I doubt you will need\n" +
		"to use it.<br><br>Paul.<br>\n" +
		"\n"
,
		"Simon,<br>As you know, you have been assigned to Expedition 14A-2.  There\n" +
		"are several goals we hope your team will be able to meet while on expedition.\n" +
		"Since you are familiar with the discovered tablets, we would like you to find\n" +
		"cooperative evidence on the other side of the Main Portal - similar writings of\n" +
		"any kind should be photographed and collected.<br><br>Primarily, we are looking\n" +
		"to capture several more bio-specimens.  Having already collected many of the\n" +
		"smaller 'Imp' creatures, we would like to bring back one of the larger ones,\n" +
		"described by others as a 'Hell-Knight'.  Previous expeditions have reported\n" +
		"seeing them at the Main Portal destination, where you will be arriving.  You\n" +
		"will have several weapons with you, but we ask that you attempt to use non-\n" +
		"lethal force, we would really like to have a live specimen if possible.<br><br>\n" +
		"Finally, traveling through the portal has caused varied reactions.  For our\n" +
		"records we would like you to keep an audio log of everything you experience for\n" +
		"us to later analyze.<br><br>I hope you have a safe trip.<br>Heather\n" +
		"\n"
,
		"Simon,<br>In preparation for your 'jump' next week, I wanted to inform you\n" +
		"of another tasklist item we need to cover.  Since the Main Portal has not been\n" +
		"operational for a few weeks, we have not had time to follow up on some of the\n" +
		"physiological reports.  While on the other side of the portal, pay close\n" +
		"attention to your stamina while working and moving around.  Many individuals\n" +
		"have reported a lack of being tired, and some work constantly without the need\n" +
		"for rest.  One of the teams has called it 'the hercules complex'.  Anyhow, to\n" +
		"help us further our study, please record any additional information related to\n" +
		"this phenomenon.<br><br>Thank You, and good luck next week!<br><br>Earl\n" +
		"Besch<br>Research Scientist\n" +
		"\n"
,
		"This is a the default email.<br><br>It should really be replaced.<br><br>\n" +
		"Thank You,<br>PDA Guy\n" +
		"\n"
,
		"This is a the default email.<br><br>It should really be replaced.<br><br>\n" +
		"Thank You,<br>PDA Guy\n" +
		"\n"
,
		"Dear Counselor Swann,<br><br>\n" +
		"\n" +
		"The Board has unanimously approved a resolution for a full operations review of\n" +
		"the Mars Research Facility.  You've been designated as our official\n" +
		"representative in this matter and we suggest full preparation begin\n" +
		"today.<br><br>\n" +
		"\n" +
		"We expect a complete appraisal of all research developments. Both current and\n" +
		"future directions must be brought inline with the Board's expectations.  We must\n" +
		"be able to control Dr. Betruger.  His extra-dimensional research is a potential\n" +
		"risk to the teleportation experimentation, which we can not afford.  The Board\n" +
		"considers teleportation the UAC's single most important scientific endeavor and\n" +
		"is not willing to jeopardize it's dominance in this area of research.<br><br>\n" +
		"\n" +
		"Anticipate a formal briefing and itinerary in the next few days.<br><br>\n" +
		"Sincerely,<br>UAC Board Chairman\n" +
		"\n"
,
		"Counselor Swann,<br><br>\n" +
		"\n" +
		"I was pleased to hear of the Board's decision to have you investigate the\n" +
		"current state of the UAC on Mars.  I've become more and more worried about Dr.\n" +
		"Betruger's research direction and my hope is that this intervention will bring\n" +
		"much needed changes.  I've also learned that I've been reassigned by Dr.\n" +
		"Betruger to a post back on Earth.  Although this is disappointing I do fear for\n" +
		"my own safety and sanity if I were to stay here.<br><br>\n" +
		"\n" +
		"I hope your intervention won't be too late. I look forward to meeting with you\n" +
		"back on Earth once your visit is concluded.<br><br>\n" +
		"\n" +
		"Sincerely,<br>Elizabeth McNeil\n" +
		"\n"
,
		"Counselor Swann,<br><br>\n" +
		"\n" +
		"I've been extremely patient with the Board to date, but the decision for a full\n" +
		"operations review is hard for me to stomach.<br><br>There is no need for you to\n" +
		"come to Mars and there is no need for this review.  All I need is more money and\n" +
		"more time.  The research being conducted here will change mankind in ways no one\n" +
		"can imagine.  Our research is beginning to yield amazing results which I cannot\n" +
		"allow to be compromised.<br><br>Don't waste your time coming here, Swann.  You\n" +
		"will only get in my way and impede the outstanding work we're doing\n" +
		"here.<br><br>\n" +
		"\n" +
		"Sincerely,<br>Malcolm Betruger\n" +
		"\n"
,
		"Lost all your money?<br>Job not paying you enough?<br>Woman took all your\n" +
		"credits?<br><br>**DON'T WORRY**<br><br>Martian Buddy has got you\n" +
		"covered.<br><br>You can earn extra credits every week by helping us in our\n" +
		"direct marketing campaign. <br>All you need to do is send out emails from the\n" +
		"comfort of your own computer and rake in the credits - it's that easy.<br><br>\n" +
		"Contact Martian Buddy for more details.\n" +
		"\n"
,
		"Bruce,<br><br>You have been reassigned to the CPU complex effective\n" +
		"immediately. We've had numerous reports of computer break-ins and security\n" +
		"breaches there in the last few weeks.<br><br>I've placed a rocket launcher in\n" +
		"the cabinet near the security checkpoint. The door code is 571. I know a rocket\n" +
		"launcher might seem excessive but people tend to listen when you point it at\n" +
		"them.<br><br>Be careful over there and remember to report anything odd\n" +
		"immediately.<br><br>Michael Abrams\n" +
		"\n"
,
		"Our time is here!  Soon the humans will suffer eternal torment as spoken of\n" +
		"in the prophesy.  We will feast upon their souls!<br><br>\n" +
		"\n" +
		"When opening sacrificial portals, it is important to remember:<br><br>\n" +
		"\n" +
		" - Virgin blood is best<br>- Goat blood must be no older than 3 days<br>-\n" +
		" Entrails must be removed and apportioned either before death, or no later than\n" +
		" 30min<br>- Candles must be sorted by tallest in back to shortest in front -\n" +
		" never the other way around!<br><br>\n" +
		"\n" +
		"Most important - pentagrams must be drawn from the center to the outside and\n" +
		"left to right.<br>\n" +
		"\n"
,
		"Mr. Bates,<br><br>I am concerned about the lax security measures that you\n" +
		"have implemented in Lab A. I have received numerous reports now, about your\n" +
		"team-members flagrantly disregarding security procedures, and posting the daily\n" +
		"security codes on the data-boards.<br>\n" +
		"\n" +
		"With corporate bringing by so many investors lately, you need to stay more\n" +
		"vigilant in maintaining security in the lab. The last thing we need is more\n" +
		"people wandering around, unsupervised, with all the odd goings-on\n" +
		"lately.<br><br>\n" +
		"\n" +
		"Thank you\n" +
		"\n"
,
		"Tired of being a loser with the ladies? Want a more exciting and fulfilling\n" +
		"love life?  Vist us at <a href=\"http://www.martianbuddy.com\" title=\"www.martianbuddy.com\">martianbuddy.com</a> and find out how!<br><br>\n" +
		"Your Martian Buddy!<br>\n" +
		"\n"
,
		"Tony,<br><br>I've had a chance to run the formulas you provided us. The\n" +
		"results have been uploaded to your mailbox.<br><br>Please pay particular note to\n" +
		"page 12, section F, part 2. The left side of the graph is showing unusual spikes\n" +
		"over the last 3 weeks, which can only be explained by unauthorized access to the\n" +
		"EnPro power grid.<br><br>It's not possible to attain the figures you have shown,\n" +
		"without exceeding power limits for your areas.<br><br>This should probably be\n" +
		"reported to Dr. Betruger at once.\n" +
		"\n"
,
		"Failed to deliver to 'Rbrody@ua-corp.com'.<br>STTP module(domain ua-\n" +
		"corp.com)reports:<br>host name is unknown<br><br>Reporting-MTA dns; ua-\n" +
		"corp.com<br><br>Original-Recipient rtg374;&lt;Rbrody@ua-corp.com&gt;<br>\n" +
		"Final-Recipient rtg374;&lt;Rbrody@ua-corp.com&gt;<br>Action failed<br>Status\n" +
		"5.0.0<br>Received from [192.246.40.132.44543.43.661] (account chaskell HELL\n" +
		"chaskell.ua-corp.com)<br>by ua-corp.com (SpaceGate Pro STTP 5.2.6)<br>with\n" +
		"ESMTP id 8302948-033-4488291 for Rbrody@ua-corp.com; Thu, 13 Nov 2145\n" +
		"173249-0500<br>Message-Id\n" +
		"&lt;4.1.0.123.5.74839298473.84hdjlk83902@mail.ua-corp.com&gt;<br>\n" +
		"X-Sender chaskell@mail.ua-corp.com<br>X-Mailer MARSMAILER Xenerix Vartoxi\n" +
		"Version 9.3<br>Date Thu, 13 Nov 2145 173249 -0500<br>To Rbrody@ua-corp.com<br>\n" +
		"From Charlie Haskell &lt;chaskell@ua-corp.com&gt;<br>Subject MFC Failsafe\n" +
		"failure<br>Mite-Version 3.6<br>Content-Type text/plain; charset=\"us-ascii16\";\n" +
		"format=flowed\n" +
		"\n"
,
		"Charlie,<br><br>How have you been? I hear things are getting pretty busy\n" +
		"over there. Rumors are flying that you guys are on to something big. BTW I left\n" +
		"you some goodies in locker 669. The combination is 468. Sorry, I had to take\n" +
		"back the pr0n video disc! Take care mate!<br><br>J.B.\n" +
		"\n"
,
		"Steve,<br><br>I've been noticing a steady degradation in filesystem\n" +
		"performance on server A12. Could you please head up to the third floor and look\n" +
		"into the problem? I'm working on matter transference problems for the guys in\n" +
		"Delta, and all my data is stored on that server.<br><br>If we have backups from\n" +
		"last night, could you please restore them to server A17 if it's not in use? I\n" +
		"can patch into that server to continue my work.<br><br>Thanks,<br>Henry\n" +
		"\n"
,
		"Steve,<br><br>Corporate needs the new BFG video I placed in your office\n" +
		"uploaded to the server and placed on our internal web site. Don't forget to\n" +
		"convert it to a .uacmov so it'll play on all terminals.<br><br>Fire me an email\n" +
		"when it's up, I can't wait to see it.<br><br>-Brian\n" +
		"\n"
,
		"Mr. Davis,<br><br>I received word of your discovery.  Very impressive\n" +
		"start, but I am not yet convinced.  Do not lose sight of the bigger picture. We\n" +
		"still have a lot of work to do before any of these findings will be ready for\n" +
		"distribution or publication.  Analysis must continue on the remaining\n" +
		"artifacts.<br><br>Your audio reports in the future need to be more analytical\n" +
		"and less emotional.  It is important that we all maintain our professionalism\n" +
		"and do not become biased or too attached to our work.  I do not want to remind\n" +
		"you of this again.<br><br>Continue to forward me your findings, and I will help\n" +
		"you manage the reports so as not to give Corporate the wrong impression.<br><br>\n" +
		"Malcolm Betruger\n" +
		"\n"
,
		"Hey Dick,<br><br>\n" +
		"\n" +
		"The structural integrity of the Ruins is deteriorating.  Our initial rock form\n" +
		"and in-ground analyses prior to deep ground exploration inferred no immediate\n" +
		"dangers.  In fact, the Ruins structure itself was determined to be very\n" +
		"sound.<br><br>As you already know, expansive underground digs of this nature\n" +
		"are an engineering nightmare.  The heightened pressure to open up the primary\n" +
		"dig site was a dangerous oversight.  Just recently, one of our engineers, Peter,\n" +
		"got crushed by a falling brick - yup those huge stones lining the entire wall\n" +
		"and ceiling of the Ruins!<br><br>Some of the guys are mumbling about the walls\n" +
		"being alive or something, but you and I know it's just the horizontal stretching\n" +
		"of the regional tectonic stresses.  Heh, stones... alive... spoooooky. I think\n" +
		"we all need a long vacation.<br><br>Anyway, watch your step and don't forget to\n" +
		"look up once in a while.<br><br>\n" +
		"\n" +
		"Foshinator out!\n" +
		"\n"
,
		"Richard,<br><br>I thank you for your industrious support of the work being\n" +
		"conducted here. I've been compiling your data along with mine and have made some\n" +
		"startling new discoveries. I firmly believe your work with the tablets are the\n" +
		"language to solving the mysterious relationship between the Soul Cube, the Ancients,\n" +
		"and us.<br><br>I am sending word to the Executive Council for an immediate\n" +
		"increase of our research budget. I have a strange feeling that we must find the\n" +
		"answer soon or... no, I dare not say it just yet.<br><br>I will contact you\n" +
		"post-haste with any news regarding our funding.<br><br>\n" +
		"\n" +
		"Pierce\n" +
		"\n"
,
		"Pierce,<br><br>The scanner results for the ceiling carvings are back and I\n" +
		"can say without a doubt that this is a map of our solar system.  There's a very\n" +
		"clear indication that Earth may have been a possible teleport destination for\n" +
		"the survivors of this race.  If the dates on these artifacts are correct there's\n" +
		"a distinct possibility that we could be descendents of this race!<br><br>I can\n" +
		"barely comprehend how immense this is.  All the struggles we've had in the past\n" +
		"year have suddenly become trivial.<br><br>Richard\n" +
		"\n"
,
		"Uncle Pierce,<br><br>Mom says we're going to get to see you next month.  I\n" +
		"can't wait.  You've been away so long.  I hope your project is still going well.\n" +
		"I know you can't tell me about it, but your work always sounds so exciting and\n" +
		"mysterious.  Working on Mars must be really cool.<br><br>Thanks for sending me\n" +
		"the video games for my birthday.  Have you tried these?  The new Quake-43 game\n" +
		"blows my mind.  It is even better than Super Turbo Turkey Puncher.  I haven't\n" +
		"played that since I got these new ones from you.<br><br>I love you, and we miss\n" +
		"you lots!!!<br>Timmy<br><br>P.S. Mom says HI!\n" +
		"\n"
,
		"Server: send failure, unable to link to orbital communication satellite.\n" +
		"Please contact I.T. system administrator(code 504)<br><br>----Original Message-\n" +
		"---<br>Sent: 11-15-2145<br>To: Elizabeth McNeil<br><br>I can now only beg\n" +
		"your forgiveness.  You were right about everything.  I wish that you had been\n" +
		"wrong and that this had somehow been a nightmare that I will soon wake from.\n" +
		"Now I can only pray that this message reaches you.<br>Betruger is mad and\n" +
		"things have gone worse than you could have imagined.  Now your banishment from\n" +
		"Mars has become a blessing in disguise.  It has likely saved your life.  You\n" +
		"must get the word out.  The answer is the Soul Cube object.  It always has been.\n" +
		"Listen to my report.  I will upload my finding to the databank.<br><br>Warn\n" +
		"them all. You must not let this spread to Earth.<br><br>God Bless\n" +
		"You,<br>Pierce\n" +
		"\n"
,
		"Mr Cody,<br><br>Several crews working in the caverns have reports of at least a\n" +
		"dozen generator failures after the recent seismic activity.  Please assemble\n" +
		"your assigned team and report to Maintenance Supervision for the detailed\n" +
		"report.  We need those generators operational as soon as possible.<br><br>Thank\n" +
		"You,<br>Maintenance Control\n" +
		"\n"
,
		"Hey Bob,<br><br>I see that you've been assigned generator duty again, good luck\n" +
		"down there with the 'howlers'.  Remember to try and keep your team focused,  I\n" +
		"was assigned down there last month, so I'm very aware of how difficult it can\n" +
		"get, especially with all those tremors!  Anyhow, give me a shout when you get\n" +
		"some free time, we can hit up one of the common areas and down some\n" +
		"suds.<br><br>Talk to ya soon,<br>Mark\n" +
		"\n"
,
		"Adrian Carmack<br>Artist, Co-Owner\n" +
		"\n"
,
		"Thank You.<br>John Carmack<br>Programmer, Co-Owner\n" +
		"\n"
,
		"I would like to thank our fans for playing Doom 3.<br>Kevin\n" +
		"Cloud<br>Artist, Co-Owner\n" +
		"\n"
,
		"Wow.  We're finally done!<br><br>I would like to thank Tara for her\n" +
		"patience with the long hours and all of the travel and for always being there\n" +
		"when I got home, regardless of the hour.  Thanks to Tuffy, Champ, Barney, Boo,\n" +
		"Tazzy, Charm, Ally, Annie, Nadeema, Firefox, Amira, Shahira, Misty and Hope (in\n" +
		"our memories) for taking care of Tara when I wasn't there.  Thanks to my family\n" +
		"for all of the support and encouragement over the years.<br><br>Thanks to the\n" +
		"entire team here at id for all of the hard work that was put in to make DOOM 3\n" +
		"the game we set out to make from the start.  And on behalf of the entire team at\n" +
		"id, thanks to everyone at Activision for their work in assisting us and\n" +
		"supporting the DOOM 3 development.<br><br>And, of course, thanks to the fans,\n" +
		"who make all of the effort worth it in the end.  See you guys at QuakeCon and on\n" +
		"the DOOM 3 servers, where I'll be happy to spoon feed you some rockets courtesy\n" +
		"of 'Toddh'!<br><br>Todd Hollenshead<br>CEO, Co-Owner\n" +
		"\n"
,
		"I would like to thank all of our fans for playing Doom 3. We sure had a\n" +
		"great time making it.<br><br>I would also like to give a special thanks to my\n" +
		"wife Stephanie and children Richard and Destinee for putting up with me being\n" +
		"gone long hours during crunch-time.<br><br>Tim Willits<br>Lead Designer, Co-\n" +
		"Owner\n" +
		"\n"
,
		"Gloria: 'Thanks for putting up with the long hours we work.'<br><br>My\n" +
		"Family : 'Thank you for all your love and support over the years in helping me\n" +
		"settle here'<br><br>Bryan Watkins and the entire team at Danetracks : 'Thanks\n" +
		"for going above and beyond the call of duty for us'<br><br>The Owners at id :\n" +
		"'Thank you for your continued support and guidance throughout the years'<br><br>\n" +
		"\n"
,
		"To my wonderful wife Christine for 'putting up with me' through the\n" +
		"years<br>To my parents - Mom, you played that far to read this??<br>To my\n" +
		"friends having to deal with my computer addiction.<br>And a big BLEH! to the\n" +
		"#qeradiant regulars<br><br>\n" +
		"\n" +
		"Thanks to Id fans everywhere .. special mention to Mac/Linux players and\n" +
		"European gamers.<br><br>\n" +
		"\n" +
		"It's been a wild ride .. I look forward to the future.<br><br>\n" +
		"\n" +
		"TTimo<br>Paris, France\n" +
		"\n"
,
		"Well we are finally done, it's been a long road getting here and let me tell\n" +
		"you 4 years on a game is a looooooong time!<br>To do a game like this, with the\n" +
		"amount of people we have working on the team, it really says a lot about the\n" +
		"talent and commitment of everyone at id.  I would like to thank everyone at id\n" +
		"for making the times at the office fun and rewarding (and we have been at the\n" +
		"office a lot!)  I would also like to thank my Wife Erin and my daughter Marlee\n" +
		"for holding down the fort while I have been away and not giving me to much\n" +
		"trouble about not being home so much.  And last, but not least I would like to\n" +
		"thank the fans.  You guys (and girls) that plop your 50 down for this game.  We\n" +
		"worked really hard on it and I hope you enjoyed it!<br><br>Mal\n" +
		"Blackwell<br>Designer\n" +
		"\n"
,
		"Player,<br><br>\n" +
		"\n" +
		"Well, here we are at the end of the game.  You've been persistent and skillful\n" +
		"or you've found some cheat codes somewhere.  Either way, thanks for making it\n" +
		"this far.  In fact, I'm so grateful that I think you deserve a little something\n" +
		"extra. Here are some highly classified secrets and trivia about the art\n" +
		"team.<br><br>Kenneth Scott: Can navigate a Japanese bookseller's website faster\n" +
		"than most Japanese people can.<br>Seneca Menard: Will never eat at a restaurant\n" +
		"where orange lycra hotpants are part of the staff's uniform.<br>Fred Nilsson:\n" +
		"Can recite uncensored lines from an animated movie he worked on.<br>Pat Duffy:\n" +
		"Was once invited to a movie starlet's hotel room but didn't go.<br>Patrick\n" +
		"Thomas: Cannot stifle laughter.<br>James Houska: Has escaped death from\n" +
		"lightning multiple times.<br>Eric Webb: Tried to impress a girl by saying he\n" +
		"liked a terrible song.<br>Adrian Carmack: Likes soup but doesn't seem to like\n" +
		"crackers.<br>Kevin Cloud: Only eats from a blender.<br><br>\n" +
		"\n" +
		"Thanks to my family, friends, and you.<br><br>\n" +
		"\n" +
		"out,<br>Andy Chang<br>Mesquite, TX, USA, Earth\n" +
		"\n"
,
		"Hello!  I hope you enjoyed playing the game.<br><br>I'd like to thank the\n" +
		"owners of id for making such a great place to work, everyone who works at id for\n" +
		"being so good at what they do, and my family for being patient with me while I\n" +
		"was busy working on the game, especially my Mom, who died of cancer last\n" +
		"year.<br><br>See you around in the Delta Labs!<br><br>Jim\n" +
		"\n"
,
		"I hope you are enjoying all the hard work we put into Doom 3, it's been a\n" +
		"blast creating it. You are the reason we can keep building great games and we\n" +
		"appreciate your support.<br><br>A quick thanks to my wife Christina for her\n" +
		"endless dedication in caring for our newborn during my late hours. Joshua, you\n" +
		"sure gave crunchtime a little extra kick ;)<br><br>Enjoy the rest of the\n" +
		"game!<br><br>patd\n" +
		"\n"
,
		"Thanks to friends and family for the support.<br>I hope everyone enjoys\n" +
		"playing it as much as we enjoyed making it.<br>Special Thanks to Austen and\n" +
		"Camryn :-)<br><br>In the game of the year polls please remember Super Turbo\n" +
		"Turkey Puncher 3.<br>robert...\n" +
		"\n"
,
		"Thanks to my wife Tanya, and my kids Gabriel, Aaron, and Ariel.<br><br>Matt\n" +
		"Hooper<br>Designer\n" +
		"\n"
,
		"I would like to thank my wife for her love &amp; support during this extremely\n" +
		"difficult time.<br><br>James Houska<br>Animator\n" +
		"\n"
,
		"To all our fans,<br>Thank you for being the best fans around, I always\n" +
		"enjoy meeting you when I can.<br><br>Donna Jackson<br>id Mom\n" +
		"\n"
,
		"Took your sweet time gettin' here Marine!... =)<br><br>First and foremost,\n" +
		"a sincere thanks to you for playing Doom 3!  I know it's been a few years in the\n" +
		"making and trust that you're thoroughly enjoying the dedication and hard work\n" +
		"that has gone into this title.<br>J. Keehan<br>Designer\n" +
		"\n"
,
		"Seneca Menard<br>Artist\n" +
		"\n"
,
		"Wow,  You are really far into the game if you're reading this.  Hope you're\n" +
		"enjoying it so far.... Turn around.. Quick. =) <br><br>I would like to say\n" +
		"thanks to my friends and family: Lars, Ingegard, Sofie, Chris, Andrew, Anneli,\n" +
		"Tom, Denise, TJ.<br>Also to all my friends and family in Sweden.<br>To SMR:\n" +
		"...<br><br>Empire(III): Thanks for all the late night Deathmatching.....  Fun\n" +
		"stuff...  Better get those shotgun skills back up to par.  No railgun for you!\n" +
		"2ez....<br><br>To my co-workers: We have an amazing crew of people working\n" +
		"here, you should all be proud of what we've achieved..  Thanks for keeping that\n" +
		"smile on my face..   =)<br><br>To the owners of id:  You guys have built quite\n" +
		"an amazing place here... I've learned a whole bunch about game design from the\n" +
		"best of the best...<br><br>And of course to you the player: Thanks for all your\n" +
		"support and for being patient while we've worked so hard on this for you\n" +
		"all<br><br>Thanks... <br><br>Fredrik\n" +
		"\n"
,
		"Steve Rescoe<br>Designer\n" +
		"\n"
,
		"A big mushy thanks to the rest of my brothers in arms for delivering a\n" +
		"blockbuster game and being good company while doing it. I'm proud as hell to see\n" +
		"this on the shelves and I'm looking forward to making more great games with you\n" +
		"fools.  :)<br><br>-Kenneth<br><br>ps - I love you Peach!\n" +
		"\n"
,
		"If you've played this far, I certainly hope you've enjoyed DOOM 3 (or at\n" +
		"least enjoyed the quick devmap and noclip ride to look at this secret).  It was\n" +
		"a pleasure to be a part of the team that created it.  Even after 4 years of\n" +
		"seeing it in development, I'm still in awe of the detail, complexity, and\n" +
		"creativity packed into every square inch of the game.<br><br>\n" +
		"\n" +
		"To my wife Gen, and my kids, Andy and Alexa - thank you for being you - your\n" +
		"support is unbelievable.  You three are simply the greatest! And, to the rest of\n" +
		"my family, 32 years of thanks, respect, and appreciation.<br><br>\n" +
		"\n" +
		"Enjoy the rest of the game.  See you at QuakeCon.<br><br>\n" +
		"\n" +
		"Ship it!<br>Marty\n" +
		"\n"
,
		"Patrick Thomas<br>Artist\n" +
		"\n"
,
		"Thanks to me and my beloved twin who unfortunately had to be stripped for\n" +
		"spare parts.<br><br>MrElusive,<br>Programmer\n" +
		"\n"
,
		"Thanks to Andy and Fred for telling me what I needed to hear, and making me\n" +
		"realize what I needed to do. Thanks Mom and Dad for understanding why I haven't\n" +
		"visited in months.<br><br>Most of all, I have to thank my 'bagel-chick' Emmy!\n" +
		"Thank you for all those nights I came home late and you were there waiting.\n" +
		"Thank you for your love and inspiration. Thank you for being a better girlfriend\n" +
		"than I could ever ask for!<br><br>-eric\n" +
		"\n"
,
		"We'd like to thank Miss Donna first and foremost for taking such good care\n" +
		"of us.  We'd like to thank the awesome guys at id for not firing us (yet), and\n" +
		"the Guildhall at SMU for getting us here in the first place.<br><br>Brian would\n" +
		"like to thank his friends and family for always believing in him.  He would like\n" +
		"to give special thanks to Kim, Jon, Lucas, Blake and all the guys in Team\n" +
		"Reaction.  I wouldn't be here without you all.<br><br>Kevin would like to thank\n" +
		"his brother Matt<br><br>- Brian Harris &amp; Kevin Dobler\n" +
		"\n"];
		""

}
