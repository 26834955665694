import {Contains, IsNotEmpty, IsOptional, MinLength, ValidateNested} from "class-validator";
import {classToPlain, plainToClass, Transform, Type} from "class-transformer";
import {WaihonaUserTextContent} from "../../domain/user/WaihonaUserTextContent";
import {ITextContentObject} from "../../domain/ITextContentObject";
import {DocumentTextContent} from "../../domain/DocumentTextContent";

export class UserProfileMessage implements ITextContentObject<WaihonaUserTextContent> {

	@IsNotEmpty()
	public guid:string = null;

	@MinLength(2)
	@IsNotEmpty()
	public firstName:string;

	@MinLength(2)
	@IsNotEmpty()
	public lastName:string;

	@IsOptional()
	public altFirstName:string;

	@MinLength(2)
	@IsNotEmpty()
	@Contains(" ", {message: "Your professional name is required, and it should include at least your first or alternate first name and your last name."})
	public professionalName:string;

	@Transform((value:object, obj, type) => {
		//From object to object map of WaihonaUserTextContents
		let keys:Array<string> = Object.keys(value);
		// console.log(JSON.stringify(keys));
		let returnObject:{ [key:string]:WaihonaUserTextContent } = {};

		// tslint:disable-next-line:forin
		for (let iKey in keys) {
			let key:string = keys[iKey];
			let waihonaUserTextContentPlain:any = value[key];
			let waihonaUserTextContent:WaihonaUserTextContent = plainToClass(WaihonaUserTextContent, waihonaUserTextContentPlain);
			returnObject[key] = waihonaUserTextContent;
		}
		return returnObject;

	}, {toClassOnly: true})
	@Transform((value:{ [key:string]:WaihonaUserTextContent }, obj, type) => {
		//From object map of WaihonaUserTextContents to a plain object map
		let keys:Array<string> = Object.keys(value);
		let returnObject:{ [key:string]:{} } = {};

		// tslint:disable-next-line:forin
		for (let iKey in keys) {
			let key:string = keys[iKey];
			let waihonaUserTextContent:WaihonaUserTextContent = value[key];
			let waihonaUserTextContentPlain:any = classToPlain(waihonaUserTextContent);
			returnObject[key] = waihonaUserTextContentPlain;
		}
		return returnObject;
	}, {toPlainOnly: true})
	public documentTextContent:DocumentTextContent<WaihonaUserTextContent> = new DocumentTextContent<WaihonaUserTextContent>(WaihonaUserTextContent);

	@Type(()=> OrganizationTitleMessage)
	/*@ValidateNested()*/
	public organizations:Array<OrganizationTitleMessage> = [];

}

export class OrganizationTitleMessage {
	/** this is the org guid */
	public guid:string = null;

	@IsNotEmpty()
	public userTitle:string;
}
