import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {ResourceSubmissionDraftGuard} from "../../../guards/ResourceSubmissionDraftGuard";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";

@Component({
	selector: 'cancel-resource-submission-modal',
	templateUrl: './cancel-resource-submission-modal.component.html',
	styleUrls: ['./cancel-resource-submission-modal.component.scss']
})
export class CancelResourceSubmissionModalComponent implements OnInit, OnDestroy {

	private cbContext = Localization.template.modal.resource.cancel_resource_submission;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body,
		yes: Localization.template.buttons.yes,
		no: Localization.template.buttons.no
	};

	//@ViewChild('yesAnswer') dataContainer: ElementRef;

	public component:ResourceSubmissionDraftGuard;

	//Example link: http://localhost:4200/#/resources/users/alastor-moody/aKYhMN3MxGUtpUobPdP1/edit
	constructor(public bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("CancelResourceSubmissionModalComponent", this.CB);
		this.logger.info("BSModalRef ran");
	}

	public ngOnDestroy() {
		this.logger.info('component destroy')
	}

	public ngOnInit():void {
		this.logger.info('component init');
	}

	public getContent(pathObjectOrString:any|string): string {
		return this.localizationService.get(pathObjectOrString);
	}

}
