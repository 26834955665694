import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {BehaviorSubject, Observable} from 'rxjs/Rx';
import {validateSync, ValidationError} from 'class-validator';
import {ValidationException} from './exceptions/ValidationException';
import {AuthService} from "app/services/AuthService";
import {Organization} from "../domain/organization/Organization";
import {OrganizationVerificationMessage} from "./messages/OrganizationVerificationMessage";
import {OrganizationRepository} from "./repository/OrganizationRepository";
import {OrganizationFunctions} from "./functions/OrganizationFunctions";
import {InviteUserMessage} from "./messages/InviteUserMessage";
import {NGXLogger} from "ngx-logger";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {IPartialInstruction, QueryCriteria} from "./repository/AbstractFirestoreRepository";
import {PageListService, PagingService} from "./PageListService";

export interface TriState {
    pending:boolean;
    wasSuccessful:boolean;
}

@Injectable({
  providedIn: 'root',
} as any)
export class OrganizationService implements PagingService<Organization> {

	private _paging:{
		list:PageListService<Organization>,
	};

	public paging = {
		pageSize: 20,
		list: {
			cursor: () => {
				return this._paging.list.pageCursor;
			},
			initialize: {
				list$:() =>  this._paging.list.initializePaging$(this.repo.by.criteria.all(), this.paging.pageSize)
			},
			next:() => {
				this._paging.list.getNextPage();
			},
			isLoading: () => {
				return this._paging.list.pageLoading$;
			},
			nextPageExists: () => {
				return this._paging.list.pageCursor?.nextPageExists;
			},
		}
	}


	public organizationsUri:any = {
        allOrganizations: "Organizations",
    };

    constructor(private authService: AuthService,
                private storage: AngularFireStorage,
				private functions:OrganizationFunctions,
                private repo:OrganizationRepository,
                protected logger:NGXLogger) {

		this._paging = {
			list: new PageListService<Organization>(this.repo),
		}
    }

	public verifyUser$(orgGuid:string, userToVerifyGuid:string, shouldBeVerified:boolean, title?:string):Observable<TriState> {
        this.logger.info(`starting the verifyUser$ method on OrganizationService to verify ${userToVerifyGuid} as ${shouldBeVerified}...`);
        let wasSuccessful$:BehaviorSubject<TriState> = new BehaviorSubject({pending: true, wasSuccessful: false});

		let verificationMessage:OrganizationVerificationMessage = new OrganizationVerificationMessage();
            verificationMessage.orgGuid = orgGuid;
            verificationMessage.userToVerifyGuid = userToVerifyGuid;
            verificationMessage.isVerified = shouldBeVerified;
            verificationMessage.userTitle = title;
            //currentUser is set via auth

        try {
            this.logger.info(`calling verifyUserWithOrg$ method on firestore functions from OrganizationService`);
            this.functions.verifyUserWithOrg$(verificationMessage).subscribe(organization => {
                wasSuccessful$.next({pending: false, wasSuccessful: true});
            });
        } catch (error) {
            this.logger.error(error);
            wasSuccessful$.next({pending: false, wasSuccessful: false});
        }

		this.logger.info(`end of verifyUser$ method on OrganizationService.  Awaiting response.`);
        return wasSuccessful$;
	}

	public get store() {
    	return this.repo.accessors();
	}
	public get by() {
    	return this.repo.by;
	}

    /** Get an Organization */
    public getOrganization$(organizationGuid:string, preferCache:boolean = true):Observable<Organization> {
        return this.repo.get$(organizationGuid, preferCache);
    }

	/** Watch an Organization */
	public watchOrganization$(organizationGuid:string):Observable<Organization> {
		return this.repo.watch$(organizationGuid);
	}

    public inviteUserToOrg$(organizationGuid:string, waihonaUserToInviteGuid:string) {
    	return this.functions.inviteUserToOrg$(new InviteUserMessage(organizationGuid, waihonaUserToInviteGuid, null));
	}

	public warmUpInviteUserToOrg() {
		return this.functions.warmUpInviteUserToOrg$();
	}

    public inviteUserToOrgByEmail$(organizationGuid:string, emailsToInvite:string[]) {
        return this.functions.inviteUserToOrg$(new InviteUserMessage(organizationGuid, null, emailsToInvite));
    }

    /**
    *
    * @param {organization} Organization
    * @throws {ValidationException} ValidationException
    */
    public save$(organization:Organization):Observable<Organization> {
    	console.log(`Organization Service is saving the org ${organization.guid}.`);
        /*const errors:ValidationError[] = validateSync(organization);

		if (errors.length > 0) {
            this.logger.error("validation failed. errors: ", errors);
            throw new ValidationException(errors);
        }*/
        return this.repo.save$(organization);
    }

    public updatePartial$(organization:Organization, instructions:{[key:string]:IPartialInstruction|{}|boolean}):Observable<Organization> {
	    return this.repo.updatePartial$(organization, instructions);
    }

    public validate(organization:Organization) {
        const errors:ValidationError[] = validateSync(organization);
        if (errors.length > 0) {
          this.logger.info("validation failed. errors: ", errors);
          throw new ValidationException(errors);
        }
    }

    public receiveRequestToJoinOrg(organization:Organization, waihonaUserRef:WaihonaUserRef):Observable<Organization> {
    	this.logger.info(`OrganizationService::receiveRequestToJoinOrg handling request for user ${waihonaUserRef.guid} to join organization ${organization.guid}`);

    	//verify the user isn't already in the organization or pending approval
		if ((organization.usersPendingApproval.find(user => (user.guid == waihonaUserRef.guid))) && (organization.members.find(user => (user.guid == waihonaUserRef.guid)))) {
			console.error(`OrganizationService::receiveRequestToJoinOrg user is already in this org!`);
			return null;
		}

    	organization.usersPendingApproval.push(waihonaUserRef);
		return this.repo.save$(organization);
	}

}
