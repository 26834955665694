import { NgModule } from '@angular/core';
//Routing
import { ResourcesRoutingModule } from './resources-routing.module';
import {ResourcesCommonModule} from "./common/resources-common.module";
import {CommonModule} from "@angular/common";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {QuillModule} from "ngx-quill";
import {ToasterModule} from "angular2-toaster";
import {TreeModule} from "angular-tree-component";
import {FrameworkSelectComponent} from "./create/single/edit-resource-submission/subcomponents/framework-select/framework-select.component";
import {EditResourceSubmissionComponent} from "./create/single/edit-resource-submission/edit-resource-submission.component";
import {ResourceSubmissionPreviewComponent} from "./create/single/resource-submission-preview/resource-submission-preview.component";
import {StubData} from "../../data/StubData";
import {ResourceSubmissionService} from "../../services/ResourceSubmissionService";
import {ConversionService} from "../../services/ConversionService";
import {ListPublishedResourcesComponent} from "./live/multiple/list-live-resources/list-published-resources.component";
import {ListResourceSubmissionsComponent} from "./create/multiple/list-resource-submissions/list-resource-submissions.component";
import {ViewPublishedResourceComponent} from "./create/single/view-published-resource/view-published-resource.component";
import {ReviewService} from "../../services/ReviewService";
import {AinaService} from "../../services/AinaService";
import {LessonContentComponent} from "./live/single/lesson-content/lesson-content.component";
import {SharedModule} from "../shared/shared.module";
import {ReviewAsideComponent} from "./review/review-aside";
import {ReviewChatComponent} from "./review/review-chat/review-chat.component";
import {ResourceChatRepository} from "../../services/repository/ResourceChatRepository";
import {ResourceChatService} from "../../services/ResourceChatService";
import {ComponentCommonModule} from "../common/component-common.module";
import {ImageCropperModule} from "ngx-image-cropper";
import {DefaultStorageBucket} from "../../services/storage/DefaultStorageBucket";
import {CommonDirectivesModule} from "../shared/CommonDirectivesModule";
import {FanContributionRepository} from "../../services/repository/FanContributionRepository";
import {AngularStickyThingsModule} from "@w11k/angular-sticky-things";
import {EditResourceSubmissionHelperService} from "../../services/EditResourceSubmissionHelperService";
import { FanContributionReviewComponent } from './create/fan-contribution-review/fan-contribution-review.component';
import { FrameworkChooserComponent } from './create/single/edit-resource-submission/subcomponents/framework-chooser/framework-chooser.component';
import {ContributorDropDownComponent} from "../common/contributor-drop-down/contributor-drop-down/contributor-drop-down.component";


const SERVICES = [
    ResourceSubmissionService,
	EditResourceSubmissionHelperService,
    ReviewService,
    AinaService,
    ConversionService,
    ResourceChatService,
    DefaultStorageBucket
];

const REPOSITORIES = [
    ResourceChatRepository,
	FanContributionRepository
];

@NgModule({
	imports: [
		CommonModule,
		ResourcesRoutingModule,
		SharedModule,
		QuillModule.forRoot(),
		ImageCropperModule,

		//TODO: Go through everything below and resolve:
		BsDropdownModule.forRoot(),
		FormsModule,
		NgSelectModule,
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		AngularFireFunctionsModule,
		PopoverModule.forRoot(),
		ResourcesCommonModule,
		ToasterModule.forChild(),
		TreeModule.forRoot(),
		ResourcesCommonModule,
		ModalModule.forRoot(),
		AccordionModule.forRoot(),
		TooltipModule.forRoot(),
		ModalModule.forRoot(),
		TabsModule,
		ComponentCommonModule,
		CommonDirectivesModule,
		AngularStickyThingsModule
	],
    declarations: [
        FrameworkSelectComponent,
        EditResourceSubmissionComponent,
        ViewPublishedResourceComponent,
        ListResourceSubmissionsComponent,
        ListPublishedResourcesComponent,
        ReviewAsideComponent,
        ReviewChatComponent,
        ResourceSubmissionPreviewComponent,
        LessonContentComponent,
        FanContributionReviewComponent,
        FrameworkChooserComponent,
    ],
    providers: [
        StubData,
        ...SERVICES,
        ...REPOSITORIES,

        ConversionService
    ],
    exports: [
        ReviewAsideComponent,
        FrameworkSelectComponent
    ]
})
export class ResourcesModule { }
