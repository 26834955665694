import {RegisterClazz} from "../RegisterType";

@RegisterClazz(ContactInfo)
export class ContactInfo {
    /** The type of contact info (Enum) */
    public type:ContactInfoType;
    /** The value to display **/
    public value:string;

    constructor(type:ContactInfoType, value:string) {
        this.type = type;
        this.value = value;
    }
}

/**
 * The type phone,email, website, etc
 */
export enum ContactInfoType {
    "phone" = "phone",
    "email" = "email",
    "website" = "website",
    "address" = "address",
    "gps" = "gps",
    "leader" = "leader",
    "island" = "island",
    "city" = "city"
}
