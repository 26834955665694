<div class="full-site">

  <div class="app-header-container">
    <ng-container *ngIf="usingIE && showIEAlert">
      <div id="ie-alert" class="alert alert-dismissible fade show" [ngClass]="'alert-info'" style="top: 0; left: 0; margin-bottom: 0;" role="alert">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        <span class="font-weight-bold" [innerHTML]=" CB.ie_alert | localize:{'no-cache': true, 'trusted-html': true, url: 'https://www.google.com/chrome/'} | async"></span>
        <button type="button" class="close" data-dismiss="alert" (click)="onDismissIEAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-container>
    <div *ngIf="!isProduction" [ngClass]="env" class="header-environment">Welcome to the {{prettyEnv}} Server</div>
    <app-header></app-header>
  </div>

  <div class="app-body">
    <!--  <sidebar></sidebar>-->

    <!-- Main content -->
    <main class="main">
      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <breadcrumbs></breadcrumbs>
      </ol>
      <div class="padding-container" [ngClass]="{disabled: childIsHomepage}">
        <router-outlet (activate)="this.routerActivated = true"></router-outlet>
      </div>

      <div [ngClass]='{"extra-bottom-margin": usingIE}'></div>
    </main>
  </div>

  <div class="app-footer-container">
    <app-footer></app-footer>
  </div>

</div>

