import {IndexProperty} from "../../../../domain/IndexProperty";
import {Exclude, Type} from "class-transformer";
import {IsNotEmpty, MinLength} from "class-validator";

export class AudienceRef {
	public guid:string = null;
}
export class EventContext {
	public guid:string = null;
	@IndexProperty({property: "guid"})
	public objects:Array<ObjectContextRef> = [];
	public description:string = null;
}
export class EventContextRef {
	public guid:string = null;
}

export class EventTypeRef {
	public guid:string = null;
}

export class AudienceMapping {
	public guid:string = null;
	@Type(()=>AudienceRef)
	public audience:AudienceRef = null;
	@Type(()=>EventTypeRef)
	public eventType:EventTypeRef = null;
	@Type(()=>EventContextRef)
	public eventContext:EventContextRef = null;

	public generateGuid():void {
		if (this.guid == null) {
			let newGuid:string = `${this.eventType.guid}-${this.audience}`;
			this.guid = newGuid;
		}
	}

}
export class Audience {
	public guid:string = null;
	public description:string = null;

	@IndexProperty({property: "guid"})
	@Type(()=>EventContextRef)
	public eventContexts:Array<EventContextRef> = []; //required Objects
}

export class EventType {
	@Exclude({toPlainOnly:true})
	public guid:string = null;

	@Type(()=>EventContextRef)
	public contexts:Array<EventContextRef> = [];

	@IsNotEmpty()
	@MinLength(5)
	public name:string = null;

	@IsNotEmpty()
	@MinLength(5)
	public description:string = null;

	//Master On-Off switch for Event Type (does not go on ref)
	public enabled:boolean = false;

	//Category/tags the event type fits under
	public tags:Array<string> = [];
}



//---

export class Template {
	public templateId:string = "";
	public templateSourceType:TemplateSourceType;

	constructor(templateId:string, sourceType:TemplateSourceType) {
		this.templateId = templateId;
		this.templateSourceType = sourceType;
	}
}

export enum TemplateSourceType {
	external = "external",
	internal = "internal"
}

export class ObjectContext {
	public guid:string = null;
}
export class ObjectContextRef {
	public guid:string = null;
}

export class Variables {
	public documentPathId:string = null;
	public variables:{} = null;

	constructor(variables:{} = null, documentPathId:string = null) {
		this.variables = variables;
		this.documentPathId = documentPathId;
		if (variables == null && documentPathId == null) {
			throw new Error(`Must create variables or pass a document path`)
		}
	}
}

export class EventMapping {
	@Exclude({toPlainOnly: true})
	public guid:string = null;
	public eventType:EventTypeRef = null;
	public eventContext:EventContextRef = null;//only one context per event type;
	public audience:AudienceRef = null; //only one audience per event context

	public configuration:EventMappingConfiguration = null;
	public mappingDestination:MappingDestination = null;
	public template:Template = null;
	public variables:Variables = null;

	public enabled:boolean = false;
	public labels:Array<string> = [];
	public description:string = "";
}
export class EventMappingRef {
	public guid:string = null;
}

export class EventMappingConfiguration {
	public guid:string;
	public name:string;
	public description:string;
	public expirations:Array<ExpirationType> = [];
}

export class EventMappingConfigurationRef {
	public guid:string;
}

export class ExpirationType {
	public guid:string = null;
	public description:string = "";
	public times:number = 1;
	public perType:PerTypeEnum;

	constructor(times:number, perType:PerTypeEnum) {
		this.times = times;
		this.perType = perType;
	}
}
export class EventSendingLog {
	public receiverGuid:string;
	public eventMapping:EventMapping;

}

export class MappingDestination {
	@Exclude({toPlainOnly: true})
	public guid:string = null;

	public target:MappingTypeEnum = null;
	public provider:ProviderEnum = null;
	public enabled:boolean = false;
	public selectable:boolean = false;

	public toString():string {
		return `${this.target} via ${this.provider}`;
	}
}
export class MappingDestinationRef {
	public guid:string = null;
}

export enum ProviderEnum {
	"Mandrill"="Mandrill",
	"Zapier"="Zapier"
}

export enum PerTypeEnum {
	"receieverGuid" = "receiverGuid",
	"minutes" = "timeout",
	"eventType" = "eventType",
}

export enum MappingTypeEnum {
	"email" = "email",
	"zap" = "zap",

	//--first two probably the above --.
	"slack" = "slack",
	"sms" = "sms",
	"mms" = "mms",
	"phonevoice" = "phonevoice",
	"push" = "push",
	//in-app
	//browser-notifications
	//chaining [browser, email]
}

export class EnumToArray {
	public static convert<T>(EnumReference):Array<T> {

		let keys:string[] = Object.keys(EnumReference)
		let values:T[] = keys.map(k => EnumReference[k as any]).map(v => v as T);

		return values;

	}
}