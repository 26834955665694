import {IsNotEmpty, MaxLength} from 'class-validator';
import {Exclude} from 'class-transformer';
import {RegisterClazz} from "../RegisterType";
import {SupportedLanguage} from "../SupportedLanguages";
import {ITextContent} from "../ITextContent";
import {MaxPlainTextLength} from "../../validators/MaxPlainTextLength";

@RegisterClazz(WaihonaUserTextContent)
export class WaihonaUserTextContent implements ITextContent {


	@Exclude()
	public $render:{ [key:string]:SupportedLanguage };

	@MaxPlainTextLength(3000)
	@IsNotEmpty()
	public bio:string = "";

	constructor() {
		this.$render = {};
	}

}
