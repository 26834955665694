<div class="animated fadeIn horizontal-card-deck" *ngIf="collection != null">
	<h1 class="display-5 text-primary font-weight-bold mt-4"></h1><br>
	<div class="card">
		<div class="card-body">
			<div class="card border border-0">
				<div class="card-header bg-white border-0">
					<div class="row">
						<div class="col-md-8 col-xl-10">
							<h2 class="card-title font-weight-bold text-primary">{{localize.collection().title}}
								<small class="text-muted">
									<i class="collection-visibility-icon" [ngClass]="CollectionVisibilityTypeIcon[collection.configuration.visibilityType]" tooltip="{{CollectionVisibilityTypePretty[collection.configuration.visibilityType]}}"></i>
								</small>
							</h2>
						</div>
						<div class="col-md-4 col-xl-2">
							<button *ngIf="visual.canEdit()" class="btn btn-primary text-white float-md-right mt-1 mb-3 my-md-0" (click)="router.navigate(['/collections', collection.guid, 'edit'])">{{CB.button_edit | localize | async}}</button>
						</div>
					</div>
					<p>{{localize.collection().summary}}</p>
					<p *ngIf="collection.owner.isOrg()" class="font-italic">{{CB.curated_by | localize: {name: collection.owner.name} | async}}</p>
					<div *ngIf="collection.owner.isUser()"  class="row ml-1">
						<a role="button" href="javascript:void(0);" [routerLink]="['/users', collection.owner.guid]" tooltip="{{collection.owner.name}}">
							<avatar-component [userRef]="collection.owner.userRef" [class]="'select-image'"></avatar-component>
						</a>
						<span *ngFor="let collaborator of collection.collaborators">
							<a role="button" href="javascript:void(0);" [routerLink]="['/users', collaborator.guid]" tooltip="{{collaborator.preferredFirstName}} {{collaborator.lastName}}">
								<avatar-component [userRef]="collaborator" [class]="'select-image'"></avatar-component>
							</a>
						</span>
					</div>
				</div>
				<div class="card-body">
					<div class="card">
						<div class="card-header bg-secondary text-primary">
							<div class="row d-flex align-items-center">
								<div class="col-md-2">
									<view-switcher [views]="[ViewType.list, ViewType.grid]" [extraClassString]="'fa-2x'" [currentView]="collectionViewMode" (isActive)="collectionViewMode = $event"></view-switcher>
								</div>
								<div class="col-md">
									<h4 class="font-weight-bold text-white my-2">{{CB.table_header | localize: {collectionType: collection.configuration.collectionType} | async}}:</h4>
								</div>
								<div class="col-md-4">
									<div class="float-md-right">
										<app-search (searchValue)="setSearchValue($event)"></app-search>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<!--List view-->
							<ng-container *ngIf="collectionViewMode == ViewType.list && collection?.items?.length > 0">
								<ul *ngFor="let item of collection.items | listFilter:currentSearchValue:['localization.title.en', 'localization.title.haw', 'localization.summary.en', 'localization.summary.haw', 'submitter.firstName', 'submitter.lastName', 'organization.title']; let isLast=last" class="pl-0">
									<li class="row pb-3" [class.border-bottom]="!isLast">
										<div class="col-md-3 col-lg-2">
											<a role="button" href="javascript:void(0);" [routerLink]="['/resources', item.guid]">
												<img *ngIf="item.configuration?.hasImage" class="card-img-side-smaller" src="{{visual.resourceImage(item)}}" alt="resource cover">
											</a>
										</div>
										<div class="col-md-6 col-lg-8">
											<a role="button" href="javascript:void(0);" [routerLink]="['/resources', item.guid]">
												<h5 class="font-weight-bold">{{localize.resourceTitle(item)}}</h5>
											</a>
											<p class="font-italic text-muted">Contributed by: {{resourceRefSubmitterName(item)}}</p>
											<p><strong>Summary: </strong>{{localize.resourceSummary(item)}}</p>
										</div>
										<div class="col-md-3 col-lg-2">
										</div>
									</li>
								</ul>
							</ng-container>
							<!--Grid view-->
							<ng-container *ngIf="collectionViewMode == ViewType.grid && collection?.items?.length > 0">
								<div class="card-deck translucent-on-hover-deck">
									<div *ngFor="let resourceRef of collection.items | listFilter:currentSearchValue:['localization.title.en', 'localization.title.haw', 'localization.summary.en', 'localization.summary.haw', 'submitter.firstName', 'submitter.lastName', 'organization.title']">
										<div class="mini-card card-inverse animated fadeInLeft">
											<a href="javascript:void(0);" [routerLink]="['/resources', resourceRef.guid]" style="text-decoration: none;">
												<div *ngIf="resourceRef?.configuration?.hasImage; else noImg" style="display: contents">
													<img class="card-img-top" src="{{visual.resourceImage(resourceRef)}}" alt="cover image">
												</div>
												<ng-template #noImg>
													<div class="card-img-top bg-dark"></div>
												</ng-template>
												<div class="card-img-overlay">
													<h6 class="card-title font-weight-bold">{{localize.resourceTitle(resourceRef)}}</h6>
												</div>
											</a>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="card-footer">
							<div *ngIf="visual.canEdit()" role="button" class="text-primary font-weight-bold" (click)="router.navigate(['/collections', collection.guid, 'edit'])">+ {{CB.add_or_edit | localize | async}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
