import {Injectable} from "@angular/core";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {RegistrationInviteCode} from "../../domain/invites/RegistrationInviteCode";

@Injectable()
export class InvitationCodeRepository extends AbstractFirestoreRepository<RegistrationInviteCode> {

	constructor (protected db:AngularFirestore) {
		super(RegistrationInviteCode, db, db.collection("Invites"));

	}

	public byOrganizationGuid(orgGuid:string):QueryFn {
		let queryFn:QueryFn  = ref => {
			return ref.where("orgGuid", '==', orgGuid);
		};
		return queryFn;
	}

}
