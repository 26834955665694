import {Type} from "class-transformer";
import {WaihonaUser} from "../../../domain/user/WaihonaUser";
import {RegisterClazz, RegisterType} from "../../../domain/RegisterType";

@RegisterClazz({clazz: Token})
export class Token {
	public token:string = "";
	public guid:string = "";

	@Type(()=> WaihonaUser)
	@RegisterType({clazz: WaihonaUser})
	public waihonaUser:WaihonaUser;

	constructor(guid:string, token:string, waihonaUser:any) {
		this.guid = guid;
		this.token = token;
		this.waihonaUser = waihonaUser;
	}
}
