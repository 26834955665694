import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {LocalizationService} from "../services/LocalizationService";
import {AsyncPipe} from "@angular/common";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import * as Mustache from "mustache";

@Pipe({
	name: 'localize'
})
export class LocalizePipe implements PipeTransform  {

	constructor(private localizationService:LocalizationService) {

	}

	/**
	 * Transforms the input
	 * @param key the CB item in Localization
	 * @param params pass a 'no-cache': true param to NOT recycle the same observable (good for ngFor loops)
	 */
	public transform(key, params?:{}):Observable<string|Function> {
		let localizationObservable:Observable<string>;
		if(typeof key == "string") {
			return new BehaviorSubject<string|Function>(key);  //Just use the string as passed
		} else {
			localizationObservable = this.localizationService.fetch$(key,params) as Subject<any>;
		}

		return localizationObservable;
	}

}

/*


import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {LocalizationService} from "../services/LocalizationService";
import {AsyncPipe} from "@angular/common";
import {BehaviorSubject, Observable, ObservableInput, ReplaySubject, Subject, Subscription} from "rxjs";
import * as Mustache from "mustache";
import {UnsubscriptionError} from "rxjs/src/internal/util/UnsubscriptionError";

@Pipe({
	name: 'localize',
	pure: false,

})
export class LocalizePipe implements PipeTransform, OnDestroy  {
	protected _subscription:Subscription;
	protected _otherSubscriptions:Array<Subscription> = [];

	constructor(private localizationService:LocalizationService) {

	}

	public transform(key, params?:{[key:string]:string|Function|Observable<string|Function>}):Observable<string|Function> {
		let localizationObservable:Subject<string|Function>;


		if(typeof key == "string") {
			return new BehaviorSubject<string|Function>(key);  //Just use the string as passed
		} else if(params != null) {
			let computedParams:{[key:string]:string|Function} = {};
			let paramKeys:Array<string> = Object.keys(params);
			localizationObservable = new Subject<string|Function>();

			let numberOfObservables:number = 0;

			//Iterate over the params, some will be strings or functions, others observables.
			for(let i:number = 0; i < paramKeys.length; i++) {
				let paramKey:string = paramKeys[i];
				let param:string|Function|Observable<string|Function> = params[paramKey];

				//Observable? Listen to it and update the localization variable
				if(param instanceof Observable) {
					numberOfObservables++;
					let paramAsObservable:Observable<{}> = param as Observable<string|Function>;

					if(paramAsObservable instanceof BehaviorSubject) {
						let paramAsBehaviorSubject:BehaviorSubject<string|Function> = paramAsObservable;
						computedParams[key] = paramAsBehaviorSubject.getValue();
					}
					//this._otherSubscriptions.push(  )
					paramAsObservable.subscribe((value:string|Function) => {
						computedParams[paramKey] = value;

						let s:Subscription = (this.localizationService.fetch$(paramKey, computedParams) as Subject<any>).catch((err:any, caught:Observable<any>):ObservableInput<{}> => {
							this.removeOtherSubscription(s);
							return caught;
						}).subscribe((value:string|Function) => {
							localizationObservable.next(value);
							this.removeOtherSubscription(s);
						});
					});
				} else {
					computedParams[paramKey] = param;
				}
			}

			localizationObservable = this.localizationService.fetch$(key,computedParams) as Subject<any>;

		} else {
			localizationObservable = this.localizationService.fetch$(key) as Subject<any>;

		}


		return localizationObservable;

	}

	ngOnDestroy():void {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
		while(this._otherSubscriptions.length > 0) {
			let s:Subscription = this._otherSubscriptions[0];
			this.removeOtherSubscription(s);
		}
	}

	private removeOtherSubscription(s:Subscription) {
		try {
			s.unsubscribe();
		} catch(error) {
			//UnsubscriptionError: unable to unsubscribe
		}
		let index:number = this._otherSubscriptions.indexOf(s);
		if(index > -1) {
			this._otherSubscriptions.splice(index, 1);
		}
	}

}


 */
