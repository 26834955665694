<div class="modal-header bg-secondary">
	<h4 class="modal-title text-white font-weight-bold"><i class="fas fa-lock mr-2"></i>{{CB.title | localize | async}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	{{CB.body | localize | async}}
</div>

<div class="modal-footer border-0">
	<button type="button" class="btn btn-primary" (click)="component.onClickLogin()">{{CB.login | localize | async}}</button>
	<button type="button" class="btn btn-light" (click)="component.onClickRegistration()">{{CB.register | localize | async}}</button>
	<button type="button" class="btn btn-light" (click)="component.onClickCancel()">{{CB.cancel | localize | async}}</button>
</div>
