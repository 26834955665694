import {NupepafyStringUtils} from "../util/NupepafyStringUtils";
import {IslandColor, Moku} from "../domain/resources/aina/Moku";
import {Aina} from "../domain/resources/aina/Aina";

export class AinaStubData {

	constructor() {

	}

/*
	public getNaMoku():Array<Moku> {
		let i:number = 0;
		return [
			new Moku("Ka Pae ʻĀina ʻo Hawaiʻi", "", null, i++),
			new Moku("Papahānaumokuākea", "", null, i++),
			new Moku("Nā Kai ʻEwalu", "", null, i++),

			new Moku("Niʻihau Island", "", IslandColor.niihau, i++),
			new Moku("Niʻihau","Koʻolau", IslandColor.niihau, i++),
			new Moku("Niʻihau","Puna", IslandColor.niihau, i++),
			new Moku("Niʻihau","Kona", IslandColor.niihau, i++),

			new Moku("Kauaʻi Island", "", IslandColor.kauai, i++),
			new Moku("Kauaʻi","Puna", IslandColor.kauai, i++),
			new Moku("Kauaʻi","Kona", IslandColor.kauai, i++),
			new Moku("Kauaʻi", "Nā Pali", IslandColor.kauai, i++),
			new Moku("Kauaʻi","Haleleʻa", IslandColor.kauai, i++),
			new Moku("Kauaʻi","Koʻolau", IslandColor.kauai, i++),

			new Moku("Oʻahu Island", "", IslandColor.oahu, i++),
			new Moku("Oʻahu","Koʻolaupoko", IslandColor.oahu, i++),
			new Moku("Oʻahu","Kona", IslandColor.oahu, i++),
			new Moku("Oʻahu","ʻEwa", IslandColor.oahu, i++),
			new Moku("Oʻahu","Waiʻanae", IslandColor.oahu, i++),
			new Moku("Oʻahu","Waialua", IslandColor.oahu, i++),
			new Moku("Oʻahu", "Koʻolauloa", IslandColor.oahu, i++),

			new Moku("Molokaʻi Island","", IslandColor.molokai, i++),
			new Moku("Molokaʻi","Kona", IslandColor.molokai, i++),
			new Moku("Molokaʻi","Kaluakoʻi", IslandColor.molokai, i++),
			new Moku("Molokaʻi","Pālāʻau", IslandColor.molokai, i++),
			new Moku("Molokaʻi","Koʻolau", IslandColor.molokai, i++),
			new Moku("Molokaʻi","Hālawa", IslandColor.molokai, i++),

			new Moku("Lānaʻi Island", "", IslandColor.lanai, i++),
			new Moku("Lānaʻi", "Koʻolau", IslandColor.lanai, i++),
			new Moku("Lānaʻi","Kona", IslandColor.lanai, i++),

			new Moku("Kahoʻolawe Island", "", IslandColor.kahoolawe, i++),
			new Moku("Kahoʻolawe", "Koʻolau", IslandColor.kahoolawe, i++),
			new Moku("Kahoʻolawe", "Kona", IslandColor.kahoolawe, i++),

			new Moku("Maui Island", "", IslandColor.maui, i++),
			new Moku("Maui", "Hāna", IslandColor.maui, i++),
			new Moku("Maui", "Kīpahulu", IslandColor.maui, i++),
			new Moku("Maui", "Kaupō", IslandColor.maui, i++),
			new Moku("Maui", "Kahikinui", IslandColor.maui, i++),
			new Moku("Maui", "Honuaʻula", IslandColor.maui, i++),
			new Moku("Maui", "Kula", IslandColor.maui, i++),
			new Moku("Maui", "Wailuku", IslandColor.maui, i++),
			new Moku("Maui", "Lāhainā", IslandColor.maui, i++),
			new Moku("Maui", "Kāʻanapali", IslandColor.maui, i++),
			new Moku("Maui", "Hāmākuapoko", IslandColor.maui, i++),
			new Moku("Maui", "Hāmākualoa", IslandColor.maui, i++),
			new Moku("Maui", "Koʻolau", IslandColor.maui, i++),

			new Moku("Hawaiʻi Island","", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Puna", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Kaʻū", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Kona", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Kohala", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Hāmākua", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Hilo", IslandColor.hawaiiIsland, i++),
			new Moku("Hawaiʻi","Maunakea", IslandColor.hawaiiIsland, i++)
		];

	}
*/

	public formerMoku():Array<Moku> {
		return [
				new Moku("Niʻihau","Koʻolau", IslandColor.niihau),
				new Moku("Niʻihau","Puna", IslandColor.niihau),
				new Moku("Niʻihau","Kona", IslandColor.niihau),

				new Moku("Kauaʻi","Haleleʻa", IslandColor.kauai),
				new Moku("Kauaʻi","Koʻolau", IslandColor.kauai),
				new Moku("Kauaʻi","Puna", IslandColor.kauai),
				new Moku("Kauaʻi","Kona", IslandColor.kauai),
				new Moku("Kauaʻi","Mānā", IslandColor.kauai),
				new Moku("Kauaʻi", "Nāpali", IslandColor.kauai),

				new Moku("Oʻahu", "Koʻolauloa", IslandColor.oahu),
				new Moku("Oʻahu","Koʻolaupoko", IslandColor.oahu),
				new Moku("Oʻahu","Kona", IslandColor.oahu),
				new Moku("Oʻahu","ʻEwa", IslandColor.oahu),
				new Moku("Oʻahu","Waiʻanae", IslandColor.oahu),
				new Moku("Oʻahu","Waialua", IslandColor.oahu),

				new Moku("Molokaʻi","Koʻolau", IslandColor.molokai),
				new Moku("Molokaʻi","Hālawa", IslandColor.molokai),
				new Moku("Molokaʻi","Kona", IslandColor.molokai),
				new Moku("Molokaʻi","Pālāʻau", IslandColor.molokai),
				new Moku("Molokaʻi","Kaluakoʻi", IslandColor.molokai),

				new Moku("Lānaʻi", "Koʻolau", IslandColor.lanai),
				new Moku("Lānaʻi","Kona", IslandColor.lanai),

				new Moku("Kahoʻolawe", "Koʻolau", IslandColor.kahoolawe),
				new Moku("Kahoʻolawe", "Kona", IslandColor.kahoolawe),

				new Moku("Maui", "Hāmākualoa", IslandColor.maui),
				new Moku("Maui", "Koʻolau", IslandColor.maui),
				new Moku("Maui", "Hāna", IslandColor.maui),
				new Moku("Maui", "Kīpahulu", IslandColor.maui),
				new Moku("Maui", "Kaupō", IslandColor.maui),
				new Moku("Maui", "Kahikinui", IslandColor.maui),
				new Moku("Maui", "Honuaʻula", IslandColor.maui),
				new Moku("Maui", "Kula", IslandColor.maui),
				new Moku("Maui", "Kealaloloa", IslandColor.maui),
				new Moku("Maui", "Lahaina", IslandColor.maui),
				new Moku("Maui", "Kāʻanapali", IslandColor.maui),
				new Moku("Maui", "Wailuku", IslandColor.maui),
				new Moku("Maui", "Hāmākuapoko", IslandColor.maui),

				new Moku("Hawaiʻi","Hāmākua", IslandColor.hawaiiIsland),
				new Moku("Hawaiʻi","Hilo", IslandColor.hawaiiIsland),
				new Moku("Hawaiʻi","Puna", IslandColor.hawaiiIsland),
				new Moku("Hawaiʻi","Kaʻū", IslandColor.hawaiiIsland),
				new Moku("Hawaiʻi","Kona", IslandColor.hawaiiIsland),
				new Moku("Hawaiʻi","Kohala", IslandColor.hawaiiIsland)
			];
	}

/*	public getAina():Array<Aina> {

		let naMoku:Array<Moku> = this.getNaMoku();
		let naAina:Array<Aina> = new Array<Aina>();

		for(let i:number = 0; i < naMoku.length; i++) {

			let moku:Moku = naMoku[i];
			let aina:Aina = new Aina();
				aina.guid = NupepafyStringUtils.generateGuidFromTwoParts(moku.mokupuni, moku.moku);

			naAina.push(aina);
		}

		return naAina;

	}*/
	public outputOldData() {
		this.formerMoku();
	}

	public beforeAndAfter() {

		let newOtherAreas = {
			"00-ka-pae--aina-o-hawaii":"00-ka-pae--aina-o-hawaii",
			"01-papahanaumokuakea":"01-papahanaumokuakea",
			"02-na-kai--ewalu":"02-na-kai--ewalu"
		};

		let newExtraMoku = {
			"52-hawaii-maunakea":"52-hawaii-maunakea"
		};

		let newIslands = {
			"03-niihau-island":"03-niihau-island",
			"07-kauai-island":"07-kauai-island",
			"13-oahu-island":"13-oahu-island",
			"20-molokai-island":"20-molokai-island",
			"26-lanai-island":"26-lanai-island",
			"29-kahoolawe-island":"29-kahoolawe-island",
			"32-maui-island":"32-maui-island",
			"45-hawaii-island":"45-hawaii-island",
		};

		let nowMissing = {
			"kauai-mana": "kauai-mana",
		};


		//After is on the right side (value).  Before guid is the key.
		let afterIsOnTheRightSide:{[before:string]:string} = {
			"niihau-koolau":"04-niihau-koolau",
			"niihau-puna":"05-niihau-puna",
			"niihau-kona":"06-niihau-kona",

			"kauai-halelea":"11-kauai-halelea",
			"kauai-koolau":"12-kauai-koolau",
			"kauai-puna":"08-kauai-puna",
			"kauai-kona":"09-kauai-kona",
			"kauai-mana":"", //missing
			"kauai-napali":"10-kauai-na-pali",

			"oahu-koolauloa":"19-oahu-koolauloa",
			"oahu-koolaupoko":"14-oahu-koolaupoko",
			"oahu-kona":"15-oahu-kona",
			"oahu-ewa":"16-oahu--ewa",
			"oahu-waianae":"17-oahu-waianae",
			"oahu-waialua":"18-oahu-waialua",

			"molokai-koolau":"24-molokai-koolau",
			"molokai-halawa":"25-molokai-halawa",
			"molokai-kona":"21-molokai-kona",
			"molokai-palaau":"23-molokai-palaau",
			"molokai-kaluakoi":"22-molokai-kaluakoi",

			"lanai-koolau":"27-lanai-koolau",
			"lanai-kona":"28-lanai-kona",

			"kahoolawe-koolau":"30-kahoolawe-koolau",
			"kahoolawe-kona":"31-kahoolawe-kona",

			"maui-hamakualoa":"43-maui-hamakualoa",
			"maui-koolau":"44-maui-koolau",
			"maui-hana":"33-maui-hana",
			"maui-kipahulu":"34-maui-kipahulu",
			"maui-kaupo":"35-maui-kaupo",
			"maui-kahikinui":"36-maui-kahikinui",
			"maui-honuaula":"37-maui-honuaula",
			"maui-kula":"38-maui-kula",
			"maui-kealaloloa":"", //missing

			"maui-lahaina":"40-maui-lahaina",
			"maui-kaanapali":"41-maui-kaanapali",
			"maui-wailuku":"39-maui-wailuku",
			"maui-hamakuapoko":"42-maui-hamakuapoko",

			"hawaii-hamakua":"50-hawaii-hamakua",
			"hawaii-hilo":"51-hawaii-hilo",
			"hawaii-puna":"46-hawaii-puna",
			"hawaii-kau":"47-hawaii-kau",
			"hawaii-kona":"48-hawaii-kona",
			"hawaii-kohala":"49-hawaii-kohala"
		};

		let o = {
			afterIsOnTheRightSide:afterIsOnTheRightSide,
			newOtherAreas:newOtherAreas,
			newExtraMoku:newExtraMoku,
			newIslands:newIslands,
			nowMissing:nowMissing,
		};

		return o;
	}
	
	public currentData():Array<string> {
		return [
			"00-ka-pae--aina-o-hawaii",
			"01-papahanaumokuakea",
			"02-na-kai--ewalu",
			"03-niihau-island",
			"04-niihau-koolau",
			"05-niihau-puna",
			"06-niihau-kona",
			"07-kauai-island",
			"08-kauai-puna",
			"09-kauai-kona",
			"10-kauai-na-pali",
			"11-kauai-halelea",
			"12-kauai-koolau",
			"13-oahu-island",
			"14-oahu-koolaupoko",
			"15-oahu-kona",
			"16-oahu--ewa",
			"17-oahu-waianae",
			"18-oahu-waialua",
			"19-oahu-koolauloa",
			"20-molokai-island",
			"21-molokai-kona",
			"22-molokai-kaluakoi",
			"23-molokai-palaau",
			"24-molokai-koolau",
			"25-molokai-halawa",
			"26-lanai-island",
			"27-lanai-koolau",
			"28-lanai-kona",
			"29-kahoolawe-island",
			"30-kahoolawe-koolau",
			"31-kahoolawe-kona",
			"32-maui-island",
			"33-maui-hana",
			"34-maui-kipahulu",
			"35-maui-kaupo",
			"36-maui-kahikinui",
			"37-maui-honuaula",
			"38-maui-kula",
			"39-maui-wailuku",
			"40-maui-lahaina",
			"41-maui-kaanapali",
			"42-maui-hamakuapoko",
			"43-maui-hamakualoa",
			"44-maui-koolau",
			"45-hawaii-island",
			"46-hawaii-puna",
			"47-hawaii-kau",
			"48-hawaii-kona",
			"49-hawaii-kohala",
			"50-hawaii-hamakua",
			"51-hawaii-hilo",
			"52-hawaii-maunakea"];

	}



}
