import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {Subscription, of, timer, BehaviorSubject, Observable, from, throwError} from 'rxjs';
import {catchError, mergeMap, shareReplay} from 'rxjs/operators';
import {Subject} from "rxjs";
import {plainToClass} from "class-transformer";

import {AngularFireFunctions} from "@angular/fire/functions";
import {Token} from "./messages/Token";
import {IAuthProfile} from "../../domain/auth/IAuthProfile";
import {NGXLogger} from "ngx-logger";
import {isEqual} from "lodash";
import {Role} from "../../domain/user/Role";
import {WaihonaUserService} from "../WaihonaUserService";
import * as firebase from 'firebase/app';
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import createAuth0Client, {LogoutOptions} from "@auth0/auth0-spa-js";
import {environment} from "../../../environments/environment";
import {WaihonaUserRepository} from "../repository/WaihonaUserRepository";

@Injectable()
export class AuthFunctions {

	public _currentUser$:BehaviorSubject<WaihonaUser> = new BehaviorSubject(null);
	public _isAuthenticatingCurrently$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public _isAuthenticated$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public firebaseAuthUser:Subject<firebase.User> = new Subject<firebase.User>();

	// Create subject of user profile data
	public userProfileSubject$ = new BehaviorSubject<any>(null);

	public _options = {
		client_id: environment.auth.clientId,
		domain: environment.auth.clientDomain,
		responseType: 'token',

		redirect_uri: `${window.location.origin}/#/callback`, //environment.auth.redirect,
		audience: environment.auth.audience,
		scope: environment.auth.scope
	};

	// Create an observable of Auth0 instance of client
	public auth0Client$ = (from(
		createAuth0Client({
			client_id: environment.auth.clientId,
			domain: environment.auth.clientDomain,
			responseType: 'token',

			redirect_uri: `${window.location.origin}/#/callback`, //environment.auth.redirect,
			audience: environment.auth.audience,
			scope: environment.auth.scope,
			useRefreshTokens: true,
		})
	) as Observable<Auth0Client>).pipe(
		shareReplay(1), // Every subscription receives the same shared value
		catchError(err => throwError(err))
	);

	public authProfile:IAuthProfile;

	public loggedInFirebase:boolean = false;

	// Subscribe to Firebase token renewal timer stream
	public refreshTokenOnTimeoutSub:Subscription;

	// Subscribe to the Firebase token refresh on user update stream
	public refreshTokenOnUserUpdateSub:Subscription;
	protected currentUserRoles:Array<Role>;

	protected _isInTokenAuth$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public _isHandlingLocalSelfAuth$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public prevAuthState:any = null;
	public currentAuthState:any = null;
	public freshLogin:boolean = null;
	public updatesScheduled:boolean = null;

	constructor(
		private router:Router,
		private afAuth:AngularFireAuth,
		private functions:AngularFireFunctions,
		protected logger:NGXLogger,
		protected waihonaUserService:WaihonaUserService,
		protected waihonaUserRepo:WaihonaUserRepository
	) {
		this._currentUser$.subscribe((user:WaihonaUser) => {
			if (!!user && this._isHandlingLocalSelfAuth$.value === true) {
				console.log("current user updated in AuthFunctions sub");
				this._isHandlingLocalSelfAuth$.next(false);
				this._isAuthenticatingCurrently$.next(false);
			}
		});
	}

	public isInTokenAuth$():BehaviorSubject<boolean> {
		return this._isInTokenAuth$;
	}

	public isInTokenAuth():boolean {
		return this._isInTokenAuth$.getValue();
	}


	public setAuth0Session(authResult, profile:IAuthProfile):Observable<IGetCustomTokenResponse> {
		this.logger.info("AuthFunctions::setAuth0Session");

		// Set tokens and expiration in localStorage
		const expiresAt = "" + ((authResult.expiresIn * 1000) + Date.now());//JSON.stringify();
		localStorage.setItem('expires_at', expiresAt);
		this.authProfile = profile;

		// Get Firebase token
		return this.getCustomToken();
	}

	private getToken$():Observable<Token> {
		this.logger.info("AuthFunctions::getToken$");
		let response$:Subject<Token> = new Subject<Token>();
		let register_callableFunction = this.functions.httpsCallable('getToken');

		this._isInTokenAuth$.next(true);
		//Set a timeout for 5 seconds; should be logged in by then.

		const getToken$ = register_callableFunction({user: this.authProfile}).subscribe((result) => {
			this.logger.info("AuthFunctions::register_callableFunction done");
			let token:Token = plainToClass<Token, any>(Token, result) as any;
			if (token != null && token.waihonaUser != null) {
				token.waihonaUser.guid = result.waihonaUser.guid;
			}
			this._isInTokenAuth$.next(false);
			response$.next(token);
		}, (error) => {
			this.logger.info("An error occured: " + error);
			this._isInTokenAuth$.next(false);
		});

		return response$;
	}

	private getCustomToken():Observable<IGetCustomTokenResponse> {

		let complete$:Subject<IGetCustomTokenResponse> = new Subject<IGetCustomTokenResponse>();
		let s:Subscription = this.getToken$().subscribe(
			(token:Token) => {
				this._currentUser$.next(token.waihonaUser);
				//this.logger.info(`Got response: ` + JSON.stringify(res.waihonaUser, null, 2));
				s.unsubscribe();
				return this.signInWithCustomToken(token).subscribe((signedIn:boolean) => {
					complete$.next({token: token, signedIn: signedIn});
				});
			}, (err) => {
				s.unsubscribe();
				this.logger.error(`An error occurred fetching Firebase token: ${err.message}`)
			}
		);
		return complete$;
	}

	private signInWithCustomToken(token:Token):Observable<boolean> {
		let signedIn$:Subject<boolean> = new Subject();
		this.logger.info("AuthFunctions::signInWithCustomToken");
		firebase.auth().signInWithCustomToken(token.token)
			.then( res => {
				this.loggedInFirebase = true;
				this.logger.info('Successfully authenticated with Firebase!');
				this.logger.info("Signed in with token for current user: " + token.guid);
				signedIn$.next(true);
			})
			.catch(err => {
				const errorCode = err.code;
				const errorMessage = err.message;
				this.logger.error(`${errorCode} Could not log into Firebase: ${errorMessage}`);
				this.loggedInFirebase = false;
				localStorage.removeItem('isLoggedIn');
				signedIn$.next(false);
			});
		return signedIn$;
	}

	public scheduleFirebaseRenewal() {
		this.logger.info("AuthService::scheduleFirebaseRenewal");
		// Check for existing Firebase subscription and unsubscribe,
		this.unscheduleFirebaseRenewal();
		// If user isn't authenticated, then return (don't schedule renewal)
		if (!this.loggedInFirebase) {
			console.log("not scheduling firebase renewal");
			return;
		}

		// Create and subscribe to expiration observable
		// Custom Firebase tokens minted by Firebase
		// expire after 3600 seconds (1 hour)
		const expiresAt = new Date().getTime() + (3600 * 1000);
		const expiresIn$ = of(expiresAt)
			.pipe(
				mergeMap(
					expires => {
						const now = Date.now();
						// Use timer to track delay until expiration
						// to run the refresh at the proper time
						return timer(Math.max(1, expires - now));
					}
				)
			);

		this.refreshTokenOnTimeoutSub = expiresIn$
			.subscribe(
				() => {
					this.logger.info('Firebase token expired; fetching a new one');
					this.refreshToken();
					this.scheduleFirebaseRenewal();
				}
			);
	}

	public refreshToken(options?):Observable<boolean> {
		this.logger.info("AuthFunctions::refreshToken");
		this._isAuthenticatingCurrently$.next(true);
		let refreshTokenComplete$:Subject<boolean> = new Subject<boolean>();
		try {
			this.auth0Client$.take(1).subscribe((client:Auth0Client) => {
				client.checkSession().then(() => {
					from(client.getUser(options)).take(1).subscribe((user:IAuthProfile) => {
						if (!user) {
							console.log("No Auth0 User cached. Need to re-authenticate.");
							this.logout();
							return;
						}
						// this.logger.info(`Got user: ${user ? JSON.stringify(user, null, 2) : false}`);
						let guid:string = user.sub.slice(6);
						this.waihonaUserRepo.get$(guid).subscribe((waihonaUser:WaihonaUser) => {
							this._currentUser$.next(waihonaUser);
						});

						this.setAuth0Session({}, user).take(1).subscribe((res:IGetCustomTokenResponse) => {
							this._isAuthenticatingCurrently$.next(false);
							refreshTokenComplete$.next(true);
						});
						return this.userProfileSubject$.next(user)
					});
				}).catch((e) => {
					console.error("Error in checkSession: ");
					console.error(e);
				});
			});
		} catch (e) {
			refreshTokenComplete$.next(false);
			console.error("Error refreshing token: ");
			console.error(e);
		}
		return refreshTokenComplete$;
	}

	// Unsubscribe from previous expiration observable
	public unscheduleFirebaseRenewal() {
		this.logger.info("AuthService::unscheduleFirebaseRenewal");
		if (this.refreshTokenOnTimeoutSub) {
			this.refreshTokenOnTimeoutSub.unsubscribe();
		}
	}

	public refreshTokenOnUserRoleUpdate(userId:string) {
		this.logger.info("AuthService::refreshTokenOnUserRoleUpdate");

		// Unsubscribe from previous refresh token observable
		if (this.refreshTokenOnUserUpdateSub) {
			this.refreshTokenOnUserUpdateSub.unsubscribe();
		}

		// If user isn't authenticated, then return (don't schedule renewal)
		if (!this.loggedInFirebase) {
			return;
		}

		this.refreshTokenOnUserUpdateSub = this.waihonaUserService.repo.watch$(userId).subscribe((waihonaUser:WaihonaUser) => {
			if (waihonaUser != null) {
				this._currentUser$.next(waihonaUser);

				let previousRoles = this.currentUserRoles;
				if (!isEqual(previousRoles, waihonaUser.roles)) {
					this.currentUserRoles = waihonaUser.roles;
					if (!!previousRoles) {
						console.log("Updating user token!");
						//TODO: Send some sort of nice update to user
						this.refreshToken();
					}
				}
			}
		});
	}

	public logout(auth0LogoutOptions?:LogoutOptions) {
		// Call firebase log out method
		this.firebaseLogout().then(() => {
			this.logOutCleanup();
			// then call Auth0 logout
			this.auth0Logout(auth0LogoutOptions);
		});
	}

	public auth0Logout(auth0LogoutOptions?:LogoutOptions) {
		this.logger.info("AuthFunctions::auth0Logout");
		this.updatesScheduled = false;

		let options:LogoutOptions;
		// if logout options were passed in, use those
		if (auth0LogoutOptions) {
			options = auth0LogoutOptions;
		} else {
			// otherwise use the default options
			options = {
				client_id: this._options.client_id,
				returnTo: `${window.location.origin}`
			}
		}

		// Ensure Auth0 client instance exists
		this.auth0Client$.take(1).subscribe((client:Auth0Client) => {
			// then call Auth0 logout
			client.logout(options);
		});
	}

	private logOutCleanup() {
		this.currentUserRoles = null;
		this._currentUser$.next(null);
		this._isAuthenticated$.next(false);
	}

	public firebaseLogout():Promise<void> {
		this.logger.info("AuthFunctions::firebaseLogout");
		// Ensure all auth items removed
		localStorage.removeItem('isLoggedIn');
		localStorage.removeItem('expires_at');
		localStorage.removeItem('auth_redirect');

		this.loggedInFirebase = false;

		return firebase.auth().signOut().then(()=> {
			this.firebaseAuthUser.next(null);
			this.logger.info("firebase logout succeeded");
		}, (failure)=> {
			this.logger.error("firebase logout failed");
		});
	}

	public get isTokenValid():boolean {
		this.logger.info("AuthFunctions::isTokenValid");
		// Check if current time is past access token's expiration
		const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
		return Date.now() < expiresAt;
	}

}

export interface IGetCustomTokenResponse {
	token: Token, signedIn: boolean
}
