import {BehaviorSubject, Observable, Subject} from "rxjs";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";
import {
	AbstractFirestoreRepository,
	OrderByCriterion,
	PageCursor,
	PageResults, QueryCriteria, QueryCriteriaBuilder
} from "./repository/AbstractFirestoreRepository";
import {QueryFn} from "@angular/fire/firestore";
import {IPagingParam} from "../domain/IPagingParam";
import {AbstractCompoundFirestoreRepository} from "./repository/AbstractCompoundFirestoreRepository";
import {classToClass} from "class-transformer";

export class PageListService<T,R=any> extends SubscriptionCleaner {

	private pageableRepo:AbstractFirestoreRepository<T,R> | AbstractCompoundFirestoreRepository<T, R>;

	public pageData$:BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	public cache:{[key:string]:T[]} = {null:[]};  //key can also be null (when no filter)
	public pageCursor:PageCursor<T>;
	public pageLoading$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	protected _filter:string = null;
	protected _filters:Map<string, QueryCriteria> = new Map<string, QueryCriteria>();

	public constructor (repo:AbstractFirestoreRepository<T,R> | AbstractCompoundFirestoreRepository<T, R>) {
		super();
		this.pageableRepo = repo;

	}
	public setFilter(key:string|null):void {
		if(key != this._filter) { //only change if its changed.
			//backup current filter
			this.cache[this._filter] = this.pageData$.value;

			this._filter = key; //change the filter

			//get the new filter from cache (or create a new array)
			let data:T[] = (this.cache[this._filter] == null) ? [] : this.cache[this._filter];
			data = data.filter(value => {
				if(this.currentCriteria == null) {
					return true;
				}
				return this.currentCriteria.verify(value);
			});
			this.pageData$.next(data);
		}
	}
	protected get currentCriteria():QueryCriteria {
		return this._filters.get(this._filter);
	}
	public initializePaging$(queryCriteria:QueryCriteria, pageSize:number = 20, filterKey:string = null, ...params:Array<string>):Observable<Array<T>> {
		this.pageLoading$.next(true);
		let pageResultsObservable:BehaviorSubject<Array<T>> = new BehaviorSubject<Array<T>>([]);

		if(filterKey) {
			this._filters.set(filterKey, queryCriteria);
			this.setFilter(filterKey);
		}


		// if there is data in the cache, populate list from there

		if (this.pageData$.value.length != 0) {
			//filter out the things that maybe is no longer relevant
			let newFilteredList:Array<T> = this.pageData$.value.filter(value => {
				if(this.currentCriteria == null) {
					return true;
				}
				return this.currentCriteria.verify(value);
			});

			this.pageCursor.criteria = classToClass(queryCriteria); //make a copy
			this.pageCursor.cursorEnd = newFilteredList[newFilteredList.length-1];

			if(this.pageData$.value.length != newFilteredList.length) {
				this.pageData$.next(newFilteredList);
			} else {
				pageResultsObservable.next(newFilteredList);
			}
			this.pageLoading$.next(false);
		} else {
			// otherwise, go get new data from the server
			this.trackSubscription(this.getFirstPage$(queryCriteria, pageSize, ...params).subscribe((pageResults:PageResults<T>) => {
				this.onPageUpdated(pageResults);
			}));
		}

		// update list as new pageData comes in
		this.trackSubscription(this.pageData$.subscribe((results) => {
			pageResultsObservable.next(results);
		}));

		return pageResultsObservable;
	}

	public getFirstPage$(queryCriteria:QueryCriteria, pageSize:number = 20, ...params:Array<string>):Observable<PageResults<T>> {
		let queryFn:QueryFn = new QueryCriteriaBuilder(queryCriteria).toQueryFn();

		if(this.pageableRepo instanceof AbstractCompoundFirestoreRepository) {
			let compoundRepo:AbstractCompoundFirestoreRepository<T, R> = (this.pageableRepo as AbstractCompoundFirestoreRepository<T, R>);
			return compoundRepo.page$(pageSize, queryFn, queryCriteria, ...params);
		} else {
			return this.pageableRepo.page$(pageSize, queryFn, queryCriteria);
		}
	}



	public getNextPage(...params:Array<string>):void {
		console.log(`this.pageCursor.nextPageExists: ${this.pageCursor?.nextPageExists}`);
		if (this.pageCursor?.nextPageExists) {
			if (!this.pageLoading$.value) {
				this.pageLoading$.next(true);

				if(this.pageableRepo instanceof AbstractCompoundFirestoreRepository) {
					let compoundRepo:AbstractCompoundFirestoreRepository<T, R> = (this.pageableRepo as AbstractCompoundFirestoreRepository<T, R>);
					compoundRepo.nextPage$(this.pageCursor, ...params).subscribe(this.onPageUpdated);
				} else {
					this.pageableRepo.nextPage$(this.pageCursor).subscribe(this.onPageUpdated);
				}
			}
		}
	}

	public onPageUpdated = (pageResults:PageResults<T>) => {
		this.pageCursor = pageResults.pageCursor;
		if (this.pageData$.value) {
			let newData:T[] = this.pageData$.value.concat(pageResults.results);
			this.pageData$.next(newData);
			this.pageLoading$.next(false);
		}
	}
}

export interface PagingService<T> {
	paging:{
		pageSize:number,
		list: {
			initialize: {
				list$:() => Observable<Array<T>>
			},
			next:() => void,
			isLoading: () => BehaviorSubject<boolean>,
			// important for nextPageExists to be here, being used in ScreenResizeService
			nextPageExists: () => boolean,
		}
	}
}
