import {RegisterClazz, RegisterType} from "../RegisterType";
import {Transform, Type} from "class-transformer";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {InboxFilterType} from "./InboxFilterType";
import {ArrayNotEmpty, IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {IndexProperty} from "../IndexProperty";

@RegisterClazz(MessageRef, "Users/${userGuid}/Inbox")
export class MessageRef {
	//Do not @Exclude() this property, it is needed for sorting/paging
	public guid:string = null;
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef})
	public from:WaihonaUserRef = null;

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@ArrayNotEmpty()
	public to:Array<WaihonaUserRef> = [];

	@IsNotEmpty()
	@MinLength(1)
	@MaxLength(200)
	public subject:string = "";

	@IsNotEmpty()
	@MinLength(1)
	@MaxLength(5000)
	public body:string = "";

	@Transform((value:number, obj, type) => value == null ? null : new Date(value), { toClassOnly: true })
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, { toPlainOnly: true })
	public sent:Date = null;
	@Transform((value:number, obj, type) => value == null ? null : new Date(value), { toClassOnly: true })
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, { toPlainOnly: true })
	public opened:Date = null;
	@Transform((value:number, obj, type) => value == null ? null : new Date(value), { toClassOnly: true })
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, { toPlainOnly: true })
	public trashed:Date = null;

	@IndexProperty({isArrayOfKeys: true, possibilities:()=> Object.values(InboxFilterType)})
	public sortingTags:Array<InboxFilterType> = [];

}
