import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'loading-button',
  templateUrl: 'loading-button.component.html',
  styleUrls: ['../../../../../../../../../node_modules/ladda/dist/ladda-themeless.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingButtonComponent implements OnInit {
  // trigger-variable for Ladda
  isLoading: boolean = false;

  @Input()
  public label:string = "Not yet set";

  /**
   * Only Emits if not currently loading
   * @type {EventEmitter<any>}
   */
  @Output()
  onClick = new EventEmitter();

  constructor(protected logger:NGXLogger) {

  }

  ngOnInit() {
    this.logger.info("text value is " + this.label);
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  public onButtonClick(event:Event):void {
    if(!this.isLoading) {
      this.onClick.emit();
    }
  }
}
