import {RegisterClazz} from "../RegisterType";

@RegisterClazz(OrganizationRef)
export class OrganizationRef {
	public guid:string = "";
	public title:string = "";

	constructor(guid?:string, title?:string) {
		if(guid != null) {
			this.guid = guid;
		}
		if(title != null) {
			this.title = title;
		}
	}
}
