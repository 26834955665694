import {Component, Injector, OnInit} from '@angular/core';
import {Localization} from "../../../data/Localization";
import {BsModalRef} from "ngx-bootstrap/modal";
import {CollectionService} from "../../../services/CollectionService";
import {Collection} from "../../../domain/collections/Collection";
import {LocalizationService} from "../../../services/LocalizationService";
import {PublishedResource} from "../../../domain/resources/PublishedResource";
import {IModal} from "../IModal";
import {CollectionTextContent} from "../../../domain/collections/CollectionTextContent";
import {NupepafyStringUtils} from "../../../util/NupepafyStringUtils";
import {ValidationErrorService} from "../../../services/common/ValidationErrorService";
import {ValidationException} from "../../../services/exceptions/ValidationException";
import {ToastType} from "../../../services/common/NotificationService";
import {ValidationError} from "class-validator";
import {Subscription} from "rxjs";
import {CollectionOwner} from "../../../domain/collections/CollectionOwner";
import {BaseComponent} from "../../BaseComponent";
import {CollectionVisibilityType} from "../../../domain/collections/CollectionConfigurationEnums";
import {PublishedResourceRef} from "../../../domain/resources/PublishedResourceRef";

@Component({
	selector: 'app-add-to-collection-modal',
	templateUrl: './add-to-collection-modal.component.html',
	styleUrls: ['./add-to-collection-modal.component.scss'],
	providers: [CollectionService, ValidationErrorService]
})
export class AddToCollectionModalComponent extends BaseComponent implements OnInit, IModal {

	private cbContext =  Localization.template.collections.add_to_collection_modal;
	public CB = {
		modal_title: this.cbContext.title,
		modal_submit: this.cbContext.submit,
		modal_cancel: this.cbContext.cancel,
		create_new: this.cbContext.create_new,
		create_collection: this.cbContext.create_collection,
		new_collection_title_placeholder: this.cbContext.new_collection_title_placeholder,
		new_collection_description_placeholder: this.cbContext.new_collection_description_placeholder,
		label_title: this.cbContext.labels.title,
		label_summary: this.cbContext.labels.summary,
	};

	protected _properties:{resourceToAdd:PublishedResource} = null;
	public inCreateNewCollectionMode:boolean = false; // a boolean flag that says if a user has entered "create new collection" mode
	public availableCollections:Array<Collection> = []; // array of collections to choose from
	public selectedCollections:Array<Collection> = []; // array to hold selected collections
	public newCollection:Collection = null;
	public collectionTextContent:CollectionTextContent;

    constructor (public bsModalRef:BsModalRef,
				 private collectionService:CollectionService,
				 public localizationService:LocalizationService,
				 protected injector:Injector) {
    	super(injector);

		//get all collections that the user can edit.  This array could be empty.
		let s1:Subscription = this.collectionService.getAllCollectionsUserCanEdit$(this.currentUser).subscribe((items) => {
			s1.unsubscribe();
			this.availableCollections = items.length > 0 ? this.collectionService.sortCollections(items) : []
			this.logger.log(`Loading initial batch of ${this.availableCollections.length} collections.`);
		});
    }

	ngOnInit(): void {
    }

    //get all collections that contain a resource.  This array could be empty.  Then filter the collections.  TODO: query is looking for indexed titles. Newly-added resources need to be indexed upon add.
    public getCollectionsContainingResource():void {
		if (this._properties) {
			let s2:Subscription = this.collectionService.getAllCollectionsContainingAResource$(this._properties.resourceToAdd.guid).subscribe( (items)=> {
				s2.unsubscribe();
				if (items.length > 0) {
					this.availableCollections = this.availableCollections.filter(collection => (items.find(collection2 => collection2.guid == collection.guid) == null));
					this.logger.log(`We have ${this.availableCollections.length} filtered collections.`);
				}
			});
		}
	}

	public onProperties(properties:{resourceToAdd:PublishedResource}):void {
		this._properties = properties;
		this.getCollectionsContainingResource();
	}

	public onEnterNewCollectionMode() {
		this.newCollection = new Collection();
		this.newCollection.documentTextContent.generate(this.localizationService.language);
		this.collectionTextContent = this.newCollection.documentTextContent[this.localizationService.language];
		this.newCollection.owner = new CollectionOwner(this.currentUserRef);
		this.newCollection.lastSavedBy = this.currentUser.guid;
		this.newCollection.items = [];
		this.newCollection.configuration.visibilityType = CollectionVisibilityType.privateToUser;
		this.inCreateNewCollectionMode = true;
	}

	private duplicateTitleCheck(newCollectionTitle:string) {
    	if (this.availableCollections.find((collection:Collection) => (collection.documentTextContent.en?.title === newCollectionTitle) || (collection.documentTextContent.haw?.title === newCollectionTitle) )) {
    		let validationError = new ValidationError();
    		validationError.property = "title";
    		validationError.constraints = {isNotDuplicate: "A collection with that name already exists"};
    		throw new ValidationException([validationError]);
	    }
	}

	public onCreateNewCollection() {
    	try {
		    this.duplicateTitleCheck(this.collectionTextContent.title);
		    this.validationErrorService.validateTextContentObject(this.newCollection);
	    } catch (ex) {
		    if (ex instanceof ValidationException) {
			    this.logger.info(`validation errors found and returned in view`);
			    this.validationErrorService.updateErrors(ex.errors);
		    } else {
			    this.logger.error(ex);
		    }
		    return;
	    }
		this.newCollection.indexedTitle = NupepafyStringUtils.nupepafy(this.collectionTextContent.title).toLowerCase();
	    try {
			this.trackSubscription(this.collectionService.save$(this.newCollection).subscribe((collection) => {
				this.notificationService.displayToast(Localization.template.collections.toasts.create_collection.success, ToastType.success);
				this.bsModalRef.hide();
				this.selectedCollections.push(collection);
				this.onConfirmAddToCollection();
			}));
		} catch (err) {
    		this.logger.error(err);
		}
		this.validationErrorService.clearErrors();
		this.inCreateNewCollectionMode = false;
	}

	public onConfirmAddToCollection():void {
		let resourceRef:PublishedResourceRef = this.conversionService.convert(this._properties.resourceToAdd, PublishedResourceRef);
		try {
			this.trackSubscription(this.collectionService.addResourceRefsToCollections$([resourceRef], this.selectedCollections, this.currentUser.guid).subscribe(() => {
				this.notificationService.displayToast(Localization.template.collections.toasts.resource_added.success, ToastType.success);
				this.getCollectionsContainingResource();
			}));
		} catch (err) {
			this.logger.error(err);
			this.notificationService.displayToast(Localization.template.collections.toasts.resource_added.fail, ToastType.error);
		}
		this.bsModalRef.hide();
	}

	public onCancel():void {
		console.info(`user clicked on cancel button`);
		this.bsModalRef.hide();
	}

}
