import {Converter} from "../ConversionService";
import {ResourceSubmission} from "../../domain/resources/ResourceSubmission";
import {ResourceSubmissionRef} from "../../domain/resources/ResourceSubmissionRef";
import {ResourceSubmissionTextContent} from "../../domain/resources/ResourceSubmissionTextContent";
import {DocumentTextContent} from "../../domain/DocumentTextContent";
import {SupportedLanguage} from "../../domain/SupportedLanguages";


export class ResourceSubmissionToResourceSubmissionRefConverter implements Converter {

	public from:typeof ResourceSubmission;
	public to:typeof ResourceSubmissionRef;

	constructor() {
		this.from = ResourceSubmission;
		this.to = ResourceSubmissionRef;
	}

	public convert(source:ResourceSubmission) {
		let resourceSubmissionTextContent:DocumentTextContent<ResourceSubmissionTextContent> = source.documentTextContent;
		let languages:Array<string> = Object.keys(resourceSubmissionTextContent);
		let title:{[key:string]:string} = {};

		for(let i:number = 0; i < languages.length; i++) {
			let languageKey:SupportedLanguage = languages[i] as SupportedLanguage;
			let languageValue:ResourceSubmissionTextContent = resourceSubmissionTextContent[languageKey];
			title[languageKey] = languageValue.title;
		}

		let resourceSubmissionRef:ResourceSubmissionRef = ResourceSubmissionRef.create(source.guid, title, source.submitter);
		return resourceSubmissionRef;
	}
}

