<!-- , <a href="javascript:void(0)" (click)="setActiveFilterType(FilterType.subject)">Subject</a>, <a href="javascript:void(0)" (click)="setActiveFilterType(FilterType.standard)">Standard</a> or <a href="javascript:void(0)" (click)="setActiveFilterType(FilterType.none)">None</a></span> -->


<div class="col-12">
	<div class="filter-options-container">
		<p *ngIf="filterTypeList.length > 0">Add search filter(s): <span *ngFor="let filterType of filterTypeList; let i = index; let last = last"><a
				href="javascript:void(0)" class="filter-option" [ngClass]="filterType.type"
				[class.selected]="filterType == this.currentFilterSource" (click)="setActiveFilterType(filterType)"
				attachOutsideOnClick="true" (clickOutside)="closeFilter()">{{filterType.label}}</a><span
				*ngIf="!last" class="text-light"> | </span></span>
		</p>
		<span class="checkbox-container"><mat-checkbox [(ngModel)]="exactMatch"></mat-checkbox><span class="exact-match-checkbox-label">{{CB.exactMatch | localize | async}}</span></span>
	</div>
	<div class="input-group w-100">
		<div class="input-group-append">
			<div class="input-group-text">
				<i class="fas fa-search"></i>
			</div>
		</div>
		<ng-select #searchFilter id="searchFilter" name="searchFilter" dropdownPosition="bottom"
		   	(change)="onSearchFilterChanged($event)" [(ngModel)]="finalFilter"
		   	(ngModelChange)="onModelChange($event)" (clear)="closeFilter()" [items]="currentFilterSource.options"
		   	[multiple]="true" class="bootstrap custom" [placeholder]="currentFilterSource.placeholder"
		   	(add)="onAdded($event)" [addTag]="filterProperties.addTag" [addTagText]="filterProperties.addTagText"
			style="width: calc(100% - 40px)">
			<ng-template class="multiple" let-clear="clear" let-item="item" let-index="index" ng-label-tmp>
				<div *ngIf="item.value != null" class="option container d-inline-flex p-0" [ngClass]="item.category"
					 [class.selected]="item.category == this.currentFilterSource.type">
						<div>
							<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
						</div>
						<div *ngIf="isFirstInCategory(item)">
							<span class="ng-value-label"><i class="fas fa-filter"></i></span>
						</div>
						<div *ngIf="asOption(item).category == CategoryForIOption.Contributors" class="d-inline-flex px-1">
							<avatar-component [userRef]="wur(item.value)" [class]="AvatarMode.filterSelectImage"></avatar-component>
							<span>{{getSelectedFilterLabel(item)}}</span>
						</div>
						<div *ngIf="asOption(item).category != CategoryForIOption.Contributors" class="px-1">
							<span>{{getSelectedFilterLabel(item)}}</span>
							<span *ngIf="asOption(item).category == CategoryForIOption.Framework">
								<span class="font-weight-bold"> {{asSFRef(item).selectedKeys.length}} Chosen.</span>
								<a href="javascript:void(0);" (click)="configureFramework(item)">(Edit Settings)</a>
							</span>
						</div>

					</div>
				<div *ngIf="item.value == null" class="option suggest">
					<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
					<span class="ng-value-label">
						<span class="suggestText" *ngIf="filterProperties.addTag">{{filterProperties.addTagText}} </span>{{item.label}}
					</span>
				</div>
			</ng-template>
			<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
				<div class="option">
					<span class="ng-value-label">
						{{item.label}}
					</span>
				</div>
			</ng-template>
		</ng-select>
	</div>
</div>


<!--
<ng-select [(ngModel)]="resourceSubmission.collaborators" [items]="waihonaUserRefs | async" [multiple]="true" bindLabel="fullName"
		   class="bootstrap custom contributor-center"
		   field="collaborators" placeholder="{{CB.basic_collab_placeholder | localize | async}}" waih-validation-color>
	<ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
		<div class="option">
			<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
			<span class="ng-value-label">
				<avatar-component [userRef]="item.value" [class]="'select-image'"></avatar-component>
				{{item.fullName}}
			</span>
		</div>
	</ng-template>
	<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
		<div class="option">
			<span class="ng-value-label">
				<avatar-component [userRef]="item" class="'select-image'"></avatar-component>
				{{item.fullName}}
			</span>
		</div>
	</ng-template>
</ng-select>
-->
