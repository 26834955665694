
/** Extra information about the file itself in the firebase file system */
export class FirebaseFileInfo {
  public name:string;
  public contentType:string;
  public size:number;
  public url:string;
  public created:string;
  public bucket:string;
  public path:string;
}
