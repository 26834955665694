import {Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../../RegisterType";
import {ResourceChatMessage} from "./ResourceChatMessage";

@RegisterClazz(ResourceChat, "Chats")
export class ResourceChat {
	public resourceGuid:string;

	@Type(() => ResourceChatMessage)
	@RegisterType({clazz: ResourceChatMessage, isArray: true})
	public messages:Array<ResourceChatMessage> = [];


}
