import { Directive, Input, Output, HostBinding, HostListener, Renderer2, ElementRef, OnInit, AfterContentInit, EventEmitter } from '@angular/core';

@Directive({
  selector: '[nae-loc-pseudo-input]'
})
export class NaeLocPseudoInputDirective implements OnInit, AfterContentInit {
  @HostBinding('attr.contenteditable')
  contentEditable: boolean;

  @HostBinding('style.background-color')
  highlighted:string;

  @HostBinding('attr.placeholder')
  inputPlaceholder: string;

  @HostBinding('attr.value')
  textValue: string;

  private nativeElement: Element;
  private _placeholder:string;
  public elementType:string;

  // @Output() valuechange:EventEmitter<any> = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(event) {
    if (this.contentEditable === true) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event'])
  onEnterKey(event) {
    console.log("Enter key pressed");
    if (this.contentEditable === true && event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      console.log("this.textValue: " + this.textValue);
    }
  }

  constructor(private renderer:Renderer2,private element:ElementRef) {
  }

  ngOnInit() {
    //TODO: set spellcheck false when localization edit mode is switched on, or maybe don't turn spellcheck off at all...?
    document.body.setAttribute('spellcheck', "false");
    this.nativeElement = this.element.nativeElement;

    // const anchor = this.renderer.createElement('a');
    // this.renderer.setStyle(anchor, 'position', 'relative');
    //
    // const icon= this.renderer.createElement('i');
    // this.renderer.setAttribute(icon, 'class', 'fas fa-edit fa-xs loc-edit-icon');
    // this.renderer.setStyle(icon, 'position', 'absolute');
    // this.renderer.setStyle(icon, 'margin-left', '10px');
    // this.renderer.setStyle(icon, 'margin-bottom', '5px');
    // this.renderer.setStyle(icon, 'z-index', '14');
    // this.renderer.setStyle(icon, 'color', 'purple');
    //
    // this.renderer.appendChild(anchor, icon);
    // this.renderer.appendChild(this.renderer.parentNode(this.nativeElement), anchor);
  }

  ngAfterContentInit() {
    // const pseudoInput = this.renderer.createElement('input');
    // this.renderer.setStyle(pseudoInput, 'position', 'absolute');
    // this.renderer.setAttribute(pseudoInput, 'value', 'blah blah blah');
    // this.renderer.appendChild(this.nativeElement, pseudoInput);
    // this.renderer.setStyle(pseudoInput, 'position', 'absolute');
    this._placeholder = this.nativeElement.getAttribute('placeholder');
    this.elementType = this.nativeElement.nodeName;
    this.inputPlaceholder = this._placeholder;
    // this.textValue = "blah blah blah"
  }

  // onKey(event:KeyboardEvent){
  //   this.valuechange.emit(event);
  // }
}
