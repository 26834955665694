import {Component, Input, OnInit} from '@angular/core';
import {Localization} from "../../../data/Localization";

@Component({
  selector: 'loading-card',
  templateUrl: './loading-card.component.html',
  styleUrls: ['./loading-card.component.scss']
})
export class LoadingCardComponent implements OnInit {

	@Input()
	public itemsLoading:boolean;

	@Input()
	public listType:string = "items";

	public cbContext = Localization.template.common.card_list;
	public CB = {
		loading: this.cbContext.loading,
		no_items: this.cbContext.no_items,
	};

	constructor() { }

	ngOnInit() {
	}

}
