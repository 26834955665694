/// <reference path="../../../../../../../../../node_modules/@types/jquery/index.d.ts" />

import {NGXLogger} from "ngx-logger";
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningAssetComponentMode, RenameEvent} from "../lesson-asset/lesson-asset.component";
import {LearningAsset} from '../../../../../../../domain/resources/LearningAsset';

import {ResourceSubmissionService} from '../../../../../../../services/ResourceSubmissionService';
import {LearningAssetUpload, UploadStatus} from '../../../../../../../services/objects/LearningAssetUpload';
import {BehaviorSubject} from 'rxjs/Rx';
import {AssetTypes} from '../../../../../../../data/LessonData';
import {IOption} from 'ng-select';
import {ResourceSubmission} from '../../../../../../../domain/resources/ResourceSubmission';
import {ValidationError} from "class-validator";
import {NotificationService, ToastType} from "../../../../../../../services/common/NotificationService";
import {LocalizationService} from "../../../../../../../services/LocalizationService";
import {DefaultStorageBucket} from "../../../../../../../services/storage/DefaultStorageBucket";
import {LearningAssetUploadComponentMode} from "../lesson-asset-upload/lesson-asset-upload.component";
import {Localization} from "../../../../../../../data/Localization";
import {AngularFireUploadTask} from "@angular/fire/storage";

declare var require;

let sanitize = require("sanitize-filename");


interface FileExt extends File {
	webkitRelativePath:any;
	lastModifiedDate:any;
}

@Component({
	selector: 'lesson-assets-component',
	templateUrl: './lesson-assets.component.html',
	styleUrls: [
		"./lesson-assets.component.scss",
	],
	providers: [ResourceSubmissionService]
})
export class LessonAssetsComponent implements OnInit {

	private cbContext = Localization.template.resource.lesson_assets;
	public CB = {
		header: this.cbContext.header,
		noAssets: this.cbContext.none,
		someAssets: this.cbContext.some,
		upload: this.cbContext.upload,
		notUploaded: this.cbContext.not_uploaded,
		uploadNow: this.cbContext.upload_now,
		toast_nameWarning: this.cbContext.toasts.naming_issue_warning,
	};

	public assetTypes:Array<IOption> = AssetTypes;
	public assetErrors:BehaviorSubject<ValidationError[]> = new BehaviorSubject<ValidationError[]>([]);
	public userMessage:string = "";

	@Input()
	public uploadPath:string;

	@Output()
	public onFileChange:EventEmitter<any> = new EventEmitter<any>();

	@Input()
	public resourceSubmission:ResourceSubmission;

	@Input()
	public assets$:BehaviorSubject<LearningAsset[]> = new BehaviorSubject<LearningAsset[]>([]);
	public uploads$:BehaviorSubject<LearningAssetUpload[]> = new BehaviorSubject<LearningAssetUpload[]>([]);

	constructor (private resourceSubmissionService:ResourceSubmissionService,
				 protected notificationService:NotificationService,
				 protected localizationService:LocalizationService,
				 protected defaultStorageBucket:DefaultStorageBucket,
				 protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("LessonAssetsComponent", this.CB);

		this.assetTypes = AssetTypes;

		this.resourceSubmissionService.completedUploads.subscribe((learningAssetUpload:LearningAssetUpload) => {
			if (learningAssetUpload != null) {
				const index:number = this.uploads.indexOf(learningAssetUpload);
				this.uploads.splice(index, 1); //delete the upload from the list

				//Find any matching assets which are to be trashed
				let assetIndex:number = this.assets.findIndex(asset => {
					return asset.fileName == learningAssetUpload.name
				});

				if (assetIndex != -1) {
					if (learningAssetUpload.keepExistingMetadata) {
						let asset:LearningAsset = this.assets[assetIndex];
						learningAssetUpload.learningAsset.tags = asset.tags;
						learningAssetUpload.learningAsset.title = asset.title;
					}
					this.assets.splice(assetIndex, 1);
				}

				this.uploads = this.uploads; //Dear Future Kalani, this looks stupid, but it's not! Love, Past Kalani
				if (learningAssetUpload.status == UploadStatus.success) {
					this.assets.push(learningAssetUpload.learningAsset);
					this.notificationService.displayToast((this.cbContext.toasts.fileUpload.success), ToastType.info);
				}
				this.assets = this.assets; //this also looks stupid, but it's not! (trust Kalani)

				this.onFileChange.emit();
			}
		});
	}

	ngOnInit() {
		if (this.uploadPath == null || this.uploadPath == "") {
			throw new TypeError("The input ‘uploadPath’ is required");
		}
	}

	@Input()
	public set assets(value) {
		this.assets$.next(value);
	};

	public get assets() {
		return this.assets$.getValue();
	}


	@Input()
	public set uploads(value) {
		this.uploads$.next(value);
	};

	public get UploadStatus() {
		return UploadStatus;
	}

	public get uploads() {
		return this.uploads$.getValue();
	}

	public onUploadClick(event:Event):void {
		console.info(`trying upload`);
		let uploadsWithIssues:Array<LearningAssetUpload> = this.uploads.filter(upload => {
			return upload.status == UploadStatus.preupload_problem;
		});

		let hasUploadIssues:boolean = uploadsWithIssues.length > 0;
		let pendingUploads:Array<LearningAssetUpload> = this.pendingUploads();

		if (hasUploadIssues) {
			this.notificationService.displayToast(this.cbContext.toasts.naming_issue_warning, ToastType.error);
		} else if (!hasUploadIssues && pendingUploads.length > 0) {
			console.info(`uploading ${pendingUploads.length} files via resourceSubmissionService`);
			this.resourceSubmissionService.uploadAll(this.resourceSubmission, pendingUploads, this.uploadPath);
		} else {
			this.logger.info("Nothing to upload dude!");
		}
	}

	public pendingUploads():Array<LearningAssetUpload> {
		return this.uploads.filter((upload:LearningAssetUpload) => upload.status === UploadStatus.waiting);
	}

	public addFiles(files:Array<File>) {

		//Replace any existing files that were about to be uploaded with the new one
		files.forEach((file) => {

			this.addOrReplaceFile(file);
			this.logger.info("\tfile.webkitRelativePath: " + (<FileExt>file).webkitRelativePath);
			this.logger.info("\tfile.lastModified: " + file.lastModified);
			this.logger.info("\tfile.name: " + file.name);
			this.logger.info("\tfile.lastModifiedDate: " + (<FileExt>file).lastModifiedDate);
		});
		this.uploads$.next(this.uploads);
	}

	/**
	 * Replaces the file in this.files if the pass file shares the same name
	 * @param {File} file
	 */
	private addOrReplaceFile(file:File):void {

		let sanitizedFileName:string = sanitize(file.name);
		this.logger.info("Before sanitized fileName: " + file.name + " and after sanitized fileName: " + sanitizedFileName);

		const matchedFilesIndex:number = this.uploads.findIndex((assetUpload:LearningAssetUpload) => {
			return (assetUpload.name == sanitizedFileName);
		});

		if (matchedFilesIndex > -1) {
			//It already exists in the existing upload
			let learningAssetUpload:LearningAssetUpload = this.uploads[matchedFilesIndex];
			if (learningAssetUpload.status == UploadStatus.uploading) {
				learningAssetUpload.cancel(); //Cancel any existing uploads.
				this.logger.info(`${learningAssetUpload.file.name} was already uploading when replaced.  Cancelling upload`);
			}
			this.uploads[matchedFilesIndex] = new LearningAssetUpload(file, sanitizedFileName);
			this.logger.info(file.name + " already existed (files with same name are not allowed) so it was replaced with the new file.");
			return;
		}

		let matchingAsset:LearningAsset = this.assets.find((learningAsset:LearningAsset) => {
			return (learningAsset.fileInfo.name == sanitizedFileName);
		});

		let futureLearningAsset:LearningAssetUpload = new LearningAssetUpload(file, sanitizedFileName);

		if (matchingAsset) {
			futureLearningAsset.status = UploadStatus.preupload_problem;
		}

		this.uploads.push(futureLearningAsset);
	}


	public onDoUploadNow(event) {
		if (event != null) {
			event.preventDefault();
		}
		this.onUploadClick(event);
	}

	/** When the upload is trashed */
	public onUploadRemove(uploadToRemove:LearningAssetUpload) {
		this.logger.info("onUploadRemove");
		const uploadsForDeleteSearch:LearningAssetUpload[] = this.uploads;
		uploadsForDeleteSearch.splice(this.uploads.indexOf(uploadToRemove), 1);
	}
	/** When the upload is canceled */
	public onUploadCancel(uploadToCancel:LearningAssetUpload) {
		this.logger.info("onUploadCancel");
		let taskToCancel:AngularFireUploadTask = this.resourceSubmissionService.tasks$.getValue().find((task:AngularFireUploadTask) => task.task.snapshot.ref.name === uploadToCancel.name);
		taskToCancel.cancel();
		uploadToCancel.cancel();
		this.notificationService.displayToast(this.cbContext.toasts.cancel.success, ToastType.success, {path: uploadToCancel.name})
	}
	/** When the asset is trashed */
	public onAssetDelete(assetToDelete:LearningAsset) {
		this.logger.info("onAssetDelete handler");

		const assetsForDeleteSearch:LearningAsset[] = this.assets;
		let index:number = this.assets.indexOf(assetToDelete);

		//this modifies the this.resourceSubmission.files array reference directly via the observable (from Past Kalani)
		this.assets.splice(index, 1); //remove the item

		let pathToDelete:string = this.uploadPath + "/" + assetToDelete.fileInfo.name;
		this.defaultStorageBucket.delete(pathToDelete).subscribe(result => {
			if (result) {
				this.logger.info("displaying success");
				this.notificationService.displayToast(this.cbContext.toasts.delete.success, ToastType.success, {path: assetToDelete.fileInfo.name})
			} else {
				this.logger.info("displaying failure");
				this.notificationService.displayToast(this.cbContext.toasts.delete.failure, ToastType.error, {path: assetToDelete.fileInfo.name});
			}
		});

		this.onFileChange.emit();
	}



	/** Called on Rename clicked or when Overwrite Clicked */
	public onAssetRename(message:RenameEvent):void {
		console.info(`renaming ${message.learningAsset.fileName} to ${message.newFileName}`);

		//sanitize newFileName input
		let sanitizedFileName:string = sanitize(message.newFileName);
		console.log("Before sanitized fileName: " + message.newFileName + " and after sanitized fileName: " + sanitizedFileName);

		//search for matching file names
		console.info(`checking for new name conflicts`);
		let matchingAsset:LearningAsset = this.assets.find((learningAsset:LearningAsset) => {
			return (learningAsset.fileName == sanitizedFileName);
		});

		if (matchingAsset) {
			console.error(`unable to complete action because there is another asset with the same name`);
			this.userMessage = "Oops! Another file already has that name.";
		} else {
			console.info(`No name conflicts! Passing to ResourceSubmissionService to handle name change.`);
			this.resourceSubmissionService.renameLearningAsset(this.resourceSubmission, message.learningAsset, sanitizedFileName).subscribe(done => {

			});
			//TODO: Kalani immediately regretted writing this coffee cup code (needed for redraw of subcomponent since array change did not occur and angular waits a frame to determine what to redraw and change detector injector  doesnʻt work at all */
			let coffeeCup:Array<LearningAsset> = [...this.assets];
			this.assets =[];
			setTimeout(()=> {
				this.assets = [...coffeeCup];
			}, 80);

		}
	}

	protected findMatchingAndMarkAsOverwritten(learningAssetUpload:LearningAssetUpload):void {
		let match:LearningAsset = this.assets.find(asset => {
			return asset.fileName == learningAssetUpload.name;
		});
		if (match) {
			match.beingOverwritten = true;
		}
	}

	protected findMatchingAssetAndMarkAsOverwrittenByFanContribution(learningAsset:LearningAsset):void {
		let match:LearningAsset = this.assets.find(asset => {
			return asset.fileName == learningAsset.hasConflictWith;
		});
		if (match) {
			console.info(`found matching asset: ${match.fileName}`);
			match.beingOverwritten = true;

			//set the overwrite message for the new asset
			let overwriteMessage:RenameEvent = new RenameEvent();
				overwriteMessage.learningAsset = learningAsset;
				overwriteMessage.newFileName = match.fileName;
				overwriteMessage.mode = LearningAssetComponentMode.regular;

			try {
				//delete the old asset
				this.onAssetDelete(match);
				//rename the new asset
				this.onAssetRename(overwriteMessage);

			} catch (error) {
				console.error(error);
				throw (error);
			}

		} else {
			console.warn(`no match found!`);
		}
	}

	public onLearningAssetProblemFixChoiceConfirmed(mode:LearningAssetUploadComponentMode, learningAssetUpload:LearningAssetUpload) {
		switch (mode) {
			case LearningAssetUploadComponentMode.overwrite:
				this.logger.info("will overwrite " + learningAssetUpload.name);
				this.findMatchingAndMarkAsOverwritten(learningAssetUpload);
				break;
			case LearningAssetUploadComponentMode.discard:
				this.onUploadRemove(learningAssetUpload);
				break;
			case LearningAssetUploadComponentMode.rename:
				this.logger.info("will rename " + learningAssetUpload.name);
				break;
			default:
				this.logger.error("onLearningAssetProblemFixChoiceConfirmed: Default case hit!");
				break;
		}
	}

	public onFanContributionAssetProblemFixChoiceConfirmed(mode:LearningAssetComponentMode, learningAsset:LearningAsset, newFileName?:string) {
		switch (mode) {
			case LearningAssetComponentMode.overwrite:
				console.info("will overwrite " + learningAsset.hasConflictWith);
				this.findMatchingAssetAndMarkAsOverwrittenByFanContribution(learningAsset);
				break;
			case LearningAssetComponentMode.discard:
				console.info(`deleting ${learningAsset.fileName}`);
				this.onAssetDelete(learningAsset);
				break;
			case LearningAssetComponentMode.rename:
				console.info(`renaming ${learningAsset.fileName}`);
				break;
			default:
				console.error("onFanContributionAssetProblemFixChoiceConfirmed: Default case hit!");
				break;
		}
	}


	public handleFolderIssues(issues:Array<File>) {
		this.notificationService.displayToast(this.cbContext.toasts.upload_folder, ToastType.error, {count: issues.length});

	}
}
