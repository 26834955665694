import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ResourceSubmission, ResourceSubmissionStatus} from "../../../../../domain/resources/ResourceSubmission";
import {LearningAsset} from "../../../../../domain/resources/LearningAsset";
import {SupportedFileTypes} from "../../../../../helper/SupportedFileTypes";
import {WaihonaUserRef} from "../../../../../domain/user/WaihonaUserRef";
import {BaseComponent} from "../../../../BaseComponent";
import {FirebaseFileInfo} from "../../../../../services/storage/FirebaseFileInfo";
import {FileTypeService} from "../../../../../services/FileTypeService";
import {DownloadService} from "../../../../../services/DownloadService";
import {FileService} from "../../../../../services/FileService";
import {ToastType} from "../../../../../services/common/NotificationService";
import {Localization} from "../../../../../data/Localization";
import {ResourcePublishStatus} from "../../../../../domain/resources/ResourcePublishStatus";
import {PublishState} from "../../../../../domain/resources/PublishState";
import {ResourceSubmissionTextContent} from "../../../../../domain/resources/ResourceSubmissionTextContent";
import {PublishedResourceRef} from "../../../../../domain/resources/PublishedResourceRef";
import {CollectionRef} from "../../../../../domain/collections/CollectionRef";
import {sort} from "../../../../../helper/SortTools";
import {SupportedLanguage} from "../../../../../domain/SupportedLanguages";


@Component({
	selector: 'lesson-content',
	templateUrl: './lesson-content.component.html',
	styleUrls: ['./lesson-content.component.scss'],
})
export class LessonContentComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.resource.lesson_content;
	public CB = {
		contributor: this.cbContext.labels.contributor,
		org: this.cbContext.labels.org,
		collaborators: this.cbContext.labels.collaborators,
		reviewer: this.cbContext.labels.reviewer,
		selfPublished: this.cbContext.labels.self_published,
		publicResource: this.cbContext.labels.public_resource,
		membersOnlyResource: this.cbContext.labels.members_only_resource,
		tooltip_publicResource: this.cbContext.labels.tooltip_public_resource,
		tooltip_membersOnlyResource: this.cbContext.labels.tooltip_members_only_resource,
		tooltip_selfPublished: this.cbContext.labels.tooltip_self_published,
		summary: this.cbContext.labels.summary,
		content: this.cbContext.labels.content,
		sources: this.cbContext.labels.sources,
		modifications: this.cbContext.labels.modifications,
		assets: this.cbContext.labels.assets,
		filename: this.cbContext.table.filename,
		fileSummary: this.cbContext.table.summary,
		fileTags: this.cbContext.table.tags,
		fileType: this.cbContext.table.filetype,
		zip: this.cbContext.download_zip,
		links: Localization.template.common.links.header,
		related_header: Localization.template.resource.detail.related.header,
	};

	public localize = {
		resourceSubmission: ()=> this.localizationService.LocalizeTools.document(this.resourceSubmission, ResourceSubmissionTextContent, this.localizationService.language),
		refTitle: (ref:PublishedResourceRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "title"),
	};

	public sortedResourceRefs:PublishedResourceRef[] = null;
	@Input()
	public submitter:WaihonaUserRef;
	@Input()
	public reviewers:Array<WaihonaUserRef>;
	@Input()
	public publishStatus?:ResourcePublishStatus;
	@Input()
	public collections:Array<CollectionRef> = [];
	@Output()
	public onResourceRefClick:EventEmitter<string> = new EventEmitter<string>();

	private _resourceSubmission:ResourceSubmission = null;
	@Input()
	public set resourceSubmission(newResourceSubmission:ResourceSubmission){
		this._resourceSubmission = newResourceSubmission;
		if(this.resourceSubmission.relatedResources?.length > 0) {
			this.sortedResourceRefs = sort(this.resourceSubmission.relatedResources, "title", {nupepafy: true, localizedField: true, ref: true, lang: this.localizationService.language}).ascending();
		} else {
			this.sortedResourceRefs = [];
		}
	};
	public get resourceSubmission():ResourceSubmission {
		return this._resourceSubmission;
	}

	constructor(protected injector:Injector,
	            private fileTypeService:FileTypeService,
				protected fileService:FileService,
	            protected downloadService:DownloadService) {
		super(injector);
		this.localizationService.registerAndLocalize("LessonContentComponent", this.CB);
	}

	public ngOnInit() {
		this.trackSubscription(this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
			if(this.resourceSubmission.relatedResources?.length > 0) {
				this.sortedResourceRefs = sort(this.resourceSubmission.relatedResources, "title", {nupepafy: true, localizedField: true, ref: true, lang: newLang}).ascending();
			} else {
				this.sortedResourceRefs = [];
			}
		}));
	}

	public get ResourceSubmissionTextContent():(new()=>{}) {
		return ResourceSubmissionTextContent;
	}
	public get PublishState() {
		return PublishState;
	};

	public getZipLocation():string {
		return this.urlService.resourceZip.published(this.resourceSubmission.guid, this.resourceSubmission.version.major.toString());
	}

	public getFriendlyFileType(learningAsset):string {
		return this.fileTypeService.getFriendlyFileType(learningAsset);
	}

	public getFileName(learningAsset:LearningAsset):string {
		const path:string = "/assets/img/fileTypeIcons/";
		return path + SupportedFileTypes.getFileName(learningAsset.extension);
	}

	public getAssetURL(fileName) {

		if (this.isInDraftState()) {
			return this.urlService.resourceAssets.draft(this.resourceSubmission.guid, fileName);
		} else if (this.isInReviewState()) {
			return this.urlService.resourceAssets.review(this.resourceSubmission.guid, fileName);
		}

		return this.urlService.resourceAssets.published(this.resourceSubmission.guid, fileName);
	}

	public isInDraftState():boolean {
		return [ResourceSubmissionStatus.draft, ResourceSubmissionStatus.submitted].includes(this.resourceSubmission.status);
	}

	public isInReviewState():boolean {
		return [ResourceSubmissionStatus.in_review_revision, ResourceSubmissionStatus.in_review_continuation, ResourceSubmissionStatus.in_review].includes(this.resourceSubmission.status);
	}

	public isInPublishedState():boolean {
		return this.resourceSubmission.status == ResourceSubmissionStatus.published;
	}

	public isSelfReviewed():boolean {
		return (this.reviewers.length == 1 && this.reviewers[0].guid == this.submitter.guid);
	}

	public isPublicResource():boolean {
		return this.resourceSubmission.configuration.allowPublicAccess;
	}

	public async downloadResourceAsset(learningAsset:LearningAsset) {
		let fileInfo:FirebaseFileInfo = learningAsset.fileInfo;
		let url:string = this.getAssetURL(fileInfo.name);
		try {
			this.downloadService.tryDownload(url).take(1).subscribe((response) => {
				if (response === "File Not Found") {
					this.notificationService.displayToast(Localization.template.resource.common_toasts.download.problem, ToastType.error);
				}
			});
		} catch (err) {
			console.error(err);
		}
	}

	public async openResourceAsset(learningAsset:LearningAsset) {
		let fileInfo:FirebaseFileInfo = learningAsset.fileInfo;
		let url:string = this.getAssetURL(fileInfo.name);
		try {
			this.fileService.tryOpen(url).take(1).subscribe((response) => {
				if (response === "File Not Found") {
					this.notificationService.displayToast(Localization.template.resource.common_toasts.file_open.problem, ToastType.error);
				}
			});
		} catch (err) {
			console.error(err);
		}
	}

	public resourceRefSubmitterName(resourceRef:PublishedResourceRef):string {
		if (resourceRef.configuration?.hideContributorName) {
			return resourceRef.organization.title;
		} else {
			return (resourceRef.submitter.altFirstName ? resourceRef.submitter.altFirstName : resourceRef.submitter.firstName) + " " + resourceRef.submitter.lastName;
		}
	}

	public downloadResourceZip($event:MouseEvent):void {
		$event.preventDefault();
		let url = this.getZipLocation();
		try {
			this.downloadService.tryDownload(url).take(1).subscribe((response) => {
				if (response === "File Not Found") {
					this.notificationService.displayToast(Localization.template.resource.common_toasts.download.problem, ToastType.error);
				}
			});
		} catch (err) {
			console.error(err);
		}
	}

	public onRelatedResourceClick(resource:PublishedResourceRef):void {
		this.onResourceRefClick.emit(resource.guid);
	}

}
