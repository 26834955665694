import {Converter} from "../ConversionService";
import {Badge} from "../../domain/badges/Badge";
import {BadgeRef} from "../../domain/badges/BadgeRef";
import {RefLocalization} from "../../domain/ITextContentObject";


export class BadgeToBadgeRefConverter implements Converter {

	public from:typeof Badge;
	public to:typeof BadgeRef;

	constructor() {
		this.from = Badge;
		this.to = BadgeRef;
	}

	public convert(source:Badge):BadgeRef {
		let guid:string = source.guid;
		let en = source.documentTextContent.en;
		let haw = source.documentTextContent.haw;

		let refLocalization:RefLocalization = {
			name:{
				en:en?.name,
				haw:haw?.name,
			},
			description: {
				en: en?.description,
				haw: en?.description
			}
		};

		return BadgeRef.create(guid, refLocalization);
	}
}