<div class="animated fadeIn">
	<h1 class="display-5 text-primary font-weight-bold mt-4"></h1><br>

	<div class="card">
		<div class="card-body">
			<ng-container *ngIf="badge != null">
				<div class="row">

					<!--sidebar div-->
					<div class="col-md-3">

						<!--Meta Card-->
						<div class="card border border-0">
							<img class="card-img-top badge-img" [src]="badgeImage" alt="badge artwork">
							<div class="mt-5">
								<button *ngIf="canEdit" class="btn btn-lg btn-primary text-white font-weight-bold" [routerLink]="['/more/badges', badge.guid, 'edit']">{{CB.editBtn | localize | async}}</button>
								<!--<button *ngIf="canAward" class="btn btn-lg btn-secondary font-weight-bold my-3">{{CB.awardBtn | localize | async}}</button>-->
							</div>

						</div>


					</div>

					<!--Main section-->
					<div class="col-md-9">
						<div class="card border border-0">
							<div class="card-body">
								<div class="mb-5">
									<h1 class="card-title font-weight-bold text-primary">{{badgeContent.name}}</h1>
									<h4 class="card-subtitle" [innerHTML]="badgeContent.description"></h4>
								</div>
								<div class="mb-5">
									<h5 class="font-weight-bold text-primary">{{CB.header_criteria | localize | async}}:</h5>
									<p class="card-text" [innerHTML]="badgeContent.criteria"></p>
								</div>
								<!--todo: enable the cards after there is a service to award badges-->
								<!--<div>
									<h5 class="font-weight-bold text-primary">{{CB.header_people | localize | async}}:</h5>
									<p class="card-text">{{CB.noUsers | localize | async}}</p>
								</div>-->

							</div>
						</div>
					</div>
				</div>

				<!--closing tags-->
			</ng-container>
		</div>
	</div>
</div>
