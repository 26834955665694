import {Framework, FrameworksType} from "../domain/frameworks/Framework";
import {FrameworkRef} from "../domain/frameworks/FrameworkRef";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {Review} from "../domain/resources/review/Review";
import {StubData} from "./StubData";


export class EmailStubData {

	public stubData:StubData;


/*	public getReviewEmail_toKahu():ReviewEmail {
		let sourceUser:WaihonaUser = this.stubData.hogwarts.severusWaihonaUser;
		let receiver:WaihonaUser = this.stubData.devteam.kabright_waihonaUser;
		let review:Review = this.stubData.hogwarts.review.polyjuicePotionReview;

		let reviewEmail:ReviewEmail = new ReviewEmail();
			reviewEmail.sourceWaihonaUser = sourceUser;
			reviewEmail.receiverWaihonaUser = receiver;
			reviewEmail.review = review;

		return reviewEmail;
	}*/

}




