import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {AuthService} from "../services/AuthService";
import {Observable, Subject, Subscription} from "rxjs";

@Injectable()
export class AuthenticationCallbackGuard implements CanActivate {

	constructor(private router: Router, private location: Location, private authService:AuthService) { }

	canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		var path = this.location.path(true);
		let isAuthenticated$:Subject<boolean> = new Subject<boolean>();

		// You may want to make a more robust check here
		var isAuthenticationCallback = path.indexOf("code") !== -1;

		if (isAuthenticationCallback) {
			let s:Subscription = this.authService.isAuthenticated$.subscribe(isAuthenticated => {
				isAuthenticated$.next(isAuthenticated);
				s.unsubscribe();
			});
		} else {
			isAuthenticated$.next(false);
		}

		return isAuthenticated$;

	}
}
