import {Injectable} from "@angular/core";
import {ResourceChatRepository} from "./repository/ResourceChatRepository";
import {ResourceChat} from "../domain/resources/chat/ResourceChat";
import {ResourceChatMessage} from "../domain/resources/chat/ResourceChatMessage";
import {Observable} from "rxjs";
import {AuthService} from "./AuthService";
import {firestore} from "firebase";
import FieldValue = firestore.FieldValue;
import {classToPlain} from "class-transformer";

@Injectable({
	providedIn: 'root',
} as any)
export class ResourceChatService {

	constructor(private repo:ResourceChatRepository, private authService:AuthService) {}

	public initializeChat(resourceId:string):void {
		//Safe way to do it if weʻre not sure it exists already...
		this.repo.updatePartial$({"resourceGuid": resourceId});
	}

	public async sendMessageToChat(resourceGuid:string, message:string) {
		let resourceChatMessage:ResourceChatMessage =
			ResourceChatMessage.create(message).as(this.authService.currentUserRef).at();

		this.repo.updatePartial$({
			resourceGuid: resourceGuid,
			messages: FieldValue.arrayUnion(classToPlain<ResourceChatMessage>(resourceChatMessage))
		});
	}

	public getChat$(resourceGuid:string):Observable<ResourceChat> {
		return this.repo.watch$(resourceGuid);
	}

}
