import {Component, Input, OnInit} from '@angular/core';
import {ResourceSubmission} from "../../../../domain/resources/ResourceSubmission";
import {Localization} from "../../../../data/Localization";
import {LocalizationService} from "../../../../services/LocalizationService";


@Component({
	selector: 'resource-submission-meta',
	templateUrl: './resource-submission-meta.component.html',
	styleUrls: ['./resource-submission-meta.component.scss']
})
export class ResourceSubmissionMetaComponent implements OnInit {

	private cbContext = Localization.template.resource.meta;
	public CB = {
		grades: this.cbContext.grades,
		subjects: this.cbContext.subjects,
		flags: this.cbContext.flags,
		modalities: this.cbContext.modalities,
		perspectives: this.cbContext.perspectives,
		aina: this.cbContext.aina,
		tags: this.cbContext.tags,
		frameworks: this.cbContext.frameworks,
	};

	@Input("resourceSubmission")
	public resourceSubmission:ResourceSubmission;

	constructor(private localizationService:LocalizationService) {
		this.localizationService.registerAndLocalize("ResourceSubmissionMetaComponent", this.CB);
	}

	ngOnInit() {
	}

}
