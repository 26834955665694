import {WaihonaUser} from "../domain/user/WaihonaUser";
import {Injectable} from "@angular/core";
import {validateSync, ValidationError} from "class-validator";
import {ValidationException} from "./exceptions/ValidationException";
import {WaihonaUserRepository} from "./repository/WaihonaUserRepository";
import {WaihonaUserFunctions} from "./functions/WaihonaUserFunctions";
import {UserProfileMessage} from "./messages/UserProfileMessage";
import {InvitationAnswerMessage} from "./messages/InvitationAnswerMessage";
import {Observable, Subject} from "rxjs";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {ConversionService} from "./ConversionService";
import {NGXLogger} from "ngx-logger";
import {WaihonaUserOrganization} from "../domain/user/WaihonaUserOrganization";
import {Organization} from "../domain/organization/Organization";
import {QueryFn} from "@angular/fire/firestore";
import {PageListService, PagingService} from "./PageListService";
import {Role, RoleType} from "../domain/user/Role";
import {BadgeAward} from "../domain/badges/BadgeAward";


@Injectable({
    providedIn: 'root',
} as any)
export class WaihonaUserService implements PagingService<WaihonaUser> {

	private _paging:{
		list:PageListService<WaihonaUser>,
	};

	public paging = {
		pageSize: 20,
		list: {
			cursor: () => {
				return this._paging.list.pageCursor;
			},
			initialize: {
				list$:() =>  this._paging.list.initializePaging$(this.repo.by.criteria.all(), this.paging.pageSize)
			},
			next:() => {
				this._paging.list.getNextPage();
			},
			isLoading: () => {
				return this._paging.list.pageLoading$;
			},
			nextPageExists: () => {
				return this._paging.list.pageCursor?.nextPageExists;
			},
		}
	}

	public WaihonaUsersUri: any = {
		allWaihonaUsers: "Users",
	};

	constructor(public repo:WaihonaUserRepository,
				private func:WaihonaUserFunctions,
				private conversionService:ConversionService,
				protected logger:NGXLogger) {

		this._paging = {
			list: new PageListService<WaihonaUser>(this.repo),
		}
	}

	public get store() {
		return this.repo.accessors();
	}
	public get by() {
		return this.repo.by;
	}

	public query$(queryFn:QueryFn):Observable<WaihonaUser[]> {
		return this.repo.query$(queryFn);
	}
	public queryAsRef$(queryFn:QueryFn):Observable<WaihonaUserRef[]> {
		return this.repo.queryAsRef$(queryFn);
	}

	/** Get all WaihonaUsers */
	public watchListAsRef$(limit?: number): Observable<WaihonaUserRef[]> {
		return this.repo.queryAsRef$(waihonaUserInQuery => {
			return waihonaUserInQuery.orderBy("lastName", "asc");
		});
	}

	/** Get all WaihonaUsers */
	public listAllWaihonaUserRefs(limit?: number): Observable<WaihonaUserRef[]> {
		return this.repo.queryAsRef$(contributors => {
			return contributors.orderBy("lastName", "asc");
		});
	}

	public getWaihonaUserById(guid:string):Observable<WaihonaUser> {
		return this.repo.get$(guid);
	}

	public watchWaihonaUser$(userGuid:string):Observable<WaihonaUser> {
		return this.repo.watch$(userGuid);
	}

	public getWaihonaUsersForIds(guids: Array<string>): Observable<WaihonaUser[]> {
		return this.func.getWaihonaUsersForIds(guids);
	}
	public favoriteUser(guid:string):Observable<WaihonaUser> {
		return this.func.favoriteUser(guid);
	}
	public unfavoriteUser(guid:string):Observable<WaihonaUser> {
		return this.func.unfavoriteUser(guid);
	}

	public getUserFullName(guid:string):Observable<string> {
		let fullName$:Subject<string> = new Subject<string>();
		this.getWaihonaUserById(guid).subscribe((user:WaihonaUser) => {
			fullName$.next(user.fullName);
		});
		return fullName$;
	}

	public getUserDefaultOrganization(waihonaUser:WaihonaUser):WaihonaUserOrganization {
		let userDefaultOrganization:WaihonaUserOrganization = waihonaUser.organizations.find(organization => {
			return organization.default;
		});
		if(userDefaultOrganization == null) {
			//First organization they are currently at
			userDefaultOrganization = waihonaUser.organizations.find(organization => {
				return organization.currentlyAt;
			});
		}
		if(userDefaultOrganization == null) {
			let firstOrganization:WaihonaUserOrganization = waihonaUser.organizations[0];
			userDefaultOrganization = firstOrganization;
		}
		return userDefaultOrganization;
	}


	public saveProfile(profile:UserProfileMessage):Observable<WaihonaUser> {
		this.logger.info(`WaihonaUserService is calling saveProfile on the client`);
		return this.func.saveProfile(profile);
	}

	public acceptInvitation(orgGuid:string, userTitle:string):Observable<WaihonaUser> {
		this.logger.info("calling acceptInvitation from WaihonaUserService on the client");
		let message:InvitationAnswerMessage = new InvitationAnswerMessage();
		message.acceptsInvite = true;
		message.orgGuid = orgGuid;
		message.userTitle = userTitle;

		return this.func.answerOrgInvite(message);
	}

	public declineInvitation(orgGuid:string):Observable<WaihonaUser> {
		this.logger.info("calling declineInvitation from WaihonaUserService on the client");
		let message:InvitationAnswerMessage = new InvitationAnswerMessage();
		message.acceptsInvite = false;
		message.orgGuid = orgGuid;
		message.userTitle = null;

		return this.func.answerOrgInvite(message);
	}

	public changeDefaultWaihonaUserOrganzation(waihonaUser:WaihonaUser, newOrgGuid:string):Observable<WaihonaUser> {
		//set values
		let waihonaUserOrganizations:Array<WaihonaUserOrganization> =  waihonaUser.organizations;
			for (let i:number = 0; i < waihonaUserOrganizations.length; i++) {
				let userOrg = waihonaUserOrganizations[i];
				if (userOrg.organizationGuid == newOrgGuid) {
					userOrg.default = true;
				} else {
					userOrg.default = false;
				}
			}

		//save the newly-updated waihonaUser object
		return this.repo.save$(waihonaUser);
	}

	/**
	 *
	 * @param waihonaUser
	 * @throws ValidationException
	 */
	public validate(waihonaUser:WaihonaUser):void {
		let errors:ValidationError[] = validateSync(waihonaUser);

		if (errors.length > 0) {
			this.logger.info("validation failed. errors: ", errors);
			throw new ValidationException(errors);
		}
	}

	public save(waihonaUser:WaihonaUser):Observable<WaihonaUser> {
		this.validate(waihonaUser);
		return this.repo.save$(waihonaUser);
	}

	public updateRoles(waihonaUserRoles:{guid:string, roles:Object}):Observable<WaihonaUser> {
		return this.repo.updatePartial$(waihonaUserRoles);
	}

	public updateAvatar(userGuid:string, avatarExists:boolean, version:number):Observable<WaihonaUser> {
		return this.repo.updatePartial$({guid: userGuid, avatar: {exists: avatarExists, version: version}});
		//return this.repo.updatePartial$(user, {avatar: true}); //TODO: Update partial needs to get fixed so that contained properties are sent
	}

	public addRoleToUser$(userGuid:string, roleType:RoleType, orgGuid:string = null):Observable<WaihonaUser> {
		this.getWaihonaUserById(userGuid).subscribe(waihonaUser => {
			let hasRole:Role = waihonaUser.roles.find(role => {
				return (role.type === roleType) && (role.organization === orgGuid);
			});
			if (!hasRole) {
				let newRole:Role = new Role;
				newRole.type = roleType;
				newRole.organization = orgGuid;
				let roles = waihonaUser.roles.push(newRole);
				return this.updateRoles({guid:waihonaUser.guid, roles:roles});
			} else {
				throw new Error("This user already has the role you are trying to add.");
			}
		})
		return null;
	}

	public removeRoleFromUser$(userGuid:string, roleType:RoleType, orgGuid:string = null):Observable<WaihonaUser> {
		this.getWaihonaUserById(userGuid).subscribe(waihonaUser => {
			let roleIndex:number = waihonaUser.roles.findIndex(role => {
				return (role.type === roleType) && (role.organization === orgGuid);
			});
			if (roleIndex > -1) {
				let modifiedRoles:Array<Role> = waihonaUser.roles.splice(roleIndex, 1);
				return this.updateRoles({guid:waihonaUser.guid, roles:modifiedRoles});
			} else {
				throw new Error("This user does not have the role you are trying to remove.");
			}
		})
		return null;
	}

	public sendRequestToJoinOrganization(waihonaUser:WaihonaUser, organization:Organization, titleAtOrg:string):Observable<WaihonaUser> {
		this.logger.info(`WaihonaUserService::sendRequestToJoinOrganization start - handling user request to join org`);

		//convert user to ref and send to OrganizationService
		let waihonaUserRef = this.conversionService.convert(waihonaUser, WaihonaUserRef);
		try {
			this.func.requestToJoinOrg(organization, waihonaUserRef);
		} catch (requestJoinOrgErr) {
			this.logger.error(`ERROR in request to join org: ${requestJoinOrgErr}`);
			return null;
		}

		//check to make sure org is not already in waihonaUserOrgArray
		if (waihonaUser.organizations.find(org => (org.organizationGuid == organization.guid))) {
			this.logger.error(`This organization already exists in the user's organizations array!`);
			return null;
		}

		//create new waihonaUserOrganization object for user
		this.logger.info(`creating a new waihona user organization for this user`);
		let newOrg:WaihonaUserOrganization = new WaihonaUserOrganization();
			newOrg.organizationGuid = organization.guid;
			newOrg.organizationName = organization.title;
			newOrg.userTitle = titleAtOrg;
			newOrg.pendingApproval = true;
			newOrg.needsToAcceptInvite = false;
			newOrg.default = false;
			newOrg.currentlyAt = false;
		waihonaUser.organizations.push(newOrg);

		//save user
		return this.repo.save$(waihonaUser);
	}

	public addBadgeAwardToUser$(badgeAward:BadgeAward, userGuid:string):Observable<WaihonaUser> {
		if (badgeAward && userGuid) {
			this.getWaihonaUserById(userGuid).subscribe(waihonaUser => {

				//check to make sure badgeAward is not already in badges array
				if (waihonaUser.badges.find(badge => (badge.badgeRef.guid == badgeAward.badgeRef.guid  && badge.awardLevel == badgeAward.awardLevel))) {
					this.logger.log(`User ${waihonaUser.guid} already has the ${badgeAward.badgeRef.guid} badge. No action taken.`);
					return waihonaUser;
				}

				//otherwise, add the badge to the array and update the user.
				this.logger.log(`Adding the ${badgeAward.badgeRef.guid} badge to user ${waihonaUser.guid}.`)
				waihonaUser.badges.push(badgeAward);
				return this.repo.updatePartial$(waihonaUser, {badges: waihonaUser.badges});
			})
		}
		return null;
	}

	//todo: make a counter
	/*public incrementUnreadMessageCounter(waihonaUser:WaihonaUser):Observable<WaihonaUser> {
		let updateObject:any = {
			userCounter: {
				inbox: {
					unread: waihonaUser.userCounter.inbox.unread + 1,
				}
			}
		}
		this.repo.updatePartial$(updateObject).subscribe(waihonaUser => {
			if (waihonaUser) {
				return waihonaUser;
			}
		});
		return null;
	}*/

}

