/** NOTE: having it in this format prevents us from referring to properties we haven't defined yet (though string
 *  property files are more popular, this way of doing things has less risk of error.*/
import {SupportedLanguage} from "../domain/SupportedLanguages";
import {DelimiterType} from "../areas/modals/invite-user-by-email-modal/invite-user-by-email-modal.component";

export class Localization {

    public static template = {
        admin: {
            delete_files: {
                toast: {
                    success: {
                        title: {
                            en: `Delete File`,
                            haw: `Kāpae i ka Faila`
                        },
                        body: {
                            '@type': 'mustache',
                            en: () => `Successfully deleted {{path}}`,
                        }
                    },
                    failure: {
                        title: {
                            en: `Delete File`,
                            haw: `Kāpae i ka Faila`
                        },
                        body: {
                            '@type': 'mustache',
                            en: () => `Failed in deleting {{path}}.  Perhaps it did not exist?`
                        }
                    }
                }
            },
            localization: {
                list: {
                    header: {
                        en: `Translation Data Editor`
                    },
                    card_header: {
                        en: `Manage Edits:`
                    },
                    alert: {
                        header: {
                            en: `Instructions`
                        },
                        list_headers: {
                            one: {
                                en: `Import:`
                            },
                            two: {
                                en: `Edit:`
                            },
                            three: {
                                en: `Confirm:`
                            },
                            four: {
                                en: `Save:`
                            },
                            five: {
                                en: `Preview:`
                            },
                            six: {
                                en: `Publish:`
                            }
                        },
                        list_items: {
                            one: {
                                en: `Begin by clicking the "Import New Text" button to check for new text that is awaiting translation.`
                            },
                            two: {
                                en: `Make edits in the table below.`
                            },
                            three: {
                                en: `Confirm that the other side of the translation is correct by checking the box below your change.`
                            },
                            four: {
                                en: `Click "Save" on each line of changes before continuing.`
                            },
                            five: {
                                en: `If you have selected the "Turn Edit Mode On" option from the Translations menu, you will be able to preview your changes in context throughout the site.`
                            },
                            six: {
                                en: `When you are finished making changes, click the "Publish Changes" button to finalize your edits and make them visible to the public. Please note that it will take several seconds for your changes to populate site-wide.`
                            }
                        }
                    },
                    custom_buttons: {
                        import: {
                            en: `Import New Text`
                        },
                        publish: {
                            en: `Publish Changes`
                        },
                        view_all: {
                            en: `View All`
                        },
                        view_outdated: {
                            en: `View Outdated`
                        },
                        view_missing: {
                            en: `View Missing`
                        },
                        view_selected: {
                            en: `View Selected`
                        }
                    },
                    table: {
                        headers: {
                            remove: {
                                en: `Remove`,
                                haw: `Hemo`
                            },
                            location: {
                                en: `Location`
                            },
                            english: {
                                en: `English`,
                                haw: `ʻŌlelo Pelekania`
                            },
                            hawaiian: {
                                en: `ʻŌlelo Hawaiʻi`,
                                haw: `ʻŌlelo Hawaiʻi`
                            },
                            action: {
                                en: `Action`
                            }
                        }
                    }

                },
            },
            validation: {
                error: {
                    title: {
                        en: "Invalid entry"
                    },
                    body: {
                        en: "You have entered invalid data.  Your input must be alphanumeric without punctuation or spaces in kebab-case."
                    }
                },
                already_exists: {
                    title: {
                        en: "Oops!"
                    },
                    body: {
                        en: "This code already exists!"
                    }
                }
            }
        },
        alert_headers_reusable: {
            did_you_know: {
                en: `Did you know?`
            },
            language_mismatch: {
                en: `Possible Language Mismatch:`
            },
            ie_alert: {
                '@type': 'mustache',
                en: () => `Microsoft has announced they are ending all support for Internet Explorer. For the best and most reliable experience we recommend you use <a href='{{url}}' target="_blank">Google Chrome</a>.`,
            }
        },
        app: {
            initialization: {
                toasts: {
                    localization_startup: {
                        starting_in_olelo:{
                            title:{
                                en:"Starting in ʻŌlelo Hawaiʻi",
                            },
                            body:{
                                en:"We noticed you were in ʻŌlelo Hawaiʻi previously.  Resuming ʻŌlelo Hawaiʻi mode.  Toggle the switch to return to English."
                            }
                        }
                    }
                }
            }
        },
        app_header: {
            top_level: {  // Main menu items (top level)
                resources: {
                    en: "Resources",
                    haw: "Nā Haʻawina"
                },
                contributors: {
                    en: "Contributors",
                    haw: "Nā Mea Hoʻokaʻa Haʻawina"
                },
                organizations: {
                    en: "Organizations",
                    haw: "Nā Hui"
                },
                more: {
                    en: "More",
                    haw: "Nā mea ʻē aʻe"
                },
                log_in: {
                    en: "Log In",
                    haw: "ʻEʻe i Luna"
                },
                greeting: {
                    en: "Aloha,",
                    haw: "Aloha e"
                }
            },
            more_menu: {
	            collections: {
		            en: "Collections",
		            haw: "Nā Pūʻulu Haʻawina"
	            },
                badges: {
                    en: "Badges",
                    haw: "Nā Pihi"
                },
                frameworks: {
                    en: "Frameworks",
                    haw: "Nā ʻOlokeʻa Aʻo"
                },
                community: {
                    en: "Legal Considerations"
                },
                about: {
                    en: "About",
                    haw: "No ka Waihona"
                }
            },
            dropdown_menu: { //Items in dropdown profile menu
                inbox: {
                    en: "My Inbox",
                    haw: "Koʻu Pahu Leka"
                },
                profile: {
                    en: "My Profile",
                    haw: "Koʻu ʻIkepili"
                },
                my_reviews: {
                    en: "My Reviews",
                    haw: "Koʻu mau mea e Nānā ai"
                },
                resources: {
                    en: "My Resources",
                    haw: "Kaʻu mau Haʻawina"
                },
                organizations: {
                    en: "My Organizations",
                    haw: "Koʻu mau Hui"
                },
                collections: {
                  en: "My Collections",
                },
                admin: {
                    en: "Admin Dashboard",
                },
                log_out: {
                    en: "Log Out",
                    haw: "Lele i Lalo"
                }
            },
            localization_menu: {
                numberBlocks: {
                    '@type': 'mustache',
                    en: () => `This page has {{number}} content block(s):`,
                },
                viewAll: {
                    en: `View all content blocks`
                }
            }
        },
        app_footer: {
            legal: {
                en: "Kuleana"
            },
            slack: {
                en: "Discuss on Slack"
            },
            feedback: {
                en: "Submit Feedback"
            },
            modal: {
                title: {
                    en: "Give us your feedback"
                },
                body: {
                    en: `Do you have issues or ideas relating to this tool that you'd like to share with the development team? E kōkua mai!`,
                },
                placeholder: {
                    en: `He aha kou manaʻo? What are your thoughts? Please provide as much detail as possible.`
                },
                send: {
                    en: `Send Feedback`
                }
            }
        },
        badges: {
            list: {
                header: {
                    en: `Badges and Milestones`,
                },
                create: {
                    en: `Create a new badge`,
                }
            },
            detail: {
				headers: {
					criteria: {
						en: `Criteria to Earn this Badge`
					},
					people: {
						en: `People who have Earned this Badge`
					}
				},
				text: {
					no_users: {
						en: `Be the first to earn this badge!`
					}
				},
				badge_buttons: {
					edit: {
						en: `Edit Badge`
					},
					award: {
						en: `Award Badge`
					}
				}
			},
			edit: {
				custom_buttons: {
					add_english: {
						en: `Click to add English-language content`
					},
					add_hawaiian: {
						en: `Click to add Hawaiian-language content`
					},
					save_content: {
						en: `Save Content`
					},
					remove_content: {
						en: `Remove Content`
					},
					save_badge: {
						en: `Save Badge`
					},
					view_badge: {
						en: `View Badge`
					}
				},
				header: {
					en: `Badge Editor`
				},
				form: {
					headers: {
						guid: {
							en: `First, enter a unique badge ID:`
						},
						english: {
							en: `English Content`
						},
						hawaiian: {
							en: `Hawaiian Content`
						},
						badge_art: {
							en: `Badge Artwork`
						}
					},
					content: {
						name: {
							label: {
								en: `Badge Name`
							},
							placeholder: {
								en: `Enter the name of this badge`
							}
						},
						description: {
							label: {
								en: `Badge Text`
							},
							placeholder: {
								en: `How will we describe this badge? This is the "punny" part.`
							}
						},
						criteria: {
							label: {
								en: `Badge Criteria`
							},
							placeholder: {
								en: `Please describe the criteria to earn this badge, in user-friendly sentences.`
							}
						}
					}
				},
				toast: {
					errors: {
						insufficient_permission: {
							title: {
								en: `Insufficient Permission`
							},
							body: {
								en: `We're sorry, but you do not have sufficient permission to perform this action.  If you feel that this is an error, please check with a Waihona Administrator to update your account.`
							}
						},
						upload: {
							title: {
								en: `Unable to Upload`
							},
							body: {
								en: `We're sorry, but we encountered an error while trying to upload this file.  Please try again.`
							}
						}
					},
					save: {
						first_time: {
							title: {
								en: `Hulō!`
							},
							body: {
								en: `You have successfully created a new badge! You may return to edit this badge at any time.`
							}
						},
						success: {
							title: {
								en: `Hulō!`
							},
							body: {
								en: `You have successfully saved your changes.`
							}
						},
						fail: {
							title: {
								en: `Problem Found`
							},
							body: {
								en: `This badge has not been saved. Please check for errors and try again.`
							}
						},
						duplicate_guid: {
							title: {
								en: `Problem Found`
							},
							body: {
								en: `The badge ID you entered already exists, and this badge may already be in the database.  If this is indeed new, please create a unique ID for this badge.`
							}
						}
					}
				}
			}},
        buttons: { //some of these are shared between multiple components
            accept: {
                en: `Accept`
            },
            approve: {
                en: `Approve`
            },
            cancel: {
                en: `Cancel`,
                haw: `Hoʻōki`
            },
	        continue: {
                en: `Continue`
	        },
            decline: {
                en: `Decline`
            },
            delete: {
                en: `Delete`,
                haw: `Kāpae`
            },
            discard: {
                en: `Discard Changes`
            },
            discard_plain: {
                en: `Discard`},
			language_switcher: {
				en: `Editing Language`,
            },
			edit: {
            	en: `Edit`
			},
            loading: {
                en: `Loading...`
            },
            login: {
                en: `Log In`,
                haw: `ʻEʻe i Luna`
            },
            logout: {
                en: `Log out`
            },
            no: {
                en: `No`,
                haw: `ʻAʻole`
            },
            overwrite: {
                en: `Overwrite`
            },
            pause: {
                en: `Pause`
            },
            preview: {
                en: `Preview`
            },
            publish: {
                en: `Publish Now`
            },
			quick_edit: {
            	en: `Quick Edit`
			},
            register: {
                en: `Register`
            },
            reject: {
                en: `Reject`
            },
            rename: {
                en: `Rename`
            },
            reset: {
                en: `Reset`
            },
            resume: {
                en: `Resume`
            },
	        return_to_list: {
		        en: `Return to List`
	        },
            save: {
                en: `Save`,
                haw: `Mālama`
            },
	        saving: {
            	en: `Saving...`,
		        haw: `ke mālama nei...`
	        },
            save_Image: {
                en: `Save Image`,
                haw: `Mālama`
            },
	        save_and_continue: {
		        en: `Save and Continue`
	        },
            send: {
                en: `Send`
            },
            sending: {
                en: `Sending...`
            },
            submit: {
                en: `Submit`
            },
            suggest: {
                en: `Suggest:`
            },
            suggest_new: {
                en: `Suggest New:`
            },
            verify: {
                en: `Verify`
            },
	        view: {
		        en: `View`,
		        haw: `Nānā`
	        },
            yes: {
                en: `Yes`,
                haw: `ʻAe`
            }
        },
	    collections: {
		    add_to_collection_modal: {
			    cancel: {
				    en: `Cancel`,
				    haw: `Kāpae`,
			    },
			    create_collection: {
				    en: `Create`,
				    haw: `Holo`,
			    },
			    create_new: {
				    en: `+ Create New Collection`,
				    haw: `+ Hana i Pūʻulu Hou`,
			    },
			    new_collection_title_placeholder: {
				    en: `Name of new collection...`,
				    haw: `Inoa no ka pūʻulu hou...`,
			    },
				new_collection_description_placeholder: {
			    	en:`Add a short summary or description...`,
				},
			    select_collections_placeholder: {
				    en: `Select collection(s)`,
				    haw: `Koho i (mau) pūʻulu`,
			    },
			    submit: {
				    en: `Confirm`,
				    haw: `Holo`,
			    },
			    title: {
				    en: `Add To Collection`,
				    haw: `Pākuʻi i Pūʻulu Haʻawina`
			    },
			    labels: {
			    	title: {
			    		en: `Collection Title`
					},
					summary: {
			    		en: `Summary`
					}
				}
		    },
			common: {
		    	visibility: {
					public: {
						en: "This collection is publicly visible"
					},
					none: {
						en: `This collection has not been shared with anyone else`
					}
				},
				types: {
		    		collection: {
		    			down: {
							en: `collection`
						},
						up: {
		    				en: `Collection`
						}
					},
					series: {
		    			down: {
							en: `series`
						},
						up: {
		    				en: `Series`
						}
					},
					public: {
		    			down: {
		    				en: `public`
						},
						up: {
		    				en: `Public`
						}
					},
					private: {
		    			down: {
		    				en: `private`
						},
						up: {
		    				en: `Private`
						}
					}
				}
			},
		    detail: {
		    	labels: {
		    		curated: {
						'@type': 'mustache',
						en: () => `Curated by {{name}}`
					},
				    visibility : {
					    en: `Visibility:`,
					    haw: ``
				    },
				    owner: {
					    en: `Owner:`,
					    haw: ``
				    },
				    collaborators: {
					    en: `Collaborators:`,
					    haw: ``
				    },
				    description: {
					    en: `Description:`,
					    haw: ``
				    },
				    header_resources: {
					    en: `Resources:`,
					    haw: `Nā Haʻawina`
				    },
					table_header: {
		    			"@type": "mustache",
				    	en: ()=> `Resources in this {{collectionType}}`,
						haw: `Haʻawina no kēia pūʻulu`
					},
					add_or_edit: {
				    	en: `Add resource or edit collection`
					}
			    },
			    buttons: {
				    button_edit: {
					    en: `Edit Collection`,
					    haw: `Hoʻololi Pūʻulu`
				    },
			    },
		    },
		    edit: {
			    buttons: {
				    save: {
					    en: `Save Changes`,
					    haw: `Mālama`
				    },
				    language_switcher: {
					    header: {
						    en: `Editing Language`,
					    },
				    },
					view_collection: {
				    	en: `View Collection`,
					},
					view_series: {
				    	en: `View Series`,
					}
			    },
			    cover_image: {
				    warning: {
					    en: `You must save your new collection before uploading a cover image.`
				    }
			    },
			    collection_items: {
				    warning: {
					    en: `You must save your new collection before adding resources.`
				    }
			    },
			    formContent: {
				    labels: {
					    collectionName: {
						    en: "Name of collection or series:",
						    haw: "Ka inoa o ka pūʻulu:"
					    },
					    summary: {
						    en: `Summary:`
					    },
					    logo: {
						    en: `Select and crop a new image:`
					    },
					    visibilityType: {
						    en: `Visibility type:`
					    },
					    orderType: {
						    en: `Order type:`
					    },
					    collectionType: {
						    en: `Collection type:`
					    },
					    ownerType: {
						    en: `Owner type:`
					    },
					    selectNewOwnerOrg: {
						    en: `Choose organization:`
					    },
					    selectNewOwnerUser: {
						    en: `Transfer to a new owner:`
					    },
					    collaborators: {
						    en: `Collaborators:`
					    },
					    sharedTo: {
						    en: `Viewers:`
					    },
					    addItems: {
						    en: "Add resources:",
						    haw: "Pākuʻi i haʻawina hou:"
					    },
					    reorderItems: {
						    en: "Re-order resources:",
						    haw: "Hoʻonohonoho i nā haʻawina:"
					    },
					    listItems: {
						    en: "Resources in this collection:",
						    haw: ``
					    },
				    },
				    placeholders: {
					    collectionName: {
						    en: "Please enter the name of this collection.",
						    haw: "E ʻoluʻolu, e hōʻike mai i ka inoa o ka pūʻulu."
					    },
					    summary: {
						    en: `Please provide a brief summary that describes this collection or series.`
					    },
					    selectNewOwnerOrg: {
						    en: `Choose an organization`
					    },
					    selectNewOwnerUser: {
						    en: `Choose a new owner`
					    },
					    collaborators: {
						    en: `These users can make changes`
					    },
					    sharedTo: {
						    en: `These users cannot make changes`
					    },
				    },
				    tooltips: {
					    language: {
						    en: `This collection can be edited in English, ʻŌlelo Hawaiʻi, or both!  Use these buttons to select a language to work in. The active language is blue.`
					    },
					    visibilityType: {
						    en: `Choose the visibility for this collection. It can be visible to only the owner and collaborators (Private), visible to everyone in an organization (Organization), or visible to all Waihona users (Public).`
					    },
					    orderType: {
						    en: `Choose the ordering for this collection. If "Manual" is chosen, you can manually order the resources of this collection in any way you choose. If another ordering is chosen, by default the resources will automatically be ordered by it. For instance, if you choose "Alphabetical", the resources will always appear in alphabetical order by default.`
					    },
					    collectionType: {
						    en: `Choose the type of this collection. The default option is "Collection". "Series" indicates that the resources of this collection are all part of the same series.`
					    },
					    ownerType: {
						    en: `Choose the owner type for this collection. It can belong to a user, or an organization.`
					    },
					    collaborators: {
						    en: `Allow additional users to edit this collection.`
					    },
						cover_image: {
					    	en: `A cover image is optional for collections. If not provided, the collection will display the covers of the resources inside the collection.`
						},
					    sharedTo: {
						    en: `Allow additional users to view this private collection.`
					    },
					    resource_select: {
						    en: "Choose the resources that will be included in this collection."
					    },
					    add_items: {
						    en: "Choose the resources that will be included in this collection."
					    },
					    reorder_items: {
						    en: "Drag and drop the resources into the order you would like them displayed in this collection."
					    },
					    remove_items: {
						    en: "Click on the trash can icon to remove a resource from this collection."
					    },
				    }
			    },
			    headers: {
				    title: {
					    en: `Collection Editor`
				    },
					title_block: {
				    	en: `Collection Title and Summary`
					},
					item_block: {
				    	en: `Collection Items`
					},
					settings_block: {
						en: `Collection Settings`
					},
					manage_block: {
				    	en: `Collection Management`
					},
				    cover_image: {
					    en: `Collection Cover Image`
				    },
				    ownership: {
					    en: `Ownership`
				    },
				    authorized_users: {
					    en: `Authorized Users`
				    },
			    },
			    popovers: {
				    delete: {
				    	title: {
						    en: `Are you sure you want to remove this item?`,
						    haw: `E huki ʻia kēia mea?`
					    },
					    confirm: {
						    en: `Remove`,
						    haw: `Huki aku`
					    },
					    decline: {
						    en: `Cancel`,
						    haw: `ʻAʻole`
					    },
				    },
			    },
			    text: {
			    	ownership: {
			    		en: `This collection is being curated by`,
				    }
			    },
			    toast: {
				    fail: {
					    title: {
						    en: "Unable to save",
					    },
					    body: {
						    en: "We were unable to save because one or more errors have not yet been corrected."
					    }
				    },
				    saved: {
					    title: {
						    en: "Saved",
						    haw: "Ua mālama ʻia"
					    },
					    body: {
						    en: "The collection has been saved.",
						    haw: "Ua mālama ʻia ka pūʻulu"
					    }
				    }
			    },
		    },
		    list: {
		    	title: {
		    		en: `Browse Collections`,
		    		haw: ``
			    },
			    create_new: {
				    en: `Create a new collection`,
				    haw: ``
			    },
			    pill_collectionItems: {
				    en: `Resources`,
				    haw: ``
			    },
				expand: {
		    		en: `expand all`,
					haw: `hoʻonui`
				},
				collapse: {
		    		en: `collapse all`,
					haw: `hoʻoliʻiliʻi`
				}
		    },
		    toasts: {
			    create_collection: {
				    failure: {
					    title: {
						    en: "Oops!",
						    haw: "Auwē!"
					    },
					    body: {
						    en: "There was a problem with your new collection!",
						    haw: "Ua pilikia paha kāu pūʻulu hou!"
					    }
				    },
				    success: {
					    title: {
						    en: "Success!",
						    haw: "Nani!"
					    },
					    body: {
						    en: "New collection created!",
						    haw: "Kāu pūʻulu hou, ua puka!"
					    }
				    }
			    },
				update_collection:{
					failure:{
						title:{
							en:"Oops!",
							haw:"Auwē!"
						},
						body:{
							en:"There was a problem saving your changes. Please check and try again.",
						}
					},
					success:{
						title:{
							en:"Success!",
							haw:"Hulō!"
						},
						body:{
							en:"The changes to your collection have been saved.",
						}
					}
				},
			    resource_added: {
				    success: {
					    title: {
						    en: "Success!",
						    haw: "Nani!"
					    },
					    body: {
						    en: "Resource added to selected collection(s)!",
						    haw: "Ua pākuʻi ʻia!"
					    }
				    },
					fail: {
						title: {
							en: "Auwē!",
							haw: "Auwē!"
						},
						body: {
							en: `Unfortunately, there was an error adding this resource to your selected collection(s).  Please report this error to the development team by clicking on the "Submit Feedback" button below.`,
						}
					}
			    }
		    },
			types: {
		    	private: {
		    		en: `Private`
				},
				public: {
		    		en: `Public`
				},
				organization: {
		    		en: `Organization Only`
				}
			},
		    user_collections: {
		    	header: {
		    		en: `Manage My Collections`
				},
				none: {
		    		en: `No collections found!`
				},
				quick_edit: {
					en: `You can make quick edits here, or click on the collection title to view the collection and/or make additional edits.`
				},
				instructions: {
		    		owner: {
		    			en: `These are the collections that you created.`
					},
					collaborator: {
		    			en: `These are collections that you are collaborating on.`
					},
					org_admin: {
						'@type': 'mustache',
		    			en: () => `These are the collections that you are managing as an administrator for {{orgName}}.`
					}
				},
				table: {
		    		header: {
		    			en: `My Collections`
					},
					title: {
		    			en: `Title`
					},
					description: {
		    			en: `Description`
					},
					keywords: {
		    			en: `Keywords`
					},
					num_resoures: {
		    			en: `Resources`
					},
					collaborators: {
		    			en: `Collaborators`
					},
					owner: {
		    			en: `Owner`
					},
					visibility: {
		    			en: `Visibility`
					},
					viewers: {
		    			en: `Viewers`
					},
					action: {
		    			en: `Action`
					}
				}
			}
	    },
	    common: {
		    cropper: {
			    headers: {
				    current_image: {
					    en: `Current Image`
				    },
				    upload_image: {
					    en: `Upload New Image`
				    },
				    crop_image: {
					    en: `Crop your picture`
				    }
			    },
			    upload: {
				    success: {
					    title: {
						    en: `Success!`
					    },
					    body: {
						    en: `You have successfully uploaded a new image.`
					    }
				    },
				    fail: {
					    title: {
						    en: `Unable to Upload`
					    },
					    body: {
						    en: `We're sorry, but we encountered an error while trying to upload this image file.  Please try again.`
					    }
				    }
			    },
		    },
		    oho: {
			    field: {
				    tooltip: {
					    en: () => `Enter this in English, ʻŌlelo Hawaiʻi, or Bilingually.  
								Right now, you're editing the {{language}} version.`
				    }
			    }
		    },
		    pills: {
			    new: {
				    en: `New!`,
				    haw: `Hou!`
			    },
			    resources: {
					en: (count:number) => (count === 1) ? `resource` : `resources`,
					haw: `haʻawina`,
				}
		    },
		    search: {
			    placeholder: `begin typing...`,
			    exactMatch: {
					en: `Exact Text`,
				    haw: `Huli Hāiki`
			    }
		    },
            select_resource_refs: {
                label: {
                    en: `Select resource(s):`
                },
                placeholder: {
                    en: `Start typing a title...`
                }
            },
			select_collections: {
		    	label: {
		    		en: `Series or Collection(s):`
				},
				placeholder: {
		    		en: `Start typing a title...`
				}
			},
		    toasts: {
			    checkInput: {
				    title: {
					    en: "Invalid Input",

				    },
				    body: {
					    en: "Please check your input for errors and try again.",
				    }

			    }
		    },
			view_switcher: {
		    	list: {
		    		en: `list view`
				},
				grid: {
		    		en: `grid view`
				}
			},
		    yesNo: {
			    header: {
				    en: `Reviewer Comments`
			    },
			    body: {
				    en: `This resource is currently being edited. Are you sure you want to edit this?`
			    },
			    addComment: {
				    en: `Add a comment or feedback:`
			    },
			    placeholder: {
				    en: `Enter your comment or feedback here.  Don't worry; these comments will not be visible in the published resource for the general public.`
			    }
		    },
		    required: {
			    en: `Required Field`
		    },
		    card_list: {
			    loading: {
				    en: `Loading...`
			    },
			    no_items: {
				    '@type': 'mustache',
				    en: () => `No {{listType}} found`
			    }
		    },
		    links: {
			    header: {
				    en: `External Links`
			    },
			    instructions: {
				    en: `Paste a URL here:`
			    },
			    placeholder: {
				    en: `example: https://waihona.net`
			    },
			    button: {
				    en: `Add Link`
			    }


		    }
	    },
        community: {
            header: {
                en: `Legal Considerations`
            },
            guidelines: {
                header: {
                    en: `Community Guidelines`
                },
                body: {
                    en: `The Waihona is a community of everyday kānaka working to strengthen the lāhui. Our community guidelines are meant to build a mutual understanding of the kuleana that this pilina entails in plain language.`
                }
            },
            privacy: {
                header: {
                    en: `Privacy and Terms of Use`
                },
                body: {
                    en: `Kapu aloha! The Waihona is a safe space for kānaka of all backgrounds. Our End User License Agreement defines the terms of use in clear and strict legal terms for everyone who uses the platform, and our Privacy Policy spells out out how we use and safeguard your data.`
                },
                contrib_user_agreement: {
                    en: `Contributor and User Agreement`
                },
                eula: {
                    en: `End User License Agreement`
                },
                link: {
                    en: `Privacy Policy`
                }
            }
        },
        frameworks: {
            list: {
                header: {
                    en: `Available Frameworks`,
                },
                create: {
                    en: `Create a new framework`
                },
                edit: {
                    en: `edit`,
                },
                view: {
                    en: `view`
                }
            },
            detail: {
                card_title: {
                    en: `Elements:`
                },
                description: {
                    en: `Description:`
                },
                element: {
                    en: `Element:`
                },
                type: {
                    en: `Type:`
                }
            },
            edit: {
                header: {
                    en: `Framework Details Editor`,
                },
                sidebar: {
                    title: {
                        en: `Elements:`
                    },
                    buttons: {
                        add: {
                            en: `Add New Element`
                        },
                        delete: {
                            en: `Delete Selected Element`
                        },
                        moveUp: {
                            en: `Move Selected Element Up`
                        },
                        moveDown: {
                            en: `Move Selected Element Down`
                        },
                        importAndExport: {
                            en: `Import & Export`
                        }
                    }
                },
                form: {
                    header: {
                        en: `Basic Information`,
                    },
                    labels: {
                        title: {
                            en: `Framework Title:`
                        },
                        summary: {
                            en: `Brief Summary:`
                        },
                        description: {
                            en: `Full Description:`
                        },
                        element: {
                            en: `Element:`,
                        },
                        elementName: {
                            en: `Element Name:`,
                        },
                        elementDescription: {
                            en: `Element Description:`
                        }
                    },
                    placeholders: {
                        title: {
                            en: `Please enter the name of this framework, for example: Nā Hopena Aʻo`
                        },
                        summary: {
                            en: `Please enter a brief summary of this framework.`
                        },
                        description: {
                            en: `Please enter some descriptive information about this framework for those who may not be familiar with it.`
                        },
                        elementName: {
                            en: `Please enter the name of this framework element`
                        },
                        elementDescription: {
                            en: `Please enter some descriptive information about this framework element.`
                        }
                    }
                },
                meta: {
                    header: {
                        en: `Meta Information`
                    },
                    type: {
                        en: `Framework Type:`
                    },
                    placeholder: {
                        en: `Select...`
                    }
                },
                save: {
                    en: `Save Framework`
                },
                toast: {
                    success: {
                        title: {
                            en: "Success!",
                        },
                        body: {
                            en: "Your framework was successfully saved."
                        }
                    },
                    fail: {
                        title: {
                            en: "Unable to save",
                        },
                        body: {
                            en: "We were unable to save because one or more errors have not yet been corrected."
                        }
                    }
                }
            }
        },
        inbox: {
            shared: {
                to: {
                    en: `To:`
                },
                from: {
                    en: `From:`
                },
                subject: {
                    en: `Subject:`
                },
                body: {
                    en: `Message:`
                }
            },
            compose: {
                header: {
                    en: `New Message`
                },
                placeholders: {
                    to: {
                        en: `Who do you want to receive this message?`
                    },
                    subject: {
                        en: `Enter the subject of your message.`
                    },
                    body: {
                        en: `Type your message here.`
                    }
                }

            },
            listed_message: {
                click: {
                    en: `Click to read the message body.`
                }
            },
            inbox: {},
            view: {
                reply: {
                    en: `Begin typing your reply...`
                },
                button: {
                    en: `Send Reply`
                }
            },
            toasts: {
                compose: {
                    success: {
                        title: {
                            en: `Success!`,
                        },
                        body: {
                            en: `Your message is on its way!`,
                        }
                    },
                    validation_error: {
                        title: {
                            en: `Incomplete message!`,
                        },
                        body: {
                            en: `Please make sure you have specified your recipient(s), subject, and message before clicking send.`,
                        }
                    }
                }
            }
        },
        organization: {
            edit: {
                alerts: {
                    pendingVerification: {
                        '@type': 'mustache',
                        en: () => `{{userName}} has indicated an affiliation with {{orgName}} and is awaiting verification by an organizational administrator or reviewer. `,
                    },
                    verifyLink: {
                        en: `Click to verify now.`
                    },
                    pendingAcceptance: {
                        '@type': 'mustache',
                        en: () => `<b>{{userName}}</b> <i><small>(invited by {{invitedBy}})</small></i>`,
                    },
                    cancelInvite: {
                        en: `Cancel this Invite`,
                    },
                    language_mismatch_body: {
                        '@type': 'mustache',
                        en: () => `It looks like the text you entered for your organizational profile might be in {{inputLang}}, but you are currently editing in {{selectedLang}}.  If you would like to switch your editor to {{inputLang}}, please use the language button just above the logo uploader.`
                    }
                },
                buttons: {
                    save: {
                        en: `Save Changes`,
                        haw: `Mālama`
                    },
                    invite: {
                        en: `+ Invite New User`
                    },
                    language_switcher: {
                        header: {
                            en: `Editing Language`,
                        },
                    }
                },
                config: {
                    properties: {
                        en: `Properties:`
                    },
                    content: {
                        label: {
                            en: `Content Ownership Options:`
                        },
                        owner: {
                            en: `Content creator retains copyrights`
                        },
                        org: {
                            en: `Organization retains copyrights`
                        }
                    },
                    publishing: {
                        label: {
                            en: `Default Publishing Options:`
                        },
                        noa: {
                            en: `Publish to Waihona`,
                            haw: `Noa`
                        },
                        kapu: {
                            en: `Publish to Organization Only`,
                            haw: `Kapu`
                        }
                    },
                    frameworks: {
                        label: {
                            en: `Curricular Standards, Cultural Perspectives, and Assessment Frameworks Options:`
                        }
                    }
                },
                formContent: {
                    help: {
                        adminOnly: {
                            en: `(Administrative Use Only)`
                        },
                        choose: {
                            en: `Choose...`
                        }
                    },
                    labels: {
                        orgName: {
                            en: "Name of Organization:",
                            haw: "Ka Inoa o ka Hui:"
                        },
                        orgGuid: {
                            en: `Please specify the organization's ID:`
                        },
                        city: {
                            en: `City or Location:`
                        },
                        island: {
                            en: `Island:`,
                            haw: `Moku:`
                        },
                        phone: {
                            en: `Main Phone Number:`,
                            haw: `Kelepona:`
                        },
                        email: {
                            en: `Main Email Address:`,
                            haw: `Lekauila:`
                        },
                        website: {
                            en: `Website URL:`
                        },
                        poo: {
                            en: `Name of Poʻo, Principal, or Executive Director:`
                        },
                        profile: {
                            en: `About this Organization:`
                        },
                        admins: {
                            en: `Designated Organization Administrator(s):`
                        },
                        reviewers: {
                            en: `Designated Organization Reviewer(s):`,
                            haw: `Nā Kaʻi o ka Hui:`
                        },
                        contributors: {
                            en: `Designated Contributors Affiliated with this Organization:`,
                            haw: `Nā Kumu o ka Hui:`
                        },
                        members: {
                            en: `All Users Affiliated with this Organization:`,
                        },
                        logo: {
                            en: `Select and crop a new image:`
                        }
                    },
                    placeholders: {
                        orgName: {
                            en: "Please enter the name of this organization, for example: Kula Middle School",
                            haw: "E ʻoluʻolu, e hōʻike mai i ka inoa o ka hui. Penei nō: Ke Kula Waena o Kula."
                        },
                        city: {
                            en: `for example: Honolulu`,
                        },
                        phone: {
                            en: `example: (808) 234-5678 ext. 100`
                        },
                        email: {
                            en: `for example: info@school.edu`
                        },
                        web: {
                            en: `for example: http://www.school.edu`
                        },
                        poo: {
                            en: `for example: Mr. Poʻo Kula, Principal`
                        },
                        about: {
                            en: `Please enter some descriptive information about this organization.`
                        },
                        admins: {
                            en: `Select one or more Admin(s)`
                        },
                        reviewers: {
                            en: `Select one or more Reviewer(s)`
                        },
                        contributors: {
                            en: `Select one or more Contributor(s)`
                        },
                        members: {
                            en: `Select one or more Member(s)`
                        }
                    },
                    tooltips: {
                        about: {
                            en: "Please provide detailed information for users who may not be familiar with your school or organization. You may include a mission statement, history, overview, description, links, or any other information that others may find helpful as they view your organization and its contributions to the Waihona."
                        },
                        configuration: {
                            approveAll: {
                                en: "This option allows reviewers to approve or decline the entire resource as a whole, rather than approving or declining each element of the resource individually."
                            },
                            autoAdd: {
                                en: "This option will automatically designate newly-approved members of the organization as contributors, so that they may immediately begin creating submitting resources for review."
                            }
                        },
                        users: {
                            orgAdmins: {
                                en: "These people have the ability to edit the organization's profile, update configuration settings, and designate organization members as reviewers or contributors."
                            },
                            reviewers: {
                                en: "These people have the ability to review and publish or decline resource submissions on behalf of the organization, and have the responsibility to help contributors create content that meets the quality standards of the organization."
                            },
                            contributors: {
                                en: "These people have the ability to create resources to submit for review and potential publication, and to manage their own publications and submissions."
                            },
                            members: {
                                en: "These people are affiliated with an organization, and may collaborate on resources, but are not allowed to submit a resource on behalf of the organization."
                            },
                        },
                        inviteUser: {
                            en: "Click here to invite someone to join your organizational presence in the Waihona."
                        },
                        language: {
                            en: `This organization can be edited in English, ʻŌlelo Hawaiʻi, or both!  Use these buttons to select a language to work in. The active language is blue.`
                        }
                    }
                },
                headers: {
                    title: {
                        en: `Organization Details Editor`
                    },
                    authorized: {
                        en: `Authorized Users`
                    },
                    configuration: {
                        en: `Configuration Options`
                    },
                    logo: {
                        en: `Organization Logo:`
                    }
                },
	            logo: {
                	warning: {
                		en: `You must save your new organization before uploading a logo.`
	                }
	            },
                modal: {
                    addUser: {
	                    title: {
		                    en: `Invite someone to join the team!`,
	                    },
	                    form: {
		                    label_enterEmail: {
			                    en: `Enter or paste a single email address or a list of addresses in the box below.  If you want to enter a list, please indicate how your list is separated here:`
		                    },
		                    placeholder_enterEmail: {
		                    	comma: {
									en: `for example: user1@kula.edu, user2@kula.edu, User Three <user3@kula.edu>`
								},
								newline: {
									en: `for example:\nuser1@kula.edu\nuser2@kula.edu\nUser Three <user3@kula.edu>`

								},
								semicolon:{
									en:`for example: user1@kula.edu; user2@kula.edu; User Three <user3@kula.edu>`
								}
							},
	                    },
	                    buttons: {
		                    useEmail: {
			                    en: `Invite by email address instead`
		                    },
		                    sendInvite: {
			                    en: `Send Invitation(s)`
		                    }
	                    },
	                    toast: {
		                    success: {
			                    title: {
				                    en: `Processing Invitation(s)`,
			                    },
			                    body: {
				                    en: `The server is processing your invitation request(s).  Please wait for the results for more information.`,
			                    }
		                    },
		                    fail: {
		                    	invalid_input: {
		                    	    title: {
		                    	    	en: `Invalid Input`
			                        },
				                    body: {
		                    	    	en: `There were no valid email addresses in the data you provided.  Please check your data and make any necessary corrections, then try again.`
				                    }
			                    },
			                    other_error: {
				                    title: {
					                    en: `Server Error`
				                    },
				                    body: {
					                    en: `Unfortunately, a server error prevented your request from processing.  Please submit feedback about this error.`
				                    }
			                    }
		                    }
	                    }
                    }
                },
                toast: {
	                dup_invite_code: {
		                title: {
			                en: `Invitation code error`
		                },
		                body: {
			                en: `The invitation code you are trying to create already exists elsewhere in the database.  Please create a new code and try again.`
		                }
	                },
	                guid_already_exists: {
		                title: {
			                en: "Invalid entry"
		                },
		                body: {
			                en: "This ID already exists in the database.  Please create a unique ID and try again."
		                }
	                },
	                error: {
		                title: {
			                en: "Invalid entry"
		                },
		                body: {
			                en: "You have entered invalid data.  Your input must be alphanumeric without punctuation or spaces in kebab-case."
		                }
	                },
                    fail: {
                        title: {
                            en: "Unable to save",
                        },
                        body: {
                            en: "We were unable to save because one or more errors have not yet been corrected."
                        }
                    },
	                saved: {
		                title: {
			                en: "Saved",
			                haw: "Ua mālama ʻia"
		                },
		                body: {
			                en: "The organization has been saved.",
			                haw: "Ua mālama ʻia ka hui"
		                }
	                }
                }
            },
            detail: {
                headers: {
                    contributors: {
                        en: "Our Contributors:",
                    },
                    frameworks: {
                        en: "Curricular and Assessment Standards and Frameworks:",
                    },
                    resources: {
                        en: `Resources Published by Our Contributors:`
                    }
                },
                buttons: {
                    edit: {
                        en: "Edit this Organization",
                    }
                }
            },
            list: {
                title: {
                    en: "Participating Organizations",
                },
                createNew: {
                    en: "Create a new organization",
                },
                pills: {
                    contributors: {
                        en: "Contributors",
                    },
                    kanaeokana: {
                        en: "Kanaeokana Network",
                    },
                },
                switch: {
                    edit: {
                        en: "Edit",
                    },
                    view: {
                        en: "View",
                    }
                }
            },
	        org_invite_codes: {
            	header: {
            		en: `Manage Invitation Codes`
	            },
		        thead: {
            		code: {
            			en: `Code`
		            },
			        status: {
            			en: `Status`
			        },
			        remaining: {
            			en: `Number Remaining`
			        },
			        used: {
            			en: `Number Used`
			        }
		        },
		        tfoot: {
            		code: {
            			label: {
            				en: `Enter the new invite code:`
			            },
			            placeholder: {
            				en: `enter new code`
			            }
		            },
			        number: {
            			label: {
            				en: `Max users:`
			            },
				        placeholder: {
            				en: `enter number of codes`
				        }
			        }
		        },
		        custom_buttons: {
            		create: {
            			en: `Create Code`
		            },
			        create_new: {
            			en: `+ Create New Invite Code`
			        },
			        tooltip_create: {
				        en: "Click here to create a new invitation code for this organization."
			        }

		        }
	        }
        },
        registration: {
            start: {
                header: {
                    title: {
                        en: `How to Join the Waihona`
                    },
                    body: {
                        en: `There are two ways to join: you may register with your existing school or organization, or join the Hale Kākoʻo.`
                    },
                    tooltip: {}
                },
                organization: {
                    title: {
                        en: `Register with your School or Organization`
                    },
                    body: {
                        en: `This option is for members of registered schools and organizations. An invitation code is needed to register in these groups.`
                    },
                    placeholder: {
                        en: `Select your organization`
                    },
                    button: {
                        en: `Continue to Step 2`
                    },
                    tooltip: {}
                },
                kakoo: {
                    title: {
                        en: `Join the Hale Kākoʻo`
                    },
                    body1: {
                        en: `If your organization does not currently participate in the Waihona, or you did not previously receive an invitation code to register, or you aren't sure where to begin, please join the Hale Kākoʻo! In the Hale, you may self-publish your own contributions at any time, without waiting for anyone else to review your work.`
                    },
                    body2: {
                        en: `Hale Kākoʻo is our group for community contributions to the Lāhui, and all are welcome here.`
                    },
                    button: {
                        en: `Join Hale Kākoʻo`
                    },
                    tooltip: {}
                }
            },
            page2: {
                header: {
                    en: `Please enter the following information to join Hale Kākoʻo.`
                },
                org: {
                    label: {
                        en: `Name of school or organization:`
                    },
                    placeholder: {
                        en: `example: Windward Middle School`
                    },
                    small: {
                        en: `If you are completely unaffiliated with any school or organization, enter "none".`
                    }
                },
                category: {
                    label: {
                        en: `Organization category:`
                    },
                    placeholder: {
                        en: `Choose the best match`
                    },
                    small: {
                        en: `Choose the best match.`
                    }
                },
                expertise: {
                    label: {
                        en: `Area(s) of expertise:`
                    },
                    placeholder: {
                        en: `example: Math`
                    }
                },
                referral: {
                    label: {
                        en: `How did you hear about the Waihona?`
                    },
                    placeholder: {
                        en: `(optional) please let us know who to thank for sharing the Waihona with you!`
                    }
                },
                footer: {
                    en: `Mahalo no kou kākoʻo!`,
                    haw: `Mahalo no kou kākoʻo!`
                },
                button: {
                    en: `Continue`
                }
            },
            header: {
                en: `Create a new account`
            },
            notices: {
                legal: {
                    '@type': 'mustache',
                    en: () => `I have read and agree to the <a href='{{url}}' target="_blank">Waihona Contributor and User Kuleana Agreement.</a>`,
                },
                must_first_read_legal: {
                    '@type': 'mustache',
                    en: () => `You must first read the <a href='{{url}}' target="_blank">Waihona Contributor and User Kuleana Agreement</a> before accepting.`,
                },

                toolbar: {
                    open_in_new_window: {
                        '@type': 'mustache',
                        en: () => `<a href='{{url}}' target="_blank"'>Read in New Window</a>`,
                    },
                    read_percentage: {
                        '@type': 'mustache',
                        en: () => `Read {{percentage}}`,
                    },
                    scroll_instructions: {
                        en: `Scroll to read agreement.`,
                    }
                }

            },
            text: {
                instructions: {
                    en: `Please complete this form to create your new account.`
                },
                required: {
                    en: `Required`
                },
                already: {
                    en: `Already have an account?`
                },
                login: {
                    en: `Sign in instead.`
                },
            },
            button: {
                en: `Create Account`
            },
            table: {
                labels: {
                    first: {
                        en: `First (given) name:`
                    },
                    alternate: {
                        en: `Alternate (preferred) first name:`
                    },
                    last: {
                        en: `Surname (family name):`
                    },
                    full: {
                        en: `Full professional name:`,
                        haw: `Inoa piha ʻoihana`
                    },
                    code: {
                        en: `Organization invitation code:`
                    },
                    organization: {
                        en: `Select your organization:`
                    },
                    title: {
                        en: `Role in your organization:`
                    },
                    email: {
                        en: `Email address:`
                    },
                    password: {
                        en: `Create a secure password:`
                    },
                    confirm: {
                        en: `Confirm your password:`
                    }
                },
                placeholders: {
                    first: {
                        en: `example: John`
                    },
                    alternate: {
                        en: `example: Keoni`
                    },
                    last: {
                        en: `example: Smith`
                    },
                    full: {
                        en: `Please enter your full professional name for your profile page`
                    },
                    code: {
                        en: `Enter your organization's invitation code`
                    },
                    organization: {
                        en: `Select your organization`
                    },
                    title: {
                        en: `Please enter your professional title at this organization.`
                    },
                    email: {
                        en: `Please enter your school/work email address`
                    },
                    password: {
                        en: `Create a secure password`
                    },
                    confirm: {
                        en: `enter your password again`
                    }
                },
                help: {
                    alternate: {
                        en: `If you prefer a different first name, enter it here.`
                    },
                    full: {
                        en: `Please enter your full professional name, with any prefixes and suffixes, as you want to see it displayed. This will be shown on your contributor profile page.`
                    },
                    code: {
                        en: `Check with your organization if you do not have an invitation code.`
                    },
                    organization: {
                        en: `Currently, this site is limited to users from these organizations only.`
                    },
                    org_locked: {
                        en: `You have been automatically added to this organization.`
                    },
                    email: {
                        en: `Your email address will also be your username.`
                    },
                    password: {
                        en: `Minimum 8 characters with at least 1 uppercase, 1 lowercase, and 1 number.`  //this is no longer used (dont use it)
                    },
                    confirm: {
                        en: `Your passwords must match.`
                    }
                }
            },
            toast: {
                submit: {
                    error: {
                        title: {
                            en: "Unable to register",
                        },
                        body: {
                            en: "Please confirm all fields are filled correctly and try again."
                        }
                    },
                    success: {
                        title: {
                            en: "Hulō!",
                            haw: "Hulō!"
                        },
                        body: {
                            en: "Your new user profile has been successfully created. Welcome!"
                        }
                    },
                    password: {
                        title: {
                            en: "Auwē! Your passwords don't match!"
                        },
                        body: {
                            en: "Please try again.  Your passwords must match before you may continue."
                        }
                    },
                    serverErrors: {
                        VALIDATION: {
                            title: {
                                en: "Unable to register",
                            },
                            body: {
                                en: "Please confirm all fields are filled correctly and try again."
                            }
                        },
                        INVALID_INVITATION_CODE: {
                            title: {
                                en: "Invalid invitation code"
                            },
                            body: {
                                en: "E kala mai, but the invitation code you provided is not valid for your organization. Please check your code again, or contact your organizational admin if you need a new code."
                            }
                        },
                        MISSING_INVITATION_CODE: {
                            title: {
                                en: "Missing invitation code"
                            },
                            body: {
                                en: "Please provide an invitation code to register with your organization."
                            }
                        },
                        INVALID_EMAIL: {
                            title: {
                                en: "Invalid email format"
                            },
                            body: {
                                en: "Please check that email is in the correct format."
                            }
                        },
                        DUPLICATE_EMAIL: {
                            title: {
                                en: "Email already exists"
                            },
                            body: {
                                en: "E kala mai, that email address is already in use! Did you mean to log in instead?"
                            }
                        },
                        DUPLICATE_NAME: {
                            title: {
                                en: "Name already exists"
                            },
                            body: {
                                en: "An existing user has the same name. Do you still want to register with this name?"
                            }
                        },
                        EMAIL_INVITE_NOT_FOUND: {
                            title: {
                                en: "Error with Invite"
                            },
                            body: {
                                en: "Sorry! There is no matching invite in the system. Perhaps this invite has already been used?"
                            }
                        },
                    },
                    loggedInCantRegister: {
                        title: {
                            en: "Unable to register",
                        },
                        body: {
                            en: "You are already logged in. Please log out to register a new user."
                        }
                    },
                }
            }
        },
        resource: {
			view: {
				share_clipboard_copy: {
					toast:{
						acknowledgement:{
							title:{
								en:`Copied!`,
							},
							body: {
								en: "Successfully copied the Resource url to your clipboard.",
							}
						},
						acknowledgement_not_public:{
							title:{
								en:`Copied!`,
							},
							body: {
								en: "Successfully copied the Resource url to your clipboard.  \n\nThis resource is not public so it can only be shared with other waihona members.",
							}
						}
					}
				}
			},
            review_aside: {
                settings: {en: "Settings"},
                version: {en: "Version"},
                auto_publish_label: {en: "Auto-publish on Approval"},
                auto_publish_description: {en: "Whether upon approval your lesson is auto-published live."},
                allow_feedback_label: {en: "Allow Feedback"},
                allow_feedback_description: {en: "If users are able to send you private feedback on your resource."},
                allow_public_comments_label: {en: "Allow Public Comments"},
                allow_public_comments_description: {en: "If users are able to leave public feedback on your resource's page."},
                changing_settings_no_effect: {
                    en: "*The above option toggles currently have no effect. Changing their settings at this current moment does nothing (but will in the future)."
                },
                on: {en: "On"},
                off: {en: "Off"},
            },
            review_chat: {
                prompt: {
                    en: "Type your message here"
                },
                placeholder: {
                    en: "Say something..."
                },
                send: {
                    en: "Send"
                },
                none: {
                    en: "There are no chat messages. Send something."
                }
            },
            lesson_asset: {
                appends: {
                    fan: {
                        en: `(Fan Contribution!)`
                    },
                    overwritten: {
                        en: `(Being Overwritten)`
                    }
                },
                conflicts: {
                    fan: {
                        en: `You already have a file with the same name as this fan contribution. What do you want to do?`
                    },
                    filename: {
                        en: `Conflicting Filename:`
                    },
                    new_filename: {
                        en: `New Filename:`
                    },
                },
                confirm: {
                    ask_overwrite: {
                        en: `Are you sure you want to overwrite your original file?`
                    },
                    ask_delete: {
                        en: `Are you sure you want to delete this file?`
                    },
                    overwrite: {
                        en: `Yes, overwrite it.`
                    },
                    delete: {
                        en: `Yes, delete it.`
                    }
                },
                popover: {
                    needs_info: {
                        en: `Needs Information`
                    },
                    update: {
                        en: `View and Update File Details`
                    },
                    summary: {
                        en: `File Summary:`
                    },
                    placeholder: {
                        en: `Please provide a brief summary of the contents of this file.`
                    },
                    types: {
                        en: `What kind of asset is this?`
                    }
                }
            },
            lesson_assets: {
                header: {
                    en: `Description`
                },
                none: {
                    en: `There are no learning assets.`
                },
                some: {
                    en: `Mahalo for uploading some assets. Please click on the "update information" link to describe and categorize the file.`
                },
                upload: {
                    en: `Upload some!`
                },
                not_uploaded: {
                    en: `Your selected files have not yet been uploaded.`,
                },
                upload_now: {
                    en: `Upload them now!`
                },
                toasts: {
                    naming_issue_warning: {
                        title: {
                            en: "Upload Name Issue",
                        },
                        body: {
                            en: `Warning! A file you attempted to upload shares the same file name as an existing file in this resource. For each file in this state, please choose to overwrite or rename.`,
                        }
                    },
                    naming_issue_error: {
                        title: {
                            en: "Upload Name Issue",
                        },
                        body: {
                            en: "Error! You cannot proceed because a file you attempted to upload shares the same file name as an existing file in this resource. For each file in this error state, please choose to overwrite or rename.",
                        }
                    },
	                fileUpload: {
		                success: {
			                title: {
				                en: "Upload File",
				                haw: `Hoʻouka i ka Faila`
			                },
			                body: {
				                en: `Successfully uploaded!`,
				                haw: `Ua kō!`
			                }
		                },
	                },
	                delete: {
		                success: {
			                title: {
				                en: `Delete File`,
				                haw: `Kāpae i ka Faila`
			                },
			                body: {
				                en: `Successfully deleted!`,
				                haw: `Ua kō!`
			                }
		                },
		                failure: {
			                title: {
				                en: `Delete File`,
				                haw: `Pilikia me ka hoʻokāpae`
			                },
			                body: {
				                en: `Failed deleting this file. Perhaps it did not exist?`
			                }
		                }
	                },
                    cancel: {
                        success: {
                            title: {
                                en: `Cancel File Upload`,
                                haw: `Kāpae i ka Faila`
                            },
                            body: {
                                en: `Successfully canceled!`,
	                            haw: `Ua kō!`
                            }
                        },
                        failure: {
                            title: {
                                en: `Delete File`,
                                haw: `Pilikia me ka hoʻokāpae`
                            },
                            body: {
                                en: `Failed deleting this file. Perhaps it did not exist?`
                            }
                        }
                    },
                    upload_folder: {
                        title: {
                            en: "You selected a folder!",
                        },
                        body: {
                            en: `Sorry! You cannot upload folders. Try zipping up the folder first and adding the .zip file instead.`,
                        }
                    }
                }
            },
            lesson_asset_upload: {
                filename_conflict: {
                    en: `Error! A file already exists with the same name. What do you want to do?`
                },
                new_name: {
                    en: `Please enter the new name for the file:`
                },
                new_file: {
                    en: `(New File)`
                },
                ready: {
                    en: `(Ready to Upload)`
                },
                confirm_overwrite: {
                    en: `Are you sure you want to overwrite the file?`
                },
                confirm_discard: {
                    en: `Are you sure you want to discard this file not yet uploaded?`
                },
                custom_buttons: {
                    keep: {
                        en: `Keep existing metadata`
                    },
                    overwrite: {
                        en: `Yes, overwrite it.`
                    },
                    delete: {
                        en: `Yes, delete it.`
                    }
                }
            },
            file_upload: {
                drag: {
                    en: `Drag and drop your file(s) here`
                },
                or: {
                    en: `or`,
                },
                choose: {
                    en: `Choose file(s)…`
                },
                results: {
                    en: `Results!`
                },
                download: {
                    en: `Download Me!`
                }
            },
            lesson_content: {
                labels: {
                    contributor: {
                        en: "Contributed by:"
                    },
                    org: {
                        en: "Organization:"
                    },
                    collaborators: {
                        en: "Collaborator(s):"
                    },
                    reviewer: {
                        en: "Reviewed by:"
                    },
                    self_published: {
                        en: "Self-published"
                    },
					public_resource: {
						en: "Public Resource"
					},
					members_only_resource: {
						en: "Members-Only Resource"
					},

					tooltip_public_resource: {
						en: "This resource is a public resource.  It is unlocked for all users."
					},
					tooltip_members_only_resource: {
						en: "This is a Members-Only Resource.  Only Waihona members can access it."
					},
                    tooltip_self_published: {
                        en: `This resource was self-published by the contributor, and was not vetted or reviewed by an editor or kaʻi with this organization.`
                    },
                    summary: {
                        en: "Summary:"
                    },
                    content: {
                        en: "Full Text:"
                    },
                    sources: {
                        en: "Sources, Attributions, and Usage Notes:"
                    },
                    modifications: {
                        en: "Suggested Modifications:"
                    },
                    assets: {
                        en: "Learning Assets:"
                    },
                },
                table: {
                    filename: {
                        en: "Filename"
                    },
                    summary: {
                        en: "Summary"
                    },
                    tags: {
                        en: "Tag(s)"
                    },
                    filetype: {
                        en: "File Type"
                    }
                },
                download_zip: {
                    en: "Download Zip"
                }
            },
            meta: {
                grades: {
                    en: `Grade Level(s):`,
                },
                subjects: {
                    en: `Subject(s):`
                },
                flags: {
                    en: `Flags:`
                },
                modalities: {
                    en: `Learning Modalities:`
                },
                perspectives: {
                    en: `Cultural Perspective(s):`
                },
                aina: {
                    en: `Related ʻAina:`
                },
                tags: {
                    en: `Related Tag(s):`
                },
	            frameworks: {
                	en: `Selected Framework(s)`
	            }
            },
            list_published: {
				toasts: {
					resource_unlocked: {
						title: {
							en: "Resource now available to the public",
						},
						body: {
							en: "This resource has been unlocked.  The general public can now access.",
						}

					},
					resource_locked: {
						title: {
							en: "Resource restricted to teachers",
						},
						body:{
							en:"This resource has been locked.  Only waihona members will be able to access.",
						}
					}
				},
                title: {
                    en: "Browse Learning Resources",
                    haw: "E Nānā i Nā Haʻawina"
                },
                new: {
                    en: "Contribute a Resource",
                    haw: "E Hoʻokaʻa i Mea"
                },
                assets: {
                    en: "asset(s)",
                    haw: "kumuwaiwai aʻo"
                }
            },
            list_contributions: {
                header: {
                    en: `My Contributions`
                },
                alerts: {
                    no_drafts: {
                        en: `You don't have any contributions yet.`
                    },
                    create_one: {
                        en: `Create one!`
                    },
                    fan_contributions: {
                        en: `Hulō! You have fan contributions for the following submissions:`
                    }
                },
                links: {
                    create_new: {
                        en: `+ Create a new resource`
                    },
                },
                table: {
                    section_headers: {
                        collaborations: {
                            en: `Collaborations:`
                        },
                        corrections: {
                            en: `Corrections Needed:`
                        },
                        drafts: {
                            en: `Drafts:`
                        },
                        pending_review: {
                            en: `Submissions Pending Review:`
                        },
                        in_review: {
                            en: `Submissions In Review:`
                        },
                        review_continuation: {
                            en: `Resubmissions In Continuation of Review:`
                        },
                        published: {
                            en: `Published Submissions:`
                        }
                    },
                    table_headers: {
                        edit_view: {
                            en: `Edit | View`
                        },
                        view_recall: {
                            en: `View | Recall`
                        },
                        view: {
                            en: `View`
                        },
                        title: {
                            en: `Resource Title`
                        },
                        collaborators: {
                            en: `Collaborator(s)`
                        },
                        reviewer: {
                            en: `Reviewer`,
                            haw: `Kaʻi`
                        },
                        reviewers: {
                            en: `Reviewer(s)`,
                            haw: `(Nā) Kaʻi`
                        },
                        version: {
                            en: `Version`
                        },
                        review_date: {
                            en: `Review Date`
                        },
                        save_date: {
                            en: `Date Saved`
                        },
                        submit_date: {
                            en: `Submission Date`
                        },
                        submitter: {
                            en: `Resource Submitter`
                        },
                        start_date: {
                            en: `Date Review Started`
                        },
                        publish_date: {
                            en: `Date Published`
                        },
                        updated_date: {
                            en: `Date Updated`
                        }
                    },
                    table_data: {
                        no_reviewers: {
                            en: `No reviewers assigned`
                        }
                    }
                },
                toast: {
                    recall: {
                        title: {
                            en: "Recalled",
                        },
                        body: {
                            en: "You have recalled this resource from the review queue and returned it to a draft state.  Be sure to save and re-submit after making your changes.",
                        }
                    }
                }
            },
            edit: {
                alerts: {
                    first_time: {
                        en: `A resource can be created in either English, Hawaiian, or both. Just click or tap on the appropriate language button below to select a language and begin creating. You may click the button at any time to switch languages, but be sure to save your progress as you go!`
                    },
                    has_fan_contributions: {
                        en: `Hulō! You have contributions for this resource to review! Scroll down to see them.`
                    },
                    language_mismatch: {
                        body: {
                            '@type': 'mustache',
                            en: () => `It looks like the text you entered here might be in {{inputLang}}, but you are currently editing in {{selectedLang}}.  If you would like to switch your editor to {{inputLang}}, please use the language button at the top right. You may also ignore this message and continue.`
                        }
                    },
                    direct_publish: {
                        en: `This resource will be published as soon as you click the "Publish Now" button, and will not be reviewed by an editor or kaʻi.  Please check your own work and ensure it is ready before you publish.`
                    },
                    edit_as_collaborator: {
                        en: `As a resource collaborator, you cannot submit or publish this resource. Please be sure to save your changes, and then let the content owner know when you are finished editing this resource.`
                    }
                },
                button_tooltips: {
                    preview: {
                        en: `Please save before previewing your resource.`
                    },
                    submit: {
                        en: `Please save before submitting your resource.`
                    },
                    publish: {
                        en: `Please save before publishing your resource.`
                    },
                },
                language: {
                    languageToggleLabel: {
                        en: 'Language: ',
                        haw: 'ʻŌlelo: '
                    },
                    confirm: {
                        haw_title: {
                            en: 'Do you want to create a Hawaiian version?',
                            haw: 'Makemake ʻoe e hana i mana ʻōlelo Hawaiʻi?'
                        },
                        en_title: {
                            en: 'Do you want to create an English version?',
                            haw: 'Makemake ʻoe e hana i mana ʻōlelo Pelekania?'
                        },
                        text: {
                            en: () => `Do you wish to create an {{language}} version of this resource?`
                        },
                        yes: {
                            en: 'Yes',
                            haw: 'ʻAe'
                        },
                        no: {
                            en: 'No',
                            haw: "ʻAʻole"
                        }
                    },
                    tooltip: {
                        en: `This resource can be created in English, ʻŌlelo Hawaiʻi, or both!  Use these buttons to select a language to work in. The active language is blue.`
                    },
                    switcher: {
                        en: `Resource Language`,
                    }
                },
                header: {
                    en: `Contribute a Resource`
                },
                required: {
                    en: `Required Field`
                },
                basic_info: {
                    title: {
                        en: `Basic Information`
                    },
                    organization: {
                        label: {
                            en: `Submitting Organization`
                        },
                        selected: {
                            '@type': 'mustache',
                            en: () => `You are submitting this resource on behalf of {{orgName}}.`
                        },
                        change_org_label: {
                            en: `If you would like, you may select a different organization:`
                        },
                        placeholder: {
                            en: `Select an organization`
                        },
                        help: {
                            en: `Please note that your submission will be reviewed and/or published according to the guidelines of the organization that you select. You cannot change your selection after you submit your resource for review, so please double-check your selection.`
                        }
                    },
                    resource: {
                        label: {
                            en: `Resource Title:`
                        },
                        placeholder: {
                            en: `Give your resource a succinct, descriptive title.`
                        }
                    },
                    collaborators: {
                        label: {
                            en: `Content Collaborators:`
                        },
                        tooltip: {
                            en: "Collaborators must be existing Waihona users, and will be able to edit this resource, but will not be able to submit it for review.  If you'd like to mention someone who has contributed to your resource but is not currently a Waihona user or does not need to edit your resource directly, you may also choose to mention them in the Source Notes box below."
                        },
                        placeholder: {
                            en: `Select users to edit this submission.`
                        },
                        help: {
                            en: `Tip: start typing to quickly select a name.`
                        }
                    }
                },
                summary: {
                    header: {
                        en: `Resource Summary`
                    },
                    label: {
                        en: `Brief Summary:`
                    },
                    tooltip: {
                        en: `This summary will be displayed with the resource title on the resource list and search pages, as well as at the top of the page when you view the resource itself.`
                    },
                    placeholder: {
                        en: `Please enter a very brief summary that describes this resource.`
                    }
                },
                content: {
                    header: {
                        en: `Full Text`
                    },
                    label: {
                        en: `Full Text of Resource Description, Information, and Content:`
                    },
                    placeholder: {
                        en: `Type or paste information about your resource here. You might include a sample lesson plan, language takeaways, standards alignment information, teaching tips, guiding questions, related material links, bullet points, etc.`
                    },
                    help: {
                        en: `Tip: To avoid extra white space in the display, do not enter blank lines here.`
                    }
                },
                source: {
                    header: {
                        en: `Source Notes (Optional)`
                    },
                    label: {
                        en: `Sources, Attributions, and Usage Notes:`
                    },
                    placeholder: {
                        en: `If you would like to add your source information or attributions, reference links, usage modification notes or restrictions, or other comments for teachers and other users, please enter them here.  These notes will be published with your resource.`
                    },
                    help: {
                        en: `Tip: To avoid extra white space in the display, do not enter blank lines here.`
                    }
                },
                modifications: {
                    header: {
                        en: `Modification Notes (Optional)`
                    },
                    label: {
                        en: `Suggestions for Modification:`
                    },
                    placeholder: {
                        en: `If you would like to add any suggestions for grade level modifications or other comments for teachers and other users, please enter them here.  These notes will be published with your resource.`
                    },
                    help: {
                        en: `Tip: To avoid extra white space in the display, do not enter blank lines here.`
                    }
                },
                fan: {
                    header: {
                        en: `Review Contributions from your Fans`
                    },
                    text: {
                        en: `Review these submissions from your fans for possible inclusion with this resource.`
                    },
                    card_header: {
                        en: `Contribution from`
                    },
                    card_text: {
                        en: `Message from your fan:`
                    },
                    table: {
                        header: {
                            en: `Suggested assets:`
                        },
                        filename: {
                            en: `Filename`
                        },
                        summary: {
                            en: `Summary`
                        },
                        tags: {
                            en: `Tag(s)`
                        }
                    }
                },
				frameworks: {
                	header: {
                		en: `Frameworks, Assessments, Standards, or Outcomes (Optional)`
					}
				},
                cover: {
                    header: {
                        en: `Resource Cover Image:`
                    },
                    tooltip: {
                        en: "The image that you upload here will be shown wherever this resource is published. If you do not upload an image, a generic image will be displayed that represents the first subject category that you select for this submission."
                    },
                    warning: {
                        en: `Please save your progress before adding a cover image to this resource.`
                    },

                },
                meta: {
                    header: {
                        en: `Meta Information`
                    },
                    labels: {
                        grade: {
                            en: `Grade Level:`
                        },
                        subject: {
                            en: `Subject Categories:`
                        },
                        culture: {
                            en: `Cultural Perspectives:`
                        },
                        mode: {
                            en: `Learning Modalities:`
                        },
                        aina: {
                            en: `ʻĀina (Mokupuni and Moku):`
                        },
                        frameworks: {
                            en: `Curriculum Standards and Frameworks:`
                        },
                        series: {
                            en: `Series or Collection:`
                        },
                        tags: {
                            en: `Tags:`
                        }
                    },
                    placeholders: {
                        grade: {
                            en: `Select the appropriate grade level for this resource.`
                        },
                        subject: {
                            en: `Choose or suggest resource tags.`
                        },
                        culture: {
                            en: `Choose or suggest cultural perspectives.`
                        },
                        mode: {
                            en: `Select the applicable learning modalities.`
                        },
                        aina: {
                            en: `Select the applicable moku for this resource.`
                        },
                        frameworks: {
                            en: `Choose applicable standards or frameworks.`
                        },
                        series: {
                            en: `Select a series or collection...`
                        },
                        tags: {
                            en: `Add some tags that describe this content.`
                        }
                    },
                    tooltips: {
                        grade: {
                            en: "Please select all grades for which your submission is applicable, so that this resource may be found in a grade-specific filter."
                        },
                        subject: {
                            en: "Please choose a western subject category or categories that most closely encompasses your submission, so that this resource may be found in a subject-specific filter."
                        },
                        culture: {
                            en: "If your submission may be described by one or more of the below perspectives, please select it. Please note that ʻŌlelo describes language-learning or teaching resources"
                        },
                        mode: {
                            en: "Which learning modalities does this resource engage? Please select all that are applicable."
                        },
                        aina: {
                            en: "If your submission is relative to a specific island and district, please select the appropriate moku and indicate in the usage notes how this resource is limited or may be modified."
                        },
                        frameworks: {
                            en: ``
                        },
                        series: {
                            en: `If this resource is part of a series or published collection, please select it from the list below.  You can also add this to a collection later, or remove it from a collection after it has been added by editing the collection.`
                        },
                        tags: {
                            en: ``
                        }
                    }
                },
                config: {
                    header: {
                        en: `Configuration Options for this Resource`
                    },
                    switches: {
                        fans: {
                            en: `Allow fans to send me content to add to this resource`
                        },
                        messages: {
                            en: `Allow people to message me privately about this resource`
                        },
                        comments: {
                            en: `Allow public comments on this resource`
                        },
                        public_access: {
                            en: `Allow public access to this resource`
                        },
	                    ghost: {
                        	en: `Hide my name as the resource contributor`
	                    }
                    },
                    tooltips: {
                        fans: {
                            en: "This feature allows other website users to submit additional materials that they may have which align to your resource for your consideration and potential inclusion. You will be able to review and decide whether or not to include any submissions from other users into your resource."
                        },
                        messages: {
                            en: ``
                        },
                        comments: {
                            en: ``
                        },
                        public_access: {
                            en: `If switched on, this resource will be accessible to the public, meaning anyone can view the resource, not just users with Waihona accounts.`
                        },
	                    ghost: {
                        	en: `If switched on, this resource will be submitted on behalf of the organization, but your name will be hidden as the contributor for the resource.`
	                    }
                    }
                },
                assets: {
                    header: {
                        en: `Learning Assets`
                    },
                    tooltip: {
                        en: "A learning asset can be any type of file that enhances this resource, such as a handout, video, audio clip, image, PowerPoint slide deck, lesson plan, student sample, etc.  You may upload any number of assets, but make sure each file that you upload has a unique file name."
                    },
                    warning: {
                        en: `Please save your progress before uploading some learning assets.`
                    }
                },
                comments: {
                    header: {
                        en: `Comments for your Reviewer (Optional)`
                    },
                    label: {
                        en: `If you have any private comments for your reviewer, you may enter them here:`
                    },
                    placeholder: {
                        en: `These notes will not be published with your resource.`
                    },
                    reviewer: {
                        en: `Reviewer's comments:`
                    }
                },
                related: {
                    header: {
                        en: `Related Content`
                    },
                    tooltip: {
                        en: `You may optionally choose to link other resources which have already been published as related to or subcomponents of this resource.  Related resources will be displayed at the bottom of the page as mini-cards.`
                    },
                },
                review_tooltips: {
                    approved: {
                        en: `Your reviewer approved this section.`
                    },
                    declined: {
                        en: `Your reviewer did not approve this section.`
                    }
                },
                version: {
                    label: {
                        en: `Submission Version`
                    },
                    tooltip: {
                        en: `The version number is incremented each time you save, submit, or publish a resource.`
                    }
                },
                toasts: {
                    save: {
                        success: {
                            title: {
                                en: "Your progress has been saved",
                            },
                            body: {
                                en: `Hulō! There are no errors or missing data here. Please click on "submit" when you are finished making changes and are ready to submit this resource for review.`,
                            }
                        },
                        warning: {
                            title: {
                                en: "Your progress has been saved",
                            },
                            body: {
                                en: "Mahalo for saving your work in progress!  This resource is not yet ready to submit. Please continue your work, and ensure that all of the highlighted areas are complete and correct before submitting.",
                            }
                        },
	                    fail: {
		                    title: {
			                    en: "Auwē! Save Unsuccessful",
		                    },
		                    body: {
			                    en: "We were unable to save because one or more errors have not yet been corrected.",
		                    }
	                    }
                    },
                    save_to_publish: {
                        success: {
                            title: {
                                en: "Your progress has been saved",
                            },
                            body: {
                                en: `Hulō! There are no errors or missing data here. Since this resource will not be reviewed by an editor or kaʻi, please proofread your work before you publish.`,
                            }
                        },
                        warning: {
                            title: {
                                en: "Your progress has been saved",
                            },
                            body: {
                                en: "Mahalo for saving your work in progress!  This resource is not yet ready to publish. Please continue your work, and ensure that all of the highlighted areas are complete and correct before publishing.",
                            }
                        }
                    },
                    submit: {
                        success: {
                            title: {
                                en: "Hulō! Successful Submission",
                            },
                            body: {
                                en: "Mahalo for your submission! A reviewer will be notified to review this resource prior to publication.",
                            }
                        },
                        fail: {
                            title: {
                                en: "Auē! Unsuccessful Submission",
                            },
                            body: {
                                en: "We were unable to submit because one or more errors have not yet been corrected.",
                            }
                        }
                    },
                    fanContribution: {
                        accept: {
                            title: {
                                en: "Contribution Accepted",
                            },
                            body: {
                                en: "This fan's contribution has been added to your list of learning assets. Please review these files and rename or update the file information tags as appropriate.",
                            }
                        },
                        decline: {
                            title: {
                                en: "Contribution Declined",
                            },
                            body: {
                                en: "We've removed this contribution from your list and notified the contributor. Mahalo!",
                            }
                        },
                    },
	                download: {
		                problem: {
			                title: {
				                en: "Download problem",
			                },
			                body: {
				                en: "An issue with the file was detected! If this resource was just published then it may not yet be available.",
			                }
		                }
	                },
                    direct_publish: {
                        success: {
                            title: {
                                en: `Publication Success!`
                            },
                            body: {
                                en: `Your resource has been published and may now be viewed under "Resources." You may revise this resource at any time in the future.`
                            }
                        },
                        fail: {
                            title: {
                                en: "Auwē! Publish was Unsuccessful!",
                            },
                            body: {
                                en: "We were unable to publish your resource because one or more errors have not yet been corrected. Please check your work and try again.",
                            }
                        }

                    },
	                add_link: {
                    	fail: {
                    		title: {
                    			en: `Invalid URL Format`
		                    },
		                    body: {
                    			en: `Please check the URL you are trying to add and try again. Include the http:// prefix as part of the URL.`
		                    }
	                    }
	                }
                },
            },
            preview: {
                buttons: {
                    edit: {
                        en: `Continue Editing`
                    }
                }
            },
            detail: {
				switch: {
					make_public: {
						en: "Enable Public Access (General Public)"
					},
					make_members_only: {
						en: "Disable Public Access (Teachers Only)"
					}
				},
                buttons: {
                	question: {
                		en: `Ask a Question`
	                },
                    download: {
                        en: `Download this Resource`,
	                    haw: `Hoʻoili i kēia Haʻawina`
                    },
	                add_to_collection: {
		                en: `Add to Collection`,
		                haw: `Pākuʻi i Pūʻulu Haʻawina`
	                },
                    edit: {
                        en: `Edit this Resource`
                    },
                    contribute: {
                        en: `Contribute to this Resource`
                    },
                    favorite: {
                        en: `Favorite this Resource`
                    },
					unlock: {
						en: `Unlock this Resource (General Public)`,
					},
					lock: {
						en: `Lock this Resource (Only Teachers)`,
					},
                    collect: {
                        en: `Add to My Collections`
                    },
                    share: {
                        en: `Share this Resource`
                    },
                    unpublish: {
                        en: `Unpublish this Resource`
                    }
                },
                related: {
                    header: {
                        en: `Related Content:`
                    }
                },
                version: {
                    en: `Publication version`
                },
                publish_status: {
                    not_yet_published: {
                        en: "The resource you just published is loading..."
                    }
                }
            },
            common_toasts: {
                download: {
                    problem: {
                        title: {
                            en: "Download problem",
                        },
                        body: {
                            en: "An issue with the file was detected! If this resource was just published then it may not yet be available.",
                        }
                    }
                },
	            file_open: {
		            problem: {
			            title: {
				            en: "Problem Opening File",
			            },
			            body: {
				            en: "An issue with the file was detected! If this resource was just published then it may not yet be available.",
			            }
		            }
	            },
                unpublish: {
                    title: {
                        en: "Unpublished Resource"
                    },
                    body: {
                        en: "This resource has been unpublished.",
                    }
                }
            },

        },
        reviews: {
            list: {
                header: {
                    en: `Review and Approve Resource Submissions`
                },
                section_headers: {
                    pending: {
                        en: `Initial Submissions Awaiting Review:`
                    },
                    in_progress: {
                        en: `My Reviews In Progress:`
                    },
                    review_continuation: {
                        en: `Modified Submissions Awaiting Re-Review:`
                    },
                    returned: {
                        en: `Returned to Submitter for Revisions:`
                    },
                    approved: {
                        en: `My Approved Reviews:`
                    },
                },
                table_headers: {
                    title: {
                        en: `Title`
                    },
                    submitter: {
                        en: `Submitter`
                    },
                    subStatus: {
                        en: `Submitter Status`
                    },
                    reviewers: {
                        en: `Reviewer(s)`
                    },
                    version: {
                        en: `Version`
                    },
                    dateSubmitted: {
                        en: `Date Submitted`
                    },
                    dateModified: {
                        en: `Date Modified`
                    },
                    dateReturned: {
                        en: `Date Returned`
                    },
                    dateApproved: {
                        en: `Date Approved`
                    },
                },
                messages: {
                    verified: {
                        en: `Verified`
                    },
                    notVerified: {
                        en: `Not Verified`
                    },
                    verify: {
                        en: `Verify`
                    },
                    new: {
                        en: `New!`
                    },
                    revision: {
                        en: `Revision`
                    },
                    published: {
                        en: `Published`
                    }
                }
            },
            status: {
                header: {
                    en: `Current Publication Status:`
                },
                draft: {
                    in_progress: {
                        en: `Draft in progress`
                    },
                    submitted: {
                        en: `Draft submitted`
                    },
                },
                review: {
                    pending: {
                        en: `Pending review`
                    },
                    started: {
                        en: `Review started`
                    },
                    active: {
                        en: `In review`
                    },
                    success: {
                        en: `Review successful`
                    },
                    fail: {
                        en: `Review failed`
                    },
                },
                revision: {
                    await: {
                        en: `Awaiting revision`
                    },
                    submitted: {
                        en: `Revision submitted`
                    },
                    pendingReview: {
                        en: `Review revision`
                    },
                    activeReview: {
                        en: `Reviewing revision`
                    },
                    success: {
                        en: `Revision accepted`
                    },
                    fail: {
                        en: `Revision rejected`
                    },
                },
                publish: {
                    future: {
                        en: `Publish`
                    },
                    past: {
                        en: `Published`
                    }
                }
            },
            field: {},
            review: {
                alerts: {
                    unverified: {
                        en: `Stop! This resource was submitted by an unverified user.`
                    },
                    verify: {
                        '@type': 'mustache',
                        en: () => `Is {{fullName}} affiliated with {{orgName}}?`
                    },
                    begin: {
                        en: `Do you want to begin reviewing this resource now?`
                    },
                    active: {
                        en: `This resource is currently under review.`
                    },
                    complete: {
                        en: `This review is already complete.`
                    },
                },
                instructions: {
                    header: {
                        en: `Resource Review Instructions:`
                    },
                    body: {
                        en: `Click on the prompts in each of the areas to the right. Click once to approve, again to decline, and a third time to reset.  You can also add a comment to any section by clicking on the comment icon.`
                    },
                    feedback: {
                        en: `Add overall feedback:`
                    },
                    placeholder: {
                        en: `Enter your feedback for the submitter here.  Don't worry; these comments will not be visible in the published resource for the general public.`
                    },
                    approve: {
                        en: `Do you approve this resource for publication?`
                    },
                },
                buttons: {
                    show: {
                        en: `Show Review`
                    },
                    return: {
                        en: `Return to List`
                    },
                    save: {
                        en: `Save progress for later`
                    }
                },
                resource: {
                    submitter: {
                        en: `Submitted by:`
                    },
                    collaborators: {
                        en: `Collaborator(s):`
                    },
                    organization: {
                        en: `Organization:`
                    },
                    meta: {
                        en: `Meta Tags:`
                    },
                    summary: {
                        en: `Summary:`
                    },
                    full: {
                        en: `Full Text:`
                    },
                    sources: {
                        en: `Sources, Attributions, and Usage Notes:`
                    },
                    modifications: {
                        en: `Suggested Modifications:`
                    },
                    cover: {
                        en: `Resource Cover Image:`
                    },
                    assets: {
                        header: {
                            en: `Learning Assets:`
                        },
                        filename: {
                            en: `Filename`
                        },
                        fileSummary: {
                            en: `Summary`
                        },
                        fileTags: {
                            en: `Tag(s)`
                        },
                        fileType: {
                            en: `File Type`
                        }
                    }
                }
            },
            toast: {}
        },
        tooltips: {
            edit: {
                en: `edit`
            },
            view: {
                en: `view`
            },
            recall: {
                en: `recall`
            }
        },
        user: {
            default_organization: {
                former_member: {
                    en: `former member`
                }
            },
            profile: {
                title: {
                    en: `My Profile Editor`,
                },
                form: {
                    labels: {
                        firstName: {
                            en: `First (given) name:`,
                        },
                        preferredName: {
                            en: `Preferred first name:`,
                        },
                        lastName: {
                            en: `Surname (family name):`,
                        },
                        fullName: {
                            en: `Full professional name:`,
                        },
                        bio: {
                            en: `Biography:`,
                        }
                    },
                    placeholders: {
                        firstName: {
                            en: `Please enter your first (given) name.`,
                        },
                        preferredName: {
                            en: `optional`,
                        },
                        lastName: {
                            en: `Please enter your last name (surname or family name).`,
                        },
                        fullName: {
                            en: `Please enter your full professional name for your profile page.`,
                        },
                        bio: {
                            en: `Please type or paste a full biography or C.V. to help others get to know you better.`,
                        }
                    },
                    help: {
                        preferredName: {
                            en: `This will be used instead of your first name.`,
                        },
                        fullName: {
                            en: `Please enter your full professional name, with any prefixes and suffixes, as you want to see it displayed. This will be shown on your contributor profile page.`
                        },
                        bio: {
                            en: `Tip: To avoid extra white space in the display, do not enter blank lines here.`,
                        }
                    }
                },
                image: {
                    header: {
                        en: `My Profile Image:`,
                    }
                },
                organizations: {
                    header: {
                        en: `My Organizations:`,
                    }
                },
                buttons: {
                    saveProfile: {
                        en: `Save Profile`,
                    },
                    resetChanges: {
                        en: `Reset Changes`,
                    },
                },
                toast: {
                    success: {
                        title: {
                            en: `Your profile has been saved`,
                        },
                        body: {
                            en: `Mahalo for updating your profile!`,
                        }
                    },
                    failure: {
                        title: {
                            en: `Uh-oh`,
                        },
                        body: {
                            en: `We were unable to save the changes to your profile.  Please check for errors and try again.`,
                        }
                    },
                    language_mismatch: {
                        title: {
                            en: `Possible Language Mismatch`
                        },
                        body: {
                            en: `It looks like the text you entered for your biography might not be in the language you have selected for editing.  If you want to switch the language of your editor, please use the button below.`
                        }
                    }
                },
                alerts: {
                    first_time: {
                        en: `Your biography can be created in either English, Hawaiian, or both. Just click or tap on the appropriate language button below to select a language and begin creating. You may click the button at any time to switch languages, but be sure to save your progress first!`
                    },
                    language_mismatch: {
                        title: {
                            en: `Possible Language Mismatch`
                        },
                        body: {
                            '@type': 'mustache',
                            en: () => `It looks like the text you entered for your biography might be in {{inputLang}}, but you are currently editing in {{selectedLang}}.  If you would like to switch your editor to {{inputLang}}, please use the language button just above the profile image uploader.`
                        }
                    }
                },
                language: {
                    tooltip: {
                        en: `Your profile can be edited in English, ʻŌlelo Hawaiʻi, or both!  Use these buttons to select a language to work in. The active language is blue.`
                    },
                    switcher: {
                        header: {
                            en: `Editing Language`,
                        },
                    }
                }
            },
            organizations: {
                tooltip: {
                    photo: {
                        en: "Please choose a recent, professional, high-resolution headshot photo that will help other teachers and website users recognize you. This photo will be used throughout the site and may be updated at any time."
                    },
                    organizationName: {
                        en: "You are affiliated with these organizations in the Waihona."
                    },
                    title: {
                        en: "Please enter or update your professional title with each associated organization. Your title will be displayed with your photo and full or professional name throughout the site."
                    },
                    status: {
                        en: "This indicates your active, pending, or former status with each associated organization."
                    }
                },
                toast: {
                    success: {
                        title: {
                            en: `Saved!`,
                        },
                        body: {
                            en: `Mahalo for updating your organization information!`,
                        }
                    },
                    failure: {
                        title: {
                            en: `Uh-oh`,
                        },
                        body: {
                            en: `We were unable to save the changes.  Please check for errors and try again.`,
                        }
                    }
                }
            },
            list: {
                title: {
                    en: "Our Contributors"
                },
                badgePills: {
                    oneResource: {
                        en: "1 resource",
                        haw: "1 haʻawina"
                    },
                    moreResources: {
                        en: "resources",
                        haw: "mau haʻawina"
                    }
                }
            },
            detail: {
                sectionHeaders: {
                    badges: {
                        en: "Badges Earned"
                    },
                    contributions: {
                        en: "Contributions"
                    },
                },
                buttons: {
                    follow: {
                        en: "Follow this Contributor"
                    },
                    stopFollow: {
                        en: "Stop Following this Contributor"
                    },
                    message: {
                        en: "Message this Contributor"
                    },
	                edit: {
                    	en: `Edit my Profile`
	                },
	                contribute: {
                    	en: `Contribute a Resource`
	                },
	                organizations: {
                    	en: `Manage my Organizations`
	                }
                }

            },
            list_user_organizations: {
                title: {
                    en: `My Organizations`,
                    haw: `Koʻu Mau Hui`
                },
                alerts: {
                    invited: {
                        '@type': 'mustache',
                        en: () => `You've been invited to join {{orgName}} in the waihona! You may accept or decline your invitation below.`
                    },
                    noOrganizations: {
                        en: `ʻAuē! You don't currently belong to any organizations.`
                    }
                },
                buttons: {
                    save: {
                        en: "Save Changes",
                        haw: "Mālama"
                    }
                },
            },
            list_user_organizations_table: {
                headers: {
                    orgName: {
                        en: "Organization Name"
                    },
                    userTitle: {
                        en: `Professional Title`
                    },
                    userStatus: {
                        en: `Status`
                    }
                },
                tooltips: {
                    organizationName: {
                        en: "You are affiliated with these organizations in the Waihona."
                    },
                    userTitle: {
                        en: "Please enter or update your professional title with each associated organization. Your title will be displayed with your photo and full or professional name throughout the site."
                    },
                    userStatus: {
                        en: "This indicates your active, pending, or former status with each associated organization."
                    }
                },
                help: {
                    default: {
                        en: `default organization`,
                    },
                    setDefault: {
                        en: `+ set as default`,
                    },
                    title: {
                        en: `This will be displayed on your contributor profile page.`,
                    },
                    pendingApproval: {
                        en: `Your request will pend organization approval.`,
                    },
                    saving: {
                        en: `saving changes...`
                    }
                },
                placeholder: {
                    title: {
                        en: `Please enter your professional title.`,
                    }
                },
                labels: {
                    selectOrg: {
                        en: `Select an organization:`,
                    },
                    newTitle: {
                        en: `Your title at this organization:`,
                    }
                },
                status: {
                    active: {
                        en: "active member"
                    },
                    inactive: {
                        en: "inactive or former member"
                    },
                    invited: {
                        en: `Hulō! Youʻve been invited!`
                    },
                    pending: {
                        en: `pending organization approval`
                    },
                    unknown: {
                        en: `unknown status`
                    }
                },
                buttons: {
                    join: {
                        en: `Join`,
                    },
                    joinAnother: {
                        en: `+ Join Another Organization`,
                    },
                    edit_org: {
                        en: `Edit Organization`,
                    }
                }
            }
        },
        modal: {
            maintenance: {
                site_locked_for_maintenance: {
                    title: {
                        en: "E kala mai",
                        haw: "E kala mai",
                    },
                    body: {
                        en: "This site is currently undergoing maintenance and cannot be accessed at this time. Please try again later."
                    }
                }
            },
            home: {
                get_started: {
                    title: {
                        en: "Getting Started",
                        haw: "Hoʻomaka Maʻaneʻi"
                    },
                    first: {
                        en: "Aloha! First things first:",
                        haw: `Aloha! ʻO ka mua ka mua:`
                    },
                    list: {
                        zero: {
                            en: `Be sure to log in by clicking the red "Log In" button.`
                        },
                        one: {
                            en: `Update your user profile. You'll find "My Profile" in the menu under your avatar in the top right corner after you log in.`
                        },
                        two: {
                            en: `Start browsing "Resources" to see what others have contributed, and then upload your own contributions!`
                        },
                        three: {
                            en: `If you encounter any issues with the site, please click on the "Give Feedback" button at the bottom right.`
                        }
                    },
                    footer: {
                        en: `Mahalo! Now get out there and have some fun!`
                    },
                    continue: {
                        en: "Okay, I got it",
                        haw: "E hele!"
                    },
                    error: {
                        en: `Your browser does not support the video tag.`
                    }
                }
            },
            auth: {
                site_locked_authenticated_users_only: {
                    title: {
                        en: "E kala mai...",
                        haw: "E kala mai...",
                    },
                    body: {
                        en: `We are excited that you have an interest in the Waihona; however, the item you navigated to is currently only available to registered users.  Please log in to access this content.  If you have not yet registered, you may create a free account by clicking the "register" button below.  Mahalo!`
                    },
                    register: {
                        en: "Register",
                        haw: `Kau Inoa`
                    },
                    contact: {
                        en: "Contact",
                    }
                }
            },
            resource: {
                cancel_resource_submission: {
                    title: {
                        en: "This resource is not in a draft state",
                    },
                    body: {
                        en: "E kala mai! This submission is currently in review and cannot be edited at this time. You may recall your submission and put it back into a draft state, but your reviewer will lose all progress on their review.  We recommend that you wait until the review is complete before making any additional changes to your submission. Do you still wish to interrupt the review process and return your submission to a draft state?"
                    }
                },
                navigate_away: {
                    title: {
                        en: "Leaving Resource Create/Edit",
                    },
                    body: {
                        en: "Any changes you've made will be discarded when you leave this page. Are you sure you want to navigate away without saving?"
                    }
                },
                not_authorized_edit: {
                    title: {
                        en: "Not authorized to edit",
                    },
                    body: {
                        en: "E kala mai! You don't have access to edit this resource. If you are a collaborator, ask the owner to add you."
                    },
                    ok: {
                        en: "Okay",
                        haw: "ʻAe"
                    }
                },
                contribute_fan_content: {
                    title: {
                        en: "Contribute Resource Content",
                    },
                    body: {
                        mahalo: {
                            en: `Mahalo for contributing to this resource!`,
                            haw: `Mahalo no kou kākoʻo ʻana i keia haʻawina!`
                        },
                        form: {
                            proxy: {
                                en: "I am submitting this form by proxy on behalf of someone else.",
                            },
                            name: {
                                en: "Name",
                                haw: "Inoa"
                            },
                            name_placeholder: {
                                en: `Please enter your first and last name.`
                            },
                            email: {
                                en: "Email",
                                haw: "Leka uila"
                            },
                            email_placeholder: {
                                en: `Please enter your email address.`
                            },
                            message: {
                                en: "Please add a brief message to the author of this resource about your contribution.",
                            }
                        },
                        uploader: {
                            en: "Please upload your file(s) here.",
                        }
                    },
                    toast: {
                        fail: {
                            title: {
                                en: "Uh-oh!",
                                haw: "Auwē!"
                            },
                            body: {
                                en: "Please correct your errors and try again. All fields are required and must be properly formatted, and don't forget to upload your files!"
                            }
                        },
                        success: {
                            title: {
                                en: "Hulō!",
                                haw: "Hulō!"
                            },
                            body: {
                                en: "Mahalo for your contribution. We'll let the author know so that they may consider it for inclusion in a future publication."
                            }
                        },
                    }
                },
                specify_framework_specs: {
                    title: {
                        '@type': 'mustache',
                        en:  () => `How is {{frameworkName}} used by this resource?`
                    },
                    toast: {
                        save: {
                            fail: {
                                title: {
                                    en: "Uh-oh!",
                                    haw: "Auwē!"
                                },
                                body: {
                                    en: "We were unable to save your settings for the framework.  Please see the error that occured and fix it if you are able."
                                }
                            },
                            success: {
                                title: {
                                    en: "Hulō!",
                                    haw: "Hulō!"
                                },
                                body: {
                                    en: "Your changes to the framework have been made."
                                }
                            },
                        }
                    }
                }
            },
            review: {
                review_comment: {
                    title: {
                        en: `Reviewer Comments`
                    },
                    label: {
                        en: `Add a comment or feedback:`
                    },
                    placeholder: {
                        en: `Enter your comment or feedback here.  Don't worry; these comments will not be visible in the published resource for the general public.`
                    }
                }
            },
            organization: {
                verify_user_with_organization: {
                    title: {
                        en: "Submitter Verification Needed",
                    },
                    body: {
                        '@type': 'mustache',
                        en: () => `Is {{fullName}} a teacher or faculty member with {{orgName}}?`
                    }
                }
            },
            framework: {
                import_and_export: {
                    buttons: {
                        import: {
                          en: "Import",
                        },
                        export: {
                            en: "Export",
                        },
                    },
                    title: {
                        '@type': 'mustache',
                        en:  () => `Export {{frameworkName}} or change data within {{frameworkName}}`
                    },
                    toast: {
                        export: {
                            success: {
                                title: {
                                    en: "Exported!",
                                },
                                body: {
                                    en: "Your data was exported to the clipboard.  Please paste this value into a framework import box for the framework you would like to replace."
                                }
                            },
                            fail: {
                                title: {
                                    en: "Oops!",
                                },
                                body: {
                                    en: "Unable to copy to clipboard!"
                                }
                            },
                        },
                        import: {
                            fail: {
                                title: {
                                    en: "Uh-oh!",
                                    haw: "Auwē!"
                                },
                                body: {
                                    en: "Your import content is invalid"
                                }
                            },
                            success: {
                                title: {
                                    en: "Hulō!",
                                    haw: "Hulō!"
                                },
                                body: {
                                    en: "Your data was imported.  Please remember to save."
                                }
                            },
                        }
                    }
                }
            },
            search: {
                specify_search_framework_specs: {
                    title: {
                        '@type': 'mustache',
                        en:  () => `How is {{frameworkName}} used by this resource?`
                    },
                    buttons: {
                        set_search_specs: {
                            en: "Set Search Specs",
                        },
                    },
                    toast: {
                        save: {
                            fail: {
                                title: {
                                    en: "Uh-oh!",
                                    haw: "Auwē!"
                                },
                                body: {
                                    en: "We were unable to save your settings for the framework.  Please see the error that occured and fix it if you are able."
                                }
                            },
                            success: {
                                title: {
                                    en: "Hulō!",
                                    haw: "Hulō!"
                                },
                                body: {
                                    en: "Your changes to the framework have been made."
                                }
                            },
                        }
                    }
                }
            }

        },
        homepage: {
            tagline: {
                en: `Where kumu share resources and ideas with each other`,
                haw: `Kahi a nā kumu e kaʻana like ai i ka haʻawina me ka manaʻo`
            },
            version: {
                en: ``
            },
            in_development: {
                en: `Early Access`
            },
            buttons: {
                get_started: {
                    en: `Get Started`,
                    haw: `E Hoʻomaka`
                },
                roadmap: {
                    en: `Roadmap`
                },
                updates: {
                    en: `Updates`
                }
            },
            headers: {
                roadmap: {
                    en: `Development Roadmap`
                },
                phase: {
                    en: `Current development Phase 3: Additional Functionality`
                },
                updates: {
                    en: `Updates and Announcements`
                }
            },
            text_blocks: {
                one: {
                    en: `Mahalo for registering as an "early adopter" of the waihona. This project is in an active development state, meaning that our team is currently working on the site and adding features we consider core functionality. That being said it's also ready to use!.`
                },
                two: {
                    en: `For more information about the development phases, please refer to the diagram below. As you are using the site, please send feedback about your experience to the development team by clicking on the "Give Feedback" button in the lower right corner of every page, but please frame it within the scope of the current development phase. If you'd like to discuss the site with others, ask questions and get help, or suggest ideas for future development, click on the "Discuss on Slack" button below. Mahalo!`
                },
                three: {
                    en: `This "ao" phase addresses additional user functionality. Key features in this phase include ʻōlelo Hawaiʻi support, searching, and in-app messaging and notifications.`
                }
            },
            features: {
                title: {
                    en: `Share. Collaborate. Have fun doing it.`
                },
                subtitle: {
                    en: `Jump in and put your lolo uila to good use`
                },
                body: {
                    first: {
                        header: {
                            en: `Upload Resources`
                        },
                        body: {
                            en: `Upload images, worksheets, lesson and unit plans, whatever learning resources you want to share with the lāhui.`
                        }
                    },
                    second: {
                        header: {
                            en: `Easy to use`
                        },
                        body: {
                            en: `It won’t take a lot to learn how to use this tool. It’s easy, and fun. If it isn’t, let us know and we’ll fix it.`
                        }
                    },
                    third: {
                        header: {
                            en: `Collaborate`
                        },
                        body: {
                            en: `Share yours ideas and grow them through collaboration. Help others and lift learning in all communities.`
                        }
                    },
                    fourth: {
                        header: {
                            en: `Vibrant and thriving`
                        },
                        body: {
                            en: `Just like us, this collaborative space will evolve over time to meet the needs of kumu and life long learners everywhere.`
                        }
                    }
                }
            },
            articles: {
                first: {
                    title: {
                        en: `Hello early adopters and ahead of the curve-ers`
                    },
                    subtitle: {
                        en: `From a technical standpoint, we’re just getting started`
                    },
                    body: {
                        en: `Nineteen months and counting. That’s how long we’ve been working on the waihona. It may sound like a long time, but if we think about it on a broader scale, nineteen months is a really short amount of time for something we aim to be around for generations.`
                    }
                },
                second: {
                    title: {
                        en: `Let’s build something beautiful together`
                    },
                    subtitle: {
                        en: `We’re looking for some awesome kala-borators`
                    },
                    body: {
                        en: `With your help, we’re hoping to build something that lasts, not only for this generation, but for the generations yet to come. Its being built from the ground up, for us, by us (kākou).`
                    }
                },
                third: {
                    title: {
                        en: `Everyone is bringing their “A” game`
                    },
                    subtitle: {
                        en: `Lots of lima working together`
                    },
                    body: {
                        en: `Your enthusiasm and energy will help shape it. Our ‘ōiwi developers want to hear from you, so please don’t be afraid to give feedback. If you have any nīnau, need kōkua, or would like to suggest ideas for future development, let’s discuss on Slack! Just use the buttons below at any time.`
                    }
                }
            },
            footer: {
                title: {
                    en: `Ready to get started?`
                },
                org_admin: {
                    en: `Org admin? Apply for your org to participate`
                },
                updates: {
                    en: `Latest updates`
                }
            }
        }
    };

    public isReady: boolean = false;

    /** Structured data */
    public data: { [key: string]: {} } = {};

    /** Localizations */
    public localizations: { [key: string]: {} } = {
        en: {},
        haw: {},
    };
    public templates: { [key: string]: { [key: string]: TemplateData } } = {
        en: {},
        haw: {},
    };

    public static pathToObjectRef(path: string): any {
        //modifies the original object adding _'s to the path, containing the path in the object itself

        let ref = (obj, str) => {
            str = str.split(".");
            for (let i = 0; i < str.length; i++) {
                obj = obj[str[i]];
            }
            return obj;
        };
        return ref(Localization.template, path);
    }

    public toArray(): Array<string> {
        return Object.keys(this.localizations.en);
    }

    public _() {
        return Localization.template;
    }

    constructor(isReady: boolean) {
        this.isReady = isReady;

        //Copy from the static template
        let copy: any = JSON.parse(JSON.stringify(Localization.template));

        this.notateObjectStructureAndExtractTranslationData(Localization.template);
        this.data = copy;
    }

    protected notateObjectStructureAndExtractTranslationData(obj: any, path: string = ""): any {
        //modifies the original object adding _'s to the path, containing the path in the object itself
        Object.keys(obj).forEach(e => {
            if (typeof obj[e] == "object") {
                //this.logger.info(`language=${e}  value=${obj[e]}`)
                let subElementPath: string = (path == "") ? e : path + "." + e;
                obj[e]._ = subElementPath;
                //this.logger.info(`${obj[e]._}`);
                this.notateObjectStructureAndExtractTranslationData(obj[e], subElementPath);

                let isMissingOlelo: boolean = this.isMissingLanguage(obj[e], "haw");
                let isMissingEnglish: boolean = this.isMissingLanguage(obj[e], "en");

                let isTypelessFunction: boolean = obj[e]['@type'] == null && typeof obj[e].en == "function"
                let isMustacheTemplate: boolean = (obj[e]['@type'] != null && obj[e]['@type'] == 'mustache') || isTypelessFunction; //by d

                let hawaiianContent: string | Function = obj[e].haw;
                let englishContent: string | Function = obj[e].en;
                let hasHawaiianFunction: boolean = false;
                let hasEnglishFunction: boolean = false;

                let fullPath: string = obj[e]._;

                if (isMustacheTemplate) {
                    //TODO: The repetitive setup will get tiresome when the third or greater language is added, replace with
                    // a function call then to dynamically add the function and return the wrapped function
                    hasHawaiianFunction = obj[e].haw && typeof obj[e].haw == "function";
                    hasEnglishFunction = obj[e].en && typeof obj[e].en == "function";
                    if (hasHawaiianFunction) {
                        //hawaiianContent = hawaiianContent;
                    }
                    if (hasEnglishFunction) {
                        //englishContent = englishContent;
                    }
                }

                if (!isMissingOlelo) {
                    this.set(fullPath, "haw", hawaiianContent);
                }

                //Set English
                this.set(fullPath, "en", englishContent);

                //TODO: Maybe we want to keep this around..
                /*                delete obj[e].haw;
								delete obj[e].en;*/
            }
        });
    }

    private isMissingLanguage(objectNode: object, languageCode: string): boolean {
        let isMissing: boolean = objectNode[languageCode] == null;
        return isMissing;
    }

    public set(pathObjectOrPath: object | string, language: string, value: string | Function) {
        let path: string = typeof pathObjectOrPath == "object" ?
            pathObjectOrPath["_"] : pathObjectOrPath;

        //For example this.localizations[language][path] == this.localizations["haw"]["organization.edit.toast.saved.warning.body"]" and assigning value "Ua mālama ʻia ka hui."
        this.localizations[language][path] = value;
        if (typeof value == "function") {
            let template: string = value();
            this.templates[language][path] = new TemplateData(template);
        }
    }

    public toKeys(): Array<string> {
        let allPaths: Array<string> = Object.keys(this.localizations[SupportedLanguage.en]);
        return allPaths;
    }

    /** Takes the object {organizations.edit.toast.saved.warning.body}, and "haw" and returns "Ua mālama ʻia ka hui." */
    public get(pathObjectOrPath: object | string, language: string): string {
        let path: string = typeof pathObjectOrPath == "object" ?
            pathObjectOrPath["_"] : pathObjectOrPath;
        return this.localizations[language][path];
    }

    private index(obj: any, i: string) {
        return obj[i];
    }

    public toString(): string {
        return JSON.stringify(this.localizations, null, 2);
    }
}

export class TemplateData {
    public template: string = "";
    public parameters: {} = null;

    constructor(template?: string) {
        if (template != null) {
            this.template = template;
        }
    }
}

//Keep this around we might need it
//-----------------------------------
/** Takes "organizations.edit.toast.saved.body", "haw" and returns "Ua mālama ʻia ka hui." */
/*public getLanguageSpecific(path:string, language?:string):string {
    var language:string =
        (language != null) ?
            language : this.language;

    let value:string = (path + "." + language).split('.').reduce(this.index, this.areas);
    return value;
}

private index(obj:any,i:string) {
    return obj[i];
}*/
//-----------------------------------


