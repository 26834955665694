import {Injectable} from "@angular/core";
import {NewUserRegistrationToWaihonaUserConverter} from "./converters/NewUserRegistrationToWaihonaUserConverter";
import {FrameworkToFrameworkRefConverter} from "./converters/FrameworkToFrameworkRefConverter";
import {WaihonaUserToWaihonaUserRefConverter} from "./converters/WaihonaUserToWaihonaUserRefConverter";
import {FrameworkToSelectableFrameworkRefConverter} from "./converters/FrameworkToSelectableFrameworkRefConverter";
import {WaihonaUserOrganizationToOrganizationRefConverter} from "./converters/WaihonaUserOrganizationToOrganizationRefConverter";
import {WaihonaUserToUserProfileMessageConverter} from "./converters/WaihonaUserToUserProfileMessageConverter";
import {ReviewToActivityStreamEntryConverter} from "./converters/ReviewToActivityStreamEntryConverter";
import {ResourceSubmissionToResourceSubmissionRefConverter} from "./converters/ResourceSubmissionToResourceSubmissionRefConverter";
import {OrganizationRefToWaihonaUserOrganizationConverter} from "./converters/OrganizationRefToWaihonaUserOrganizationConverter"
import {EventTypeToEventTypeRefConverter} from "./converters/email/EventTypeToEventTypeRefConverter";
import {ObjectContextToObjectContextRefConverter} from "./converters/email/ObjectContextToObjectContextRefConverter";
import {EventContextToEventContextRefConverter} from "./converters/email/EventContextToEventContextRefConverter";
import {AudienceToAudienceRefConverter} from "./converters/email/AudienceToAudienceRefConverter";
import {EventMappingToEventMappingRefConverter} from "./converters/email/EventMappingToEventMappingRefConverter";
import {MappingDestinationToMappingDestinationRefConverter} from "./converters/email/MappingDestinationToMappingDestinationRefConverter";
import {
    KnownSpecificationTypeToKnownSpecificationTypeRefConverter,
    NodeRelationshipDefinitionToNodeRelationshipDefinitionRefConverter
} from "../areas/admin/specification/specification-converters";
import {OrganizationToOrganizationRefConverter} from "./converters/OrganizationToOrganizationRefConverter";
import {BadgeToBadgeRefConverter} from "./converters/BadgeToBadgeRefConverter";
import {AinaToAinaRefConverter} from "./converters/AinaToAinaRefConverter";
import {CollectionToCollectionRefConverter} from "./converters/CollectionToCollectionRefConverter";
import {PublishedResourceToPublishedResourceRefConverter} from "./converters/PublishedResourceToPublishedResourceRefConverter";

@Injectable({
    providedIn: 'root',
} as any)
/** Conversion Service converts between registered types */
export class ConversionService {

    public converters:Array<Converter> = [];
    public fromMap:object = {};

    constructor() {
        this.register(new NewUserRegistrationToWaihonaUserConverter());
        this.register(new FrameworkToFrameworkRefConverter());
        this.register(new FrameworkToSelectableFrameworkRefConverter());
        this.register(new WaihonaUserToWaihonaUserRefConverter());
        this.register(new WaihonaUserOrganizationToOrganizationRefConverter());
        this.register(new WaihonaUserToUserProfileMessageConverter());
        this.register(new ReviewToActivityStreamEntryConverter());
        this.register(new ResourceSubmissionToResourceSubmissionRefConverter());
        this.register(new PublishedResourceToPublishedResourceRefConverter());
	    this.register(new OrganizationRefToWaihonaUserOrganizationConverter());
        this.register(new EventTypeToEventTypeRefConverter());
        this.register(new ObjectContextToObjectContextRefConverter());
        this.register(new EventContextToEventContextRefConverter());
        this.register(new AudienceToAudienceRefConverter());
        this.register(new EventMappingToEventMappingRefConverter());
        this.register(new MappingDestinationToMappingDestinationRefConverter());

        //Collection Related
        this.register(new CollectionToCollectionRefConverter());

        //Specifications
        this.register(new KnownSpecificationTypeToKnownSpecificationTypeRefConverter());
        this.register(new NodeRelationshipDefinitionToNodeRelationshipDefinitionRefConverter());
        this.register(new OrganizationToOrganizationRefConverter());
        this.register(new BadgeToBadgeRefConverter());
        this.register(new AinaToAinaRefConverter());
    }

    /** Registers a new Converter */
    public register(converter:Converter) {
        let fromScope = this.fromMap[converter.from];

        if(fromScope == null) {
            this.fromMap[converter.from] = {};
        }
		this.fromMap[converter.from][converter.to] = converter;
    }
    public convert(sourceObject:object, destinationType:any) {
        if(sourceObject == null || destinationType == null) {
            throw new ConversionException(null, ConversionException.SOURCE_WAS_NULL);
        }

        let converters:object = this.fromMap[sourceObject.constructor as any];
        if(converters == null) {
            console.error(`no mapping for source`)
            //No mapping for source (so impossible there is one for destination
            throw new ConversionException(null, ConversionException.MESSAGE_NO_CONVERTER, sourceObject, destinationType);
        }
        let converter:Converter = this.fromMap[sourceObject.constructor as any][destinationType];
        if(converter == null) {
            console.error(`no mapping for destination`)
            //There is one for source but not one for destination
            throw new ConversionException(converter, ConversionException.MESSAGE_NO_CONVERTER,sourceObject, destinationType);
        }

        let destination;
        try {
            destination = converter.convert(sourceObject);
        } catch(error) {
            throw new ConversionException(converter, ConversionException.CONVERSION_ERROR,sourceObject, destinationType);
        }
        return destination;
    }

}

export interface Converter {
    readonly from;
    readonly to;

    convert(source:any):any;
}

export class ConversionException extends Error {

    public static MESSAGE_NO_CONVERTER="No converter was registered for the source and destination object type";
    public static SOURCE_WAS_NULL="The source type was null";
    public static CONVERSION_ERROR="There was a problem running the converter";

    public converter:Converter;
    public sourceObject:Object;
    public destinationType:any;

    constructor(converter:Converter, message:string, sourceObject?:any, destinationType?:any) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

        this.converter   = converter;
        this.sourceObject = sourceObject;
        this.destinationType = destinationType;
    }
}
