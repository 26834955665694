import {RegisterClazz} from "../RegisterType";

@RegisterClazz(Role)
export class Role {

    public type:RoleType; //(Enum)
    /** The guid of the organization */
    public organization:string;

    constructor(type?:RoleType, organization?:string) {
        if(type != null) {
			this.type = type;
        }
		if(organization != null) {
            this.organization = organization;
		}
    }

    public equals(to:Role):boolean {
        return (to.type == this.type) &&
               (to.organization == this.organization);
    }
}

export enum RoleType {
    "registered" = "registered",
    "contributor" = "contributor", // (verified)
    "reviewer" = "reviewer",
    "contentOwner" = "contentOwner",
    "organizationAdmin" = "organizationAdmin",
    "frameworkAdmin" = "frameworkAdmin",
    "waihonaAdmin" = "waihonaAdmin",
	"waihonaLocalizer" = "waihonaLocalizer",
	"badgeAdmin" = "badgeAdmin"
}
