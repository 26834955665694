<table class="table table-responsive-sm table-hover table-outline col-md-12">
	<thead class="thead-light"></thead>
	<tbody>
	<tr>
		<td>
			<file-upload #fileUpload (UploadFilesSelected)="addFiles($event)" (UploadFolderIssues)="handleFolderIssues($event)"></file-upload>
		</td>
	</tr>
	<tr>
		<div id="pending-uploads-alert" *ngIf="pendingUploads().length > 0" class="p-3 bg-warning text-center font-weight-bold">
			<div>{{CB.notUploaded | localize | async}}</div>
			<div><a  id="upload-text" href="javascript:void(0)" style="text-decoration: none" (click)="onDoUploadNow($event)">{{CB.uploadNow | localize | async}}</a></div>
		</div>
	</tr>
	</tbody>
</table>

<table class="table table-responsive-sm table-hover table-outline file-asset-component"
	   *ngIf="uploads.length > 0 || assets.length > 0">
	<thead class="thead-light">
	<tr>
		<th class="text-left col-xs-1"><i class="icon-arrow-down-circle"></i></th>
		<th class="text-center col-xs-1"><i class="far fa-file"></i></th>
		<th class="text-left col-xs-9">{{CB.header | localize | async}}</th>
		<th class="text-right col-xs-1">&nbsp;</th>
	</tr>
	</thead>
	<tbody>

	<!--Pre-upload Assets (queued and waiting to upload)-->
	<ng-container *ngFor="let learningAssetUpload of uploads$ | async">
		<tr class="app-lesson-asset-upload"
			[class.bg-warning]="learningAssetUpload.status==UploadStatus.waiting || learningAssetUpload.status==UploadStatus.failure"
			[class.bg-danger]="learningAssetUpload.status==UploadStatus.preupload_problem"
			[learningAssetUpload]="learningAssetUpload" (onUploadRemoved)="onUploadRemove($event)" (onUploadCanceled)="onUploadCancel($event)"
			(onChoiceConfirmed)="onLearningAssetProblemFixChoiceConfirmed($event, learningAssetUpload);"
		></tr>
	</ng-container>

	<!-- List of Existing Assets (things that actually uploaded) -->
	<ng-container *ngFor="let asset of assets$ | async">
		<tr class="app-lesson-asset"
			[asset]="asset"
			[class.bg-danger]="asset.hasConflictWith!=''"
			(onChoiceConfirmed)="onFanContributionAssetProblemFixChoiceConfirmed($event, asset);"
			(onRenameConfirmed)="onAssetRename($event)"
			(onDelete)="this.onAssetDelete(asset)"
		></tr>
	</ng-container>

	</tbody>
</table>

<div class="p-3 mb-3 bg-light text-center font-weight-bold" *ngIf="uploads$.getValue().length == 0 && assets$.getValue().length == 0">{{CB.noAssets | localize | async}}&nbsp;
	<span><a style="text-decoration: none" href="javascript:void(0)" (click)="fileUpload.onSelectFilesButtonClick($event)">{{CB.upload | localize | async}}</a></span>
</div>

<div class="p-3 mb-3 bg-light text-center font-weight-bold" *ngIf="assets$.getValue().length > 0">{{CB.someAssets | localize | async}}</div>

<loading-button *ngIf="pendingUploads().length > 0" label="Upload" (onClick)="onUploadClick($event)"></loading-button>
