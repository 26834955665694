import {Injectable} from "@angular/core";
import {environment} from '../../environments/environment';
import {SiteConfigurationRepository} from "./repository/SiteConfigurationRepository";
import {SiteConfiguration} from "../domain/config/SiteConfiguration";
import {BehaviorSubject, Subscription} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {RoleService} from "./common/RoleService";
import {PermissionType} from "../domain/user/Permission";
import {Router} from "@angular/router";
import {NotificationService} from "./common/NotificationService";


@Injectable(
	{providedIn: 'root'
} as any)
/** Site Configuration Service */
export class SiteConfigurationService {

	private _siteConfiguration:SiteConfiguration;
	public isLoaded:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public constructor(private repo:SiteConfigurationRepository, protected logger:NGXLogger, protected roleService:RoleService, protected router:Router) {
		this._siteConfiguration = new SiteConfiguration();

		if(environment && environment["site-config"]) {
			if(environment["site-config"].environment != null) {
				this._siteConfiguration.environment = environment["site-config"].environment;
			}
			this._siteConfiguration.inviteCodesRequiredForRegistration 	= environment["site-config"].inviteCodesRequiredForRegistration;
			this._siteConfiguration.loginRequiredForSiteAccess 			= environment["site-config"].loginRequiredForSiteAccess;
		}
		let siteFormerlyWasUndergoingMaintenance:boolean = null;


		this.repo.watch$().filter(siteConfig => siteConfig != null).subscribe(siteConfig => {
			this._siteConfiguration = siteConfig;
			this.logger.info("Loaded Site Configuration from Server:\n" + this._siteConfiguration.toString());
			this.isLoaded.next(true);

			if(siteFormerlyWasUndergoingMaintenance !=null && siteFormerlyWasUndergoingMaintenance != siteConfig.siteIsUndergoingMaintenance) {
				console.log("SiteConfiguration updated to " + siteConfig.siteIsUndergoingMaintenance);
				if(!this.roleService.hasPermissionFor(PermissionType.site_access_while_shutdown)) {
					this.router.navigate(["/"]);

				}
				if(siteFormerlyWasUndergoingMaintenance && !siteConfig.siteIsUndergoingMaintenance) {
					//TODO:write code here to hide modal
					console.log("write code here to hide modal");
				}
			}
			siteFormerlyWasUndergoingMaintenance = siteConfig.siteIsUndergoingMaintenance;

		});
	}

	public get siteConfiguration():SiteConfiguration {
		return this._siteConfiguration;
	}

	public save():void {
		this.repo.save$(this._siteConfiguration);
	}

}
