import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {SiteConfigurationService} from "./SiteConfigurationService";
import {AgreementMode} from "../domain/config/SiteConfiguration";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Injectable({
	providedIn: 'root',

} as any)
export class UrlService {

	public resourceImage = {
		draft:(resourceGuid:string, cachebust:string = null) => {
			if (cachebust == null) {
				return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/${resourceGuid}.png`;
			}
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/${resourceGuid}.png?t=${cachebust}`;
		},
		review:(resourceGuid:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Reviews/${resourceGuid}/${resourceGuid}.png`,
		published:(resourceGuid:string, majorVersion?:number) => {
			let url:string = `https://storage.googleapis.com/${environment.firebase.storageBucket}/Resources/${resourceGuid}/current/${resourceGuid}.png`;
			if (majorVersion) {
				url += `?${majorVersion}`;
			}
			return url;
		}
	};


	public resourceZip = {
		draft: (resourceGuid:string, version:string) => {
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/archive/${resourceGuid}_${version}.zip`;
		},
		review: (resourceGuid:string, version:string) => {
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Reviews/${resourceGuid}/archive/${resourceGuid}_${version}.zip`;
		},
		published: (resourceGuid:string, version:string) => {
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Resources/${resourceGuid}/archive/${resourceGuid}_${version}.zip`;
		}
	};

	public resourcePage = {
		draft: (userGuid:string, resourceGuid:string) => {
			return `${environment.site.baseUrl}/#/resources/users/${userGuid}/${resourceGuid}`;
		},
		review: (resourceGuid:string) => {
			return `${environment.site.baseUrl}/#/reviews/${resourceGuid}`;
		},
		published: (resourceGuid:string) => {
			return `${environment.site.baseUrl}/#/resources/${resourceGuid}`;
		}
	};
	public localizations = {
		file: (language:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/localizations/waihona_${language}.properties`
	};

	public fanContribution = {
		assets: {
			file: (fanContributionGuid:string, filename:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/FanContributions/${fanContributionGuid}/assets/${filename}`
		}
	};

	public legal = {
		contributorAgreement: {
			version: (version:string = "LATEST") => {
				if(this.siteConfigurationService.siteConfiguration.agreement.mode == AgreementMode.checkbox_only) {
					return this.siteConfigurationService.siteConfiguration.links.contributor_agreement;
				} else {
					return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Legal/versions/${version}/WAIHONA%20CONTRIBUTOR%20AND%20USER%20KULEANA%20AGREEMENT.pdf`;
				}
			}
		}
	};

	public site = {
		roadmap: () => {
			return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Site/roadmap.svg`;
		}
	}

	public root = {
		resources: {
			page: {
				draft: {
					image: (resourceGuid:string, cachebust:string = null) => {
						if (cachebust == null) {
							return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/${resourceGuid}.png`;
						}
						return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/${resourceGuid}.png?t=${cachebust}`;
					},
				},
				review: {
					image: null,
				},
				published: {

				}

			},

		},
		organizations: {

		},
		user: {

		}

	}

	//TODO: Move to a class called PathService
	public resourceAssets = {
		draft: (resourceGuid:string, fileName:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Drafts/${resourceGuid}/assets/${encodeURIComponent(fileName)}`,
		review: (resourceGuid:string, fileName:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Reviews/${resourceGuid}/assets/${encodeURIComponent(fileName)}`,
		published: (resourceGuid:string, fileName:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Resources/${resourceGuid}/current/assets/${encodeURIComponent(fileName)}`
	};

	public imageUrl = {
		avatar: (userGuid:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Users/${userGuid}.png`,
		organization: (orgGuid:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Organizations/${orgGuid}.png`,
		badge: (badgeGuid:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Badges/${badgeGuid}.png`,
		collection: (collectionGuid:string) => `https://storage.googleapis.com/${environment.firebase.storageBucket}/Collections/${collectionGuid}.png`,
		draft: (resourceGuid:string) => this.resourceImage.draft(resourceGuid),
	};

	constructor (
		private siteConfigurationService:SiteConfigurationService,
		private cookieService:CookieService
	) {
	}

	public baseUrl():string {
		return environment.site.baseUrl;
	}

	public saveAttemptedResourceRoute(next:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
		console.log("Attempted navigation to: " + state.url);
		this.cookieService.set("loginDestination", state.url);
	}

/* unused files

	/*public getFirebaseStorageAvatarUrl(userGuid:string):string {
		return `https://storage.googleapis.com/${environment.firebase.storageBucket}/Users/${userGuid}.png`;
	}*/

	//old (moved to HttpFileUtils) -- keep until test is successful
	/*public fileExists(url:string):Observable<boolean> {
		let fileExistsObservable:Subject<boolean> = new Subject<boolean>();
		fetch(url, {
			method: 'HEAD'
		}).then((response) => {
			if (response.status >= 200 && response.status < 300 && response.ok) {
				fileExistsObservable.next(true);
			} else {
        fileExistsObservable.next(false);
      }
		}).catch((error) => Error(error));
		return fileExistsObservable;
	}*/

	// old (keep until test is successful)
	/*public relativeFirebaseStorageUserResourceSubmissionSaves(userGuid:string, resourceGuid:string, fileName:string):string {
		return `/Users/${userGuid}/Saves/${resourceGuid}/Files/${fileName}`;
	}*/

	/*/!** The path on the firebase storage instance to a draft submission's learning assets *!/
	public relativeFirebaseStorageDraftAssets(resourceGuid:string, fileName:string):string {
		return `/Drafts/${resourceGuid}/assets/${fileName}`;
	}

	/!** The path on the firebase storage instance to a review submission's learning assets *!/
	public relativeFirebaseStorageReviewAssets(resourceGuid:string, fileName:string):string {
		return `/Reviews/${resourceGuid}/assets/${fileName}`;
	}

	/!** The path on the firebase storage instance to a published submission's learning assets *!/
	public relativeFirebaseStoragePublishedResourceAssets(resourceGuid:string, fileName:string):string {
		return `/Resources/${resourceGuid}/current/assets/${fileName}`;
	}*/

}

