<ng-container *ngIf="framework != null">
	<div class="animated fadeIn">
		<h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.header | localize | async}}</h1><br>
		<div class="card">
			<div class="card-body">
				<div class="row">

					<!--sidebar div-->
					<div class="col-md-3">

						<!--Tree Card-->
						<div class="card border border-0">
							<div class="card-body">
								<h5 class="card-title font-weight-bold">{{CB.sidebar_title | localize | async}}</h5>
								<tree-root #tree [nodes]="framework.elements" [(state)]="frameworkTreeState"
										   [options]="frameworkTreeOptions"
										   (activate)="onElementSelected($event)"></tree-root>
							</div>
						</div>

						<!--Button Card-->
						<div class="card border border-0">
							<button class="btn btn-primary" (click)="addNode()">{{CB.addButton | localize | async}}</button>
							<button class="btn btn-primary" (click)="deleteNode()">{{CB.deleteButton | localize | async}}</button>
							<button class="btn btn-primary" (click)="moveNodeUp()">{{CB.upButton | localize | async}}</button>
							<button class="btn btn-primary" (click)="moveNodeDown()">{{CB.downButton | localize | async}}</button>
							<br/>
							<button class="btn btn-danger" (click)="displayImportExportModal()">{{CB.importAndExport | localize | async}}</button>
						</div>
						<!--closing sidebar div-->
					</div>
					<!--Main section-->
					<div class="col-md-6">
						<tabset>
							<tab heading="{{CB.form_header | localize | async}}">
								<div class="card">
									<div class="card-body">
										<div class="form-group has-feedback" waih-validation-color field="title">
											<label>{{CB.form_labelTitle | localize | async}} <i
													class="fas fa-asterisk text-danger required-field"></i></label>
											<input type="text" class="form-control" id="title" name="title"
												   data-field-attr="title" class="form-control" [(ngModel)]="framework.title"
												   [attr.maxlength]="maxLengths?.title"
												   placeholder="{{CB.form_placeholderTitle | localize | async}}">
											<char-count [fieldLength]="framework?.title?.length" [maxLength]="maxLengths?.title"></char-count>
											<waih-validation-text field="title"></waih-validation-text>
										</div>

										<div class="form-group" waih-validation-color field="shortDescription">
											<label for="short-desc">{{CB.form_labelSummary | localize | async}}
												<i class="fas fa-asterisk text-danger required-field"></i></label>
											<textarea rows="3" cols="50" class="form-control" id="short-desc" required
													  [(ngModel)]="framework.shortDescription"
													  [attr.maxlength]="maxLengths?.shortDescription"
													  placeholder="{{CB.form_placeSummary | localize | async}}"></textarea>
											<char-count [fieldLength]="framework?.shortDescription?.length" [maxLength]="maxLengths?.shortDescription"></char-count>
											<waih-validation-text field="shortDescription"></waih-validation-text>
										</div>

										<div class="form-group" waih-validation-color field="fullDescription">
											<label for="full-desc">{{CB.form_labelDescription | localize | async}}
												<i class="fas fa-asterisk text-danger required-field"></i></label>
											<quill-editor
													class="quill-editor-size"
													id="full-desc"
													name="full-desc"
													[(ngModel)]="framework.fullDescription"
													[modules]="editorModules"
													[maxlength]="maxLengths?.fullDescription"
													placeholder="{{CB.form_placeDescr | localize | async}}">
											</quill-editor>
											<char-count [fieldLength]="fullDescriptionPlainText.length" [maxLength]="maxLengths?.fullDescription"></char-count>
											<waih-validation-text field="fullDescription"></waih-validation-text>
										</div>

									</div>
								</div>
							</tab>
							<tab heading="Element" *ngIf="activatedNodeName!=null" [active]="activatedNodeName!=null">
								<div class="framework-element">
									<div class="card my-lg-3 my-sm-1">
										<div class="card-header form-group has-feedback"
											 waih-validation-color field="element-name">
											<label for="element-name">{{CB.form_labelElementName | localize | async}} </label>
											<input type="text" class="form-control"
												   id="element-name" name="element-name"
												   data-field-attr="name" class="form-control"
												   [(ngModel)]="activatedNodeName"
												   [attr.maxlength]="maxLengths?.name"
												   placeholder="{{CB.form_placeElemName | localize | async}}">
											<char-count [fieldLength]="activatedNodeName?.length" [maxLength]="maxLengths?.name"></char-count>
											<waih-validation-text field="element-name"></waih-validation-text>
										</div>
										<div class="card-body">
											<div class="form-group has-feedback"
												 waih-validation-color field="element-desc">
												<label for="element-desc">{{CB.form_labelElementDescr | localize | async}}</label>
												<textarea rows="5" cols="50" class="form-control" id="element-desc"
														  required
														  [(ngModel)]="activatedNodeDesc"
														  [attr.maxlength]="maxLengths?.description"
														  placeholder="{{CB.form_placeElemDescr | localize | async}}"></textarea>
												<char-count [fieldLength]="activatedNodeDesc?.length" [maxLength]="maxLengths?.description"></char-count>
												<waih-validation-text field="element-desc"></waih-validation-text>
											</div>
										</div>
									</div>
								</div>
							</tab>
						</tabset>
					</div>
					<!--end main section-->

					<!--right side-->
					<div class="col-md-3">
						<div class="card">
							<div class="card-header">{{CB.meta_header | localize | async}}</div>
							<div class="card-body">
								<div class="form-group has-feedback">
									<span class="help-block">{{CB.meta_type | localize | async}}
										<i class="fas fa-asterisk text-danger required-field"></i>
									</span>
									<ng-select
											waih-validation-color field="type"
											class="bootstrap custom"
											[items]="frameworksTypeArray"
											[multiple]="false"
											placeholder="{{CB.meta_place | localize | async}}"
											[(ngModel)]="framework.type">
										<ng-template ng-label-tmp let-item="item" let-clear="clear" class="single">
											<div class="option">
												<span class="ng-value-icon left" (click)="clear(item)"
													  aria-hidden="true">&times;</span><span
													class="ng-value-label">{{item}}</span>
											</div>
										</ng-template>
									</ng-select>
									<waih-validation-text field="type"></waih-validation-text>
								</div>
							</div>
						</div>

						<div class="card">
							<div class="card-header">Settings</div>
							<div class="card-body">
								<div class="form-group">
									<!-- Is Visible -->
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" name="switchVisible" id="switchVisible"
											   [(ngModel)]="framework.visible" [attr.checked]="framework.visible">
										<label for="switchVisible" class="custom-control-label">Is Visible</label>
									</div>

									<!-- Is Selectable -->
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" name="switchSelectable" id="switchSelectable"
											   [(ngModel)]="framework.selectable"  [attr.checked]="framework.selectable">
										<label for="switchSelectable" class="custom-control-label">Is Selectable</label>
									</div>
									<!-- Is Searchable -->
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" name="switchSearchable" id="switchSearchable"
											   [(ngModel)]="framework.searchable"  [attr.checked]="framework.searchable">
										<label for="switchSearchable" class="custom-control-label">Is Searchable</label>
									</div>
								</div>
							</div>
						</div>

					</div>
					<!--end right side-->
				</div>
			</div>
		</div>
		<button class="btn btn-warning text-black mx-2" (click)="onSubmitClick()">{{CB.save | localize | async}}<span *ngIf="isSaving" class="spinner-border spinner-border-sm mx-4" role="status"></span></button>
	</div>
</ng-container>
