import {RegisterClazz} from "../RegisterType";
import {ResourceVersion} from "./ResourceVersion";
import {PublishState} from "./PublishState";
import {TPublishStatus} from "./TPublishStatus";

@RegisterClazz(ResourcePublishStatus)
export class ResourcePublishStatus {
	public guid:string = null;

	public resourceGuid:string = null;
	public resourceVersion:ResourceVersion = null;
	public status:TPublishStatus = {
		document: PublishState.not_started,
		files: PublishState.not_started,
		archive: PublishState.not_started,
	};

	constructor() {
	}

}

