import {AbstractCompoundFirestoreRepository} from "./AbstractCompoundFirestoreRepository";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {Observable, Subject} from "rxjs";
import {
	FilterFunction,
	FirestoreIndex,
	IndexableFirestoreRepository,
	OrderByCriterion,
	OrderByDirections,
	QueryCriteria,
	QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {MessageRef} from "../../domain/inbox/MessageRef";
import {InboxFilterType} from "../../domain/inbox/InboxFilterType";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class InboxRepository extends AbstractCompoundFirestoreRepository<MessageRef> implements IndexableFirestoreRepository {

	public by = {
		criterion: {
			sortingTagsContains: (filterType:InboxFilterType) =>
				QueryCriterion.create("sortingTags", WhereFilterOperations.ArrayContains, filterType),
			notTrash: () =>
				QueryCriterion.create(`@sortingTags.${InboxFilterType.trash}`, WhereFilterOperations.EqualTo, false),
			notImportant: ()=>
				QueryCriterion.create(`@sortingTags.${InboxFilterType.important}`, WhereFilterOperations.EqualTo, false),
			notStarred:() =>
				QueryCriterion.create(`@sortingTags.${InboxFilterType.starred}`, WhereFilterOperations.EqualTo, false),
			unread:()=>
				QueryCriterion.create("opened", WhereFilterOperations.EqualTo, null),
		},
		order: {
			sentDescending: ()=> OrderByCriterion.create("sent", OrderByDirections.Descending),
			guidAscending: () => OrderByCriterion.create("guid",  OrderByDirections.Ascending)
		},
		criteria:{
			all: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.sentDescending())
				.orderBy(this.by.order.guidAscending())
				.toCriteria(),
			regular:():QueryCriteria => new QueryCriteriaBuilder()
				.where(this.by.criterion.notTrash())
				.where(this.by.criterion.notImportant())
				.where(this.by.criterion.notStarred())
				.filterFunction(this.by.filters.unread.regular)
				.orderBy(this.by.order.sentDescending()).toCriteria(),
			unread:():QueryCriteria => new QueryCriteriaBuilder()
				.where(this.by.criterion.notTrash())
				.where(this.by.criterion.unread())
				.filterFunction(this.by.filters.unread.anyExceptTrash)
				.orderBy(this.by.order.sentDescending())
				.orderBy(this.by.order.guidAscending())
				.toCriteria(),
			filterType:(filterType:InboxFilterType):QueryCriteria => new QueryCriteriaBuilder()
				.where(this.by.criterion.sortingTagsContains(filterType))
				.orderBy(this.by.order.sentDescending()).toCriteria(),
		},

		filters: {
			unread: {
				any: (item:MessageRef) => /** No Tags and also unread*/
					item.opened == null,
				anyExceptTrash: (item:MessageRef) => /** No Tags and also unread*/
					item.opened == null && !item.sortingTags.includes(InboxFilterType.trash),
				regular: (item:MessageRef) => /** No Tags and also unread*/
					item.opened == null && item.sortingTags.length == 0,
				important:(item:MessageRef) =>
					item.opened == null && item.sortingTags.includes(InboxFilterType.important),
				starred:(item:MessageRef) =>
					item.opened == null && item.sortingTags.includes(InboxFilterType.starred),
				trash:(item:MessageRef) =>
					item.opened == null && item.sortingTags.includes(InboxFilterType.trash)
			},
			byTag: {
				none: (item:MessageRef) => {
					return item.sortingTags.length == 0;/** No Tags and also unread*/
				},
				important:(item:MessageRef) => item.sortingTags.includes(InboxFilterType.important),
				starred:(item:MessageRef) => item.sortingTags.includes(InboxFilterType.starred),
				trash:(item:MessageRef) => item.sortingTags.includes(InboxFilterType.trash)
			}
		},
		counts: ()=> this.by.filters, //counts are the same functions as filters (no need here to be different)
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn(),
			regular:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.regular()).toQueryFn(),
			unread:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.unread()).toQueryFn(),
			sent: ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.sent)).toQueryFn(),
			important: ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.important)).toQueryFn(),
			starred: ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.starred)).toQueryFn(),
			trash: ():QueryFn =>new QueryCriteriaBuilder(this.by.criteria.filterType(InboxFilterType.trash)).toQueryFn()
		},
	};

	public indexes = {
		all:  ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.all()).toFirestoreIndex(InboxRepository.PATH),
		unread:  ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.unread()).toFirestoreIndex(InboxRepository.PATH),
	}

	public counts:IInboxRepositoryCounts = {
		unread: {
			any: -1,
			anyExceptTrash: -1,
			regular: -1,
			important: -1,
			starred: -1,
			trash: -1,
		},
		totals: {
			all: -1,
			byTag: {
				none: -1,
				important: -1,
				starred: -1,
				trash: -1
			}
		}
	};

	public static get PATH():string {
		return "Users/%1$s/Inbox"
	}

	constructor(protected db:AngularFirestore) {
		super(MessageRef, db, InboxRepository.PATH);
	}



	//Temporary count function..do not duplicate
	public watchCounts$(userGuid:string):Observable<IInboxRepositoryCounts> {
		let counts$:Subject<IInboxRepositoryCounts> = new Subject<IInboxRepositoryCounts>();
		//TODO prevent calling more than once per login

		//TODO: We really want to aggregate rather than doing this this way
		this.watchList$(userGuid).subscribe(messages => {

			const count = (paramMessages:MessageRef[], filter:FilterFunction) => {
				return paramMessages.filter(filter).length;
			};
			let unread = this.by.filters.unread;
			let byTag = this.by.filters.byTag;
			let counts = this.counts;
				counts.unread.any = count(messages, unread.any);
				counts.unread.anyExceptTrash = count(messages, unread.anyExceptTrash);
				counts.unread.regular = count(messages, unread.regular);
				counts.unread.important = count(messages, unread.important);
				counts.unread.starred = count(messages, unread.starred);
				counts.unread.trash = count(messages, unread.trash);

				counts.totals.all = messages.length;
				counts.totals.byTag.important = count(messages, byTag.important);
				counts.totals.byTag.starred = count(messages, byTag.important);
				counts.totals.byTag.none = count(messages, byTag.none);
				counts.totals.byTag.trash = count(messages, byTag.trash);


			counts$.next(counts);
		});

		return counts$;
	}
}


export interface IInboxRepositoryCounts {
	unread: {
		any: number,
		anyExceptTrash: number,
		regular: number,
		important: number,
		starred: number,
		trash: number,
	},
	totals: {
		all: number,
		byTag: {
			none: number,
			important: number,
			starred: number,
			trash: number
		}
	}
}
