import {ITextContentObject, RefLocalization} from "../domain/ITextContentObject";
import {SupportedLanguage, SupportedLanguages} from "../domain/SupportedLanguages";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";

export const LocalizeTools = {
	has: (document:ITextContentObject<any>, language:SupportedLanguage, field:string) => {
		return document && document.documentTextContent && document.documentTextContent[language] && document.documentTextContent[language][field];
	},
	ref: (document:RefLocalization, language:SupportedLanguage, key:string) => {
		if(!language) {
			throw new Error("language must be defined");
		}
		//Language requested is available
		if(document && document[key] && document[key][language] != null){
			return document[key][language];
		} else if (document && document[key]) {
			return document[key][SupportedLanguages.oppositeOf(language)];
		}
		//Language requested is not available
		return null;
	},
	document: (document:ITextContentObject<any>, textContentClass:new() => any, language:SupportedLanguage) => {
		if (!language) {
			throw new Error("language must be defined");
		}
		let element:typeof textContentClass = document?.documentTextContent?.[language];
		let defaultElement = document?.documentTextContent?.[SupportedLanguage.en];
		let alternateElement = document?.documentTextContent?.[SupportedLanguage.haw];
		let render:any = new textContentClass();

		let isBlank:Function = (value:string) => {
			if (value == null) {
				return true;
			}
			return (value.trim() == '');
		};

		if (!!element) {
			Object.keys(render).map(property => {
				if (property == "$render") {
					return;
				}
				let value:string = element[property];
				if (defaultElement) {
					let defaultValue:string = defaultElement[property];
					let defaultIsBetter:boolean = isBlank(value) && !isBlank(defaultValue);
					render[property] = defaultIsBetter ? defaultValue : value;
					render.$render[property] = defaultIsBetter ? SupportedLanguage.en : language;
				} else {
					render[property] = value;
				}

			});
		} else {
			Object.keys(render).map(property => {
				if (property == "$render") {
					return;
				}

				if (defaultElement) {
					render[property] = defaultElement[property];
					render.$render[property] = language;
				} else if (alternateElement) {
					language = SupportedLanguage.haw;
					render[property] = alternateElement[property];
					render.$render[property] = language;
				} else {
					console.log("issues with item " + property);
				}
			});
		}
		return render;
	},
	buildDocTextContentSearchFn: (textContentClass:new() => any, searchProperty:string, currentLang:SupportedLanguage) => {
		return (term: string, textContentObject:ITextContentObject<any>):boolean => {//TODO This really needs to be typed...
			if (!currentLang) {
				throw new Error("language must be defined");
			}
			let alternateLang:SupportedLanguage = SupportedLanguages.oppositeOf(currentLang);
			let defaultTextContent:typeof textContentClass = textContentObject?.documentTextContent[currentLang];
			let alternateTextContent:typeof textContentClass = textContentObject?.documentTextContent[alternateLang];
			let nupepafy:(input:string) => string = NupepafyStringUtils.nupepafy;
			let searchTerm:string = nupepafy(term).toLowerCase();
			let nupepafiedTitle:string = null;
			let searchTermFound:boolean = false;

			if (defaultTextContent) {
				nupepafiedTitle = nupepafy(defaultTextContent[searchProperty]).toLowerCase();
				searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
			} else if (alternateTextContent) {
				nupepafiedTitle = nupepafy(alternateTextContent[searchProperty]).toLowerCase();
				searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
			} else {
				console.error("issue with doc text content");
			}
			return searchTermFound;
		}
	},
	buildRefSearchFn: (key:string, currentLang:SupportedLanguage) => {
		return (term: string, ref:any):boolean => {
			if (!currentLang) {
				throw new Error("language must be defined");
			}
			let alternateLang = SupportedLanguages.oppositeOf(currentLang);
			let defaultElement = ref.localization[key][currentLang];
			let alternateElement = ref.localization[key][alternateLang];
			let nupepafy:(input:string) => string = NupepafyStringUtils.nupepafy;
			let searchTerm:string = nupepafy(term).toLowerCase();
			let nupepafiedTitle:string = null;

			let searchTermFound:boolean = false;
			if (defaultElement) {
				nupepafiedTitle = nupepafy(ref.localization[key][currentLang]).toLowerCase();
				searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
			} else if (alternateElement) {
				nupepafiedTitle = nupepafy(ref.localization[key][alternateLang]).toLowerCase();
				searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
			} else {
				console.log("issues with resource ref");
			}
			return searchTermFound;
		}
	}

};
