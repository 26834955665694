import {NewUserRegistration} from "../../domain/user/NewUserRegistration";
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {Converter} from "../ConversionService";
import {isEmpty} from "lodash"
import {Role, RoleType} from "../../domain/user/Role";

//TODO: This is probably no longer relevant
export class NewUserRegistrationToWaihonaUserConverter implements Converter {

    public from:typeof NewUserRegistration;
    public to:typeof WaihonaUser;

    constructor() {
        this.from = NewUserRegistration;
        this.to = WaihonaUser;
    }

    public convert(source:NewUserRegistration):WaihonaUser {

        //Create the target object
        let waihonaUser:WaihonaUser = new WaihonaUser();
            waihonaUser.guid = source.guid;
            waihonaUser.firstName = source.firstName;
            waihonaUser.altFirstName = source.altFirstName;
            waihonaUser.lastName = source.lastName;
            waihonaUser.professionalName = source.professionalName;
            waihonaUser.documentTextContent.en.bio = source.bio;
            waihonaUser.roles.push(new Role(RoleType.registered));

        //If school is set add it to the users organizations array with good starting properties
        if(!isEmpty(source.organizationGuid)) {
           let org:WaihonaUserOrganization = new WaihonaUserOrganization();
               org.currentlyAt = true;
               org.organizationGuid = source.organizationGuid;
               org.organizationName = source.organizationName;
               org.pendingApproval = true; //needs to be kahu/org admin approved (should not yet show)
               org.default = true;
               org.userTitle = source.titleAtOrganization;

            waihonaUser.organizations.push(org);
        }
        return waihonaUser;
    }
}
