import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {classToPlain} from "class-transformer";
import {environment} from "../../environments/environment";
import {NGXLogger} from "ngx-logger";


@Injectable()
export class CommunicationService {

	public message:FeedbackMessage = {
		userGuid: "Anonymous User",
		userFeedback:""
	};

	constructor  (private httpClient:HttpClient, protected logger:NGXLogger) {

	}

	public sendFeedback(userGuid:string, userFeedback:string, logs?:string):void {

		let message:FeedbackMessage = new FeedbackMessage();
		message.userGuid = userGuid;
		message.userFeedback = userFeedback;

		if(logs && logs != "") {
			message.logs = logs;
		}

		this.logger.info("submitting feedback from feedback modal to slack");
		const url:string = environment.zapier.sendFeedback;
		const body:any = JSON.stringify(classToPlain(message));

		this.httpClient.post(
			url,
				body,
			{/*headers, */})
			.subscribe(
				data  => {
					this.logger.info("POST Request is successful ", data);
				},
				error  => {
					this.logger.info("Error", error);
				}
			);
	}

	public sendNewUserNotification(userGuid:string, orgGuid:string, inviteCode:string):void {
		this.logger.info(`sending a notification to slack that a new user, ${userGuid} has just joined with invite code ${inviteCode}.`);

		let message = new NewUserNotificationMessage();
			message.newUserGuid = userGuid;
			message.orgGuid = orgGuid;
			message.usedInviteCode = inviteCode;
			message.environment = environment.env;

		const url:string = environment.zapier.newUserNotification;
		const body:any = JSON.stringify(classToPlain(message));
		this.logger.info(`message: ${body.toString()}`);

		this.httpClient.post(
			url,
			body,
			{/*headers, */})
			.subscribe(
				data  => {
					this.logger.info("POST Request is successful ", data);
				},
				error  => {
					this.logger.info("Error", error);
				}
			);
	}

}

export class FeedbackMessage {
	public userGuid:string;
	public userFeedback:string;
	public logs?:string;
}

export class NewUserNotificationMessage {
	public newUserGuid:string;
	public orgGuid:string;
	public usedInviteCode:string;
	public environment:string;
}
