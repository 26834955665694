<div class="has-feedback">
	<ng-select waih-validation-color
	           [field]="field"
	           [items]="userOptions"
	           [multiple]="showMultiple"
	           bindLabel="fullName"
	           [hideSelected]="true"
	           [placeholder]="placeholder"
	           [hideSelected]="true"
	           [(ngModel)]="value"
	           id="contrib-drop-down">

		<ng-template ng-label-tmp let-item="item" let-clear="clear">
			<div class="option">
				<span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">&times;</span>
				<span class="ng-value-label" style="display: inline-flex; vertical-align: middle;">
                    <avatar-component [userRef]="item" [class]="'select-image'"></avatar-component>
					<span style="align-self: center;">{{item.fullName}}</span>
				</span>
			</div>
		</ng-template>

		<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
			<div class="option">
				<span class="ng-value-label" style="display: inline-flex;">
					<avatar-component [userRef]="item" [class]="'select-image'"></avatar-component>
					<span style="align-self: center;">{{item.fullName}}</span>
				</span>
			</div>
		</ng-template>

	</ng-select>
	<waih-validation-text [field]="this.field"></waih-validation-text>
</div>


