import {IsEmail, IsNotEmpty, IsOptional} from "class-validator";


export class InviteUserMessage {
	@IsNotEmpty()
	public orgGuid:string;
	@IsNotEmpty()
	public userGuid:string;

	@IsEmail({allow_display_name:false, allow_utf8_local_part: true, require_tld: false}, {groups: ["email"]})
	public userEmails:string[];

	public success:boolean = false;

	constructor (orgGuid?:string, userGuidToInvite?:string, emailsToInvite?:string[]) {
		this.orgGuid = orgGuid;
		this.userGuid = userGuidToInvite;
		this.userEmails = emailsToInvite;
	}

}
