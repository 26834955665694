import {NewUserRegistration} from "../domain/user/NewUserRegistration";
import {Organization, OrganizationFlags} from "../domain/organization/Organization";
import {ContactInfo, ContactInfoType} from "../domain/organization/ContactInfo";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../domain/user/WaihonaUserOrganization";
import {Role, RoleType} from "../domain/user/Role";
import {HogwartsLessonStubData} from "./HogwartsLessonStubData";
import {HogwartsReviewStubData} from "./HogwartsReviewStubData";
import {OrganizationRef} from "../domain/organization/OrganizationRef";

export class HogwartsStubData {

	public static uid_dumbledore:string = "albus-dumbledore";
	public static bio_dumbledore:string = "Professor Albus Percival Wulfric Brian Dumbledore, O.M. (First Class), Grand Sorc., D. Wiz., X.J. (sorc.), S. of Mag.Q.[13] (c. Summer[1] 1881[2] – 30 June, 1997[2]) was the Defence Against the Dark Arts Professor, later the Transfiguration Professor, and later Headmaster of Hogwarts School of Witchcraft and Wizardry. Professor Dumbledore also served as Supreme Mugwump of the International Confederation of Wizards (?–1995) and Chief Warlock of the Wizengamot (?-1995; 1996-1997). He was a half-blood, Muggle-supporting wizard, the son of Percival and Kendra Dumbledore, and the elder brother of Aberforth and Ariana. His father died in Azkaban when Dumbledore was young, while his mother and sister were later accidentally killed. His early losses greatly affected him early on, even at his death, but, in turn, made him a better person.\n\n" +
		"While initially unable to directly act against Gellert Grindelwald, due to their past Blood pact, the Ministry's surveillance of him, his lingering feelings of love toward Grindelwald, and his lingering guilt over Ariana's death, Dumbledore sent Newt Scamander to New York to release the Thunderbird Frank, and later sent Newt to Paris to rescue Credence Barebone. After the tragic death of Leta Lestrange in Paris, Dumbledore became determined to contribute helpful advice to the resistance against Grindelwald, notably Newt, Theseus, Tina, Jacob, Yusuf, and Nagini in the Global Wizarding War. Initially unbeknownst to Dumbledore however, Grindelwald intended to use the former's alleged Obscurial relative, Aurelius Dumbledore, as a weapon against him.\n\n" +
		"Following the Global Wizarding War, and end of the For the Greater Good revolution, Dumbledore became most famous for his defeat of Gellert Grindelwald, the discovery of the twelve uses of dragon's blood, and his work on alchemy with Nicolas Flamel.";

	public static uid_severus:string = "severus-snape";
	public static bio_severus:string = "When Severus Snape began his teaching career at Hogwarts in 1981, he initially applied for the position of Defence Against the Dark Arts, but was rejected multiple times.[19] Many students were under the impression that this was because Dumbledore feared Snape might return to his old ways if allowed to teach his favourite subject, but in reality it was because Dumbledore was aware by that point that the job had been jinxed by Voldemort.";

	public static uid_filius:string = "filius-flitwick";
	public static bio_filius:string = "Professor Filius Flitwick (b. 17 October 1958 or earlier) was a part-goblin, part-wizard who attended Hogwarts School of Witchcraft and Wizardry and was sorted into Ravenclaw House,[11] being an intelligent young man and a model student.[12] Some time after his graduation, Flitwick returned to Hogwarts and became the Charms Master, as well as the Head of Ravenclaw House.[7]\n\n" +
		"During the First and Second Wizarding Wars, Flitwick opposed Lord Voldemort. In 1995, he also opposed Dolores Umbridge, the High Inquisitor of Hogwarts, and, in 1997, fought at the Battle of the Astronomy Tower, after which he attended the Funeral of Albus Dumbledore. He also protected the students from the Carrows in the 1997-1998 school year. On 2 May 1998, he partook in the Battle of Hogwarts and defeated many Death Eaters.\n\n" +
		"He survived the war, and likely continued to teach Charms at Hogwarts to future generations of students.";

	public static uid_moody:string = "alastor-moody";
	public static bio_moody:string = "Alastor \"Mad-Eye\" Moody (d. 27 July, 1997) was a pure-blood Scottish wizard, considered to be the most famous Auror of all times.[10] He was also a pivotal member of the Order of the Phoenix during the First and Second Wizarding Wars. Moody served with distinction during the first conflict, gaining a considerable reputation, as well as losing an eye, leg, and part of his nose while fighting the Dark Arts. He was also responsible for many of the inhabited cells in Azkaban, having caught innumerable Dark Wizards. As a result, Moody became overly-cautious and paranoid about his security.\n\n" +
		"In 1994, he accepted Albus Dumbledore's offer to teach Defence Against the Dark Arts at Hogwarts, but was kidnapped by Barty Crouch Jr, who disguised himself as Moody.\n\n" +
		"Later, the real Moody was rescued and became a member of the second Order of the Phoenix. He fought in several battles of the war, including the Battle of the Department of Mysteries in 1996 and the Battle of the Seven Potters in 1997, where he was murdered by Lord Voldemort.\n\n" +
		"His magical eye was later used by Dolores Umbridge to spy on her subordinates in the Muggle-Born Registration Commission, but was later retrieved by Harry Potter during the Infiltration of the Ministry of Magic in 1997, and was given a proper burial by Harry in the forest near where the 422nd Quidditch World Cup had been held three years earlier.";

    public static uid_minerva:string = "minerva-mcgonagall";
    public static bio_minerva:string = "Professor Minerva McGonagall, O.M. (First Class), (b. 4 October, 1935) was a half-blood witch, the only daughter of Muggle Robert McGonagall and witch Isobel Ross. She had two younger brothers, Malcolm and Robert Jr. Minerva was a registered Animagus who attended Hogwarts School of Witchcraft and Wizardry from 1947-1954 and was Sorted into Gryffindor House (but it took the sorting hat five and a half minutes to decide if she was Gryffindor or Ravenclaw, which made her a Hatstall).\n\n" +
        "After her education, Minerva worked for two years at the Ministry of Magic and later returned to Hogwarts, where she became Head of Gryffindor House, Transfiguration professor and concurrently, at differing times, Deputy Headmistress and Headmistress of Hogwarts.\n\n" +
        "McGonagall was also a member of the Order of the Phoenix. In 1995, she opposed Dolores Umbridge, the High Inquisitor of Hogwarts. She also protected the students from Alecto and Amycus Carrow from 1997 until 1998, and as part of this resistance movement, fought in several battles of both wars, including the Battle of the Astronomy Tower and the Battle of Hogwarts (where she led the resistance against Lord Voldemort). She was also the one who confronted Severus Snape right before the Battle of Hogwarts, resulting in him fleeing away from the school.\n\n" +
        "She survived the Second Wizarding War and continued her job as Headmistress, a title she still holds as of Albus Potter's time at Hogwarts.";


    public static uid_filch:string = "argus-filch";
	public static bio_filch:string = "Argus Filch was a Squib and the caretaker of Hogwarts School of Witchcraft and Wizardry since around 1973. Filch usually wandered around the school corridors with his cat Mrs Norris, trying to catch students breaking the school rules and complaining about Peeves the Poltergeist.\n\n" +
		"In 1992, after Mrs Norris was petrified by Salazar Slytherin's Basilisk, Filch was furious; however, his cat was healed by the end of the year. In 1995, when Dolores Umbridge became High Inquisitor at Hogwarts, Filch gladly supported her. The next year, he was responsible for recognising Dark objects that were brought into Hogwarts.\n\n" +
		"Filch helped the students evacuate during the Battle of Hogwarts in 1998 and participated in the battle himself. He kept his job of caretaker after the Second Wizarding War. Filch is also the only one of the Hogwarts staff to stay at Hogwarts Castle during the summer holidays.";

	public static uid_hagrid:string = "rubeus-hagrid";
	public static bio_hagrid:string = "Professor Rubeus Hagrid (b. 6 December 1928[11][12]) is a half-giant wizard, son of Mr Hagrid and the giantess Fridwulfa, and elder half-brother of the giant Grawp.\n\n" +
		"Hagrid attended Hogwarts School of Witchcraft and Wizardry in 1940 and was sorted into Gryffindor house. In Hagrid's third year, he was framed by Tom Riddle for the crime of opening the Chamber of Secrets and using his pet Acromantula to attack several Muggle-born students and eventually killing one of them. Though Hagrid's wand was snapped and he was expelled, he was trained as gamekeeper of Hogwarts and allowed to live on the school grounds at the request of Albus Dumbledore.\n\n" +
		"In 1991, Hagrid was given the task of reintroducing Harry Potter to the wizarding world. In 1993, Hagrid would assume the post of Care of Magical Creatures professor after the retirement of Professor Silvanus Kettleburn. As a member of both the original and the re-instated Order of the Phoenix, Hagrid fought in several battles of the First and the Second Wizarding Wars, including the Battle of Hogwarts in 1998. By 2017, he was still living, and presumably still teaching and tending to his gamekeeping duties at Hogwarts, inviting Harry's son Albus Severus for tea when he joined the school as Harry informed him at the station, just as Hagrid had done to his father, Ron Weasley and Hermione Granger.";

	public static uid_trelawney:string = "sybill-trelawney";
	public static bio_trelawney:string = "Professor Sybill Patricia[6] Trelawney (b. 9 March,[1][3] pre 1962[2]) was a half-blood[7] witch and professor of Divination at Hogwarts School of Witchcraft and Wizardry. She is the great-great-granddaughter of the celebrated Cassandra Trelawney,[8] who was also a Seer.\n\n" +
		"It was Trelawney herself who made the prophecy concerning Voldemort and the one with the power to vanquish him (Voldemort took this to mean Harry Potter) during her job interview with Albus Dumbledore.[9] At the end of the 1993-1994 school year, she accurately predicted the escape of Peter Pettigrew and return of Voldemort.[10] Two school years later, Trelawney was greatly distressed by Dolores Umbridge, who dismissed her and attempted to banish her from Hogwarts Castle, though Dumbledore allowed her to remain at the school.[8] She was greatly offended when she later had to teach alongside the centaur Firenze,[9] and participated in the Battle of Hogwarts, dropping crystal balls on Death Eaters' heads[11] and tending to the wounded and dead along with Padma Patil.[12]\n\n" +
		"Professor Trelawney continued to teach well into the 2010s, continuing her tradition of predicting the deaths of her students.[13] Since Firenze was welcomed back to his herd,[14] it is possible that she taught Divination on her own again.";


	public lessons:HogwartsLessonStubData;
	public review:HogwartsReviewStubData;

	constructor() {
		this.lessons = new HogwartsLessonStubData();
		this.lessons.hogwartsStubData = this;

		this.review = new HogwartsReviewStubData();
		this.review.hogwartsStubData = this;

	}

	public get dumbledoreRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_dumbledore, "Albus", "Dumbledore");
	}
	public get severusRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_severus, "Severus", "Snape");
	}
	public get filiusRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_filius, "Filius",  "Flitwick");
	}
    public get minervaRef():WaihonaUserRef {
        return WaihonaUserRef.create(HogwartsStubData.uid_minerva, "Minerva",  "McGonagall");
    }
	public get alastorMoodyRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_moody, "Alastor", "Moody");
	}
	public get argusFilchRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_filch, "Argus", "Filch");
	}
	public get hagridRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_hagrid, "Rubeus", "Hagrid");
	}
	public get trelawneyRef():WaihonaUserRef {
		return WaihonaUserRef.create(HogwartsStubData.uid_trelawney, "Sybill", "Trelawney");
	}

	public get dumbledoreWaihonaUser():WaihonaUser {

		//Create the target object
		let dumbledore:WaihonaUser = new WaihonaUser();
			dumbledore.guid = HogwartsStubData.uid_dumbledore;
			dumbledore.professionalName = "Albus";
			dumbledore.firstName = "Albus";
			dumbledore.lastName = "Dumbledore";
			dumbledore.documentTextContent.en.bio = HogwartsStubData.bio_dumbledore;
			dumbledore.roles.push(new Role(RoleType.registered));
			dumbledore.roles.push(new Role(RoleType.organizationAdmin, "hogwarts"));
			dumbledore.roles.push(new Role(RoleType.reviewer, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "hogwarts";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Headmaster";
			org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

			dumbledore.organizations.push(org);

		return dumbledore;
	}
	public get severusWaihonaUser():WaihonaUser {

		//Create the target object
		let severus:WaihonaUser = new WaihonaUser();
			severus.guid = HogwartsStubData.uid_severus;
			severus.professionalName = "Severus Snape";
			severus.firstName = "Severus";
			severus.lastName = "Snape";
			severus.documentTextContent.en.bio = HogwartsStubData.bio_severus;
			severus.roles.push(new Role(RoleType.registered));
			severus.roles.push(new Role(RoleType.reviewer, "hogwarts"));
			severus.roles.push(new Role(RoleType.contributor, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "hogwarts";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Professor of the Dark Arts";
			org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

		severus.organizations.push(org);

		return severus;
	}
	public get filiusWaihonaUser():WaihonaUser {
		let filius:WaihonaUser = new WaihonaUser();
			filius.guid = HogwartsStubData.uid_filius;
			filius.firstName = "Filius";
			filius.lastName = "Flitwick";
			filius.professionalName = "Filius Flitwick";
			filius.documentTextContent.en.bio = HogwartsStubData.bio_filius;
			filius.roles.push(new Role(RoleType.registered));
			filius.roles.push(new Role(RoleType.contributor, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "hogwarts";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Professor of Charms";
			org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

		filius.organizations.push(org);

		return filius;
	}
    public get minervaWaihonaUser():WaihonaUser {
        let minerva: WaihonaUser = new WaihonaUser();
			minerva.guid = HogwartsStubData.uid_minerva;
			minerva.firstName = "Minerva";
			minerva.lastName = "McGonagall";
			minerva.professionalName = "Minerva McGonagall";
			minerva.documentTextContent.en.bio = HogwartsStubData.bio_minerva;
			minerva.roles.push(new Role(RoleType.registered));
			minerva.roles.push(new Role(RoleType.reviewer, 'hogwarts'));
			minerva.roles.push(new Role(RoleType.contributor, "hogwarts"));

        let org: WaihonaUserOrganization = new WaihonaUserOrganization();
        	org.organizationName = "Hogwarts School of Witchcraft and Wizardry";
			org.organizationGuid = "hogwarts";
			org.currentlyAt = true;
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Professor of Transformations";

		minerva.organizations.push(org);

        return minerva;
    }
	public get moodyWaihonaUser():WaihonaUser {
		let moody:WaihonaUser = new WaihonaUser();
			moody.guid = HogwartsStubData.uid_moody;
			moody.firstName = "Alastor";
			moody.lastName = "Moody";
			moody.professionalName = "Alastor Moody";
			moody.documentTextContent.en.bio = HogwartsStubData.bio_moody;
			moody.roles.push(new Role(RoleType.registered));
			moody.roles.push(new Role(RoleType.contributor, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
		org.currentlyAt = true;
		org.organizationGuid = "hogwarts";
		org.pendingApproval = false;
		org.default = true;
		org.userTitle = "Defense Against the Dark Arts Teacher";
		org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

		moody.organizations.push(org);

		return moody;
	}
	public get filchWaihonaUser():WaihonaUser {
		let filch:WaihonaUser = new WaihonaUser();
			filch.guid = HogwartsStubData.uid_filch;
			filch.firstName = "Argus";
			filch.lastName = "Filch";
			filch.professionalName = "Argus Filch";
			filch.documentTextContent.en.bio = HogwartsStubData.bio_filch;
			filch.roles.push(new Role(RoleType.registered));
			filch.roles.push(new Role(RoleType.contributor, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "hogwarts";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Caretaker";
			org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

        filch.organizations.push(org);
		return filch;
	}
	public get hagridWaihonaUser():WaihonaUser {
		let hagrid:WaihonaUser = new WaihonaUser();
			hagrid.guid = HogwartsStubData.uid_hagrid;
			hagrid.firstName = "Rubeus";
			hagrid.lastName = "Hagrid";
			hagrid.professionalName = "Rubeus Hagrid";
			hagrid.documentTextContent.en.bio = HogwartsStubData.bio_hagrid;
			hagrid.roles.push(new Role(RoleType.registered));
			hagrid.roles.push(new Role(RoleType.reviewer, "hogwarts"));
			hagrid.roles.push(new Role(RoleType.contributor, "hogwarts"));

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "hogwarts";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Professor of Magical Creatures";
			org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

		hagrid.organizations.push(org);
		return hagrid;
	}

	public get trelawneyWaihonaUser():WaihonaUser {
		let trelawney:WaihonaUser = new WaihonaUser();
			trelawney.guid = HogwartsStubData.uid_trelawney;
			trelawney.firstName = "Sybill";
			trelawney.lastName = "Trelawney";
			trelawney.professionalName = "Sybill Trelawney";
			trelawney.documentTextContent.en.bio = HogwartsStubData.bio_trelawney;
			trelawney.roles.push(new Role(RoleType.registered));
			trelawney.roles.push(new Role(RoleType.frameworkAdmin));
			trelawney.roles.push(new Role(RoleType.contributor, "hogwarts"));

			let org:WaihonaUserOrganization = new WaihonaUserOrganization();
				org.currentlyAt = true;
				org.organizationGuid = "hogwarts";
				org.pendingApproval = false;
				org.default = true;
				org.userTitle = "Professor of Divination";
				org.organizationName = "Hogwarts School of Witchcraft and Wizardry";

			trelawney.organizations.push(org);
		return trelawney;
	}


	public getHogwartsOrganization():Organization {
		let hogwarts:Organization = new Organization();
		hogwarts.guid = "hogwarts";
		hogwarts.flags = [OrganizationFlags.school, OrganizationFlags.kanaeokanaMember];
		hogwarts.title = "Hogwarts School of Witchcraft and Wizardry";
		//hogwarts.logo = "/assets/img/sampleImages/hogwarts.png";
		hogwarts.documentTextContent.en.description = "According to the novels, admission to Hogwarts is selective, in that children who show magical ability will automatically gain a place, and squibs cannot attend the school as students (though they can work there in other roles, as Argus Filch does). A magical quill at Hogwarts detects the birth of magical children and writes their names into a large parchment book, but there is no admission test because \"you are either magical or you are not.\" Every year, a teacher checks this book and sends a letter to the children who are turning eleven. Acceptance or refusal of a place at Hogwarts must be posted by 31 July. The letter also contains a list of supplies like spell books, uniform, and other things that the student will need. The prospective student is expected to buy all the necessary materials, normally from shops in Diagon Alley, a concealed street near Charing Cross Road in London that can be found behind the wizarding pub, The Leaky Cauldron. Students who cannot afford their supplies can receive financial aid from the school.\n\n" +
			"Letters to Muggle-born witches and wizards, who may not be aware of their powers and are unfamiliar with the concealed wizarding world, are delivered in person by a member of Hogwarts staff, who then explains to the parents or guardians about magical society, and reassures them regarding this news.\n" +
			"Though the school is in Great Britain, its catchment area is the wider British Isles.";
		hogwarts.contactInfo = [
			new ContactInfo(ContactInfoType.city, "Hogsmeade"),
			new ContactInfo(ContactInfoType.island, "Scotland"),
			new ContactInfo(ContactInfoType.leader, "Albus Dumbledore"),
			new ContactInfo(ContactInfoType.website, "www.hogwarts.com"),
			new ContactInfo(ContactInfoType.phone, "+44 7911 12345"),
			new ContactInfo(ContactInfoType.email, "muggleinfo@hogwarts.com")
		];
		hogwarts.enabled = true;

		hogwarts.admins = [this.dumbledoreRef];
		hogwarts.kahu = [this.severusRef, this.minervaRef, this.dumbledoreRef];
		hogwarts.contributors = [this.severusRef, this.filiusRef, this.minervaRef, this.alastorMoodyRef, this.argusFilchRef, this.hagridRef, this.trelawneyRef];

		//TODO
		hogwarts.frameworks = [];
		hogwarts.invites = [];

		return hogwarts;
	}

	/** Hogwarts Organization Ref */
	public getHogwartsOrganizationRef():OrganizationRef {
		let orgReference:OrganizationRef = new OrganizationRef();
			orgReference.title = "Hogwarts School of Witchcraft and Wizardry";
			orgReference.guid =  "hogwarts";

		return orgReference;
	}

	public getHogwartsWaihonaUsers():Array<WaihonaUser> {
		return [this.dumbledoreWaihonaUser, this.severusWaihonaUser, this.minervaWaihonaUser, this.trelawneyWaihonaUser, this.filiusWaihonaUser, this.moodyWaihonaUser, this.filchWaihonaUser, this.hagridWaihonaUser]
	}

	public getHogwartsNewUserRegistrations():NewUserRegistration[] {
		let hogwarts:Array<NewUserRegistration> = [];

		let severus:NewUserRegistration = new NewUserRegistration();
		severus.organizationGuid = "hogwarts";
		severus.organizationName = "Hogwarts School of Witchcraft and Wizardry";

		severus.titleAtOrganization = "Professor of the Dark Arts";
		severus.firstName = "Severus";
		severus.lastName = "Snape";
		severus.professionalName = "Severus Snape";
		severus.password = "password";
		severus.confirmPassword = "password";
		severus.email = "sesnape@hogwarts.edu";
		severus.bio = HogwartsStubData.bio_severus;

		hogwarts.push(severus);

		let moody:NewUserRegistration = new NewUserRegistration();
			moody.organizationGuid = "hogwarts";
			moody.organizationName = "Hogwarts School of Witchcraft and Wizardry";
			moody.titleAtOrganization = "Defence Against the Dark Arts Teacher";
			moody.firstName = "Alastor";
			moody.lastName = "Moody";
			moody.professionalName = "Alastor Moody";
			moody.password = "password";
			moody.confirmPassword = "password";
			moody.email = "almoody@hogwarts.edu";
			moody.bio = HogwartsStubData.bio_moody;

		hogwarts.push(moody);

        let minerva:NewUserRegistration = new NewUserRegistration();
			minerva.organizationGuid = "hogwarts";
			minerva.organizationName = "Hogwarts School of Witchcraft and Wizardry";
        	minerva.titleAtOrganization = "Transformations Professor";
			minerva.firstName = "Minerva";
			minerva.lastName = "McGonagall";
			minerva.professionalName = "Minerva McGonagall";
			minerva.password = "password";
			minerva.confirmPassword = "password";
			minerva.email = "mimcgonagall@hogwarts.edu";
			minerva.bio = HogwartsStubData.bio_minerva;

        hogwarts.push(minerva);

		let filius:NewUserRegistration = new NewUserRegistration();
			filius.organizationGuid = "hogwarts";
			filius.organizationName = "Hogwarts School of Witchcraft and Wizardry";
			filius.titleAtOrganization = "Charms";
			filius.firstName = "Filius";
			filius.lastName = "Flitwick";
			filius.professionalName = "Filius Flitwick";
			filius.password = "password";
			filius.confirmPassword = "password";
			filius.email = "fiflitwick@hogwarts.edu";
			filius.bio = HogwartsStubData.bio_filius;

		hogwarts.push(filius);

		let filch:NewUserRegistration = new NewUserRegistration();
			filch.organizationGuid = "hogwarts";
			filch.organizationName = "Hogwarts School of Witchcraft and Wizardry";
			filch.titleAtOrganization = "Caretaker";
			filch.firstName = "Argus";
			filch.lastName = "Filch";
			filch.professionalName = "Argus Filch";
			filch.password = "password";
			filch.confirmPassword = "password";
			filch.email = "arfilch@hogwarts.edu";
			filch.bio = HogwartsStubData.bio_filch;

		hogwarts.push(filch);

		let albus:NewUserRegistration = new NewUserRegistration();
			albus.organizationGuid = "hogwarts";
			albus.organizationName = "Hogwarts School of Witchcraft and Wizardry";

			albus.titleAtOrganization = "Headmaster";
			albus.firstName = "Albus";
			albus.lastName = "Dumbledore";
			albus.professionalName = "Albus Dumbledore";
			albus.password = "password";
			albus.confirmPassword = "password";
			albus.email = "aldumbledore@hogwarts.edu";
			albus.bio = HogwartsStubData.bio_dumbledore;

		hogwarts.push(albus);


		let hagrid:NewUserRegistration = new NewUserRegistration();
			hagrid.titleAtOrganization = "Professor of Magical Creatures";
			hagrid.organizationGuid = "hogwarts";
			hagrid.organizationName = "Hogwarts School of Witchcraft and Wizardry";

			hagrid.firstName = "Rubeus";
			hagrid.lastName = "Hagrid";
			hagrid.professionalName = "Rubeus Hagrid";
			hagrid.password = "password";
			hagrid.confirmPassword = "password";
			hagrid.email = "ruhagrid@hogwarts.edu";
			hagrid.bio = HogwartsStubData.bio_hagrid;

		hogwarts.push(hagrid);

		let trelawney:NewUserRegistration = new NewUserRegistration();
			trelawney.titleAtOrganization = "Professor of Divination";
			trelawney.organizationGuid = "hogwarts";
			trelawney.organizationName = "Hogwarts School of Witchcraft and Wizardry";

			trelawney.firstName = "Sybill";
			trelawney.lastName = "Trelawney";
			trelawney.professionalName = "Sybill Trelawney";
			trelawney.password = "password";
			trelawney.confirmPassword = "password";
			trelawney.email = "sytrelawney@hogwarts.edu";
			trelawney.bio = HogwartsStubData.bio_trelawney;

		hogwarts.push(trelawney);

		return hogwarts;

	}

	public getAvatarUrlForHogwarts(guid:string):string {
		return `./assets/img/avatars/hogwarts/${guid}.jpg`;
	}



}
