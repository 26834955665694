import {classToPlain, Exclude} from "class-transformer";
import {RegisterClazz} from "../RegisterType";
import {AppHeaderAlert} from "./AppHeaderAlert";


@RegisterClazz(SiteConfiguration, "Config")
export class SiteConfiguration {
	@Exclude({ toPlainOnly: true })
	public guid:string = "site-config";

	public version:string = "";
	public slackUrl:string = "";

	public environment:string = null;
	public inviteCodesRequiredForRegistration:boolean = true;
	public loginRequiredForSiteAccess:boolean = true;
	public siteIsUndergoingMaintenance:boolean = false;
	public messages:AppHeaderAlert = null;

	public agreement:{mode:AgreementMode} = {
		mode: AgreementMode.scroll_first_then_check
	};

	public links = {
		contributor_agreement: '', //https://docs.google.com/document/d/1kIz7MVcsP_TsCO2lgaPERCXVEYo-9NWjJzuruSeww2s/export?format=pdf&attachment=false
		contact_form: "",
		update_document: "", //https://docs.google.com/document/d/1reQw8KWRJUDNF4yQKdpWjRi7sopqpD5dHK6P6V7ztgQ/export?format=pdf&attachment=false
		org_registration: "", //https://docs.google.com/forms/d/e/1FAIpQLSdoWFTV4s1Ue1lDnZpxq-bh05gEiO4e7xDC60UDwRQXCFB4rA/viewform?usp=sf_link
	};

	public toString():string {
		return JSON.stringify(classToPlain(this), null, 2);
	}
}

export enum AgreementMode {
	none='none',
	checkbox_only='checkbox_only',
	scroll_first_then_check='scroll_first_then_check',
}
