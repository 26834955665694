import {RegisterClazz} from "../RegisterType";
import {IsIn, IsNotEmpty} from "class-validator";
import {CollectionOrderType, CollectionOwnerType, CollectionType, CollectionVisibilityType} from "./CollectionConfigurationEnums";

@RegisterClazz(CollectionConfiguration)
export class CollectionConfiguration {

	@IsIn(Object.values(CollectionType))
	@IsNotEmpty()
	public collectionType:CollectionType = CollectionType.collection; //enum

	@IsIn(Object.values(CollectionOrderType))
	@IsNotEmpty()
	public orderType:CollectionOrderType = CollectionOrderType.alphabetical; //enum

	@IsIn(Object.values(CollectionOwnerType))
	@IsNotEmpty()
	public ownerType:CollectionOwnerType = CollectionOwnerType.user; //enum

	@IsIn(Object.values(CollectionVisibilityType))
	@IsNotEmpty()
	public visibilityType:CollectionVisibilityType = CollectionVisibilityType.privateToUser; //enum

	public hasImage:boolean = false;

}


