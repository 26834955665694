import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Badge} from "../../domain/badges/Badge";
import {BadgeRef} from "../../domain/badges/BadgeRef";
import {ConversionService} from "../ConversionService";
import {LocalizationService} from "../LocalizationService";
import {RefCachingService} from "../RefCachingService";

@Injectable()
export class BadgesRepository extends AbstractFirestoreRepository<Badge, BadgeRef> {

	constructor (protected db:AngularFirestore,
				 protected resolver:RefCachingService,
				 protected conversionService:ConversionService,
				 protected localizationService:LocalizationService
				 ) {
		super(Badge, db,
			{
				type: db.collection("Badges"),
				ref: db.collection("Refs/badges/All")
			}, "guid", conversionService, BadgeRef);
			resolver.registerSource(this);
	}

}
