import {Component, OnInit, ViewChild} from '@angular/core';
import {ValidationErrorService} from "../../../services/common/ValidationErrorService";
import {Localization} from "../../../data/Localization";
import {BehaviorSubject} from "rxjs";
import {IModal} from "../IModal";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {ConversionService} from "../../../services/ConversionService";
import {NotificationService, ToastType} from "../../../services/common/NotificationService";
import {ValidationException} from "../../../services/exceptions/ValidationException";
import {classToPlain, plainToClass} from "class-transformer";
import {ClipboardService} from "ngx-clipboard";
import {Framework} from "../../../domain/frameworks/Framework";
import {FrameworkEditComponent} from "../../frameworks/single/framework-edit/framework-edit.component";


export interface IFrameworkImportExportModalInput {
	framework:Framework;
}

@Component({
	selector:'app-framework-import-export-modal',
	templateUrl:'./framework-import-export-modal.component.html',
	styleUrls:['./framework-import-export-modal.component.scss'],
	providers:[ValidationErrorService]
})
export class FrameworkImportExportModalComponent implements OnInit, IModal {

	@ViewChild('modal')
	public modal:BsModalRef;

	private cbContext = Localization.template.modal.framework.import_and_export;

	public component:FrameworkEditComponent;

	public content:IFrameworkImportExportModalInput;
	public errors:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
	public warnings:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
	public importData:string = "";

	public framework:Framework;

	public CB = {
		title:this.cbContext.title,
		cancel:Localization.template.buttons.cancel,
		importButton:this.cbContext.buttons.import,
		exportButton:this.cbContext.buttons.export,
		toast_export_success:this.cbContext.toast.export.success,
		toast_import_success:this.cbContext.toast.import.success,
		toast_import_failure:this.cbContext.toast.import.fail
	};

	constructor(private bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				private conversionService:ConversionService,
				private validationErrorService:ValidationErrorService,
				private clipboardService:ClipboardService,
				private notificationService:NotificationService) {
	}

	public ngOnInit():void {

	}

	public onProperties(properties:IFrameworkImportExportModalInput):void {
		this.content = properties;
		this.framework = properties.framework;
	}


	public onCancel() {
		console.info(`user clicked on cancel button`);
		this.bsModalRef.hide();

	}

	 public onExportClick():void {
		 let plain:{} = classToPlain(this.framework);
		 let plainString:string = JSON.stringify(plain, null, 2);
		 this.clipboardService.copy(plainString);
		 console.info("Export data is " + plainString);
		 this.notificationService.displayToast(this.CB.toast_export_success, ToastType.success);
		 this.bsModalRef.hide();
	 }

	public onImportClick() {
		console.info(`user clicked on submit button`);

		// clear validation errors and warnings
		this.validationErrorService.updateErrors([]);
		this.validationErrorService.updateWarnings([]);

		//try save contribution and return toasts in either case todo: add toasts!
		try {
			//To Do: Validate
			let importClass:Framework = plainToClass(Framework, JSON.parse(this.importData));
			this.validationErrorService.validate(importClass);
			console.log("No Errors!");

			this.component.framework = importClass;

			this.notificationService.displayToast(this.CB.toast_import_success, ToastType.success);
			this.bsModalRef.hide();

		} catch (ex) {
			if (ex instanceof ValidationException) {
				console.info(`validation errors caught`);
				this.validationErrorService.updateErrors(ex.errors);
			} else {
				console.error(ex);
			}
			this.notificationService.displayToast(this.CB.toast_import_failure, ToastType.error)

		}
	}

}
