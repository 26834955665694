import {Injectable} from "@angular/core";

// this method of getting metadata using getFromContainer works with class-validator ver 11. ver 12+ uses a more convenient method (getMetadataStorage), but has a bug, where the "type" property is always set to "customValidator", making it hard to pull out the info you need.
import {getFromContainer, getMetadataStorage, MetadataStorage} from "class-validator";
import {ValidationMetadata} from "class-validator/types/metadata/ValidationMetadata";

@Injectable({
	providedIn: 'root',
} as any)
/** Exposes validation metadata for domain object */
export class ValidationMetadataService {

	/**
	 * Returns the validation metadata for the given target
	 * @param target the class constructor to get metadata for
	 */
	public getValidationMetadata(target:Function):ValidationMetadata[] {
		return getMetadataStorage()
			.getTargetValidationMetadatas(target, target.name);
	}

	public getMaxLengths(target:Function):IFieldMaxLengthMap<any> {
		let maxLengths:IFieldMaxLengthMap<any> = {};
		this.getValidationMetadata(target)
			.filter((metadata:ValidationMetadata) => metadata.constraints?.length > 0 && typeof metadata.constraints[0] === "number").forEach((metadata:ValidationMetadata) => {
			// this isn't the best method, but right now grabbing the largest number for each property, because from class-validator version 12+ can't rely on metadata.type value. Used to have "maxLength" but now is "customValidation"
			if (!maxLengths[metadata.propertyName]) {
				maxLengths[metadata.propertyName] = metadata.constraints[0]
			} else {
				if (metadata.constraints[0] > maxLengths[metadata.propertyName]) {
					maxLengths[metadata.propertyName] = metadata.constraints[0]
				}
			}
		});
		return maxLengths;
	}

	// return plain text stripped of html tags
	public getPlainText(str:string):string {
		if (!str) {
			return "";
		} else {
			let domParser = new DOMParser();
			let doc:Document = domParser.parseFromString(str, 'text/html');
			return doc.documentElement.innerText;
		}
	}
}

export type IFieldMaxLengthMap<T> = {
	// this type definition would allow better code hinting, but "as" keyword is not available in index properties until Typescript 4, I think. We have to use < 3.9 to stay compatible with our current angular compiler.
	// [P in keyof T as (T[P] extends string ? P : never)]:number
	// [P in keyof T as P]:number
	[P in keyof T]:number
}
