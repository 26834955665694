import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule, routes} from "./app.routing";
import {RouterModule} from "@angular/router";
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {AuthService} from "./services/AuthService";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireFunctionsModule} from "@angular/fire/functions";
import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire";
import {AngularFirestoreModule} from "@angular/fire/firestore";  //Dropped the FirestoreSettingsToken DI token in favor of SETTINGS,  https://github.com/angular/angularfire/releases
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {CookieService} from "ngx-cookie-service";
import {FrameworksModule} from "./areas/frameworks/frameworks.module";
import {ToasterModule} from "angular2-toaster";

import {AccordionModule} from "ngx-bootstrap/accordion";
import {AlertModule} from "ngx-bootstrap/alert";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {DatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule} from "ngx-bootstrap/modal";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {PopoverModule} from "ngx-bootstrap/popover";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {TooltipModule} from "ngx-bootstrap/tooltip";

import {TabsModule} from "ngx-bootstrap/tabs";
import {ChartsModule} from "ng2-charts/ng2-charts";
import {TreeModule} from 'angular-tree-component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {CallbackComponent} from "./areas/auth/callback/callback.component";
import {LogoutComponent} from "./areas/auth/logout/logout.component";

import {FormsModule} from "@angular/forms";
import {SharedModule} from "./areas/shared/shared.module";
import {NAV_DROPDOWN_DIRECTIVES} from "./directives/nav-dropdown";
import {ReplaceDirective} from "./directives/replace";
import {SIDEBAR_TOGGLE_DIRECTIVES} from "./directives/sidebar";
import {FrameworkService} from "./services/FrameworkService";

import {ConversionService} from "./services/ConversionService";
import {RoleService} from "./services/common/RoleService";
import {WaihonaUserFunctions} from "./services/functions/WaihonaUserFunctions";
import {OrganizationFunctions} from "./services/functions/OrganizationFunctions";
import {WaihonaUserRepository} from "./services/repository/WaihonaUserRepository";
import {UploadService} from "./services/UploadService";
import {ResourceSubmissionService} from "./services/ResourceSubmissionService";
import {NotificationService} from "./services/common/NotificationService";

import {NgbCollapseModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {BadgesRepository} from "./services/repository/BadgesRepository";
import {HttpClientModule} from "@angular/common/http";
import {BadgesModule} from "./areas/badges/badges.module";
import {CancelResourceSubmissionModalComponent} from "./areas/modals/cancel-resource-submission-modal/cancel-resource-submission-modal.component";
import {CommunicationService} from "./services/CommunicationService";
import {ReviewCommentModalComponent} from "./areas/modals/review-comment-modal/review-comment-modal.component";
import {FeedbackModalComponent} from "./areas/modals/feedback-modal/feedback-modal.component";
import {AddToCollectionModalComponent} from "./areas/modals/add-to-collection-modal/add-to-collection-modal.component";
import {LocalizationService} from "./services/LocalizationService";
import {GetStartedModalComponent} from './areas/modals/get-started-modal/get-started-modal.component';
import {VerifyUserModalComponent} from './areas/modals/verify-user-modal/verify-user-modal.component';
import {SetupComponent} from "./areas/admin/setup/setup.component";
import {AuthenticatedUsersOnlyModalComponent} from "./areas/modals/authenticated-users-only-modal/authenticated-users-only-modal.component";
import {SiteConfigurationService} from "./services/SiteConfigurationService";
import {BadgeService} from "./services/BadgeService";
import {AinaService} from "./services/AinaService";
import {QuillModule} from "ngx-quill";
import {UrlService} from "./services/UrlService";
import {NotAuthorizedAccessResourceModalComponent} from "./areas/modals/not-authorized-access-resource-modal/not-authorized-access-resource-modal.component";
import {SiteUndergoingMaintenanceModalComponent} from './areas/modals/site-undergoing-maintenance-modal/site-undergoing-maintenance-modal.component';
import {PathService} from "./services/PathService";
import {FanContributionModalComponent} from "./areas/modals/fan-contribution-modal/fan-contribution-modal.component";
import {InvitationCodeRepository} from "./services/repository/InvitationCodeRepository";
import {InvitationCodeService} from "./services/InvitationCodeService";
import {FileTypeService} from "./services/FileTypeService";
import {CommonDirectivesModule} from "./areas/shared/CommonDirectivesModule";
import {DefaultStorageBucket} from "./services/storage/DefaultStorageBucket";
import {AuthFunctions} from "./services/functions/AuthFunctions";
import {FanContributionService} from "./services/FanContributionService";
import {FanContributionRepository} from "./services/repository/FanContributionRepository";
import {AnalyticsService} from "./services/AnalyticsService";
import {AnalyticsData} from "./services/data/AnalyticsData";
import {ScrollableDirective} from './directives/scrollable/scrollable.directive';
import {InboxRepository} from "./services/repository/InboxRepository";
import {SentRepository} from "./services/repository/SentRepository";
import {InboxService} from "./services/InboxService";
import {LogMonitoringService} from "./services/logging/LogMonitoringService";
import {NavigatingComponentDeactivationGuard} from "./guards/NavigatingComponentDeactivationGuard";
import {ResourceSubmissionDraftGuard} from "./guards/ResourceSubmissionDraftGuard";
import {RoleGuard} from "./guards/RoleGuard";
import {SiteIsUndergoingMaintenanceModalBlockGuard} from "./guards/SiteIsUndergoingMaintenanceModalBlockGuard";
import {AuthenticatedUsersOnlyModalBlockGuard} from "./guards/AuthenticatedUsersOnlyModalBlockGuard";
import {AuthenticationCallbackGuard} from "./guards/AuthenticationCallbackGuard";
import {LoggedInGuard} from "./guards/LoggedInGuard";
import {LogoComponent} from "./areas/home/logo/logo.component";
import {LottieModule} from "ngx-lottie";
import player from 'lottie-web';
import {BirdComponent} from "./areas/home/bird/bird.component";
import {ContentBlockRepository} from "./services/repository/content-block.repository";

import {ComponentCommonModule} from "./areas/common/component-common.module";
import {PipesModule} from "./pipes/pipes.module";
import {LocalizeDirective} from "./directives/localize/LocalizeDirective";
import {AngularStickyThingsModule} from "@w11k/angular-sticky-things";
import { NavigateAwayConfirmationModalComponent } from './areas/modals/navigate-away-confirmation-modal/navigate-away-confirmation-modal.component';
import {InviteUserByEmailModalComponent} from './areas/modals/invite-user-by-email-modal/invite-user-by-email-modal.component';
import { HomeComponent } from './areas/home/home.component';
import {AinaRepository} from "./services/repository/AinaRepository";
import { ResourceFrameworkSpecsModalComponent } from './areas/modals/resource-framework-specs-modal/resource-framework-specs-modal.component';
import {ResourcesModule} from "./areas/resources/resources.module";
import {SearchFrameworkSpecsModalComponent} from "./areas/modals/search-framework-specs-modal/search-framework-specs-modal.component";
import {FrameworkImportExportModalComponent} from "./areas/modals/framework-import-export-modal/framework-import-export-modal.component";
import {LocalUserSettingsRepository} from "./services/repository/LocalUserSettingsRepository";
import {RefCachingService} from "./services/RefCachingService";
import {HttpHelperUtils} from "./services/HttpHelperUtils";
import {RoutingService} from "./services/RoutingService";
import {CollectionsModule} from "./areas/collections/collections.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {SortablejsModule} from "ngx-sortablejs";

export function playerFactory() {
	return player;
}

const APP_STANDARD_IMPORTS = [
	AppRoutingModule,
];

const FIREBASE_MODULES = [
	AngularFireModule.initializeApp(environment.firebase),
	AngularFirestoreModule,
	AngularFireDatabaseModule,
	AngularFireAuthModule,
	AngularFireStorageModule,
	// AngularFirestoreModule.enablePersistence(),
	AngularFireFunctionsModule,
];
export const APP_DIRECTIVES = [
	NAV_DROPDOWN_DIRECTIVES,
	ReplaceDirective,
	SIDEBAR_TOGGLE_DIRECTIVES
];

export const SERVICES = [
	SiteConfigurationService,
	CommunicationService,
	FrameworkService,
	AuthService,
	CookieService,
	ConversionService,
	UploadService,
	RoleService,
	NotificationService,
	LocalizationService,
	AinaService,
	BadgeService,
	InvitationCodeService,
	AnalyticsData,
	ResourceSubmissionService,
	FileTypeService,
	UrlService,
	PathService,
	AnalyticsService,
	InboxService,
	FanContributionService
];

const WAIHONA_MODULES = [
	FrameworksModule,
	BadgesModule,
	SharedModule,
	PipesModule,
	ResourcesModule,
	ComponentCommonModule,
	CollectionsModule
];

const APP_COMPONENTS = [
	HomeComponent,
	LogoComponent,
];


export const RouterModuleRef = RouterModule.forRoot(routes, {
	// using this to always go to the top of pages when navigating, if there is no state to restore.
	scrollPositionRestoration: 'top',
	// anchorScrolling: 'enabled',
	scrollOffset: [0, 60]
});

const APP_ROUTES = [
	AppRoutingModule,
	RouterModuleRef

];

const GUARDS = [
	NavigatingComponentDeactivationGuard,
	ResourceSubmissionDraftGuard,
	RoleGuard,
	SiteIsUndergoingMaintenanceModalBlockGuard,
	AuthenticatedUsersOnlyModalBlockGuard,
	AuthenticationCallbackGuard,
	LoggedInGuard
];

const MODALS = [
	AuthenticatedUsersOnlyModalComponent,
	CancelResourceSubmissionModalComponent,
	ReviewCommentModalComponent,
	GetStartedModalComponent,
	VerifyUserModalComponent,
	NotAuthorizedAccessResourceModalComponent,
	SiteUndergoingMaintenanceModalComponent,
	FanContributionModalComponent,
	NavigateAwayConfirmationModalComponent,
	InviteUserByEmailModalComponent,
	ResourceFrameworkSpecsModalComponent,
	SearchFrameworkSpecsModalComponent,
	FrameworkImportExportModalComponent,
	FeedbackModalComponent,
	AddToCollectionModalComponent
];

@NgModule({
	declarations: [
		AppComponent,
		CallbackComponent,
		LogoutComponent,
		CancelResourceSubmissionModalComponent,
		ReviewCommentModalComponent,
		SetupComponent,
		LocalizeDirective,
		...MODALS,

		...[/** COMPONENTS **/
			LogoComponent,
			HomeComponent,
			BirdComponent
		],
		...APP_COMPONENTS,
		...APP_DIRECTIVES,
		ScrollableDirective,
		...[/* PIPES */
		],
	],
	imports: [
		LottieModule.forRoot({player: playerFactory, useCache: false}),
		CommonDirectivesModule,
		CommonModule,
		HttpClientModule,
		NgbModule,
		FormsModule,
		QuillModule.forRoot(),
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),

		SharedModule,
		//CarouselModule.forRoot(),
		CollapseModule.forRoot(),
		PaginationModule.forRoot(),
		PopoverModule.forRoot(),
		ProgressbarModule.forRoot(),
		TooltipModule.forRoot(),
		SortablejsModule.forRoot({ animation: 150 }),

		ModalModule.forRoot(),
		AlertModule.forRoot(),
		TreeModule.forRoot(),
		DatepickerModule.forRoot(),
		AccordionModule.forRoot(),

		BrowserModule,
		// NgbCollapseModule,
		BrowserAnimationsModule,

		AppRoutingModule,
		ToasterModule.forRoot(),
		ChartsModule,
		AngularStickyThingsModule,
		...FIREBASE_MODULES,
		...APP_ROUTES,
		...APP_STANDARD_IMPORTS,
		...WAIHONA_MODULES,
		NgSelectModule,

	],
	providers: [
		...[//SERVICES,
			SiteConfigurationService,
			CommunicationService,
			FrameworkService,
			AuthService,
			CookieService,
			ConversionService,
			UploadService,
			RoleService,
			NotificationService,
			LocalizationService,
			AinaService,
			BadgeService,
			InvitationCodeService,
			AnalyticsData,
			ResourceSubmissionService,
			FileTypeService,
			UrlService,
			PathService,
			AnalyticsService,
			LogMonitoringService,
			FanContributionService,
			RefCachingService,
			FanContributionService,
			HttpHelperUtils,
			InboxService,
			RoutingService
		],
		...[/* FUNCTIONS */
			WaihonaUserFunctions,
			OrganizationFunctions,
			AuthFunctions,
		],
		...[/*REPOSITORIES */
			ContentBlockRepository,
			WaihonaUserRepository,
			BadgesRepository,
			AinaRepository,
			InvitationCodeRepository,
			InboxRepository,
			SentRepository,
			WaihonaUserRepository,
			BadgesRepository,
			AinaRepository,
			InvitationCodeRepository,
			FanContributionRepository,
			LocalUserSettingsRepository,
		],
		...[/* BUCKETS */
			DefaultStorageBucket
		],
		...GUARDS,
		/*{provide: APP_BASE_HREF, useValue: '/'},*/
		//{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{provide: LocationStrategy, useClass: HashLocationStrategy},
		/*{provide: FirestoreSettingsToken, useValue: {}}*/
	],

	exports:[
		AccordionModule,
		...MODALS,
		ToasterModule,
		PipesModule,
		LocalizeDirective,
	],
	entryComponents: [
		...MODALS,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
