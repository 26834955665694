export enum SupportedLanguage {
	"en" = "en",
	"haw" = "haw"
}

export enum SupportedLanguagePretty {
	en = "English",
	haw = "ʻŌlelo Hawaiʻi"
}

export class SupportedLanguages {

	public static get list():Array<SupportedLanguage> {
		return [SupportedLanguage.en, SupportedLanguage.haw];
	}
	/** TODO: Not really opposite; just finds the first non-matching thing */
	public static oppositeOf(of:SupportedLanguage):SupportedLanguage {
		return SupportedLanguages.list.find(language => {
			return language != of;
		});
	}

	public static toPretty(supportedLanguage:SupportedLanguage):string {
		switch (supportedLanguage) {
			case SupportedLanguage.en:
				return "English";
				break;
			case SupportedLanguage.haw:
				return 'ʻŌlelo Hawaiʻi';
				break;
			default:
				return 'Unknown';
				break;
		}
	}

}
