import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ActivityStreamRepository} from "./repository/ActivityStreamRepository";
import {ActivityStream, ActivityType} from "../domain/resources/ActivityStream";
import {ResourceVersion} from "../domain/resources/ResourceVersion";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";
import {Review} from "../domain/resources/review/Review";

@Injectable({
	providedIn: 'root',
} as any)
export class ActivityStreamService {

	constructor(private repo:ActivityStreamRepository) {}

	public safeUpdateActivityStream$(resourceId:string, activityStream:ActivityStream):Observable<ActivityStream> {
		//Safe way to do it if we're not sure it exists already...
		let activityStreamObservable:Subject<ActivityStream> = new Subject<ActivityStream>();
		this.repo.updatePartial$(activityStream,{guid: resourceId, entries: activityStream.entries}).subscribe((newActivityStream:ActivityStream) => activityStreamObservable.next(newActivityStream));
		return activityStreamObservable;
	}
	
	public safeSaveActivityStream$(activityStream:ActivityStream, resourceId:string, createNew:boolean = false):Observable<ActivityStream> {
		let activityStreamObservable:Subject<ActivityStream> = new Subject<ActivityStream>();
		if (!activityStream) {
			activityStream = new ActivityStream();
			createNew = true;
		}
		activityStream.guid = resourceId;

		if (createNew) {
			activityStream.addEntryStart(ActivityType.CREATED, "Resource Created", new ResourceVersion().set(0, 0, 0));
		}

		this.safeUpdateActivityStream$(resourceId, activityStream).subscribe((savedActivityStream:ActivityStream) => {
			activityStreamObservable.next(savedActivityStream)
		});
		return activityStreamObservable;
	}

	public logToActivityStream$(activityStream:ActivityStream,
	                             resource: ResourceSubmission | Review,
	                             logAction:(activityStream:ActivityStream, resource:ResourceSubmission | Review, optionalComments?:string, optionalDetail?:string) => void,
	                             optionalComments?:string,
	                             optionalDetail?:string):Observable<ActivityStream> {
		let createNew:boolean = false;
		if (!activityStream) {
			createNew = true;
			activityStream = new ActivityStream();
		}
		logAction(activityStream, resource, optionalComments, optionalDetail);
		return this.safeSaveActivityStream$(activityStream, resource.guid, createNew);
	}

	public updateActivityStream$(activityStream:ActivityStream):Observable<ActivityStream> {
		return this.repo.save$(activityStream);
	}

	public watchActivityStream$(resourceGuid:string):Observable<ActivityStream> {
		return this.repo.watch$(resourceGuid);
	}

	public getActivityStream$(resourceGuid:string):Observable<ActivityStream> {
		return this.repo.get$(resourceGuid);
	}
}
