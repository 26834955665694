import {Converter} from "../ConversionService";
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {WaihonaUserRef} from "../../domain/user/WaihonaUserRef";
import {classToClass} from "class-transformer";
import {Avatar} from "../../domain/user/Avatar";


export class WaihonaUserToWaihonaUserRefConverter implements Converter {

	public from:typeof WaihonaUser;
	public to:typeof WaihonaUserRef;

	constructor() {
		this.from = WaihonaUser;
		this.to = WaihonaUserRef;
	}

	public convert(source:WaihonaUser):WaihonaUserRef {

		//Create the target object
		let waihonaUserRef:WaihonaUserRef = WaihonaUserRef.create(source.guid, source.firstName, source.lastName, source.altFirstName);
			if(source.avatar)
			waihonaUserRef.avatar = classToClass<Avatar>(source.avatar);

		return waihonaUserRef;
	}
}
