export const environment = {
	production: true,
	site: {
		baseUrl: "https://waihona.net",
	},
	env: "prd",
	analytics: {
		type: "google",
		enabled: true,
		idCode: "G-GQ41X96805" //old code: UA-80043022-6 was updated july 2023
	},
	algolia: {
		appId: 'GM8061O8FQ',
		apiKey: '66a868d752ef16596c63253c1d165e66'
	},
	apiRoot: 'https://waihona.net/',
	auth: {
		clientId: 'U0fReATc8b5XKLPDuw8XR4XudBDuDDk0',
		clientDomain: 'login.waihona.net', // e.g., you.auth0.com
			audience: 'https://waihona.net/', // e.g., http://localhost:1337/
		redirect: 'https://waihona.net/#/callback',
		logoutUri: 'https://waihona.net/#/logout',
		scope: 'openid profile email'
	},
	cloudinary: {
		key: "waihona-prd"
	},
	firebase: {
		apiKey: "AIzaSyA_esafWpkyEf5RkzBRjLooGN8DLT8P6aM",
		authDomain: "waihona-prd.firebaseapp.com",
		databaseURL: "https://waihona-prd.firebaseio.com",
		projectId: "waihona-prd",
		storageBucket: "waihona-prd.appspot.com",
		messagingSenderId: "105929091132"
	},
	zapier: {
		sendFeedback: "https://hooks.zapier.com/hooks/catch/2945950/719d10/",
		newUserNotification: "https://hooks.zapier.com/hooks/catch/2945950/o21rz64/"
	},
	"site-config": {
		environment: "production",
		inviteCodesRequiredForRegistration: true,
		loginRequiredForSiteAccess: true
	}
};
