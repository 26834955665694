import "reflect-metadata";

export interface IRefType {
	Clazz?:new()=>{};
	clazzName?:string;

	property?:string,
	isProperty?:boolean;
	isArray?:boolean;
	isObjectMap?:boolean;
	isARefContainerObject?:boolean;
}

export function resolveAllRefTypeFields(target:{}, resolverFunction:(refToResolve:any, iRefType:IRefType,extra:{target: object, property: string})=>any):void {
	let propertyKeys:Array<IRefType[]> = getRefTypeKeys(target);
	let propertyKeysAsStrings:Array<string> = propertyKeys.map(pk => {
		return pk[0].property;
	})

	for(let i:number = 0; i < propertyKeys.length; i++) {
		let propertyKey:Array<IRefType> = propertyKeys[i];

		resolveRefTypeField(target, propertyKey[0], resolverFunction);//every field should only have a single property key
	}
}

export function resolveRefTypeField(target:{}, refType:IRefType, resolverFunction:(refToResolve:any, iRefType:IRefType, extra:{target: object, property: string})=>any) {
	resolverFunction(target[refType.property],refType, {target: target, property: refType.property});
}

export function RefType(propertyType:IRefType):any {
	return (target:Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
		let o:IRefType = {
			property: propertyType.property,
			isArray: propertyType.isArray,
			isObjectMap: propertyType.isObjectMap,
			isProperty: propertyType.isProperty,
			isARefContainerObject: propertyType.isARefContainerObject,

			Clazz: propertyType.Clazz,
			clazzName: propertyType.clazzName,
		};

		if(o.clazzName == null && o.Clazz != null) {
			o.clazzName = o.Clazz.name;
		}

		if(o.property == null) {
			o.property = `${propertyKey}`;
		}
		if(o.isArray == null && o.isObjectMap == null && o.isProperty==null && o.isARefContainerObject==null) {
			//Theres a bug here in that even if it is initialized in properties the Decorator runs before the assignment to array so theses will be undefined/null
			o.isProperty = true;
			o.isArray = false;
			o.isObjectMap = false;

			let isObject:boolean = typeof target[propertyKey] === 'object';
			let isArray:boolean = isObject && Array.isArray(target[propertyKey]);

			if(isArray) {
				o.isArray = true;
				o.isObjectMap = false;
				o.isProperty = false;
			} else if(isObject) {
				o.isProperty = true;
				o.isArray = false;
				o.isObjectMap = false;
			} //...no else: if they want an object map they need to say so explicitly.


		}

		Reflect.defineMetadata("custom:annotations:reftype", o, target, propertyKey);
		return descriptor;
	};
}
export function hasRefType(targetInstance:{}, property:string):boolean {
	if(property ) {
		let TargetClass:new()=>{} = targetInstance.constructor as any as new()=>{};
		const currValues = Reflect.getMetadata("custom:annotations:reftype", TargetClass, property);
		if(currValues != null) {
			return true;
		}
	}
	return false;
}

export function getRefTypeKeys(target:{}):Array<IRefType[]> {
	if(target == null) {
		return [];
	}
	// get info about keys that used in current property
	let allDecorators = [];
	let allKeys:string[] = Object.keys(target);
	//console.log(`allKeys: ${JSON.stringify(allKeys)}`);

	for(let i:number = 0; i < allKeys.length; i++) {
		let propertyName:string = allKeys[i];
		const keys: any[] = Reflect.getMetadataKeys(target, propertyName);

		const decorators:Array<IRefType> = keys
		// filter your custom decorators
			.filter(key => key.toString().startsWith("custom:annotations:reftype"))
			.reduce((values, key) => {
				// get metadata value.
				const propertyKeyValues = Reflect.getMetadata(key, target, propertyName);
				return values.concat(propertyKeyValues);
			}, []);
		if(decorators.length > 0) {
			allDecorators.push(decorators);
		}
	}

	return allDecorators;
}
