import {ReviewFieldApprovalStatus} from "../../domain/resources/review/ReviewFieldApprovalStatus";
import {ActivityType} from "../../domain/resources/ActivityStream";
import {ActivityEntry} from "../../domain/resources/ActivityEntry";
import {Review} from "../../domain/resources/review/Review";

export class ReviewToActivityStreamEntryConverter {

	public from:typeof Review;
	public to:typeof ActivityEntry;

	constructor() {
		this.from = Review;
		this.to = ActivityEntry;
	}

	public convert(source:Review):ActivityEntry {

		let detail:string = "";

		for(let i:number = 0; i < source.reviewFields.length; i++) {
			let iReviewFieldApprovalStatus:ReviewFieldApprovalStatus = source.reviewFields[i];

			detail += "----------------------------------------------------------------\n";
			detail += "Field: " + iReviewFieldApprovalStatus.field + "\n";
			detail += "Comment: " + iReviewFieldApprovalStatus.reviewerComment + "\n";
			detail += "----------------------------------------------------------------\n\n";

			detail += "----------------------------------------------------------------\n";
			detail += "Field: " + iReviewFieldApprovalStatus.field + "\n";
			detail += "Comment: " + iReviewFieldApprovalStatus.reviewerComment + "\n";
			detail += "----------------------------------------------------------------\n\n";

			detail += "----------------------------------------------------------------\n";
			detail += "Field: " + iReviewFieldApprovalStatus.field + "\n";
			detail += "Comment: " + iReviewFieldApprovalStatus.reviewerComment + "\n";
			detail += "----------------------------------------------------------------\n\n";

			detail += "----------------------------------------------------------------\n";
			detail += "Field: " + iReviewFieldApprovalStatus.field + "\n";
			detail += "Comment: " + iReviewFieldApprovalStatus.reviewerComment + "\n";
			detail += "----------------------------------------------------------------\n\n";

			detail += "----------------------------------------------------------------\n";
			detail += "Field: " + iReviewFieldApprovalStatus.field + "\n";
			detail += "Comment: " + iReviewFieldApprovalStatus.reviewerComment + "\n";
			detail += "----------------------------------------------------------------\n\n";

		}
		//Create the target object
		let activityEntry:ActivityEntry = new ActivityEntry(ActivityType.REVIEWER_COMMENT, detail, new Date());
		return activityEntry;
	}

}
