import {Injectable} from "@angular/core";
import {NewUserRegistration} from "../domain/user/NewUserRegistration";
import {StubData} from "../data/StubData";
import {RegistrationService} from "./RegistrationService";
import {Observable} from "rxjs/Observable";
import {Organization} from "../domain/organization/Organization";
import {OrganizationService} from "./OrganizationService";
import {FrameworkService} from "./FrameworkService";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserService} from "./WaihonaUserService";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Framework} from "../domain/frameworks/Framework";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";
import {ResourceSubmissionService} from "./ResourceSubmissionService";
import {Aina} from "../domain/resources/aina/Aina";
import {AinaService} from "./AinaService";
import {Badge} from "../domain/badges/Badge";
import {BadgeService} from "./BadgeService";
import {PublishedResourceService} from "./PublishedResourceService";
import {Review} from "../domain/resources/review/Review";
import {Subject} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {InboxService} from "./InboxService";
import {InboxRepository} from "./repository/InboxRepository";
import {InboxStubService} from "./data/InboxStub";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {Avatar} from "../domain/user/Avatar";
import {capitalize} from "lodash";
import {MessageRef} from "../domain/inbox/MessageRef";
import {InboxFilterType} from "../domain/inbox/InboxFilterType";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";

@Injectable ({
	"providedIn": "root",
} as any)
export class ProvisioningService {

	constructor(private stubData:StubData,
	            private registrationService:RegistrationService,
				private organizationService:OrganizationService,
				private frameworkService:FrameworkService,
				private waihonaUserService:WaihonaUserService,
				private functions:AngularFireFunctions,
				private lessonSubmissionService:ResourceSubmissionService,
				private publishedResourceService:PublishedResourceService,
				private ainaService:AinaService,
				private badgesService:BadgeService,
	            private inboxService:InboxService,
	            private inboxRepository:InboxRepository,
	            private inboxStubService:InboxStubService,
	            protected logger:NGXLogger) {
	}

	public outputAina():void {
		console.log(`former: --------------`)

		this.stubData.aina.formerMoku().map(moku => {
			let oldGuid:string = NupepafyStringUtils.generateGuidFromTwoParts(moku.mokupuni, moku.moku);
			console.log(`${oldGuid}`)
		});

		console.log(`current: --------------`)
		this.stubData.aina.currentData().map(guid => {
			console.log(`${guid}`)
		});


	}

	public provisionUserInbox(userGuid:string):void {
		let toRef:WaihonaUserRef = new WaihonaUserRef();
		toRef.altFirstName = null;
		toRef.avatar = new Avatar();
		toRef.avatar.bgColor = "#f44638";
		toRef.avatar.version = 1;

		toRef.firstName = capitalize(userGuid.split("-")[0]);
		toRef.guid = userGuid;
		toRef.lastName = capitalize(userGuid.split("-")[1]);

		let messageRefs:Array<MessageRef> = this.inboxStubService.messageRefsFromDoomEmails();

		let read:Array<InboxFilterType> = [null, null, null, null, InboxFilterType.important, InboxFilterType.starred, null];
		let currentPhase = 0;

		for(let i:number = 0.0; i < messageRefs.length; i++) {
			let messageRef:MessageRef = messageRefs[i];
			let percentage:number = (i * 1.0) /(messageRefs.length * 1.0);

			if(percentage > .7 && currentPhase == 0) {
				currentPhase++;
			} else if ( percentage > .9 && currentPhase == 1) {
				currentPhase++;
			}
			if(currentPhase == 0) {
				let randomRead:number = Math.floor(Math.random()*read.length);
				let filterType:InboxFilterType = read[randomRead];
				if(filterType != null) {
					messageRef.sortingTags.push(filterType);
				}
				messageRef.opened = Math.random() > .5 ? null : new Date();
			}
			if(currentPhase == 1) {
				messageRef.sortingTags.push(InboxFilterType.sent);
			}
			if(currentPhase == 2) {
				messageRef.sortingTags.push(InboxFilterType.trash);
				messageRef.opened = Math.random() > .5 ? null : new Date();
			}

		}

		this.inboxRepository.saveAll$(messageRefs, userGuid);

	}


	public async createHogwarts():Promise<void> {
		let hogwarts:Organization = this.stubData.hogwarts.getHogwartsOrganization();
		this.organizationService.save$(hogwarts);

		let hogwartsContributers:Array<WaihonaUser> = this.stubData.hogwarts.getHogwartsWaihonaUsers();
		let userRegistrations:Array<NewUserRegistration> = this.stubData.hogwarts.getHogwartsNewUserRegistrations();

		for(let i:number = 0; i < userRegistrations.length; i++) {
			let newUserRegistration:NewUserRegistration = userRegistrations[i];
			this.logger.info("attempt to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName)
			try {
				await this.registrationService.registerUser$(newUserRegistration);
				this.logger.info("Created user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);

			} catch(error) {
				this.logger.error("Failed to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);
			}
		}
	}

	public async createDevTeam():Promise<void> {
		let devteam:Organization = this.stubData.devteam.getDevelopmentTeam();
		this.organizationService.save$(devteam);
		let userRegistrations:Array<NewUserRegistration> = this.stubData.devteam.getDevTeamNewUserRegistrations();

		for(let i:number = 0; i < userRegistrations.length; i++) {
			let newUserRegistration:NewUserRegistration = userRegistrations[i];
			this.logger.info("attempt to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);
			try {
				await this.registrationService.registerUser$(newUserRegistration);
				this.logger.info("Created user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);

			} catch(error) {
				this.logger.error("Failed to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);
			}
		}
		this.logger.info("Now Creating Dev Team Waihona Users");
		this.waihonaUserService.save(this.stubData.devteam.kabright_waihonaUser);
		this.waihonaUserService.save(this.stubData.devteam.chjones_waihonaUser);
		this.waihonaUserService.save(this.stubData.devteam.wybartle_waihonaUser);
		this.waihonaUserService.save(this.stubData.devteam.rygonzal_waihonaUser);
		this.waihonaUserService.save(this.stubData.devteam.keabad_waihonaUser);
		this.logger.info("Finished Creating Dev Team Waihona Users");
	}

	public async createKealaiwikuamoo():Promise<void> {
		let kealaiwikuamoo:Organization = this.stubData.kealaiwikuamoo.getKealaiwikuamoo();
		this.organizationService.save$(kealaiwikuamoo);
		let userRegistrations:Array<NewUserRegistration> = this.stubData.kealaiwikuamoo.getKealaiwikuamooNewUserRegistrations();

		for(let i:number = 0; i < userRegistrations.length; i++) {
			let newUserRegistration:NewUserRegistration = userRegistrations[i];
			this.logger.info("attempt to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);
			try {
				await this.registrationService.registerUser$(newUserRegistration);
				this.logger.info("Created user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);

			} catch(error) {
				this.logger.error("Failed to create user: " + newUserRegistration.firstName + " " + newUserRegistration.lastName);
			}
		}
		this.logger.info("Now Creating Kealaiwikuamoʻo Waihona Users");
		this.waihonaUserService.save(this.stubData.kealaiwikuamoo.kabright_waihonaUser);
		this.waihonaUserService.save(this.stubData.kealaiwikuamoo.chjones_waihonaUser);
		this.waihonaUserService.save(this.stubData.kealaiwikuamoo.wybartle_waihonaUser);
		this.waihonaUserService.save(this.stubData.kealaiwikuamoo.rygonzal_waihonaUser);
		this.waihonaUserService.save(this.stubData.kealaiwikuamoo.keabad_waihonaUser);
		this.logger.info("Finished Creating Kealaiwikuamoʻo Waihona Users");
	}

	public createFrameworks():void {
	//	grab all of the frameworks from stubdata
		let frameworks:Framework[] = this.stubData.frameworks.getAllFrameworks();

		for (let i:number = 0; i < frameworks.length; i++) {
			this.frameworkService.save(frameworks[i]);
		}
		this.logger.info("Updated selectedFramework info in the database");
	}

	public createResources():void {
		let resources:ResourceSubmission[] = this.stubData.hogwarts.lessons.allLessons;

		for (let i:number = 0; i< resources.length; i++) {
			let resource:ResourceSubmission = resources[i];
			this.lessonSubmissionService.saveTo(resource);
			//this.logger.info("resource:" + JSON.stringify(resource));

			let review:Review = this.lessonSubmissionService.submit(resource);
			this.publishedResourceService.publishResourceFromReview(review);
		}

		this.logger.info("updated resource info in the database and published new resources");
	}



	public sayHello():Observable<string> {
		let helloCallableFunction = this.functions.httpsCallable('hello');
		let data:any = {};

		//currentUser is set via auth
		let response:Subject<string> = new Subject<string>();

		helloCallableFunction(data).subscribe(function(result) {
			response.next(result);
		});//TODO: handle failure case

		return response.asObservable();

	}

	public createBadges():void {
		let allBadges:Array<Badge> = this.stubData.badges.getAllBadges();

		for (let i:number = 0; i < allBadges.length; i++) {
			let badge:Badge = allBadges[i];
			this.badgesService.save$(badge);
		}
		this.logger.info("Updated badge info in the database");
	}


}
