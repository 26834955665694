<div class="animated fadeIn horizontal-card-deck">
  <h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.header | localize | async}}</h1><br>

  <!--iterated card deck-->
  <div class="card-deck mb-5">

    <!--create new framework card-->
    <div class="" *ngIf="canCreateFramework()">
      <div class="card my-lg-3 my-sm-1 bg-transparent border-0 p-3" style="display: block;" >
        <a href="javascript:void(0);" style="text-decoration: none;" [routerLink]="['/more/frameworks', '', 'edit']">
          <div class="card-body text-center">
            <i class="fas fa-plus-circle fa-6x center-plus-button"></i>
            <h3>{{CB.create | localize | async}}</h3>
          </div>
        </a>
      </div>
    </div>
    <!--end create new framework card-->

    <div *ngFor="let framework of frameworks">
      <ng-container *ngIf="canCreateFramework(); else cannotEdit">
        <div class="card my-lg-3 my-sm-1" [id]="framework.guid">
          <div class="card-body">
            <h5 class="card-title font-weight-bold">{{framework.title}} <span class="small font-weight-bold text-warning" *ngIf="!framework.visible">HIDDEN</span></h5>
            <h6 class="text-muted">{{framework.shortDescription | slice: 0: 250}}</h6>
            <!--todo: create a custom truncate pipe to slice whole words instead of characters-->
            <div class="align-self-end">
            <span>
              <a href="javascript:void(0);" (click)="saveStateAndNavigate(framework.guid, ['/more/frameworks', framework.guid, 'edit'])" style="text-decoration: none;">{{CB.edit | localize | async}}</a> |
				<a href="javascript:void(0);" (click)="saveStateAndNavigate(framework.guid, ['/more/frameworks', framework.guid])" style="text-decoration: none;">{{CB.view | localize | async}}</a>
            </span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #cannotEdit>
        <a href="javascript:void(0);" (click)="saveStateAndNavigate(framework.guid, ['/more/frameworks', framework.guid])"
           style="text-decoration:
      none;">
          <div class="card my-lg-3 my-sm-1" [id]="framework.guid">
            <div class="card-body">
              <h5 class="card-title font-weight-bold">{{framework.title}}</h5>
              <h6 class="text-muted">{{framework.shortDescription | slice: 0: 250}}</h6>
              <!--todo: create a custom truncate pipe to slice whole words instead of characters-->
            </div>
          </div>
        </a>
      </ng-template>
    </div>
  </div>
  <!--end card deck-->
</div>

