import "reflect-metadata";
import {escapeRegExp} from "lodash";  //TODO: Dont really want a lodash dependency in this annotaion..but needed for makeTextIndex stuff

export interface IPropertyType {
	extraGeneratedMetadataProperty?:string;
	generatedProperty?:string;
	property?:string;
	isArray?:boolean;
	isArrayOfKeys?:boolean,
	isObjectMap?:boolean;
	isString?:boolean;
	transform?:Function;
	possibilities?:Array<string>|(()=>Array<string>);
	itemIdentifier?:string; /** the thing on the object in the contained array or map */
}

export function generateAllPropertyKeys(target:{}):void {
	let propertyKeys:Array<IPropertyType> = getIndexedPropertyKeys(target);
	for(let i:number = 0; i < propertyKeys.length; i++) {
		let propertyKey:IPropertyType = propertyKeys[i];
		generateFromPropertyKey(target, propertyKey[0]);//every field should only have a single property key
	}
}

export function generateFromPropertyKey(target:{}, indexPropertyType:IPropertyType) {
	if (indexPropertyType.isObjectMap){
		//Where we copy/look at
		let sourceObjectMap:{} = target[indexPropertyType.property];
		let sourceKeys:Array<string> = Object.keys(sourceObjectMap);

		//Where we copy/create index on
		target[indexPropertyType.generatedProperty] = {};
		let targetObjectMap:{} = target[indexPropertyType.generatedProperty];


		let possibilities:Array<string> = [];
		if (indexPropertyType.possibilities != null && typeof indexPropertyType.possibilities === "function" && !Array.isArray(indexPropertyType.possibilities)) {
			possibilities = indexPropertyType.possibilities();
		} else {
			possibilities = indexPropertyType.possibilities as Array<string>;
		}

		for (let i:number = 0; i < possibilities.length; i++){
			let possibility:string = possibilities[i];
			let existsInObject:boolean = sourceKeys.indexOf(possibility) != -1;

			//If exists in source object index obj["propName"] will be true, otherwise will be false
			targetObjectMap[possibility] = existsInObject;
		}
	} else if (indexPropertyType.isArray) {
		let theArray:Array<object> = target[indexPropertyType.property];
		let guidArray:Array<string> = theArray.map(value => {
			return value[indexPropertyType.itemIdentifier];
		});
		target[indexPropertyType.generatedProperty] = guidArray;
		let arrayExtraMetaData:string = "@" +indexPropertyType.generatedProperty;
		target[arrayExtraMetaData]= {
			length: guidArray.length
		};
	} else if (indexPropertyType.isArrayOfKeys) {
		let theArray:Array<string> = target[indexPropertyType.property];

		target[`@${indexPropertyType.generatedProperty}`]= {
			length: theArray.length
		};
		target[indexPropertyType.generatedProperty]= {};

		let possibilitiesIsAFunction:boolean = indexPropertyType.possibilities != null && typeof indexPropertyType.possibilities === "function" && !Array.isArray(indexPropertyType.possibilities);
		let possibilities:Array<string> = possibilitiesIsAFunction ? (indexPropertyType.possibilities as Function)() : indexPropertyType.possibilities as Array<string>;

		for (let possibility of possibilities){
			//If exists in source object index obj["propName"] will be true, otherwise will will be false (key from possibilites array)
			target[indexPropertyType.generatedProperty][possibility] = theArray.indexOf(possibility) != -1;
		}
	} else if (indexPropertyType.isString) {
		let indexVal:string;

		if (indexPropertyType.transform) {
			indexVal = indexPropertyType.transform(target);
		}

		if (!target["metadata"]) {
			target["metadata"] = {};
		}

		target["metadata"][indexPropertyType.property] = indexVal;
	}
}


// a factory function for generating functions that generate an index field for the given text property,
// or for the given text value
export function generateTextIndex({textProp, textValue, propResolveFunc}:EitherField<ITextIndexParams>):Function | string {
	if (textProp) {
		return (target) => makeTextIndex(target[textProp]);
	} else if (propResolveFunc) {
		return (target) => {
			let resolvedProp:string = propResolveFunc(target);
			// split in the case of a nested sub property, e.g. documentTextContent.en.title
			let propertyParts:string[] = resolvedProp.split(".");
			let textValue = propertyParts.reduce((accumulator, currentVal:string) => {return accumulator[currentVal]}, target);
			return makeTextIndex(textValue);
		}
	} else if (textValue) {
		return makeTextIndex(textValue);
	}
	else {
		return null;
	}
}

/* Since this is in index property and its used in script manager in the domains folder used Nupepafy function needed to be moved here */
export function makeTextIndex(val:string):string {
	val = val.replace(new RegExp(escapeRegExp("Ā"), "g"), "A");
	val = val.replace(new RegExp(escapeRegExp("Ē"), "g"), "E");
	val = val.replace(new RegExp(escapeRegExp("Ī"), "g"), "I");
	val = val.replace(new RegExp(escapeRegExp("Ō"), "g"), "O");
	val = val.replace(new RegExp(escapeRegExp("Ū"), "g"), "U");
	val = val.replace(new RegExp(escapeRegExp("ā"), "g"), "a");
	val = val.replace(new RegExp(escapeRegExp("ē"), "g"), "e");
	val = val.replace(new RegExp(escapeRegExp("ī"), "g"), "i");
	val = val.replace(new RegExp(escapeRegExp("ō"), "g"), "o");
	val = val.replace(new RegExp(escapeRegExp("ū"), "g"), "u");
	// the next two look similar, but they're actually different characters
	val = val.replace(new RegExp(escapeRegExp("ʻ"), "g"), "");
	val = val.replace(new RegExp(escapeRegExp("‘"), "g"), "");
	val = val.replace(new RegExp(escapeRegExp("'"), "g"), "");
	val = val.replace(new RegExp(escapeRegExp("-"), "g"), "");
	return val.toLowerCase();
}

interface ITextIndexParams {
	textProp?:string, textValue?:string, propResolveFunc?:(target:any) => string
}

type EitherField<T, TKey extends keyof T = keyof T> =
	TKey extends keyof T ? { [P in TKey]-?:T[TKey] } & Partial<Record<Exclude<keyof T, TKey>, never>>: never


export function IndexProperty(propertyType?:IPropertyType):any {
	if(propertyType == null) {
		propertyType = {};
	}

	return (target:Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
		//this.logger.info(`target.constructor.name: ${target.constructor.name}`);


		let o:IPropertyType = {
			generatedProperty: propertyType.generatedProperty,
			isArray: propertyType.isArray,
			isObjectMap: propertyType.isObjectMap,
			isString: propertyType.isString,
			possibilities: propertyType.possibilities != null ? propertyType.possibilities : [],
			itemIdentifier: propertyType.itemIdentifier,
			transform: propertyType.transform,
			isArrayOfKeys: propertyType.isArrayOfKeys,
			extraGeneratedMetadataProperty: propertyType.extraGeneratedMetadataProperty,
		};
		if(o.property == null) {
			o.property = `${propertyKey}`;
		}
		if(o.generatedProperty == null) {
			o.generatedProperty = `@${propertyKey}`;
		}
		if(o.extraGeneratedMetadataProperty == null) {
			o.extraGeneratedMetadataProperty = `@${o.generatedProperty}`;
		}
		if(o.isArray == null && o.isArrayOfKeys == null) {
			//note this is before any variables are defined so we cant introspect the object to see what it is
			o.isArray = (o.isObjectMap == null || o.isObjectMap == false);
		}
		if(o.isArray && o.itemIdentifier == null && !o.isArrayOfKeys) {
			o.itemIdentifier = "guid";
		}
		if(o.isObjectMap == null) {
			//note this is before any variables are defined so we cant introspect the object to see what it is
			o.isObjectMap = false;
		}
		if(o.isString) {
			o.isObjectMap = false;
			o.isArray = false;
		}

		Reflect.defineMetadata("custom:annotations:indexproperty", o, target, propertyKey);
		return descriptor;
	};
}
export function hasIndexedProperty(targetInstance:{}, property:string):boolean {
	if(property ) {
		let TargetClass:new()=>{} = targetInstance.constructor as any as new()=>{};
		const currValues = Reflect.getMetadata("custom:annotations:indexproperty", TargetClass, property);
		if(currValues != null) {
			return true;
		}
	}
	return false;
}

export function getIndexedPropertyKeys(target:{}):Array<IPropertyType> {
	// get info about keys that used in current property
	let allDecorators = [];
	let allKeys:string[] = Object.keys(target);
	//console.log(`allKeys: ${JSON.stringify(allKeys)}`);

	for(let i:number = 0; i < allKeys.length; i++) {
		let propertyName:string = allKeys[i];
		const keys: any[] = Reflect.getMetadataKeys(target, propertyName);
		// console.log(`keys for ${propertyName}: ${JSON.stringify(keys)}`);

		const decorators:Array<IPropertyType> = keys
		// filter your custom decorators
			.filter(key => key.toString().startsWith("custom:annotations:indexproperty"))
			.reduce((values, key) => {
				// get metadata value.
				//console.log(`values: ${values}, keys: ${keys}`);
				const propertyKeyValues = Reflect.getMetadata(key, target, propertyName);
				return values.concat(propertyKeyValues);
			}, []);
		if(decorators.length > 0) {
			allDecorators.push(decorators);
		}
	}

	return allDecorators;
}
