import { Component, OnInit } from '@angular/core';
import {ProvisioningService} from "../../../services/ProvisioningService";
import {EditResourceSubmissionComponent} from "../../resources/create/single/edit-resource-submission/edit-resource-submission.component";

@Component({
  selector: 'setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  constructor(private provisioningService:ProvisioningService) { }

  public createDevTeam():void {
    this.provisioningService.createDevTeam();
  }

  public onClick() {
    this.createDevTeam();
  }

  ngOnInit() {

  }

}
