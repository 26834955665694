import {Exclude, Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../../RegisterType";
import {AinaRef} from "./AinaRef";
import {AinaType} from "./AinaType";
import {IndexProperty} from "../../IndexProperty";
import { IsNotEmpty, Min } from "class-validator";
import {Framework} from "../../frameworks/Framework";

@RegisterClazz(Aina, "Aina")
export class Aina {
	@Exclude({toPlainOnly: true})
	public guid:string;

	@IsNotEmpty()
	public type:AinaType = null;
	@IsNotEmpty()
	public label:string = "";
	@Min(0)
	public order:number = -1;

	public configuration = {
		hideLabelIfParent: false,
	};

	@IndexProperty({isArray: true, itemIdentifier: "guid"})
	@Type(()=>AinaRef)
	public children:Array<AinaRef> = [];

	@Exclude()
	public actualChildren:Array<Aina> = [];

	@Exclude()
	public actualParent:Aina;

	public static create(guid:string, type:AinaType, label:string, order:number, children:Array<AinaRef> = []):Aina {
		let aina:Aina = new Aina();
			aina.guid = guid;
			aina.type = type;
			aina.label = label;
			aina.order = order;
			aina.children = children;

		return aina;
	}
	public get terminalDistance():number {
		if(this.actualParent == null) {
			return 0;
		}  else {
			let tParent:Aina = this.actualParent;
			let count = 0;
			while(tParent != null) {
				count++;
				tParent = tParent.actualParent;
			}
			return count;
		}
	}
	public renderLabel(...params:Array<RenderLabelStyle>):string {
		let label:string = "";
		let hyphensText:string = "";
		if(params.includes(RenderLabelStyle.hyphens)) {
			let hyphens:number = this.terminalDistance;
			while(hyphens > 0) {
				hyphensText += "--";
				hyphens--;
			}
			label = `${hyphensText}`;
		}
		if(params.includes(RenderLabelStyle.parent)) {
			if(this.actualParent && !this.actualParent.configuration.hideLabelIfParent) {
				label = `${label}${this.actualParent.label} - `;
			}
		}
		return label + this.label;
	}
}
//Here now but move to a more generic place when reused for another domain.
export enum RenderLabelStyle {
	hyphens="hyphens",
	parent="parent",
}




