import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {Observable, Subject} from "rxjs";
import {HttpHelperUtils} from "./HttpHelperUtils";

@Injectable({
	providedIn:'root',
} as any)
export class FileService {

	constructor(protected logger:NGXLogger,
				protected httpHelperUtils:HttpHelperUtils) {
	}

	public tryOpen(url:string):Observable<string> {
		let openObservable:Subject<string> = new Subject<string>();
		this.httpHelperUtils.fileExists(url).take(1).subscribe((fileExists) => {
			if (fileExists) {
				// actually open the file
				window.open(url, '_blank');
				openObservable.next("Opening file!");
			} else {
				openObservable.next("File Not Found");
			}
		});
		return openObservable;
	}

}

