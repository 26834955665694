import {ResourceSubmission} from "../ResourceSubmission";
import {WaihonaUserRef} from "../../user/WaihonaUserRef";
import {ReviewFieldApprovalStatus} from "./ReviewFieldApprovalStatus";
import {Exclude, Type} from "class-transformer";
import {ReviewHistoryState} from "./ReviewHistoryState";
import {RegisterClazz, RegisterType} from "../../RegisterType";
import {ResourceVersion} from "../ResourceVersion";
import {IndexProperty} from "../../IndexProperty";
import {RefType} from "../../RefType";

//DOMAIN OBJECT: /Reviews/{reviewGuid}
@RegisterClazz(Review, "Reviews")
export class Review {
    @Exclude({ toPlainOnly: true })
    public guid:string = null;

    @Type(() => ResourceSubmission)
    @RegisterType({clazz:ResourceSubmission})
    @RefType({isARefContainerObject: true})
    public resourceSubmission:ResourceSubmission = null;

    @Type(() => WaihonaUserRef)
    @RegisterType({clazz:WaihonaUserRef})
    @IndexProperty({isArray: true})
    @RefType({isArray: true, Clazz: WaihonaUserRef})
    public reviewers:Array<WaihonaUserRef> = [];

    @Type(() => ReviewHistoryState)
    @RegisterType({clazz: ReviewHistoryState, isArray: true})
    public reviewHistory:Array<ReviewHistoryState> = [];

    @Type(() => ReviewFieldApprovalStatus)
    @RegisterType({clazz: ReviewFieldApprovalStatus, isArray: true})
    public reviewFields:Array<ReviewFieldApprovalStatus> = [];

    public overallComment:string = "";

    public epochLastSubmissionDate:number = 0;
    public submittedDate:number = 0;
    public startedReview:number = 0;
    public completedReview:number = 0;

    /** The major version which is incremented at the time (prior) to the lesson submission reaching a publish state */
    public get version():ResourceVersion {
        return this.resourceSubmission.version;
    }

    public getEpochAsTime():string {
        let newDate:Date = new Date(this.epochLastSubmissionDate);
        return (newDate.getMonth() + 1) +
            "/" +  newDate.getDate() +
            "/" +  newDate.getFullYear();
    }

    public getSubmittedDateAsTime():string {
        let newDate:Date = new Date(this.submittedDate);
        return (newDate.getMonth() + 1) +
            "/" +  newDate.getDate() +
            "/" +  newDate.getFullYear();
    }

    public getReviewStartAsTime():string {
        let newDate:Date = new Date(this.startedReview);
        return (newDate.getMonth() + 1) +
            "/" +  newDate.getDate() +
            "/" +  newDate.getFullYear();
    }

    public getReviewFinishAsTime():string {
        let newDate:Date = new Date(this.completedReview);
        return (newDate.getMonth() + 1) +
            "/" +  newDate.getDate() +
            "/" +  newDate.getFullYear();
    }

    public reset() {
        this.reviewFields = [];

    }
    public resetReviewFields() {
        this.reviewFields = [];
    }

}
