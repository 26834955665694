import {Observable, Subject} from "rxjs";

import {classToPlain, plainToClass} from "class-transformer";
import { map, take} from 'rxjs/operators';
import {AngularFireFunctions} from "@angular/fire/functions";
import {NGXLogger} from "ngx-logger";

export abstract class AbstractOldFirebaseFunctions<T> implements IFirebaseFunctions<T> {

	protected Type:new () => T;
	protected functions: AngularFireFunctions;
	protected identifierProperty: string = "guid";

	protected _refs:{[key: string]:(data: T) => Observable<any>} = {};
	protected uris = {};

	constructor(Type: new () => T, functions:AngularFireFunctions, identifierProperty: string = "guid") {
		this.Type = Type;
		this.functions = functions;
		this.identifierProperty = identifierProperty;
	}
	protected initUris(uris:object) {
		this.uris = uris;
		//Instantiate references
		for (var key in this.uris) {
			this.uris[key] = key;
			this._refs[key] = this.functions.httpsCallable(key);
		}
	}
	public httpsCallableRef(functionName:string):(data: T) => Observable<any> {
		return this.functions.httpsCallable(functionName);
	}
	public ref(uriKey:string):(data: T) => Observable<any> {
		return this._refs[uriKey];
	}
	public callUrlDirect(path:string, message:object):Observable<T> {
		return this.callDirect(this.ref(this.uris[path]), message);
	}
	public callDirect(ref:(data: T) => Observable<any>, message:object):Observable<T> {
		let item$:Subject<T> = new Subject();

		let plain:any = message;
		ref(plain)
			.pipe(
				take(1),
				map(doc => {
						//Get document data
						const data = doc;
						const dataAsClass:T = plainToClass<T, any>(this.Type, data);
						if (dataAsClass) {
							dataAsClass[this.identifierProperty] = data[this.identifierProperty];
						}

						return dataAsClass;
					}
				))
			.subscribe(item => {
				item$.next(item);
				item$.complete();
			});

		return item$;
	}
	public call(ref:(data: T) => Observable<any>, message:T):Observable<T> {
		let item$:Subject<T> = new Subject();

		let plain:any = classToPlain<T>(message);

		if(this.identifierProperty != null) {
			plain[this.identifierProperty] = message[this.identifierProperty];
		}
		ref(plain)
			.pipe(
				take(1),
				map(doc => {
					//Get document data
					const data = doc;
					const dataAsClass:T = plainToClass<T, any>(this.Type, data);

					if(this.identifierProperty != null) {
						dataAsClass[this.identifierProperty] = data[this.identifierProperty];
					}
					return dataAsClass;
				}
			))
			.subscribe(item => {
				item$.next(item);
				item$.complete();
			});

		return item$;
	}
	public callDirectToArray(ref:(data: T) => Observable<any>, message:object):Observable<T[]> {
		let list$:Subject<T[]> = new Subject();

		let plain:any = message;

		if(this.identifierProperty != null) {
			plain[this.identifierProperty] = message[this.identifierProperty];
		}

		ref(plain)
			.take(1)
			.map(actions => {
				return actions.map(a => {
					//Get document data
					const data = a.payload.doc.data();//TODO:THIS PROBABLY DOESNT WORK  debug..ithink its just data
					const dataAsClass:T = plainToClass<T, any>(this.Type, data);

					if(this.identifierProperty != null) {
						dataAsClass[this.identifierProperty] = data[this.identifierProperty];
					}
					return dataAsClass;
				});
			}).subscribe(items => {
			list$.next(items);
			list$.complete();
		});

		return list$;
	}

	public callToArray(ref:(data: T) => Observable<any>, message:T):Observable<T[]> {
		let list$:Subject<T[]> = new Subject();

		let plain:any = classToPlain<T>(message);

		if(this.identifierProperty != null) {
			plain[this.identifierProperty] = message[this.identifierProperty];
		}

		ref(plain)
			.take(1)
			.map(actions => {
				return actions.map(a => {
					//Get document data
					const data = a.payload.doc.data();
					const dataAsClass:T = plainToClass<T, any>(this.Type, data);

					if(this.identifierProperty != null) {
						dataAsClass[this.identifierProperty] = data[this.identifierProperty];
					}
					return dataAsClass;
				});
			}).subscribe(items => {
				list$.next(items);
				list$.complete();
		});

		return list$;
	}
}

export interface IFirebaseFunctions<T> {
	httpsCallableRef(functionName:string):(data: T) => Observable<any>;
	call(ref:(data: T) => Observable<any>, message:T):Observable<T>;
	callToArray(ref:(data: T) => Observable<any>, message:T):Observable<T[]>;
}
