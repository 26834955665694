import {Aina} from "../domain/resources/aina/Aina";
import {Observable, Subject, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {AinaRepository} from "./repository/AinaRepository";

@Injectable()
export class AinaService {

	constructor (private repo:AinaRepository,
	             protected logger:NGXLogger) {

	}

	public save$(aina:Aina):Observable<Aina> {
		return this.repo.save$(aina);
	}

	public getAllAina$(limit?:number):Observable<Aina[]> {
		return this.repo.doList$();
	}

}
