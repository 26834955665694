import {Avatar} from "./Avatar";
import {Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(WaihonaUserRef)
export class WaihonaUserRef {
	public guid:string;

	public firstName:string;
	public altFirstName:string;
	public lastName:string;

	@Type(() => Avatar)
	@RegisterType({clazz:Avatar})
	public avatar:Avatar = new Avatar();

	public static create(guid:string, firstName:string, lastName:string, altFirstName?:string):WaihonaUserRef {
		let waihonaUserRef:WaihonaUserRef = new WaihonaUserRef();
		waihonaUserRef.guid = guid;
		waihonaUserRef.firstName = firstName;
		waihonaUserRef.altFirstName = altFirstName;
		waihonaUserRef.lastName = lastName;
		waihonaUserRef.avatar = new Avatar();

		return waihonaUserRef;
	}

	constructor() { //Keep this no-args
		this.avatar = new Avatar();
	}

	public get fullName():string {
		const firstName = this.altFirstName ? this.altFirstName : this.firstName;
		if (firstName && this.lastName) {
			return (this.altFirstName ? this.altFirstName : this.firstName) + " " + this.lastName;
		} else {
			return "";
		}
	}

	public get preferredFirstName():string {
		return (this.altFirstName ? this.altFirstName : this.firstName);
	}

	//TODO: This needs to get pulled out of domain
	public get twoInitials():string {
		const firstName = this.altFirstName ? this.altFirstName : this.firstName;
		if (firstName && this.lastName) {
			let firstChar:string = firstName.trim().charAt(0);
			let secondChar:string = (this.lastName).trim().charAt(0);
			return `${firstChar} ${secondChar}`.toUpperCase();
		} else {
			return "";
		}
	}
}
