import {IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {classToPlain, Exclude, plainToClass, Transform, Type} from "class-transformer";
import {ContactInfo, ContactInfoType} from "./ContactInfo";
import {UserOrgInvite} from "./UserOrgInvite";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {FrameworkRef} from "../frameworks/FrameworkRef";
import {OrganizationConfiguration} from "./OrganizationConfiguration";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {IndexProperty} from "../IndexProperty";
import {OrganizationTextContent} from "./OrganizationTextContent";
import {ITextContentObject} from "../ITextContentObject";
import {DocumentTextContent} from "../DocumentTextContent";
import {IOrganizationMetadata} from "./IOrganizationMetadata";
import {generateTextIndex} from "../IndexProperty";
import {RefType} from "../RefType";
import {toClassDocTextTransformFactory, toPlainDocTextTransform} from "../../helper/DocTextContentHelper";

@RegisterClazz(Organization, "Organizations")
export class Organization implements ITextContentObject<OrganizationTextContent> {

	@Exclude({toPlainOnly: true})
	public guid:string = null;

	public metadata:IOrganizationMetadata = {} as any;

	/** (Enum) Used to determine if it is a school or whatever, designed to support multiple types into the future */
	public flags:Array<OrganizationFlags> = [];

	//document text content transformer
	@Transform(toClassDocTextTransformFactory(OrganizationTextContent), {toClassOnly: true})
	@Transform(toPlainDocTextTransform, {toPlainOnly: true})
	public documentTextContent:DocumentTextContent<OrganizationTextContent> = new DocumentTextContent<OrganizationTextContent>(OrganizationTextContent);

	/** name/title of the organization or school */
	@IndexProperty({isString:true, transform:generateTextIndex({textProp:"title"}) as Function})
	@MinLength(4)
	@MaxLength(64)
	@IsNotEmpty()
	public title:string;

	@Type(() => OrganizationConfiguration)
	@RegisterType({clazz: OrganizationConfiguration})
	public configuration:OrganizationConfiguration;

	@Type(() => ContactInfo)
	public contactInfo:Array<ContactInfo> = [];

	/** Whether the item is displayed in the list or not */
	public enabled:boolean = true;

	/** Who has ability to edit the school information */
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@IndexProperty({isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public admins:Array<WaihonaUserRef> = [];//Array of GUIDs (user ids)

	@IndexProperty({isArray: true})
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public kahu:Array<WaihonaUserRef> = [];//Array of GUIDs (user ids)

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@IndexProperty({isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public contributors:Array<WaihonaUserRef> = [];//Array of GUIDs (user ids)

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@IndexProperty({isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public members:Array<WaihonaUserRef> = []; //All members who belong to the organization (have been verified or completed the invite)

	@IndexProperty({isArray: true})
	@RegisterType({clazz: FrameworkRef, isArray: true})
	@Type(() => FrameworkRef)
	@RefType({isArray: true, Clazz: FrameworkRef})
	public frameworks:Array<FrameworkRef> = [];//Selected Frameworks (array of selectedFramework guids)

	@IndexProperty({isArray: true, itemIdentifier: "emailAddress"})
	@Type(() => UserOrgInvite)
	@RegisterType({clazz: UserOrgInvite, isArray: true})
	public invites:Array<UserOrgInvite> = [];//Array of User Org Invites (these contain GUID or (user ids))

	@IndexProperty({isArray: true})
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public usersPendingApproval:Array<WaihonaUserRef> = [];


	constructor() {
		this.configuration = new OrganizationConfiguration();
	}

	public is(flag:OrganizationFlags):boolean {
		return this.flags.indexOf(flag) != -1;
	}

	/** Returns true if the user is currently pending approval with the organization */
	public isThisUserPendingOrganizationApproval(waihonaUserRef:WaihonaUserRef):boolean {
		let foundWaihonaUserRef:WaihonaUserRef = this.usersPendingApproval.find(userPendingApproval => {
			return waihonaUserRef.guid == userPendingApproval.guid;
		});
		let isUserPendingApproval:boolean = foundWaihonaUserRef != null;
		return isUserPendingApproval;
	}


	public getDedupedUsers():Array<WaihonaUserRef> {
		//This isnʻt very efficient, but it runs on the user machine, so Iʻm not inclined to worry very much.
		let deduped:Array<WaihonaUserRef> = [...this.admins];
		this.addNonMatchingElements(this.kahu, deduped);
		this.addNonMatchingElements(this.contributors, deduped);

		return deduped;
	}

	/**
	 * Returns true if the ContactInfoType is included in the contact information, false otherwise.
	 * @param {ContactInfoType} contactInfoType
	 * @returns {boolean} returns true if the contactInfo array contains the type of contact info you are looking for
	 */
	public has(contactInfoType:ContactInfoType):boolean {
		return this.contactInfo.find(item => {
			return item.type == contactInfoType;
		}) != null;
	}

	/**
	 * does stuff
	 * @param contactInfoType
	 */

	public get(contactInfoType:ContactInfoType):Array<ContactInfo> {
		return this.contactInfo.filter(item => {
			return item.type == contactInfoType;
		});
	}

	public getOne(contactInfoType:ContactInfoType):ContactInfo {
		return this.contactInfo.find(item => {
			return item.type == contactInfoType;
		});
	}

	public setOne(contactInfoType:ContactInfoType, value:string) {
		let item:ContactInfo = this.contactInfo.find(item => {
			return item.type == contactInfoType;
		});

		if (item != null) {
			if (value == "") {
				//if empty string ... delete the record that had existed
				let index:number = this.contactInfo.indexOf(item);
				this.contactInfo.splice(item as any, 1);
			} else {
				item.value = value;

			}

		} else {
			if (value != "") {
				let newItem = new ContactInfo(contactInfoType, value);
				this.contactInfo.push(newItem);
			}
		}
	}

	public isOrgAdmin(userGuid:string) {
		return this.admins.find(user => {
			return user.guid == userGuid
		}) != null;
	}

	public isOrgKahu(userGuid:string) {
		return this.kahu.find(user => {
			return user.guid == userGuid
		}) != null;
	}

	public isOrgContributor(userGuid:string) {
		return this.contributors.find(user => {
			return user.guid == userGuid
		}) != null;
	}

	private addNonMatchingElements(sourceArray:Array<WaihonaUserRef>, destinationArray:Array<WaihonaUserRef>) {
		for (let i = 0; i < sourceArray.length; i++) {
			let waihonaUserRef:WaihonaUserRef = sourceArray[i];
			if (!this.findByUid(destinationArray, waihonaUserRef.guid)) {
				destinationArray.push(waihonaUserRef);
			}
		}
	}

	private findByUid(array:Array<WaihonaUserRef>, guid:string):WaihonaUserRef {
		return array.find(item => {
			return item.guid == guid;
		});
	}


}

export enum OrganizationFlags {
	"school" = "school",
	"nonProfit" = "nonProfit",
	"organization" = "organization",
	"team" = "team",
	"company" = "company",
	"workshop" = "workshop",
	"kanaeokanaMember" = "kanaeokanaMember",
	"independent" = "independent"
}
