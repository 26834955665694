<div class="animated fadeIn">
	<h1 class="display-5 text-primary font-weight-bold mt-4"></h1><br>

	<div class="card">
		<div class="card-body">
			<div class="sections-wrapper">

				<!--sidebar div-->
				<div class="left-column">
					<!--Meta Card-->
					<div class="card border border-0" *ngIf="framework != null">
						<div class="card-body even-padding">
							<h5 class="card-title font-weight-bold">{{CB.title | localize | async}}</h5>
							<tree-root [nodes]="framework.elements" [options]="frameworkTreeOptions"
									   (activate)="onEvent($event)"></tree-root>
						</div>
					</div>
				</div>
				<!--closing sidebar div-->

				<!--Main section-->
				<div class="main-section" *ngIf="framework != null">
					<div class="card border border-0" *ngIf="framework != null">
						<div class="card-body even-padding">
							<div class="title-row">
								<!--Framework Title-->
								<h2 class="card-title text-primary font-weight-bold">{{framework.title}}</h2>
								<div class="framework-type-section">
									<h5 class="font-weight-bold align-self-end">{{CB.type | localize | async}}</h5>
									<span class="framework-type" *ngIf="framework != null">{{framework.type}}</span>
								</div>
							</div>

							<tabset>
								<tab heading="Framework Description">
									<div class="card border border-0">
										<div class="card-body text-body">
											<!--Framework Description-->
											<p class="card-text" [innerHTML]="framework.fullDescription"></p>
										</div>
									</div>
								</tab>
								<tab heading="Selected Element" *ngIf="activatedNodeName!=null" [active]="activatedNodeName!=null">
									<div *ngIf="activatedNodeName">
										<div class="card my-lg-3 my-sm-1">
											<div class="card-header">
												<h5>{{activatedNodeName}}</h5>
											</div>
											<div class="card-body text-body">
												<p class="card-text">{{activatedNodeDesc}}</p>
											</div>
										</div>
									</div>
								</tab>
							</tabset>
						</div>
					</div>
				</div>

				<div class="right-column pl-0 pr-0">
				</div>

				<!--Resource cards -->
<!--				<div class="miniCard-deck">-->
<!--					<div class="card border-0">-->
<!--						<div class="card-body">-->
<!--							&lt;!&ndash;<h4 class="text-primary font-weight-bold">Participating Organizations using {{framework.title}}:</h4>&ndash;&gt;-->
<!--							<div class="card-deck">-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->

				<!--closing tags-->
			</div>
		</div>
	</div>
</div>

