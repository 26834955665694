import {RegisterClazz} from "../RegisterType";
import {ResourceSubmission} from "./ResourceSubmission";
import {OrganizationConfiguration} from "../organization/OrganizationConfiguration";
import {Review} from "./review/Review";
import {FanContribution} from "./FanContribution";
import {Aina} from "./aina/Aina";
import {SelectableFrameworkRef} from "../frameworks/SelectableFrameworkRef";
import {Framework} from "../frameworks/Framework";
import {
	AssetTypes,
	GradeOptions,
	HawaiianCategories,
	LearningModalities,
	SpecialIndicators, SubjectCategories
} from "../../data/LessonData";
import {IOption} from "ng-select";
import {OrganizationRef} from "../organization/OrganizationRef";
import {ActivityStream} from "./ActivityStream";
import {AinaRef} from "./aina/AinaRef";
import {ResourceSubmissionRef} from "./ResourceSubmissionRef";
import {PublishedResourceRef} from "./PublishedResourceRef";
import {PublishedResource} from "./PublishedResource";
import {CollectionRef} from "../collections/CollectionRef";
import {Collection} from "../collections/Collection";

@RegisterClazz(EditResourceDataProvider)
export class EditResourceDataProvider {


	public ready:boolean = false;

	public resourceSubmission:ResourceSubmission; //the resourceSubmission contains an org ref (org guid and title only)
	public orgConfig:OrganizationConfiguration; //the configuration for the organization on the resource
	public listOfSelectableOrgRefs:Array<OrganizationRef> = [];
	public review:Review = null;
	public fanContributions:Array<FanContribution> = null;
	public assetsFolderPath:string = "";
	public imageSource:string = "";
	public commentsForReviewer:string = "";
	public activityStream:ActivityStream = null;

	//meta tag support //todo: create a database record for tags and a service to crud them
	public ainaList:Array<Aina> = [];
	public ainaRefList:Array<AinaRef> = [];
	public assetTypes:Array<IOption> = AssetTypes;
	public gradeOptions:Array<IOption> = GradeOptions;
	public subjectCategories:Array<IOption> = SubjectCategories;
	public hawaiianCategories:Array<IOption> = HawaiianCategories;
	public learningModalities:Array<IOption> = LearningModalities;
	public specialIndicators:Array<IOption> = SpecialIndicators;
	public resourceRefs:Array<PublishedResourceRef> = [];
	public selectableResourceRefs:Array<PublishedResourceRef> = [];

	//framework support //todo: set up in helper service after we figure out what we are doing with frameworks. Currently missing from component.

	public selectableFrameworks:Array<SelectableFrameworkRef> = [];
	/*	public selectableFrameworkRef:BehaviorSubject<SelectableFrameworkRef> = new BehaviorSubject<SelectableFrameworkRef>(null);*/
	public frameworksList:Array<Framework> = null;

	//collections support
	public selectableCollections:Array<Collection> = [];
	public filteredCollections:Array<Collection> = [];
	public selectedCollections:Array<Collection> = []




}
