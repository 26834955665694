import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable} from "rxjs";
import {classToPlain} from "class-transformer";
import {NGXLogger} from "ngx-logger";
import {AbstractFirebaseFunctionsFactory} from "./AbstractFirebaseFunctionsFactory";
import {AbstractFirebaseFunction, IServerError, IWarmUpReturn} from "./AbstractFirebaseFunction";
import {NewUserRegistration} from "../../domain/user/NewUserRegistration";
import {WaihonaUser} from "../../domain/user/WaihonaUser";

interface RegistrationFunctionWrappers {
	register: AbstractFirebaseFunction<NewUserRegistration | string, WaihonaUser>,
}

@Injectable({
	providedIn: 'root',
} as any)
export class RegistrationFunctions extends AbstractFirebaseFunctionsFactory {

	public methods:RegistrationFunctionWrappers;

	constructor(protected functions:AngularFireFunctions, protected logger:NGXLogger) {
		super(functions, {register: [NewUserRegistration, WaihonaUser]});
	}

	public register$(message:NewUserRegistration | string):Observable<WaihonaUser | IServerError<NewUserRegistration>> {
		this.logger.info(`RegistrationFunctions is calling the 'register' firebase function on the server.`);
		this.logger.info(`RegistrationFunctions::register$`);
		let response:Observable<WaihonaUser | IServerError<NewUserRegistration>> = this.methods.register.call(message) as Observable<WaihonaUser | IServerError<NewUserRegistration>>;
		this.logger.info(`RegistrationFunctions::register$ done`);
		return response;
	}

	public warmUpRegistration$() {
		this.logger.info(`RegistrationFunctions is warming up the 'register' firebase function on the server.`);
		this.logger.info(`RegistrationFunctions::warmUpRegistration$`);
		let response:Observable<IWarmUpReturn> = this.methods.register.warmUp();
		this.logger.info(`RegistrationFunctions::warmUpRegistration$ done`);
		return response;
	}

}
