import {Converter} from "../ConversionService";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {OrganizationRef} from "../../domain/organization/OrganizationRef";

export class OrganizationRefToWaihonaUserOrganizationConverter implements Converter {

    public from:typeof OrganizationRef;
    public to:typeof WaihonaUserOrganization;

    constructor() {
        this.from = OrganizationRef;
        this.to = WaihonaUserOrganization;
    }

    public convert(source:OrganizationRef):WaihonaUserOrganization {

        //Create the target object
        let waihonaUserOrganization:WaihonaUserOrganization = new WaihonaUserOrganization();
	        waihonaUserOrganization.organizationGuid = source.guid;
	        waihonaUserOrganization.organizationName = source.title;

        return waihonaUserOrganization;
    }
}
