<div class="animated fadeIn">

	<!--Alert div that will show when a resource has not yet been published-->
	<div *ngIf="resourceNotYetPublishedToDatabase"
	     class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
		<strong>{{CB.not_yet_published | localize | async}}</strong>
		<span class="spinner-border spinner-border-sm mx-4" role="status"></span>
	</div>
	<!--end alert div-->

	<h1 class="display-5 text-primary font-weight-bold mt-4"></h1><br>

	<!--activity stream-->
	<ng-container
			*ngIf="publishedResource != null && usersVersionOfResourceSubmission != null &&  currentUser != null && currentUser.guid == submitter.guid">
        <span *ngIf="isActivityStreamVisible == false; else close" role="button" appAsideMenuToggler
              class="navbar-toggler-icon bounce-top fas fa-3x text-info fa-chevron-circle-left"
              (click)="isActivityStreamVisible = true"></span>
		<ng-template #close>
			<span class="navbar-toggler-icon fas fa-3x text-info fa-chevron-circle-right" role="button"
			      appAsideMenuToggler (click)="isActivityStreamVisible = false"></span>
		</ng-template>
		<review-aside [resourceSubmission]="usersVersionOfResourceSubmission"></review-aside>
	</ng-container>


	<div class="card" *ngIf="publishedResource != null">
		<div class="card-body">
			<div class="row">
				<!--sidebar div-->
				<div class="col-md-3">

					<!--Meta Card-->
					<div class="card border border-0 bg-light">
						<div *ngIf="publishedResource.resourceSubmission.hasImage; else altImg">
							<!--checks for user-uploaded cover image; otherwise uses a stock category graphic -->
							<img class="card-img border border-light" src="{{this.urlService.resourceImage.published(this.publishedResourceGuid, this.publishedResource.version.major)}}"
							     alt="{{LocalizeTools.document(publishedResource.resourceSubmission, ResourceSubmissionTextContent).title}}">
						</div>
						<ng-template #altImg>
							<img class="card-img border border-light"
							     src="./assets/img/categories/{{publishedResource.resourceSubmission.subjectCategories[0].value}}.png"
							     alt="{{publishedResource.resourceSubmission.subjectCategories[0].value}}">
						</ng-template>
						<div class="card-body card-text">
							<resource-submission-meta [resourceSubmission]="publishedResource.resourceSubmission"></resource-submission-meta>
							<br>
							<p class="font-italic my-0">{{CB.version | localize | async}} {{publishedResource.resourceSubmission.version.major}}
								.{{publishedResource.resourceSubmission.version.minor}}</p>
							<p class="font-italic">Published on {{publishedResource.resourceSubmission.getApprovedAsTime()}}</p>
						</div>
					</div>

					<!--status box for content owners and collaborators-->
					<div *ngIf="isOwner || isCollaborator">
						<resource-status-box [resourceSubmission]="publishedResource.resourceSubmission"></resource-status-box>
					</div>

					<!--buttons-->
		            <div class="card border border-0">

			            <!--show message button if the user is not the submitter-->
			            <button *ngIf="!isOwner" role="button" class="btn btn-primary" (click)="onSendMessageClick()">{{CB.question | localize | async}}</button>

						<!--"add to collection" button-->
						<button role="button" class="btn btn-primary" href="javascript:void(0);" (click)="onClickAddToCollection()">{{CB.add_to_collection | localize | async}}</button>

						<button (click)="onShareLinkClick($event)" role="button" class="btn btn-primary">{{CB.share | localize | async}}</button>

						<!--show contribute button if the submitter enabled fan contributions and not viewing as owner or collaborator-->
			            <button *ngIf="publishedResource.resourceSubmission.configuration.allowFanContributions && this.currentUser && !isOwner && !isCollaborator" role="button" class="btn btn-secondary" (click)="openFanContributionModal()">{{CB.contribute | localize | async}}</button>

			            <!--download-->
			            <button role="button" class="btn btn-secondary" (click)="downloadResourceZip($event)" [disabled]="publishStatus && publishStatus.status.archive !== PublishState.success">{{CB.download | localize | async}}
				            <span *ngIf="publishStatus && publishStatus.status.archive !== PublishState.success && publishStatus.status.archive !== PublishState.error" class="spinner-border spinner-border-sm mx-4" role="status"></span>
			            </button>

			            <!--show edit button if user can edit-->
			            <button *ngIf="canEdit" class="btn btn-dark" href="javascript:void(0);" [routerLink]="['/resources/users', publishedResource.resourceSubmission.submitter.guid, publishedResource.resourceSubmission.guid, 'edit']">{{CB.edit | localize | async}}</button>

			            <!--show unpublish button if user can unpublish the resource-->
			            <button role="button" class="btn btn-dark" *ngIf="canUnpublish" (click)="unpublish()">{{CB.unpublish | localize | async}}</button>

						<!--locks-->
						<button class="hidden btn btn-primary" href="#">{{CB.favorite | localize | async }}</button>
						<ng-container *ngIf="currentUser && isAbleToUnlockResources">
							<button class="btn btn-primary" href="javascript:void(0)" (click)="onToggleLockStatusClick(this.publishedResource)">
								<span *ngIf="publishedResource.resourceSubmission.configuration.allowPublicAccess else locked" class="d-flex justify-content-center" style="z-index: 100" tooltip="This resource is unlocked for all users." placement="left">
										<span class="p-2 fas fa-lock"></span><span class="d-inline-flex flex-column justify-content-center">&nbsp;&nbsp;{{CB.lock | localize | async }}</span>
									</span>
								<ng-template #locked>
									<span class="d-flex justify-content-center" style="z-index: 100" tooltip="This resource is locked for all users." placement="left">
										<span class="p-2 fas fa-lock"></span><span class="d-inline-flex flex-column justify-content-center">&nbsp;&nbsp;{{CB.unlock | localize | async }}</span>
									</span>
								</ng-template>
							</button>
						</ng-container>

			            <!--todo: wire up the hidden buttons-->
			            <button class="hidden btn btn-primary" href="#">{{CB.favorite | localize | async }}</button>
			            <button class="hidden btn btn-primary" href="#">{{CB.collect | localize | async }}</button>
			            <!--<button class="hidden btn btn-primary" href="#">{{CB.share | localize | async}}</button>-->

		            </div>

					<!--closing sidebar div-->
				</div>

				<!--Main section-->
				<div class="col-md-9">
					<div class="card border border-0">
						<lesson-content [resourceSubmission]="publishedResource.resourceSubmission"
						                [submitter]="publishedResource.resourceSubmission.submitter"
						                [reviewers]="publishedResource.reviewers"
										[publishStatus] ="publishStatus"
										[collections]="collections"
										(onResourceRefClick)="onRelatedResourceClick($event.valueOf())">
						</lesson-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
