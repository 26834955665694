import {Component, Injector, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ResourceChat} from "../../../../domain/resources/chat/ResourceChat";
import {ResourceChatService} from "../../../../services/ResourceChatService";
import {BaseComponent} from "../../../BaseComponent";
import {Localization} from "../../../../data/Localization";

@Component({
	selector: 'review-chat',
	templateUrl: './review-chat.component.html',
	styleUrls: ['./review-chat.component.scss']
})
export class ReviewChatComponent extends BaseComponent implements OnChanges {

	@Input()
	public chat:ResourceChat;

	private cbContext = Localization.template.resource.review_chat;
	public CB = {
		prompt: this.cbContext.prompt,
		placeholder: this.cbContext.placeholder,
		send: this.cbContext.send,
		none: this.cbContext.none
	};

	constructor(protected injector:Injector,
	            private resourceChatService:ResourceChatService) {
		super(injector);
		this.localizationService.registerAndLocalize("ReviewChatComponent", this.CB);

	}
	ngOnChanges(changes:SimpleChanges):void {
		this.logger.info("this.chat: " + this.chat);
	}

	//** MM/DD/YY 2:30P */
	public toFancyDateString(date:Date):string {
		let month:string = "" + (date.getMonth()+1); //month is idiotic, and zero based
		let day:string = "" + date.getDate(); //same same
		let year:string = ("" + date.getFullYear()).slice(2); //last 2 digits

		let dateString:string = `${month}/${day}/${year}`;
		let timeString:string = this.formatAMPM(date);

		return `${dateString} ${timeString}`;

	}

	private formatAMPM(date:Date):string {
		let hours:number = date.getHours();
		let minutes:number = date.getMinutes();
		let ampm:string = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
		let minutesString:string = minutes < 10 ? '0'+minutes : "" + minutes;
		let strTime:string = hours + ':' + minutesString + ' ' + ampm;

		return strTime;
	}

	public onEnter(value:string) {
		if(this.chat) { //TODO: take this out and properly disable UI if not set
			this.resourceChatService.sendMessageToChat(this.chat.resourceGuid, value);
		}
	}


}
