import {
	AbstractFirestoreRepository,
	QueryCriterion,
	QueryCriteriaBuilder,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";
import {Review} from "../../domain/resources/review/Review";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {IRepository} from "./IRepository";
import {ResourceSubmissionStatus} from "../../domain/resources/ResourceSubmission";
import {RefCachingService} from "../RefCachingService";
import {CollectionReference, Query} from "@angular/fire/firestore/interfaces";


export interface IReviewRepository extends IRepository<Review> {
	listReviewsByReviewer$(reviewerGuid:string):Observable<Review[]>;
	getAllReviewsForOrganization$(orgGuid:string):Observable<Review[]>
}

@Injectable({
	providedIn: 'root',
} as any)
export class ReviewRepository extends AbstractFirestoreRepository<Review> implements IReviewRepository {

	public by = {
		condition: {
			anyOfTheseOrgGuids: (orgGuids:Array<string>) =>
				QueryCriterion.create("guid", WhereFilterOperations.In, orgGuids),
			thisOrgEquals: (orgGuid:string) =>
				QueryCriterion.create("resourceSubmission.organization.guid", WhereFilterOperations.EqualTo, orgGuid),
			reviewerIncludes: (reviewerGuid:string) =>
				QueryCriterion.create("@reviewers", WhereFilterOperations.ArrayContains, reviewerGuid),
			statusEquals: ( status:ResourceSubmissionStatus) =>
				QueryCriterion.create("resourceSubmission.status", WhereFilterOperations.EqualTo, status)
		},
		query: {
			listReviewsByOrgAndReviewerWithStatus$: (orgGuid:string, reviewerGuid:string, status:ResourceSubmissionStatus):QueryFn => {
				let queryCriteriaBuilder:QueryCriteriaBuilder = new QueryCriteriaBuilder()
					.where(this.by.condition.thisOrgEquals(orgGuid))
					.where(this.by.condition.reviewerIncludes(reviewerGuid))
					.where(this.by.condition.statusEquals(status));
				return queryCriteriaBuilder.toQueryFn();
			}
		}
	};


	constructor(protected db:AngularFirestore, protected refCachingService:RefCachingService) {
		super(Review, db, db.collection("Reviews"));
		this.refCachingService.registerProcessor(this);
	}
	
	public listReviewsByReviewer$(reviewerGuid:string):Observable<Review[]> {
		let resourceGuidMatchesReviewerUid:QueryFn = (ref) => {
			return ref.where("@reviewers", 'array-contains', reviewerGuid);
		};
		//TODO:Not sure if this works
		return this.query$(resourceGuidMatchesReviewerUid);
	}

	public listReviewsBySubmitter$(submitterGuid:string):Observable<Review[]> {
		let resourceGuidMatchesSubmitterUid:QueryFn = (ref) => {
			return ref.where("resourceSubmission.submitter.guid", '==', submitterGuid);
		};
		return this.query$(resourceGuidMatchesSubmitterUid);
	}

	public listReviewsBySubmitterWithStatus$(submitterGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> {
		let resourceGuidMatchesSubmitterUidAndStatus:QueryFn = (ref) => {
			return ref.where("resourceSubmission.submitter.guid", '==', submitterGuid)
				.where("resourceSubmission.status", '==', status);
		};
		return this.query$(resourceGuidMatchesSubmitterUidAndStatus);
	}

	public listReviewsByReviewerWithStatus$(reviewerGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> {
		let resourceGuidMatchesReviewerUidAndStatus:QueryFn = (ref) => {
			return ref.where("@reviewers", 'array-contains', reviewerGuid)
				.where("resourceSubmission.status", '==', status);
		};
		return this.query$(resourceGuidMatchesReviewerUidAndStatus);
	}

	public listReviewsByOrgAndReviewerWithStatus$(orgGuid:string, reviewerGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> {
		return this.query$(this.by.query.listReviewsByOrgAndReviewerWithStatus$(orgGuid, reviewerGuid, status));
	}


	/** Get all reviews (not started, in progress, declines (or back to submitter), ... basically until it has received its final approval */
	public getAllReviewsForOrganization$(orgGuid:string):Observable<Review[]> {
		let resourceGuidMatchesOrgGuid:QueryFn = (ref) => {
			return ref.where("resourceSubmission.organization.guid", '==', orgGuid);
		};
		return this.query$(resourceGuidMatchesOrgGuid);
	}


	/** Get all reviews for an organization with a given status */
	public getAllReviewsForOrganizationWhereStatus$(orgGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> {
		let resourceGuidMatchesOrgGuid:QueryFn = (ref) => {
			return ref.where("resourceSubmission.organization.guid", '==', orgGuid)
				.where("resourceSubmission.status", "==", status);
		};
		return this.query$(resourceGuidMatchesOrgGuid);
	}
}
