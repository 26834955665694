import {Component, Injector, OnInit} from '@angular/core';
import {BadgeService} from "../../../../services/BadgeService";
import {PermissionType} from "../../../../domain/user/Permission";
import {Localization} from "../../../../data/Localization";
import {Badge} from "../../../../domain/badges/Badge";
import {BaseComponent} from "../../../BaseComponent";
import {SupportedLanguage} from "../../../../domain/SupportedLanguages";
import {BadgeTextContent} from "../../../../domain/badges/BadgeTextContent";

@Component({
	selector: 'app-list-badges',
	templateUrl: './list-badges.component.html',
	styleUrls: ['./list-badges.component.scss']
})
export class ListBadgesComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.badges.list;
	public CB = {
		header: this.cbContext.header,
		create: this.cbContext.create,
	};

	public badges:Array<Badge> = [];
	public BADGE_TEXT_CONTENT = BadgeTextContent;

	constructor(protected injector:Injector,
	            private badgeService:BadgeService) {
		super(injector);

		this.localizationService.registerAndLocalize("ListBadgesComponent", this.CB);

		this.badgeService.listBadges$().subscribe(badges => {
			this.badges = badges;
		})
	}

	ngOnInit() {
		this.logger.info("ListBadgesComponent::ngOnInit");
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};

	public get currentLanguage():SupportedLanguage {
		return this.localizationService.language;
	}

	public get canCreateBadge():boolean {
		return this.roleService.hasPermissionFor(PermissionType.create_badge);
	}

	public getBadgeArt(badge:Badge):string {
		return this.urlService.imageUrl.badge(badge.guid);
	}

	public getBadgeContent(badge):BadgeTextContent {
		return badge.documentTextContent[this.currentLanguage] ? badge.documentTextContent[this.currentLanguage] : badge.documentTextContent[SupportedLanguage.en];
	}

}
