import {BehaviorSubject} from 'rxjs/Rx';
import {AngularFireUploadTask} from '@angular/fire/storage';
import {noop} from 'rxjs/internal-compatibility';
import {UploadTaskSnapshot} from '@angular/fire/storage/interfaces';
import getClassNameForExtension from 'font-awesome-filetypes';
import {AssetSource, LearningAsset} from '../../domain/resources/LearningAsset';
import {IOption} from 'ng-select';
import {AssetTypes} from '../../data/LessonData';
import {FirebaseFileInfo} from '../storage/FirebaseFileInfo';

declare var require;
const pretty = require('prettysize');
const mimetype2fa = require('mimetype-to-fontawesome')({ prefix: 'fa-' });


export class LearningAssetUpload {

    public progress:number = 0;
    public file:File;
    public name:string;
    public task:AngularFireUploadTask;
    public overwrite:boolean = false;
    public keepExistingMetadata:boolean = true;


    public learningAsset:LearningAsset;
    public assetTypes: Array<IOption> = AssetTypes;

    public metadata:any;
    public totalBytes:number;
    public currentBytes:number;

    public storageType:string;
    public storageUri:string;
    public storageId:string;
    public percentage$:BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public status$:BehaviorSubject<UploadStatus> = new BehaviorSubject<UploadStatus>(UploadStatus.waiting);

    public constructor(file:File, altName:string = null) {
      this.file = file;
      this.name = file.name;
      if(altName != null) {
          this.name = altName;
      }
      this.learningAsset = new LearningAsset(this.name);
      this.learningAsset.assetSource = AssetSource.userUpload;
      this.learningAsset.extension = this.fileExtension;
    }

    public track(task:AngularFireUploadTask) {
        this.task = task;
        this.status = UploadStatus.uploading;
        //Update percentage as it uploads
        if(task.percentageChanges() != null) {
            task.percentageChanges().subscribe(percentage => {
              this.percentage = percentage;
              console.info("" + percentage + "%");
            }, noop, noop);
        }
        const self = this;
        //Catch upload success and error
        task.catch((error) => {
          self.onUploadError.apply(self, [error]);
        })
        .then((uploadTaskSnapshot:UploadTaskSnapshot) => {
          self.onUploadSuccess.apply(self, [uploadTaskSnapshot]);
        });


    }
  public set percentage(progress:number) {
    this.percentage$.next(progress);
  }
  public get percentage():number {
    return this.percentage$.getValue();
  }
    public set status(status:UploadStatus) {
      this.status$.next(status);
    }
    public get status():UploadStatus {
      return this.status$.getValue();
    }

    public cancel():void {
      this.task.cancel();
      this.status = UploadStatus.cancelled;
    }
    public pause():void {
        this.task.pause();
      this.status = UploadStatus.paused;
    }
    public resume():void {
      this.task.resume();
      this.status = UploadStatus.uploading;
    }

    public get statusIcon() {
      return UploadStatusIcon[this.status];
    }

    public get currentPercentage() {
        return Math.floor(this.percentage$.getValue());
    }

    /** File Size label */
    public get currentUploadSize():string {
        if(this.file && this.file.size > 0) {
            return pretty(this.file.size * (this.percentage/100))
        }

        return "?";
    }

    /** File Size label */
    public get fileSizeLabel():string {
      if(this.file && this.file.size > 0) {
        return pretty(this.file.size)
      }

      return "?";
    }

    public get fileType():string {
      if(this.file && this.file.size > 0) {
        return this.file.type;
      }
      return "?";
    }

    public get fileExtension():string {
      const a = this.file.name.split(".");
      if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
        return "";
      }
      return a.pop();    // feel free to tack .toLowerCase() here if you want
    }

    /** Font Awesome File Icons */
    public get fontAwesomeFileIcon():string {
      const ext:string = this.fileExtension;
      let iconClass = "";
      if(ext == "") {
        iconClass = mimetype2fa(this.fileType);
      } else {
        iconClass = getClassNameForExtension(ext);
      }

      return iconClass;       //TODO need to handle other icon/file types
    }

    protected onUploadError(error) {
        console.info("upload failed for " +this.file.name);
        this.status = UploadStatus.failure;
    }
    protected onUploadSuccess(uploadSnapshot:UploadTaskSnapshot) {
    	if(uploadSnapshot === undefined) {
    		console.error("onUploadSuccess Exiting.  Wasn't successful in upload (probably cancelled)");
    		return;
	    }
	//todo: add throw to handle anon upload cases where user is null
        console.info("upload was successful");

        //Fetch the download URL of the Storage file
        uploadSnapshot.ref.getDownloadURL().then((downloadURL) => {


        const fileInfo:FirebaseFileInfo = new FirebaseFileInfo();
          fileInfo.name = uploadSnapshot.metadata.name;
          fileInfo.contentType = uploadSnapshot.metadata.contentType;
          fileInfo.size = uploadSnapshot.metadata.size;
          fileInfo.url = downloadURL;
          fileInfo.created = uploadSnapshot.metadata.timeCreated;
          fileInfo.bucket = uploadSnapshot.metadata.bucket;
          fileInfo.path = uploadSnapshot.metadata.fullPath;

          this.learningAsset.fileInfo = fileInfo;
          this.learningAsset.created = new Date();  //TODO: handle on server
          this.status = UploadStatus.success;

        });
        //We have full metadata now
        //downloadURL: string | null;
        //metadata: firebase.storage.FullMetadata;
        //ref: firebase.storage.Reference;
        //state: firebase.storage.TaskState;
        //task: firebase.storage.UploadTask;
        //totalBytes: number;

    }

}

/**
 Must be One of the following:
 waiting = "waiting"-       not yet uploaded, we are waiting for a click
 cancelled = "cancelled"-     upload cancelled by the user
 paused = "paused"-         lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
 uploading = "uploading"-   We are currently uploading (either started or resumed)
 failure = "failure"-      Upload failed
 success = "success"-       Uploaded successfully
 */
export enum UploadStatus {
    preupload_problem = "preupload_problem",      // not yet uploaded, we are waiting for a click
    waiting = "waiting",      // not yet uploaded, we are waiting for a click
    cancelled = "cancelled",        // lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
    paused = "paused",        // lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
    uploading = "uploading",  // We are currently uploading (either started or resumed)
    failure = "failure",      // Upload failed
    success = "success"      // Uploaded successfully
}

export enum UploadStatusIcon {
    preupload_problem = "<i class=\"fas fa-octagon\"></i>",
    waiting = "<i class=\"fas fa-hourglass-start\"></i>",      // not yet uploaded, we are waiting for a click
    cancelled = "<i class=\"fas fa-ban\"></i>",        // lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
    paused = "<i class=\"fas fa-pause\"></i>",        // lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
    uploading = "<i class=\"fas fa-arrow-up\"></i>",  // We are currently uploading (either started or resumed)
    failure = "<i class=\"fas fa-exclamation-circle\"></i>",      // Upload failed
    success = "<i class=\"fas fa-check-circle\"></i>"      // Uploaded successfully


}
