
export class SupportedFileTypes {

    public static supportedIcons:Array<string> = ["aac", "ai", "aiff", "avi", "bmp", "c", "cpp", "css", "csv", "dat", "dmg", "doc", "docx", "dotx", "dwg", "dxf", "eps", "epub", "exe", "flv", "gif", "h", "hpp", "html", "ics", "ipa", "iso", "java", "jpg", "js", "language", "kf8", "less", "mid", "mobi", "mov", "mp3", "mp4", "mpg", "odf", "ods", "odt", "otp", "ots", "ott", "pdf", "php", "png", "ppt", "psb", "psd", "py", "qt", "rar", "rb", "rtf", "sass", "scss", "sql", "tga", "tgz", "tiff", "txt", "wav", "xls", "xlsx", "xml", "yml", "zip"];
    //Should have an unsupported filetypes
    public static alternates:Array<{from:string, to:string}> = [
        {from: "pptx", to: "ppt"}
    ];

    public static getFileName(extension:string):string {
        if(SupportedFileTypes.supportedIcons.indexOf(extension) != -1) {
            const fileExtension:string = extension.toLowerCase();
            return fileExtension + ".png";
        }
        let matchingAlternate = SupportedFileTypes.alternates.find(item => { return item.from == extension;});
        if(matchingAlternate != null) {
            return matchingAlternate.to + ".png"; //for example use .ppt image for .pptx
        } else {
            return "_blank.png";
        }
    }
}
