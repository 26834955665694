import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {OrganizationTitleMessage, UserProfileMessage} from "../messages/UserProfileMessage";
import {classToClass} from "class-transformer";

export class WaihonaUserToUserProfileMessageConverter {

	public from:typeof WaihonaUser;
	public to:typeof UserProfileMessage;

	constructor() {
		this.from = WaihonaUser;
		this.to = UserProfileMessage;
	}

	public convert(source:WaihonaUser):UserProfileMessage {


		let waihonaUserCopy:WaihonaUser = classToClass(source);

		//Create the target object
		let userProfileMessage:UserProfileMessage = new UserProfileMessage();
			userProfileMessage.guid = source.guid;
			userProfileMessage.firstName = source.firstName;
			userProfileMessage.lastName = source.lastName;
			userProfileMessage.altFirstName = source.altFirstName;

			userProfileMessage.professionalName = source.professionalName;

			userProfileMessage.documentTextContent = waihonaUserCopy.documentTextContent;

			for(let i:number = 0; i < source.organizations.length; i++) {
			    let waihonaUserOrganization:WaihonaUserOrganization = source.organizations[i];
				let organizationTitleMessage:OrganizationTitleMessage = new OrganizationTitleMessage();
					organizationTitleMessage.guid = waihonaUserOrganization.organizationGuid;
					organizationTitleMessage.userTitle = waihonaUserOrganization.userTitle;
				userProfileMessage.organizations.push(organizationTitleMessage);
			}


		return userProfileMessage;
	}

}
