<div class="modal-header bg-info">
	<h4 class="modal-title">{{CB.modal_title | localize | async}}</h4>
	<button type="button" class="close" (click)="onCancel()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p>{{CB.modal_body | localize | async}}</p>
	<form autocomplete="off">
		<div class="form-row">
			<div class="form-group">
			<textarea class="form-control bg-light"
			          rows="6" cols="70"
			          id="userFeedback" name="userFeedback"
			          [(ngModel)]="feedback"
			          placeholder="{{CB.modal_placeholder | localize | async}}">
			</textarea>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer  border-0">
	<button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">{{CB.modal_cancel | localize | async}}</button>
	<button type="submit" class="btn btn-primary" (click)="onSubmitFeedback()">{{CB.modal_send | localize | async}}</button>
</div>
