import {Component, EventEmitter, Injector, OnInit, Output} from "@angular/core";
import {WaihonaUserService} from "../../../services/WaihonaUserService";
import {ReviewService} from "../../../services/ReviewService";
import {ResourceSubmissionStatus} from "../../../domain/resources/ResourceSubmission";
import {ResourceSubmissionService} from "../../../services/ResourceSubmissionService";
import {PermissionType} from "../../../domain/user/Permission";
import {BaseComponent} from "../../BaseComponent";
import {Review} from "../../../domain/resources/review/Review";
import {combineLatest, Observable, Subject} from "rxjs";
import {WaihonaUserOrganization} from "../../../domain/user/WaihonaUserOrganization";
import {WaihonaUser} from "../../../domain/user/WaihonaUser";
import {concat} from "lodash"
import {FanContributionService} from "../../../services/FanContributionService";
import {ContentBlockRegistryValue} from "../../../domain/content/ContentBlockRegistryValue";
import {Localization} from "../../../data/Localization";
import {AppFooterComponent} from "../app-footer";
import {DocumentTextContentService} from "../../../services/common/DocumentTextContentService";
import {SiteConfigurationService} from "../../../services/SiteConfigurationService";
import {AppHeaderAlert} from "../../../domain/config/AppHeaderAlert";
import {AuthState} from "../../../services/AuthService";
import {SupportedLanguage} from "../../../domain/SupportedLanguages";
import {InboxService} from "../../../services/InboxService";


@Component({
	selector: 'app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['app-header.component.scss'],
})

export class AppHeaderComponent extends BaseComponent implements OnInit {

	private cbContext =  Localization.template.app_header;
	public CB = {
		top_resources: this.cbContext.top_level.resources,
		top_contributors: this.cbContext.top_level.contributors,
		top_organizations: this.cbContext.top_level.organizations,
		top_more: this.cbContext.top_level.more,
		top_login: this.cbContext.top_level.
log_in,
		top_greeting: this.cbContext.top_level.greeting,
		more_collections: this.cbContext.more_menu.collections,
		more_badges: this.cbContext.more_menu.badges,
		more_frameworks: this.cbContext.more_menu.frameworks,
		more_community: this.cbContext.more_menu.community,
		more_about: this.cbContext.more_menu.about,
		dropdown_inbox: this.cbContext.dropdown_menu.inbox,
		dropdown_profile: this.cbContext.dropdown_menu.profile,
		dropdown_reviews: this.cbContext.dropdown_menu.my_reviews,
		dropdown_resources: this.cbContext.dropdown_menu.resources,
		dropdown_organizations: this.cbContext.dropdown_menu.organizations,
		dropdown_collections: this.cbContext.dropdown_menu.collections,
		dropdown_admin: this.cbContext.dropdown_menu.admin,
		dropdown_logout: this.cbContext.dropdown_menu.log_out,
		localization_numberBlocks: this.cbContext.localization_menu.numberBlocks,
		localization_viewAll: this.cbContext.localization_menu.viewAll,
	};

	@Output() onLoggedOut = new EventEmitter();

	public numberOrgsPendingInviteAcceptance:number = 0;
	public numberPendingRevision:number = 0;
	public numberUnassignedPendingReviews:number = 0;
	public numberPendingReviewContinuation:number = 0;
	public numberOfFanContributions:number = 0;
	public totalAllActions:number = 0;
	public isCollapsed:boolean = true;
	public isOleloHawaii:boolean = false;

	public hideAlert:boolean = false;
	public hasAlert:boolean = false;
	public alertMessage:AppHeaderAlert;

	constructor(protected injector:Injector,
				private waihonaUserService: WaihonaUserService,
				private resourceSubmissionService: ResourceSubmissionService,
				private fanContributionService:FanContributionService,
				private reviewService: ReviewService,
                private inboxService:InboxService,
        private siteConfigurationService:SiteConfigurationService,
        private docTextContentService:DocumentTextContentService) {
		super(injector);

		this.localizationService.registerAndLocalize("AppHeaderComponent", this.CB);
		this.isOleloHawaii = this.localizationService.language == SupportedLanguage.haw;
		this.authService.currentUser$.subscribe((waihonaUser:WaihonaUser) => {
			if (waihonaUser == null) {
				this.numberPendingRevision = 0;
				this.numberOfFanContributions = 0;
				this.numberOrgsPendingInviteAcceptance = 0;
				this.numberUnassignedPendingReviews = 0;
				this.numberPendingReviewContinuation = 0;
			} else {
				this.numberOrgsPendingInviteAcceptance = this.numberOfCurrentUserOrgsPendingActions;

				this.numberOfFanContributionsWaiting.subscribe(number => {
					this.numberOfFanContributions = number;
				});

				this.numberOfSubmissionsByCurrentUserPendingRevision.subscribe((numberOfItems:number) => {
					this.numberPendingRevision = numberOfItems;
				});

				this.numberOfReviewsForCurrentUserOrgsPendingReview.subscribe(number => {
					this.numberUnassignedPendingReviews = number;
				});

				this.numberOfReviewsForCurrentUserPendingReviewContinuation.subscribe(number => {
					this.numberPendingReviewContinuation = number;
				});
			}
			//dont want actions same as unread so dont add messages back
			this.totalAllActions = this.numberOfFanContributions + this.numberPendingRevision + this.numberOrgsPendingInviteAcceptance + this.numberUnassignedPendingReviews + this.numberPendingReviewContinuation;
		});

		this.siteConfigurationService.isLoaded.subscribe(isLoaded => {
			if (isLoaded) {
				this.alertMessage = this.siteConfigurationService.siteConfiguration.messages;
				if (this.alertMessage != null && this.alertMessage.isActive == true) {
					this.hasAlert = true;
				}
				if (this.hasAlert && this.alertMessage.shouldBeAutoDismissed) {
					let timeout = this.alertMessage.autoDismissTimeout;
					console.warn(`auto-dismissing alert message in ${timeout} seconds`);
					setTimeout(() => {
						this.hideAlert = true;
					}, timeout * 1000);
				}
			}
		});
	}

	public onDismissAlert() {
		this.hideAlert = true;
	}

	public pathToObjectRef(path:string):{} {
		return Localization.pathToObjectRef(path);
	}

	public get contentBlocks():Array<ContentBlockRegistryValue> {
		let self = this;
		let allContentBlocks:Array<ContentBlockRegistryValue> = this.localizationService.currentContentBlocks;
		return allContentBlocks.filter(contentBlockRegistryValue => {
			let isADumbHeaderOrFooter:boolean = [ "AppHeaderComponent","AppFooterComponent"].includes(contentBlockRegistryValue.className);
			if(isADumbHeaderOrFooter) {
				return false;
			}
			return self.router.url == contentBlockRegistryValue.route && !isADumbHeaderOrFooter;
		});
	}

	public set isContentBlockModeOn(value:boolean) {
		this.localizationService.isContentBlockModeOn = value;
	}
	public get isContentBlockModeOn():boolean {
		return this.localizationService.isContentBlockModeOn;
	}
	public get counts() {
		return this.inboxService.inboxCounts;
	}

	public ngOnInit(): void {
		this.isOleloHawaii = this.localizationService.language == SupportedLanguage.haw;
	}
	public isLocalizationsVisible():boolean {
		return this.roleService.hasPermissionFor(PermissionType.edit_localization);
	}
	public isInboxVisible(): boolean {
		return this.authService.currentUser != null;
	}

	public isMyReviewsVisible(): boolean {
		return this.roleService.hasPermissionFor(PermissionType.see_ability_to_review);
	}

	public isAdminDashboardVisible(): boolean {
		return this.roleService.hasPermissionFor(PermissionType.view_admin_dashboard);
	}

	public isMyOrganizationsVisible():boolean {
		return (this.currentUser.organizations.length > 0);
	}

	public login(): void {
		this.authService.login();
	}

	public logout(): void {
		this.authService.logout();
	}
	public get currentAuthState():AuthState {
		return this.authService.authState;
	}
	public get AuthState() {
		return AuthState;
	}


	public switchLanguage(): void {
		let currentLang:SupportedLanguage = this.localizationService.language;
		if (currentLang == SupportedLanguage.en) {
			this.localizationService.changeLanguage(SupportedLanguage.haw);
		} else if (currentLang == SupportedLanguage.haw) {
			this.localizationService.changeLanguage(SupportedLanguage.en);
		}
		this.isOleloHawaii = this.localizationService.language == SupportedLanguage.haw;
	}

	public getContent(pathObjectOrString:any|string): string {
		return this.localizationService.get(pathObjectOrString);
	}

	//Menu Notifications
	public get numberOfCurrentUserOrgsPendingActions():number {
		if (this.currentUser == null) {
			return 0;
		}
		return this.currentUser.organizations.filter(org => org.needsToAcceptInvite == true).length;
	}

	/** Get the number of submissions submitted by the current user which are currently in review revision */
	public get numberOfSubmissionsByCurrentUserPendingRevision():Observable<number> {
		if (this.currentUser == null) {
			return Observable.of(0);
		}
		let numberOfSubmissions:Subject<number> = new Subject<number>();

		this.reviewService.repo.listReviewsBySubmitterWithStatus$(this.currentUser.guid, ResourceSubmissionStatus.in_review_revision).subscribe((reviews:Review[]) => {
			numberOfSubmissions.next(reviews.length);
		});
		return numberOfSubmissions;
	}

	/** We want a # of total reviews,
	 *     in a PENDING state (not yet assigned a reviewer),
	 *     which is the aggregation of all orgs the user is a reviewer for */
	public get numberOfReviewsForCurrentUserOrgsPendingReview():Observable<number> {
		if (this.currentUser == null) {
			return Observable.of(0);
		}
		//we need an observable to return at end of method
		let numberOfPendingReviews$:Subject<number> = new Subject<number>();

		//We just want the users user orgs where they are capable of review
		let userOrgs:Array<WaihonaUserOrganization> = this.currentUser.organizations.filter(userOrg => {
			return this.roleService.hasPermissionFor(PermissionType.review_count, userOrg.organizationGuid);
		});
		let allOrgObservables:Array<Observable<Review[]>> = [];

		//iterate over the userʻs organizations
		for (let i:number = 0; i < userOrgs.length; i++) {
			let selectOrg:WaihonaUserOrganization = userOrgs[i];
			//get the number of reviews pending for this org
			let orgObservable:Observable<Review[]> = this.reviewService.repo.getAllReviewsForOrganizationWhereStatus$(selectOrg.organizationGuid, ResourceSubmissionStatus.submitted);
			allOrgObservables.push(orgObservable);
		}


		combineLatest(allOrgObservables).subscribe((arrayOfReviewArrays:Array<Review[]>) => {
			let first:Array<Review> = arrayOfReviewArrays[0];
			let remaining:Array<Review[]> = arrayOfReviewArrays.slice(1);

			let concatenated:Array<Review> = concat(first, ...remaining);
			numberOfPendingReviews$.next(concatenated.length);
		});

		//and return the total
		return numberOfPendingReviews$;
	}

	/** We want a # of total reviews,
	 *     in a REVIEW CONTINUATION state (returned to review after the user made revisions),
	 *     which is the aggregation of all orgs the user is a reviewer for */
	public get numberOfReviewsForCurrentUserPendingReviewContinuation():Observable<number> {
		if (this.currentUser == null) {
			return Observable.of(0);
		}

		//we need an observable to return at end of method
		let numberOfReviewsPendingContinuation$:Subject<number> = new Subject<number>();

		//We just want the users user orgs where they are capable of review
		let userOrgs:Array<WaihonaUserOrganization> = this.currentUser.organizations.filter(userOrg => {
			return this.roleService.hasPermissionFor(PermissionType.review_count, userOrg.organizationGuid);
		});
		let allOrgObservables:Array<Observable<Review[]>> = [];

		//iterate over the userʻs organizations
		for (let i:number = 0; i < userOrgs.length; i++) {
			let selectOrg:WaihonaUserOrganization = userOrgs[i];
			//get the number of reviews pending for this org
			let orgObservable:Observable<Review[]> = this.reviewService.repo.getAllReviewsForOrganizationWhereStatus$(selectOrg.organizationGuid, ResourceSubmissionStatus.in_review_continuation);
			allOrgObservables.push(orgObservable);
		}

		//combine the results
		combineLatest(allOrgObservables).subscribe((arrayOfReviewArrays:Array<Review[]>) => {
			let first:Array<Review> = arrayOfReviewArrays[0];
			let remaining:Array<Review[]> = arrayOfReviewArrays.slice(1);

			let concatenated:Array<Review> = concat(first, ...remaining);
			numberOfReviewsPendingContinuation$.next(concatenated.length);
		});

		//and return the total
		return numberOfReviewsPendingContinuation$;
	}

	public get numberOfMissingProfileItems():number {
		let numberOfMissingProfileItems:number = 0;
		let currentUserProfileTextFields = this.currentUser.documentTextContent[this.docTextContentService.defaultLanguage(this.currentUser)];
		if (currentUserProfileTextFields) {
      if (currentUserProfileTextFields.bio == "") {
        numberOfMissingProfileItems ++;
      }
    } else {
      numberOfMissingProfileItems ++;
    }

		/*if (this.currentUser.avatar == null) {
			numberOfMissingProfileItems ++;
		}*/
		return numberOfMissingProfileItems;
	}

	public get numberOfFanContributionsWaiting():Observable<number> {
		if (this.currentUser == null) {
			return Observable.of(0);
		}
		//we need an observable to return at end of method
		let numberOfFanContributions$:Subject<number> = new Subject<number>();

		this.fanContributionService.listBySubmitterId$(this.currentUser.guid).subscribe(contributions => {
			numberOfFanContributions$.next(contributions.length)
		});
		return numberOfFanContributions$
	}

}
