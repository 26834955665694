import {Pipe, PipeTransform} from '@angular/core';
import {NupepafyStringUtils} from 'app/util/NupepafyStringUtils'
import {Subject} from "rxjs";
import {convertKeyToValue} from "../helper/SearchTools";

@Pipe({
	name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

	transform(list:any[], filterText:string, searchKeysArray?:Array<IArraySearchOption | string>, resultCount?:Subject<number>):any {

		if (!list) {
			return [];
		}
		if (!filterText) {
			console.info(`no filter terms`);
			return list;
		}

		filterText = NupepafyStringUtils.nupepafy(filterText).toLowerCase();

		let filteredList:any[] = list.filter(item => {
			let matchFound:boolean = false;
			for (let key of searchKeysArray) {
				if (matchFound) {return true}
				let arraySearchOption = key as IArraySearchOption;
				if (arraySearchOption.array === true) {
					let array = convertKeyToValue(item, arraySearchOption.prop);
					for (let arrayItem of array) {
						let value:string = "";
						for (let subProp of arraySearchOption.subProps) {
							if (matchFound) {return true}
							if (arraySearchOption.merge) {
								value = value.concat(value, " ", arrayItem[subProp])
							} else {
								value = convertKeyToValue(arrayItem, subProp as string);
							}
							if (value) {
								let valueToCompare:string = NupepafyStringUtils.nupepafy(value).toLowerCase();
								matchFound = valueToCompare.includes(filterText)
							}
						}
					}
				} else {
					let value:string = convertKeyToValue(item, key as string);
					if (value) {
						let valueToCompare:string = NupepafyStringUtils.nupepafy(value).toLowerCase();
						matchFound = valueToCompare.includes(filterText)
					}
				}
			}
			return matchFound;
		});

		if (resultCount) {
			resultCount.next(filteredList.length);
		}
		return filteredList;
	}

}

interface IArraySearchOption {
	array:boolean, prop:string, subProps:string[], merge?:boolean
}
