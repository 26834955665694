import {RoleType} from "./Role";
import {RegisterClazz} from "../RegisterType";

@RegisterClazz(RoleDefinition)
export class RoleDefinition {
    public type:RoleType; //Enum
    /** The guid of the organization */
    public organization:OrgTypeDefinition; //Enum

    constructor(type?:RoleType, organization?:OrgTypeDefinition) {
        if(type != null) {
			this.type = type;
        }
		if(organization != null) {
            this.organization = organization;
		}
    }
}



export enum OrgTypeDefinition {
    "any_organization"="any_organization",
    "part_of_organization"="part-of-organization",
}
