import {AuthService} from "app/services/AuthService";
import {ToasterConfig} from 'angular2-toaster';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {SiteConfigurationService} from "./services/SiteConfigurationService";
import {AnalyticsService} from "./services/AnalyticsService";

import {NGXLogger} from 'ngx-logger';
import {LogMonitoringService} from "./services/logging/LogMonitoringService";
import {LocalizationService} from "./services/LocalizationService";
import {ContentBlockRepository} from "./services/repository/content-block.repository";
import {AppHeaderAlert} from "./domain/config/AppHeaderAlert";
import {LocalUserSettingsRepository} from "./services/repository/LocalUserSettingsRepository";
import {SupportedLanguage} from "./domain/SupportedLanguages";
import {NotificationService, ToastType} from "./services/common/NotificationService";
import {Localization} from "./data/Localization";
import {Subscription} from "rxjs";


declare var require;
declare let gtag: Function;
declare var window;
let storage_1 = require("class-transformer/storage");


@Component({
	templateUrl: './app.component.html',
	styleUrls: [
		'../scss/vendors/toastr/toastr.scss',
		'./app.component.scss'
	],
	encapsulation: ViewEncapsulation.None,
	selector: "body"
})
export class AppComponent implements OnInit {

	public hideAlert:boolean = false;
	public hasAlert:boolean = false;
	public alertMessage:AppHeaderAlert;
	public isLocalizationReady:boolean = false;
	public toasterConfig:ToasterConfig =
		new ToasterConfig({
			tapToDismiss: true,
			timeout: 5000,
			animation: 'fade',
			mouseoverTimerStop: false
		});

	private cbContext = Localization.template.app;
	public CB = {
		starting_in_olelo: this.cbContext.initialization.toasts.localization_startup.starting_in_olelo
	};

	constructor(private authService:AuthService,
	            private router:Router,
	            private localizationService:LocalizationService,
	            private contentBlockRepository:ContentBlockRepository,
	            private siteConfigurationService:SiteConfigurationService,
	            private analyticsService:AnalyticsService,
	            private notificationService:NotificationService,
	            private route:ActivatedRoute,
	            private logger:NGXLogger,
	            private localUserSettingsRepository:LocalUserSettingsRepository,
	            private logMonitoringService:LogMonitoringService) {

		this.logger.registerMonitor(this.logMonitoringService);
		this.logger.info("Launching " + environment.env + " Production Env? " + environment.production); // Logs false for default environment
		this.logger.info("Loaded Site Configuration from Environmental Variables:\n" + this.siteConfigurationService.siteConfiguration.toString());

		this.analyticsService.initialize();

		this.siteConfigurationService.isLoaded.subscribe(isLoaded => {
			if (isLoaded) {
				this.alertMessage = this.siteConfigurationService.siteConfiguration.messages;
				if (this.alertMessage != null && this.alertMessage.isActive == true) {
					this.hasAlert = true;
				}
				if (this.hasAlert && this.alertMessage.shouldBeAutoDismissed) {
					let timeout = this.alertMessage.autoDismissTimeout;
					//console.warn(`auto-dismissing alert message in ${timeout} seconds`);
					setTimeout(() => {
						this.hideAlert = true;
					}, timeout * 1000);
				}
			}
		})
	}

	public ngOnInit() {
		this.localUserSettingsRepository.get();

		let s:Subscription = this.localizationService.loadLocalizationFiles$().filter(localization => localization != null).subscribe(() => {
			console.log("We're ready");
			this.isLocalizationReady = true;
			//Need localization ready?
			if(this.localUserSettingsRepository.value.oleloHawaiiPreference.hasPreference) {
				this.localizationService.language = this.localUserSettingsRepository.value.oleloHawaiiPreference.isOleloHawaii ? SupportedLanguage.haw : SupportedLanguage.en;
				console.log(`Had a previous preference for ${this.localizationService.language}. Setting.`);

				if(this.localizationService.language == SupportedLanguage.haw) { //Only display if ʻŌlelo Hawaiʻi..
					setTimeout(() => { //The boolean will get toggled in appcomponent and the toaster-container will be initialized..but its not ready yet; so wait.
						this.notificationService.displayToast(this.CB.starting_in_olelo, ToastType.success);
					}, 500);
				}

			}
			s.unsubscribe();
		});
		console.log(`Loaded ${this.localUserSettingsRepository.value.loads++} times`);
		this.localUserSettingsRepository.set();
	}

	public get env() {
		return environment.env;
	}
	public get prettyEnv() {
		switch(this.env) {
			case 'dev':
				return "Development";
			case 'stg':
				return "Staging";
			case 'pubstg':
				return "Public Staging / Preproduction";
			case 'prd':
				return "Production";
			case 'sandbox':
				return "Sandbox Training";
		}
	}
	public get isProduction():boolean {
		return environment.env == "prd";
	}
}
