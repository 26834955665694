<!--Resource title, contributor, collaborators, and organization -->
<div class="card-body" *ngIf="resourceSubmission.submitter != null">
    <h1 class="card-title font-weight-bold text-primary">{{localize.resourceSubmission().title}}
        <span *ngIf="collections.length > 0" class="float-right" tooltip="This resource belongs to one or more collections." placement="left">
            <a [routerLink]="['/collections']" style="text-decoration: none;"><i class="fad fa-album-collection text-secondary"></i></a>
        </span>
    </h1>

    <h5 *ngIf="!resourceSubmission.configuration.hideSubmitterName" class="card-text">{{CB.contributor | localize | async}}
        <span>
            <a [routerLink]="['/users', resourceSubmission.submitter.guid]" style="text-decoration: none;">
            {{resourceSubmission.submitter.fullName}}
            </a>
        </span>
    </h5>
    <h5 class="card-text" *ngIf="resourceSubmission.organization != null">{{CB.org | localize | async}}
        <a [routerLink]="['/organizations', resourceSubmission.organization.guid]" style="text-decoration: none;">
            {{resourceSubmission.organization.title}}
        </a>
    </h5>
    <h6 *ngIf="resourceSubmission.collaborators.length > 0" class="font-italic card-text">{{CB.collaborators | localize | async}}
        <ng-container *ngFor="let collaborator of resourceSubmission.collaborators; let isLast=last">
            <a [routerLink]="['/users', collaborator.guid]" style="text-decoration: none;">
            {{collaborator.fullName}}{{isLast ? '' : ', '}}
            </a>
        </ng-container>
    </h6>
	<h6 *ngIf="isSelfReviewed()" class="card-text font-weight-bold text-secondary">{{CB.selfPublished | localize | async}}
		<i class="fas fa-question-circle text-secondary mx-1"
		   tooltip="{{CB.tooltip_selfPublished | localize | async}}"></i>
	</h6>
    <h6 *ngIf="isPublicResource();else membersOnlyResource" class="card-text font-weight-bold text-secondary">{{CB.publicResource | localize | async}}<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.tooltip_publicResource | localize | async}}"></i></h6>
    <ng-template #membersOnlyResource>{{CB.membersOnlyResource | localize | async}}<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.tooltip_membersOnlyResource | localize | async}}"></i></ng-template>
    <h6 *ngIf="!isSelfReviewed() && reviewers && reviewers.length > 0" class="font-italic card-text">{{CB.reviewer | localize | async}}
        <ng-container *ngFor="let reviewer of reviewers; let isLast=last">
            <a [routerLink]="['/users', reviewer.guid]" style="text-decoration: none;">
                {{reviewer.fullName}}{{isLast ? '' : ', '}}
            </a>
        </ng-container>
    </h6>
</div>

<!--lesson Summary-->
<div class="card-body">
    <h5 class="card-title"><strong>{{CB.summary | localize | async}}</strong></h5>
    <p class="card-text" [innerHTML]="localize.resourceSubmission().summary"></p>
</div>

<!--Resource Description-->
<div class="card-body">
    <h5 class="card-title"><strong>{{CB.content | localize | async}}</strong></h5>
    <p class="card-text" [innerHTML]="localize.resourceSubmission().description"></p>
</div>

<!--Resource Notes-->
<div *ngIf="localize.resourceSubmission().notes" class="card-body">
    <h5 class="card-title"><strong>{{CB.sources | localize | async }}</strong></h5>
    <p class="card-text" [innerHTML]="localize.resourceSubmission().notes"></p>
</div>

<!--Resource Modifications-->
<div *ngIf="localize.resourceSubmission().modifications" class="card-body">
    <h5 class="card-title"><strong>{{CB.modifications | localize | async }}</strong></h5>
    <p class="card-text" [innerHTML]="localize.resourceSubmission().modifications"></p>
</div>

<!--links-->
<div *ngIf="resourceSubmission.links && resourceSubmission.links.length > 0" class="card-body">
	<h5 class="card-title font-weight-bold">{{CB.links | localize | async}}:</h5>
	<div *ngFor="let link of resourceSubmission.links">
		<div class="alert alert-secondary alert-dismissible fade show mb-1" role="alert">
			<a class="text-black-50 font-weight-bold" href="{{link}}" target="_blank"><i class="fas fa-link mr-1 py-1"></i>{{link}}</a>
		</div>
	</div>
</div>

<!--Resource assets-->
<div class="card-body" *ngIf="resourceSubmission.files.length > 0">
    <button *ngIf="this.isInPublishedState()" role="button" class="btn btn-sm btn-secondary float-right"
			(click)="downloadResourceZip($event)"
			[disabled]="publishStatus && publishStatus.status.archive !== PublishState.success">
	    {{CB.zip | localize | async}}
	    <span *ngIf="publishStatus && publishStatus.status.archive !== PublishState.success && publishStatus.status.archive !== PublishState.error" class="spinner-border spinner-border-sm mx-4" role="status"></span>
    </button>
    <h5 class="card-title">
	    <strong>{{CB.assets | localize | async }}</strong>
	    <span *ngIf="publishStatus && publishStatus.status.files !== PublishState.success" class="spinner-border spinner-border-sm mx-4" role="status"></span>
    </h5>

    <div class="table-responsive-sm">
        <table class="table table-hover">
            <thead>
            <tr>
                <th scope="col">{{CB.filename | localize | async}}</th>
                <th scope="col">{{CB.fileSummary | localize | async }}</th>
                <th scope="col">{{CB.fileTags | localize | async}}</th>
                <th scope="col">{{CB.fileType | localize | async}}</th>
            </tr>
            </thead>
            <tbody *ngFor="let learningAsset of resourceSubmission.files">
            <tr>
                <th scope="row">
                    <a href="javascript:void(0);" (click)="openResourceAsset(learningAsset)">{{learningAsset.fileName}}</a>
                </th>
                <td>{{learningAsset.title}}</td>
                <td><span *ngFor="let tag of learningAsset.tags">{{tag.value}}<br></span></td>
                <td>{{getFriendlyFileType(learningAsset)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<!--user-selected related resources deck-->
<div *ngIf="resourceSubmission.relatedResources?.length > 0" class="resource-deck">
    <div class="card border-0">
        <div class="card-body">
            <h5 class="card-title font-weight-bold">{{CB.related_header | localize | async}}</h5>
            <div class="card-deck">
                <div *ngFor="let resource of sortedResourceRefs">
	                <span [id]="resource.guid"></span> <!-- For scroll restoration on navigation-->
	                <div class="resource-card" role="button" (click)="onRelatedResourceClick(resource)">
                        <div *ngIf="resource.configuration.hasImage; else noImg" style="display: contents">
                            <img class="card-img-top" src="{{this.urlService.resourceImage.published(resource.guid)}}" alt="cover image">
                        </div>
                        <ng-template #noImg>
                            <div class="card-img-top bg-dark"></div>
                        </ng-template>
                        <div class="card-img-overlay">
                            <h6 class="card-title font-weight-bold font-italic">{{localize.refTitle(resource)}}</h6>
                            <p class="text-light small">by {{resourceRefSubmitterName(resource)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

