import {Exclude, Type} from "class-transformer";
import {ArrayNotEmpty} from "class-validator";
import {FrameworkRef} from "./FrameworkRef";
import {Framework} from "./Framework";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(SelectableFrameworkRef)
export class SelectableFrameworkRef {

	@Type(() => FrameworkRef)
	@RegisterType({clazz: FrameworkRef})
	public ref:FrameworkRef;

	@Exclude()
	@RegisterType({clazz: Framework})
	public actual:Framework;

	/*@ArrayNotEmpty({
		message: "Your framework, assessment, or standard needs at least 1 item selected within it."
	})*/
	public selectedKeys:Array<string> = [];

	constructor() {

	}

	public toObjectKeys():object {
		var o:Object = {};
		for(let i:number = 0; i < this.selectedKeys.length; i++) {
			let selectedKey:string = this.selectedKeys[i];
			o[selectedKey] = true;
		}

		return o;
	}

	public fromObjectKeys(o:object):void {
		this.selectedKeys = [];
		for (var property in o) {
			if (o.hasOwnProperty(property) && o[property] === true) {
				this.selectedKeys.push(property);
			}
		}
	}

}
