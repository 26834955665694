import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {NavigationExtras, Router} from "@angular/router";

@Injectable({
	providedIn: 'root',
} as any)
export class NavigationHistoryService {

	restoredState$:BehaviorSubject<INavState> = new BehaviorSubject<INavState>(null);

	constructor(private router:Router) {}

	public saveStateAndNavigate(savedState:ISavedState, navCommands:any[], extras?:NavigationExtras) {
		window.history.replaceState(savedState, '');
		this.router.navigate(navCommands, extras);
	}

	public saveState(state:ISavedState) {
		window.history.replaceState(state, '');
	}

}

export interface INavState {
	savedState:ISavedState,
	url:string
}

export interface ISavedState {selectedId?:string, [key:string]:any}
