import {Component, OnInit, ViewChild} from '@angular/core';
import {ValidationErrorService} from "../../../services/common/ValidationErrorService";
import {Localization} from "../../../data/Localization";
import {BehaviorSubject} from "rxjs";
import {IModal} from "../IModal";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {ConversionService} from "../../../services/ConversionService";
import {NotificationService, ToastType} from "../../../services/common/NotificationService";
import {ResourceSubmission} from "../../../domain/resources/ResourceSubmission";
import {ValidationException} from "../../../services/exceptions/ValidationException";
import {SelectableFrameworkRef} from "../../../domain/frameworks/SelectableFrameworkRef";
import {FrameworkSelectComponent} from "../../resources/create/single/edit-resource-submission/subcomponents/framework-select/framework-select.component";


export interface IFrameworkSpecsModalInput {
	resourceSubmission:ResourceSubmission;
	framework:SelectableFrameworkRef;
}

@Component({
	selector:'app-resource-framework-specs-modal',
	templateUrl:'./resource-framework-specs-modal.component.html',
	styleUrls:['./resource-framework-specs-modal.component.scss'],
	providers:[ValidationErrorService]
})
export class ResourceFrameworkSpecsModalComponent implements OnInit, IModal {

	@ViewChild('modal')
	public modal:BsModalRef;
	@ViewChild('frameworkSelect')
	public frameworkSelect:FrameworkSelectComponent;

	private cbContext = Localization.template.modal.resource.specify_framework_specs;

	public component:any;
	public content:IFrameworkSpecsModalInput;
	public resourceSubmission:ResourceSubmission;

	public errors:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
	public warnings:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);

	public CB = {
		title:this.cbContext.title,
		cancel:Localization.template.buttons.cancel,
		submit:Localization.template.buttons.submit,
		toast_save_success:this.cbContext.toast.save.success,
		toast_save_fail:this.cbContext.toast.save.fail
	};

	constructor(private bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				private conversionService:ConversionService,
				private validationErrorService:ValidationErrorService,
				private notificationService:NotificationService) {
	}

	public ngOnInit():void {


	}

	public onProperties(properties:object):void {
		this.content = properties as IFrameworkSpecsModalInput;
		this.resourceSubmission = this.content.resourceSubmission;
	}


	public onCancel() {
		console.info(`user clicked on cancel button`);
		this.bsModalRef.hide();

	}

	public onSubmit() {
		console.info(`user clicked on submit button`);
		console.log(`User has selected ${JSON.stringify(this.content.framework.selectedKeys)}`);

		this.frameworkSelect.saveChanges();

		// clear validation errors and warnings
		this.validationErrorService.updateErrors([]);
		this.validationErrorService.updateWarnings([]);

		//try save contribution and return toasts in either case todo: add toasts!
		try {
			//To Do: Validate
			this.notificationService.displayToast(this.CB.toast_save_success, ToastType.success);
			this.bsModalRef.hide();

		} catch (ex) {
			if (ex instanceof ValidationException) {
				console.info(`validation errors caught`);
				this.validationErrorService.updateErrors(ex.errors);
				this.notificationService.displayToast(this.CB.toast_save_fail, ToastType.error)
			} else {
				console.error(ex);
			}
		}
	}

}
