import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {NotificationService} from "../services/common/NotificationService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {SiteConfigurationService} from "../services/SiteConfigurationService";
import {SiteUndergoingMaintenanceModalComponent} from "../areas/modals/site-undergoing-maintenance-modal/site-undergoing-maintenance-modal.component";
import {NGXLogger} from "ngx-logger";
import {AuthService} from "../services/AuthService";
import {AuthenticatedUsersOnlyModalComponent} from "../areas/modals/authenticated-users-only-modal/authenticated-users-only-modal.component";
import {RoleService} from "../services/common/RoleService";
import {PermissionType} from "../domain/user/Permission";

@Injectable({
	providedIn: 'root',
})
/**
 * Blocks access if site maintenance is underway
 */
export class SiteIsUndergoingMaintenanceModalBlockGuard implements CanActivate {

	private bsModalRef:BsModalRef;

	constructor(private notificationService:NotificationService,
				protected authService:AuthService,
				protected roleService:RoleService,
				private siteConfigurationService:SiteConfigurationService,
				private router:Router, protected logger:NGXLogger) {
	}

	public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		this.logger.info("SiteIsUndergoingMaintenanceModalBlockGuard::canActivate");
		let self = this;
		let didResolve = false;

		let observable:ReplaySubject<boolean> = new ReplaySubject<boolean>();


		self.siteConfigurationService.isLoaded.filter(isLoaded => isLoaded == true).subscribe(response => {
			let siteIsUndergoingMaintenance:boolean = self.siteConfigurationService.siteConfiguration.siteIsUndergoingMaintenance;

			if(siteIsUndergoingMaintenance) {
				let s:Subscription = this.canActivateAuthCheck(next, state).subscribe(isAllowedBasedOnPermission => {
					s.unsubscribe();
					observable.next(isAllowedBasedOnPermission);
				});
			}  else {
				observable.next(true);
			}

		});

		return observable;
	}

	public canActivateAuthCheck(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {

		let self = this;
		return Observable.defer(async function () {
			self.logger.info("SiteIsUndergoingMaintenanceModalBlockGuard::canActivateAuthCheck::async function ()");
			let shouldBlockAccess:boolean = self.siteConfigurationService.siteConfiguration.loginRequiredForSiteAccess;
			if (!shouldBlockAccess) {
				console.info("Not blocking access based on beta phase");
				return true;
			}
			await self.authService.checkIfLoggedIn();
			return self.finishUpEitherWay();
		});
	}

	public finishUpEitherWay():boolean {
		let allowedAccess:boolean = this.authService.currentUser && this.roleService.hasPermissionFor(PermissionType.site_access_while_shutdown);
		if (allowedAccess) {
			console.info(`Ok, youʻre allowed in ${this.authService.currentUser.guid}! Close the door behind you.`);
		} else {
			console.error("It is invalid for most users to access the site with it in Maintenance Mode.");
			this.showModal();
		}
		return allowedAccess;
	}

	public showModal():void {
		this.logger.info("Site is undergoing maintenance and site access is blocked!");
		this.bsModalRef = this.notificationService.displayModal(SiteUndergoingMaintenanceModalComponent, this);
	}
}
