import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {AnalyticsData} from "./data/AnalyticsData";
import {NGXLogger} from "ngx-logger";

declare let gtag:Function;


@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {



	constructor(private router:Router, private analyticsData:AnalyticsData, private route:ActivatedRoute, protected logger:NGXLogger) {

	}
	public get data():AnalyticsData {
		return this.analyticsData;
	}

	public initialize():void {
		if(environment.analytics.enabled && environment.analytics.type == AnalyticsType.google) {
			this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event:NavigationEnd) => {
				if(typeof gtag != "undefined") {
					this.logger.info(`Sending gtag event for  ${event.urlAfterRedirects}`);
					gtag('config', environment.analytics.idCode, {
							'page_path': event.urlAfterRedirects
						});
				} else {
					console.warn("gtag is undefined.  Analytics may not work.");
				}
			});
		}
	}

	public emit(event:AnalyticsEvent) {
		gtag('event', event.name, {
			eventCategory: event.category,
			eventLabel: event.label,
			eventAction: event.action,
			eventValue: event.value
		});
	};

}


export enum AnalyticsCategory {
	organization="organization",
	resource="resource",
	framework="framework"
}

export interface AnalyticsEvent {
	name:string,
	category:AnalyticsCategory,
	action:string,
	label?:string,
	value?:number|string|boolean
}

export enum AnalyticsType {
	none="none",
	google="google"
}
