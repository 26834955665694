
//Dont like the naming of this, but cant think of another name for a message that doesnʻt confuse with 3rd party adapter messages
//For now just the fact its not sitting in /messages folder (i.e. is a domain object is enough)
import {WaihonaUserRef} from "../../user/WaihonaUserRef";
import {Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../../RegisterType";

@RegisterClazz(ResourceChatMessage) //TODO: This is probably incorrect to have this on a message..
export class ResourceChatMessage {
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef})
	public from:WaihonaUserRef;
	public createdEpoch:number;
	public text:string;

	constructor() {

	}
	public static create(message:string):ResourceChatMessage {
		let resourceChatMessage:ResourceChatMessage = new ResourceChatMessage();
		resourceChatMessage.text = message;
		return resourceChatMessage;
	}
	public as(from:WaihonaUserRef):ResourceChatMessage {
		this.from = from;
		return this;
	}
	public at(date:Date = new Date()):ResourceChatMessage {
		this.createdEpoch = date.getTime();
		return this;
	}
	public withText(messageText:string):ResourceChatMessage {
		this.text = messageText;
		return this;
	}

	public get created():Date {
		let ts:Date = new Date();
		ts.setTime(this.createdEpoch);

		return ts;
	}
}
