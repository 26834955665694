import {Converter} from "../../ConversionService";
import {ObjectContext, ObjectContextRef} from "../../../areas/admin/email/email-event-mapper/email-domains";

export class ObjectContextToObjectContextRefConverter implements Converter {

	public from:typeof ObjectContext;
	public to:typeof ObjectContextRef;

	constructor() {
		this.from = ObjectContext;
		this.to = ObjectContextRef;
	}

	public convert(source:ObjectContext):ObjectContextRef {
		//Create the target object
		let objectContextRef:ObjectContextRef = new ObjectContextRef();
			objectContextRef.guid = source.guid;

		return objectContextRef;
	}
}
