<ng-container *ngIf="alertMessage && hideAlert == false">
	<div id="header-alert" class="alert alert-dismissible fade show" [ngClass]="'alert-' + alertMessage.alertClass" style="top: 0; left: 0; margin-bottom: 0;" role="alert">
		<span><i class="fas fa-exclamation-triangle mr-2"></i><strong *ngIf="alertMessage.messageStrong" class="mr-1">{{alertMessage.messageStrong}}</strong>{{alertMessage.messageBody}}</span>
		<button type="button" class="close" data-dismiss="alert" (click)="onDismissAlert()" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
</ng-container>
<nav class="app-header navbar responsive-navbar navbar-expand-lg" #appheader>

	<a class="navbar-brand" href="#"></a>

	<!--hamburger icon-->
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
	        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
	        [attr.aria-expanded]="!isCollapsed"
	        (click)="isCollapsed = !isCollapsed">
		<span class="navbar-toggler-icon"></span>
	</button>

	<div id="navbarSupportedContent" class="collapse navbar-collapse" [collapse]="isCollapsed" [isAnimated]="false">
		<ul id="collapsed-nav-menu" class="navbar-nav">

			<!--resources-->
			<li class="nav-item px-3">
				<a class="nav-link" [routerLink]="['/resources']"
				   data-localize="Resources">{{CB.top_resources | localize | async}}</a>
			</li>

			<!--contributors-->
			<li class="nav-item px-3">
				<a class="nav-link" [routerLink]="['/users']"
				   data-localize="Contributors">{{CB.top_contributors | localize | async}}</a>
			</li>

			<!--organizations-->
			<li class="nav-item px-3">
				<a class="nav-link" [routerLink]="['/organizations']"
				   data-localize="Organizations">{{CB.top_organizations | localize | async}}</a>
			</li>

			<!--collections-->
			<li class="nav-item px-3">
				<a class="nav-link" [routerLink]="['/collections']"
					data-localize="Collections">{{CB.more_collections | localize | async}}</a>
			</li>

			<!--more-->
			<li dropdown class="nav-item dropdown px-3">
				<a dropdownToggle class="nav-link dropdown-toggle" href="#" id="tools-dropdown" role="button"
				   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="false">
					{{CB.top_more | localize | async}}
				</a>
				<div *dropdownMenu class="dropdown-menu" aria-labelledby="tools-dropdown">
					<a class="dropdown-item"
					   [routerLink]="['/more/badges']">{{CB.more_badges | localize | async}}</a>
					<a class="dropdown-item"
					   [routerLink]="['/more/frameworks']">{{CB.more_frameworks | localize | async}}</a>
				</div>
			</li>

			<!--localizations-->
			<li *ngIf="isLocalizationsVisible()" dropdown class="nav-item dropdown px-3">
				<a dropdownToggle class="nav-link" data-toggle="dropdown" href="#" role="button"
				   aria-haspopup="true" aria-expanded="false" (click)="false">
					<i class="fas fa-language" [ngClass]="{'text-warning': isContentBlockModeOn}"></i>
					<span *ngIf="contentBlocks.length > 0" class="badge badge-pill badge-warning">{{contentBlocks.length}}</span>
				</a>
				<div *dropdownMenu class="dropdown-menu" aria-labelledby="localization-dropdown">
					<div class="dropdown-header text-black font-weight-bold">{{CB.localization_numberBlocks | localize:{number:contentBlocks.length} | async}}</div>
					<ng-container *ngFor="let value of contentBlocks; let i=index">
						<button *ngIf="i < 5" [routerLink]="['/admin/localization']" [fragment]=" this.router.url + '$' + value.path" class="dropdown-item" [tooltip]="pathToObjectRef(value.path) | localize | async">
							<span class="text-primary">{{value.path}}</span><span class="font-italic ml-1 small">({{value.className}})</span>
						</button>
					</ng-container>

					<a class="dropdown-item text-secondary font-weight-bold" [routerLink]="['/admin/localization']" [fragment]="this.router.url + '$'">{{CB.localization_viewAll | localize | async}}</a>

					<a href="javascript:void(0)" class="dropdown-item text-warning font-weight-bold"
					   [class.bg-warning]="this.isContentBlockModeOn" [class.text-black-50]="this.isContentBlockModeOn"
					   (click)="this.isContentBlockModeOn = !this.isContentBlockModeOn">
						{{this.isContentBlockModeOn ? "Turn Edit Mode Off" : "Turn Edit Mode On"}}
					</a>
				</div>
			</li>

			<!--language switch-->
			<div id="header-language-switch" class="nav-item pb-3" placement="bottom right">
				<label class="switch header-slider">
					<input type="checkbox" [(ngModel)]="isOleloHawaii" [checked]="isOleloHawaii" (ngModelChange)="switchLanguage()">
					<span class="slider round "></span>
					<span class="oho-icon" [ngClass]="{en: !isOleloHawaii, haw: isOleloHawaii}"></span>
				</label>
			</div>

		</ul>
	</div>

	<!--icons for user notifications-->
	<div *ngIf="currentUser" id="navbarIconContent" class="collapse navbar-collapse justify-content-end">
		<ul id="icon-nav-menu" class="navbar-nav">
			<!--inbox-->
			<li dropdown class="nav-item dropdown">
				<a dropdownToggle class="nav-link" data-toggle="dropdown" [routerLink]="['/inbox']" role="button"
				   aria-haspopup="true" aria-expanded="false" (click)="false">
					<i class="fas fa-envelope text-white" style="font-size: 18px;"></i>
					<span *ngIf="this.counts.unread.anyExceptTrash>0" class="badge badge-pill badge-warning">{{this.counts.unread.anyExceptTrash}}</span>
				</a>
			</li>
		</ul>
	</div>

	<!--avatar menu-->
	<div id="avatar-menu-area">
		<ng-container [ngSwitch]="currentAuthState">
			<ng-container *ngSwitchCase="AuthState.loggedIn">
				<ul class="navbar-nav ml-auto avatar-dropdown">
					<li class="nav-item dropdown" dropdown placement="bottom right">
						<a class="nav-link"
						   data-toggle="dropdown"
						   href="javascript: void(0);"
						   role="button"
						   aria-haspopup="true" aria-expanded="false"
						   dropdownToggle (click)="false; isCollapsed=true;">
							<div class="d-flex row align-items-center float-right mr-1 mr-md-0">
								<!--<small id="aloha" class="text-white pr-2 font-weight-bold font-italic">{{CB.top_greeting | localize | async}} {{currentUser.firstName}}</small>-->
								<avatar-component [user]="currentUser" [class]="'select-image'"></avatar-component>
							</div>

						</a>
						<div id="avatar-menu" class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
							<!--my profile-->
							<a class="dropdown-item" [routerLink]="['/users', currentUser.guid]">
									<span *ngIf="numberOfMissingProfileItems>0"
										  class="badge badge-pill badge-danger">{{numberOfMissingProfileItems}}</span>
								{{CB.dropdown_profile | localize | async}}
							</a>

							<!--my inbox-->
							<a *ngIf="isInboxVisible()" class="dropdown-item" [routerLink]="['/inbox']" data-localize="Inbox">
								<span *ngIf="this.counts.unread.anyExceptTrash > 0"  class="badge badge-pill badge-danger">{{this.counts.unread.anyExceptTrash}}</span>
								{{CB.dropdown_inbox | localize | async}}
							</a>

							<!--my resources-->
							<a class="dropdown-item" [routerLink]="['/resources/users/', currentUser.guid]">
									<span *ngIf="numberOfFanContributions+numberPendingRevision>0"
										  class="badge badge-pill badge-danger">{{numberOfFanContributions + numberPendingRevision}}</span>
								{{CB.dropdown_resources | localize | async}}
							</a>

							<!--my reviews-->
							<a *ngIf="this.isMyReviewsVisible()" class="dropdown-item" [routerLink]="['/reviews']">
									<span *ngIf="(numberPendingReviewContinuation+numberUnassignedPendingReviews)>0"
										  class="badge badge-pill badge-danger">{{numberPendingReviewContinuation + numberUnassignedPendingReviews}}</span>
								{{CB.dropdown_reviews | localize | async}}
							</a>

							<!--my organizations-->
							<a *ngIf="this.isMyOrganizationsVisible()" class="dropdown-item"
							   [routerLink]="['/users', currentUser.guid, 'organizations' ]">
									<span *ngIf="numberOrgsPendingInviteAcceptance>0"
										  class="badge badge-pill badge-danger">{{numberOrgsPendingInviteAcceptance}}</span>
								{{CB.dropdown_organizations | localize | async}}
							</a>

							<!--my collections-->
							<a class="dropdown-item" [routerLink]="['/users', currentUser.guid, 'collections']">
								{{CB.dropdown_collections | localize | async}}
							</a>

							<!--admin dashboard-->
							<a *ngIf="this.isAdminDashboardVisible()" class="dropdown-item"
							   [routerLink]="['/admin']"><i class="fa fa-lock"></i>
								{{CB.dropdown_admin | localize | async}}
							</a>

							<!--logout-->
							<div class="divider"></div>
							<a class="dropdown-item" href="javascript:void(0);" (click)="logout()"
							   data-localize="Log out"><i class="fa fa-lock"></i>
								{{CB.dropdown_logout | localize | async}}
							</a>
						</div>
					</li>
				</ul>
			</ng-container>
			<ng-container *ngSwitchCase="AuthState.loggingIn">
				<span class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Logging in...</span></span>
			</ng-container>
			<ng-container *ngSwitchCase="AuthState.loggedOut">
				<button class="btn btn-danger btn-sm mr-3 font-weight-bold" (click)="login()">{{CB.top_login | localize | async}}</button>
			</ng-container>
		</ng-container>
	</div>


</nav>

