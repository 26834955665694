import {Subscription} from "rxjs";
import {OnDestroy} from "@angular/core";

/** Track subscriptions and then later clean them up.  Extend or extend by composition this class to use it. and use ngOnDestroy (implement OnDestroy) */
export class SubscriptionCleaner implements OnDestroy{

	protected _trackedSubscriptions:Array<Subscription> = [];

	public trackSubscription(subscription:Subscription):Subscription {
		this._trackedSubscriptions.push(subscription);
		return subscription;
	}

	public cleanUpSubscriptions():void {
		this._trackedSubscriptions.map(subscription => {
			subscription.unsubscribe();
		});
		this._trackedSubscriptions = [];
	}

	ngOnDestroy():void {
		this.cleanUpSubscriptions();
	}

}
