import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FrameworksRoutingModule} from "./frameworks-routing.module";
import {FrameworkService} from "../../services/FrameworkService";
import {ListFrameworksComponent} from "./multiple/list-frameworks/list-frameworks.component";
import {FrameworkDetailComponent} from "./single/framework-detail/framework-detail.component";
import {FrameworkEditComponent} from "./single/framework-edit/framework-edit.component";


import { StubData } from "../../data/StubData";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {ValidationErrorService} from "../../services/common/ValidationErrorService";
import {WaihonaUserService} from "../../services/WaihonaUserService";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {TreeModule} from "angular-tree-component";
import {SharedModule} from "../shared/shared.module";
import {TabsModule} from "ngx-bootstrap/tabs";
import {QuillModule} from "ngx-quill";

@NgModule({
    imports: [
        CommonModule,
        FrameworksRoutingModule,
        NgSelectModule,
        FormsModule,
        TreeModule.forRoot(),
        SharedModule,
        AngularFirestoreModule,
        TabsModule,
        QuillModule.forRoot()
    ],
    declarations: [
        ListFrameworksComponent,
        FrameworkDetailComponent,
        FrameworkEditComponent
    ],
    providers: [
        ValidationErrorService,
        WaihonaUserService,
        FrameworkService,
        StubData
    ]
})

export class FrameworksModule { }
