<div class="animated fadeIn horizontal-card-deck"
     infiniteScroll
     [fromRoot]="true"
     [alwaysCallback]="false"
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     (scrolled)="getNextPage()">

	<!--  <nae-localization locWidth='content-width'>-->
	<h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.title | localize | async}}</h1>
	<!--  </nae-localization>-->

	<!--filter subcomponent-->
	<div class="card">
		<div class="card-body">
			<filter-search #filterComponent [(finalFilter)]="this.filter" [(exactMatch)]="this.exactMatch"></filter-search>
		</div>
	</div>


	<!--iterated card deck-->
	<div class="card-deck mt-3 mb-5">
		<!--create new resource card-->
		<div>
			<div class="card my-lg-3 my-sm-1 bg-transparent border-0 p-3" style="display: block;">
				<a href="javascript:void(0);" style="text-decoration: none;" [routerLink]="['/resources/create']">
					<div class="card-body text-center">
						<i class="fas fa-plus-circle fa-6x center-plus-button"></i>
						<h3>{{CB.contribute | localize | async}}</h3>
					</div>
				</a>
			</div>
		</div>

		<!--resource cards-->
		<ng-container *ngFor="let indexedResource of publishedResources | publishedResourceFilter:filterComponent.finalFilter:filteredResultCount:exactMatch">
			<div>
				<div class="card my-lg-3 my-sm-1 animated fadeIn">

					<!--image-->
					<div *ngIf="indexedResource.resourceSubmission.hasImage; else altImg"
						 style="display: contents">
						<img class="card-img-side" src="{{imageSrcForGuid(indexedResource.guid)}}" alt="resource cover">
					</div>
					<ng-template #altImg>
						<div style="display: contents">
							<img class="card-img-side"
								 src="./assets/img/categories/{{indexedResource.resourceSubmission.subjectCategories[0].value}}.png"
								 alt="{{indexedResource.resourceSubmission.subjectCategories[0].value}}">
						</div>
					</ng-template>

					<!--card body-->
					<div class="card-body">
						<ng-template let-localizedText="text"  [ngTemplateOutletContext]="{ text: (LocalizeTools.document(indexedResource.resourceSubmission, ResourceSubmissionTextContent)) }" [ngTemplateOutlet]="resourceTextSelfie" #resourceTextSelfie>
							<a class="stretched-link" href="javascript:void(0);" (click)="navigateToDetail(indexedResource.guid)" [id]="indexedResource.guid" style="text-decoration: none;">
								<h6 class="card-title font-weight-bold" style="text-overflow: ellipsis;">
									{{(localizedText.title.length > 48) ? (localizedText.title | slice: 0:48) + '...' : localizedText.title}}</h6>

								<h6 class="card-text font-italic">{{indexedResource.resourceSubmission.configuration.hideSubmitterName ? indexedResource.resourceSubmission.organization.title : indexedResource.resourceSubmission.submitter.fullName}}</h6>

								<p *ngIf="(localizedText.summary != null); else showBadges" class="card-text small text-gray-400">
									{{(localizedText.summary.length > 100) ? (localizedText.summary | slice: 0:100) + '...' : localizedText.summary}}</p>

								<!--pill badges-->
								<div *ngIf="isNew(indexedResource)" class="badge badge-pill badge-success">{{CB.new_item | localize | async}}</div>
								<div *ngIf="hasOleloContent(indexedResource)" class="badge badge-pill badge-primary pt-1">ʻŌlelo Hawaiʻi</div>

								<ng-template #showBadges>
									<div class="badge badge-pill badge-light"
										 *ngFor="let gradeLevel of indexedResource.resourceSubmission">{{gradeLevel.label}}</div>
									<div class="badge badge-pill badge-light"
										 *ngFor="let category of indexedResource.resourceSubmission.subjectCategories">{{category.label}}</div>
									<div class="badge badge-pill badge-secondary"
										 *ngIf="indexedResource.resourceSubmission.files.length > 0">{{indexedResource.resourceSubmission.files.length}} {{CB.assets | localize | async}}</div>
								</ng-template>
							</a>
						</ng-template>
					</div>

					<!--locks-->
					<ng-container *ngIf="!currentUser">
						<i *ngIf="indexedResource.resourceSubmission.configuration.allowPublicAccess else locked" class="p-2 text-black fas fa-lock-open d-flex align-self-end" style="z-index: 100" tooltip="This resource is unlocked for all users." placement="left"></i>
						<ng-template #locked>
							<i class="p-2 text-light fas fa-lock d-flex align-self-end" style="z-index: 100" tooltip="You must log in to access this resource." placement="left"></i>
						</ng-template>
					</ng-container>
					<ng-container *ngIf="currentUser && isAbleToUnlockResources">
						<i class="p-2 fas d-flex align-self-end lock-icon"
						   [ngClass]="indexedResource.resourceSubmission.configuration.allowPublicAccess ? 'text-dark fa-lock-open' : 'text-light fa-lock'"
						   style="z-index: 100; position: relative;" role="button"
						   (click)="onToggleLockStatusClick(indexedResource)"></i>
					</ng-container>

				</div>
			</div>
		</ng-container>

		<!--page loading card-->
		<loading-card *ngIf="publishedResources.length === 0 || dataLoading()" [itemsLoading]="dataLoading()" listType="resources"></loading-card>
		<!--end page loading card-->
	</div>
	<!--end card deck-->
</div>
