import {Converter} from "../../ConversionService";
import {Audience, AudienceRef} from "../../../areas/admin/email/email-event-mapper/email-domains";

export class AudienceToAudienceRefConverter implements Converter {

	public from:typeof Audience;
	public to:typeof AudienceRef;

	constructor() {
		this.from = Audience;
		this.to = AudienceRef;
	}

	public convert(source:Audience):AudienceRef {
		//Create the target object
		let audienceRef:AudienceRef = new AudienceRef();
			audienceRef.guid = source.guid;

		return audienceRef;
	}
}
