import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ResourceSubmission, ResourceSubmissionStatus} from "../../../../domain/resources/ResourceSubmission";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../data/Localization";
import {LocalizationService} from "../../../../services/LocalizationService";


@Component({
	selector: 'resource-status-box',
	templateUrl: './resource-status-box.component.html',
	styleUrls: ['./resource-status-box.component.scss']
})
export class ResourceStatusBoxComponent implements OnInit, OnChanges {

	@Input() public resourceSubmission:ResourceSubmission;
	@Input() ngSwitch:any;
	@Input() currentStatus:any;
	public statusDraft = "active";
	public statusPendingReview = "future";
	public statusInReview = "future";
	public statusAwaitingRevision = "inactive";
	public statusReviewingRevision = "inactive";
	public statusPublished = "future";
	private cbContext = Localization.template.reviews.status;
	public CB = {
		header: this.cbContext.header,
		draft_in_progress: this.cbContext.draft.in_progress,
		draft_submitted: this.cbContext.draft.submitted,
		review_pending: this.cbContext.review.pending,
		review_started: this.cbContext.review.started,
		review_active: this.cbContext.review.active,
		review_success: this.cbContext.review.success,
		review_fail: this.cbContext.review.fail,
		revision_await: this.cbContext.revision.await,
		revision_submitted: this.cbContext.revision.submitted,
		revision_pendingReview: this.cbContext.revision.pendingReview,
		revision_activeReview: this.cbContext.revision.activeReview,
		revision_success: this.cbContext.revision.success,
		revision_fail: this.cbContext.revision.fail,
		publish_future: this.cbContext.publish.future,
		publish_past: this.cbContext.publish.past
	};

	constructor(private localizationService:LocalizationService,
	            protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("ResourceStatusBoxComponent", this.CB);
	}

	ngOnInit():void {
		this.logger.info(`from resource-status-box component: the current resource is ${this.resourceSubmission.guid}`);
		this.logger.info(`the current resource has a status of ${this.resourceSubmission.status}`);
	}

	ngOnChanges() {

		switch (this.resourceSubmission.status) {
			case ResourceSubmissionStatus.draft:
				this.statusDraft = "active";
				this.statusPendingReview = "future";
				this.statusInReview = "future";
				this.statusPublished = "future";
				break;
			case ResourceSubmissionStatus.submitted:
				this.statusDraft = "success";
				this.statusPendingReview = "active";
				this.statusInReview = "future";
				this.statusPublished = "future";
				break;
			case ResourceSubmissionStatus.in_review:
				this.statusDraft = "success";
				this.statusPendingReview = "success";
				this.statusInReview = "active";
				this.statusPublished = "future";
				break;
			case ResourceSubmissionStatus.in_review_revision:
				this.statusDraft = "success";
				this.statusPendingReview = "success";
				this.statusInReview = "failed";
				this.statusAwaitingRevision = "active";
				this.statusReviewingRevision = "future";
				this.statusPublished = "future";
				break;
			case ResourceSubmissionStatus.in_review_continuation:
				this.statusDraft = "success";
				this.statusPendingReview = "inactive";
				this.statusInReview = "inactive";
				this.statusAwaitingRevision = "success";
				this.statusReviewingRevision = "active";
				this.statusPublished = "future";
				break;
			case ResourceSubmissionStatus.published:
				this.statusDraft = "success";
				this.statusPendingReview = "success";
				this.statusInReview = "success";
				this.statusAwaitingRevision = "inactive";
				this.statusReviewingRevision = "inactive";
				this.statusPublished = "active";
				break;

			default:
				this.logger.info("Unknown review state");
		}
	}

}
