import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Localization} from "../../../data/Localization";
import {BaseComponent} from "../../BaseComponent";
import {Collection} from "../../../domain/collections/Collection";
import {CollectionTextContent} from "../../../domain/collections/CollectionTextContent";
import {MakeProvider} from "../../../base/AbstractValueAccessor";

@Component({
	selector:'select-collection',
	templateUrl:'./select-collection.component.html',
	styleUrls:['./select-collection.component.scss'],
	providers:[MakeProvider(SelectCollectionComponent)]
})
export class SelectCollectionComponent extends BaseComponent implements OnInit {

	@Input()
	public selectableCollections:Array<Collection> = [];

	@Input()
	public selectedCollections:Array<Collection> = [];

	@Input()
	public cbTooltip; //tooltip that will be displayed on the label as cbContext

	@Output()
	public onSelectionChange:EventEmitter<Collection[]> = new EventEmitter<Collection[]>();

	public collectionsSearchFn:(term:string, collection:Collection) => boolean = null; //allows searching

	public localize = {
		collection: (collection) => this.localizationService.LocalizeTools.document(collection, CollectionTextContent, this.localizationService.language),
	};
	private cbContext = Localization.template.common.select_collections;
	public CB = {
		label: this.cbContext.label,
		placeholder:this.cbContext.placeholder,
	};

	constructor(protected injector:Injector) {
		super(injector);
		this.localizationService.registerAndLocalize("SelectCollectionComponent", this.CB);

		// re-build the search function whenever the site language is switched
		this.trackSubscription(this.localizationService.language$.subscribe(() => this.collectionsSearchFn = this.localizationService.LocalizeTools.buildDocTextContentSearchFn(CollectionTextContent, "title")));
	}

	ngOnInit():void {
	}

	public emitSelectedCollections():void {
		console.log(`emitting ${this.selectedCollections.length} selected collections to parent component!`);
		this.onSelectionChange.emit(this.selectedCollections);
	}

}
