import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PermissionType} from "../../domain/user/Permission";
import {ListCollectionsComponent} from "./multiple/list-collections/list-collections.component";
import {CollectionEditComponent} from "./single/collection-edit/collection-edit.component";
import {CollectionDetailComponent} from "./single/collection-detail/collection-detail.component";
import {AuthenticatedUsersOnlyModalBlockGuard} from "../../guards/AuthenticatedUsersOnlyModalBlockGuard";
import {PermissionGuard} from "../../guards/PermissionGuard";
import {Collection} from "../../domain/collections/Collection";
import {DomainType, UserAction} from "../../services/common/RoleService";


const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Collections'
        },
        children: [
	        { // List all collections
                path: '',
                component: ListCollectionsComponent
            },
	        {
		        path: 'create',
		        component: CollectionEditComponent,
		        canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
		        data: {
			        title: 'Create Collection',
			        action: UserAction.create,
		        }
	        },
            {
                path: ':collectionId/edit',
                component: CollectionEditComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard, PermissionGuard],
                data: {
	                title: 'Edit Collection',
	                domainType: DomainType.collections,
	                action: UserAction.edit,
	                permission: PermissionType.edit_collection,
	                guid: "collectionId",
	                classToCheck: Collection,
                }
            },
            {
                path: ':collectionId',
                component: CollectionDetailComponent,
	            canActivate: [PermissionGuard],
	            data: {
		            title: 'View Collection',
		            domainType: DomainType.collections,
		            action: UserAction.view,
		            permission: PermissionType.view_collection,
		            guid: "collectionId",
		            classToCheck: Collection,
	            }
            },
	        // { // List a user's collections .. Chryssa put this path in waihona-users-routing module as users/guid/collections
		    //     path: 'users/:guid',
		    //     component: ManageCollectionsComponent,
		    //     canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
		    //     data: {
			//         summary: "This component lists and allows you to manage collections that you own or that you have access to as a collaborator",
			//         title: 'My Contributions',
			//         action: 'list'
		    //     }
		    //     /* canActivate: [LoggedInGuard], */
	        // },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CollectionsRoutingModule { }
