import {Framework, FrameworksType} from "../domain/frameworks/Framework";
import {FrameworkRef} from "../domain/frameworks/FrameworkRef";


export class FrameworkStubData {

	public get commonCore():Framework {
		let commonCore:Framework = new Framework();
			commonCore.guid = "common_core";
			commonCore.type = FrameworksType.standard;
			commonCore.title = "Common Core";

			commonCore.shortDescription = "Building on the best of existing state standards; the Common Core State Standards provide clear and consistent learning goals to help prepare students for college; career; and life.";
			commonCore.fullDescription = "The Common Core is a set of high-quality academic standards in mathematics and English language arts/literacy (ELA). These learning goals outline what a student should know and be able to do at the end of each grade. The standards were created to ensure that all students graduate from high school with the skills and knowledge necessary to succeed in college; career; and life; regardless of where they live. Forty-one states; the District of Columbia; four territories; and the Department of Defense Education Activity (DoDEA) have voluntarily adopted and are moving forward with the Common Core. Building on the best of existing state standards; the Common Core State Standards provide clear and consistent learning goals to help prepare students for college; career; and life. The standards clearly demonstrate what students are expected to learn at each grade level; so that every parent and teacher can understand and support their learning.";
			commonCore.elements = [];
			
		return commonCore;
	}
	
	public get eOla():Framework {

		let eOla:Framework = new Framework();
			eOla.guid = "e-ola";
			eOla.type = FrameworksType.standard;
			eOla.title = "E Ola!";
			eOla.type = FrameworksType.framework;
			eOla.shortDescription = "Haumāna will become local and global servant leaders who are culturally engaged and play significant roles in creating strong ‘ohana and communities throughout ka pae ʻāina o Hawai‘i and beyond.";
			eOla.fullDescription = "Haumāna will become local and global servant leaders who are culturally engaged and play significant roles in creating strong ‘ohana and communities throughout ka pae ʻāina o Hawai‘i and beyond. The metaphor of a Native Hawaiian forest conveys Kamehameha’s learner outcomes needed to achieve these goals. Students are likened to the array of plants thriving in fertile ‘āina — diverse individuals with unique talents nurtured by common experiences inherent in a Kamehameha Schools education.";
			eOla.elements = [];

		return eOla;
	}

	public get eOlaRef():FrameworkRef {
		let eOlaRef:FrameworkRef = new FrameworkRef("e-ola", "E Ola!");
		return eOlaRef;
	}
	
	public get naAlaIke() {
		let naAlaIke:Framework = new Framework();
			naAlaIke.guid = "na-ala-ike";
			naAlaIke.title = "Nā Ala ʻIke";
			naAlaIke.type = FrameworksType.framework;
			naAlaIke.shortDescription = "Nā ala ʻike supports culturally relevant approaches that also embrace learning through the Hawaiian language; culture; history; and tradition."
			naAlaIke.fullDescription = "Nā ala ʻike is a selectedFramework for developing a comprehensive support system which promotes community and student-centered learning environments. They support experiences that foster and shape the development of learners to become responsible; capable; caring; healthy human beings in spiritual; intellectual; emotional; physical; and social ways. As a result; students will be able to better reach their full potential with a strong cultural identity and sense of place. Nā ala ʻike supports culturally relevant approaches that also embrace learning through the Hawaiian language; culture; history; and tradition. The cultural pathways support state mandates that recognize Hawaiian as an official language of public education and foster efforts that revitalize the Hawaiian language and culture.";
			naAlaIke.elements = [];
		
		return naAlaIke;
		
	}
	
	
	public get naHopenaAo() {
		let naHopenaAo:Framework = new Framework();
			naHopenaAo.guid = "na-hopena-ao";
			naHopenaAo.title = "Nā Hopena Aʻo (HĀ)";
			naHopenaAo.type = FrameworksType.framework;
			naHopenaAo.shortDescription = "Nā Hopena A‘o (“HĀ”) is a selectedFramework of outcomes that reflects the Department of Education’s core values and beliefs in action throughout the public educational system of Hawaii."
			naHopenaAo.fullDescription = "Nā Hopena A‘o (“HĀ”) is a selectedFramework of outcomes that reflects the Department of Education’s core values and beliefs in action throughout the public educational system of Hawaii. It is a set of six outcomes that are firmly rooted in Hawaiʻi. These six-outcomes contain values that are universal to all cultures. Educating students in an environment of HĀ will add value to and strengthen each and every person who engages over the course of a learning journey. Department faculty and staff should also be models of behaviors that direct students to what these outcomes might look like in practice. Those who are moved by the goals and intentions of HĀ are encouraged to use it in their every day practice.";
			naHopenaAo.elements = [];

		return naHopenaAo;		
	}


	public get hfcsVision() {
		let hfcsVision:Framework = new Framework();
			hfcsVision.guid = "hfcs-vision";
			hfcsVision.title = "HFCS Vision of the Graduate";
			hfcsVision.type = FrameworksType.assessment;
			hfcsVision.shortDescription = "Seventeen HFCS participate in the Culturally Relevant Assessment (CRA) project that cascades from the HFCS Vision of the Graduate.  Project objectives include cultural competency; readiness for the next level; and academic growth.";
			hfcsVision.fullDescription = "Seventeen HFCS participate in the Culturally Relevant Assessment (CRA) project that cascades from the HFCS Vision of the Graduate.  Project objectives include cultural competency; readiness for the next level; and academic growth."
			hfcsVision.elements = [];

		return hfcsVision;
	}

	public get kaeo() {
		let kaeo:Framework = new Framework();
			kaeo.guid = "kaeo";
			kaeo.type = FrameworksType.assessment;
			kaeo.title = "Kaiapuni Assessment of Educational Outcomes (KAEO)";
			kaeo.shortDescription = "The purpose of the Kaiapuni Assessment of Education Outcomes (KĀʻEO) is to support a fair; valid; and reliable assessment.";
			kaeo.fullDescription = "The purpose of the Kaiapuni Assessment of Education Outcomes (KĀʻEO) is to support a fair; valid; and reliable assessment that demonstrates:\n • The achievement of Hawaiian language (immersion) schools in Hawaiian language arts; mathematics; and science for the purpose of community accountability as well as state and federal accountability.\n • An appropriate selectedFramework to grow and improve the Hawaiian language immersion program in classroom curriculum; at the school level; in Hawaiian immersion families; and in the broader community.\n • The reliability and validity of building the foundation of an assessment that is informed by Hawaiian"
			kaeo.elements = [];

		return kaeo;
	}

	public get kupukupu() {
		let kupukupu:Framework = new Framework();
			kupukupu.guid = "kupukupu";
			kupukupu.type = FrameworksType.standard;
			kupukupu.title = "Kupukupu";
			kupukupu.shortDescription = "";
			kupukupu.fullDescription = "";
			kupukupu.elements = [];

		return kupukupu;
	}


	public get moenaha() {

		let moenaha:Framework = new Framework();
			moenaha.guid = "moenaha";
			moenaha.title = "Moenahā";
			moenaha.type = FrameworksType.framework;
			moenaha.shortDescription = "The Moenahā model integrates natural learning strategies from Bernice McCarthy's 4MAT method with Native Hawaiian pedagogy.";
			moenaha.fullDescription = "The Moenahā model integrates natural learning strategies from Bernice McCarthy's 4MAT method with Native Hawaiian pedagogy."
			moenaha.elements = [];

		return moenaha;
	}

	public get nextGenScience() {
		let nextGenScience:Framework = new Framework();
			nextGenScience.guid = "nextgen-science";
			nextGenScience.title = "Next Generation Science Standards";
			nextGenScience.type = FrameworksType.standard;
			nextGenScience.shortDescription = "The Next Generation Science Standards (NGSS) are K–12 science content standards. Standards set the expectations for what students should know and be able to do. The NGSS were developed by states to improve science education for all students.";
			nextGenScience.fullDescription = "The Next Generation Science Standards (NGSS) are K–12 science content standards. Standards set the expectations for what students should know and be able to do. The NGSS were developed by states to improve science education for all students."
			nextGenScience.elements = [];

		return nextGenScience;
	}
	
	public getAllFrameworks(): Framework[] {

		let frameworks: Framework[] = [];
		frameworks.push(this.commonCore, this.eOla, this.naAlaIke, this.naHopenaAo, this.hfcsVision, this.kaeo, this.kupukupu, this.moenaha, this.nextGenScience);

		return frameworks;
	}
}
