import {IsNotEmpty, NotContains, IsDefined} from 'class-validator';
import {Type} from "class-transformer";
import {FirebaseFileInfo} from "./FirebaseFileInfo";


/** A file representing the actual file itself in the Firebase File System */
export class FirebaseFile {
    public id:Number;

    @IsDefined()
    @Type(() => FirebaseFileInfo)
    public fileInfo:FirebaseFileInfo;

    @IsNotEmpty()
    public fileName:string;//	1	The on-disk file name	REQUIRED.  (obtained from file itself [multi-part form upload)

    /* The file extension	The file extension to use */
    @NotContains(".htaccess")
    public extension:string;

    /** The date the file was created */
    @Type(() => Date)
    public created:Date;

    /** The date the file was modified */
    @Type(() => Date)
    public modified:Date;

    constructor(fileName?:string) {
      if(fileName != null) {
        this.fileName = fileName;
      }
    }
}
