import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {classToClass, Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {SupportedLanguage} from "../SupportedLanguages";

@RegisterClazz(ResourceSubmissionRef)
export class ResourceSubmissionRef {
	public guid:string;

	public data:{ title:{ [key:string]:string } } = {
			title: {
				haw:"",
				en:""
			}
	};

  @Type(() => WaihonaUserRef)
  @RegisterType({clazz: WaihonaUserRef})
  public submitter:WaihonaUserRef;

  public static create(guid:string, title:{[key:string]:string}, submitter:WaihonaUserRef):ResourceSubmissionRef {
    let resourceSubmissionRef:ResourceSubmissionRef = new ResourceSubmissionRef();
    resourceSubmissionRef.guid = guid;
    resourceSubmissionRef.data.title = title;
    resourceSubmissionRef.submitter = classToClass(submitter);

    return resourceSubmissionRef;
  }

	public title(language:SupportedLanguage) {
		return this.data.title[language];
	}

}
