<div *ngIf="this.localizationService.isContentBlockModeOn; else normalContent" class="loc-container" [ngClass]="setWrapperClass()">
  <div class="loc-content-buttons-wrapper">
    <!-- Use pseudo input if type of element is input, otherwise just use the normal content -->
    <div *ngIf="this.locHostElement?.nodeName === 'INPUT' && this.editingLabel; else normalContent" class="pseudo-input-box" contenteditable="true">
      <div class="pseudo-input" nae-loc-pseudo-input>{{this.locHostElement.textValue}}</div>
    </div>
    <ng-container class="loc-buttons">
      <a *ngIf="!this.editingLabel; else editModeButtons" class="loc-button" (click)="this.enterEditLabelMode($event)">
        <i class="fas fa-edit loc-edit-icon"></i>
      </a>
    </ng-container>
  </div>
</div>

<ng-template #editModeButtons>
  <a class="loc-button" (click)="this.exitEditLabelMode($event)">
    <i class="fas fa-check loc-accept-icon"></i>
  </a>
  <a class="loc-button" (click)="this.exitEditLabelMode($event)">
    <i class="fas fa-close loc-cancel-icon"></i>
  </a>
</ng-template>

<ng-template #normalContent>
  <ng-content class="loc-content"></ng-content>
</ng-template>
