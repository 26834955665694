import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {HogwartsStubData} from "./HogwartsStubData";
import {FirebaseFileInfo} from "../services/storage/FirebaseFileInfo";
import {LearningAsset} from "../domain/resources/LearningAsset";
import {FrameworkStubData} from "./FrameworkStubData";
import {Aina} from "app/domain/resources/aina/Aina";

export class HogwartsLessonStubData {

	public hogwartsStubData:HogwartsStubData;
	public frameworkStubData:FrameworkStubData;

	constructor() {
		this.frameworkStubData = new FrameworkStubData();
	}
	public get allLessons():Array<ResourceSubmission> {
		return [this.polyjuiceHogwartsLesson, this.dragonCareHogwartsLesson, this.levitationHogwartsLesson];
	}

	public get polyjuiceHogwartsLesson(): ResourceSubmission {
		let polyjuice:ResourceSubmission = new ResourceSubmission();
			polyjuice.documentTextContent['en'].title = "Polyjuice Potion Recipe";
			polyjuice.guid = "polyjuice-potion";
			polyjuice.documentTextContent['en'].summary = "Brew your own Polyjuice Potion! Ready in 30 days."
			polyjuice.documentTextContent['en'].description = "<h2>Brewing instructions</h2><br><h4>Part 1, step one</h4><ol><li>Add 3 measures of fluxweed to the cauldron (must have been picked on a full moon).</li><li>Add 2 bundles of&nbsp;knotgrass to the cauldron.</li><li>Stir 4 times, clockwise.</li><li>Wave your wand then let potion brew for 80 minutes (for a&nbsp;<span style=\"color: rgb(153, 110, 0);\">Pewter Cauldron</span>. A&nbsp;<span style=\"color: rgb(153, 110, 0);\">Brass Cauldron</span>&nbsp;will only require 68, and a&nbsp;<span style=\"color: rgb(153, 110, 0);\">copper one</span>&nbsp;only 60.)</li></ol><br><h4>Part 1, step two</h4><ol><li>Add 4&nbsp;leeches to the cauldron.</li><li>Add 2 scoops of stewed lacewing flies to the mortar, crush to a fine paste, then add 2 measures of the crushed lacewings to the cauldron.</li><li>Heat for 30 seconds on a low heat.</li><li>Wave your wand to complete this stage of the potion.</li></ol><br><h4>Part 2, step one</h4><ol><li>Add 3 measures of&nbsp;boomslang skin to the cauldron.</li><li>Add 1 measure of&nbsp;bicorn horn to the mortar, crush to a fine powder, then add one measure of the crushed horn to the cauldron.</li><li>Heat for 20 seconds at a high temperature.</li><li>Wave your wand then let potion brew for 24 hours (for a Pewter Cauldron. A Brass Cauldron will only require 1224 minutes, and a copper one only 18 hours).</li></ol><br><h4>Part 2, step two</h4><ol><li>Add 1 additional scoop of lacewings to the cauldron.</li><li>Stir 3 times, counter-clockwise.</li><li>Split potion into multiple doses, if desired, then add the pieces of the person you wish to become.</li><li>Wave your wand to complete the potion.</li></ol><i class=\"fas fa-wrench\"></i></p>";
			polyjuice.documentTextContent['en'].notes = "<p>This recipe can be found in Moste Potente Potions, which is housed in the Restricted Section of the Hogwarts school library.  It is a very complicated potion with rare ingredients, and should only be attempted by an advanced potions master.</p><br><p>Independent of its actual brewing process, the Polyjuice Potion requires a good deal of preparation prior to making it.  For example, the lacewing flies must be stewed for 21 days prior to making the potion, and only fluxweed picked at the full moon is acceptable.  The total process takes approximately one month to complete.</p>";

			//TODO:  polyjuice.status = ResourceSubmissionStatus.draft;
			polyjuice.submitter = this.hogwartsStubData.severusRef;
			polyjuice.collaborators = [this.hogwartsStubData.alastorMoodyRef];
			polyjuice.gradeLevels = [{value: "University/College", label: "University/College"}];
			polyjuice.subjectCategories = [{value: "Science", label: "Science"}];
			polyjuice.status = ResourceSubmissionStatus.draft;
			polyjuice.tags = [{value: "potions", label: "potions"}, {value: "restricted", label: "restricted"}];
			polyjuice.organization = this.hogwartsStubData.getHogwartsOrganizationRef();

			//polyjuice.frameworks = [{guid: "e_ola", title: "E Ola!"}];


			let file1:LearningAsset = new LearningAsset();
				file1.extension = "docx";
				file1.fileName = "sample.docx";
				file1.title = "Handout #1";
				file1.tags = [{value: "Handout", label: "Handout"}];

				let info1:FirebaseFileInfo = new FirebaseFileInfo();
					info1.bucket = "dev-waihona.appspot.com";
					info1.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
					info1.created = "2019-01-15T19:34:41.397Z";
					info1.name = "sample.docx";
					info1.path = "null/sample.docx";
					info1.size = 26286;
					info1.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.docx?alt=media&token=152553f2-c4c9-4d7e-9bf4-1a5fac1be9dd";

			file1.fileInfo = info1;

			let file2:LearningAsset = new LearningAsset();
				file2.extension = "pdf";
				file2.fileName = "sample.pdf";
				file2.title = "Handout #2";
				file2.tags = [{value: "Handout", label: "Handout"}];

				let info2:FirebaseFileInfo = new FirebaseFileInfo();
					info2.bucket = "dev-waihona.appspot.com";
					info2.contentType = "application/pdf";
					info2.created = "2019-01-15T19:34:41.422Z";
					info2.name = "sample.pdf";
					info2.path = "null/sample.pdf";
					info2.size = 4975;
					info2.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.pdf?alt=media&token=904cea80-5e5d-46eb-b80b-3f2914ebd358";

				file2.fileInfo = info2;

			let file3:LearningAsset = new LearningAsset();
				file3.extension = "ppt";
				file3.fileName = "sample.ppt";
				file3.title = "Presentation";
				file3.tags = [{value: "Other Presentation", label: "Other Presentation"}];

				let info3:FirebaseFileInfo = new FirebaseFileInfo();
					info3.bucket = "dev-waihona.appspot.com";
					info3.contentType = "application/vnd.ms-powerpoint";
					info3.created = "2019-01-15T19:34:41.464Z";
					info3.name = "sample.ppt";
					info3.path = "null/sample.ppt";
					info3.size = 48128;
					info3.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.ppt?alt=media&token=8b842690-bc23-41cc-85be-0d4e8413ab94";

				file3.fileInfo = info3;

			polyjuice.files.push(file1, file2, file3);

		return polyjuice;
	}
	public get levitationHogwartsLesson(): ResourceSubmission {
		let levitation: ResourceSubmission = new ResourceSubmission();
			levitation.documentTextContent['en'].title = "Levitation Charm";
			levitation.guid = "levitation-charm";
			levitation.documentTextContent['en'].description = "The Levitation Charm (Wingardium Leviosa) is a charm used to make objects fly, or levitate. It is taught to first years at Hogwarts School of Witchcraft and Wizardry.  There are a number of lesser variations of the Levitation Charm, such as: the Hover Charm, the Rocket Charm, and the Floating Charm, but the Levitation Charm remains the original and best. The Levitation Charm appears to be an improvement on both the Levitation Spell and Locomotion Charm spells, being able to lift objects high in to the air but also allowing one to magically move them through it as well. It also conveniently defies gravity by lifting objects heavier than a normal person would otherwise be able to carry. Despite its strengths, however, the Levitation Charm has one fatal flaw: it does not work on human beings. Though a human can be levitated using this charm, it is actually their clothing that is being affected. The charm is apparently not strong enough to allow a human to do anything more that float a few feet off the air using this method, and therefore does not allow the unsupported flight afforded to most other objects. Almost anything can be lifted with this spell, although the object levitated depends on the skill of the wizard and the weight of the object.";
			levitation.documentTextContent['en'].notes = "The Levitation Charm was first developed in the sixteenth century, and warlock Jarleth Hobart is credited with its creation.";

			//TODO: levitation.status = ResourceSubmissionStatus.published;
			levitation.submitter = this.hogwartsStubData.filiusRef;
			levitation.collaborators = [];
			levitation.gradeLevels = [{value: "Middle School", label: "Middle School"}];
			levitation.subjectCategories = [{value: "Career and Technical Education", label: "Career and Technical Education"}];
			levitation.tags = [{value: "charms", label: "charms"}, {value: "easy", label: "easy"}];
			levitation.status = ResourceSubmissionStatus.draft;
			levitation.documentTextContent['en'].summary = "Levitation made easy and breezy.";
			levitation.organization = this.hogwartsStubData.getHogwartsOrganizationRef();
			//levitation.frameworks = [{guid: "moenaha", title: "Moenahā"}];

				let file1:LearningAsset = new LearningAsset();
					file1.extension = "docx";
					file1.fileName = "sample.docx";
					file1.title = "Handout #1";
					file1.tags = [{value: "Handout", label: "Handout"}];

					let info1:FirebaseFileInfo = new FirebaseFileInfo();
						info1.bucket = "dev-waihona.appspot.com";
						info1.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
						info1.created = "2019-01-15T19:34:41.397Z";
						info1.name = "sample.docx";
						info1.path = "null/sample.docx";
						info1.size = 26286;
						info1.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.docx?alt=media&token=152553f2-c4c9-4d7e-9bf4-1a5fac1be9dd";

				file1.fileInfo = info1;

				let file2:LearningAsset = new LearningAsset();
					file2.extension = "pdf";
					file2.fileName = "sample.pdf";
					file2.title = "Handout #2";
					file2.tags = [{value: "Handout", label: "Handout"}];

					let info2:FirebaseFileInfo = new FirebaseFileInfo();
						info2.bucket = "dev-waihona.appspot.com";
						info2.contentType = "application/pdf";
						info2.created = "2019-01-15T19:34:41.422Z";
						info2.name = "sample.pdf";
						info2.path = "null/sample.pdf";
						info2.size = 4975;
						info2.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.pdf?alt=media&token=904cea80-5e5d-46eb-b80b-3f2914ebd358";

				file2.fileInfo = info2;

				let file3:LearningAsset = new LearningAsset();
					file3.extension = "ppt";
					file3.fileName = "sample.ppt";
					file3.title = "Presentation";
					file3.tags = [{value: "Other Presentation", label: "Other Presentation"}];

					let info3:FirebaseFileInfo = new FirebaseFileInfo();
						info3.bucket = "dev-waihona.appspot.com";
						info3.contentType = "application/vnd.ms-powerpoint";
						info3.created = "2019-01-15T19:34:41.464Z";
						info3.name = "sample.ppt";
						info3.path = "null/sample.ppt";
						info3.size = 48128;
						info3.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.ppt?alt=media&token=8b842690-bc23-41cc-85be-0d4e8413ab94";

				file3.fileInfo = info3;

			levitation.files.push(file1, file2, file3);

		return levitation;
	}
	public get dragonCareHogwartsLesson(): ResourceSubmission {
		let dragonCare: ResourceSubmission = new ResourceSubmission();
		dragonCare.documentTextContent['en'].title = "Pet Dragon Care Guide";
		dragonCare.guid = "dragon-care";
		dragonCare.documentTextContent['en'].description = "<p>A pet dragon is a delightful companion. Not only will a pet dragon keep you safe from any intruders, pet dragons are incredibly loyal and perfect for helping you roast marshmallows!</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Shelter:</strong>&nbsp;Dragons need large enclosures, including space to stretch their wings. We recommend that you line the enclosure with bricks or some form of fireproof material. Usually pet dragons only breathe fire when commanded, but accidents happen and you want to be prepared. Keeping buckets of water nearby, just in case, is also a good idea.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Food:</strong><span style=\\\"color: rgb(84, 153, 50);\\\">&nbsp;</span>Dragons love meat and fish. Make sure you have plenty! Like phoenixes, they also love fire-berries.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Grooming:&nbsp;</strong>Dragons don’t need much grooming. Sometimes their scales can become sooty and require a polishing. Pet dragons love to have their scales scratched. Their claws need to be trimmed once a year.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Flying:&nbsp;</strong>Although wild dragons’ wings can grow to the length of a house when spread out fully, pet dragons’ wings will never grow so big. Pet dragons don’t fly much, but still like some flying exercise. Make sure you take your dragon out once a week for a flight.</p>";
		dragonCare.documentTextContent['en'].notes = "";

		dragonCare.status = ResourceSubmissionStatus.draft;
		dragonCare.submitter = this.hogwartsStubData.hagridRef;
		dragonCare.collaborators = [];
		dragonCare.gradeLevels = [{value: "High School", label: "High School"}];
		dragonCare.subjectCategories = [{value: "Social Studies", label: "Social Studies"}];
		dragonCare.tags = [{value: "dragons", label: "dragons"}];
		dragonCare.status = ResourceSubmissionStatus.draft;
		dragonCare.documentTextContent['en'].summary = "Really, what could make a better pet than a dragon?";
		dragonCare.organization = this.hogwartsStubData.getHogwartsOrganizationRef();

		//dragonCare.frameworks = [{guid: "commonCore", title: "Common Core"}];

			let file1:LearningAsset = new LearningAsset();
				file1.extension = "docx";
				file1.fileName = "sample.docx";
				file1.title = "Handout #1";
				file1.tags = [{value: "Handout", label: "Handout"}];

				let info1:FirebaseFileInfo = new FirebaseFileInfo();
					info1.bucket = "dev-waihona.appspot.com";
					info1.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
					info1.created = "2019-01-15T19:34:41.397Z";
					info1.name = "sample.docx";
					info1.path = "null/sample.docx";
					info1.size = 26286;
					info1.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.docx?alt=media&token=152553f2-c4c9-4d7e-9bf4-1a5fac1be9dd";

			file1.fileInfo = info1;

			let file2:LearningAsset = new LearningAsset();
				file2.extension = "pdf";
				file2.fileName = "sample.pdf";
				file2.title = "Handout #2";
				file2.tags = [{value: "Handout", label: "Handout"}];

				let info2:FirebaseFileInfo = new FirebaseFileInfo();
					info2.bucket = "dev-waihona.appspot.com";
					info2.contentType = "application/pdf";
					info2.created = "2019-01-15T19:34:41.422Z";
					info2.name = "sample.pdf";
					info2.path = "null/sample.pdf";
					info2.size = 4975;
					info2.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.pdf?alt=media&token=904cea80-5e5d-46eb-b80b-3f2914ebd358";

				file2.fileInfo = info2;

			let file3:LearningAsset = new LearningAsset();
				file3.extension = "ppt";
				file3.fileName = "sample.ppt";
				file3.title = "Presentation";
				file3.tags = [{value: "Other Presentation", label: "Other Presentation"}];

				let info3:FirebaseFileInfo = new FirebaseFileInfo();
					info3.bucket = "dev-waihona.appspot.com";
					info3.contentType = "application/vnd.ms-powerpoint";
					info3.created = "2019-01-15T19:34:41.464Z";
					info3.name = "sample.ppt";
					info3.path = "null/sample.ppt";
					info3.size = 48128;
					info3.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.ppt?alt=media&token=8b842690-bc23-41cc-85be-0d4e8413ab94";

				file3.fileInfo = info3;

			dragonCare.files.push(file1, file2, file3);

		return dragonCare;
	}

	public get disarmingYourOpponent():ResourceSubmission {

		/*

		TITLE
		Disarming Your Opponent

		SUMMARY
		The quickest way to end a battle is to disarm your opponent.  Here's how.

		COLLABORATORS
		Severus Snape

		DESCRIPTION
		The Disarming Charm, or Disarming Spell (Expelliarmus) is a charm which forces whatever an opponent is holding to fly out of their hand. It is common to see this spell used in duels, to make an opponent release their wand. Multiple or exceptionally powerful Disarming Charms are even able to shove, knock down or send a target flying, sometimes to the extent of rendering a target unconscious.

		It is not known precisely who created the Disarming Charm. Some historians claim that it may have been invented by Merlin himself, while others claim that its first widespread use was in Madagascar in the 11th century. Even if any of this is true, the fact remains that it was not very popular until 1379, when Elizabeth Smudgling used it in a dueling contest in Dartmoor.

		It became The Disarming charm notably caused the death of Lord Voldemort during the Battle of Hogwarts by rebounding Voldemort's Killing Curse, as the Elder Wand recognized Harry as its master through the spell connection, and thus strengthened the Disarming Charm to the point that Voldemort's curse backfired on himself.

		The Disarming Charm causes whatever an opponent is holding at the time — usually a wand — to fly high out of their reach. It can also be used to intercept or rebound an opponents' spell while dueling, hence the life-saving effects of the charm.

		The charm appears as a jet of red light; indeed, this is one of the things that makes it recognizable before the effects of the charm are seen. Another notable effect of the charm is that if multiple charms are used on the same target, not only will the target be disarmed, but they will also be blasted backwards

		SOURCES, ATTRIBUTIONS
		Charm information from https://harrypotter.fandom.com/wiki/Disarming_Charm


		GRADE LEVEL
		Eleventh Grade

		SUBJECT CATEGORIES
		Science

		SUGGESTIONS FOR MODIFICATIONS:
		Modify at your own risk!
		*/

		let disarmingOpponent: ResourceSubmission = new ResourceSubmission();
			disarmingOpponent.documentTextContent['en'].title = "Disarming Your Opponent";
			disarmingOpponent.guid = "disarming-your-opponent";
			disarmingOpponent.documentTextContent['en'].description = "<p>A pet dragon is a delightful companion. Not only will a pet dragon keep you safe from any intruders, pet dragons are incredibly loyal and perfect for helping you roast marshmallows!</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Shelter:</strong>&nbsp;Dragons need large enclosures, including space to stretch their wings. We recommend that you line the enclosure with bricks or some form of fireproof material. Usually pet dragons only breathe fire when commanded, but accidents happen and you want to be prepared. Keeping buckets of water nearby, just in case, is also a good idea.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Food:</strong><span style=\\\"color: rgb(84, 153, 50);\\\">&nbsp;</span>Dragons love meat and fish. Make sure you have plenty! Like phoenixes, they also love fire-berries.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Grooming:&nbsp;</strong>Dragons don’t need much grooming. Sometimes their scales can become sooty and require a polishing. Pet dragons love to have their scales scratched. Their claws need to be trimmed once a year.</p><p><br></p><p><strong style=\\\"color: rgb(234, 122, 21);\\\">Flying:&nbsp;</strong>Although wild dragons’ wings can grow to the length of a house when spread out fully, pet dragons’ wings will never grow so big. Pet dragons don’t fly much, but still like some flying exercise. Make sure you take your dragon out once a week for a flight.</p>";
			disarmingOpponent.documentTextContent['en'].notes = "";

			disarmingOpponent.status = ResourceSubmissionStatus.draft;
			disarmingOpponent.submitter = this.hogwartsStubData.alastorMoodyRef;
			disarmingOpponent.collaborators = [this.hogwartsStubData.severusRef];
			disarmingOpponent.gradeLevels = [{value: "High School", label: "High School"}];
			disarmingOpponent.subjectCategories = [{value: "Social Studies", label: "Social Studies"}];
			disarmingOpponent.tags = [{value: "disarm", label: "disarm"}];
			disarmingOpponent.status = ResourceSubmissionStatus.draft;
			disarmingOpponent.documentTextContent['en'].summary = "The quickest way to end a battle is to disarm your opponent.  Here's how.";
			disarmingOpponent.organization = this.hogwartsStubData.getHogwartsOrganizationRef();
			disarmingOpponent.documentTextContent['en'].modifications = "Modify at your own risk!";
			disarmingOpponent.documentTextContent['en'].notes = "Charm information from https://harrypotter.fandom.com/wiki/Disarming_Charm";

    let file1:LearningAsset = new LearningAsset();
      file1.extension = "docx";
      file1.fileName = "sample.docx";
      file1.title = "Handout #1";
      file1.tags = [{value: "Handout", label: "Handout"}];

      let info1:FirebaseFileInfo = new FirebaseFileInfo();
      info1.bucket = "dev-waihona.appspot.com";
      info1.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      info1.created = "2019-01-15T19:34:41.397Z";
      info1.name = "sample.docx";
      info1.path = "null/sample.docx";
      info1.size = 26286;
      info1.url = "https://firebasestorage.googleapis.com/v0/b/dev-waihona.appspot.com/o/null%2Fsample.docx?alt=media&token=152553f2-c4c9-4d7e-9bf4-1a5fac1be9dd";

      file1.fileInfo = info1;

    disarmingOpponent.files.push(file1);

		return disarmingOpponent;
	}

	public get quiddichRules():ResourceSubmission {

/*    TITLE
    Quidditch Rules for Hogwarts

      CONTRIBUTORS
    Filius Flitwick

    BRIEF SUMMARY
    New rules for Quidditch effective fall term

    Quidditch Modifications for Hogwarts
                                (effective Fall term 2019)

    Due to significant and ongoing structural damage to the quidditch stadium and surrounding grounds and landscape from stray bludgers, wild broomsticks, and rogue spells, which require time and care to repair, the headmaster and heads of each of the four Houses have agreed on the following modification to Quidditch for all games played at Hogwarts, effective Fall term 2019.
    1.    Any player found to intentionally aim a bludger, quaffle, broomstick, or spell directly at the ground, stadium, or stands, will be immediately suspended from the match and the player’s team will receive a 500-point penalty. In addition, the offending player will be required to assist the Groundskeeper with repairing the damaged property. If there is a repeat offense by the same player, he or she will be disqualified from all future quidditch play at Hogwarts. We strongly encourage players to aim carefully.
    2.    Bludgers in practice and regular-season play at Hogwarts will be enchanted so that they will pass through any object, including humans, without causing damage to the object. Beaters will be counter-charmed so that they can still strike the ball effectively. Players are encouraged to remember that bludgers in tournament play against other schools are not charmed, so property damage and bodily injury will occur if struck; therefore, every bludger should be regarded as dangerous whether it is enchanted or not.
    3.    Quaffles will not be enchanted, and therefore care should be exercised when striking quaffles to ensure that they go where intended.

      SOURCES, ATTRIBUTIONS, AND USAGE NOTES
    This guide was developed with approval by all of the houses

    GRADE LEVEL:
      Ninth Grade
    Tenth Grade
    Eleventh Grade
    Twelfth Grade

    ʻĀINA (Mokupuni and Moku)
    Hawaiʻi Hilo*/

    let quiddichRules: ResourceSubmission = new ResourceSubmission();
        quiddichRules.documentTextContent['en'].title = "Quidditch Rules for Hogwarts";
        quiddichRules.guid = "quidditch-rules-for-hogwarts";
        quiddichRules.documentTextContent['en'].description = "New rules for Quidditch effective fall term\n<br/>" +
          "\n<br/>" +
          "    Quidditch Modifications for Hogwarts\n<br/>" +
          "                                (effective Fall term 2019)\n<br/>" +
          "\n<br/>" +
          "    Due to significant and ongoing structural damage to the quidditch stadium and surrounding grounds and landscape from stray bludgers, wild broomsticks, and rogue spells, which require time and care to repair, the headmaster and heads of each of the four Houses have agreed on the following modification to Quidditch for all games played at Hogwarts, effective Fall term 2019.\n<br/>" +
          "    1.    Any player found to intentionally aim a bludger, quaffle, broomstick, or spell directly at the ground, stadium, or stands, will be immediately suspended from the match and the player’s team will receive a 500-point penalty. In addition, the offending player will be required to assist the Groundskeeper with repairing the damaged property. If there is a repeat offense by the same player, he or she will be disqualified from all future quidditch play at Hogwarts. We strongly encourage players to aim carefully.\n<br/>" +
          "    2.    Bludgers in practice and regular-season play at Hogwarts will be enchanted so that they will pass through any object, including humans, without causing damage to the object. Beaters will be counter-charmed so that they can still strike the ball effectively. Players are encouraged to remember that bludgers in tournament play against other schools are not charmed, so property damage and bodily injury will occur if struck; therefore, every bludger should be regarded as dangerous whether it is enchanted or not.\n<br/>" +
          "    3.    Quaffles will not be enchanted, and therefore care should be exercised when striking quaffles to ensure that they go where intended.";
        quiddichRules.documentTextContent['en'].notes = "";

        quiddichRules.status = ResourceSubmissionStatus.draft;
        quiddichRules.submitter = this.hogwartsStubData.filiusRef;
        quiddichRules.collaborators = [];
        quiddichRules.gradeLevels = [{value: "Ninth", label: "Ninth"},{value: "Tenth", label: "Tenth"},{value: "Eleventh", label: "Eleventh"},{value: "Twelfth", label: "Twelfth"}];
        quiddichRules.aina = [new Aina()];
        quiddichRules.subjectCategories = [{value: "Social Studies", label: "Social Studies"}];
        quiddichRules.tags = [{value: "disarm", label: "disarm"}];
        quiddichRules.status = ResourceSubmissionStatus.draft;
        quiddichRules.documentTextContent['en'].summary = "The quickest way to end a battle is to disarm your opponent.  Here's how.";
        quiddichRules.organization = this.hogwartsStubData.getHogwartsOrganizationRef();
        quiddichRules.documentTextContent['en'].modifications = "Modify at your own risk!";
        quiddichRules.documentTextContent['en'].notes = "This guide was developed with approval by all of the houses";

    return quiddichRules;

  }
}
