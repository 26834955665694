import {AfterViewChecked, AfterViewInit, Injector} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UrlService} from "../services/UrlService";
import {LocalizationService} from "../services/LocalizationService";
import {RoleService} from "../services/common/RoleService";
import {NotificationService} from "../services/common/NotificationService";
import {ValidationErrorService} from "../services/common/ValidationErrorService";
import {ConversionService} from "../services/ConversionService";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {AuthService} from "../services/AuthService";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {environment} from "../../environments/environment";
import {NGXLogger} from "ngx-logger";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";
import {INavState, NavigationHistoryService} from "../services/NavigationHistoryService";
import {ScreenResizeService} from "../services/ScreenResizeService";


export abstract class BaseComponent extends SubscriptionCleaner implements AfterViewChecked, AfterViewInit {

	public router:Router;
	public route:ActivatedRoute;
	public authService:AuthService;
	public urlService:UrlService;
	public localizationService:LocalizationService;
	public roleService:RoleService;
	public notificationService:NotificationService;
	public validationErrorService:ValidationErrorService;
	public conversionService:ConversionService;
	protected logger:NGXLogger;
	protected navHistoryService:NavigationHistoryService;
	protected screenResizeService:ScreenResizeService;

	public stateRestored:boolean;

	constructor(protected injector:Injector) {
		super();
		this.router = injector.get(Router);
		this.route = injector.get(ActivatedRoute);
		this.authService = injector.get(AuthService);
		this.urlService = injector.get(UrlService);
		this.localizationService = injector.get(LocalizationService);
		this.roleService = injector.get(RoleService);
		this.notificationService = injector.get(NotificationService);
		this.validationErrorService = injector.get(ValidationErrorService);
		this.conversionService = injector.get(ConversionService);
		this.logger = injector.get(NGXLogger);
		this.navHistoryService = injector.get(NavigationHistoryService);
		this.screenResizeService = injector.get(ScreenResizeService);
	}

	ngAfterViewInit() {
		// this is important (says Maui) for checking if there is a vertical scroll bar available on the page, but only after the page is completely rendered (thus the small delay)
		setTimeout(() => {
			this.screenResizeService.checkVerticalScrollBar();
		}, 1000);
	}

	ngAfterViewChecked() {
		// If the state hasn't already been restored...
		if (!this.stateRestored) {
			// Check if there is a state to restore...
			let navState:INavState = this.navHistoryService?.restoredState$?.value;
			if (navState) {
				// If the saved state applies to the current location, then restore it
				if (Object.is(navState.url, window.location.toString())) {
					this.navHistoryService?.saveState(navState.savedState);
					// console.log(this.navHistoryService?.restoredState$?.value);
					let selectedElement = document.getElementById(navState?.savedState?.selectedId);
					// If the element exists in the view, then scroll to it
					if (selectedElement) {
						selectedElement.scrollIntoView({
							behavior: 'auto',
							block: 'center'
						});
						this.stateRestored = true;
					}
				}
			}
		}
	}

	public get environment() {
		return environment;
	}

	public get currentUser():WaihonaUser {
		return this.authService.currentUser;
	}

	public get currentUserRef():WaihonaUserRef {
		return this.authService.currentUserRef;
	}


}
