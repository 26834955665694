import {
	AbstractFirestoreRepository, FilterFunction,
	IPartialInstruction,
	PageCursor,
	PageResults, QueryCriteria
} from "./AbstractFirestoreRepository";

declare var require;
const sprintf = require('sprintf-js').sprintf;
import {
	AngularFirestore,
	QueryFn
} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {ConversionService} from "../ConversionService";
import {countBy} from "lodash";

export abstract class AbstractCompoundFirestoreRepository<T,R=any> extends AbstractFirestoreRepository<T> {

	public collectionPathParamsCount:number = 0;

	public paths:ICollectionRefContainerPaths = {
		type: null,
		ref: null
	};
	public readonly compoundAccessors = () => {
		let self = this;
		return {
			convertToRef: (value:T):R => {
				return self.convertToRef.apply(value);
			},
			get$: (guid:string, preferCache:boolean, ...params:Array<string>):Observable<T> => {
				return self.get$.apply(self, [guid, preferCache, ...params]);
			},
			getAsRef$: (guid:string, preferCache:boolean, ...params:Array<string>):Observable<T> => {
				return self.getAsRef$.apply(self, [guid, preferCache, ...params]);
			},
			list$: (preferCache:boolean, ...params:Array<string>):Observable<T[]> => {
				return self.list$.apply(self, [preferCache, ...params]);
			},
			listAsRef$:(...params:Array<string>):Observable<R[]> => {
				return self.listAsRef$.apply(self, params);
			},
			listFilter$: (filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<T[]> => {
				return self.listFilter$.apply(self, [filterFunction, preferCache, ...params]);
			},
			listFilterAsRef$: (filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<R[]> => {
				return self.listFilterAsRef$.apply(self, [filterFunction, preferCache, ...params]);
			},
			query$:(queryInstructions:QueryFn|QueryCriteria, preferCache:boolean, ...params:Array<string>):Observable<T[]> => {
				return self.query$.apply(self, [queryInstructions, preferCache, ...params]);
			},
			queryAsRef$:(queryFn?:QueryFn, ...params:Array<string>):Observable<R[]> => {
				return self.queryAsRef$.apply(self, [queryFn, ...params]);
			},
			watchList$:(...params:Array<string>):Observable<T[]> => {
				return self.watchList$.apply(self, params);
			},
			watchListAsRef$:(...params:Array<string>):Observable<T[]> => {
				return self.watchListAsRef$.apply(self, params);
			},
			watchQuery$:(queryFn:QueryFn, ...params:Array<string>):Observable<T[]> => {
				return self.watchQuery$.apply(self, params);
			},
			watchQueryAsRef$:(queryFn:QueryFn, ...params:Array<string>):Observable<T[]> => {
				return self.watchQueryAsRef$.apply(self, params);
			},
			watchListFilter$: (filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<T[]> => {
				return self.watchListFilter$.apply(self, [filterFunction, preferCache, ...params]);
			},
			watchListFilterAsRef$: (filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<R[]> => {
				return self.watchListFilterAsRef$.apply(self, [filterFunction, preferCache, ...params]);
			}
		}
	};


	constructor( Type:new () => T, db:AngularFirestore,  collectionRefString:string|ICollectionRefContainerPaths,  identifierProperty:string = "guid", conversionService:ConversionService=null, RefType:new()=>R=null) {
		super(Type, db, null, identifierProperty, conversionService, RefType);
		if(typeof collectionRefString == "string") {
			this.paths.type = collectionRefString;
			this.collectionPathParamsCount = countBy(collectionRefString)['%'];
		} else {
			this.paths = collectionRefString;
			this.collectionPathParamsCount = countBy(this.paths.type)['%'];
		}
	}


	/** Create a Parameter */
	public create$(value:T, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.create$(value);
	}

	public update$(value:T, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.update$(value);
	}

	public updatePartial$(value:object|T, instructions?:{[key:string]:IPartialInstruction}, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.updatePartial$(value, instructions);
	}

	public save$(value:T, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		if (value[this.identifierProperty] == null) {
			return this.create$(value, ...params);
		} else {
			return this.update$(value, ...params);
		}
	}

	public saveAll$(values:T[], ...params:Array<string>):Observable<Array<T>> {
		this.setPaths(...params);
		return super.saveAll$(values);
	}
	public get$(guid:string, preferCache:boolean = true, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.get$(guid);
	}
	public getAsRef$(guid:string, preferCache:boolean, ...params:Array<string>):Observable<R> {
		this.setPaths(...params);
		return super.getAsRef$(guid, preferCache);
	}
	public list$(preferCache:boolean, ...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.list$(preferCache);
	}
	public listAsRef$(preferCache:boolean, ...params:Array<string>):Observable<R[]> {
		this.setPaths(...params);
		return super.listAsRef$(preferCache);
	}

	public listFilter$(filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.listFilter$(filterFunction, preferCache);
	}
	public listFilterAsRef$(filterFunction:FilterFunction, preferCache:boolean, ...params:Array<string>):Observable<R[]> {
		this.setPaths(...params);
		return super.listFilterAsRef$(filterFunction, preferCache);
	}
	public page$(resultsPerPage:number, queryFn:QueryFn, queryCriteria:QueryCriteria, ...params:Array<string>):Observable<PageResults<T>> {
		this.setPaths(...params);
		return super.page$(resultsPerPage, queryFn, queryCriteria);
	}

	public previousPage$(pageCursor:PageCursor<T>, ...params:Array<string>):Observable<PageResults<T>> {
		this.setPaths(...params);
		return super.previousPage$(pageCursor);
	}

	public nextPage$(pageCursor:PageCursor<T>, ...params:Array<string>):Observable<PageResults<T>> {
		this.setPaths(...params);
		return super.nextPage$(pageCursor);
	}

	public watch$(guid:string, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.watch$(guid);
	}
	public watchAsRef$(guid:string, ...params:Array<string>):Observable<R> {
		this.setPaths(...params);
		return super.watchAsRef$(guid);
	}

	public watchList$(...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.watchList$();
	}
	public watchListAsRef$(...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.watchListAsRef$();
	}

	public watchListFilter$(filterFunction:FilterFunction, preferCache:boolean = true, ...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.watchListFilter$(filterFunction, preferCache);
	}
	public watchListFilterAsRef$(filterFunction:FilterFunction, preferCache:boolean = true, ...params:Array<string>):Observable<R[]> {
		this.setPaths(...params);
		return super.watchListFilterAsRef$(filterFunction, preferCache);
	}

	public query$(queryInstructions:QueryFn|QueryCriteria, preferCache:boolean, ...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.query$(queryInstructions, preferCache);
	}
	public queryAsRef$(queryFn?:QueryFn, ...params:Array<string>):Observable<R[]> {
		this.setPaths(...params);
		return super.queryAsRef$(queryFn)
	}

	public watchQuery$(queryFn:QueryFn, ...params:Array<string>):Observable<T[]> {
		this.setPaths(...params);
		return super.watchQuery$(queryFn);
	}
	public watchQueryAsRef$(queryFn?:QueryFn, ...params:Array<string>):Observable<R[]> {
		this.setPaths(...params);
		return super.watchQueryAsRef$(queryFn)
	}
	public delete$(guidOrObject:string|T, ...params:Array<string>):Observable<T> {
		this.setPaths(...params);
		return super.delete$(guidOrObject);
	}
	public setPaths(...params:Array<string>):void {
		if(params.length < this.collectionPathParamsCount) {
			throw new Error(`Have an issue. You didnʻt pass all the necessary params! to ${this.label}. ${JSON.stringify(params,null,2)}`);
		}
		let path:string = sprintf(this.paths.type, ...params);
		this.setCollection(this.db.collection(path));

		if(this.hasCollectionForRef) {
			let refPath:string = sprintf(this.paths.ref, ...params);
			this.setCollectionForRef(this.db.collection(refPath));
		}
	}
}
export interface ICollectionRefContainerPaths {
	type: string;
	ref?: string
}
