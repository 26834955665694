import {Component, OnDestroy, OnInit} from '@angular/core';
import {Localization} from "../../../data/Localization";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {NGXLogger} from "ngx-logger";
import {Subject} from "rxjs";

@Component({
	selector: 'app-navigate-away-confirmation-modal',
	templateUrl: './navigate-away-confirmation-modal.component.html',
	styleUrls: ['./navigate-away-confirmation-modal.component.scss']
})
export class NavigateAwayConfirmationModalComponent implements OnInit, OnDestroy {

	public subject:Subject<boolean>;
	public component:{ userSaysItsOkayToLeaveSubject$:Subject<boolean> };
	private cbContext = Localization.template.modal.resource.navigate_away;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body,
		yes: Localization.template.buttons.yes,
		no: Localization.template.buttons.no
	};

	constructor(public bsModalRef:BsModalRef,
	            private localizationService:LocalizationService,
	            protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("NavigateAwayConfirmationModalComponent", this.CB);
		this.logger.info("BSModalRef ran");
	}

	public ngOnDestroy() {
		this.logger.info('component destroy')
	}

	public ngOnInit():void {
		this.logger.info('component init');
	}

	public destroyModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onClickNo():void {
		this.logger.info("Clicked No");
		this.component.userSaysItsOkayToLeaveSubject$.next(false);
		if (this.subject) {
			this.subject.next(false);
			this.subject.complete();
		}
		this.destroyModal();
	}

	public onClickYes():void {
		this.logger.info("Clicked Yes");
		this.component.userSaysItsOkayToLeaveSubject$.next(true);

		if (this.subject) {
			this.subject.next(true);
			this.subject.complete();
		}
		this.destroyModal();
	}

}
