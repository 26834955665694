import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {ResourceSubmission, ResourceSubmissionStatus} from "../../../../../domain/resources/ResourceSubmission";
import {WaihonaUser} from "../../../../../domain/user/WaihonaUser";
import {ResourceSubmissionService} from "../../../../../services/ResourceSubmissionService";
import {Review} from "../../../../../domain/resources/review/Review";
import {ReviewService} from "../../../../../services/ReviewService";
import {Localization} from "../../../../../data/Localization";
import {BaseComponent} from "../../../../BaseComponent";


@Component({
    selector: 'resource-submission-preview',
    templateUrl: './resource-submission-preview.component.html',
    styleUrls: ['./resource-submission-preview.component.scss']
})
export class ResourceSubmissionPreviewComponent extends BaseComponent implements OnInit, OnDestroy {

    public cbContext = Localization.template.resource.preview;
    public CB = {
        button_edit: this.cbContext.buttons.edit,
    };

    @Input()
    public resourceSubmission: ResourceSubmission;

    public review: Review;
    // public isActivityStreamVisible:boolean = false;

    @Input()
    public submitter: WaihonaUser;

    private userGuid: string;
    private resourceId: string;

    constructor(protected injector:Injector,
                private resourceSubmissionService: ResourceSubmissionService,
                private reviewService: ReviewService) {
        super(injector);
        this.localizationService.registerAndLocalize("ResourceSubmissionPreviewComponent", this.CB);

        this.userGuid = this.route.snapshot.params['userGuid'] != "" ? this.route.snapshot.params['userGuid'] : null;
        this.resourceId = this.route.snapshot.params['resourceId'] != "" ? this.route.snapshot.params['resourceId'] : null;
        this.logger.info("ResourceSubmissionPreviewComponent");

    }

    ngOnInit() {
        if (this.resourceSubmission == null) {
            this.resourceSubmissionService.getResourceSubmission$(this.resourceId).filter(resource => resource != null).subscribe(resource => {
                this.resourceSubmission = resource;
                this.loadReview();
            });

        } else {
            this.loadReview();
        }
        /*this.isActivityStreamVisible = true;
        document.querySelector('body').classList.toggle('aside-menu-hidden');*/
    }

    public ngOnDestroy(): void {
        this.isActivityStreamVisible = false;
    }

    private loadReview(): void {
        this.logger.info("isAReviewState: " + this.isAReviewState + " currently in " + this.resourceSubmission.status);
        if (this.isAReviewState) {
            this.reviewService.getReview$(this.resourceId).filter(review => review != null).subscribe(review => {
                this.logger.info("review: " + review);
                this.review = review;
                this.isActivityStreamVisible = true;
            });
        }
    }

    public get imageSrc(): string {
        return this.urlService.resourceImage.draft(this.resourceSubmission.guid);
    }

    public get isAReviewState(): boolean {
        if (this.resourceSubmission == null) {
            return false;
        }
        let isOneOfTheReviewStates:boolean = [ResourceSubmissionStatus.submitted, ResourceSubmissionStatus.in_review, ResourceSubmissionStatus.in_review_revision, ResourceSubmissionStatus.in_review_continuation].includes(this.resourceSubmission.status);
        return isOneOfTheReviewStates;
    }

    public get userIsRevising(): boolean {
        return (this.resourceSubmission.status === ResourceSubmissionStatus.in_review_revision);
    }

    public get canEdit():boolean {
        //return true if user is either submitter or a collaborator, and resource is either not in a review state or the user is revising it
        let userIsSubmitter:boolean = (this.currentUser.guid == this.resourceSubmission.submitter.guid);
        let userIsCollaborator:boolean = (this.resourceSubmission.collaborators.find(collaborator => collaborator.guid == this.currentUser.guid) != null);
        return ((userIsSubmitter || userIsCollaborator) && (!this.isAReviewState || this.userIsRevising));
    }

    public get isActivityStreamVisible(): boolean {
        return !document.querySelector('body').classList.contains('aside-menu-hidden');
    }

    public set isActivityStreamVisible(isVisible: boolean) {
        if (isVisible) {
            if (!this.isActivityStreamVisible) {
                document.querySelector('body').classList.toggle('aside-menu-hidden');
            }
        } else if (!isVisible) {
            if (this.isActivityStreamVisible) {
                document.querySelector('body').classList.toggle('aside-menu-hidden');
            }
        }
    }

	public onRelatedResourceClick(resourceGuid:string):void {
		let navCommands = ['/resources', resourceGuid];
		this.navHistoryService.saveStateAndNavigate({selectedId: resourceGuid}, navCommands);
	}

}
