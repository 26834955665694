import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {AngularFireStorage} from "@angular/fire/storage";
import {NGXLogger} from "ngx-logger";

@Injectable()
export class DefaultStorageBucket /*implements IStorageBucket */{

  constructor(public storage:AngularFireStorage, protected logger:NGXLogger) {
	}

	public delete(fileLocation:string):Observable<boolean> {
		let replaySubject:ReplaySubject<boolean> = new ReplaySubject<boolean>();

		let fileRef:firebase.storage.Reference = this.storage.storage.ref().child(fileLocation);
		let obFromPromise:Observable<any> = Observable.from(fileRef.delete());

		//Failure
		obFromPromise.catch((err) => {
			this.logger.error(`Error occured\n${err}`);
			replaySubject.next(false);
			return Observable.throwError(err);
		})
		.subscribe(success => {
				this.logger.info("Successfully deleted file: " + success);
				replaySubject.next(true);
			}
		);


		return replaySubject.take(1);
	}



}
