import {AngularFireFunctions} from "@angular/fire/functions";
import {NGXLogger} from "ngx-logger";
import {AbstractFirebaseFunction} from "./AbstractFirebaseFunction";


export abstract class AbstractFirebaseFunctionsFactory {

	protected methods:any = {};

	constructor(protected functions:AngularFireFunctions, methodParams:{[key:string]:Function|Array<Function>}) {
		this.initMethods(methodParams);
	}
	protected initMethods(methodParams:{[key:string]:Function|Array<Function>}) {
		//Instantiate references
		let keys:Array<string> = Object.keys(methodParams);
		for(let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			let Class:Function|Array<Function> = methodParams[key];
			let method:AbstractFirebaseFunction<any, any> = this.generateWrapper(key, Class);
			this.methods[key] = method;
		}
	}
	protected generateWrapper(uri:string, Class:Function|Array<Function>):AbstractFirebaseFunction<any, any> {
		let SendClass:(Function);
		let ReceiveClass:(Function);
		if(Array.isArray(Class)) {
			SendClass = Class[0];
			ReceiveClass = Class[1];
		} else {
			SendClass = Class;
			ReceiveClass = Class;
		}
		return new (class Foo extends AbstractFirebaseFunction<typeof SendClass, typeof ReceiveClass>  {
		})(this.functions, uri, Class as any)
	}


}

export interface IFirebaseFunctionMethod<T> {
	Class:Function,
	method:string
}
