import {ReviewFieldApprovalStatus} from "./ReviewFieldApprovalStatus";
import {RegisterClazz, RegisterType} from "../../RegisterType";
import {Type} from "class-transformer";

@RegisterClazz(ReviewHistoryState)
export class ReviewHistoryState {
	/* Version of the review */
	public version:string = "";
	@Type(()=> ReviewFieldApprovalStatus)
	@RegisterType({clazz: ReviewFieldApprovalStatus, isArray: true})
	public fields:Array<ReviewFieldApprovalStatus> = [];

	/** Comments the reviewer had when ending the review */
	public reviewerComments:string = "";
	/** Comments the user had prior to the review starting */
	public userComments:string = "";

	constructor() {

	}

}
