export enum CollectionOwnerType {
	user = "user",
	organization = "organization",
}
export enum CollectionOwnerTypePretty {
	user = "User",
	organization = "Organization",
}

export enum CollectionOrderType {
	manual = "manual",
	alphabetical = "alphabetical"
}
export enum CollectionOrderTypePretty {
	manual = "Manual",
	alphabetical = "Alphabetical"
}

export enum CollectionVisibilityType {
	privateToUser = "privateToUser",
	privateToOrg = "privateToOrg",
	public = "public"
}
export enum CollectionVisibilityTypePretty {
	privateToUser = "Private",
	privateToOrg = "Organization Only",
	public = "Public"
}
export enum CollectionVisibilityTypeIcon {
	privateToUser = "fa fa-eye-slash",
	privateToOrg = "fa fa-building",
	public = "fa fa-globe",
}

export enum CollectionType {
	series = 'series',
	collection ='collection'
}
export enum CollectionTypePretty {
	series = 'Series',
	collection ='Collection'
}
