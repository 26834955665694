import {RegisterClazz} from "../RegisterType";

@RegisterClazz(CheckboxItem)
export class CheckboxItem {
    public value:any;
    public label:string;
    public checked:boolean;

    public constructor(value:any, label:any, checked?:boolean) {
        this.value = value;
        this.label = label;
        this.checked = checked ? checked : false;
    }
}
