import {Component, OnInit, AfterViewInit, Input, ElementRef, Renderer2, ViewContainerRef} from '@angular/core';
import {LocalizationService} from "../../../services/LocalizationService";
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'nae-localization',
  templateUrl: './nae-localization.component.html',
  styleUrls: ['./nae-localization.component.scss']
})
export class NaeLocalizationComponent implements OnInit, AfterViewInit {

  @Input() locWidth: "content-width" | "full-width";

  private nativeElement:Element;
  locHostElement:Element;

  public inEditLabelMode:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public localizationService:LocalizationService,
              private renderer:Renderer2,
              private viewContainerRef:ViewContainerRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.nativeElement = this.viewContainerRef.element.nativeElement;
    this.locHostElement = this.nativeElement.getElementsByClassName('loc-host')[0];
    console.log(this.locHostElement);
  }

  get editingLabel(): boolean {
    return this.inEditLabelMode.value;
  }
  set editingLabel(value: boolean) {
    this.inEditLabelMode.next(value);
  }

  // public toggleEditLabelMode(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //
  //   if (this.editingLabel === false) {
  //     this.enterEditLabelMode();
  //   } else if (this.editingLabel === true) {
  //     this.exitEditLabelMode();
  //   }
  // }

  public enterEditLabelMode(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.editingLabel === true) {return;}

    this.inEditLabelMode.next(true);
    console.log("editing label: " + this.editingLabel)
  }

  public exitEditLabelMode(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.editingLabel === false) {return;}

    this.inEditLabelMode.next(false);
    console.log("editing label: " + this.editingLabel)
  }

  public setWrapperClass(){
    if (this.locWidth === "content-width") {
      return { "loc-content-width": true };
    } else if (this.locWidth === "full-width") {
      {
        return { "loc-full-width": true };
      }
    }
    return {};
  }

}
