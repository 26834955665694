/**
 * The information about what organizations a user is associated with and their title at each
 */
import {RegisterClazz} from "../RegisterType";

@RegisterClazz(WaihonaUserOrganization)
export class WaihonaUserOrganization {
	public organizationName:string = "";
	public userTitle:string = "";

	public default:boolean = false;
	/** The persons title at the particular school */
	public currentlyAt:boolean = true;
	public organizationGuid:string;
	/** Whether the user needs to be approved by the organization */
	public pendingApproval:boolean = true;
	/** If the user was approved, whether they need to accept, this occurs if the organization adds them but the user had not requested to be added */
	public needsToAcceptInvite:boolean = false;

	public get isInactive():boolean {
		return (this.needsToAcceptInvite == false && this.pendingApproval == false && this.currentlyAt == false);
	}

	public get isAbleToSetAsDefault():boolean {
		return (this.needsToAcceptInvite == false && this.pendingApproval == false && this.currentlyAt == true);
	}

	public get orgStatus():UserStatusWithOrganization {
		if (this.pendingApproval == true && this.needsToAcceptInvite == false) {
			return UserStatusWithOrganization.pending;
		} else if (this.needsToAcceptInvite == true && this.pendingApproval == false) {
			return UserStatusWithOrganization.invited;
		} else if (this.needsToAcceptInvite == false && this.pendingApproval == false && this.currentlyAt == true) {
			return UserStatusWithOrganization.active
		} else if (this.needsToAcceptInvite == false && this.pendingApproval == false && this.currentlyAt == false) {
			return UserStatusWithOrganization.inactive;
		} else {
			return UserStatusWithOrganization.unknown;
		}
	}
}

export enum UserStatusWithOrganization {
	"pending" = "pending",
	"invited" = "invited",
	"active" = "active",
	"inactive" = "inactive",
	"unknown" = "unknown"
}
