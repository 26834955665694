import {classToClass, classToClassFromExist} from "class-transformer";
import {SupportedLanguage} from "../SupportedLanguages";

export class ContentBlockTranslation {
	/** Must be one of the Supported Languages */
	public language:SupportedLanguage = SupportedLanguage.en;

	/** If contentType is html or text it is used appropriately, generally the html or text will not be controlled by the content type unless the developers (one of us) specifically used this property to determine innerHtml or innerText properties.  In the case the contentType is an image the content property is used as a url */
	public content:string = "";

	/** When a user changes one language translation node, its possible that other languages will become outdated, when that happens the flag on the other language translations will be updated to true. This will flag an error in the editor*/
	public isPossiblyOutdated:boolean = false;

	constructor(language:SupportedLanguage, content:string) {
		this.language = language;
		this.content = content;
		this.isPossiblyOutdated = false;
	}

	public duplicate():ContentBlockTranslation {
		return classToClass(this);
	}
}


