<!--++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
This is a subcomponent of the edit-resource component.  This is the container that handles learning asset uploads.
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->

<!--show this div if there is a problem with the selected file(s)-->
<ng-container *ngIf="learningAssetUpload.status == UploadStatus.preupload_problem">
		<td class="text-center col-xs-1" [innerHTML]="learningAssetUpload.statusIcon"></td>
		<td class="col-xs-11">
			<span><strong><i class="fas fa-exclamation-triangle text-warning"></i> {{learningAssetUpload.name}}</strong></span>
			<div class="btn-group-sm" *ngIf="mode == LearningAssetUploadComponentMode.regular">
				<p>{{CB.filename_conflict | localize | async}}</p>
				<button type="button" class="btn  btn-primary" (click)="onRenameClick()" [class.active]="mode == LearningAssetUploadComponentMode.rename">{{CB.button_rename | localize | async}}</button>
				<button type="button" class="btn  btn-primary" (click)="onOverwriteClick()">{{CB.button_overwrite | localize | async}}</button>
				<button type="button" class="btn  btn-primary" (click)="onDiscardClick()">{{CB.button_discard | localize | async}}</button>
			</div>
			<div *ngIf="mode != LearningAssetUploadComponentMode.regular">
				<ng-container *ngIf="this.mode == LearningAssetUploadComponentMode.rename">
					<span>{{CB.new_name | localize | async}} </span><input [(ngModel)]="newFileName"/>
					<button class="btn  btn-primary" (click)="onRenameConfirm()">{{CB.button_rename | localize | async}}</button>
					<button class="btn  btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
				</ng-container>
				<ng-container *ngIf="this.mode == LearningAssetUploadComponentMode.overwrite">
					<span>{{CB.confirm_overwrite | localize | async}}</span><br/>
					<input type="checkbox" [(ngModel)]="learningAssetUpload.keepExistingMetadata"/>{{CB.button_keep | localize | async}}
					<button class="btn  btn-primary" (click)="onOverwriteConfirm()">{{CB.button_confirm_overwrite | localize | async}}</button>
					<button class="btn  btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
				</ng-container>
				<ng-container *ngIf="this.mode == LearningAssetUploadComponentMode.discard">
					<span>{{CB.confirm_discard | localize | async}}</span><br/>
					<button class="btn  btn-primary" (click)="onDiscardConfirm()">{{CB.button_confirm_delete | localize | async}}</button>
					<button class="btn  btn-light" (click)="onCancel()">{{CB.button_cancel | localize | async}}</button>
				</ng-container>
			</div>
		</td>
</ng-container>

<!--show this div if there are no problems with the selected file-->
<ng-container *ngIf="learningAssetUpload.status != UploadStatus.preupload_problem">
	<td class="text-center col-xs-1" [innerHTML]="learningAssetUpload.statusIcon"></td>
	<td class="text-center col-xs-1">
		<i class="fas {{learningAssetUpload.percentage == 0}}"></i>
	</td>
	<td class="col-xs-9" *ngIf="learningAssetUpload.status != 'waiting'">
		<div class="clearfix">
			<div class="float-left">
				<strong>{{learningAssetUpload.currentPercentage}}%</strong>
			</div>
			<div class="float-right">
				<span>{{learningAssetUpload.name}} {{learningAssetUpload.status}}</span><br/>
				<small class="text-muted pl-2">{{learningAssetUpload.currentUploadSize}}
					of {{learningAssetUpload.fileSizeLabel}}</small>
			</div>
		</div>

		<div class="progress progress-xs">
			<div class="progress-bar bg-success" role="progressbar"
				 [style.width]="learningAssetUpload.percentage + '%'"
				 aria-valuenow="50" aria-valuemin="0"
				 aria-valuemax="100"></div>
		</div>
	</td>
	<td class="col-xs-9" *ngIf="learningAssetUpload.status == 'waiting'">
		<div class="clearfix">
			<span><strong><i class="fas fa-exclamation-triangle text-white"></i>&nbsp;{{learningAssetUpload.name}}
				<span *ngIf="learningAssetUpload.overwrite">&nbsp;<i class="fab fa-rev text-white"></i>({{CB.new_file | localize | async}})</span></strong><br>
				{{CB.ready | localize | async}}
			</span>
		</div>
	</td>
	<td class="table-col-right" *ngIf="learningAssetUpload.status == 'waiting'">
		<asset-menu-dropdown type="upload" (onRemove)="onUploadRemove()"></asset-menu-dropdown>
	</td>
	<td class="table-col-right" *ngIf="learningAssetUpload.status == 'uploading'">
		<asset-menu-dropdown type="uploading" (onCancel)="onUploadCancel()"></asset-menu-dropdown>
	</td>
</ng-container>




