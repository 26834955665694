export class ContentBlockRegistryValue {
	public className:string;
	public path:string;
	public route?:string;

	constructor(args?:{className?:string, path?:string, route?:string}) {
		if(args) {
			this.className = args.className;
			this.path = args.path;
			this.route = args.route;
		}
	}
}
