import {RegisterClazz} from "../RegisterType";

@RegisterClazz(FrameworkRef)
export class FrameworkRef {
	public guid:string;
	public title:string;


	constructor(guid?:string, title?:string) {
		this.guid = guid;
		this.title = title;
	}
}
