import {
	AbstractFirestoreRepository,
	OrderByCriterion, OrderByDirections, QueryCriteria, QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {Organization} from "../../domain/organization/Organization";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RefCachingService} from "../RefCachingService";
import {OrganizationRef} from "../../domain/organization/OrganizationRef";
import {ConversionService} from "../ConversionService";
import {RoleType} from "../../domain/user/Role";

@Injectable({
	providedIn: 'root',
} as any)
export class OrganizationRepository extends AbstractFirestoreRepository<Organization, OrganizationRef> {


	public by = {
		order: {
			byTitle: ()=> OrderByCriterion.create("metadata.title", OrderByDirections.Ascending),
		},
		criteria:{
			all: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.byTitle()).toCriteria()
		},
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn()
		}
	};

	constructor(protected db:AngularFirestore, protected resolver:RefCachingService, protected conversionService:ConversionService) {
		super(Organization, db, db.collection("Organizations"),"guid", conversionService, OrganizationRef);
		this.settings.caching.cacheObjects = true;
		this.settings.caching.cacheRefs = true;

		this.resolver.registerProcessor(this);
	}
}
