import {Exclude, Type} from 'class-transformer';
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {ResourceSubmission} from "./ResourceSubmission";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {ResourceVersion} from "./ResourceVersion";
import {IndexProperty} from "../IndexProperty";
import {PublishedResourceScope} from "./PublishedResourceScope";
import {RefType} from "../RefType";

//DOMAIN OBJECT: /Resources/{reviewGuid}
@RegisterClazz(PublishedResource, "Resources")
export class PublishedResource {
	@Exclude({ toPlainOnly: true })
	public guid:string = null;

	// This field is used for indexing the PublishedResources for paging. It doesn't use a decorator like the other domains, because LocalizeTools (inside LocalizationService) is needed to determine which title (en or haw) to use for the index.
	public indexedTitle:string;

	@Type(() => ResourceSubmission)
	@RegisterType({clazz:ResourceSubmission})
	@RefType({isARefContainerObject: true})
	public resourceSubmission:ResourceSubmission;

	//** Who reviewed the Published Resource
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz:WaihonaUserRef})
	@IndexProperty({isArray: true})
	@RefType({Clazz: WaihonaUserRef})
	public reviewers:Array<WaihonaUserRef> = [];

	public archived:Array<string> = [];

	public scope:PublishedResourceScope; //Enum

	/** The major version which is incremented at the time (prior) to the lesson submission reaching a publish state */
	public get version():ResourceVersion {
		return this.resourceSubmission.version;
	}
}

