<ng-container *ngIf="content">
    <div class="modal-header bg-warning">
        <h4 class="modal-title pull-left">{{CB.title | localize | async}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
		{{CB.body | localize:{fullName:content.identity.userFullName, orgName:content.identity.orgTitle} | async}}
    </div>
    <div class="modal-footer border-0">
        <button type="button" class="btn btn-primary" (click)="onVerifyClick(true); isLoadingYes=true"><span *ngIf="isLoadingYes" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Loading...</span></span>{{CB.yes | localize | async}}</button>
		<button type="button" class="btn btn-danger" (click)="onVerifyClick(false); isLoadingNo=true"><span *ngIf="isLoadingNo" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Loading...</span></span>{{CB.no | localize | async}}</button>
		<button type="button" class="btn btn-dark" (click)="bsModalRef.hide()">{{CB.cancel | localize | async}}</button>
	</div>
</ng-container>
