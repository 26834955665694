import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ValidationErrorService} from "../../../services/common/ValidationErrorService";
import {Subscription} from "rxjs";
import {ValidationError} from "class-validator";
import {SupportedLanguage, SupportedLanguages} from "../../../domain/SupportedLanguages";

@Component({
	selector:'waih-validation-text',
	templateUrl:'./validation-text.component.html',
	styleUrls:['./validation-text.component.scss']
})
export class ValidationTextComponent {


	/** Name of the field to validate **/
	@Input() field:string = "";

	private errorsSubscription:Subscription;
	private warningsSubscription:Subscription;


	private _language:SupportedLanguage;

	@Output()
	public languageSwitch:EventEmitter<SupportedLanguage> = new EventEmitter<SupportedLanguage>();

	/** The array of errors for the form **/
	private validationErrors:ValidationError[];
	/** The array of warnings for the form **/
	private validationWarnings:ValidationError[];

	constructor(private validationErrorService:ValidationErrorService) {
		this.errorsSubscription = validationErrorService.errors$.subscribe(
			errors => {
				this.validationErrors = errors;
			});

		this.warningsSubscription = validationErrorService.warnings$.subscribe(
			warnings => {
				this.validationWarnings = warnings;
			});
	}
	public get language():SupportedLanguage {
		return this._language;
	}
	@Input()
	public set language(value:SupportedLanguage) {
		this._language = value;
	}

	public get switchToLanguage():SupportedLanguage {
		return SupportedLanguages.oppositeOf(this._language);
	}
	public get switchToLanguagePretty():string {
		return SupportedLanguages.toPretty(this.switchToLanguage);
	}

	public hasErrorFor(name:string):boolean {
		return this.validationErrorService.hasProblem(name, this.validationErrors);
	}

	public hasWarningFor(name:string):boolean {
		return this.validationErrorService.hasProblem(name, this.validationWarnings);
	}

	public errorTextFor(name:string):string {
		return this.validationErrorService.extractTextFor(name, this.validationErrors);
	}

	public warningTextFor(name:string):string {
		return this.validationErrorService.extractTextFor(name, this.validationWarnings);
	}

	public oppositeLanguageErrorFor(name:string):boolean {
		let errorText:string = this.validationErrorService.extractTextFor(name, this.validationErrors);
		let oppositeLanguageKey:SupportedLanguage = SupportedLanguages.oppositeOf(this.language);
		let searchText:string = `${SupportedLanguages.toPretty(oppositeLanguageKey)}: `;
		let hasErrorForOppositeLanguage:boolean = errorText.indexOf(searchText) != -1;
		return hasErrorForOppositeLanguage;
	}

	public oppositeLanguageWarningFor(name:string):boolean {
		let warningText:string = this.validationErrorService.extractTextFor(name, this.validationWarnings);
		let oppositeLanguageKey:SupportedLanguage = SupportedLanguages.oppositeOf(this.language);
		let searchText:string = `${SupportedLanguages.toPretty(oppositeLanguageKey)}: `;
		let hasWarningForOppositeLanguage:boolean = warningText.indexOf(searchText) != -1;

		return hasWarningForOppositeLanguage;
	}

	public switchLanguage():void {
		console.log(`Switching Language to ${this.switchToLanguagePretty}`);
		this.languageSwitch.emit(this.switchToLanguage);
	}
}




