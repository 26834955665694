import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NGXLogger} from "ngx-logger";

@Component({
	selector: 'asset-menu-dropdown',
	templateUrl: './asset-menu-dropdown.component.html',
	styleUrls: ['./asset-menu-dropdown.component.scss']
})
export class AssetMenuDropdownComponent implements OnInit {


	/**
	 * Only Emits if not currently loading
	 * @type {EventEmitter<any>}
	 */
	@Output()
	public onRemove = new EventEmitter();

	@Output()
	public onCancel= new EventEmitter();

	@Output()
	public onDelete = new EventEmitter();

	@Input()
	public type:string = "upload"; //asset

	public uploadItems:Array<string> = ['Remove'];
	public uploadingItems:Array<string> = ['Cancel'];
	public assetItems:Array<string> = ['Delete'];

	public items:Array<string> = [];

	public status:{ isOpen:boolean } = {isOpen: false};
	public disabled:boolean = false;
	public isDropup:boolean = true;
	public autoClose:boolean = false;

	constructor(protected logger:NGXLogger) {
	}

	toggleDropdown($event:MouseEvent):void {
		$event.preventDefault();
		$event.stopPropagation();
		this.status.isOpen = !this.status.isOpen;
	}

	change(value:boolean):void {
		this.status.isOpen = value;
	}


	ngOnInit() {
		//TODO: modify to use observable so you dont need to do this dumb loop
		if (this.type == "upload") {
			for (let i = 0; i < this.uploadItems.length; i++) {
				this.items.push(this.uploadItems[i]);
			}
		} else if (this.type == "uploading") {
			for (let i = 0; i < this.uploadingItems.length; i++) {
				this.items.push(this.uploadingItems[i]);
			}
		} else {
			for (let i = 0; i < this.assetItems.length; i++) {
				this.items.push(this.assetItems[i]);
			}
		}
	}

	onHidden():void {
		this.logger.info('Dropdown is hidden');
	}

	onShown():void {
		this.logger.info('Dropdown is shown');
	}

	isOpenChange():void {
		this.logger.info('Dropdown state is changed');
	}

	onItemClick(choice:string) {
		this.logger.info("Clicked " + choice);
		switch (choice) {
			case 'Remove':
				this.onRemove.emit();
				break;
			case 'Cancel':
				this.onCancel.emit();
				break;
			case 'Delete':
				this.logger.info("emitting onDelete")
				this.onDelete.emit();
				break;

		}
	}

}


