import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {AuthService} from "../services/AuthService";

@Injectable({
	providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) {

	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let url: string = state.url;
		return this.checkLogin(url);
	}

	public checkLogin(url: string): boolean {
		if (this.authService.isAuthenticated) {
			return true;
		}
		//TODO: Store the attempted URL for redirecting
		this.authService.login();

		// Navigate to the login page with extras
		return false;
	}
}
