<div class="animated fadeIn" *ngIf="collection != null">
	<div class="mb-2">
		<h1 class="display-5 text-primary font-weight-bold mt-4 mb-3">{{CB.header_title | localize | async}}
			<!-- link to collection-detail -->
			<div *ngIf="mode === CollectionEditMode.edit" (click)="onView()" id="view-collection-link" class="font-sm font-weight-normal d-flex justify-content-md-end align-items-center mt-2" style="cursor: pointer">
				<i class="fas fa-eye mr-2"></i><span>{{(collection.configuration.collectionType === CollectionType.collection) ? (CB.button_viewCollection | localize | async) : (CB.button_viewSeries | localize | async)}}</span>
			</div>
		</h1>
	</div>

	<div *ngIf="this.mode == CollectionEditMode.create || this.mode == CollectionEditMode.edit" class="card">
		<div class="card-body">
			<div class="mb-3"><small class="text-right"><i class="fas fa-asterisk text-danger mr-2"></i><span>{{CB.required| localize | async}}</span></small></div>

			<!--language switcher-->
			<div class="language-switcher">
				<h6 id="language-switcher-label" class="text-primary font-weight-bold">{{CB.button_language | localize | async}}
					<i class="fas fa-question-circle text-secondary" placement="bottom"
					   tooltip="{{CB.tooltip_language | localize | async}}"></i>
				</h6>
				<doc-language-toggle [documentTextContent]="collection.documentTextContent"
									 [selectedLanguage]="this.currentTextLanguage"
									 (languageChange)="this.currentTextLanguage = $event"
									 [DocumentTextContentClass]="CollectionTextContent">
				</doc-language-toggle>
			</div>

			<div class="row">

				<!--Main section-->
				<div class="col-md-8">
					<div class="card border border-0">
						<div id="main-content-card-body" class="">

							<!--title and summary-->
							<div class="card">
								<div class="card-header bg-light"><strong>{{CB.header_title_block | localize | async}}:</strong></div>
								<div class="card-body">

									<div class="form-group has-feedback" waih-validation-color field="title">
										<label for="title">{{CB.formLabel_collectionName | localize | async}} <i
												class="fas fa-asterisk text-danger required-field"></i></label>
										<input type="text" class="form-control" data-field-attr="title"
											   id="title" name="title"
											   [(ngModel)]="collectionTextContent.title"
											   [attr.maxlength]="maxLengths?.title"
											   placeholder="{{CB.placeholder_collectionName | localize | async}}">
										<char-count [fieldLength]="collectionTextContent.title?.length" [maxLength]="maxLengths?.title"></char-count>
										<waih-validation-text field="title" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
									</div>
									<div class="form-group has-feedback" waih-validation-color field="summary">
										<label for="summary">{{CB.formLabel_summary | localize | async}}
											<i class="fas fa-asterisk text-danger required-field"></i>
										</label>
										<span class="olelo-hawaii-oe-field"
											  tooltip="{{CB.oho_field_tooltip | localize:{language: currentTextLanguagePretty} }}"
											  [ngClass]="{ae: LocalizeTools.has(collection, SupportedLanguage.haw, 'summary')}">
										</span>
										<textarea *ngIf="collection.documentTextContent[this.currentTextLanguage]" rows="3"
												  type="text" class="form-control" data-field-attr="summary" id="summary" name="summary"
												  placeholder="{{CB.placeholder_summary | localize | async}}"
												  [attr.maxlength]="maxLengths?.summary"
												  [(ngModel)]="collectionTextContent.summary"
												  [ngClass]="currentTextLanguage">
										</textarea>
										<char-count [fieldLength]="collectionTextContent.summary?.length" [maxLength]="maxLengths?.summary"></char-count>
										<waih-validation-text field="summary" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
									</div>
								</div>
							</div>

							<!--Add Collection Items section-->
							<div class="card">
								<div class="card-header bg-light"><strong>{{CB.header_item_block | localize | async}}:</strong>
									<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_addItems | localize | async}}"></i>
								</div>
								<div class="card-body">
									<ng-container *ngIf="(this.mode == CollectionEditMode.edit && collection.items) else saveFirst">
										<!--select resource refs-->
										<select-resource-refs [selectableResourceRefs]="selectableResources"
															  [selectedResourceRefs]="collection.items"
															  (onResourceRefsChange)="onCollectionItemsChanged($event)">
										</select-resource-refs>

										<!--resources in collection-->
										<div *ngIf="collection.items.length > 0" class="mt-4">
											<div class="row">
												<div class="col-md-8">
													<p class="mb-4">{{CB.formLabel_listItems | localize | async}}
														<span class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_sortItemsSection | localize | async}}"></span>
													</p>
												</div>
												<div class="col-md-4">
													<div class="d-flex justify-content-md-end mb-2 text-secondary">
														<view-switcher [views]="[ViewType.list, ViewType.grid]"
																	   [currentView]="collectionViewMode"
																	   (isActive)="collectionViewMode = $event">
														</view-switcher>
													</div>
												</div>
											</div>

											<!--list view-->
											<div *ngIf="collectionViewMode == ViewType.list" >
												<div [sortablejs]="sortableElements"
													 [sortablejsOptions]="sortableJsOptions">
													<div *ngFor="let item of sortableElements.value; index as index;">
														<div class="sortable-element alert alert-secondary alert-dismissible fade show mb-1" role="alert">
															<div [ngClass]="sortableJsOptions.disabled == false ? {'visiblyDraggable': true} : {'visiblyDraggable': false}">
																<!--<i class="fas fa-bars text-muted mr-3"></i>-->
																<!--<span *ngIf="sortableJsOptions.disabled == false" class="collection-item-index text-muted mr-3" contenteditable="true" (mouseup)="onIndexChange($event, item, index)">{{index + 1}}.</span>-->
																<span *ngIf="sortableJsOptions.disabled == false" class="fas fa-grip-lines text-muted mr-3" style="cursor: pointer;"></span>
																<span><em><strong><a href="#/resources/{{item.guid}}" target="_blank" style="text-decoration: underline">{{resourceRefTitle(item)}}</a></strong></em> by {{resourceRefSubmitterName(item)}}</span>
																<button type="button" class="close" data-dismiss="alert" aria-label="Remove" (click)="removeRelatedResource(item)">
																	<span class="far fa-trash-alt small"></span>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>

											<!--card view-->
											<div *ngIf="collectionViewMode == ViewType.grid">
												<div class="row">
													<div id="collection-items-container" class="translucent-on-hover-deck"
														 [sortablejs]="sortableElements"
														 [sortablejsOptions]="sortableJsOptions">
														<div class="sortable-element" *ngFor="let item of sortableElements.value; index as index;">
															<!--<div class="mini-er-card-top-section">
																<span *ngIf="sortableJsOptions.disabled == false" class="collection-item-index" contenteditable="true" (keydown.enter)="onIndexChange($event, item, index)">{{index + 1}}</span>
															</div>-->
															<div [ngClass]="sortableJsOptions.disabled == false ? {'visiblyDraggable': true} : {'visiblyDraggable': false}" class="mini-er-card card-inverse animated fadeInLeft">
																<div *ngIf="item?.configuration?.hasImage; else noImg" class="card-img-area" style="display: contents">
																	<img class="card-img-top" src="{{this.urls.resourceImage(item)}}" alt="cover image">
																</div>
																<ng-template #noImg>
																	<div class="card-img-top bg-dark"></div>
																</ng-template>
																<div class="card-img-overlay">
																	<h6 class="card-title font-weight-bold">{{localize.title(item)}}</h6>
																	<div *ngIf="collection.configuration.orderType == CollectionOrderType.manual"
																		 class="card-img-overlay-bottom">
																		<i class="fas fa-arrows-alt"></i>
																	</div>
																</div>
																<div class="dragging-icon">
																	<i class="fas fa-arrows-alt fa-4x"></i>
																</div>
															</div>
															<div class="mini-er-card-bottom-section">
																<i class="fa fa-trash trash-icon"
																   [popover]="deletePopoverTemplate"
																   popoverTitle="{{CB.confirmDeletePopover_title | localize | async}}"
																   [popoverContext]="{index: index}"
																   (clickOutside)="this.popover.delete.hide(index)"
																   aria-hidden="true"></i>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</ng-container>
									<ng-template #saveFirst>
										<div class="card-body">
											<p><i class="fas fa-2x fa-exclamation-triangle text-warning"></i> {{CB.collectionItems_warning | localize | async}}</p>
										</div>
									</ng-template>
								</div>
							</div>

						</div>
					</div>
				</div>

				<!--right sidebar section-->
				<div class="col-md-4">

					<!--Configuration section-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.header_settings_block | localize | async}}:</strong></div>
						<div class="card-body">
							<form>
								<!--collection config properties-->
								<div class="form-group has-feedback">
									<label for="visibilityType">{{CB.formLabel_visibilityType | localize | async}}
										<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_visibilityType | localize | async}}"></i>
									</label>
									<ng-select id="visibilityType" name="selectVisibilityType"
											   bindLabel="prettyType"
											   bindValue="type"
											   waih-validation-color field="visibilityType"
											   [clearable]="false"
											   [items]="collectionVisibilityTypeOptions"
											   [(ngModel)]="selectedVisibilityType">
										<ng-template ng-label-tmp let-item="item">
											<div class="visibility-option">
												<div class="visibility-icon">
													<i class="collection-visibility-icon" [ngClass]="CollectionVisibilityTypeIcon[item.type]"></i>
												</div>
												<div class="visibility-label">{{item.prettyType}}</div>
											</div>
										</ng-template>
										<ng-template ng-option-tmp let-item="item" let-index="index" >
											<div class="visibility-option">
												<div class="visibility-icon">
													<i class="collection-visibility-icon" [ngClass]="CollectionVisibilityTypeIcon[item.type]"></i>
												</div>
												<div class="visibility-label">{{item.prettyType}}</div>
											</div>
										</ng-template>
									</ng-select>
									<waih-validation-text field="visibilityType" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
								<div class="form-group has-feedback">
									<label for="orderType">{{CB.formLabel_orderType | localize | async}}
										<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_orderType | localize | async}}"></i>
									</label>
									<ng-select id="orderType" name="selectOrderType"
											   bindLabel="prettyType"
											   bindValue="type"
											   waih-validation-color field="orderType"
											   [items]="collectionOrderTypes"
											   [clearable]="false"
											   [(ngModel)]="selectedOrderType">
									</ng-select>
									<waih-validation-text field="orderType" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
								<div class="form-group has-feedback">
									<label for="collectionType">{{CB.formLabel_collectionType | localize | async}}
										<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_collectionType | localize | async}}"></i>
									</label>
									<ng-select id="collectionType" name="selectCollectionType"
											   bindLabel="prettyType"
											   bindValue="type"
											   waih-validation-color field="collectionType"
											   [items]="collectionTypes"
											   [clearable]="false"
											   [(ngModel)]="collection.configuration.collectionType">
									</ng-select>
									<waih-validation-text field="collectionType" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
							</form>
						</div>
					</div>

					<!--Ownership Section-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.header_manage_block | localize | async}}:</strong></div>
						<div class="card-body">

							<div class="form-group has-feedback">
								<div *ngIf="isAllowedToEditOwnerType()" class="form-group has-feedback">
									<label for="ownerType">{{CB.formLabel_ownerType | localize | async}}
										<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_ownerType | localize | async}}"></i>
									</label>
									<ng-select id="ownerType" name="selectOwnerType"
									           bindLabel="prettyType"
									           bindValue="type"
									           waih-validation-color field="ownerType"
									           [clearable]="false"
									           [items]="collectionOwnerTypes"
									           [(ngModel)]="selectedOwnerType"
									           (ngModelChange)="selectedOwnerTypeChange()">
									</ng-select>
									<waih-validation-text field="ownerType" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
							</div>

							<div>
								<!--todo: if the current user is the owner, should the ownership message be different, or the same?-->
								<p class="text-secondary font-italic">{{CB.text_ownership | localize | async}} {{collection.owner.name}}.</p>
								<!--select new owner (user), only visible if a current user (or a waihonaAdmin) is editing this collection-->
								<div *ngIf=" this.selectedOwnerType === CollectionOwnerType.user && (userHasGenericPermissionToEdit || userIsEditingAsOwner)" class="form-group has-feedback" waih-validation-color field="selectedOwnerUser">
									<!--todo: add a tooltip-->
									<label for="selectNewOwnerUser">{{CB.formLabel_selectNewOwnerUser | localize | async}}</label>
									<contributor-drop-down [(ngModel)]="selectedNewOwnerUser"
														   [userOptions]="collection.collaborators"
														   [showMultiple]="false"
														   field="collaborators" id="selectNewOwnerUser"
														   placeholder="{{CB.placeholder_selectNewOwnerUser | localize | async}}"></contributor-drop-down>
									<waih-validation-text field="selectedOrgRef" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
								<!--select owner organization, only visible if an org admin of the org is editing this collection-->
								<div *ngIf="this.selectedOwnerType === CollectionOwnerType.organization && userIsOrgAdminForSomeOrg && (userHasGenericPermissionToEdit || userIsEditingAsOwner)" class="form-group has-feedback" waih-validation-color field="selectedOrgRef">
									<label for="orgSelector">{{CB.formLabel_selectNewOwnerOrg | localize | async}}</label>
									<ng-select id="orgSelector"
											   [(ngModel)]="selectedOrgRef"
											   [items]="selectableOrgRefs"
											   [multiple]="false"
											   [clearable]="false"
											   bindLabel="title"
											   class="bootstrap custom"
											   placeholder="{{CB.placeholder_selectNewOwnerOrg | localize | async}}">
									</ng-select>
									<waih-validation-text field="selectedOrgRef" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
								</div>
							</div>
							<!--authorized users-->
							<div>
								<div class="form-group has-feedback">
									<label for="collaborators">{{CB.formLabel_collaborators | localize | async}}
										<i class="fas fa-question-circle text-secondary ml-1" placement="right" tooltip="{{CB.tooltip_collaborators| localize | async}}"></i>
									</label>
									<contributor-drop-down [userOptions]="waihonaUserRefs"
														   [(ngModel)]="collection.collaborators"
														   field="collaborators" id="collaborators"
														   [showMultiple]="true"
														   placeholder="{{CB.placeholder_collaborators | localize | async}}">
									</contributor-drop-down>
								</div>
								<div *ngIf="selectedVisibilityType != CollectionVisibilityType.public" class="form-group has-feedback">
									<label for="sharedTo">{{CB.formLabel_sharedTo| localize | async}}
										<i class="fas fa-question-circle text-secondary ml-1" placement="right" tooltip="{{CB.tooltip_sharedTo| localize | async}}"></i>
									</label>
									<contributor-drop-down [userOptions]="waihonaUserRefs"
														   [(ngModel)]="collection.sharedTo"
														   field="sharedTo" id="sharedTo"
														   [showMultiple]="true"
														   placeholder="{{CB.placeholder_sharedTo | localize | async}}">
									</contributor-drop-down>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<!--cover image and cropper-->
			<div class="card border border-0 bg-light">
				<div class="card-body">
					<h5 class="card-title text-secondary font-weight-bold">{{CB.header_cover_image | localize | async}}:
						<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.tooltip_coverImage | localize | async}}"></i>
					</h5>
					<ng-container *ngIf="collection.guid else saveBeforeImgUpload">
						<app-cropper #cropperComponent [url]="this.urlService.imageUrl[ImageType.collection](collection.guid)" (failed)="notifyOnCropperFail()" (save)="this.uploadImage$($event)"></app-cropper>
					</ng-container>
					<ng-template #saveBeforeImgUpload>
						<div class="card-body">
							<p><i class="fas fa-2x fa-exclamation-triangle text-warning"></i> {{CB.coverImage_warning | localize | async}}</p>
						</div>
					</ng-template>
				</div>
			</div>


			<div class="d-flex justify-content-center justify-content-md-start">
				<!-- save collection -->
				<button class="btn btn-lg btn-primary text-white mr-2" (click)="submit()" [disabled]="isSaving">
					<span *ngIf="isSaving" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">{{CB.loading | localize | async}}</span></span>
					<i class="far fa-save mr-2"></i><span>{{CB.button_save | localize | async}}</span>
				</button>
				<button *ngIf="mode === CollectionEditMode.edit" (click)="onView()" id="view-button" triggers="pointerenter:pointerout" class="btn btn-lg btn-secondary" type="button">
					<i class="fas fa-eye mr-2"></i><span>{{(collection.configuration.collectionType === CollectionType.collection) ? (CB.button_viewCollection | localize | async) : (CB.button_viewSeries | localize | async)}}</span>
				</button>
			</div>

		</div>
	</div>
</div>

<ng-template #deletePopoverTemplate let-index="index">
	<div>
		<span class="popover-btn btn btn-sm btn-primary" (click)="removeItem(index)">{{CB.confirmDeletePopover_confirm | localize | async}}</span>
		<span class="popover-btn btn btn-sm btn-dark" (click)="this.popover.delete.hide(index)">{{CB.confirmDeletePopover_decline | localize | async}}</span>
	</div>
</ng-template>
