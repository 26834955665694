import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {RoleService} from "../services/common/RoleService";
import {PermissionType} from "../domain/user/Permission";
import {Observable} from "rxjs";
import {AuthService} from "../services/AuthService";
import {NGXLogger} from "ngx-logger";


@Injectable({
	providedIn: 'root',
})
export class RoleGuard implements CanActivate {

	constructor(private router: Router,
	            private authService:AuthService,
	            protected logger:NGXLogger,
	            private roleService:RoleService) {

	}
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
		this.logger.info("RoleGuard::canActivate");
		let self = this;

		let url: string = state.url;
		//what is the guid being used for the operation.  Its held on the data object and usually == to "guid".

		const permissionType:PermissionType =route.data["permission"];
		const propertyOnRouteContainingGuid:string = route.data["guid"];//usually also equal to "guid"
		const guidValue:string = route.params[propertyOnRouteContainingGuid]; //"harry-potter" for example


		return Observable.defer(async function () {
			await self.authService.checkIfLoggedIn();
			return self.checkForPermission(permissionType, guidValue);
		});
	}

	public checkForPermission(permissionType:PermissionType, guid?:string): boolean {
		if(guid != null) {
			return this.roleService.hasPermissionFor(permissionType, guid);
		}
		return this.roleService.hasPermissionFor(permissionType);
	}
}
