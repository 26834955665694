<ng-container *ngIf="isAReviewState && review">
  <div class="navbar-toggler d-md-down-none">
    <span *ngIf="isActivityStreamVisible == false; else close" role="button" appAsideMenuToggler
		  class="navbar-toggler-icon bounce-top fas fa-3x text-info fa-chevron-circle-left" (click)="isActivityStreamVisible = true"></span>
    <ng-template #close>
      <span class="navbar-toggler-icon fas fa-3x text-info fa-chevron-circle-right"  role="button" appAsideMenuToggler (click)="isActivityStreamVisible = false"></span>
    </ng-template>
  </div>
  <review-aside [resourceSubmission]="resourceSubmission" *ngIf="isAReviewState && isActivityStreamVisible"></review-aside>
</ng-container>

<div class="card" *ngIf="resourceSubmission != null">
  <div class="card-body">
    <div class="row">
    <!--sidebar div-->
        <div class="col-md-3">
          <!--Meta Card-->
          <div class="card border border-light bg-light">
            <div *ngIf="resourceSubmission.hasImage; else altImg"> <!--checks for user-uploaded cover image; otherwise uses a stock category graphic -->
              <img class="card-img" src="{{imageSrc}}" alt="cover image">
            </div>
            <ng-template #altImg>
				<img *ngIf="resourceSubmission.subjectCategories && resourceSubmission.subjectCategories[0]"
					 class="card-img" src="/assets/img/categories/{{resourceSubmission.subjectCategories[0].value}}.png"
					 alt="{{resourceSubmission.subjectCategories[0].value}}">
            </ng-template>
            <div class="card-body card-text">
              <resource-submission-meta [resourceSubmission]="resourceSubmission"></resource-submission-meta>
            </div>
          </div>

          <div class="card border border-0">
            <resource-status-box [resourceSubmission]="resourceSubmission"></resource-status-box>
            <button *ngIf="canEdit" class="btn btn-primary text-white" href="javascript:void(0);" [routerLink]="['./edit']">{{CB.button_edit | localize | async}}</button>
          </div>

    <!--closing sidebar div-->
        </div>

        <!--Main section-->
        <div class="col-md-9">
          <div class="card border border-0">
            <lesson-content [resourceSubmission]="resourceSubmission"
                            [submitter]="submitter"
                            [reviewers]="[]"
                            (onResourceRefClick)="onRelatedResourceClick($event.valueOf())">
            </lesson-content>
          </div>

      <!--closing tags-->
      </div>
    </div>
  </div>
</div>
