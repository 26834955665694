import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssetSource, LearningAsset} from "../../../../../../../domain/resources/LearningAsset";
import {AssetTypes} from "../../../../../../../data/LessonData";
import {CheckboxItem} from "../../../../../../../domain/resources/CheckboxItem";
import {IOption} from "ng-select";
import {validateSync, ValidationError} from "class-validator";
import {ValidationHelper} from "../../../../../../../helper/ValidationHelper";
import {BehaviorSubject} from "rxjs";

declare var require;
const mimetype2fa = require('mimetype-to-fontawesome')({prefix: 'fa-'});
import getClassNameForExtension from 'font-awesome-filetypes';
import {NGXLogger} from "ngx-logger";
import {LocalizationService} from "../../../../../../../services/LocalizationService";
import {Localization} from "../../../../../../../data/Localization";

@Component({
	selector: 'tr.app-lesson-asset',
	templateUrl: './lesson-asset.component.html',
	styleUrls: ['./lesson-asset.component.scss']
})
export class LessonAssetComponent implements OnInit {

	private cbContext = Localization.template.resource.lesson_asset;
	public CB = {
		append_fan: this.cbContext.appends.fan,
		append_overwritten: this.cbContext.appends.overwritten,
		fan_conflict: this.cbContext.conflicts.fan,
		filename_conflict: this.cbContext.conflicts.filename,
		new_filename: this.cbContext.conflicts.new_filename,
		confirm_askOverwrite: this.cbContext.confirm.ask_overwrite,
		confirm_askDelete: this.cbContext.confirm.ask_delete,
		confirm_overwrite: this.cbContext.confirm.overwrite,
		confirm_delete: this.cbContext.confirm.delete,
		popover_needsInfo: this.cbContext.popover.needs_info,
		popover_updateInfo: this.cbContext.popover.update,
		popover_summary: this.cbContext.popover.summary,
		popover_placeholder: this.cbContext.popover.placeholder,
		popover_types: this.cbContext.popover.types,
		button_rename: Localization.template.buttons.rename,
		button_overwrite: Localization.template.buttons.overwrite,
		button_delete: Localization.template.buttons.delete,
		button_cancel: Localization.template.buttons.cancel,
	};

	public mode:LearningAssetComponentMode = LearningAssetComponentMode.regular;
	public friendlyFileName:string = "";
	public conflictingFileNameNoExtension:string = "";
	public userInputNewFileNameNoExtension:string = "";
	public fileExtension:string = "";
	public keepExistingMetadata:boolean = true;


	@Output()
	public onDelete:EventEmitter<any> = new EventEmitter();

	@Output()
	public onChoiceConfirmed:EventEmitter<LearningAssetComponentMode> = new EventEmitter();

	@Output()
	public onRenameConfirmed:EventEmitter<RenameEvent> = new EventEmitter();

	@Output("newFileName")
	public newFileName:string = "";

	@Input()
	public asset:LearningAsset;
	public assetTypes:IOption[] = AssetTypes;
	public checkboxes:CheckboxItem[] = [];
	public assetErrors:BehaviorSubject<ValidationError[]>;
	public assetWarnings:BehaviorSubject<ValidationError[]>;

	constructor(private localizationService:LocalizationService,
				protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("LessonAssetComponent", this.CB);

		this.assetTypes = AssetTypes;
		this.assetErrors = new BehaviorSubject<ValidationError[]>([]);
		this.assetWarnings = new BehaviorSubject<ValidationError[]>([]);
	}

	ngOnInit() {
		for (let i = 0; i < this.assetTypes.length; i++) {
			const item:IOption = this.assetTypes[i];
			const checkboxItem:CheckboxItem = new CheckboxItem(item.value, item.label, false);
			this.checkboxes.push(checkboxItem);
		}
		this.initializeCheckboxes();

		this.setNameStrings();
	}

	public get AssetSource() {
		return AssetSource;
	}

	public get LearningAssetComponentMode() {
		return LearningAssetComponentMode;
	}

	//set initial name strings
	public setNameStrings():void {
		if (this.asset.hasConflictWith != "") {
			this.friendlyFileName = this.asset.fileName.split("-").pop();
			this.conflictingFileNameNoExtension = this.asset.hasConflictWith.split(".").shift();
		} else {
			this.friendlyFileName = this.asset.fileName;
		}
		this.fileExtension = this.asset.fileName.split(".").pop();
	}

	public initializeCheckboxes():void {
		for (let i = 0; i < this.asset.tags.length; i++) {
			const tag:IOption = this.asset.tags[i];

			const matching:CheckboxItem = this.checkboxes.find(checkbox => {
				return tag.value == checkbox.value;
			});

			//If we found tags we will want to update the value
			matching.checked = (matching != null);
		}
	}

	public getTitle() {
		return "Asset Meta Information for \"" + this.asset.fileName + "\"";
	}

	public hasAnyErrorForAsset(asset:LearningAsset):boolean {
		return this.assetErrors.getValue().length > 0;
	}

	public hasAnyWarningForAsset(asset:LearningAsset):boolean {
		return this.assetWarnings.getValue().length > 0;
	}

	public errorTextFor(field:string) {
		return ValidationHelper.errorTextFor(field, this.assetErrors.getValue());
	}

	public warningTextFor(field:string) {
		return ValidationHelper.errorTextFor(field, this.assetWarnings.getValue());
	}

	public hasErrorFor(field:string) {
		return ValidationHelper.hasErrorFor(field, this.assetErrors.getValue());
	}

	public hasWarningFor(field:string) {
		return ValidationHelper.hasErrorFor(field, this.assetWarnings.getValue());
	}


	public validateAsset(learningAsset:LearningAsset):void {
		const validationErrors:ValidationError[] = validateSync(learningAsset);
		this.assetErrors.next(validationErrors);
	}

	public onTagsChanged(tags:IOption[]):void {
		for (let i:number = 0; i < this.checkboxes.length; i++) {
			const checkbox:CheckboxItem = this.checkboxes[i];
			checkbox.checked = false;

			const matching:IOption = tags.find(item => {
				return item.value == checkbox.value;
			});

			if (matching != null) {
				checkbox.checked = true;
			} else {
				checkbox.checked = false;
			}
		}
	}

	/** When a checkbox is clicked */
	public onCheckboxClick(checkbox:CheckboxItem) {
		//Change the value of the checkbox
		checkbox.checked = !checkbox.checked;

		//Find any tags which match the checkbox value
		const matching:IOption = this.asset.tags.find(tag => {
			return tag.value == checkbox.value;
		});

		//If we found tags we will want to update the value
		if (matching != null) {
			if (checkbox.checked == true) {
				//if its true and its already in tags its still fine..nothing to do
				this.logger.error("Checkbox odd case.  This should not occur.  Debug this.")
			} else { //checkbox wasnʻt clicked.
				//We want to delete the item from tags
				const tagIndex:number = this.asset.tags.indexOf(matching);
				this.asset.tags.splice(tagIndex, 1);
				this.asset.tags = [...this.asset.tags]; //triggers change detection
			}
		} else {
			//We didnʻt find any matching tags..
			if (checkbox.checked == true) {
				//create a tag
				const matchingTagType:IOption = this.assetTypes.find(tag => {
					return tag.value == checkbox.value;
				});
				//This shouldnt need a null check but its good practice
				if (matchingTagType != null) {
					//But this will...
					this.asset.tags = [...this.asset.tags, matchingTagType];
				}
			}
		}
	}

	/** Font Awesome File Icons */
	public get fontAwesomeFileIcon():string {
		const ext:string = this.asset.extension;
		let iconClass = "";
		if (ext == "") {
			iconClass = mimetype2fa(this.asset.fileInfo.contentType);
		} else {
			iconClass = getClassNameForExtension(ext);
		}

		return iconClass;       //TODO need to handle other icon/file types
	}

	public onDeleteClick():void {
		this.onDelete.emit();
	}

	public onRenameClick() {
		this.mode = LearningAssetComponentMode.rename;
	}

	public onOverwriteClick() {
		this.mode = LearningAssetComponentMode.overwrite;
	}

	public onDeleteFanContributionClick() {
		this.mode = LearningAssetComponentMode.discard;
	}

	/** Confirm the selection */
	public onSelectionConfirm():void {
		this.onChoiceConfirmed.emit(this.mode);
		this.mode = LearningAssetComponentMode.regular;
	}

	public onConfirmRename():void {
		let learningAssetMessage = new RenameEvent();
		learningAssetMessage.learningAsset = this.asset;
		learningAssetMessage.newFileName = this.userInputNewFileNameNoExtension + "." + this.fileExtension;
		this.mode = LearningAssetComponentMode.regular;
		this.onRenameConfirmed.emit(learningAssetMessage);
	}

	public onCancel() {
		this.mode = LearningAssetComponentMode.regular;
	}

}

export enum LearningAssetComponentMode {
	"regular" = "regular",
	"rename" = "rename",
	"overwrite" = "overwrite",
	"discard" = "discard"
}

export class RenameEvent {
	learningAsset: LearningAsset;
	newFileName: string;
	mode: LearningAssetComponentMode;
}
