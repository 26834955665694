import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/AuthService";
import {ResourceSubmissionService} from "../services/ResourceSubmissionService";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";
import {Observable} from "rxjs";
import {NotificationService} from "../services/common/NotificationService";
import {CancelResourceSubmissionModalComponent} from "../areas/modals/cancel-resource-submission-modal/cancel-resource-submission-modal.component";
import {BsModalRef} from "ngx-bootstrap/modal";
import {NotAuthorizedAccessResourceModalComponent} from "../areas/modals/not-authorized-access-resource-modal/not-authorized-access-resource-modal.component";
import {NGXLogger} from "ngx-logger";
import {RoleService} from "../services/common/RoleService";
import {PermissionType} from "../domain/user/Permission";


@Injectable({
	providedIn: 'root',
})
/**
 * Specifies whether a Draft can be edited or not
 */
export class ResourceSubmissionDraftGuard implements CanActivate {

	private bsModalRef:BsModalRef;
	private resourceSubmission:ResourceSubmission;

	constructor(private resourceSubmissionService:ResourceSubmissionService,
				private authService: AuthService,
				private notificationService: NotificationService,
	            private roleService:RoleService,
	            protected logger:NGXLogger) {
	}

	public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		const uid:string = next.params['guid'] != "" ? next.params['guid'] : null;
		const draftResourceGuid:string = next.params['resourceId'] != "" ? next.params['resourceId'] : null;

		let self = this;
		return Observable.defer(async () => {
			self.logger.info("ResourceSubmissionDraftGuard::canActivate::async function ()");
			self.resourceSubmission = await self.resourceSubmissionService.getResourceSubmission$(draftResourceGuid).pipe().toPromise();
			let isExisting:boolean = self.resourceSubmission != null;

			await self.authService.checkIfLoggedIn();
			let isAUser:boolean = self.authService.currentUser != null;

			if (!isAUser || !isExisting) {
				self.bsModalRef = self.notificationService.displayModal(NotAuthorizedAccessResourceModalComponent, self); //TODO: the passing of component should NOT be this
				return false;
			}

			let userisAbleToEdit:boolean = this.roleService.hasGeneralOrACLPermissionsFor.resources.resourceSubmission.edit(this.resourceSubmission, this.authService.currentUser.guid);
			let isSubmitter:boolean = self.authService.currentUser.guid == self.resourceSubmission.submitter.guid;
			let isInAEditableState:boolean = self.resourceSubmissionService.isEditableBySubmitterNow(self.resourceSubmission);

			let validToEdit:boolean = isInAEditableState && userisAbleToEdit;
			if (validToEdit) {
				this.logger.info("It is valid for user " + self.authService.currentUser.guid + " to edit " + draftResourceGuid);
			} else {
				let errorMessage:string = "Permission Error.  User " + uid + " does NOT have permission to edit " + draftResourceGuid + ` isExisting: ${isExisting}, isAUser: ${isAUser}, isSubmitter: ${isSubmitter}, whoSubmitted: ${self.resourceSubmission.submitter.guid} isInAEditableState: ${isInAEditableState}`;
				this.logger.error(errorMessage);
				if(!isSubmitter) {
					self.bsModalRef = self.notificationService.displayModal(NotAuthorizedAccessResourceModalComponent, self); //TODO: the passing of component should NOT be this
				} else {
					self.bsModalRef = self.notificationService.displayModal(CancelResourceSubmissionModalComponent, self); //TODO: the passing of component should NOT be this
				}

			}
			return validToEdit;
		});
	}

	public destroyModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onClickNo():void {
		this.logger.info("Clicked No");
		this.destroyModal();
	}

	public onClickYes():void {
		this.logger.info("Clicked Yes");
		this.resourceSubmissionService.recallResourceSubmission(this.resourceSubmission);
		this.destroyModal();
	}
}
