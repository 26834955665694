import {Converter} from "../../ConversionService";
import {EventType, EventTypeRef} from "../../../areas/admin/email/email-event-mapper/email-domains";


export class EventTypeToEventTypeRefConverter implements Converter {

	public from:typeof EventType;
	public to:typeof EventTypeRef;

	constructor() {
		this.from = EventType;
		this.to = EventTypeRef;
	}

	public convert(source:EventType):EventTypeRef {
		//Create the target object
		let eventTypeRef:EventTypeRef = new EventTypeRef();
			eventTypeRef.guid = source.guid;

		return eventTypeRef;
	}
}
