<!--activity stream sidebar if resource has been previously submitted-->
<div *ngIf="isActivityStreamVisible"
	 class="navbar-toggler d-md-down-none">
	<span (click)="isActivityStreamVisible = true" *ngIf="isActivityStreamVisible == false; else close" appAsideMenuToggler
		  class="navbar-toggler-icon aside-menu-toggle bounce-top fas fa-3x text-info fa-chevron-circle-left" role="button"></span>
	<ng-template #close>
		<span (click)="isActivityStreamVisible = false" appAsideMenuToggler
			  class="navbar-toggler-icon aside-menu-toggle fas fa-3x text-info fa-chevron-circle-right" role="button"></span>
	</ng-template>
</div>

<div *ngIf="dataProvider != null && dataProvider.ready" class="animated fadeIn" id=main>

	<h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.header | localize | async}}</h1>

	<!--Review overall comments: if has a review and has overall comments (show above the card)-->
	<div *ngIf="dataProvider.review && dataProvider.review.overallComment" class="alert alert-warning" role="alert">
		<strong>{{CB.comments_from_reviewer | localize | async}} </strong>{{dataProvider.review.overallComment}}
	</div>

	<div class="card">
		<div class="card-body">

			<!--alert for first-time users-->
			<language-mismatch-alert
					key="firstMessage"
					[languageMismatch]="languageMismatch"
					[alertClass]="'alert-success'"
					[title]="(CB.alert_did_you_know | localize | async)"
					[body]="(CB.alert_first_time | localize | async)">
			</language-mismatch-alert>

			<!--alert for fan contributions-->
			<div *ngIf="dataProvider.fanContributions && dataProvider.fanContributions.length > 0" class="alert alert-info"><i class="fas fa-star mx-2"></i>{{CB.alert_fanContributions | localize | async}} Or click <a href="javascript: void(0)" (click)="scrollToId('fanContributions')">here</a></div>

			<div class="card-top-content">
				<span><small class="text-right"><i class="fas fa-asterisk text-danger"></i><span>{{CB.required | localize | async}}</span></small></span>

				<!--sticky language switcher-->
				<div stickyThing class="sticky-element-wrapper resource-element" marginTop="50">
					<h6 class="text-primary font-weight-bold">
						{{CB.language_switcher | localize | async}}
						<i class="fas fa-question-circle text-secondary" placement="bottom"
						   tooltip="{{CB.language_tooltip | localize | async}}"></i>
					</h6>
					<doc-language-toggle [documentTextContent]="dataProvider.resourceSubmission.documentTextContent"
										 [selectedLanguage]="currentTextLanguage"
										 (languageChange)="currentTextLanguage = $event; checkLanguageAllKeys()"
										 [DocumentTextContentClass]="ResourceSubmissionTextContent">
					</doc-language-toggle><!-- Language -->
				</div>
			</div>

			<div class="row"> <!--start grid-->

				<!--===========================================================-->
				<!--------------------- Left Side Column------------------------>
				<!--===========================================================-->
				<div class="col-md-7"> <!--start left column -->

					<!--basic info card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.basicInfo_title | localize | async }}</strong>
							<span *ngIf="sectionWasApproved('titleBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('titleBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('titleBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('titleBlock')==true, 'alert-danger':sectionWasApproved('titleBlock')==false}">{{reviewStatusText('titleBlock')}}</div>

							<!--submitter and org info, only visible if an editor is editing this resource-->
							<p *ngIf="isEditingAsEditor" class="text-secondary font-italic">You are editing a resource that was submitted by {{dataProvider.resourceSubmission.submitter.fullName}} on behalf of {{dataProvider.resourceSubmission.organization.title}}.</p>

							<!--organization block, only visible if the content owner is editing this resource-->
							<div *ngIf="isEditingAsOwner" class="form-group has-feedback">
								<p class="text-secondary font-italic">{{CB.basicInfo_org_selected | localize:{orgName: dataProvider.resourceSubmission.organization?.title} | async}}</p>
								<div *ngIf="isAbleToChangeOrg">
									<label for="orgSelector">{{CB.basicInfo_changeOrg  | localize | async}}</label>
									<ng-select id="orgSelector"
											[(ngModel)]="dataProvider.resourceSubmission.organization"
											[items]="dataProvider.listOfSelectableOrgRefs"
											[multiple]="false"
									        [clearable]="false"
											(ngModelChange)="changeOrg()"
											bindLabel="title"
											class="bootstrap custom"
										    waih-validation-color field="organization"
											placeholder="{{CB.basicInfo_org_placeholder | localize | async}}">
									</ng-select>
									<small class="form-text text-muted font-italic">{{CB.basicInfo_org_help | localize | async}}</small>
								</div>
								<waih-validation-text field="organization"></waih-validation-text>
							</div>

							<!--title block-->
							<div class="form-group has-feedback">
								<label for="title">{{CB.basicInfo_resource_label | localize | async}}<i class="fas fa-asterisk text-danger required-field"></i></label>
								<span
									class="olelo-hawaii-oe-field" tooltip="{{CB.oho_field_tooltip | localize:{language: (currentTextLanguagePretty$ | async)} | async}}"
									[ngClass]="{ae: LocalizeTools.has(dataProvider.resourceSubmission, SupportedLanguage.haw, 'title')}">
								</span>
								<input [(ngModel)]="currentLanguageTextContent.title"
									   [isInput]="true" class="form-control olelo-hawaii-oe-input-field" data-field-attr="title"
									   [ngClass]="(currentTextLanguage$ | async)"
									   (blur)="checkLanguageInput(currentLanguageTextContent.title, currentTextLanguage, 'title')"
									   field="title"
									   id="title" name="title" autocomplete="off"
									   [attr.maxlength]="maxLengths?.title"
									   placeholder="{{CB.basicInfo_resource_placeholder| localize | async}}"
									   type="text" waih-validation-color>
								<char-count [fieldLength]="currentLanguageTextContent.title?.length" [maxLength]="maxLengths?.title"></char-count>
								<waih-validation-text field="title" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
							</div>

							<!--alert for language mismatch-->
							<language-mismatch-alert
									key="title"
									[languageMismatch]="languageMismatch"
									[iconClass]="'fas fa-comment-exclamation mr-2'"
									[alertClass]="'alert-warning'"
									[title]="(CB.alert_language_mismatch_title | localize | async)"
									[body]="CB.alert_language_mismatch_body| localize:{'inputLang': oppositeLanguage, 'selectedLang': prettyLanguage} | async">
							</language-mismatch-alert>

							<!--collaborators block (disable if not owner)-->
							<div class="has-feedback">
								<span class="help-block">{{CB.basic_collab_label | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.basic_collab_tooltip | localize | async}}"></i>
								</span>
								<contributor-drop-down [userOptions]="waihonaUserRefs | async"
								                       [(ngModel)]="dataProvider.resourceSubmission.collaborators"
								                       [disabled]="!isEditingAsOwner"
								                       [showMultiple]="true"
								                       field="collaborators"
								                       placeholder="{{CB.basic_collab_placeholder | localize | async}}">
								</contributor-drop-down>
								<small class="form-text text-muted font-italic">{{CB.basic_collab_help | localize | async}}</small>
								<waih-validation-text field="collaborators"></waih-validation-text>
							</div>
						</div>
					</div>
					<!--end basic info card-->

					<!--summary card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.summary_header | localize | async}}</strong>
							<span *ngIf="sectionWasApproved('summaryBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('summaryBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('summaryBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('summaryBlock')==true, 'alert-danger':sectionWasApproved('summaryBlock')==false}">{{reviewStatusText("summaryBlock")}}</div>
							<div class="form-group has-feedback" field="summary" waih-validation-color>
								<label for="summary">{{CB.summary_label | localize | async}}
									<i class="fas fa-asterisk text-danger required-field"></i>
									<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.summary_tooltip | localize | async}}"></i>
								</label>
								<span class="olelo-hawaii-oe-field" tooltip="{{CB.oho_field_tooltip | localize:{language: (currentTextLanguagePretty$ | async)} | async}}"
									  [ngClass]="{ae: LocalizeTools.has(dataProvider.resourceSubmission, SupportedLanguage.haw, 'summary')}">
								</span>
								<textarea [(ngModel)]="currentLanguageTextContent.summary"
										  [ngClass]=" (currentTextLanguage$ | async)"
										  (blur)="checkLanguageInput(currentLanguageTextContent.summary, currentTextLanguage, 'summary')"
										  class="form-control olelo-hawaii-oe-input-field" cols="50" id="summary" autocomplete="off"
										  [attr.maxlength]="maxLengths?.summary"
										  placeholder="{{CB.summary_placeholder | localize | async}}"
										  required rows="2">
								</textarea>
								<char-count [fieldLength]="currentLanguageTextContent.summary?.length" [maxLength]="maxLengths?.summary"></char-count>
								<waih-validation-text field="summary" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
							</div>

							<!--alert for language mismatch-->
							<language-mismatch-alert
									key="summary"
									[languageMismatch]="languageMismatch"
									[iconClass]="'fas fa-comment-exclamation mr-2'"
									[alertClass]="'alert-warning'"
									[title]="(CB.alert_language_mismatch_title | localize | async)"
									[body]="CB.alert_language_mismatch_body| localize:{'inputLang': oppositeLanguage, 'selectedLang': prettyLanguage} | async">
							</language-mismatch-alert>

						</div>
					</div>

					<!--content card - Full Text-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.content_header | localize | async}}</strong>
							<span *ngIf="sectionWasApproved('descriptionBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('descriptionBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('descriptionBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('descriptionBlock')==true, 'alert-danger':sectionWasApproved('descriptionBlock')==false}">{{reviewStatusText("descriptionBlock")}}</div>
							<div class="form-group has-feedback" field="description" waih-validation-color>
								<span class="help-block">{{CB.content_label | localize | async}} <i class="fas fa-asterisk text-danger required-field"></i></span>
								<span class="olelo-hawaii-oe-field" tooltip="{{CB.oho_field_tooltip | localize:{language: (currentTextLanguagePretty$ | async)} | async}}"
									[ngClass]="{ae: LocalizeTools.has(dataProvider.resourceSubmission, SupportedLanguage.haw, 'description')}">
								</span>
								<quill-editor
										[(ngModel)]="currentLanguageTextContent.description"
										[modules]="editorModules"
										[ngClass]="(currentTextLanguage$ | async)"
										(onSelectionChanged)="checkLanguageInput(currentLanguageTextContent.description, currentTextLanguage, 'fullText')"
										class="olelo-hawaii-oe-quill-field quill-editor-size"
										name="description"
										[maxlength]="maxLengths?.description"
										placeholder="{{CB.content_placeholder | localize | async}}">
								</quill-editor>
								<small class="text-muted font-italic">{{CB.content_help | localize | async}}</small>
								<char-count [fieldLength]="descriptionPlainText?.length" [maxLength]="maxLengths?.description"></char-count>
								<waih-validation-text field="description" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
							</div>

							<!--alert for language mismatch-->
							<language-mismatch-alert
									key="fullText"
									[languageMismatch]="languageMismatch"
									[iconClass]="'fas fa-comment-exclamation mr-2'"
									[alertClass]="'alert-warning'"
									[title]="(CB.alert_language_mismatch_title | localize | async)"
									[body]="CB.alert_language_mismatch_body| localize:{'inputLang': oppositeLanguage, 'selectedLang': prettyLanguage} | async">
							</language-mismatch-alert>
						</div>
					</div>

					<!--sources card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.source_header | localize | async}}</strong>
							<span *ngIf="sectionWasApproved('sourcesBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('sourcesBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('sourcesBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('sourcesBlock')==true, 'alert-danger':sectionWasApproved('sourcesBlock')==false}">{{reviewStatusText("sourcesBlock")}}</div>
							<div class="form-group has-feedback" field="notes" waih-validation-color>
								<span class="help-block">{{CB.source_label | localize | async}}</span>
								<span class="olelo-hawaii-oe-field"
									  tooltip="{{CB.oho_field_tooltip | localize:{language: (currentTextLanguagePretty$ | async)} | async}}"
									  [ngClass]="{ae: LocalizeTools.has(dataProvider.resourceSubmission, SupportedLanguage.haw, 'notes')}">
								</span>
								<quill-editor
										[(ngModel)]="currentLanguageTextContent.notes" [modules]="editorModules"
										[ngClass]="(currentTextLanguage$ | async)"
										(onSelectionChanged)="checkLanguageInput(currentLanguageTextContent.notes, currentTextLanguage, 'sources')"
										class="quill-editor-size olelo-hawaii-oe-quill-field" name="notes"
										[maxlength]="maxLengths?.notes"
										placeholder="{{CB.source_placeholder | localize | async}}">
								</quill-editor>
								<small class="text-muted font-italic">{{CB.source_help | localize | async}}</small>
								<char-count [fieldLength]="notesPlainText?.length" [maxLength]="maxLengths?.notes"></char-count>
								<waih-validation-text field="notes" [language]="this.currentTextLanguage" (languageSwitch)="this.currentTextLanguage = $event"></waih-validation-text>
							</div>
							<!--alert for language mismatch-->
							<language-mismatch-alert
									key="sources"
									[languageMismatch]="languageMismatch"
									[iconClass]="'fas fa-comment-exclamation mr-2'"
									[alertClass]="'alert-warning'"
									[title]="(CB.alert_language_mismatch_title | localize | async)"
									[body]="CB.alert_language_mismatch_body| localize:{'inputLang': oppositeLanguage, 'selectedLang': prettyLanguage} | async">
							</language-mismatch-alert>
						</div>
					</div>

					<!--asset uploader card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.asset_header | localize | async}}</strong>
							<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.asset_tooltip | localize | async}}"></i>
							<span *ngIf="sectionWasApproved('learningAssetsBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('learningAssetsBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div *ngIf="hasGuid() else saveFirst" class="card-body">
							<div *ngIf="reviewStatusText('learningAssetsBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('learningAssetsBlock')==true, 'alert-danger':sectionWasApproved('learningAssetsBlock')==false}">{{reviewStatusText('learningAssetsBlock')}}
							</div>
							<lesson-assets-component
									[assets]="dataProvider.resourceSubmission.files"
									[resourceSubmission]="dataProvider.resourceSubmission"
									[uploadPath]="dataProvider.assetsFolderPath"
									(onFileChange)="onAssetsChanged()">
							</lesson-assets-component>
						</div>
						<ng-template #saveFirst>
							<div class="card-body">
								<p><i class="fas fa-2x fa-exclamation-triangle text-warning mr-2"></i>{{CB.asset_warning | localize | async}}</p>
							</div>
						</ng-template>
					</div>

					<!--fan contributions card-->
					<div id="fanContributions" class="card" *ngIf="dataProvider.fanContributions && dataProvider.fanContributions.length > 0">
						<div class="app-fan-contribution-review card-body"
						     [fanContributions]="dataProvider.fanContributions"
						     [currentLanguage]="this.currentTextLanguage"
						     [resourceSubmission]="dataProvider.resourceSubmission">
						</div>
					</div>

					<!--comment to reviewer card (only if not direct-publish)-->
					<div *ngIf="!dataProvider.orgConfig.autoPublishResources && isEditingAsOwner" class="card">
						<div class="card-header bg-light"><strong>{{CB.comments_header | localize | async}}</strong></div>
						<div class="card-body">
							<span class="help-block">{{CB.comments_label | localize | async}}</span>
							<textarea [(ngModel)]="dataProvider.commentsForReviewer" class="form-control" cols="50"
							          label="comments" placeholder="{{CB.comments_placeholder | localize | async}}"
							          rows="5" type="text">
							</textarea>
						</div>
					</div>

				</div> <!--close left-side column -->

				<!--===========================================================-->
				<!--------------------- Right Side Column------------------------>
				<!--===========================================================-->
				<div class="col-md-5"> <!--start right-side--->
					<!--image card-->
					<div class="card bg-light">
						<div class="card-header border-0 bg-light"><strong>{{CB.cover_header | localize | async}}</strong>
							<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.cover_tooltip | localize | async}}"></i>
							<span *ngIf="sectionWasApproved('coverImgBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('coverImgBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div *ngIf="hasGuid() else save">
							<div class="card-body">
								<div *ngIf="reviewStatusText('coverImgBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('coverImgBlock')==true, 'alert-danger':sectionWasApproved('coverImgBlock')==false}">{{reviewStatusText('coverImgBlock')}}</div>
								<app-cropper #cropperComponent [url]="this.urlService.imageUrl[ImageType.draft](this.dataProvider.resourceSubmission.guid)" (save)="this.uploadCover($event)" (failed)="notifyOnFail()"></app-cropper>
								<span *ngIf="isSavingImage" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Saving image...</span></span>
							</div>
						</div>
						<ng-template #save>
							<div class="card-body">
								<p><i class="fas fa-2x fa-exclamation-triangle text-warning"></i> {{CB.cover_warning | localize | async}}</p>
							</div>
						</ng-template>
					</div>

					<!--Config options card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.config_header | localize | async}}</strong></div>
						<div class="card-body">
							<div class="custom-control-inline custom-switch">
								<input type="checkbox" class="custom-control-input" id="allowFanContributionsSwitch"
									   [(ngModel)]="dataProvider.resourceSubmission.configuration.allowFanContributions">
								<label class="custom-control-label" for="allowFanContributionsSwitch">{{CB.config_switch_fans | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" placement="left" tooltip="{{CB.config_tooltips_fans | localize | async}}"></i>
								</label>
							</div>
							<div class="hidden custom-control-inline custom-switch">
								<input type="checkbox" class="custom-control-input" id="privateMessageSwitch"
									   [(ngModel)]="dataProvider.resourceSubmission.configuration.allowPrivateMessages">
								<label class="custom-control-label" for="privateMessageSwitch">{{CB.config_switch_messages | localize | async}}</label>
							</div>
							<div class="hidden custom-control-inline custom-switch">
								<input type="checkbox" class="custom-control-input" id="publicCommentSwitch"
									   [(ngModel)]="dataProvider.resourceSubmission.configuration.allowPublicComments">
								<label class="custom-control-label" for="publicCommentSwitch">{{CB.config_switch_comments | localize | async}}</label>
							</div>
							<div class="custom-control-inline custom-switch">
								<input type="checkbox" class="custom-control-input" id="publicAccessSwitch"
									   [(ngModel)]="dataProvider.resourceSubmission.configuration.allowPublicAccess">
								<label class="custom-control-label" for="publicAccessSwitch">{{CB.config_switch_public_access | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" placement="left" tooltip="{{CB.config_tooltips_public_access | localize | async}}"></i>
								</label>
							</div>
							<div *ngIf="dataProvider.orgConfig.allowGhostwriters" class="custom-control-inline custom-switch">
								<input type="checkbox" class="custom-control-input" id="hideSubmitterNameSwitch"
									   [(ngModel)]="dataProvider.resourceSubmission.configuration.hideSubmitterName">
								<label class="custom-control-label" for="hideSubmitterNameSwitch">{{CB.config_switch_ghost | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" placement="left" tooltip="{{CB.config_tooltips_ghost | localize | async}}"></i>
								</label>
							</div>
						</div>
					</div>

					<!--meta information card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.meta_header | localize | async}}</strong>
							<span *ngIf="sectionWasApproved('metaBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('metaBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('metaBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('metaBlock')==true, 'alert-danger':sectionWasApproved('metaBlock')==false}">{{reviewStatusText('metaBlock')}}</div>

							<!--grade level-->
							<div class="form-group has-feedback">
								<span class="help-block">{{CB.meta_label_grade | localize | async}}
									<i class="fas fa-asterisk text-danger required-field"></i>
									<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.meta_tooltip_grade | localize | async}}"></i>
								</span>
								<ng-select
										[(ngModel)]="dataProvider.resourceSubmission.gradeLevels" [items]="dataProvider.gradeOptions" [multiple]="true"
										class="bootstrap custom" field="gradeLevels"
										placeholder="{{CB.meta_placeholder_grade | localize | async}}"
										waih-validation-color>
								</ng-select>
								<waih-validation-text field="gradeLevels"></waih-validation-text>
							</div>

							<!--subject categories-->
							<div class="form-group has-feedback" field="subjectCategories" waih-validation-color>
								<span class="help-block">{{CB.meta_label_subject | localize | async}}<i class="fas fa-asterisk text-danger required-field"></i></span>
								<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.meta_tooltip_subject | localize | async}}"></i>
								<ng-select
										[(ngModel)]="dataProvider.resourceSubmission.subjectCategories" [addTag]="true"
										[items]="dataProvider.subjectCategories" [multiple]="true"
										addTagText="{{CB.button_suggestNew | localize | async}} "
										class="bootstrap custom"
										placeholder="{{CB.meta_placeholder_subject | localize | async}}">
									<ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
										<div *ngIf="item.value != null" class="option">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label">{{item.value}}</span>
										</div>
										<div *ngIf="item.value == null" class="option suggest">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label"><span class="suggestText">{{CB.button_suggest | localize | async}} </span>
												{{item.label}}
											</span>
										</div>
									</ng-template>
								</ng-select>
								<waih-validation-text field="subjectCategories"></waih-validation-text>
							</div>

							<!--cultural perspectives-->
							<div class="form-group has-feedback">
								<span class="help-block">{{CB.meta_label_culture | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.meta_tooltip_culture | localize | async}}"></i>
								</span>
								<ng-select [(ngModel)]="dataProvider.resourceSubmission.hawaiianCategories" [addTag]="true"
										   [items]="dataProvider.hawaiianCategories" [multiple]="true"
										   addTagText="{{CB.button_suggestNew | localize | async}} "
										   class="bootstrap custom" field="hawaiianCategories"
										   placeholder="{{CB.meta_placeholder_culture | localize | async}}"
										   waih-validation-color>
									<ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
										<div *ngIf="item.value != null" class="option">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label">{{item.value}}</span>
										</div>
										<div *ngIf="item.value == null" class="option suggest">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label"><span class="suggestText">{{CB.button_suggest | localize | async}} </span>
												{{item.label}}
											</span>
										</div>
									</ng-template>
								</ng-select>
								<waih-validation-text field="hawaiianCategories"></waih-validation-text>
							</div>

							<!--Learning modalities-->
							<div class="form-group has-feedback">
								<span class="help-block">{{CB.meta_label_mode | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.meta_tooltip_mode | localize | async}}"></i>
								</span>
								<ng-select [(ngModel)]="dataProvider.resourceSubmission.learningModalities"
										   [items]="dataProvider.learningModalities" [multiple]="true"
										   class="bootstrap custom" field="learningModalities"
										   placeholder="{{CB.meta_placeholder_mode | localize | async}}"
										   waih-validation-color>
									<ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
										<div *ngIf="item.value != null" class="option">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label">{{item.value}}</span>
										</div>
									</ng-template>
								</ng-select>
								<waih-validation-text field="learningModalities"></waih-validation-text>
							</div>

							<!--aina-->
							<div class="form-group has-feedback">
								<span class="help-block">{{CB.meta_label_aina | localize | async}}
									<i class="fas fa-question-circle text-secondary mx-1"
									   tooltip="{{CB.meta_tooltip_aina | localize | async}}"></i>
								</span>
								<ng-select [(ngModel)]="dataProvider.resourceSubmission.aina"
										   [items]="dataProvider.ainaRefList"
										   [loading]=""
										   [multiple]="true"
										   bindLabel="label"
										   class="bootstrap custom"
										   field="aina"
										   placeholder="{{CB.meta_placeholder_aina | localize | async}}"
										   waih-validation-color>
									<ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
										<div class="option">
											<span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
											<span class="ng-value-label">{{toAina(item).renderLabel(RenderLabelStyle.parent)}}</span>
										</div>
									</ng-template>
									<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
										<div class="option">
											<span class="ng-value-label">{{toAina(item).renderLabel(RenderLabelStyle.hyphens, RenderLabelStyle.parent)}}</span>
										</div>
									</ng-template>
								</ng-select>
								<waih-validation-text field="aina"></waih-validation-text>
							</div>

							<!--collection-->
							<div *ngIf="isPreviouslyPublished && dataProvider.orgConfig.autoPublishResources" class="form-group has-feedback">
								<select-collection [selectableCollections]="dataProvider.filteredCollections"
												   [selectedCollections]="dataProvider.selectedCollections"
												   [cbTooltip]="CB.meta_tooltip_series | localize | async"
												   (onSelectionChange)="addSelectedCollections($event)">
								</select-collection>
							</div>

							<!--tags--> <!--todo: need new service to handle tags -->
							<div class="hidden form-group has-feedback">
								<span class="help-block">{{CB.meta_label_tags | localize | async}}</span>
								<ng-select [(ngModel)]="dataProvider.resourceSubmission.tags" [addTag]="true"
										   [items]="dataProvider.resourceSubmission.tags" [multiple]="true"
										   addTagText="{{CB.button_suggestNew | localize | async}} "
										   class="bootstrap custom" field="tags"
										   placeholder="{{CB.meta_placeholder_tags | localize | async}}"
										   waih-validation-color>
								</ng-select>
								<waih-validation-text field="tags"></waih-validation-text>
							</div>
						</div> <!--close card body-->
					</div> <!--close meta information card-->

					<!--Frameworks card-->
					<div class="card">
						<div class="card-header font-weight-bold bg-light">{{CB.frameworks_header | localize | async}}
							<span *ngIf="sectionWasApproved('frameworksBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
							<span *ngIf="sectionWasApproved('frameworksBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						</div>
						<div class="card-body">
							<div *ngIf="reviewStatusText('frameworksBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('frameworksBlock')==true, 'alert-danger':sectionWasApproved('frameworksBlock')==false}">{{reviewStatusText("frameworksBlock")}}</div>
							<div class="form-group has-feedback" field="frameworks" waih-validation-color>
								<app-framework-chooser [availableFrameworks]="dataProvider.selectableFrameworks"  [resourceSubmission]="dataProvider.resourceSubmission" ></app-framework-chooser>
							</div>
						</div>
					</div>

					<!--Links card-->
					<div class="card">
						<div class="card-header bg-light font-weight-bold">{{CB.link_header | localize | async}}</div>
						<span *ngIf="sectionWasApproved('linksBlock') == true" class="float-right text-primary font-weight-bold"><i class="far fa-check-circle fa-2x"></i></span>
						<span *ngIf="sectionWasApproved('linksBlock') == false" class="float-right text-danger font-weight-bold"><i class="far fa-times-circle fa-2x"></i></span>
						<div class="card-body">
							<div *ngIf="reviewStatusText('linksBlock')" class="alert" [ngClass]="{'alert-primary':sectionWasApproved('linksBlock')==true, 'alert-danger':sectionWasApproved('linksBlock')==false}">{{reviewStatusText('linksBlock')}}
							</div>
							<div class="form-group">
								<label for="link">{{CB.link_instructions | localize | async}}</label>
								<input [(ngModel)]="linkInput" type="url" class="form-control" id="link" name="link" placeholder="{{CB.link_placeholder | localize | async}}">
								<button class="btn btn-sm btn-primary mt-2" (click)="addLink()">{{CB.link_button | localize | async}}</button>
							</div>
							<div *ngFor="let link of dataProvider.resourceSubmission.links">
								<div class="alert alert-secondary alert-dismissible fade show mb-1" role="alert">
									<a class="text-black-50 font-weight-bold" href="{{link}}" target="_blank"><i class="fas fa-link mr-1 py-1"></i>{{link}}</a>
									<button type="button" class="close" data-dismiss="alert" aria-label="Remove" (click)="removeLink(link)">
										<span class="far fa-trash-alt small"></span>
									</button>
								</div>
							</div>
						</div>
					</div>

					<!--related resources card-->
					<div class="card">
						<div class="card-header bg-light"><strong>{{CB.related_header | localize | async}}</strong>
							<i class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{CB.related_tooltip | localize | async}}"></i>
						</div>
						<div class="card-body">
							<!--todo: add review stuff, this is perfect to be componentized. Could even share with maui for his collections functionality to add resources...trying it out now -->
							<select-resource-refs [selectableResourceRefs]="dataProvider.selectableResourceRefs"
												  [selectedResourceRefs]="selectedRelatedResources"
												  (onResourceRefsChange)="addRelatedResources($event)">
							</select-resource-refs>
							<div *ngFor="let related of dataProvider.resourceSubmission.relatedResources">
								<div class="alert alert-secondary alert-dismissible fade show mb-1" role="alert">
									<span><em><strong><a href="#/resources/{{related.guid}}" target="_blank" style="text-decoration: underline">{{resourceRefTitle(related)}}</a></strong></em> by {{resourceRefSubmitterName(related)}}</span>
									<button type="button" class="close" data-dismiss="alert" aria-label="Remove" (click)="removeRelatedResource(related)">
										<span class="far fa-trash-alt small"></span>
									</button>
								</div>
							</div>
						</div>
					</div>

				</div> <!--end right-side column-->
			</div> <!--end grid-->

		</div> <!--close card-body -->
	</div> <!--close card-->

	<!--alert to notify user about bypassing review and publishing directly-->
	<div *ngIf="dataProvider.orgConfig.autoPublishResources && isEditingAsOwner" class="alert alert-dismissible alert-danger fade show" [hidden]="alerts.hideDirectPublishAlert" role="alert">
		<span><i class="fas fa-exclamation-triangle mr-1"></i>{{CB.alert_directPublish | localize | async}}</span>
		<button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="alerts.hideDirectPublishAlert=true;">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--alert to notify user about saving if editing as a collaborator-->
	<div *ngIf="!isEditingAsOwner && !isEditingAsEditor" class="alert alert-dismissible alert-warning fade show" [hidden]="alerts.hideSaveAsCollaboratorAlert" role="alert">
		<span><i class="fas fa-exclamation-triangle mr-1"></i>{{CB.alert_editAsCollaborator | localize | async}}</span>
		<button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="alerts.hideSaveAsCollaboratorAlert=true;">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<!--buttons-->
	<h6 class="font-italic text-primary">{{CB.version_label | localize | async}} {{dataProvider.resourceSubmission.version.toString()}}
		<i class="fas fa-question-circle text-secondary mx-1" tooltip="{{CB.version_tooltip | localize | async}}"></i>
	</h6>
	<div class="col-12 ml-4 px-2">
		<button (click)="onSave();" class="btn btn-lg btn-secondary" type="submit" [disabled]="loaders.isSaving">
			<span *ngIf="loaders.isSaving" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Saving...</span></span>
			<i class="far fa-save"></i>&ensp;{{CB.button_save | localize | async}}
		</button>
		<button *ngIf="!shouldBeDisabled" (click)="onPreview()" id="preview-button" triggers="pointerenter:pointerout"
				class="btn btn-lg btn-secondary" type="button">
			<i class="fas fa-eye"></i>&ensp;{{CB.button_preview | localize | async}}
		</button>

		<button *ngIf="!dataProvider.orgConfig.autoPublishResources" (click)="onSubmit()"
				tooltip="{{CB.tooltip_saveBeforeSubmit | localize | async}}"
				triggers="pointerenter:pointerout"
				[disabled]="shouldBeDisabled" [isDisabled]="showDisabledTooltip"
				class="btn btn-lg btn-primary" type="submit">
			<i class="fas fa-share"></i>&ensp;{{CB.button_submit | localize | async}}
		</button>
		<button *ngIf="dataProvider.orgConfig.autoPublishResources" (click)="onDirectPublish()"
				tooltip="{{CB.tooltip_saveBeforePublish | localize | async}}"
				triggers="pointerenter:pointerout"
				[disabled]="shouldBeDisabled || loaders.isLoadingPublish" [isDisabled]="showDisabledTooltip"
				class="btn btn-lg btn-primary" type="submit">
			<span *ngIf="loaders.isLoadingPublish" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">Publishing...</span></span>
			<i class="fas fa-share"></i>&ensp;{{CB.button_publish | localize | async}}
		</button>
	</div>

	<review-aside [resourceSubmission]="dataProvider.resourceSubmission" *ngIf="isActivityStreamVisible"></review-aside>

</div> <!--close page body-->

