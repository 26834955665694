import {NewUserRegistration} from "../domain/user/NewUserRegistration";
import {validateSync, ValidationError} from "class-validator";
import {ValidationException} from "./exceptions/ValidationException";
import {AngularFireAuth} from "@angular/fire/auth";
import {Observable} from "rxjs/index";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {ConversionService} from "./ConversionService";
import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {AuthService} from "./AuthService";
import {RegistrationFunctions} from "./functions/RegistrationFunctions";
import {NGXLogger} from "ngx-logger";
import {IServerError} from "./functions/AbstractFirebaseFunction";
import {Localization} from "../data/Localization";
import {NotificationService, ToastType} from "./common/NotificationService";
import {RegistrationException} from "./exceptions/RegistrationException";
import {Organization} from "../domain/organization/Organization";
import {InviteException} from "./exceptions/InviteException";

export interface TriState {
	pending:boolean;
	wasSuccessful:boolean;
}

@Injectable({
	providedIn: 'root',
} as any)
export class RegistrationService {

	private cbContext = Localization.template.registration;

    constructor(private angularFireAuth: AngularFireAuth,
        private conversionService:ConversionService,
        private db:AngularFirestore,
        private authService:AuthService,
        private functions:RegistrationFunctions,
        private notificationService:NotificationService,
        protected logger:NGXLogger) {
    }

    public warmUpRegistration() {
	    return this.functions.warmUpRegistration$();
    }

	/**
	 * Registers this user in the Waihona
	 * @param {NewUserRegistration} newUserRegistration the user's registration data
	 * @returns {Observable<WaihonaUser> | IServerError<NewUserRegistration>}
	 */
	public registerUser$(newUserRegistration:NewUserRegistration):Observable<WaihonaUser | IServerError<NewUserRegistration | string>> {

		console.log("isAuthenticated: " + this.authService.isAuthenticated);
		if (this.authService.isAuthenticated) {
			let loggedInError = new RegistrationException(RegistrationException.s.LOGGED_IN_CANT_REGISTER);
			this.logger.error(loggedInError.message);
			this.notificationService.displayToast(this.cbContext.toast.submit.loggedInCantRegister, ToastType.error);
			throw loggedInError;
		}
		this.validate(newUserRegistration);

		return this.functions.register$(newUserRegistration);
	}

    public validate(newUserRegistration:NewUserRegistration):void {
		this.logger.info(`validating registration data from client`);
        const errors:ValidationError[] = validateSync(newUserRegistration);

        if (errors.length > 0) {
            throw new ValidationException(errors);
        }
    }

	public verifyInviteByEmail(userEmail:string, org:Organization, inviteByEmailCode:string):boolean {
		let inviteFound = org.invites.find(invite => {
			return invite.emailAddress == userEmail && invite.uniqueCode == inviteByEmailCode;
		});
		if (inviteFound) {
			this.logger.info("found a match for inviteByEmail code");
			return true;
		} else {
			this.logger.error("Sorry! There is no matching invite in the system. Perhaps this invite has already been used?");
			this.notificationService.displayToast(this.cbContext.toast.submit.serverErrors.EMAIL_INVITE_NOT_FOUND, ToastType.error);
			return false;
		}
	}

}
