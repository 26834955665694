<div class="nice-display w-100 p-0 m-0">
	<!-- Top Section of the page -->
	<div id="top-green" class="position-relative overflow-hidden mx-0 px-0 w-100" style="height: calc(100vh - 35px);">
		<div class="top-section bg-primary d-flex flex-wrap mx-0 px-0 w-100">
			<app-bird></app-bird>
			<div class="section-holder d-flex flex-wrap align-items-center mx-0 px-0 w-100">
				<div class="container visual-area text-center text-black-50 font-weight-bold py-13 w-100">
					<!--waihona logo-->
					<ng-container *ngIf="shouldTryToDisplayLottieAnimation;else other_content">
						<app-logo></app-logo>
					</ng-container>
					<ng-template #other_content>
						<div class="static-logo-container">
							<img src="/assets/img/waihona-logo-birds.svg" alt="logo" class="static-logo">
						</div>
					</ng-template>
					<!--end waihona logo-->

					<h5 class="site-tagline py-md-3 font-weight-bold">{{CB.tagline | localize | async }}</h5>
					<div role="group">
						<ng-template let-loggedIn="userLoggedIn" let-redirectedFromRegistration="userRedirectedFromRegistration" [ngTemplateOutletContext]="{ userLoggedIn: (isLoggedIn | async), userRedirectedFromRegistration: (wasRedirectedFromRegistration() | async) }" [ngTemplateOutlet]="selfie" #selfie>
							<button type="button" class="btn btn-lg" *ngIf="loggedIn" [routerLink]="['resources']">{{CB.get_started | localize | async}}</button>
							<button type="button" class="btn btn-lg" *ngIf="!loggedIn && !redirectedFromRegistration " [routerLink]="['/user/register']">{{CB.register | localize | async}}</button>
							<button type="button" class="btn btn-lg" *ngIf="!loggedIn && redirectedFromRegistration" (click)="login()" >{{CB.login | localize | async}}</button>
						</ng-template>
							<button type="button" class="btn btn-lg" (click)="scrollTo('roadmap')">{{CB.roadmap | localize | async}}</button>
						<a href="{{updatesDoc}}" target="_blank" rel="noopener" style="text-decoration: none">
							<button type="button" class="btn btn-lg">{{CB.updates | localize | async}}</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Visual area -->
	<div id="wrapper2" class="position-relative">
		<main id="main">
			<div class="container position-relative">

				<!-- development box area -->
				<div class="alert-box shake-vertical">
					<div class="holder d-flex flex-wrap align-items-center justify-content-center w-100 h-100">
						<div class="position-relative alert-text">
							<i class="fas fa-tools fa-2x mb-2"></i>
							<h3><strong>{{CB.inDevelopment | localize | async}}</strong></h3>
							<p>{{CB.version | localize | async}} {{version}}</p>
						</div>
					</div>
				</div>

				<!-- Features Area -->
				<div class="features pb-lg-15 pb-5">
					<div class="text-center text-black-50 mb-4">
						<h1 class="text-primary"><strong>{{CB.features_title | localize | async}}</strong></h1>
						<h4 class="text-dark"><strong>{{CB.features_subtitle | localize | async}}</strong></h4>
					</div>
					<div class="row">
						<div class="col-lg-5">
							<div class="mb-4">
								<h2 class="mb-2 text-primary"><i class="fad fa-cloud-upload-alt fa-2x mr-3 text-secondary"></i>{{CB.features_first_header | localize | async}}</h2>
								<p>{{CB.features_first_body | localize | async}}</p>
							</div>
							<div class="mb-4">
								<h2 class="mb-2 text-primary"><i class="fad fa-grin-beam fa-2x mr-3 text-secondary"></i>{{CB.features_second_header | localize | async}}</h2>
								<p>{{CB.features_second_body | localize | async}}</p>
							</div>
						</div>
						<div class="col-lg-2 d-flex justify-content-center p-0">
							<img src="/assets/images/home/lolo-uila.svg" alt="illustration" class="artwork">
						</div>
						<div class="col-lg-5">
							<div class="mb-4">
								<h2 class="mb-2 text-primary"><i class="fad fa-chart-network fa-2x mr-3 text-secondary"></i>{{CB.features_third_header | localize | async}}</h2>
								<p>{{CB.features_third_body | localize | async}}</p>
							</div>
							<div class="mb-4">
								<h2 class="mb-2 text-primary"><i class="fad fa-waveform-path fa-2x mr-3 text-secondary"></i>{{CB.features_fourth_header | localize | async}}</h2>
								<p>{{CB.features_fourth_body | localize | async}}</p>
							</div>
						</div>
					</div>
				</div>

				<!-- Roadmap Area -->
				<div id="roadmap" class="text-center py-lg-15 py-5 border-top">
					<h1 class="text-primary mb-3"><strong>{{CB.roadmap_header | localize | async}}</strong></h1>
					<a [href]="roadmapUrl" target="_blank" rel="noopener">
						<img [src]="roadmapUrl" alt="illustration" class="w-100 border-dark">
					</a>
				</div>

				<!-- Article Area -->
				<div id="earlyAdopters" class="text-center border-top">
					<article class="article py-lg-15 py-5 my-3 border-bottom">
						<div class="row">
							<div class="col-md-3 image-area">
								<div class="image style01">
									<img src="/assets/images/home/egg.svg" alt="image-description" width="179" height="230" class="d-block mx-auto mb-md-0 mb-3">
								</div>
							</div>
							<div class="col-md-8 description-area d-flex align-items-center">
								<div class="description">
									<h2 class="text-primary"><strong>{{CB.articles_first_title | localize | async}}</strong></h2>
									<h4 class="mb-3 text-dark"><strong>{{CB.articles_first_subtitle | localize | async}}</strong></h4>
									<p class="text-left">{{CB.articles_first_body | localize | async}}</p>
								</div>
							</div>
						</div>
					</article>
					<article class="article py-lg-15 py-5 my-3 border-bottom">
						<div class="row">
							<div class="col-md-4 image-area">
								<div class="image">
									<img src="/assets/images/home/kala.svg" alt="image-description" width="401" height="293" class="d-block mx-auto mb-md-0 mb-3">
								</div>
							</div>
							<div class="col-md-8 description-area d-flex align-items-center">
								<div class="description">
									<h2 class="text-primary"><strong>{{CB.articles_second_title | localize | async}}</strong></h2>
									<h4 class="mb-3 text-dark"><strong>{{CB.articles_second_subtitle | localize | async}}</strong></h4>
									<p class="text-left">{{CB.articles_second_body | localize | async}}</p>
								</div>
							</div>
						</div>
					</article>
					<article class="article py-lg-15 py-5 my-3 border-bottom">
						<div class="row">
							<div class="col-md-4 image-area">
								<div class="image mt-lg-n6">
									<img src="/assets/images/home/e-lima.svg" alt="image-description" width="350" height="335" class="d-block mx-auto mb-md-0 mb-3">
								</div>
							</div>
							<div class="col-md-8 description-area d-flex align-items-center">
								<div class="description">
									<h2 class="text-primary"><strong>{{CB.articles_third_title | localize | async}}</strong></h2>
									<h4 class="mb-3 text-dark"><strong>{{CB.articles_third_subtitle | localize | async}}</strong></h4>
									<p class="text-left">{{CB.articles_third_body | localize | async}}</p>
								</div>
							</div>
						</div>
					</article>
				</div>

				<!-- bottom area -->
				<div class="about-area text-center py-lg-15 py-5 border-bottom">
					<h1 class="text-dark"><strong>{{CB.footer_title | localize | async}}</strong></h1>
					<ng-template let-loggedIn="userLoggedIn" let-redirectedFromRegistration="userRedirectedFromRegistration" [ngTemplateOutletContext]="{ userLoggedIn: (isLoggedIn | async), userRedirectedFromRegistration: (wasRedirectedFromRegistration() | async) }" [ngTemplateOutlet]="selfie" #selfie>
						<button type="button" class="btn btn-lg btn-danger" *ngIf="loggedIn" [routerLink]="['resources']">{{CB.get_started | localize | async}}</button>
						<button type="button" class="btn btn-lg btn-danger" *ngIf="!loggedIn && !redirectedFromRegistration " [routerLink]="['/user/register']">{{CB.register | localize | async}}</button>
						<button type="button" class="btn btn-lg btn-danger" *ngIf="!loggedIn && redirectedFromRegistration" (click)="login()" >{{CB.login | localize | async}}</button>
					</ng-template>
					<div> <!--todo: build an in-app landing page for org admins to apply to join-->
						<ul class="about-list list-unstyled d-md-flex flex-wrap justify-content-center">
							<li><a href="{{orgRegistrationFormUrl}}" target="_blank" rel="noopener">{{CB.footer_org_admin | localize | async}}</a></li>
							<li><a href="{{updatesDoc}}" target="_blank" rel="noopener">{{CB.footer_updates | localize | async}}</a></li>
						</ul>
					</div>
				</div>
			</div>
		</main>
	</div>
</div>