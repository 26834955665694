import {registerDecorator, ValidationArguments, ValidationOptions} from "class-validator";

export function countNumberChars(str) {
	let count=0,len=str.length;
	for(let i=0;i<len;i++) {
		if(/[0-9]/.test(str.charAt(i))) {
			count++;
		}
	}
	return count;
}

export function HasThisManyNumbers(property: string, thanThisMany:number, validationOptions?: ValidationOptions) {
	return function (object: Object, propertyName: string) {
		registerDecorator({
			name: "HasThisManyNumbers",
			target: object.constructor,
			propertyName: propertyName,
			constraints: [property],
			options: validationOptions,
			validator: {
				validate(value: any, args: ValidationArguments) {
					const [relatedPropertyName] = args.constraints;
					const propertyValue:number = (args.object as any)[relatedPropertyName];

					if(propertyValue == null) {
						return false;
					}

					return countNumberChars(propertyValue) >= thanThisMany; // you can return a Promise<boolean> here as well, if you want to make async validation
				}
			}
		});
	};
}
