import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {NGXLogger} from "ngx-logger";
import {PageListService, PagingService} from "./PageListService";
import {Collection} from "../domain/collections/Collection";
import {CollectionRepository} from "./repository/CollectionRepository";
import {LocalizationService} from "./LocalizationService";
import {BehaviorSubject, combineLatest, forkJoin, Subject} from "rxjs";
import {uniqWith, isEqual} from "lodash";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {PublishedResourceRef} from "../domain/resources/PublishedResourceRef";
import {CollectionOrderType} from "../domain/collections/CollectionConfigurationEnums";
import {CollectionRef} from "../domain/collections/CollectionRef";
import {CollectionTextContent} from "../domain/collections/CollectionTextContent";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";
import {sort} from "../helper/SortTools";

@Injectable({
  providedIn: 'root',
} as any)
export class CollectionService implements PagingService<Collection>{

	private _paging:{
		list:PageListService<Collection>,
	};

	public paging = {
		pageSize: 20,
		list: {
			cursor: () => {
				return this._paging.list.pageCursor;
			},
			initialize: {
				list$:() =>  this._paging.list.initializePaging$(this.repo.by.criteria.all(), this.paging.pageSize)
			},
			next:() => {
				this._paging.list.getNextPage();
			},
			isLoading: () => {
				return this._paging.list.pageLoading$;
			},
			nextPageExists: () => {
				return this._paging.list.pageCursor?.nextPageExists;
			},
		}
	}

	constructor(public repo:CollectionRepository,
                public localizationService:LocalizationService,
                protected logger:NGXLogger) {

		this._paging = {
			list: new PageListService<Collection>(this.repo),
		}
    }

	public get store() {
    	return this.repo.accessors();
	}

	public get by() {
    	return this.repo.by;
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}

	public sortCollections(collections:Array<Collection>):Array<Collection> {
		return sort(collections, "title", {nupepafy: true, localizedField: true, lang:this.localizationService.language, ref:false, textContentType:CollectionTextContent}).ascending();
	}

	public sortResourceRefs(resourceRefs:Array<PublishedResourceRef>):Array<PublishedResourceRef> {
		return sort(resourceRefs, "title", {nupepafy: true, localizedField: true, lang:this.localizationService.language, ref:true}).ascending();
	}

	/** Get all collections visible to user */
	public getAllCollectionsVisibleToUser$(user:WaihonaUser):Observable<Collection[]> {
		let resultObservable$:Subject<Collection[]> = new Subject<Collection[]>();
		let allQueryObservables:Observable<any>[] = [];

		allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.isPublic()));
		if (user) {
			allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.userIsOwner(user.guid)));
			allQueryObservables.push(...this.repo.queryBatched$(this.repo.by.queryFn.userIsMemberOfOwningOrg(user)));
			allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.userIsCollaborator(user.guid)));
			allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.userIsSharedTo(user.guid)));
		}

		combineLatest(allQueryObservables).subscribe((arrayOfCollectionArrays:Array<Collection[]>) => {
			let concatenated:Array<Collection> = [];
			arrayOfCollectionArrays.forEach((array:Collection[]) => {
				concatenated = concatenated.concat(array);
			})
			let dedupedResults:Array<Collection> = uniqWith(concatenated, isEqual);

			resultObservable$.next(dedupedResults);
		});

		return resultObservable$;
	}

	/** Get all collections user can edit */
	public getAllCollectionsUserCanEdit$(user:WaihonaUser):Observable<Collection[]> {
		let resultObservable$:Subject<Collection[]> = new Subject<Collection[]>();
		let allQueryObservables:Observable<any>[] = [];

		if (user) {
			allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.userIsOwner(user.guid)));
			allQueryObservables.push(...this.repo.queryBatched$(this.repo.by.queryFn.userIsAdminOfOwningOrg(user)));
			allQueryObservables.push(this.repo.query$(this.repo.by.queryFn.userIsCollaborator(user.guid)));
		} else {
			//TODO: throw error here
		}

		combineLatest(allQueryObservables).subscribe((arrayOfCollectionArrays:Array<Collection[]>) => {
			let concatenated:Array<Collection> = [];
			arrayOfCollectionArrays.forEach((array:Collection[]) => {
				concatenated = concatenated.concat(array);
			})
			let dedupedResults:Array<Collection> = uniqWith(concatenated, isEqual);
			resultObservable$.next(dedupedResults);
			//todo: sort results
		});

		return resultObservable$;
	}

	public getAllCollectionsContainingAResource$(resourceGuid:string):Observable<Collection[]> {
		return this.repo.query$(this.repo.by.queryFn.containsResource(resourceGuid));
	}

	/** Get all collections that a resource is found in, and which user can view */
	public getAllCollectionsContainingAResourceAndUserCanView$(resourceGuid:string, user:WaihonaUser):Observable<Collection[]> {
		this.logger.log(`CollectionService::getAllCollectionsContainingAResourceAndUserCanView$ is fetching...`);
		const operation = (list1:Array<Collection>, list2:Array<Collection>, isUnion:boolean = false) =>
			list1.filter(
				(set => a => isUnion === set.has(a.guid))(new Set(list2.map(b => b.guid)))
			);
		const inBoth = (list1, list2) => operation(list1, list2, true);
		let resultObservable$:Subject<Collection[]> = new Subject<Collection[]>();
		let allQueryObservables:Observable<Collection[]>[] = [];

		if (resourceGuid && user) {
			allQueryObservables.push(this.getAllCollectionsContainingAResource$(resourceGuid));
			allQueryObservables.push(this.getAllCollectionsVisibleToUser$(user));
		}
		combineLatest(allQueryObservables).subscribe((arrayOfCollectionArrays:Array<Collection[]>) => {
			let intersection:Array<Collection> = inBoth(arrayOfCollectionArrays[0], arrayOfCollectionArrays[1]);
			resultObservable$.next(intersection);
			this.logger.log(`CollectionService::getAllCollectionsContainingAResourceAndUserCanView$ found ${intersection.length} collections.`);
		});
		return resultObservable$;
	}

	/** Get all collections that a resource is found in, and which user can edit */
	public getAllCollectionsContainingAResourceAndUserCanEdit$(resourceGuid:string, user:WaihonaUser):Observable<Collection[]> {
		const operation = (list1:Array<Collection>, list2:Array<Collection>, isUnion:boolean = false) =>
			list1.filter(
				(set => a => isUnion === set.has(a.guid))(new Set(list2.map(b => b.guid)))
			);
		const inBoth = (list1, list2) => operation(list1, list2, true);
		let resultObservable$:Subject<Collection[]> = new Subject<Collection[]>();
		let allQueryObservables:Observable<Collection[]>[] = [];

		if (resourceGuid && user) {
			allQueryObservables.push(this.getAllCollectionsContainingAResource$(resourceGuid));
			allQueryObservables.push(this.getAllCollectionsUserCanEdit$(user));
		}
		combineLatest(allQueryObservables).subscribe((arrayOfCollectionArrays:Array<Collection[]>) => {
			let intersection:Array<Collection> = inBoth(arrayOfCollectionArrays[0], arrayOfCollectionArrays[1]);
			resultObservable$.next(intersection);
		});
		return resultObservable$;
	}

    /**
    * Saves a collection to the database.
    * @param collection Collection The collection to save
    */
    public save$(collection:Collection):Observable<Collection> {
    	if (collection.guid) {
		    console.log(`Collection Service is saving the collection ${collection.guid}.`);
	    } else {
    		this.logger.info(`Collection Service is saving the new collection.`);
	    }
        return this.repo.save$(collection);
    }

	/**
	 * Saves all collections in an array to the database.
	 * @param arrayOfCollections Array<Collection> The array of collections to save
	 */
	public saveAllCollections$(arrayOfCollections:Collection[]):Observable<Collection[]> {
		this.logger.info(`Collection Service is saving the following collections:`);
		this.logger.info(arrayOfCollections);
		return this.repo.saveAll$(arrayOfCollections);
	}

	public updateCover(collectionGuid:string, imageExists:boolean):Observable<Collection> {
		this.logger.info(`update partial on collection guid: ${collectionGuid}, imageExists: ${imageExists}`);
		return this.repo.updatePartial$({guid: collectionGuid, configuration:{ hasImage: imageExists}});
	}

	public addResourceRefsToCollections$(resourceRefs:Array<PublishedResourceRef>, collections:Array<Collection>, userGuid:string):Observable<boolean> {
		this.logger.info(`CollectionService::AddResourceRefsToCollections$ is starting ....`);
		let updateObservables:Array<Observable<Collection>> = [];
		let isComplete$:Subject<boolean> = new Subject<boolean>();

		collections.forEach((collection:Collection) => {
			this.logger.info(`Checking ${collection.guid}`);
			resourceRefs.forEach((resourceRef:PublishedResourceRef) => {
				// if the resource is not already on the collection, then add it, otherwise do nothing.
				if (!collection.items.find((collectionItem:PublishedResourceRef) => collectionItem.guid === resourceRef.guid)) {
					this.logger.info(`CollectionService::AddResourceRefsToCollections$ is adding resource ${resourceRef.guid} to collection ${collection.guid}.`);
					collection.items.push(resourceRef);
				}
			});

			// if sort order for the collection is alphabetical, sort items after adding the new ones
			if (collection.configuration.orderType === CollectionOrderType.alphabetical) {
				this.sortResourceRefs(collection.items);
			}
			let toSave:Collection = new Collection();
			toSave.guid = collection.guid;
			toSave.items = collection.items;
			toSave.lastSavedBy = userGuid;
			updateObservables.push(
				this.repo.updatePartial$(toSave, {guid: true, items: true, lastSavedBy: true})
			);
		});

		// when all chosen collections have been updated, update the observable
		forkJoin(updateObservables).subscribe((collections:Collection[]) => {
			this.logger.info(`CollectionService:AddResourceRefsToCollections$ completed with ${collections.length} collections updated.`);
			isComplete$.next(true);
		});
		return isComplete$;
	}

	public convertCollectionToCollectionRefs(collections:Array<Collection>):Array<CollectionRef> {
		this.logger.log(`CollectionService::convertCollectionToCollectionRefs is running...`);
		return this.repo.convertToArrayOfRefs(collections);
	}

}
