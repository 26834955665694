import {Injectable} from "@angular/core";
import {ContentBlockRepository} from "./repository/content-block.repository";
import {ContentBlock, ContentBlockContentType} from "../domain/content/ContentBlock";
import {ContentBlockTranslation} from "../domain/content/ContentBlockTranslation";
import {Localization, TemplateData} from "../data/Localization";
import {BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import * as Mustache from "mustache";
import {AngularFireFunctions} from "@angular/fire/functions";
import {DefaultStorageBucket} from "./storage/DefaultStorageBucket";
import {UrlService} from "./UrlService";
import {ContentBlockRegistryValue} from "../domain/content/ContentBlockRegistryValue";
import {NGXLogger} from "ngx-logger";
import {PermissionType} from "../domain/user/Permission";
import {RoleService} from "./common/RoleService";
import {Router} from "@angular/router";
import {without} from "lodash";
import {ValidationError} from "class-validator";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {SupportedLanguage, SupportedLanguages} from "../domain/SupportedLanguages";
import {LocalUserSettingsRepository} from "./repository/LocalUserSettingsRepository";
import {ITextContentObject, RefLocalization} from "../domain/ITextContentObject";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";

@Injectable({
	providedIn: 'root',
} as any)
export class LocalizationService {

	private warnings:BehaviorSubject<ValidationError[]> = new BehaviorSubject<ValidationError[]>([]);
	public warnings$ = this.warnings.asObservable();

	public contentBlockCache:{ [key:string]:ContentBlock } = {};
	public contentBlockRegistry:{ [key:string]:ContentBlockRegistryValue } = {};
	public language:SupportedLanguage = SupportedLanguage.en; //The current language
	public language$:ReplaySubject<SupportedLanguage> = new ReplaySubject<SupportedLanguage>();
	public languagePretty$:ReplaySubject<string> = new ReplaySubject<string>();

	public textContent:{ [key:string]:Localization } = {
		local: new Localization(true),
		remote: new Localization(false)
	};

	public LocalizeTools = {
		has: (document:ITextContentObject<any>, language:SupportedLanguage, field:string) => {
			return document && document.documentTextContent && document.documentTextContent[language] && document.documentTextContent[language][field];
		},
		ref: (document:RefLocalization, language:SupportedLanguage = null, key:string) => {
			if(language == null) {
				language = this.language;
			}
			//Language requested is available
			if(document && document[key] && document[key][language] != null){
				return document[key][language];
			} else if (document && document[key]) {
				return document[key][SupportedLanguages.oppositeOf(language)];
			}
			//Language requested is not available
			return null;
		},
		document: (document:ITextContentObject<any>, textContentClass:new() => any, language:SupportedLanguage = null) => {
			if (language == null) {
				language = this.language;
			}
			let element:typeof textContentClass = document.documentTextContent[language];
			let defaultElement = document.documentTextContent[SupportedLanguage.en];
			let alternateElement = document.documentTextContent[SupportedLanguage.haw];
			let render:any = new textContentClass();

			let isBlank:Function = (value:string) => {
				if (value == null) {
					return true;
				}
				return (value.trim() == '');
			};

			if (element != null) {
				Object.keys(render).map(property => {
					if (property == "$render") {
						return;
					}
					let value:string = element[property];
					if (defaultElement) {
						let defaultValue:string = defaultElement[property];
						let defaultIsBetter:boolean = isBlank(value) && !isBlank(defaultValue);
						render[property] = defaultIsBetter ? defaultValue : value;
						render.$render[property] = defaultIsBetter ? SupportedLanguage.en : language;
					} else {
						render[property] = value;
					}

				});
			} else {
				Object.keys(render).map(property => {
					if (property == "$render") {
						return;
					}

					if (defaultElement) {
						render[property] = defaultElement[property];
						render.$render[property] = language;
					} else if (alternateElement) {
						language = SupportedLanguage.haw;
						render[property] = alternateElement[property];
						render.$render[property] = language;
					} else {
						console.log("issues with item " + property);
					}
				});
			}
			return render;
		},
		buildDocTextContentSearchFn: (textContentClass:new() => any, searchProperty:string) => {
			return (term: string, textContentObject:ITextContentObject<any>):boolean => {
				let currentLang:SupportedLanguage = this.language;
				let alternateLang:SupportedLanguage = SupportedLanguages.oppositeOf(currentLang);
				let defaultTextContent:typeof textContentClass = textContentObject?.documentTextContent[currentLang];
				let alternateTextContent:typeof textContentClass = textContentObject?.documentTextContent[alternateLang];
				let nupepafy:(input:string) => string = NupepafyStringUtils.nupepafy;
				let searchTerm:string = nupepafy(term).toLowerCase();
				let nupepafiedTitle:string = null;
				let searchTermFound:boolean = false;

				if (defaultTextContent) {
					nupepafiedTitle = nupepafy(defaultTextContent[searchProperty]).toLowerCase();
					searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
				} else if (alternateTextContent) {
					nupepafiedTitle = nupepafy(alternateTextContent[searchProperty]).toLowerCase();
					searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
				} else {
					console.error("issue with doc text content");
				}
				return searchTermFound;
			}
		},
		buildRefSearchFn: (key:string) => {
			return (term: string, ref:any):boolean => {
				let currentLang = this.language;
				let alternateLang = SupportedLanguages.oppositeOf(currentLang);
				let defaultElement = ref.localization[key][currentLang];
				let alternateElement = ref.localization[key][alternateLang];
				let nupepafy:(input:string) => string = NupepafyStringUtils.nupepafy;
				let searchTerm:string = nupepafy(term).toLowerCase();
				let nupepafiedTitle:string = null;

				let searchTermFound:boolean = false;
				if (defaultElement) {
					nupepafiedTitle = nupepafy(ref.localization[key][currentLang]).toLowerCase();
					searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
				} else if (alternateElement) {
					nupepafiedTitle = nupepafy(ref.localization[key][alternateLang]).toLowerCase();
					searchTermFound = nupepafiedTitle.indexOf(searchTerm) != -1;
				} else {
					console.log("issues with resource ref");
				}
				return searchTermFound;
			}
		}

	};

	//Where the new data gets stored
	public active:{ [key:string]:{ '$'?:BehaviorSubject<string | Function>/*, '@type'?:string */ } } = {};
	//Whether we are editing and viewing content blocks
	protected contentBlockMode:boolean = false;
	protected contentBlockSubscriptions:Array<Subscription> = [];

	constructor(private repo:ContentBlockRepository,
	            private angularFireFunctions:AngularFireFunctions,
	            private defaultStorageBucket:DefaultStorageBucket,
	            private roleService:RoleService,
	            private urlService:UrlService,
	            private logger:NGXLogger,
	            private domSanitizer:DomSanitizer,
	            protected localUserSettingsRepository:LocalUserSettingsRepository,
	            protected router:Router) {

		this.textContent.local.toKeys().forEach(key => {
			//Set up the observable that we will watch from each of the components
			this.active[key] = {
				$: new BehaviorSubject<string | Function>("")
			}
		});
		console.log("initializing LocalizationService");
		this.language$.next(this.language);
		this.languagePretty$.next(SupportedLanguages.toPretty(this.language));
	}

	public get isContentBlockModeOn():boolean {
		return this.contentBlockMode;
	}

	public set isContentBlockModeOn(value:boolean) {
		if (this.roleService.hasPermissionFor(PermissionType.edit_localization)) {
			let isAChange:boolean = value != this.contentBlockMode;
			this.contentBlockMode = value;

			if (isAChange) {
				if (this.contentBlockMode == false) {
					this.contentBlockSubscriptions.map(subscription => {
						subscription.unsubscribe();
						return subscription;
					});
					while (this.contentBlockSubscriptions.length > 0) {
						this.contentBlockSubscriptions.pop();
					}
				}
				this.refreshActive();
			}
		}
	}

	public get currentContentBlocks():Array<ContentBlockRegistryValue> {
		//Takes the map and converts it into an array
		let keys:Array<string> = Object.keys(this.contentBlockRegistry);
		let values:Array<ContentBlockRegistryValue> = [];
		for (let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			let value:ContentBlockRegistryValue = this.contentBlockRegistry[key];
			values.push(value);
		}
		return values;
	}

	public get loc() {
		return Localization.template;
	}

	public get _() {
		return this.loc;
	}

	/** Returns content blocks which match a particular route in the registry */
	public filterContentBlocksMatchingRoute(contentBlocks:Array<ContentBlock>, route:string):Array<ContentBlock> {

		let allElementsMatchingRoute:Array<ContentBlockRegistryValue> = [];

		let keys:Array<string> = Object.keys(this.contentBlockRegistry);
		for (let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			let o:ContentBlockRegistryValue = this.contentBlockRegistry[key];

			if (o.route == route) {
				allElementsMatchingRoute.push(o);
			}
		}

		//Just get the elements which match the content blocks
		let decentContentBlocks:Array<ContentBlock> = contentBlocks.filter(contentBlock => {
			return allElementsMatchingRoute.find(contentBlockRegistryValue => {
				return contentBlock.guid == contentBlockRegistryValue.path;
			}) != null;
		});
		return decentContentBlocks;
	}

	public changeLanguage(to:SupportedLanguage):void {
		if ((<any>Object).values(SupportedLanguage).includes(to)) {
			this.localUserSettingsRepository.value.oleloHawaiiPreference.hasPreference = true;
			this.localUserSettingsRepository.value.oleloHawaiiPreference.isOleloHawaii = (to == SupportedLanguage.haw);
			this.localUserSettingsRepository.set(this.localUserSettingsRepository.value);

			this.language = to;
			this.language$.next(to);
			this.languagePretty$.next(SupportedLanguages.toPretty(to));

			this.refreshActive();
		}
	}

	/** Takes the CB object on a component and builds a correlated observable array and builds $ on each subobject.  This only generates if not already used. */
	public localizeData(inputMap:{ [key:string]:{ '_'?, '$'?:BehaviorSubject<string | Function>, en?:string | Function, haw?:string | Function, '@type'?:string } }):void {
		let keys:Array<string> = Object.keys(inputMap);
		inputMap.$ = {};
		for (let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			if (key == "$") {
				continue; //skip localization its part of the active watchers we're creating
			}

			let reference = inputMap[key];
			if (typeof reference == "string") {
				continue;
			}
			let referencePath:string = reference._;
			let activeItem = this.active[referencePath];
			inputMap.$[key] = activeItem.$;
			this.simpleGet(referencePath); //Update the active path

		}
	}

	public refreshActive():void {
		let self:LocalizationService = this;

		/*		let keys:Array<string> = Object.keys(this.active);
				for(let i:number = 0; i < keys.length; i++) {
					let path:string = keys[i];
					this.simpleGet(path);
				}
				return;*/

		//Content block mode on?  Only do a fetch for what is currently in the cache...otherwise grab what we have locally.
		if (this.contentBlockMode) {
			//First iterate over
			let allItems:Array<string> = Object.keys(this.active);
			let itemsNeedingContentBlock:Array<string> = this.currentContentBlocks.map(contentBlock => {
				return contentBlock.path;
			});

			let itemsNeedingRegularGet:Array<string> = without(allItems, ...itemsNeedingContentBlock);
			let itemsNeedingContentBlockReload:Array<string> = without(allItems, ...itemsNeedingRegularGet);

			for (let i:number = 0; i < itemsNeedingContentBlockReload.length; i++) {
				let key:string = itemsNeedingContentBlockReload[i];
				this.simpleGet(key);
			}
			for (let i:number = 0; i < itemsNeedingRegularGet.length; i++) {
				let key:string = itemsNeedingRegularGet[i];
				let content:string = this.getWithSomeProcessing(key);
				this.active[key].$.next(content);
			}
		} else {
			let keys:Array<string> = Object.keys(this.active);
			for (let i:number = 0; i < keys.length; i++) {
				let path:string = keys[i];
				this.simpleGet(path);
			}
		}


	}

	public registerAndLocalize(ClassName:string, paths:{ [key:string]:{} }, route?:string):void {
		this.register(ClassName, paths, route);
		this.localizeData(paths);
	}

	public register(className:string, paths:{ [key:string]:{} }, route?:string) {
		//TODO: This class is incorrect..existing record will never have a value because it stores based on path not classname
		let existingRecord:ContentBlockRegistryValue = this.contentBlockRegistry[className];
		if (existingRecord) {
			return; //Exists already; we donʻt need to register
		}
		if (route == null) {
			route = this.router.url;
		}

		let recursiveFind = (obj:{}, array) => {
			let keys:Array<string> = Object.keys(paths);
			for (let i:number = 0; i < keys.length; i++) {
				let key:string = keys[i];
				let value:any = obj[key];
				if (typeof value == "string" || value instanceof Function) {
					continue;
				}
				let objectPath:string = obj['_'];
				let hasObjectPath:boolean = objectPath != null;

				if (hasObjectPath) {
					this.contentBlockRegistry[objectPath] = new ContentBlockRegistryValue({
						className: className,
						path: objectPath,
						route: route
					});
				} /*else if(!hasObjectPath{

				}*/
			}
		};

		let keys:Array<string> = Object.keys(paths);
		for (let i:number = 0; i < keys.length; i++) {
			let key:string = keys[i];
			if (typeof paths[key] == "string") {
				continue;
			}
			let object:{ _:string, haw:'', en:'' } = paths[key] as { _:string, haw:'', en:'' };
			if (object instanceof Function) {
				continue;
			}
			let objectPath:string = object._;

			this.contentBlockRegistry[objectPath] = new ContentBlockRegistryValue({
				className: className,
				path: objectPath,
				route: route
			});
		}
	}


	public get$(contentBlockGuid:string, params?:any):Observable<ContentBlock> {

		let subject$:Subject<ContentBlock> = new Subject();
		let cachedContentBlock:ContentBlock = this.contentBlockCache[contentBlockGuid];
		if (cachedContentBlock) {
			console.log("using cache..." + JSON.stringify(cachedContentBlock, null, 2));
			subject$.next(cachedContentBlock);
		} else {
			this.repo.watch$(contentBlockGuid).subscribe((contentBlock:ContentBlock) => {
				if (contentBlock == null) {
					subject$.next(null);
					return;
				}
				this.contentBlockCache[contentBlockGuid] = contentBlock;
				console.log("got " + JSON.stringify(contentBlock, null, 2));
				subject$.next(contentBlock);
			});
		}
		return subject$;
	}

	/** Simple local/remote grabbing (no content blocks) with mustache replacement as needed */
	public getWithSomeProcessing(path:string, params?:any):string {
		let content:Function | string = this.get(path, this.language, params);
		if (typeof content == "function") {
			//Parse with mustache
			let template = this.getTemplate(path);
			if (template == null) {
				template = this.getTemplate(path, SupportedLanguage.en); //fallback...to english
			}
			if (params == null && template != null && template.parameters != null) {
				//if this gets hit we're probably running under LocalizationService's localize method without params (in constructor of component)..just return the template
				params = template.parameters;
			}
			if (params != null) {
				this.setAllTemplateParameters(path, params);
			}

			if (params != null) {
				let result:string = Mustache.render(template.template, params); //call the 0 args function
				content = result;
			} else {
				content = template.template; //params is null just use the template
			}

		}
		return content;

	}

	public simpleGet(pathObjectOrString:(any | string), params?:any):Subject<string> {
		let alternateNoCache:BehaviorSubject<string> = new BehaviorSubject("");
		let path:string = this.getPath(pathObjectOrString);
		let finalContent:string = "";
		let self = this;
		if (this.contentBlockMode) {
			this.contentBlockSubscriptions.push(this.get$(path).subscribe(contentBlock => {
				if (contentBlock == null) {
					let content:string = this.getWithSomeProcessing(path, params);
					this.active[path].$.next(content); //cachedVersion
					alternateNoCache.next(content);
				} else {
					//TODO: Possible it updates after the contentBlock
					let exists:boolean = contentBlock.content[self.language] != null;
					let textContent:string = "";
					if (exists) {
						textContent = contentBlock.content[self.language].content
					} else {
						textContent = contentBlock.content[SupportedLanguage.en].content;
					}
					//TODO...this wont work with mustache templates
					console.log("Got " + textContent + "!");
					self.active[path].$.next(textContent); //cachedVersion
					alternateNoCache.next(textContent); //no cache
				}

			}));
		} else {
			let content:string = this.getWithSomeProcessing(path, params);
			this.active[path].$.next(content);//cached version
			alternateNoCache.next(content); //no cache

		}
		return alternateNoCache;
	}

	public setAllTemplateParameters(path:string, parameters:{}):void {
		let supportedLanguages:Array<string> = Object.keys(SupportedLanguage);

		for (let i:number = 0; i < supportedLanguages.length; i++) {
			let languageKey:string = supportedLanguages[i];
			let language:SupportedLanguage = SupportedLanguage[languageKey];

			let template:TemplateData = this.getTemplate(path, language);
			template.parameters = parameters; //they all get the same object REFERENCE! (change once, change everywhere!)
		}
	}

	public fetch$(pathObjectOrString:(any | string), params?:{}):Subject<string | Function | SafeHtml> {
		let path:string = this.getPath(pathObjectOrString);
		if (params == null) {
			let template:TemplateData = this.getTemplate(pathObjectOrString);
			if (template != null && template.parameters != null) {
				params = template.parameters;
			}
		}
		let alternateNoCacheVersion:Subject<string> = this.simpleGet(path, params);
		if(params && params['no-cache'] == true && params['trusted-html'] == true) {
			let trustedHtmlVersion:Subject<SafeHtml> = alternateNoCacheVersion.pipe(
				map(stringThing => {
					return this.domSanitizer.bypassSecurityTrustHtml(stringThing);
				})
			) as Subject<SafeHtml>;
			return trustedHtmlVersion;
		}
		else if(params && params['no-cache'] == true) {
			return alternateNoCacheVersion;
		}
		return this.active[path].$;
	}

	public getPathObject(path:string) {
		let localization:Localization = this.textContent.local;
		if (this.textContent.remote.isReady) {
			localization = this.textContent.remote;
		}
		return localization[this.language][path];
	}

	public getPath(pathObjectOrString:any | string):string {
		let path:string = pathObjectOrString;
		if (typeof pathObjectOrString == "object") {
			path = pathObjectOrString["_"];
		}
		return path;
	}

	/**
	 * Takes "organizations.edit.toast.saved.body", "haw"(optional) and returns "Ua mālama ʻia ka hui."
	 * @param {any|string} pathObjectOrString  (the path to the localization message)
	 * @param {string} language (optional) see and please use LocalizationService.SupportedLanguages ("en", "haw");
	 *                 Language if not provided will use the current language.
	 * @param {any}    params If path is a function then the params to pass to the function
	 * @returns {string}  Returns the text for the translation in the requested language (if provided) or the current language (if not provided)
	 */
	public get(pathObjectOrString:any | string, language:string = null, params:any = {}):string {
		let path:string = pathObjectOrString;
		if (typeof pathObjectOrString == "object") {
			path = pathObjectOrString["_"];
		}

		language = (language != null) ? language : this.language;

		let localization:Localization = this.textContent.local;
		if (this.textContent.remote.isReady) {
			localization = this.textContent.remote;
		}

		let value:any = localization.localizations[language][path];
		//possible from remote
		if (value == null) {
			let newValue = this.textContent.local.localizations[language][path];
			if (newValue != null) {
				//The language exists locally
				value = newValue;
			} else {
				//The language did not exist locally, use english because hawaiian is not available.
				let englishVersionValue:string = this.textContent.local.localizations[SupportedLanguage.en][path];
				if (englishVersionValue != null) {
					value = englishVersionValue;
				}
			}
		}

		if (value === undefined || value == null || value === "") {
			value = localization.localizations[SupportedLanguage.en][path];
		}
		if (typeof value == "function") {
			value = value; //TODO: now we apply property to it at runtime (value as Function)(params);
		}
		return value;
	}

	public getTemplate(pathObjectOrString:any | string, language:string = null):TemplateData {
		let path:string = this.getPath(pathObjectOrString);
		let localization:Localization = this.textContent.local;
		if (this.textContent.remote.isReady) {
			localization = this.textContent.remote;
		}
		//TODO: Content Blocks!

		if (language == null) {
			language = this.language;
		}
		let templateContent:TemplateData = localization.templates[language][path];
		if (templateContent == null && localization.templates[SupportedLanguage.en][path] != null) {
			templateContent = localization.templates[SupportedLanguage.en][path];
		}

		return templateContent;
	}

	public importNewKeys() {
		this.logger.info("Attempting translation data upload");
		let localization:Localization = this.textContent.local;

		const localizations = localization.localizations;
		this.logger.info(localizations);
		let contentBlocks:Array<ContentBlock> = this.localizationsToContentBlocks(localizations);

		this.repo.list$().subscribe((databaseContent:Array<ContentBlock>) => {
			contentBlocks.forEach((contentBlock) => {
				if (!databaseContent.some((databaseBlock) => {
					return databaseBlock.guid === contentBlock.guid
				})) {
					this.logger.info(`New content block: ${JSON.stringify(contentBlock)}`);
					this.repo.save$(contentBlock);
				}
			});
		});
	}

	public getAllFromRepository$():Observable<ContentBlock[]> {
		return this.repo.list$()
	}

	public getMissingFromRepository$(language:string):Observable<ContentBlock[]> {
		return this.repo.query$(this.repo.byMissingData(language));
	}

	public getOutdatedFromRepository$(language:string):Observable<ContentBlock[]> {
		return this.repo.query$(this.repo.byOutdated(language));
	}

	public getSingleContentBlockFromRepository$(guid:string):Observable<ContentBlock> {
		return this.repo.get$(guid);
	}

	public save$(contentBlock:ContentBlock):Observable<ContentBlock> {
		return this.repo.save$(contentBlock);
	}

	/**
	 * Upload localization data to files on Google Cloud Storage
	 * @returns {Observable<File[]>}
	 */
	public publishDatabaseDataToLocalizationFiles():Observable<File[]> {
		this.logger.info("calling saveLocalizationFiles firebase function");
		let callableFunction = this.angularFireFunctions.httpsCallable('saveLocalizationFiles');
		let response:ReplaySubject<File[]> = new ReplaySubject<File[]>();
		let data:any = "";

		callableFunction(data).subscribe((result) => {
			if (result) {
				response.next(result);
				this.logger.info("localization files saved");
			}
		});
		return response;
	}

	/**
	 * Load localization data from files on Google Cloud Storage to the client
	 * @returns {Observable<Localization>}
	 */
	public loadLocalizationFiles$():Observable<Localization> {
		let translationDataObservable:Subject<Localization> = new BehaviorSubject<Localization>(null);
		let remoteTranslationData:Localization = new Localization(true);
		const getFileContentsObservable$:Subject<{ language:string, contents:string, observable:Subject<any> }> = new Subject<{ language:string, contents:string, observable:Subject<any> }>();
		const filesProcessedObservable$:Subject<boolean>[] = [];

		this.logger.info('Getting file refs');
		const supportedLanguages = Object.keys(SupportedLanguage);
		// download each file and get its contents
		for (let lang of supportedLanguages) {
			const filename = `localizations/waihona_${lang}.properties`;
			const fileURL:string = this.urlService.localizations.file(lang);
			const fileProcessedObservable$:Subject<boolean> = new BehaviorSubject<boolean>(null);
			filesProcessedObservable$.push(fileProcessedObservable$);
			this.logger.info('Downloading file:' + filename);

			fetch(fileURL, {
				mode: 'cors',
				cache: 'no-cache'
			}).then((response:Response) => {
				response.text().then((text:string) => {
					const file:{ language:string, contents:string, observable:Subject<any> } = {
						language: "",
						contents: "",
						observable: new Subject<any>()
					};
					file.language = lang;
					file.contents = text;
					file.observable = fileProcessedObservable$;
					getFileContentsObservable$.next(file);
				});
			}).catch((getFileError) => {
				console.error('ERROR getting file:', getFileError);
				translationDataObservable.error(getFileError);
			});
		}

		// when a file's contents are received, process them
		getFileContentsObservable$.subscribe((file) => {
			if (file) {
				this.logger.info(`Processing ${file.language} file`);
				const contentsSplitByLine:string[] = file.contents.split("\n");
				const contentsSplitKeyValue:Array<string[]> = contentsSplitByLine.map((line:string) => {
					//We could split at = but the right side of the = could also have more ='s so we need to split on the first occurance of =
					let firstOccurence:number = line.indexOf("=");
					let propertyNameAndValue = [line.slice(0, firstOccurence), line.slice(firstOccurence + 1)];
					return propertyNameAndValue;
				});
				// write to remoteTranslationData
				contentsSplitKeyValue.forEach((keyValuePair) => {
					let key:string = keyValuePair[0];
					let value:string = keyValuePair[1];
					let decodedValue:string = this.decodeLine(value);

					let content:Function | string = this.get(key, this.language);

					function curryChicken(value:string) { //curry the function so that the value is applied
						return function () { //we want a function so we know its mustaches
							return `${value}`;
						}
					};
					if (typeof content == "function") {
						remoteTranslationData.localizations[file.language][key] = curryChicken(decodedValue);

					} else {
						remoteTranslationData.localizations[file.language][key] = decodedValue;
					}
				});
				file.observable.complete();
			}
		});

		// when all files have been processed, load remote translation data
		forkJoin(filesProcessedObservable$).subscribe(() => {
			// Load remote translation data
			this.textContent.remote = remoteTranslationData;
			this.logger.info("remote localization files loaded");
			// this.logger.info(JSON.stringify(this.textContent.remote.localizations, undefined, 2));

			translationDataObservable.next(remoteTranslationData);
		});

		return translationDataObservable;
	}

	public encodeLine(source:string):string {
		return source.replace(/(\\)/gm, "\\\\")
			.replace(/(\t)/gm, "\\t")
			.replace(/(\f)/gm, "\\f")
			.replace(/(\n)/gm, "\\n")
			.replace(/(\r)/gm, "\\r");
	}

	public decodeLine(source:string):string {
		return source.replace(/(\\\\)/gm, "\\")
			.replace(/(\\t)/gm, "\t")
			.replace(/(\\f)/gm, "\f")
			.replace(/(\\n)/gm, "\n")
			.replace(/(\\r)/gm, "\r");
	}

	private localizationsToContentBlocks(localizations:any) {
		let contentBlocks:Array<ContentBlock> = [];

		// for each key in object
		for (const lang in localizations) {
			// check if the property/key is defined in the object itself, not in parent
			if (localizations.hasOwnProperty(lang)) {

				const languageObj = localizations[lang];
				for (const translationID in languageObj) {
					if (languageObj.hasOwnProperty(translationID)) {
						// key, value
						let content = localizations[lang][translationID];
						if (content !== undefined) {
							const newContentBlock = new ContentBlock();
							newContentBlock.imported = new Date();

							if (typeof content === "function") {
								content = content();//get the template source rather than the result
								newContentBlock.contentType = ContentBlockContentType.mustache;//TODO: read from @type
							}

							const newContentBlockTranslation = new ContentBlockTranslation(SupportedLanguage[lang], content);
							let existingContentBlock = contentBlocks.find((contentBlock) => contentBlock.guid === translationID);
							// if there's already a contentBlock for this translationID
							if (existingContentBlock) {
								// just add the new language entry (ContentBlockTranslation) to the existing contentBlock
								existingContentBlock.content[lang] = newContentBlockTranslation;
							} else {
								// otherwise add a brand new content block
								newContentBlock.content[lang] = newContentBlockTranslation;
								newContentBlock.guid = translationID;
								contentBlocks.push(newContentBlock);
							}
						}
					}
				}

			}
		}
		return contentBlocks;
	}

	private percentDiacriticals(str) {
		let count:number = 0, len = str.length;
		for (let i = 0; i < len; i++) {
			if (/[āēīōūĀĒĪŌŪʻ]/.test(str.charAt(i))) {
				count++;
			}
		}
		let percent:number = (count / len) * 100;
		// console.info(`HasDiacriticals Validator: there are ${count} diacriticals in the string, or ${percent}% of the string!`);
		return percent;
	}

	public hasDiacriticals(string:string):boolean {
		return (this.percentDiacriticals(string) >= 5);
	}

	public isValidLanguageInput(string:string, language:SupportedLanguage):boolean {
		if (language == SupportedLanguage.haw) {
			return this.hasDiacriticals(string);
		} else if (language == SupportedLanguage.en) {
			return !this.hasDiacriticals(string);
		}
	}

	// Broadcast new warnings
	public updateWarnings(warnings:ValidationError[]) {
		this.warnings.next(warnings);
	}


	public deleteContentBlock(contentBlock:ContentBlock):void {
		this.repo.delete$(contentBlock);
	}
}
