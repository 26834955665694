import {Component, OnInit, ViewChild} from '@angular/core';
import {IModal} from "../IModal";
import {BsModalRef} from "ngx-bootstrap/modal";
import {IWaihonaUserAffiliation} from "../../../domain/IWaihonaUserAffiliation";
import {LocalizationService} from "../../../services/LocalizationService";
import {Router} from "@angular/router";
import {AuthService} from "../../../services/AuthService";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";

@Component({
	selector: 'get-started-modal',
	templateUrl: './get-started-modal.component.html',
	styleUrls: ['./get-started-modal.component.scss']
})
export class GetStartedModalComponent implements OnInit, IModal {

	private cbContext = Localization.template.modal.home.get_started;
	public CB = {
		title: this.cbContext.title,
		first: this.cbContext.first,
		listZero: this.cbContext.list.zero,
		listOne: this.cbContext.list.one,
		listTwo: this.cbContext.list.two,
		listThree: this.cbContext.list.three,
		footer: this.cbContext.footer,
		error: this.cbContext.error,
		continue: this.cbContext.continue,
	};

	public component:any;
	public content:any;

	@ViewChild('getStartedModal') getStartedModal:BsModalRef;

	constructor(private bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				private authService:AuthService,
				private router:Router,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("GetStartedModalComponent", this.CB);
	}

	ngOnInit() {
	}

	public onProperties(properties:object):void {
		this.content = properties as { identity:IWaihonaUserAffiliation };
	}

	public onClick() {
		this.logger.info(`user clicked on button`);
		this.bsModalRef.hide();
		this.router.navigate(['/']);
		setTimeout(()=> {
			this.authService.login();
		}, 1300);
	}

}
