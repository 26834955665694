import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {AngularFirestore} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {SiteConfiguration} from "../../domain/config/SiteConfiguration";
import {Observable, Subject} from "rxjs";
import {NGXLogger} from "ngx-logger";

@Injectable({
	providedIn: 'root',
} as any)
export class SiteConfigurationRepository extends AbstractFirestoreRepository<SiteConfiguration> {

	constructor(protected db:AngularFirestore, protected logger:NGXLogger) {
		super(SiteConfiguration, db, db.collection("Config"));
	}
	public get$():Observable<SiteConfiguration> {
		return super.get$("site-config");
	}
	public watch$():Observable<SiteConfiguration> {
		let siteConfigObservable$:Subject<SiteConfiguration> = new Subject<SiteConfiguration>();
		super.watch$("site-config").subscribe((result:SiteConfiguration) => {
			siteConfigObservable$.next(result);
		});
		return siteConfigObservable$;
	}
	public createConfiguration$(siteConfig:SiteConfiguration = new SiteConfiguration()):Observable<SiteConfiguration> {
		if(siteConfig == null) {
			siteConfig = new SiteConfiguration();
		} else {
			//For now the guid for the type of site config canʻt be anything other than "site-config"
			siteConfig.guid = "site-config";
		}
		return super.save$(siteConfig);
	}
}
