import {Injectable, Injector} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {BehaviorSubject, Observable} from 'rxjs/Rx';
import {LearningAssetUpload, UploadStatus} from './objects/LearningAssetUpload';

import {ValidationException} from './exceptions/ValidationException';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from "app/services/AuthService";
import {ReviewService} from "./ReviewService";
import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {Review} from "../domain/resources/review/Review";
import {ActivityStream, ActivityType} from "../domain/resources/ActivityStream";
import {ResourceChatService} from "./ResourceChatService";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../domain/user/WaihonaUserOrganization";
import {UrlService} from "./UrlService";
import {UploadService} from "./UploadService";
import {DraftResourceRepository} from "./repository/DraftResourceRepository";
import {NGXLogger} from "ngx-logger";
import {FanContributionService} from "./FanContributionService";
import {FanContribution} from "../domain/resources/FanContribution";
import {LearningAsset} from "../domain/resources/LearningAsset";
import {PathService} from "./PathService";
import {ResourceFunctions} from "./functions/ResourceFunctions";
import {ReplaySubject, Subject} from "rxjs";
import {ValidationErrorService} from "./common/ValidationErrorService";
import {SupportedLanguage} from "../domain/SupportedLanguages";
import {NotificationService, ToastType} from "./common/NotificationService";
import {Router} from "@angular/router";
import {Localization} from "../data/Localization";
import {ActivityStreamService} from "./ActivityStreamService";


class NeedToSaveException extends Error {
}

@Injectable({
	providedIn: 'root',

} as any)
/** Represents the user's role in the lesson submission */
export class ResourceSubmissionService {

	/**
	 * userInfo: stores user information locally
	 * updated by subscription to authService.currentUser() in constructor
	 */
	public files$:BehaviorSubject<File[]> = new BehaviorSubject([]);
	public files:Array<File> = [];
	public tasks:Array<AngularFireUploadTask> = [];
	public tasks$:BehaviorSubject<AngularFireUploadTask[]>;
	public uploads$:BehaviorSubject<LearningAssetUpload[]>;
	public completedUploads:BehaviorSubject<LearningAssetUpload> = new BehaviorSubject<LearningAssetUpload>(null);
	private copyInstructions:Array<{ source:string, destination:string }> = [];

	private _reviewService:ReviewService;
	private _resourceChatService:ResourceChatService;


	private activityStreamActions = {
		logSaved: (activityStream:ActivityStream, resourceSubmission:ResourceSubmission) =>
			activityStream.addEntry(ActivityType.SAVED, "", resourceSubmission.version).with(resourceSubmission.submitter),

		logSubmitted: (activityStream:ActivityStream, resourceSubmission:ResourceSubmission, optionalDetail?:string, optionalComments?:string) => {
			activityStream.addEntry(ActivityType.SUBMITTED, "", resourceSubmission.version).with(resourceSubmission.submitter);
			this.addCommentToSubmission(activityStream, resourceSubmission, optionalComments); //send chat (before save)
		},
	}

	constructor(private authService:AuthService,
	            private storage:AngularFireStorage,
	            private db:AngularFirestore,
	            private injector:Injector,
	            private urlService:UrlService,
	            private uploadService:UploadService,
	            private draftResourceRepository:DraftResourceRepository,
	            protected logger:NGXLogger,
	            private fanContributionService:FanContributionService,
	            private activityStreamService:ActivityStreamService,
	            private functions:ResourceFunctions,
	            private pathService:PathService,
	            private notificationService:NotificationService,
	            private router:Router,
                private validationErrorService:ValidationErrorService) {

		this.tasks$ = new BehaviorSubject<AngularFireUploadTask[]>(this.tasks);
		this.uploads$ = new BehaviorSubject<LearningAssetUpload[]>([]);
	}

	private get reviewService():ReviewService {
		if (!this._reviewService) {
			this._reviewService = this.injector.get<ReviewService>(ReviewService);
		}
		return this._reviewService;
	}

	private get resourceChatService():ResourceChatService {
		if (!this._resourceChatService) {
			this._resourceChatService = this.injector.get<ResourceChatService>(ResourceChatService);
		}
		return this._resourceChatService;
	}

	/** Returns true if the user can edit the review now */
	public isEditableBySubmitterNow(resourceSubmission:ResourceSubmission):boolean {

		let isInEditableState:boolean = resourceSubmission.status == ResourceSubmissionStatus.draft ||
			resourceSubmission.status == ResourceSubmissionStatus.submitted ||
			resourceSubmission.status == ResourceSubmissionStatus.in_review_revision;

		if (!isInEditableState) {
			this.logger.error(`Not in an editable state.  ${resourceSubmission.status}`)
		}

		return isInEditableState;
	}

	/** Get a lesson by a user id */
	public getResourceSubmission$(guid:string):Observable<ResourceSubmission> {
		return this.draftResourceRepository.get$(guid);
	}

	/** Get any fan contributions for this resource */
	public getFanContributions$(resourceGuid:string):Observable<FanContribution[]> {
		return this.fanContributionService.listByResourceId$(resourceGuid);
	}

	/** pass to other service */
	public acceptFanContribution(fanContribution:FanContribution, resourceSubmission:ResourceSubmission, resourceLanguage:SupportedLanguage):void {
		console.info(`ResourceSubmissionService is calling FanContributionService to accept a contribution`);
		this.fanContributionService.acceptFanContribution(fanContribution, resourceSubmission);

		//push the updated files into the assets array on resource submission
		resourceSubmission.files.push(...fanContribution.files); //acceptFanContribution needs to happen first!

		//add attribution to the sources box
		let arrayOfFilenames:Array<string> = [];
		for (let i:number = 0; i < fanContribution.files.length; i++) {
			let file:LearningAsset = fanContribution.files[i];
			arrayOfFilenames.push(file.fileName);
		}

		let wordChoice:string;
		let prettyFilenames:string = (arrayOfFilenames.length == 1) ? arrayOfFilenames.toString() : arrayOfFilenames.join(", ");

		if (resourceLanguage === "en") {
			wordChoice = (arrayOfFilenames.length == 1) ? "file was" : "files were";
			resourceSubmission.documentTextContent['en'].notes = resourceSubmission.documentTextContent.en.notes + `\nThe following ${wordChoice} contributed to this resource by ${fanContribution.getName()}: ${prettyFilenames}`;
		} else if (resourceLanguage === "haw") {
			wordChoice = (arrayOfFilenames.length == 1) ? "faila" : "mau faila";
			resourceSubmission.documentTextContent['haw'].notes = resourceSubmission.documentTextContent.haw.notes + `\nNa ${fanContribution.getName()} i hoʻokaʻa mai i kēia ${wordChoice}: ${prettyFilenames}`;
		}

		console.info(`updated the resourceSubmission Notes with attribution for this contribution`);

		//update the activity stream
		this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream) => {
			activityStream.addEntry(ActivityType.FAN_CONTRIBUTION, `A fan contribution from ${fanContribution.getName()} with ${fanContribution.files.length} file(s) was accepted into this resource: ${prettyFilenames}`, resourceSubmission.version);
			this.activityStreamService.updateActivityStream$(activityStream).subscribe(
				() => console.info(`updated the activity stream`)
			);
		});

		//save the draft resource with new files!
		console.info(`saving the updated resource draft`);
		this.draftResourceRepository.save$(resourceSubmission);
	}

	/** pass to other service */
	public declineFanContribution(fanContribution:FanContribution, resourceSubmission:ResourceSubmission):void {
		//update the activity stream
		this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream) => {
			activityStream.addEntry(ActivityType.FAN_CONTRIBUTION, `A fan contribution from ${fanContribution.getName()} with ${fanContribution.files.length} file(s) was declined from this resource`, resourceSubmission.version);
			this.activityStreamService.updateActivityStream$(activityStream);
		});


		console.info(`ResourceSubmissionService is calling FanContributionService to decline a contribution`);
		this.fanContributionService.declineFanContribution(fanContribution);
	}

	/** Get all User Created Resources */
	public getResourceSubmissions$():Observable<ResourceSubmission[]> {
		return this.draftResourceRepository.query$(this.draftResourceRepository.byTitleAsc());
	}

	/** Get all User Created Resources */
	public getAllResourcesBySubmitterId$(guid:string):Observable<ResourceSubmission[]> {
		return this.draftResourceRepository.query$(this.draftResourceRepository.bySubmitterGuid(guid));
	}

	public getAllResourcesByCollaboratorId$(guid:string):Observable<ResourceSubmission[]> {
		return this.draftResourceRepository.query$(this.draftResourceRepository.byCollaboratorGuid(guid));
	}

	public recallResourceSubmission(resourceSubmission:ResourceSubmission):void {
		if (this.isEditableBySubmitterNow(resourceSubmission)) {
			return;
		}

		this.reviewService.getReview$(resourceSubmission.guid).subscribe(review => {
			if (review != null) {
				this.reviewService.recallSubmission(review);

			}
		});
	}

	/**
	 *
	 * @param {ResourceSubmission} resourceSubmission
	 * @throws {ValidationException} ValidationException
	 */
	public save(resourceSubmission:ResourceSubmission):Observable<ResourceSubmission> {
		let resourceSavedObservable:Subject<ResourceSubmission> = new Subject<ResourceSubmission>();
		let firstTimeSave = resourceSubmission.version.isNew;

		resourceSubmission.version.nextTertiary();
		resourceSubmission.modified = new Date().getTime();
		this.logger.info("saving version number " + resourceSubmission.version.toString());
		this.saveTo(resourceSubmission).subscribe((resourceSub:ResourceSubmission) => {
			if (firstTimeSave) {
				//create a new activity stream and initial entry
				this.activityStreamService.logToActivityStream$(null, resourceSubmission, this.activityStreamActions.logSaved).take(1).subscribe(() => {
					resourceSavedObservable.next(resourceSub);
				});
			} else {
				this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream:ActivityStream) => {
					this.activityStreamService.logToActivityStream$(activityStream, resourceSubmission, this.activityStreamActions.logSaved).take(1).subscribe(() => {
							resourceSavedObservable.next(resourceSub);
						});
				});
			}
		});

		return resourceSavedObservable;
	}

	public saveTo(resourceSubmission:ResourceSubmission):Observable<ResourceSubmission> {
		return this.draftResourceRepository.save$(resourceSubmission);
	}

	/** Warning! This is a destructive method! Deletes the draft instance of a resource from the database as well as any associated files in storage.*/
	public delete(resourceSubmission:ResourceSubmission):void {
		console.warn(`deleting draft resource ${resourceSubmission.guid}`);
		this.draftResourceRepository.delete$(resourceSubmission);
	}

	/**
	 * Submit the lesson
	 * @param {ResourceSubmission} resourceSubmission
	 */
	public submit(resourceSubmission:ResourceSubmission, completeHandler$?:Subject<boolean>,  optionalComments?:string):Review {
		let review:Review;
		/*
		User submits a draft for review
			- Draft is locked for editing
			- A link is created saying that a review is pending
			- A review is created in /Reviews
		 */
		let submitter:WaihonaUser = this.authService.currentUser;
		let waihonaUserOrgThatMatchesTheSubmission:WaihonaUserOrganization = submitter.organizations.find(waihonaUserOrganization => {
			return waihonaUserOrganization.organizationGuid == resourceSubmission.organization.guid;
		});


		if (resourceSubmission.guid == null) {
			//todo: UI needs to handle this error message ... update: do we still need this?
			this.logger.error("Need to Save!");
			throw new NeedToSaveException("Need to Save!");
		} else {
			this.logger.info("Found a guid for " + resourceSubmission + ": " + resourceSubmission.guid + ". Creating new review submission.");
			resourceSubmission.status = ResourceSubmissionStatus.submitted;
			resourceSubmission.modified = new Date().getTime();
			resourceSubmission.version.nextMinor();

			this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream:ActivityStream) => {
				this.activityStreamService.logToActivityStream$(activityStream, resourceSubmission, this.activityStreamActions.logSubmitted, optionalComments).take(1).subscribe(() => {
					this.logger.info("submit logged to activity stream!");

					//send to reviewService
					review = this.reviewService.receiveSubmission(resourceSubmission);

					//update the draft with status, date, and version #
					this.save(resourceSubmission).take(1).subscribe((resourceSubmission:ResourceSubmission) => {
						if(completeHandler$) {
							completeHandler$.next(true);
						}
					});
				});
			});
		}

		return review;
	}

	/** Submits a revision to a previously-submitted resource */
	public submitRevision(review:Review, resourceSubmission:ResourceSubmission, optionalComments?:string) {
		resourceSubmission.status = ResourceSubmissionStatus.in_review_continuation;
		resourceSubmission.modified = new Date().getTime();
		resourceSubmission.version.nextMinor();

		this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream:ActivityStream) => {
			this.activityStreamService.logToActivityStream$(activityStream, resourceSubmission, this.activityStreamActions.logSubmitted, optionalComments).take(1).subscribe(() => {
				this.logger.info("submit logged to activity stream!");

				//Update the resourceSubmission within the review
				review.resourceSubmission = resourceSubmission;

				this.save(resourceSubmission); //Save it in drafts

				//Save and update the Review which the kaʻi will look at
				this.reviewService.receiveSubmissionRevision(review);
			});

		});
	}

	/** THis is the newer version up uploadFile instead using a LearningAssetUpload */
	public upload(resourceSubmission:ResourceSubmission, upload:LearningAssetUpload, uploadPath:string):void {
		if (!resourceSubmission.guid) {
			this.save(resourceSubmission); //Save it in drafts to get a guid before continuing
		}
		//TODO: We need to ensure the uploadPath is reasonable..so they cant upload anywhere
		const filePath:string = `${uploadPath}/${upload.name}`;
		const task:AngularFireUploadTask = this.uploadService.uploadResourceAsset(filePath, upload.file, resourceSubmission);
		upload.track(task);
		this.tasks.push(task);
		this.tasks$.next(this.tasks);
	}

	public uploadAll(resourceSubmission:ResourceSubmission, uploads:LearningAssetUpload[], uploadPath:string) {
		console.info(`ResourceSubmissionService is uploading ${uploads.length} files to ${uploadPath}`);
		for (let i = 0; i < uploads.length; i++) {
			const upload:LearningAssetUpload = uploads[i];
			console.info("listening for status changes");

			upload.status$.subscribe((newStatus:UploadStatus) => {
				console.info("upload status changed to " + newStatus);
				if (newStatus == UploadStatus.success || newStatus == UploadStatus.failure) {
					this.completedUploads.next(upload);
				}
			});

			this.upload(resourceSubmission, upload, uploadPath);
		}
	}

	public renameLearningAsset(resourceSubmission:ResourceSubmission, learningAsset:LearningAsset, newFileName:string):Observable<void> {
		console.info(`resoureSubmissionService::renameLearningAsset has been called`);

		let replaySubject:ReplaySubject<void> = new ReplaySubject<void>();

		//create the copy instructions first!
		let copyInstruction = {
			source: learningAsset.fileInfo.path,
			destination: this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, newFileName)
		};
		this.copyInstructions.push(copyInstruction);

		//add an activity stream entry
		this.activityStreamService.getActivityStream$(resourceSubmission.guid).subscribe((activityStream) => {
			activityStream.addEntry(ActivityType.RENAMED, `A learning asset was renamed from ${learningAsset.fileName} to ${newFileName}`, resourceSubmission.version);
			this.activityStreamService.updateActivityStream$(activityStream);
		});

		//change the filename and update the file path
		learningAsset.fileName = newFileName;
		learningAsset.fileInfo.name = newFileName;
		learningAsset.fileInfo.path = this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, newFileName);
		learningAsset.hasConflictWith = "";

		try {
			this.save(resourceSubmission);
			this.functions.renameFanContributionFile(this.copyInstructions).catch((err, observable:Observable<any>):any => {
				replaySubject.next(null);
			}).subscribe(crap => {
				replaySubject.next(null);
			});
		} catch (error) {
			console.error(error);
			throw (error);
		}
		return replaySubject;
	}

	private addCommentToSubmission(activityStream:ActivityStream, resourceSubmission:ResourceSubmission, optionalComment?:string):void {
		if (optionalComment != null && optionalComment != "" && resourceSubmission != null && resourceSubmission.guid != null) {
			activityStream.addEntry(ActivityType.USER_COMMENT, optionalComment, resourceSubmission.version);
			this.activityStreamService.updateActivityStream$(activityStream);
			this.resourceChatService.sendMessageToChat(resourceSubmission.guid, optionalComment);
		}
	}

	public filterByStatus(resourceSubmissions:Array<ResourceSubmission>, status:ResourceSubmissionStatus):Array<ResourceSubmission> {
		return resourceSubmissions.filter(resourceSubmission => {
			return resourceSubmission.status == status;
		});
	}

	public updateCover(resourceGuid:string, imageExists:boolean):Observable<ResourceSubmission> {
		this.logger.info(`update partial on resource guid: ${resourceGuid}, imageExists: ${imageExists}`);
		return this.draftResourceRepository.updatePartial$({guid: resourceGuid, hasImage: imageExists});
	}




}
