import {RegisterClazz, RegisterType} from "../RegisterType";
import {Type} from "class-transformer";
import {RefLocalization} from "../ITextContentObject";
import {CollectionConfiguration} from "./CollectionConfiguration";
import {CollectionOwner} from "./CollectionOwner";


@RegisterClazz(CollectionRef)
export class CollectionRef {

	public guid:string = null

	public localization:RefLocalization = {
		title: {
			en: "",
			haw: ""
		},
		description: {
			en: "",
			haw: ""
		}
	};

	@Type(() => CollectionConfiguration)
	@RegisterType({clazz: CollectionConfiguration})
	public configuration:CollectionConfiguration = new CollectionConfiguration();

	// Who the owner is, whether a user or an organization
	@Type(() => CollectionOwner)
	@RegisterType({clazz: CollectionOwner})
	public owner:CollectionOwner = null;


	public static create(guid:string, owner:CollectionOwner, configuration:CollectionConfiguration, localization:RefLocalization) {
		let collectionRef:CollectionRef = new CollectionRef();
			collectionRef.guid = guid;
			collectionRef.owner = owner;
			collectionRef.configuration = configuration;
			collectionRef.localization = localization;
		return collectionRef;
	}

}
