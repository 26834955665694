import {Role} from "./Role";
import {Type} from "class-transformer";
import {RoleDefinition} from "./RoleDefinition";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(Permission)
export class Permission {
	public key:string = "";
	public description:string = "";

	@Type(() => Role)
	@RegisterType({clazz: RoleDefinition, isArray: true})
	public allowedRoles:Array<RoleDefinition> = [];

	constructor(key:string | PermissionType, description:string, allowedRoles:Array<RoleDefinition>) {
		if(key != null) {
			this.key = key;
		}
		if(description != null) {
			this.description = description;
		}
		if(allowedRoles != null && allowedRoles.length > 0) {
			this.allowedRoles = allowedRoles;
		}
	}

	public keyMatches(find:string) {
		return this.key == find;
	}
}

export enum PermissionType {
	"access_datastore"      	= "access_datastore",
	"can_provision"             = "can_provision",
	"can_unpublish"             = "can_unpublish", //Can unpublish a resource
	"content_owner" 			= "content-owner",
	"create_badge"     			= "create-badge",
	"create_collection"     	= "create-collection",
	"create_framework"     		= "create-framework",
	"create_invitation_code" 	= "create-invitation-code",
	"create_organization"  		= "create-organization",
	"create_resource_submission"= "create-resource-submission",
	"delete_published_resource" = "delete-published-resource",
	"edit_badge"     			= "edit-badge",
	"edit_collection"     		= "edit-collection",
	"edit_event_mappings"       = "edit_event_mappings",
	"edit_framework"     		= "edit-framework",
	"edit_specifications"       = "edit_specifications",
	"edit_localization"         = "edit-localization",
	"edit_organization"  		= "edit-organization",
	"edit_org_config"           = "edit_org_config",
	"edit_resource"             = "edit_resource",
	"review_count"     			= "review-count",
	"review_submission"     	= "review-submission",
	"see_ability_to_review"     = "see-ability-to-review",
	"site_access_while_shutdown"= "site-access-while-shutdown",
	"suggest_organization" 		= "suggest-organization",
	"unlock_resources"          = "unlock_resources",
	"view_admin_dashboard"     	= "view-admin-dashboard",
	"view_hidden_frameworks"    = "view-hidden-frameworks",
	"view_analytics"            = "view-analytics",
	"view_collection"           = "view-collection",
}
