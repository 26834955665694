import {RegisterClazz} from "../RegisterType";
import {WaihonaUserRef} from "../user/WaihonaUserRef";


@RegisterClazz(ComposeMessageData)
export class ComposeMessageData {
	public to: Array<WaihonaUserRef> = [];
	// public cc: Array<WaihonaUserRef> = null; //todo: enable this someday...
	public subject:string = "";
	public body: string = "";
	public redirectParams:Array<string> = [];

}
