<div *ngIf="badge" class="animated fadeIn">
	<h1 class="display-5 text-primary font-weight-bold my-4">{{CB.header | localize | async}}</h1>

	<div *ngIf="mode == BadgeEditMode.new">
		<div class="alert alert-secondary">
			<form class="form-inline align-items-center" autocomplete="off">
				<div class="form-group">
					<label class="mr-2" for="guid">{{CB.form_id_label | localize | async}}</label>
					<input #tempGuid name="guid" id="guid" type="text" class="form-control px-1 mr-3">
					<button type="submit" class="btn btn-primary" (click)="saveGuid(tempGuid.value)">
						<span *ngIf="loaders.isSaving" class="spinner-border spinner-border-sm mx-4" role="status"><span class="sr-only">{{CB.saving | localize | async}}</span></span>
						{{CB.save_and_continue | localize | async}}
					</button>
				</div>
			</form>
		</div>
	</div>

	<div *ngIf="mode == BadgeEditMode.create || mode == BadgeEditMode.edit">

		<!--editor-->
		<div class="row">

			<!--todo: refactor this html into a subcomponent with any x language, then pass in the subcomponent for each half-->

			<!--left side-->
			<div class="col-md-6 english">
				<div class="card lang-border">
					<div class="card-header lang-background">
						<h5><strong>{{CB.english_header | localize | async}}</strong></h5>
					</div>
					<div class="card-body">
						<button *ngIf="!badge.documentTextContent.en" type="button" class="btn lang-dark" (click)="onAddLanguageContent(SupportedLanguage.en)">{{CB.add_english | localize | async}}</button>
						<form *ngIf="badge.documentTextContent.en" autocomplete="off"> <!--form-->
							<div class="form-group form-row mb-0" waih-validation-color field="enName">
								<label class="col-sm-3 col-form-label" for="enName">{{CB.name_label | localize | async}}:</label>
								<div class="col-sm-9">
									<input type="text" class="form-control" data-field-attr="" id="enName"
									       name="enName"
									       [(ngModel)]="badge.documentTextContent.en.name"
									       [attr.maxlength]="maxLengths?.name"
									       placeholder="{{CB.name_placeholder | localize | async}}">
								</div>
							</div>
							<char-count [fieldLength]="badge.documentTextContent.en.name?.length" [maxLength]="maxLengths?.name"></char-count>
							<div class="form-group" waih-validation-color field="enDescription">
								<label>{{CB.description_label | localize | async}}:</label>
								<quill-editor class="ql-editor"
								              name="enDescription"
								              [modules]="editorModules"
								              [(ngModel)]="badge.documentTextContent.en.description"
								              [maxlength]="maxLengths?.description"
								              placeholder='{{CB.description_placeholder | localize | async}}'>
								</quill-editor>
								<char-count [fieldLength]="getPlainDescriptionText(SupportedLanguage.en)?.length" [maxLength]="maxLengths?.description"></char-count>
							</div>
							<div class="form-group" waih-validation-color field="enCriteria">
								<label>{{CB.criteria_label | localize | async}}:</label>
								<quill-editor class="ql-editor"
								              name="enCriteria"
								              [modules]="editorModules"
								              [(ngModel)]="badge.documentTextContent.en.criteria"
								              placeholder="{{CB.criteria_placeholder | localize | async}}">
								</quill-editor>
								<char-count [fieldLength]="getPlainCriteriaText(SupportedLanguage.en)?.length" [maxLength]="maxLengths?.criteria"></char-count>
							</div>
							<button type="submit" class="btn btn-sm lang-dark mr-2" (click)="onSaveContent(SupportedLanguage.en)">{{CB.save_content | localize | async}}</button>
							<button type="button" class="btn btn-sm btn-danger font-weight-bold" (click)="onDeleteLanguageContent(SupportedLanguage.en)">{{CB.remove_content | localize | async}}</button>
							<small *ngIf="loaders.isSaving" class="text-danger font-italic float-right">{{CB.saving | localize | async}}</small>
						</form>
					</div>
				</div>
			</div>

			<!--right side-->
			<div class="col-md-6 hawaiian">
				<div class="card lang-border">
					<div class="card-header lang-background">
						<h5><strong>{{CB.hawaiian_header | localize | async}}</strong></h5>
					</div>
					<div class="card-body">
						<button *ngIf="!badge.documentTextContent.haw" type="button" class="btn lang-dark" (click)="onAddLanguageContent(SupportedLanguage.haw)">{{CB.add_hawaiian | localize | async}}</button>
						<form *ngIf="badge.documentTextContent.haw" autocomplete="off"> <!--form-->
							<div class="form-group form-row mb-0" waih-validation-color field="hawName">
								<label class="col-sm-3 col-form-label" for="hawName">{{CB.name_label | localize | async}}:</label>
								<div class="col-sm-9">
									<input type="text" class="form-control" data-field-attr="" id="hawName"
									       name="hawName"
									       [(ngModel)]="badge.documentTextContent.haw.name"
									       placeholder="{{CB.name_placeholder | localize | async}}">
								</div>
							</div>
							<char-count [fieldLength]="badge.documentTextContent.haw.name?.length" [maxLength]="maxLengths?.name"></char-count>
							<div class="form-group" waih-validation-color field="hawDescription">
								<label>{{CB.description_label | localize | async}}:</label>
								<quill-editor class="ql-editor"
								              name="hawDescription"
								              [modules]="editorModules"
								              [(ngModel)]="badge.documentTextContent.haw.description"
								              [maxlength]="maxLengths?.description"
								              placeholder='{{CB.description_placeholder | localize | async}}'>
								</quill-editor>
								<char-count [fieldLength]="getPlainDescriptionText(SupportedLanguage.haw)?.length" [maxLength]="maxLengths?.description"></char-count>
							</div>
							<div class="form-group" waih-validation-color field="hawCriteria">
								<label>{{CB.criteria_label | localize | async}}:</label>
								<quill-editor class="ql-editor"
								              name="hawCriteria"
								              [modules]="editorModules"
								              [(ngModel)]="badge.documentTextContent.haw.criteria"
								              [maxlength]="maxLengths?.criteria"
								              placeholder="{{CB.criteria_placeholder | localize | async}}">
								</quill-editor>
								<char-count [fieldLength]="getPlainCriteriaText(SupportedLanguage.haw)?.length" [maxLength]="maxLengths?.criteria"></char-count>
							</div>
							<button type="submit" class="btn btn-sm lang-dark mr-2" (click)="onSaveContent(SupportedLanguage.haw)">{{CB.save_content | localize | async}}</button>
							<button type="button" class="btn btn-sm btn-danger font-weight-bold" (click)="onDeleteLanguageContent(SupportedLanguage.haw)">{{CB.remove_content | localize | async}}</button>
							<small *ngIf="loaders.isSaving" class="text-danger font-italic float-right">{{CB.saving | localize | async}}</small>
						</form>
					</div>
				</div>
			</div>
		</div> <!--end editor section -->

		<!--image and cropper-->
		<div class="card border border-0 bg-light">
			<div class="card-body">
				<h5 class="card-title text-secondary font-weight-bold">{{CB.image_header | localize | async}}</h5>
				<app-cropper #cropperComponent [url]="this.urlService.imageUrl[ImageType.badge](this.badge.guid)" (save)="this.updateImage($event)" (failed)="notifyOnFail()"></app-cropper>
			</div>
		</div>

		<!--buttons-->
		<div class="row my-3">
			<button type="submit" class="btn btn-primary" (click)="saveBadge()" [disabled]="loaders.isLoading">
				<span *ngIf="loaders.isLoading" class="spinner-border spinner-border-sm mx-4" role="status">
					<span class="sr-only">{{CB.saving | localize | async}}</span>
				</span>{{CB.save_badge | localize | async}}</button>
			<button type="button" class="btn btn-secondary ml-3" (click)="router.navigate(['/more/badges', this.badge.guid])" [disabled]="mode == BadgeEditMode.create">{{CB.view_badge | localize | async}}</button>
			<button type="button" class="btn btn-secondary ml-3" (click)="router.navigate(['/more/badges'])" [disabled]="mode == BadgeEditMode.create">{{CB.return_to_list| localize | async}}</button>
		</div>
	</div>
</div>
