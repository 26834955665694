<!--this is the file upload drop zone container-->

<div class="dropzone"
	 dropZone
	 (hovered)="toggleHover($event)"
	 (dropped)="onUploadFilesSelected($event)"
	 [class.hovering]="isHovering">

	<h5>{{CB.drag | localize | async}}</h5>
	<p>{{CB.or | localize | async}}</p>

	<div class="file">
		<label class="file-label">
			<input id="file-input" class="file-input" type="file" (change)="onUploadFilesSelected($event)"
				   multiple style="display: none;">
			<button class="btn btn-lg btn-primary btn-ladda app-upload-button" data-style="expand-right"
					(click)="onSelectFilesButtonClick($event)">
            <span class="file-cta">
              <span class="file-icon"><i class="fa fa-upload"></i></span>
              <span class="file-label">{{CB.choose | localize | async}}</span>
            </span>
			</button>

		</label>
	</div>
</div>

<!--progress bar-->
<div *ngIf="percentage | async as pct">
	<progress class="progress is-info" [value]="pct" max="100"></progress>
	{{ pct | number }}%
</div>

<div *ngIf="(snapshot | async) as snap">
	{{ snap.bytesTransferred | fileSize:false }} of {{ snap.totalBytes | fileSize:false }}

	<div *ngIf="downloadURL | async as url">
		<h3>{{CB.results | localize | async}}</h3>
		<img [src]="url"><br>
		<a [href]="url" target="_blank" rel="noopener">{{CB.download | localize | async}}</a>
	</div>
	<hr>

	<button (click)="task.pause()" class="button is-warning" [disabled]="!isActive(snap)">{{CB.button_pause | localize | async}}</button>
	<button (click)="task.cancel()" class="button is-danger" [disabled]="!isActive(snap)">{{CB.button_cancel | localize | async}}</button>
	<button (click)="task.resume()" class="button is-info" [disabled]="!(snap?.state === 'paused')">{{CB.button_resume | localize | async}}</button>
</div>
