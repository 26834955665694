import {WaihonaUserRef} from "../../domain/user/WaihonaUserRef";
import {Injectable} from "@angular/core";
import {AuthService} from "../AuthService";
import {Avatar} from "../../domain/user/Avatar";
import {DoomEmail} from "./DoomEmail";
import {MessageRef} from "../../domain/inbox/MessageRef";

@Injectable({
	providedIn: 'root',
} as any)
export class InboxStubService {

	constructor(protected authService:AuthService) {


	}

	public messageRefsFromDoomEmails(toRef?:WaihonaUserRef):Array<MessageRef> {
		// @ts-ignore
		let doomEmails:Array<DoomEmail> = DoomEmail.parse();
		let messageRefs:Array<MessageRef> = [];

		if(toRef == null) {
			toRef = new WaihonaUserRef();
			toRef.altFirstName = null;
			toRef.avatar = new Avatar();
			toRef.avatar.bgColor = "#f44638";
			toRef.avatar.version = 1;

			toRef.firstName = "Kalani";
			toRef.guid = "kalani-bright"
			toRef.lastName = "Bright";
		}

		for(let email of doomEmails) {
			let messageRef:MessageRef = this.convertDoomEmailToMessageRef(email, toRef);
			messageRefs.push(messageRef);
		}

		return messageRefs;
	}
	public makeGuid(count:number = 20):string {
		let text:string = "";
		let possible:string = "abcdefghijklmnopqrstuvwxyz0123456789";

		for (let i:number = 0; i < count; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return "-" + text;
	}


	public convertDoomEmailToMessageRef(doomEmail:DoomEmail, toRef:WaihonaUserRef):MessageRef {
			let messageRef:MessageRef = new MessageRef();
			messageRef.subject = doomEmail.subject;



			let fromRef:WaihonaUserRef = new WaihonaUserRef();
			fromRef.altFirstName = null;
			fromRef.avatar = new Avatar();
			fromRef.avatar.bgColor = this.pickRandomSiteColor();
			fromRef.avatar.version = 1;

			fromRef.firstName = doomEmail.from.split(" ")[0];
			fromRef.guid = this.makeGuid()
			fromRef.lastName = doomEmail.from.split(" ")[1];

			messageRef.from = fromRef;
			messageRef.to = [toRef];

			messageRef.body = doomEmail.body;

			let doomDate:Array<string> = doomEmail.date.split("-");
			let month:number = parseInt(doomDate[0], 10)-1; //javascript months are zero based indexes
			let day:number = parseInt(doomDate[1], 10);
			let year:number = parseInt(doomDate[2], 10);

			messageRef.sent = new Date(year, month, day);

			return messageRef;
		}

	/** Picks a random color from the available site colors */
	private pickRandomSiteColor():string {
		//From: https://drive.google.com/file/d/1FiZgxQbidD6A5knHak5YwFccn1-LfKZW/view
		let possibleColors:Array<string> = [
			//TEAL			BLUE			GREEN
			"#54c3ae",		"#5495c3",		"#68bf36",
			"#86F5E0",		"#86C7F5",		"#9dfa64",
			"#C7F6EC",		"#C7E4F6",		"#cff4b1",

			//PURPLE		PINK			BROWN
			"#9546ca",		"#c33c54",		"#c3743a",
			"#c677fb",		"#fa6b86",		"#faa969",
			"#ddbef6",		"#f5b6c6",		"#f5ceb5",

			//RED			YELLOW			GRAY
			"#f44638",		"#ffb229",		"#bdbbbc",
			"#ff877d",		"#ffd068",		"#d7d7d7",
			"#ffbab8",		"#ffe0a9",		"#eae8e9"];

		let randomPick:number = Math.floor(possibleColors.length * Math.random());
		let randomColor:string = possibleColors[randomPick];

		return randomColor;
	}

/*	public get messageRef():MessageRef {
		let messageRef:MessageRef = new MessageRef();
		messageRef.subject = "HitchHiker's Guide to the Galaxy Intro";

		let waihonaUserRef:WaihonaUserRef = new WaihonaUserRef();
			waihonaUserRef.altFirstName = null;
			waihonaUserRef.avatar = new Avatar();
			waihonaUserRef.avatar.bgColor = "#f44638";
			waihonaUserRef.avatar.version = 1;

			waihonaUserRef.firstName = "Kalani";
			waihonaUserRef.guid = "kalani-bright"
			waihonaUserRef.lastName = "Bright";

		let chryssaWaihonaUserRef:WaihonaUserRef = new WaihonaUserRef();
			chryssaWaihonaUserRef.altFirstName = null;
			chryssaWaihonaUserRef.avatar = new Avatar();
			chryssaWaihonaUserRef.avatar.bgColor = "#f44638";
			chryssaWaihonaUserRef.avatar.version = 1;

			chryssaWaihonaUserRef.firstName = "Chryssa";
			chryssaWaihonaUserRef.guid = "chryssa-jones"
			chryssaWaihonaUserRef.lastName = "Jones";

		messageRef.from = Math.random() > .5 ? chryssaWaihonaUserRef : waihonaUserRef;
		messageRef.to = [waihonaUserRef];

		messageRef.body = "Far out in the uncharted backwaters of the unfashionable end of\n" +
			"the western spiral arm of the Galaxy lies a small unregarded\n" +
			"yellow sun.\n" +
			"\n" +
			"Orbiting this at a distance of roughly ninety-two million miles\n" +
			"is an utterly insignificant little blue green planet whose ape-\n" +
			"descended life forms are so amazingly primitive that they still\n" +
			"think digital watches are a pretty neat idea.\n" +
			"\n" +
			"This planet has - or rather had - a problem, which was this: most\n" +
			"of the people living on it were unhappy for pretty much of the time.\n" +
			"Many solutions were suggested for this problem, but most of these\n" +
			"were largely concerned with the movements of small green pieces\n" +
			"of paper, which is odd because on the whole it wasn't the small\n" +
			"green pieces of paper that were unhappy.\n" +
			"\n" +
			"And so the problem remained; lots of the people were mean, and\n" +
			"most of them were miserable, even the ones with digital watches.\n" +
			"\n" +
			"Many were increasingly of the opinion that they'd all made a big\n" +
			"mistake in coming down from the trees in the first place. And\n" +
			"some suggested that even the trees had been a bad move, and that no\n" +
			"one should ever have left the oceans.\n" +
			"\n" +
			"Then, one Thursday, nearly two thousand years after one man\n" +
			"had been nailed to a tree for saying how great it would be to be\n" +
			"nice to people for a change, a girl sitting on her own in a\n" +
			"small cafe in Rickmansworth England suddenly realized what it was that\n" +
			"had been going wrong all this time, and she finally knew how the\n" +
			"world could be made a good and happy place. This time it was\n" +
			"right, it would work, and no one would have to get nailed to\n" +
			"anything.\n" +
			"\n" +
			"Sadly, however, before she could get to a telephone to tell anyone\n" +
			"about it, a terribly stupid catastrophe occurred, and the idea\n" +
			"was lost forever.\n" +
			"\n" +
			"This is not her story.\n" +
			"\n" +
			"But it is the story of that terrible stupid catastrophe and some\n" +
			"of its consequences.\n" +
			"\n" +
			"It is also the story of a book. A book called The Hitch Hiker's\n" +
			"Guide to the Galaxy - not an Earth book, never published on\n" +
			"Earth, and until the terrible catastrophe occurred, never seen or even\n" +
			"heard of by any Earthman.\n" +
			"\n" +
			"Nevertheless, a wholly remarkable book.\n" +
			"\n" +
			"It is, perhaps, the most remarkable book ever to come out\n" +
			"of the great publishing houses of Ursa Minor - of which no\n" +
			"Earthman had ever heard either.\n" +
			"\n" +
			"Not only is it a wholly remarkable book, it is also a highly\n" +
			"successful one - more popular than the Celestial Home Care\n" +
			"Omnibus, better selling than Fifty More Things to do in Zero\n" +
			"Gravity, and more controversial than Oolon Colluphid's trilogy of\n" +
			"philosophical blockbusters: Where God Went Wrong, Some More of\n" +
			"God's Greatest Mistakes and Who is this God Person Anyway?\n" +
			"\n" +
			"And in many of the more relaxed civilizations on the Outer Easterni\n" +
			"Rim of the Galaxy, the Hitch Hiker's Guide has already supplanted\n" +
			"the great Encyclopedia Galactica as the standard repository of\n" +
			"all knowledge and wisdom, for though it has many omissions and\n" +
			"contains much that is apocryphal, or at least wildly inaccurate,\n" +
			"it scores over the older, more pedestrian work in two important\n" +
			"respects.\n" +
			"\n" +
			"First, it is slightly cheaper; and secondly it has the words\n" +
			"Don't Panic inscribed in large friendly letters on its cover.\n" +
			"\n" +
			"But the story of this terrible, stupid Thursday, the story of its\n" +
			"extraordinary consequences, and the story of how these\n" +
			"consequences are inextricably intertwined with this remarkable\n" +
			"book begins very simply.\n" +
			"\n" +
			"It begins with a house";

		messageRef.sent = new Date();

		return messageRef;
	}*/
}



