<!--This component populates the status box on the left of the lesson-approval page and in the slider on the lesson-edit page.
Status icons change according to the current status of each stage-->

<div class="card bg-secondary">

	<div class="card-body">
		<h5 class="card-title "><strong>{{CB.header | localize | async}}</strong></h5>
		<p class="">Version {{resourceSubmission.version}}</p>
		<div class="card-text">

			<!--draft element-->
			<div [ngSwitch]="statusDraft">
				<div *ngSwitchCase="'active'">
					<h5 class="active"><i class="fas fa-circle"></i>
						<span> - {{CB.draft_in_progress | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.draft_submitted | localize | async}}</span>
					</h5>
				</div>
			</div>

			<!--pending review element-->
			<div [ngSwitch]="statusPendingReview">
				<div *ngSwitchCase="'future'">
					<h5 class="future"><i class="far fa-circle"></i>
						<span> - {{CB.review_pending | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'active'">
					<h5 class="active"><i class="fas fa-circle"></i>
						<span> - {{CB.review_pending | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.review_started | localize | async}}</span>
					</h5>
				</div>
			</div>


			<!--active review element-->
			<div [ngSwitch]="statusInReview">
				<div *ngSwitchCase="'future'">
					<h5 class="future"><i class="far fa-circle"></i>
						<span> - {{CB.review_active | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'active'">
					<h5 class="active"><i class="fas fa-circle"></i>
						<span> - {{CB.review_active | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.review_success | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'failed'">
					<h5 class="failed"><i class="fas fa-times-circle"></i>
						<span> - {{CB.review_fail | localize | async}}</span>
					</h5>
				</div>
			</div>

			<!--awaiting revisions element-->
			<div [ngSwitch]="statusAwaitingRevision">
				<div *ngSwitchCase="'future'">
					<h5 class="future"><i class="far fa-circle"></i>
						<span> - {{CB.revision_await | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'active'">
					<h5 class="active"><i class="fas fa-circle"></i>
						<span> - {{CB.revision_await | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.revision_submitted | localize | async}}</span>
					</h5>
				</div>
			</div>

			<!--reviewing revisions stage-->
			<div [ngSwitch]="statusReviewingRevision">
				<div *ngSwitchCase="'future'">
					<h5 class="future"><i class="far fa-circle"></i>
						<span> - {{CB.revision_pendingReview | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'active'">
					<h5 class="active"><i class="fas fa-circle"></i>
						<span> - {{CB.revision_activeReview | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.revision_success | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'failed'">
					<h5 class="failed"><i class="fas fa-times-circle"></i>
						<span> - {{CB.revision_fail | localize | async}}</span>
					</h5>
				</div>
			</div>

			<!--published stage-->
			<div [ngSwitch]="statusPublished">
				<div *ngSwitchCase="'future'">
					<h5 class="future"><i class="far fa-circle"></i>
						<span> - {{CB.publish_future | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'active'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.publish_past | localize | async}}</span>
					</h5>
				</div>
				<div *ngSwitchCase="'success'">
					<h5 class="success"><i class="fas fa-check-circle"></i>
						<span> - {{CB.publish_past | localize | async}}</span>
					</h5>
				</div>
			</div>

		</div>
	</div>
</div>
