import {Component, EventEmitter, Output} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire//storage';
import {AngularFireStorageReference} from '@angular/fire/storage/ref';
import {AngularFirestore} from '@angular/fire//firestore';
import {Observable} from 'rxjs/Observable';
import {tap} from 'rxjs/operators';
import * as $ from 'jquery';
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../../../../../data/Localization";
import {LocalizationService} from "../../../../../../../../services/LocalizationService";


@Component({
	selector: 'file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
	private cbContext = Localization.template.resource.file_upload;
	public CB = {
		drag: this.cbContext.drag,
		or: this.cbContext.or,
		choose: this.cbContext.choose,
		results: this.cbContext.results,
		download: this.cbContext.download,
		button_pause: Localization.template.buttons.pause,
		button_resume: Localization.template.buttons.resume,
		button_cancel: Localization.template.buttons.cancel,
	};

	// Main task
	task:AngularFireUploadTask;

	// Progress monitoring
	percentage:Observable<number>;

	snapshot:Observable<any>;

	// Download URL
	downloadURL:Observable<string>;

	// State for dropzone CSS toggling
	isHovering:boolean;


	/**
	 * Only Emits if not currently loading
	 * @type {EventEmitter<any>}
	 */
	@Output()
	public UploadFilesSelected:EventEmitter<File[]> = new EventEmitter<File[]>();

	@Output()
	public UploadFolderIssues:EventEmitter<File[]> = new EventEmitter<File[]>();
	public inputVal:string = "";

	constructor(private storage:AngularFireStorage,
				private localizationService:LocalizationService,
				private db:AngularFirestore,
				protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("FileUploadComponent", this.CB);
	}


	public toggleHover(event:boolean) {
		this.isHovering = event;
	}


	public startUpload(event:FileList) {
		// The File object
		const file:File = event.item(0);

		// Client-side validation example
		if (file.type.split('/')[0] !== 'image') {
			this.logger.error('unsupported file type :( ');
			return;
		}

		// The storage path
		const path = `test/${new Date().getTime()}_${file.name}`;

		// Totally optional metadata
		const customMetadata = {};// app: 'My AngularFire-powered PWA!' };

		// The main task
		this.task = this.storage.upload(path, file, {customMetadata});

		// Progress monitoring
		this.percentage = this.task.percentageChanges();
		this.snapshot = this.task.snapshotChanges().pipe(
			tap(snap => {
				this.logger.info(snap);
				if (snap.bytesTransferred === snap.totalBytes) {
					// Update firestore on completion
					this.db.collection('photos').add({path, size: snap.totalBytes})
				}
			})
		);
		const fileRef:AngularFireStorageReference = this.storage.ref(path); // Add this line to get the path as a ref
		fileRef.getDownloadURL();

		// The file's download URL
		this.downloadURL = fileRef.getDownloadURL();
	}

	// Determines if the upload task is active
	public isActive(snapshot) {
		return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
	}

	public onSelectFilesButtonClick(event) { //For the click we just open the dialog...files arent yet selected
		if (event) {
			event.preventDefault();
		}
		$("#file-input").trigger('click');
		//const fileList:FileList = $("#file-input")[0]["files"];
		// this.onUploadFilesSelected(fileList);
	}

	public onUploadFilesSelected(event) {
		let files:FileList;
		if (event instanceof FileList) {
			files = event;
		} else {
			files = event.target.files;
		}
		let fileListCount = files.length;
		let realFiles:Array<File> = [];
		let folderIssues:Array<File> = [];

		this.logger.info(`${files.length} files or folder selected `);

		let emitIfValuable = () => {
			if(realFiles.length + folderIssues.length == fileListCount) {
				if(realFiles.length > 0) {
					this.UploadFilesSelected.emit(realFiles);
				}
				if(folderIssues.length > 0) {
					this.UploadFolderIssues.emit(folderIssues);
				}
			}
		};

		for(let i:number = 0; i < files.length; i++) {
			let file:File = files.item(i);
			let reader = new FileReader();
			reader.onload = (event) => {
				// it's a file
				realFiles.push(file);
				emitIfValuable();
			};
			reader.onerror = (event) => {
				folderIssues.push(file);
				this.logger.warn(`Tried to upload a folder ${file.name}`);
				emitIfValuable();
			};
			reader.readAsDataURL(file);
		}

		event.target.value = '';
	}

}

