import {Badge, BadgeType} from "../domain/badges/Badge";
import {Injectable} from "@angular/core";

@Injectable()
export class BadgesStubData {

	public get oheloThere():Badge {
		let oheloThere:Badge = new Badge();
			oheloThere.guid = "ohelo";
			oheloThere.documentTextContent.en.name = "ʻŌhelo There!";
			oheloThere.documentTextContent.en.description = "Welcome to the Waihona! There's berry much to do and see here.";
			oheloThere.documentTextContent.en.criteria = "Complete your profile and upload 3 resources.";
			oheloThere.type = BadgeType.individual;
		return oheloThere;
	}

	public get wanaBeFriends():Badge {
		let wanaBeFriends:Badge = new Badge();
			wanaBeFriends.guid = "wana";
			wanaBeFriends.documentTextContent.en.name = "Wana Be Friends?";
			wanaBeFriends.documentTextContent.en.description = "You have many friends and aren't so prickly after all (unlike wana).";
			wanaBeFriends.documentTextContent.en.criteria = "Have 50 followers.";
			wanaBeFriends.type = BadgeType.individual;
		return wanaBeFriends;
	}

	public get aeoYou():Badge {
		let aeoYou:Badge = new Badge();
			aeoYou.guid = "aeo";
			aeoYou.documentTextContent.en.name = "ʻAe‘o You";
			aeoYou.documentTextContent.en.description = "Why do birds suddenly appear every time you are near? All of us owe it to you for helping grow our community.";
			aeoYou.documentTextContent.en.criteria = "Refer 25 users to the Waihona.";
			aeoYou.type = BadgeType.individual;
		return aeoYou;
	}

	public get kumulipo():Badge {
		let kumulipo:Badge = new Badge();
		kumulipo.guid = "kumulipo";
		kumulipo.documentTextContent.en.name = "Kūmū Lipo";
		kumulipo.documentTextContent.en.description = "Congrats on joining our community early on! We hope you stay for many more wa.";
		kumulipo.documentTextContent.en.criteria = "Register as a new user during our inaugural year.";
		kumulipo.type = BadgeType.individual;
		return kumulipo;
	}

	public get twoKuForSchool():Badge {
		let twoKuForSchool:Badge = new Badge();
		twoKuForSchool.guid = "twoKu";
		twoKuForSchool.documentTextContent.en.name = "Two Kū For School";
		twoKuForSchool.documentTextContent.en.description = "Two heads are better than one, and like two manu, you helped take a lesson to new heights.";
		twoKuForSchool.documentTextContent.en.criteria = "Assist or collaborate with someone outside your own organization on a resource.";
		twoKuForSchool.type = BadgeType.individual;
		return twoKuForSchool;
	}

	public getAllBadges():Badge[] {
		let allBadges: Badge[] = [];
		allBadges.push(this.oheloThere, this.wanaBeFriends, this.aeoYou, this.kumulipo, this.twoKuForSchool);
		return allBadges;
	}

}
