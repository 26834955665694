import {Exclude, Type} from "class-transformer";
import {IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {FrameworkNode} from "./FrameworkNode";
import {RegisterClazz, RegisterType} from "../RegisterType";
import { MaxPlainTextLength } from "../../../app/validators/MaxPlainTextLength";

@RegisterClazz(Framework, "Frameworks")
export class Framework {
  @Exclude()
  public guid:string = null;

  @MinLength(3)
  @MaxLength(50)
  @IsNotEmpty()
  public title:string = "";

  public visible:boolean = false;
  public selectable:boolean = false;
  public searchable:boolean = false;

  @MinLength(10)
  @MaxLength(360)
  @IsNotEmpty()
  public shortDescription:string = "";

  @MinLength(20)
  @MaxPlainTextLength(2000)
  @IsNotEmpty()
  public fullDescription:string = "";

  @IsNotEmpty()
  public type:FrameworksType = null; //FrameworksType is an enum

  @Type(() => FrameworkNode)
  @RegisterType({clazz: FrameworkNode, isArray: true})
  public elements:Array<FrameworkNode> = [];

  public get frameworkType_faIcon():string {

    switch(this.type) {
      case FrameworksType.framework:
        return "<i class=\"fas fa-apple-alt\"></i>"; //Apple
      case FrameworksType.standard:
        return "<i class=\"fas fa-graduation-cap\"></i>"; //
      case FrameworksType.assessment:
        return "<i class=\"fas fa-chalkboard-teacher\"></i>";
      default:
        return "";
    }

  }

  public get fullImage():string {
    return "assets/img/avatars/frameworks/" + this.guid + ".jpg";//;"-full.jpg";
  }

  public get largeImage():string {
    return "assets/img/avatars/frameworks/" + this.guid + ".jpg";//;"-full.jpg";
  }

  public get medImage():string {
    // return this.guid + "-med.jpg";
    return "assets/img/avatars/frameworks/" + this.guid + ".jpg"
  }

  public get smallImage():string {
    return "assets/img/avatars/frameworks/" + this.guid + ".jpg";//"-thumb.jpg";
  }

  public get thumbImage():string {
    return "assets/img/avatars/frameworks/" + this.guid + ".jpg";//"-thumb.jpg";
  }

}


export enum FrameworksType {
  assessment = 'Assessment',
  standard ='Standard',
  framework ='Framework',
  outcome ='Learning Outcome'
}

