import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from "../services/AuthService";
import {defer, Observable, Subscription} from "rxjs";
import {NotificationService} from "../services/common/NotificationService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthenticatedUsersOnlyModalComponent} from "../areas/modals/authenticated-users-only-modal/authenticated-users-only-modal.component";
import {SiteConfigurationService} from "../services/SiteConfigurationService";
import {NGXLogger} from "ngx-logger";
import {PublishedResourceRepository} from "../services/repository/PublishedResourceRepository";
import {RoutingService} from "../services/RoutingService";

@Injectable({
	providedIn: 'root',
})
/**
 * Specifies whether a Draft can be edited or not
 */
export class PublicAccessPreferResourceCheckFirstBlockGuard implements CanActivate {

	private bsModalRef:BsModalRef;
	private subscriptions:Array<Subscription> = [];

	constructor(private authService:AuthService,
	            private notificationService:NotificationService,
				private routingService:RoutingService,
	            private publishedResourceRepository:PublishedResourceRepository,
	            private siteConfigurationService:SiteConfigurationService,
				private router:Router,
				protected logger:NGXLogger) {
	}

	public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		console.info("PuclicAccessBlockGuard::canActivate");
		let self = this;
		return defer(async function () {
			console.info("PuclicAccessBlockGuard::canActivate::async function ()");
			let shouldBlockAccess:boolean = self.siteConfigurationService.siteConfiguration.loginRequiredForSiteAccess;
			if(!shouldBlockAccess) {
				console.info("Not blocking access based on site access variable");
				return true;
			}

			console.info("self.authService.isAuthenticatingCurrently: " + self.authService.isAuthenticatingCurrently);

			//Is not a user currently WAITING for login
			let resourceGuid:string = next.params['resourceId']
			let isAPublicResource:boolean = await self.checkPublicResource(resourceGuid);
			console.info("Is a Public Resource...?" + isAPublicResource);

			if(isAPublicResource) {
				console.error("Is a Public Resource...ignoring other checks.")
				return true;
			}

			let isAUserWaitingForLogin:boolean = self.authService.isAuthenticatingCurrently  || self.authService.isHandlingLocalSelfAuth;
			if(isAUserWaitingForLogin) {
				console.info("Waiting for login process to complete");
				let waitForLoginPromise:Promise<boolean> = new Promise((resolve, reject) => {
						let didResolve:boolean = false;


						setTimeout(() => {
							if(!didResolve) {
								console.info("AuthenticatedUsersOnlyModalBlockGuard timed out");
								self.clearSubscriptions();

								resolve(false)
							}


						}, 10000);

					let wasHandlingLocalSelfAuth:boolean = self.authService.isHandlingLocalSelfAuth;
					let wasHandlingNormalAuthenticating:boolean = self.authService.isAuthenticatingCurrently;

					if(self.authService.isAuthenticatingCurrently) {
						self.subscriptions.push(self.authService.isAuthenticatingCurrently$.subscribe(isLoggingIn => {
							console.info("Logging in process data has been changed" + isLoggingIn);
							self.clearSubscriptions();
							if(isLoggingIn == false && wasHandlingNormalAuthenticating) {
								console.info("Completed login process");
								didResolve = true;
								self.clearSubscriptions();
								resolve(true);
							}
						}));
					} else if(self.authService.isHandlingLocalSelfAuth) {
						self.subscriptions.push(self.authService.isHandlingLocalSelfAuth$.subscribe(isHandlingLocalSelfAuth => {
							if(isHandlingLocalSelfAuth == false && wasHandlingLocalSelfAuth) {
								console.info("Completed local auth process");
								didResolve = true;
								self.clearSubscriptions();
								resolve(true);
							}
						}));
					} else {
						// dont know all the cases
						resolve(true);
					}
				});

				let successfullyLoggedIn:boolean = await waitForLoginPromise;

				console.info("Waited for login process to complete succesfully, isLoggingIn==" + self.authService.isAuthenticatingCurrently + ", successfullyLoggedIn: " + successfullyLoggedIn);
				return self.finishUpEitherWay(isAPublicResource);
			} else {
				return self.finishUpEitherWay(isAPublicResource);
			}
		});
	}
	public finishUpEitherWay(isAPublicResource:boolean):boolean {
		let isAUser:boolean = this.authService.currentUser != null;
		if (isAUser) {
			console.info(`Welcome in ${this.authService.currentUser.guid}!`);
		} else if(!isAUser && isAPublicResource) {
			console.error("Hawt dog.  You got some access to a waihona resource without registering or logging in.");
		} else {
			console.error("It is invalid for anonymous user to access the site during the development phase.");
			if (this.routingService.getPreviousUrl() == undefined) {
				console.info("First navigation and not allowed..so navigating to location page.")
				this.router.navigateByUrl("/resources", {skipLocationChange:false});
			}
			this.bsModalRef = this.notificationService.displayModal(AuthenticatedUsersOnlyModalComponent, this);
		}
		return isAUser || isAPublicResource;
	}

	public checkPublicResource(resourceGuid:string):Promise<boolean> {
		let self = this;
		return new Promise((resolve, reject) => {
			let s:Subscription = self.publishedResourceRepository.get$(resourceGuid).subscribe(resource => {
				if(resource == null) {
					resolve(false);
					return; //not sure if these returns are necessary or not
				}
				if(resource.resourceSubmission.configuration.allowPublicAccess) {
					resolve(true);
					return;
				} else {
					resolve(false);
					return;
				}
			});
			self.subscriptions.push(s);
		});
	}

	public clearSubscriptions():void {
		while(this.subscriptions.length > 0) {
			let s:Subscription = this.subscriptions.pop();
			s.unsubscribe();
		}

	}
	public destroyModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onClickLogin():void {
		console.info("Clicked Login");
		this.authService.login();
		this.destroyModal();
	}
	public onClickRegistration():void {
		console.info("Clicked Registration");
		this.router.navigate(["/user/register"]);
		this.destroyModal();
	}
	public onClickCancel():void {
		console.info("Clicked Cancel");
		this.destroyModal();
	}
}
