import {classToPlain, Exclude, plainToClass, Transform, Type, TypeHelpOptions} from 'class-transformer';

import {IndexProperty} from "../../../domain/IndexProperty";
import {Moku} from "../../../domain/resources/aina/Moku";
import {Mokupuni} from "../../../domain/resources/aina/Mokupuni";
import {Aina} from "../../../domain/resources/aina/Aina";
import {MokupuniRef} from "../../../domain/resources/aina/MokupuniRef";
import {MokuRef} from "../../../domain/resources/aina/MokuRef";
import {AinaRef} from "../../../domain/resources/aina/AinaRef";
import {RegisterClazz, RegisterType} from "../../../domain/RegisterType";
import {IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {AinaRepository} from "../../../services/repository/AinaRepository";
import {AbstractFirestoreRepository} from "../../../services/repository/AbstractFirestoreRepository";
import {IRepository} from "../../../services/repository/IRepository";





export class KnownSpecificationType {
	@Exclude({toPlainOnly: true})
	public guid:string = null; //Must match the class name
	public tags:Array<string> = [];
	public description:string = null;
	public hasClass:boolean = false;


	public get Clazz():new()=>{} {
		return Specifications[this.guid];
	}
}
export class KnownSpecificationTypeRef {
	public guid:string = null;
}
export interface ISpecificationMapItem {
	name:string,
	Clazz:new()=>{},
	Ref:new()=>{}
}
export interface ISpecificationCategoryMapItem {
	repository: Function;
}
export interface ISpecificationMap {
	[key:string]:ISpecificationMapItem,
}
export interface ISpecificationCategoriesMap {
	[key:string]:ISpecificationCategoryMapItem,
}

export class SpecificationCategory {
	public guid:string = null;
	public label:string = "";

	@Type(()=>KnownSpecificationTypeRef)
	public definitions:Array<KnownSpecificationTypeRef> = [];
	public allowedTopLevel:Array<NodeRelationshipDefinition> = []

	public repositoryId:string = null;
}

export type TypedNodeName = string;
export class Specifications {
	protected static categories:ISpecificationCategoriesMap = {
		"Aina": { repository: AinaRepository }
	};
	protected static registrations:ISpecificationMap = {
		"Aina": {
			name: "Aina",
			Clazz: Aina,
			Ref: AinaRef,
		},
		"Mokupuni": {
			name: "Mokupuni",
			Clazz: Mokupuni,
			Ref: MokupuniRef
		},
		"Moku": {
			name: "Moku",
			Clazz: Moku,
			Ref: MokuRef,
		},
	};
	public static getCategory(guid:string):ISpecificationCategoryMapItem {
		if(Specifications.categories[guid] == null) {
			console.error("Cant get here because it was never registered.");
			return null;
		}
		return Specifications.categories[guid];
	}
	public static register(guid:string, Clazz:new()=>{}, Ref:new()=>{}) {
		if(Specifications.registrations[guid] != null) {
			console.error("Cant register here because it was already registered.");
			return;
		}
		Specifications.registrations[guid] = {
			name: guid,
			Clazz: Clazz,
			Ref: Ref,
		}
	}
	public static get(guid:string):ISpecificationMapItem {
		if(Specifications.registrations[guid] == null) {
			console.error("Cant get here because it was never registered.");
			return null;
		}
		return Specifications.registrations[guid];
	}
	public static list():Array<ISpecificationMapItem> {
		return Object.values(Specifications.registrations);
	}
}
export class NodeRelationshipDefinition {
	@Exclude({toPlainOnly: true})
	public guid:string = null;

	@Type(()=>KnownSpecificationTypeRef)
	public specFor:KnownSpecificationTypeRef = null;//Identifier

	public contexts:Array<string> = [];

	@Type(()=> KnownSpecificationTypeRef)
	@IndexProperty()
	public subtypes:Array<KnownSpecificationTypeRef> = [];

	public static create(specFor:KnownSpecificationTypeRef, subtypes:Array<KnownSpecificationTypeRef> = []) {
		let instance:NodeRelationshipDefinition = new NodeRelationshipDefinition();
			instance.specFor = specFor;
			instance.subtypes = subtypes;

		return instance;
	}
}
export class NodeRelationshipDefinitionRef {
	public guid:string = null;
}

export const typeInfo = (typeInfo:TypeHelpOptions) => {
	let clazzName:string = typeInfo.object["type"];
	let specificationMapItem:ISpecificationMapItem = Specifications.get(clazzName);
	return specificationMapItem.Clazz;
};
export class NodeData {
	public type:string = null;
	public label:string = "";
}
export abstract class NodeInfo {
	public guid:string = null;

	public data:NodeData = null;
	public children:Array<NodeData> = [];

	public get label():string {
		return this.data.label;
	}
}
export class TypedNode<T extends NodeData=any> extends NodeInfo {

	public static availableTypes:Array<string> = [];
	//Do not put exclude here
	public guid:string = null;

	@Type(typeInfo)
	public data:T;

	@Type(typeInfo)
	public children:Array<T> = [];

	public get label():string {
		return this.data.label;
	}
	public get type():string {
		return this.data.type;
	}
}

/*
export class Node {
	@Exclude({toPlainOnly: true})
	public guid:string = null;
	public specifications:Array<NodeSpecification> = [];
	public data:T;

	constructor(guid:string, specs:Array<NodeSpecification>) {
		this.specifications.push(...specs);
	}
}*/
