import {AbstractFirebaseStorage} from "./storage/AbstractFirebaseStorage";
import {Injectable} from "@angular/core";
import {AngularFireStorage, AngularFireUploadTask} from "@angular/fire/storage";
import {AuthService} from "./AuthService";
import {Observable} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";
import {ImageType} from "../areas/common/cropper/cropper.component";
import {Organization} from "../domain/organization/Organization";
import {Badge} from "../domain/badges/Badge";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {PathService} from "./PathService";
import {Collection} from "../domain/collections/Collection";
import {SettableMetadata} from "@angular/fire/storage/interfaces";


@Injectable({
	providedIn: 'root',
} as any)
export class UploadService extends AbstractFirebaseStorage {


	constructor(protected storage:AngularFireStorage,
	            protected pathService:PathService,
	            protected logger:NGXLogger) {
		super(storage);
	}

	public uploadResourceAsset(filePath:string, data:any, resourceSubmission:ResourceSubmission):AngularFireUploadTask {
		let collaboratorsString:string = this.arrayToCSV(resourceSubmission.collaborators, "guid");

		this.logger.info(`Starting upload to ${filePath}`);
		const task:AngularFireUploadTask = this.storage.upload(filePath, data,
		  {
			contentDisposition: "inline",
			cacheControl: "no-cache, max-age=0",
			customMetadata: {
			  'submitter': resourceSubmission.submitter.guid,
			  'organization': resourceSubmission.organization.guid,
			  'collaborators': collaboratorsString
			}
		  });
		return task;
	}

	public arrayToCSV(array:Array<any>, stringField:string):string {
		const stringValues:string[] = array.map(arrayValue => arrayValue[stringField]);
		let csvString:string = "";
		if (stringValues?.length > 0) {
			return csvString = stringValues.reduce((accumulator:string, currentVal:string) => accumulator + ", " + currentVal);
		} else {
			return null;
		}
	};

	public getMetadata(type:ImageType, value?:ResourceSubmission|Organization|Badge|WaihonaUser|Collection):{} {
		if(value == null) {
			console.error(`Some error occurred; Value was null.  Type was ${type}`)
			return;
		}
		switch (type) {
			case ImageType.draft: {
				let valueAsResourceSubmission:ResourceSubmission = value as ResourceSubmission;
				let collaboratorsString:string = this.arrayToCSV(valueAsResourceSubmission.collaborators, "guid");

				let resourceMetadata = {
					contentDisposition: "inline",
					cacheControl: "no-cache, max-age=0",
					customMetadata: {
						'submitter': valueAsResourceSubmission.submitter.guid,
						'organization': valueAsResourceSubmission.organization.guid,
						'collaborators': collaboratorsString
					}
				};
				return resourceMetadata;
			}
			case ImageType.organization: {
				let orgMetadata = {
					cacheControl: "no-cache, max-age=0",
					customMetadata: {
						'organization': (value as Organization).guid,
					}
				};
				return orgMetadata;
			}
			case ImageType.badge: {
				let badgeMetadata = {
					cacheControl: "no-cache, max-age=0",
					customMetadata: {
						'badge': (value as Badge).guid,
					}
				};
				return badgeMetadata;
			}
			case ImageType.avatar: {
				let metadata = {
					cacheControl: "no-cache, max-age=0",
				};
				//NOTE: using the user id part of file path for the user id rather than the metadata

				return metadata;
			}
			case ImageType.collection: {
				let valueAsCollection:Collection = value as Collection;
				let collaboratorsString:string = this.arrayToCSV(valueAsCollection.collaborators, "guid");

				let collectionMetadata:SettableMetadata = {
					cacheControl: "no-cache, max-age=0",
					customMetadata: {
						'collection': valueAsCollection.guid,
						'userOwner': valueAsCollection.owner?.userRef?.guid,
						'orgOwner': valueAsCollection.owner?.orgRef?.guid,
						'collaborators': collaboratorsString
					}
				};
				return collectionMetadata;
			}
			default: {
				console.error(`Some error occurred; Type was ${type}`);
				return;
			}
		}

	}

	public getPath(type:ImageType, value:ResourceSubmission|Organization|Badge|WaihonaUser|Collection):string {
		let path:string = "";
		switch(type) {
			case ImageType.draft:
				path = this.pathService.storage.resourceFolder.image.draft(value.guid);
				break;
			case ImageType.organization:
				path = this.pathService.storage.organization.image(value.guid);
				break;
			case ImageType.badge:
				path = this.pathService.storage.badge.image(value.guid);
				break;
			case ImageType.avatar:
				path = this.pathService.storage.user.image(value.guid);
				break;
			case ImageType.collection:
				path = this.pathService.storage.collection.image(value.guid);
				break;
		}
		return path;
	}

	/** Upload Cropper StorageImage to Server */
	public uploadCropperImage(type:ImageType, base64Png:string, value?:ResourceSubmission|Organization|Badge|WaihonaUser|Collection):Observable<string> {
		this.logger.info(`uploading cropper image to storage`);
		return this.saveBase64AsFile(this.getPath(type, value), base64Png, this.getMetadata(type, value));
	}

	public updateCoverImageMetadata(type:ImageType, value:ResourceSubmission|Organization|Badge|WaihonaUser|Collection):Observable<any> {
		this.logger.info(`updating image metadata`);
		return this.updateFileMetadata(this.getPath(type, value), this.getMetadata(type, value));
	}

}
