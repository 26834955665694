import {SupportedLanguage} from "../domain/SupportedLanguages";
import {convertKeyToValue} from "./SearchTools";
import {LocalizeTools} from "./LocalizeTools";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";

export function sort<P>(items:Array<P>, field:string, options?:{nupepafy?:boolean, ref?:boolean, localizedField?:boolean, lang?:SupportedLanguage, textContentType?:new () => any, textContentProperty?:string}): {ascending:()=>Array<P>,descending:()=>Array<P>} {

	console.log(`SortTools:sort method called to sort ${items.length} items by ${field}.`);
	const collator = new Intl.Collator('en', {numeric: true, sensitivity: 'base'});

	if (options.localizedField === true) {
		if (!options.lang) {
			throw new Error("language must be defined if localizedField is true");
		}
		if (!options.ref && !options.textContentType) {
			throw new Error("textContentType must be defined if localizedField is true and type is not ref");
		}
	}

	let compare = (a, b) => {
		let a_string:string;
		let b_string:string;

		if (options.localizedField === true) {
			if (!!options.textContentProperty) {
				a = convertKeyToValue(a, options.textContentProperty);
				b = convertKeyToValue(b, options.textContentProperty);
			}

			if (options.ref === true) {
				a_string = LocalizeTools.ref(a.localization, options.lang, field);
				b_string = LocalizeTools.ref(b.localization, options.lang, field);
			} else {
				a_string = LocalizeTools.document(a, options.textContentType, options.lang)[field];
				b_string = LocalizeTools.document(b, options.textContentType, options.lang)[field];
			}
		} else {
			a_string = convertKeyToValue(a, field);
			b_string = convertKeyToValue(b, field);
		}
		if (options.nupepafy === true) {
			a_string = NupepafyStringUtils.nupepafy(a_string);
			b_string = NupepafyStringUtils.nupepafy(b_string);
		}
		return collator.compare(a_string, b_string);
	};
	return {
		ascending:():Array<P> => {
			return items.sort((a:P, b:P) => {
				return compare(a, b);
			});
		},
		descending:():Array<P> => {
			return items.sort((a:P, b:P) => {
				return compare(b, a);
			});
		},
	}
}
