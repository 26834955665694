import {Injectable} from "@angular/core";
import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {PublishedResourceRepository} from "./repository/PublishedResourceRepository";
import {Observable, Subject, Subscription} from "rxjs";
import {Review} from "../domain/resources/review/Review";
import {PublishedResource} from "../domain/resources/PublishedResource";
import {ResourceFunctions} from "./functions/ResourceFunctions";
import {NGXLogger} from "ngx-logger";
import {AngularFireFunctions} from "@angular/fire/functions";
import {ResourceSubmissionService} from "./ResourceSubmissionService";
import {ActivityStream, ActivityType} from "../domain/resources/ActivityStream";
import {AuthService} from "./AuthService";
import {LocalizationService} from "./LocalizationService";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";
import {NotificationService, ToastType} from "./common/NotificationService";
import {Localization} from "../data/Localization";
import {ResourcePublishStatusRepository} from "./repository/ResourcePublishStatusRepository";
import {ResourcePublishStatus} from "../domain/resources/ResourcePublishStatus";
import {ActivityStreamService} from "./ActivityStreamService";
import {RefCachingService} from "./RefCachingService";
import {generateTextIndex} from "../domain/IndexProperty";
import {PageListService, PagingService} from "./PageListService";
import {PermissionType} from "../domain/user/Permission";
import {RoleService} from "./common/RoleService";
import {PublishedResourceRef} from "../domain/resources/PublishedResourceRef";
import {ConversionService} from "./ConversionService";
import {ResourceSubmissionTextContent} from "../domain/resources/ResourceSubmissionTextContent";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";
import {Collection} from "../domain/collections/Collection";
import {CollectionService} from "./CollectionService";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {convertActionBinding} from "@angular/compiler/src/compiler_util/expression_converter";
import {SupportedLanguage} from "../domain/SupportedLanguages";
import {sort} from "../helper/SortTools";

class NeedToSaveException extends Error {
}

@Injectable({
	providedIn: 'root',
} as any)
export class PublishedResourceService extends SubscriptionCleaner implements PagingService<PublishedResource> {

	private _paging:{
		list:PageListService<PublishedResource>,
	};

	public paging = {
		pageSize: 20,
		list: {
			cursor: () => {
				return this._paging.list.pageCursor;
			},
			initialize: {
				list$:() =>  this._paging.list.initializePaging$(this.repo.by.criteria.all(), this.paging.pageSize)
			},
			next:() => {
				this._paging.list.getNextPage();
			},
			isLoading: () => {
				return this._paging.list.pageLoading$;
			},
			nextPageExists: () => {
				return this._paging.list.pageCursor?.nextPageExists;
			},
		}
	}

	public pendingPublishes:{[resourceGuid:string]:number} = {};
	private cbEditResourceContext = Localization.template.resource.edit;

	public constructor (
	    public repo:PublishedResourceRepository,
	    public functions:ResourceFunctions,
	    private resourceSubmissionService:ResourceSubmissionService,
		private authService: AuthService,
	    protected logger:NGXLogger,
	    private angularFireFunctions:AngularFireFunctions,
	    private localizationService:LocalizationService,
	    private notificationService:NotificationService,
	    private resourcePublishStatusRepo:ResourcePublishStatusRepository,
		private refCachingService:RefCachingService,
	    private activityStreamService:ActivityStreamService,
	    private collectionService:CollectionService,
	    private conversionService:ConversionService,
	    private roleService:RoleService
    ) {
		super();

		this._paging = {
			list: new PageListService<PublishedResource>(this.repo)
		}
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};

	public get store() {
		return this.repo.accessors();
	}
	//todo: remove unused methods and refactor get/list/etc to use store instead

	///////////////////////////////////////////////////////////////////////////////
// For publish from Review
	public publishResourceFromReview(resource:Review | ResourceSubmission):Observable<PublishedResource> {
		this.logger.info("Publishing Resource");
		if(resource.guid == null) {
			this.logger.error(`publishResource resource.guid: ${resource.guid}`);
		}
		let publishedResource:PublishedResource = new PublishedResource();
		if (resource instanceof Review) {
			let review:Review = resource;
			publishedResource.guid = review.resourceSubmission.guid;
			publishedResource.resourceSubmission = review.resourceSubmission;
			publishedResource.resourceSubmission.guid = review.guid;
			publishedResource.reviewers = review.reviewers;
		} else if (resource instanceof ResourceSubmission) {
			let resourceSubmission:ResourceSubmission = resource;
			publishedResource.resourceSubmission = resourceSubmission;
			publishedResource.guid = resourceSubmission.guid;
		}

		let zipFilename:string = `${publishedResource.guid}_${resource.version.major}.zip`;

		//Check if the zip already exists in the archive list (republishing?, unlikely to happen).
		if (publishedResource.archived.indexOf(zipFilename)  == -1) {
			publishedResource.archived.push(zipFilename);
		}
		publishedResource.indexedTitle = generateTextIndex(
			{textValue: this.LocalizeTools.document(publishedResource.resourceSubmission, ResourceSubmissionTextContent).title}
		) as string;

		//THIS LOOKS ODD.  This should be happening serverside...  No user should have the ability to publish from client side.
		return this.repo.save$(publishedResource);

	}

	public publishFilesFromReview$(pubResource:PublishedResource):Observable<boolean> {
		this.logger.info(`publishing files for ${pubResource.guid}`);
		const publishFilesFromReview = this.angularFireFunctions.httpsCallable('publishFilesFromReview');
		return publishFilesFromReview({resource: pubResource});
	}

	public warmUpPublishFilesFromReview$():Observable<boolean> {
		this.logger.info(`warming up publishFilesFromReview`);
		const publishFilesFromReview = this.angularFireFunctions.httpsCallable('publishFilesFromReview');
		return publishFilesFromReview("warm-up");
	}
//
///////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////
// For publish from Draft
	/** Auto-publish a resource */
	public publishResourceSubmissionNoReview(resource:ResourceSubmission, collections:Array<Collection> = []):Observable<boolean> {
		this.logger.info(`PublishedResourceService::publishResourceSubmissionNoReview starting...`)
		let resourcePublishedObservable:Subject<boolean> = new Subject<boolean>();
		let currentUserRef:WaihonaUserRef = this.authService.currentUserRef;

		if (resource.guid == null) {
			this.logger.error("Need to Save!");
			throw new NeedToSaveException("Need to Save!");
		} else {
			resource.status = ResourceSubmissionStatus.published;
			resource.approved = new Date().getTime();
			resource.version.nextMajor();

			this.activityStreamService.getActivityStream$(resource.guid).subscribe((activityStream:ActivityStream) => {
				let createNewActivityStream:boolean = false;
				if (!activityStream) {
					createNewActivityStream = true;
					activityStream = new ActivityStream();
					activityStream.guid = resource.guid;
				}
				activityStream.addEntry(ActivityType.APPROVED, "", resource.version).with(currentUserRef);

				this.directPublishResourceOnServer(resource).take(1).subscribe((pubResource:PublishedResource) => {
					delete this.pendingPublishes[resource.guid];

					if (pubResource) {
						this.logger.info(`published ${pubResource.guid} to database`);

						//update collections, if any
						if (collections.length > 0) {
							this.logger.info(`updating selected collections after publishing...`);
							this.repo.getAsRef$(pubResource.guid).subscribe(ref => {
								try {
									this.trackSubscription(this.collectionService.addResourceRefsToCollections$([ref], collections, currentUserRef.guid).subscribe(() => {
										this.notificationService.displayToast(Localization.template.collections.toasts.resource_added.success, ToastType.success);
									}));
								} catch (err) {
									this.logger.error(err);
									this.notificationService.displayToast(Localization.template.collections.toasts.resource_added.fail, ToastType.error);
								}
							})
						}

						// publish files and archive
						this.trackSubscription(this.publishFilesFromDraft$(pubResource).take(1).subscribe(() => {}));

						// update activity stream
						activityStream.addEntry(ActivityType.PUBLISHED, "", resource.version).with(currentUserRef);
						// have to subscribe to this function in order for it to run
						this.activityStreamService.safeSaveActivityStream$(activityStream, resource.guid, createNewActivityStream).subscribe(() => {
							// Update Draft with new time approved, version num, activity etc.
							resource.status = ResourceSubmissionStatus.draft;
							this.resourceSubmissionService.save(resource);
						});

						this.notificationService.displayToast(this.cbEditResourceContext.toasts.direct_publish.success, ToastType.success);
						resourcePublishedObservable.next(true);
					}
				}, (err) => {
					this.logger.error("There was an error in directPublishResourceOnServer: " + err);
					resourcePublishedObservable.next(false);
				});
			});
		}

		return resourcePublishedObservable;
	}

	public directPublishResourceOnServer(resource:Review | ResourceSubmission):Observable<PublishedResource> {
		this.logger.info("Publishing Resource");
		if (resource.guid == null) {
			this.logger.error(`publishResource resource.guid: ${resource.guid}`);
		}
		let publishedResource:PublishedResource = new PublishedResource();
		if (resource instanceof Review) {
			let review:Review = resource;
			publishedResource.guid = review.resourceSubmission.guid;
			publishedResource.resourceSubmission = review.resourceSubmission;
			publishedResource.resourceSubmission.guid = review.guid;
			publishedResource.reviewers = review.reviewers;
		} else if (resource instanceof ResourceSubmission) {
			let resourceSubmission:ResourceSubmission = resource;
			publishedResource.resourceSubmission = resourceSubmission;
			publishedResource.guid = resourceSubmission.guid;
			publishedResource.reviewers[0] = resourceSubmission.submitter;
		}

		let guidVersion:string = `${publishedResource.guid}_${resource.version.major}`
		let zipFilename:string = `${guidVersion}.zip`;

		//Check if the zip already exists in the archive list (republishing?, unlikely to happen).
		if (publishedResource.archived.indexOf(zipFilename)  == -1) {
			publishedResource.archived.push(zipFilename);
		}

		return this.publishResourceFromDraft$(publishedResource);
	}

	private publishResourceFromDraft$(resource:PublishedResource):Observable<PublishedResource> {
		this.logger.info(`publishing ${resource.guid}`);
		const publishResourceFromDraft = this.angularFireFunctions.httpsCallable('publishResourceFromDraft');
		return publishResourceFromDraft({resource: resource});
	}

	private publishFilesFromDraft$(resource:PublishedResource):Observable<boolean> {
		this.logger.info(`publishing files for ${resource.guid}`);
		const publishFilesFromDraft = this.angularFireFunctions.httpsCallable('publishFilesFromDraft');
		return publishFilesFromDraft({resource: resource});
	}

	public warmUpPublishResourceFromDraft$():Observable<PublishedResource> {
		this.logger.info(`warming up publishResourceFromDraft`);
		const publishResourceFromDraft = this.angularFireFunctions.httpsCallable('publishResourceFromDraft');
		return publishResourceFromDraft("warm-up");
	}

	public warmUpPublishFilesFromDraft$():Observable<PublishedResource> {
		this.logger.info(`warming up publishFilesFromDraft`);
		const publishFilesFromDraft = this.angularFireFunctions.httpsCallable('publishFilesFromDraft');
		return publishFilesFromDraft("warm-up");
	}
//
///////////////////////////////////////////////////////////////////////////////

	public get$(guid:string):Observable<PublishedResource> {
		return this.repo.get$(guid).map(publishedResource => {
			publishedResource.resourceSubmission.guid = publishedResource.guid;
			return publishedResource;
		});
	}

	public watch$(guid:string):Observable<PublishedResource> {
		return this.repo.watch$(guid).map(publishedResource => {
			if (publishedResource) {
				publishedResource.resourceSubmission.guid = publishedResource.guid;
			}
			return publishedResource;
		});
	}

	public list$():Observable<PublishedResource[]> {
	  return this.repo.list$();
	}

	public getPublishedResourcesBySubmitter$(submitterId:string):Observable<PublishedResource[]> {
		return this.repo.query$(this.repo.by.queryFn.submitter(submitterId));
	}

	public getPublishedResourcesByOrgAndReviewer$(orgGuid:string, reviewerId:string):Observable<PublishedResource[]> {
		return this.repo.query$((this.repo.by.queryFn.orgAndReviewer(orgGuid, reviewerId)));
	}

	public getPublishedResourcesBySubmitterWithStatus$(submitterGuid:string, status:ResourceSubmissionStatus):Observable<PublishedResource[]> {
		return this.repo.query$(this.repo.by.queryFn.submitterWithStatus(submitterGuid, status));
	}

	public getPublishedAndSortedResourcesByOrg$(orgGuid:string):Observable<PublishedResource[]> {
		let pubResourcesObservable$:Subject<PublishedResource[]> = new Subject<PublishedResource[]>();
		let s:Subscription = this.repo.query$(this.repo.by.queryFn.organization(orgGuid)).subscribe(items => {
			s.unsubscribe();
			this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
				items = sort(items, "title", {nupepafy: true, localizedField: true, ref: false, lang: newLang, textContentType: ResourceSubmissionTextContent, textContentProperty:"resourceSubmission"}).ascending();
				pubResourcesObservable$.next(items);
			});
		});
		return pubResourcesObservable$;
	}

	public watchPublishStatus$(resource:ResourceSubmission):Observable<ResourcePublishStatus> {
		let pubStatusObservable$:Subject<ResourcePublishStatus> = new Subject<ResourcePublishStatus>();
		let guidVersion:string = `${resource.guid}_${resource.version.major}`
		let pubStatusSub:Subscription = this.resourcePublishStatusRepo.watch$(guidVersion).subscribe((pubStatus:ResourcePublishStatus) => {
			if (pubStatus) {
				console.log("pubStatus: ");
				console.log(pubStatus);
				pubStatusObservable$.next(pubStatus);

				if (pubStatus.status.archive === "success" ) {
					pubStatusSub.unsubscribe();
				}
			} else {
				pubStatusSub.unsubscribe();
			}
		});
		this.trackSubscription(pubStatusSub);

		return pubStatusObservable$;
	}


	/** Warning! This is a destructive method! Deletes the published instance of a resource from the database as well as any associated files in storage.*/
	//todo: handle delete and unpublish as separate functions on the server side and not here in the client.

	public delete(publishedResource:PublishedResource):void {
		this.logger.warn(`deleting published resource ${publishedResource.guid} from the repository`);
		this.repo.delete$(publishedResource);
		this.logger.warn(`deleting associated files for ${publishedResource.guid} from storage`);
		this.functions.deletePublishedResourceFromStorage(publishedResource.guid);
	}

	public unpublish(publishedResource:PublishedResource) {
		//This needs to be a separate operation from delete eventually that does things a bit different...
		// ... which is why its calling delete but still in its own method.

		//todo: call a new unpublish function on the server side (see the todo in ResourceController) that will handle the delete of both the database document and the storage objects, and do not use the delete method here in the client.
		this.delete(publishedResource);
	}

	public toggleLockStatus(guid:string, status:boolean):void {
		if (this.roleService.hasPermissionFor(PermissionType.unlock_resources)) { //the UI should prevent this from ever being otherwise, but just in case
			this.repo.updatePartial$({guid: guid, resourceSubmission:{configuration:{allowPublicAccess: status}}});
		} else {
			this.logger.error(`The user does not have permission to toggle the lesson lock and never should have been able to click. Debug me!`);
		}
	}

}
