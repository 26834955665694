<div *ngIf="fanContributions && fanContributions.length > 0" class="card">
	<div class="card-header bg-light"><strong>{{CB.fan_header | localize | async}}</strong></div>
  <div class="card-body">
    <p>{{CB.fan_text | localize | async}}</p>
    <div>
      <div *ngFor="let fanContribution of fanContributions" class="card">
        <div class="card-header">{{CB.fan_card_header | localize | async}} {{fanContribution.getName()}}
          <span class="font-italic float-right">{{fanContribution.timestamp}}</span>
        </div>
        <div class="card-body">
          <p class="card-text">{{CB.fan_card_text | localize | async}}
            "{{fanContribution.comment}}"</p>
          <h6>{{CB.fan_table_header | localize | async}}</h6>
          <div class="table-responsive-sm">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">{{CB.fan_table_filename | localize | async}}</th>
                <th scope="col">{{CB.fan_table_summary | localize | async}}</th>
                <th scope="col">{{CB.fan_table_tag | localize | async}}</th>
              </tr>
              </thead>
              <tbody *ngFor="let file of fanContribution.files">
              <tr>
                <th scope="row">
                  <a href="javascript:void(0);"
                     (click)="openFanAsset(fanContribution, file)">{{file.fileName}}</a>
                  <!--todo: make this work!-->
                </th>
                <td>{{file.title}}</td>
                <td><span *ngFor="let tag of file.tags">{{tag.value}}<br></span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn-group btn-group-sm float-right">
            <button class="btn btn-primary"
                    (click)="acceptFanContribution(fanContribution)">{{CB.button_accept | localize | async}}</button>
            <button class="btn btn-warning"
                    (click)="declineFanContribution(fanContribution)">{{CB.button_decline | localize | async}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
