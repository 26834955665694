import {ValidationError} from "class-validator";

export class ValidationHelper {



    public static hasErrorFor(name:string, array:Array<ValidationError>):boolean {
        const matching:ValidationError[] = array.filter((error:ValidationError) => {
            return (error.property == name);
        }) as ValidationError[];
        return (matching.length > 0);
    }

    public static errorTextFor(name:string, array:Array<ValidationError>):string {
        const matching:any[] = array.filter((error:any) => {
            return (error.property == name);
        });
        if(matching.length > 0) {
            const error = matching[0].error;
            for (const key in error) {
                if (error.hasOwnProperty(key)) {
                    let errorMessage:string = error[key];
                    errorMessage = errorMessage
                    // insert a space before first cap on the property name (gradeLevels==>grade Levels)
                    .replace(/([A-Z])/g, ' $1')
                    // uppercase the first character ("grade Levels ==> Grade Levels)
                    .replace(/^./, function(str) {
                        return str.toUpperCase();
                    });

                    return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
                }
            }
        }
        return "";
    }

}