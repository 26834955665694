import {Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ResourceSubmissionService} from "../../../../../services/ResourceSubmissionService";
import {ResourceSubmission, ResourceSubmissionStatus} from "../../../../../domain/resources/ResourceSubmission";
import {ValidationException} from "../../../../../services/exceptions/ValidationException";
import {WaihonaUserService} from "../../../../../services/WaihonaUserService";
import {
	ReviewFieldApprovalStatus,
	ReviewStatus
} from "../../../../../domain/resources/review/ReviewFieldApprovalStatus";
import {ImageCropperComponent} from "ngx-image-cropper";
import {BaseComponent} from "../../../../BaseComponent";
import {UploadService} from "../../../../../services/UploadService";
import {UrlService} from "../../../../../services/UrlService";
import {ToastType} from "../../../../../services/common/NotificationService";
import {PathService} from "../../../../../services/PathService";
import {AnalyticsService} from "../../../../../services/AnalyticsService";
import {LessonAssetsComponent} from "./subcomponents/lesson-assets/lesson-assets.component";
import {
	CanComponentDeactivate,
	NavigatingComponentDeactivationGuard
} from "../../../../../guards/NavigatingComponentDeactivationGuard";
import {LearningAssetUpload} from "../../../../../services/objects/LearningAssetUpload";
import {WaihonaUserRef} from "../../../../../domain/user/WaihonaUserRef";
import {Localization} from "../../../../../data/Localization";
import {ResourceSubmissionTextContent} from "../../../../../domain/resources/ResourceSubmissionTextContent";
import {DocumentTextContentService} from "../../../../../services/common/DocumentTextContentService";
import {cloneDeep} from "lodash";
import {ReplaySubject, Subject, Subscription} from "rxjs";
import {classToPlain} from "class-transformer";
import {DocumentTextContent} from "../../../../../domain/DocumentTextContent";
import {PublishedResourceService} from "../../../../../services/PublishedResourceService";
import {EditResourceSubmissionHelperService} from "../../../../../services/EditResourceSubmissionHelperService";
import {EditResourceDataProvider} from "../../../../../domain/resources/EditResourceDataProvider";
import {SupportedLanguage, SupportedLanguages} from "../../../../../domain/SupportedLanguages";
import {isURL} from "class-validator";
import {CropperComponent, ImageType} from "../../../../common/cropper/cropper.component";
import {IFieldMaxLengthMap, ValidationMetadataService} from "../../../../../services/common/ValidationMetadataService";
import {RoleType} from "../../../../../domain/user/Role";
import {Aina, RenderLabelStyle} from 'app/domain/resources/aina/Aina';
import {AinaRef} from "../../../../../domain/resources/aina/AinaRef";
import {PublishedResourceRef} from "../../../../../domain/resources/PublishedResourceRef";
import {ValidationErrorService} from "../../../../../services/common/ValidationErrorService";
import {Collection} from "../../../../../domain/collections/Collection";
import {CollectionRef} from "../../../../../domain/collections/CollectionRef";
import {CollectionService} from "../../../../../services/CollectionService";
import {ScrollingService} from "../../../../../services/common/ScrollingService";

@Component({
	templateUrl: './edit-resource-submission.component.html',
	styleUrls: [
		"./edit-resource-submission.component.scss",
		'../../../../../../scss/vendors/toastr/toastr.scss'
	],
	encapsulation: ViewEncapsulation.None,
	providers: [ResourceSubmissionService, NavigatingComponentDeactivationGuard, ValidationErrorService]
})
export class EditResourceSubmissionComponent extends BaseComponent implements OnInit, OnDestroy, CanComponentDeactivate {

	@ViewChild(LessonAssetsComponent) public lessonAssets:LessonAssetsComponent;

	@ViewChild("cropperComponent")
	public cropperComponent:CropperComponent;
	private cbEditResourceContext = Localization.template.resource.edit;

	//image cropper
	@ViewChild(ImageCropperComponent) imageCropper:ImageCropperComponent;
	public cropper= {
		imageChangedEvent: null, //any
		croppedImage: null, //string
		showCropper: false, //boolean
		newImageHasBeenUploaded:true //boolean
	};

	public view:string = 'preview'; //or edit //TODO: Change to enum

	public editorModules = {
		toolbar: [
			[{'align': []}],
			[{'size': ['small', false, 'large', 'huge']}],  // size
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['blockquote', 'code-block'],

			[{'list': 'ordered'}, {'list': 'bullet'}],
			//[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
			[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

			//[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			['clean'],                                         // remove formatting button

			['link']                         // link
			//['link', 'image']                         // link and image, video
		],
		clipboard: {
			matchVisual: false
		}
	};


	//what line am i on?
	private cbContext = Localization.template.resource.edit;
	private commonContext = Localization.template.common;

	//<editor-fold desc="Content Blocks">
	public CB = {
		oho_field_tooltip: this.commonContext.oho.field.tooltip,
		header: this.cbContext.header,
		required: this.cbContext.required,
		languageToggle_label: this.cbContext.language.languageToggleLabel,
		language_tooltip: this.cbContext.language.tooltip,
		language_switcher: this.cbContext.language.switcher,
		basicInfo_title: this.cbContext.basic_info.title,
		basicInfo_org_selected: this.cbContext.basic_info.organization.selected,
		basicInfo_changeOrg: this.cbContext.basic_info.organization.change_org_label,
		basicInfo_org_placeholder: this.cbContext.basic_info.organization.placeholder,
		basicInfo_org_help: this.cbContext.basic_info.organization.help,
		basicInfo_resource_label: this.cbContext.basic_info.resource.label,
		basicInfo_resource_placeholder: this.cbContext.basic_info.resource.placeholder,
		basic_collab_label: this.cbContext.basic_info.collaborators.label,
		basic_collab_tooltip: this.cbContext.basic_info.collaborators.tooltip,
		basic_collab_placeholder: this.cbContext.basic_info.collaborators.placeholder,
		basic_collab_help: this.cbContext.basic_info.collaborators.help,
		summary_header: this.cbContext.summary.header,
		summary_label: this.cbContext.summary.label,
		summary_tooltip: this.cbContext.summary.tooltip,
		summary_placeholder: this.cbContext.summary.placeholder,
		content_header: this.cbContext.content.header,
		content_label: this.cbContext.content.label,
		content_placeholder: this.cbContext.content.placeholder,
		content_help: this.cbContext.content.help,
		source_header: this.cbContext.source.header,
		source_label: this.cbContext.source.label,
		source_placeholder: this.cbContext.source.placeholder,
		source_help: this.cbContext.source.help,
		modifications_header: this.cbContext.modifications.header,
		modifications_label: this.cbContext.modifications.label,
		modifications_placeholder: this.cbContext.modifications.placeholder,
		modifications_help: this.cbContext.modifications.help,

		cover_header: this.cbContext.cover.header,
		cover_tooltip: this.cbContext.cover.tooltip,
		cover_warning: this.cbContext.cover.warning,

		meta_header: this.cbContext.meta.header,
		meta_label_grade: this.cbContext.meta.labels.grade,
		meta_label_subject: this.cbContext.meta.labels.subject,
		meta_label_culture: this.cbContext.meta.labels.culture,
		meta_label_mode: this.cbContext.meta.labels.mode,
		meta_label_aina: this.cbContext.meta.labels.aina,
		meta_label_series: this.cbContext.meta.labels.series,
		meta_label_tags: this.cbContext.meta.labels.tags,
		meta_placeholder_grade: this.cbContext.meta.placeholders.grade,
		meta_placeholder_subject: this.cbContext.meta.placeholders.subject,
		meta_placeholder_culture: this.cbContext.meta.placeholders.culture,
		meta_placeholder_mode: this.cbContext.meta.placeholders.mode,
		meta_placeholder_aina: this.cbContext.meta.placeholders.aina,
		meta_placeholder_frameworks: this.cbContext.meta.placeholders.frameworks,
		meta_placeholder_tags: this.cbContext.meta.placeholders.tags,
		meta_placeholder_series: this.cbContext.meta.placeholders.series,
		meta_tooltip_grade: this.cbContext.meta.tooltips.grade,
		meta_tooltip_subject: this.cbContext.meta.tooltips.subject,
		meta_tooltip_culture: this.cbContext.meta.tooltips.culture,
		meta_tooltip_mode: this.cbContext.meta.tooltips.mode,
		meta_tooltip_aina: this.cbContext.meta.tooltips.aina,
		meta_tooltip_series: this.cbContext.meta.tooltips.series,
		meta_tooltip_tags: this.cbContext.meta.tooltips.tags,

		frameworks_header: this.cbContext.frameworks.header,

		config_header: this.cbContext.config.header,
		config_switch_fans: this.cbContext.config.switches.fans,
		config_switch_messages: this.cbContext.config.switches.messages,
		config_switch_comments: this.cbContext.config.switches.comments,
		config_switch_public_access: this.cbContext.config.switches.public_access,
		config_switch_ghost: this.cbContext.config.switches.ghost,
		config_tooltips_fans: this.cbContext.config.tooltips.fans,
		config_tooltips_messages: this.cbContext.config.tooltips.messages,
		config_tooltips_comments: this.cbContext.config.tooltips.comments,
		config_tooltips_ghost: this.cbContext.config.tooltips.ghost,
		config_tooltips_public_access: this.cbContext.config.tooltips.public_access,

		asset_header: this.cbContext.assets.header,
		asset_tooltip: this.cbContext.assets.tooltip,
		asset_warning: this.cbContext.assets.warning,
		comments_header: this.cbContext.comments.header,
		comments_label: this.cbContext.comments.label,
		comments_placeholder: this.cbContext.comments.placeholder,
		comments_from_reviewer: this.cbContext.comments.reviewer,
		related_header: this.cbContext.related.header,
		related_tooltip: this.cbContext.related.tooltip,
		related_select_label: Localization.template.common.select_resource_refs.label,
		related_select_plaecholder: Localization.template.common.select_resource_refs.placeholder,
		version_label: this.cbContext.version.label,
		version_tooltip: this.cbContext.version.tooltip,
		review_accepted: this.cbContext.review_tooltips.approved,
		review_declined: this.cbContext.review_tooltips.declined,
		button_accept: Localization.template.buttons.accept,
		button_decline: Localization.template.buttons.decline,
		button_saveImage: Localization.template.buttons.save_Image,
		button_cancel: Localization.template.buttons.cancel,
		button_suggest: Localization.template.buttons.suggest,
		button_suggestNew: Localization.template.buttons.suggest_new,
		button_save: Localization.template.buttons.save,
		button_preview: Localization.template.buttons.preview,
		button_submit: Localization.template.buttons.submit,
		button_publish: Localization.template.buttons.publish,
		button_reset: Localization.template.buttons.reset,
		alert_did_you_know: Localization.template.alert_headers_reusable.did_you_know,
		alert_first_time: this.cbContext.alerts.first_time,
		alert_fanContributions: this.cbContext.alerts.has_fan_contributions,
		alert_language_mismatch_title: Localization.template.alert_headers_reusable.language_mismatch,
		alert_language_mismatch_body: this.cbContext.alerts.language_mismatch.body,
		tooltip_saveBeforePreview: this.cbContext.button_tooltips.preview,
		tooltip_saveBeforeSubmit: this.cbContext.button_tooltips.submit,
		tooltip_saveBeforePublish: this.cbContext.button_tooltips.publish,
		alert_directPublish: this.cbContext.alerts.direct_publish,
		alert_editAsCollaborator: this.cbContext.alerts.edit_as_collaborator,
		link_header: this.commonContext.links.header,
		link_instructions: this.commonContext.links.instructions,
		link_placeholder: this.commonContext.links.placeholder,
		link_button: this.commonContext.links.button,
	};
	//you can clck on the user icons too..
	//</editor-fold>

	//Domain objects
	public dataProvider:EditResourceDataProvider;
	public linkInput:string = "";
	public originalResourceSubmission:ResourceSubmission;
	public waihonaUserRefs:Observable<WaihonaUserRef[]>; //these are other all users as refs that could be tagged as collaborators

	/** temporary arrays that are populated by the user... kept separate from the dataprovider object for UI purposes */
	public selectedRelatedResources:Array<PublishedResourceRef> = [];

	//localization tools
	public currentTextLanguage$:ReplaySubject<SupportedLanguage> = new ReplaySubject<SupportedLanguage>();
	public currentTextLanguagePretty$:ReplaySubject<string> = new ReplaySubject<string>();
	private _currentTextLanguage:SupportedLanguage;
	public localize = {
		resourceRef: (ref:PublishedResourceRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "title"),
		collectionRef: (ref:CollectionRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "title")
	};

	//show or hide alerts = {
	public alerts = {
		hideDirectPublishAlert: false,
		hideSaveAsCollaboratorAlert: false,
		hideFanContentAlert: false,
	};

	//loader booleans
	public loaders = {
		isLoading: false,
		isSaving: false,
		isLoadingPublish: false,
		isLoadingFramework: false //is this needed?
	};

	private _isSavingImage:boolean = false;
	private submitWhenReady:boolean = false;
	private publishWhenReady:boolean = false;

	//language check booleans
	public languageMismatch = {
		firstMessage: false,
		title: false,
		summary: false,
		fullText: false,
		sources: false,
	};
	public allowDirectNavigateAway:boolean = false;

	public maxLengths:IFieldMaxLengthMap<ResourceSubmissionTextContent> = null;

	public constructor(protected injector:Injector,
	                   private publishedResourceService:PublishedResourceService,
	                   private resourceSubmissionService:ResourceSubmissionService,
	                   private waihonaUserService:WaihonaUserService,
	                   private collectionService:CollectionService,
	                   private uploadService:UploadService,
	                   private pathService:PathService,
	                   public urlService:UrlService,
					   public scrollingService:ScrollingService,
	                   private validationMetadataService:ValidationMetadataService,
	                   private analyticsService:AnalyticsService,
	                   private docTextContentService:DocumentTextContentService,
	                   private editResourceSubmissionHelperService:EditResourceSubmissionHelperService
	) {
		super(injector);
		this.localizationService.registerAndLocalize("EditResourceSubmissionComponent", this.CB);

		//We need to show any known errors and make the original clone only on first time the dataProvider finishes loading
		this.editResourceSubmissionHelperService.dataProvider$.take(1).subscribe(dp => {
			this.dataProvider = dp;
			// If we have the site-wide language for this resource, start with that by default.
			this.currentTextLanguage = this.docTextContentService.defaultLanguage(this.dataProvider.resourceSubmission);

			this.originalResourceSubmission = cloneDeep<ResourceSubmission>(this.dataProvider.resourceSubmission);
			this.checkLanguageAllKeys();
		});

		this.editResourceSubmissionHelperService.dataProvider$.subscribe((dp:EditResourceDataProvider) => {
			this.dataProvider = dp;
		});

		const resourceId:string = this.route.snapshot.params['resourceId'] != "" ? this.route.snapshot.params['resourceId'] : null;

		this.logger.log(`EditResourceSubmissionComponent::${this.route.snapshot.data['action']}, resourceId: ${resourceId}`);

		this.editResourceSubmissionHelperService.initialize(resourceId).take(1).subscribe((dp:EditResourceDataProvider) => {
			// only warmup auto-publish functions if autoPublish setting is true
			if (dp.orgConfig.autoPublishResources) {
				console.log("autoPublish setting: " + dp.orgConfig.autoPublishResources);
				let warmUpSub1:Subscription = this.publishedResourceService.warmUpPublishResourceFromDraft$()
					.take(1).subscribe(() => warmUpSub1.unsubscribe());
				this.trackSubscription(warmUpSub1);
				let warmUpSub2:Subscription = this.publishedResourceService.warmUpPublishFilesFromDraft$()
					.take(1).subscribe(() => warmUpSub2.unsubscribe());
				this.trackSubscription(warmUpSub2);
			}
		});

		//get all the other users that I can select from as potential collaborators
		this.waihonaUserRefs = this.waihonaUserService.watchListAsRef$();

		this.maxLengths = this.validationMetadataService.getMaxLengths(ResourceSubmissionTextContent);
		console.log(this.maxLengths);
	}

	public ngOnInit() {
		//todo: rewrite with index property decorator and remove from here
		/*this.trackSubscription(this.resourceSubmissionService.getAllResourcesBySubmitterId$(this.currentUser.guid).subscribe(resources => {
			this.languageMismatch.firstMessage = (resources.length == 0);
			console.info(`this ${this.languageMismatch.firstMessage ? "is" : "is not"} the user's first resource!`);
		}));*/
	}

	public ngOnDestroy():void {
		super.ngOnDestroy();
		this.isActivityStreamVisible = false;

		if (this.isUploading && this.lessonAssets) {
			for (let i:number = 0; i < this.lessonAssets.uploads.length; i++) {
				let upload:LearningAssetUpload = this.lessonAssets.uploads[i];
				upload.cancel();
			}
		}
	}

	public get ImageType() {
		return ImageType;
	}

	public get isSavingImage():boolean {
		return this._isSavingImage;
	}
	public set isSavingImage(newValue:boolean) {
		let originalValue = this._isSavingImage;
		this._isSavingImage = newValue;

		if (originalValue === true && newValue === false) {
			if (this.publishWhenReady === true) {
				this.publishWhenReady = false;
				this.onDirectPublish();
			} else if (this.submitWhenReady === true) {
				this.submitWhenReady = false;
				this.onSubmit();
			}
		}
	}

	public get SupportedLanguage() {
		return SupportedLanguage;
	}

	public get currentTextLanguage() {
		return this._currentTextLanguage;
	}

	public set currentTextLanguage(target:SupportedLanguage) {
		this._currentTextLanguage = target;
		if (!this.dataProvider.resourceSubmission.documentTextContent[this._currentTextLanguage]) { //if doesnt exist create it.
			this.dataProvider.resourceSubmission.documentTextContent[this._currentTextLanguage] = new ResourceSubmissionTextContent();
		}

		this.currentTextLanguage$.next(this._currentTextLanguage);
		this.currentTextLanguagePretty$.next(SupportedLanguages.toPretty(this._currentTextLanguage));

		console.log("current text language: " + this.currentTextLanguage);
	}
	public get currentLanguageTextContent():ResourceSubmissionTextContent {
		return this.dataProvider.resourceSubmission.documentTextContent[this._currentTextLanguage];
	}

	public get descriptionPlainText():string {
		return this.validationMetadataService.getPlainText(this.currentLanguageTextContent.description);
	}

	public get notesPlainText():string {
		return this.validationMetadataService.getPlainText(this.currentLanguageTextContent.notes);
	}

	public get ResourceSubmissionTextContent():(new() => {}) {
		return ResourceSubmissionTextContent;
	}

	public get isEditingAsOwner():boolean {
		return this.dataProvider.resourceSubmission.submitter.guid == this.currentUser.guid;
	}

	public get isEditingAsEditor():boolean {
		let isAWaihonaAdmin:boolean = this.authService.currentUser.roles.find((role) => {
			return role.type ==  RoleType.waihonaAdmin
		}) != null;

		let isALegitimateEditor:boolean = isAWaihonaAdmin && !this.isEditingAsOwner;
		return isALegitimateEditor;
	}

	public get shouldBeDisabled():boolean {
		return this.dataProvider.resourceSubmission.version.isNew || (!this.isEditingAsOwner && !this.isEditingAsEditor);
	}

	public get isPreviouslyPublished():boolean {
		return this.dataProvider.resourceSubmission.version.hasBeenPublishedPreviously;
	}

	public get showDisabledTooltip():boolean {
		return !this.shouldBeDisabled;
	}

	public get isAbleToChangeOrg():boolean {
		return (this.isEditingAsOwner && this.dataProvider.listOfSelectableOrgRefs.length > 1 && (this.dataProvider.resourceSubmission.version.isNew || this.dataProvider.resourceSubmission.version.hasBeenSavedNotSubmitted));
	}

	public get isUploading():boolean {
		if (this.lessonAssets) {
			console.log(`Currently uploading ${this.lessonAssets.uploads.length} files.`);
			return this.lessonAssets.uploads.length > 0;
		}
		return false;
	}
	public toAina(value:AinaRef):Aina {
		return this.dataProvider.ainaList.find(aina => {
			return aina.guid == value.guid;
		})
	}

	public get RenderLabelStyle() {
		return RenderLabelStyle;
	}
	public get AnalyticsContext() {
		return this.analyticsService.data.resource;
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}

	public get isActivityStreamVisible():boolean {
		return !document.querySelector('body').classList.contains('aside-menu-hidden');
	}

	public set isActivityStreamVisible(isVisible:boolean) {
		if (isVisible) {
			if (!this.isActivityStreamVisible) {
				document.querySelector('body').classList.toggle('aside-menu-hidden');
			}
		} else if (!isVisible) {
			if (this.isActivityStreamVisible) {
				document.querySelector('body').classList.toggle('aside-menu-hidden');
			}
		}
	}

	public changeOrg():void {
		this.logger.info(`the user changed the selected org to: ${this?.dataProvider?.resourceSubmission?.organization?.guid}`);
		if (this.dataProvider.resourceSubmission.organization) {
			this.editResourceSubmissionHelperService.getConfig();
		} else {
			this.logger.error("No organization selected");
		}
	}

	public canDeactivate():boolean {
		if(this?.dataProvider?.resourceSubmission == null || this.allowDirectNavigateAway) {
			console.log("ResourceSubmission is null.  Allowing Navigation.");
			return true;
		}
		//Dumb hack see March 10, 2019 fix-later slack for JIRA #: "quill editor inserts dumb spaces and we needed to ignore these fields..find out why/fix later.."
		let ctp = classToPlain;
		let formerAsCopy:{documentTextContent:DocumentTextContent<ResourceSubmissionTextContent>} = classToPlain(this?.dataProvider?.resourceSubmission) as any as {documentTextContent:DocumentTextContent<ResourceSubmissionTextContent>};
		let latterAsCopy:{documentTextContent:DocumentTextContent<ResourceSubmissionTextContent>} = classToPlain(this?.originalResourceSubmission) as any as {documentTextContent:DocumentTextContent<ResourceSubmissionTextContent>};

		let problemFields:Array<string> = ['description','notes'];
			problemFields.map(field => {
				if(formerAsCopy.documentTextContent.en) {
					delete formerAsCopy.documentTextContent.en[field];
				}
				if(formerAsCopy.documentTextContent.haw) {
					delete formerAsCopy.documentTextContent.haw[field];
				}
				if(latterAsCopy.documentTextContent.en) {
					delete latterAsCopy.documentTextContent.en[field];
				}
				if(latterAsCopy.documentTextContent.haw) {
					delete latterAsCopy.documentTextContent.haw[field];
				}
			});

		let former:string = JSON.stringify(formerAsCopy);
		let latter:string = JSON.stringify(latterAsCopy);
		// console.log(`former:${former}`);
		// console.log(`latter:${latter}`);

		let isEqual:boolean = former == latter;

		if(!isEqual || this.isUploading) {
			if(this.isUploading) {
				console.log("Is uploading.  Not allowing Navigation.");
			} else {
				console.log("They changed something.  Not allowing Navigation.");
			}
			return false;
		}
		console.log("Nothing changed.  Allowing navigation");
		return true;
	}


	public getReviewField(fieldName:string):ReviewFieldApprovalStatus {
		if (this.dataProvider.review) {
			return this.dataProvider.review.reviewFields.find(reviewField => {
				return (reviewField.field == fieldName);
			});
		}
	}

	public sectionWasApproved(fieldName:string):boolean {
		let reviewField:ReviewFieldApprovalStatus = this.getReviewField(fieldName);
		if (reviewField != null) {
			switch (reviewField.reviewApprovalStatus) {
				case ReviewStatus.approved:
					return true;
				case ReviewStatus.declined:
					return false;
			}
		} else {
			return null;
		}
	}

	public reviewStatusText(fieldName:string):string {
		let reviewField:ReviewFieldApprovalStatus = this.getReviewField(fieldName);
		if (reviewField && reviewField.reviewerComment) {
			return "Reviewer comments: " + reviewField.reviewerComment;
		} else {
			return null;
		}
	}

	public onDirectPublish() {
		this.logger.info(`Bypassing review and publishing directly on behalf of ${this.dataProvider.resourceSubmission.organization.guid}`);
		this.loaders.isLoadingPublish = true;

		if (this.isSavingImage === true) {
			// saving an image right now, so set the publishWhenReady flag and resume publish once image is saved.
			this.publishWhenReady= true;
			console.log("StorageImage is still saving, resuming publish once complete...");
			return;
		} else {
			// not saving an image, make sure publishWhenReady flag is deactivated, then continue
			this.publishWhenReady = false;
		}

		try {
			this.analyticsService.emit(this.AnalyticsContext.create.click());
			this.logger.info(`running validation on new submission`);
			let resourceSubmission = this.dataProvider.resourceSubmission;

			this.validationErrorService.validateTextContentObject(resourceSubmission);
			this.publishedResourceService.pendingPublishes[resourceSubmission.guid] = resourceSubmission.version.major;

			//publish it, lets go....
			let s:Subscription = this.trackSubscription(
				this.publishedResourceService.publishResourceSubmissionNoReview(resourceSubmission, this.dataProvider.selectedCollections).subscribe(pubResource => {
					s.unsubscribe();
					this.loaders.isLoadingPublish = false;
					this.router.navigate(['/resources',resourceSubmission.guid]);
				})
			);
			this.originalResourceSubmission = cloneDeep<ResourceSubmission>(this.dataProvider.resourceSubmission);
			
		} catch (ex) {
			if (ex instanceof ValidationException) {
				// clear validation warnings
				this.validationErrorService.clearWarnings();
				this.validationErrorService.updateErrors(ex.errors);

				this.notificationService.displayToast(this.cbContext.toasts.direct_publish.fail, ToastType.error);
			} else {
				this.logger.error(ex);
			}
			this.loaders.isLoadingPublish = false;
			return;
		}

		// clear validation errors
		this.validationErrorService.clearErrors();
	}

	public onSubmit():void {
		this.logger.info(`this resource is being submitted to ${this.dataProvider.resourceSubmission.organization.guid} for review.`);

		if (this.isSavingImage === true) {
			// saving an image right now, so set the submitWhenReady flag and resume submit once image is saved.
			this.submitWhenReady= true;
			console.log("StorageImage is still saving, resuming submit once complete...");
			return;
		} else {
			// not saving an image, make sure submitWhenReady flag is deactivated, then continue
			this.submitWhenReady = false;
		}

		try {
			//TODO: We have more states than this, this is okay, but before they can submit we need to guard the page...(kalani)
			//TODO: KALANI THIS IS TRRIBLE CODE!  why is resourceSubmission.status null, please fix...thank you, Sincerly, Kalani      PS - your spelling check works USE IT
			let shittyCodeFix:boolean = this.dataProvider.review && this.dataProvider.review.resourceSubmission.status == ResourceSubmissionStatus.in_review_revision;
			let checkVersion:boolean = this.dataProvider.review && this.dataProvider.review.version.minor > 1;
			if (this.dataProvider.resourceSubmission.status == ResourceSubmissionStatus.in_review_revision || shittyCodeFix || checkVersion) {
				this.logger.info(`this submission is a revision`);
				this.analyticsService.emit(this.AnalyticsContext.revise.click());
				this.logger.info(`running validation on revision`);
				this.validationErrorService.validateTextContentObject(this.dataProvider.resourceSubmission);
				this.resourceSubmissionService.submitRevision(this.dataProvider.review, this.dataProvider.resourceSubmission, this.dataProvider.commentsForReviewer);

			} else {
				this.logger.info(`this is a new submission`);
				this.analyticsService.emit(this.AnalyticsContext.create.click());
				this.logger.info(`running validation on new submission`);
				this.validationErrorService.validateTextContentObject(this.dataProvider.resourceSubmission);
				let onComplete$:Subject<boolean> = new Subject<boolean>();
				let s10:Subscription = this.trackSubscription(onComplete$.subscribe((complete:boolean) => {
					s10.unsubscribe();
					this.canDeactivate()
					this.allowDirectNavigateAway = true;
					this.notificationService.displayToast(this.cbEditResourceContext.toasts.submit.success, ToastType.success);
					this.router.navigate(['/resources/users', this.dataProvider.resourceSubmission.submitter.guid]);
				}));
				this.resourceSubmissionService.submit(this.dataProvider.resourceSubmission, onComplete$, this.dataProvider.commentsForReviewer);
				this.originalResourceSubmission = cloneDeep<ResourceSubmission>(this.dataProvider.resourceSubmission);
			}
		} catch (ex) {
			if (ex instanceof ValidationException) {
				// clear validation warnings
				this.validationErrorService.clearWarnings();
				this.validationErrorService.updateErrors(ex.errors);

				this.notificationService.displayToast(this.cbContext.toasts.submit.fail, ToastType.error);
			} else {
				this.logger.error(ex);
			}
			return;
		}

		// clear validation errors
		this.validationErrorService.clearErrors();
	}


	public onSave():void {
		this.logger.info("on save");
		this.loaders.isSaving = true;

		// clear validation errors and warnings
		this.validationErrorService.clearErrors();
		this.validationErrorService.clearWarnings();

		try {
			this.logger.info(`running validation on save`);
			this.validationErrorService.validateTextContentObject(this.dataProvider.resourceSubmission, null, {groups: ['requiredToSave']});
		} catch (ex) {
			if (ex instanceof ValidationException) {
				this.logger.info(`validation errors found and returned in view`);
				this.validationErrorService.updateErrors(ex.errors);
				this.notificationService.displayToast(this.cbContext.toasts.save.fail, ToastType.error);
				// case 1: resource submission has fatal validation errors that prevent saving (empty title, empty org).
				this.loaders.isSaving = false;
			} else {
				this.logger.error(ex);
			}
			return;
		}

		try {
			this.validationErrorService.validateTextContentObject(this.dataProvider.resourceSubmission);
			this.notificationService.displayToast((this.dataProvider.orgConfig.autoPublishResources ? this.cbContext.toasts.save_to_publish.success : this.cbContext.toasts.save.success), ToastType.info);
			// case 2: all good. resource submission is completely valid and can be saved.
		} catch (ex) {
			if (ex instanceof ValidationException) {
				this.logger.info(`validation warnings found and returned in view`);
				this.validationErrorService.updateWarnings(ex.errors);
				this.notificationService.displayToast((this.dataProvider.orgConfig.autoPublishResources ? this.cbContext.toasts.save_to_publish.warning : this.cbContext.toasts.save.warning), ToastType.warning);
				// case 3: resource submission has some validation errors, but no fatal errors that should prevent saving. still can be saved.
			} else {
				this.logger.error(ex);
			}
		}

		this.trackSubscription(this.resourceSubmissionService.save(this.dataProvider.resourceSubmission).subscribe(draft => {
			this.loaders.isSaving = false;
			//set the folder path
			this.dataProvider.assetsFolderPath = this.pathService.storage.resourceFolder.assetsFolder.draft(draft.guid);
			console.info(`The folder path is ready!`);

			this.dataProvider.resourceSubmission = draft;
			this.originalResourceSubmission = cloneDeep<ResourceSubmission>(this.dataProvider.resourceSubmission);
			this.logger.info(`Saved resource submission`);

			try {
				this.router.navigate(["/resources/users", this.dataProvider.resourceSubmission.submitter.guid, this.dataProvider.resourceSubmission.guid, "edit"]).then(
					(result:boolean) => {
						if (result) {
							this.logger.info("navigated to resource edit: " + result);
						} else {
							this.logger.info("no need to navigate");
						}
					}
				);
			} catch (routerErr) {
				console.error("could not navigate to resource edit: " + routerErr);
			}

		}));
	}

	public onPreview() {
		if (this.dataProvider.resourceSubmission.version.isNew) {
			this.logger.error("Must save before navigating to preview");
			return;
		}
		try {
			this.router.navigate(["/resources/users", this.dataProvider.resourceSubmission.submitter.guid, this.dataProvider.resourceSubmission.guid]).then(
				(result) => { this.logger.info("navigated to preview: " + result)}
			);
		} catch (routerErr) {
			console.error("could not navigate to preview: " + routerErr);
		}
	}

	public switch(view:string) {
		switch (view) {
			case 'preview':
				// if(this.resourceSubmission.version.minor > 1) {
				this.router.navigate(["/resources/users", this.dataProvider.resourceSubmission.submitter.guid, this.dataProvider.resourceSubmission.guid]);
				//}
				break;
		}
	}

	public hasGuid():boolean {
		return this.dataProvider.resourceSubmission.guid != null;
	}

	public onAssetsChanged() {
		this.logger.info("Assets were changed");
		this.resourceSubmissionService.save(this.dataProvider.resourceSubmission);
	}

	public checkLanguageInput(text:string, language:SupportedLanguage, key:string):void {
		this.languageMismatch[key] = false; //reset the boolean
		if (text) {
			console.info(`checking language string ${key}`);
			this.languageMismatch[key] = !this.localizationService.isValidLanguageInput(text, language);
		}
	}

	public get prettyLanguage():string {
		if (this.currentTextLanguage == SupportedLanguage.haw) {
			return "ʻŌlelo Hawaiʻi"
		} else if (this.currentTextLanguage == SupportedLanguage.en) {
			return "English"
		}
	}

	public get oppositeLanguage():string {
		if (this.currentTextLanguage == SupportedLanguage.haw) {
			return "English";
		} else if (this.currentTextLanguage == SupportedLanguage.en) {
			return "ʻŌlelo Hawaiʻi";
		}
	}

	public checkLanguageAllKeys():void {
		console.info(`checking all keys for language mismatch`);
		let keys:Array<string> = ["title", "summary", "fullText", "sources"];
		if (this.currentLanguageTextContent) {
			for (let key of keys) {
				this.checkLanguageInput(this.currentLanguageTextContent[key], this.currentTextLanguage, key);
			}
		}
	}

	public addLink():void {
		//let isValid:boolean = isURL(this.linkInput);
		let isValid:boolean = isURL(this.linkInput);
		if (isValid) {
			this.dataProvider.resourceSubmission.links.push(this.linkInput);
			this.linkInput = "";
		} else {
			this.notificationService.displayToast(this.cbContext.toasts.add_link.fail, ToastType.error);
		}
	}

	public removeLink(link:string):void {
		let index = this.dataProvider.resourceSubmission.links.indexOf(link);
		if (index > -1) {
			this.dataProvider.resourceSubmission.links.splice(index, 1);
		}
	}

	public uploadCover(base64PngImage:string):void {
		this.isSavingImage = true;
		let imageUploading$:Observable<string> = this.uploadService.uploadCropperImage(ImageType.draft, base64PngImage, this.dataProvider.resourceSubmission).filter(downloadUrl => !!downloadUrl);
		let s:Subscription = this.trackSubscription(imageUploading$.subscribe(downloadUrl => {
			s.unsubscribe();
			this.cropperComponent.url = downloadUrl;
			let s2:Subscription = this.trackSubscription(this.resourceSubmissionService.updateCover(this.dataProvider.resourceSubmission.guid, true).subscribe((value)=>{
				s2.unsubscribe();
				this.dataProvider.resourceSubmission.hasImage = value.hasImage;
				console.log("Database has been told cover has been updated.");
				this.isSavingImage = false;
			}));
		}));
	}

	public notifyOnFail():void {
		this.notificationService.displayToast(Localization.template.common.cropper.upload.fail, ToastType.error);
	}

	public addSelectedCollections(collections:Array<Collection>):void {
		this.dataProvider.selectedCollections = collections;
		this.dataProvider.filteredCollections = this.dataProvider.selectableCollections.filter(collection => (this.dataProvider.selectedCollections.find(collection2 => collection2.guid == collection.guid) == null));
	}

	public removeSelectedCollections(collection:Collection):void {
		let collections:Array<Collection> = this.dataProvider.selectedCollections;
		let index:number = collections.indexOf(collection);
		collections.splice(index, 1);
		this.dataProvider.selectableCollections = this.dataProvider.selectedCollections.filter(collection => (this.dataProvider.selectedCollections.find(collection2 => collection2.guid == collection.guid) == null));
	}

	public addRelatedResources(refs:Array<PublishedResourceRef>):void {
		let relatedResources:Array<PublishedResourceRef> = this.dataProvider.resourceSubmission.relatedResources;
		relatedResources.push(...refs);
		this.selectedRelatedResources = [];
		// make selectableResourceRefs all the resources that aren't already chosen as relatedResources
		this.dataProvider.selectableResourceRefs = this.dataProvider.resourceRefs.filter(ref => (relatedResources.find(ref2 => ref2.guid == ref.guid) == null));
	}

	public removeRelatedResource(resource:PublishedResourceRef):void {
		let relatedResources:Array<PublishedResourceRef> = this.dataProvider.resourceSubmission.relatedResources;
		let index:number = relatedResources.indexOf(resource);
		relatedResources.splice(index, 1);
		// make selectableResourceRefs all the resources that aren't already chosen as relatedResources
		this.dataProvider.selectableResourceRefs = this.dataProvider.resourceRefs.filter(ref => (relatedResources.find(ref2 => ref2.guid == ref.guid) == null));
	}

	public resourceRefTitle(resourceRef:PublishedResourceRef):string {
		return this.localizationService.LocalizeTools.ref(resourceRef.localization, this.localizationService.language, "title");
	}

	public resourceRefSubmitterName(resourceRef:PublishedResourceRef):string {
		if (resourceRef.configuration?.hideContributorName) {
			return resourceRef.organization.title;
		} else {
			return (resourceRef.submitter.altFirstName ? resourceRef.submitter.altFirstName : resourceRef.submitter.firstName) + " " + resourceRef.submitter.lastName;
		}
	}

	public scrollToId(id:string) {
		this.scrollingService.scrollTo(id);
	}

}
