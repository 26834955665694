import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'language-mismatch-alert',
  templateUrl: './language-mismatch-alert.component.html',
  styleUrls: ['./language-mismatch-alert.component.scss']
})
export class LanguageMismatchAlertComponent implements OnInit {

	@Input()
	public languageMismatch:{[key:string]:boolean};

	@Input()
	public key:string;

	@Input()
	public title:string;

	@Input()
	public body:string;

	@Input()
	public alertClass:string = "";

	@Input()
	public iconClass:string = "";

    constructor() { }

    ngOnInit() { }

    public get hasLanguageMismatch():boolean {
    	return this.languageMismatch[this.key];
    }

    public dismiss():void {
	    this.languageMismatch[this.key] = false;
    }

}
