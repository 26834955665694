import {Review} from "../domain/resources/review/Review";
import {Observable} from "rxjs/Observable";
/*import {SchoolService} from "./SchoolService";
import {NotificationService} from "./NotificationService";*/
import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {forwardRef, Inject, Injectable, InjectFlags, Injector} from "@angular/core";
import {AuthService} from "./AuthService";
import {AngularFireStorage} from "@angular/fire/storage";
import {AngularFirestore} from "@angular/fire/firestore";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../domain/user/WaihonaUserOrganization";
import {forkJoin} from "rxjs";
import {OrganizationService} from "./OrganizationService";
import {PublishedResource} from "../domain/resources/PublishedResource";

import {classToClass, classToPlain} from "class-transformer";
import {ReviewRepository} from "./repository/ReviewRepository";
import {PublishedResourceService} from "./PublishedResourceService";
import {ReviewFieldApprovalStatus, ReviewStatus} from "../domain/resources/review/ReviewFieldApprovalStatus";
import {WaihonaUserService} from "./WaihonaUserService";
import {ConversionService} from "./ConversionService";
import {ResourceSubmissionService} from "./ResourceSubmissionService";
import {ActivityStream, ActivityType} from "../domain/resources/ActivityStream";
import {ActivityEntry} from "../domain/resources/ActivityEntry";
import {ResourceChatService} from "./ResourceChatService";
import {InboxService} from "./InboxService";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {UrlService} from "./UrlService";
import {NGXLogger} from "ngx-logger";
import {LocalizationService} from "./LocalizationService";
import {AngularFireFunctions} from "@angular/fire/functions";
import {ActivityStreamService} from "./ActivityStreamService";
import {ResourceSubmissionTextContent} from "../domain/resources/ResourceSubmissionTextContent";


@Injectable({
    providedIn: 'root',
} as any)
export class ReviewService {

    private _resourceSubmissionService:ResourceSubmissionService;
    private _resourceChatService:ResourceChatService;

    public repo = {
		getAllReviewsForOrganization$: (orgGuid:string):Observable<Review[]> => this.repository.getAllReviewsForOrganization$(orgGuid),
		getAllReviewsForOrganizationWhereStatus$: (orgGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> => this.repository.getAllReviewsForOrganizationWhereStatus$(orgGuid, status),
		listReviewsBySubmitterWithStatus$: (submitterGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> => this.repository.listReviewsBySubmitterWithStatus$(submitterGuid, status),
		listReviewsByOrgAndReviewerWithStatus$: (orgGuid:string, reviewerGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> =>this.repository.listReviewsByOrgAndReviewerWithStatus$(orgGuid, reviewerGuid, status)
	};

	private activityStreamActions = {
		logRecalled: (activityStream, review) =>
			activityStream.addEntry(ActivityType.RECALLED_TO_DRAFT, "", review.version).with(review.resourceSubmission.submitter),

		logSaved: (activityStream, review) =>
			activityStream.addEntry(ActivityType.SAVED, "", review.version).with(review.resourceSubmission.submitter),

		logApproved: (activityStream:ActivityStream, review:Review, optionalDetail?:string, optionalComments?:string) => {
			activityStream.addEntry(ActivityType.APPROVED, optionalDetail, review.version).with(this.authService.currentUserRef);
			this.addCommentToReview(activityStream, review.resourceSubmission, optionalComments);
		},

		logPublished: (activityStream, review) =>
			activityStream.addEntry(ActivityType.PUBLISHED, "", review.version).with(this.authService.currentUserRef),

		logDeclined: (activityStream:ActivityStream, review:Review, optionalDetail?:string, optionalComments?:string) => {
			activityStream.addEntry(ActivityType.DECLINED, optionalDetail, review.version).with(this.authService.currentUserRef);
			this.addCommentToReview(activityStream, review.resourceSubmission, optionalComments);
		}
	}

    public constructor(private authService: AuthService,
               private storage: AngularFireStorage,
               private db: AngularFirestore,
               private organizationService:OrganizationService,
               private repository:ReviewRepository,
               private injector:Injector,
               private publishedLessonService:PublishedResourceService,
               private activityStreamService:ActivityStreamService,
               private conversionService:ConversionService,
               private waihonaUserService:WaihonaUserService,
               private inboxService:InboxService,
               protected urlService:UrlService,
               private localizationService:LocalizationService,
               private angularFireFunctions:AngularFireFunctions,
                       protected logger:NGXLogger) {

    }

    private get resourceSubmissionService():ResourceSubmissionService {
        if(!this._resourceSubmissionService) {
            this._resourceSubmissionService = this.injector.get<ResourceSubmissionService>(ResourceSubmissionService);
        }
        return this._resourceSubmissionService;
    }

    private get resourceChatService():ResourceChatService {
        if(!this._resourceChatService) {
            this._resourceChatService = this.injector.get<ResourceChatService>(ResourceChatService);
        }
        return this._resourceChatService;
    }

    public receiveSubmission(lessonSubmission:ResourceSubmission):Review {
        let review:Review = new Review();
            review.resourceSubmission = lessonSubmission;
            review.resourceSubmission.guid = lessonSubmission.guid;

            review.reset();
            review.guid = lessonSubmission.guid;
            review.epochLastSubmissionDate = new Date().getTime();

        if(review.reviewFields.length == 0) {
            this.addReviewFieldsToReview(review);
        }
        this.resourceChatService.initializeChat(review.guid);
        this.repository.save$(review);
        return review;
    }

    private addReviewFieldsToReview(review:Review):void {
        review.reset();

        let reviewTitleBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewTitleBlock.field = "titleBlock";
        let reviewMetaBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewMetaBlock.field = "metaBlock";
        let reviewSummaryBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewSummaryBlock.field = "summaryBlock";
        let reviewDescriptionBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewDescriptionBlock.field = "descriptionBlock";
        let reviewSourcesBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewSourcesBlock.field = "sourcesBlock";
        let reviewModificationsBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewModificationsBlock.field = "modificationsBlock";
        let reviewCoverImgBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
        reviewCoverImgBlock.field = "coverImgBlock";
        let reviewLearningAssetsBlock:ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewLearningAssetsBlock.field = "learningAssetsBlock";

        review.reviewFields = [reviewTitleBlock, reviewMetaBlock, reviewSummaryBlock, reviewDescriptionBlock, reviewSourcesBlock, reviewModificationsBlock, reviewCoverImgBlock, reviewLearningAssetsBlock];
    }
    public receiveSubmissionRevision(review:Review):void {
	    this.organizationService.getOrganization$(review.resourceSubmission.organization.guid).subscribe(organization => {
	    	let reviewers:Array<WaihonaUserRef> = organization.kahu;
	    	let reviewTitle = this.localizationService.LocalizeTools.document(review.resourceSubmission, ResourceSubmissionTextContent).title;
	    	let subject:string = `${reviewTitle} has been revised.`;
	    	let url:string = this.urlService.resourcePage.review(review.guid);

	    	let body:string = `${reviewTitle} by ${review.resourceSubmission.submitter.fullName} has been revised.  Please check it out at <a href='${url}'>${url}</a>.`;
	    	this.inboxService.sendMessage(reviewers, subject, body);

	    });
        //TODO: Clean up fields
        this.logger.info("receiveSubmissionRevision: Before conversion to ActivityEntry.");
        //let activityEntry:ActivityEntry = this.conversionService.convert(review, ActivityEntry);
        //    this.logger.info("activityEntry: " + JSON.stringify(activityEntry));

        if(review.reviewFields.length == 0) {
            this.addReviewFieldsToReview(review); //fixing one that wasnʻt any good.
        }
        review.resourceSubmission.guid = review.guid;

        this.logger.info("receiveSubmissionRevision: After conversion to ActivityEntry.");
        //review.activityStream.entries.push(activityEntry);
        this.cycleReviewFieldsOnReview(review);

        //Review fields should stay the same as the last time
        this.resourceChatService.initializeChat(review.guid);
        this.repository.save$(review);
        //TODO: on server side, add a trigger function to listen for review update
    }
    /** Cycle the review comments backward */
    private cycleReviewFieldsOnReview(review:Review):void {
        for(let i:number = 0; i < review.reviewFields.length; i++) {
            let reviewField:ReviewFieldApprovalStatus = review.reviewFields[i];
            reviewField.formerReviewerComment = reviewField.reviewerComment;
            reviewField.reviewerComment = "";
        }
    }

    private addCommentToReview(activityStream:ActivityStream, resourceSub:ResourceSubmission, optionalComment?:string):void {
	    if (optionalComment != null && optionalComment != "" && resourceSub != null && resourceSub.guid != null) {
		    activityStream.addEntry(ActivityType.REVIEWER_COMMENT, optionalComment, resourceSub.version).with(this.authService.currentUserRef);
		    this.resourceChatService.sendMessageToChat(resourceSub.guid, optionalComment);
	    }
    }

    public saveReview$(review:Review):void {
        this.repository.save$(review);
    }

    public listReviewsByReviewer$(reviewerGuid:string):Observable<Review[]> {
        return this.repository.listReviewsByReviewer$(reviewerGuid);
    }

	public filterByStatus(reviews:Array<Review>, status:ResourceSubmissionStatus):Array<Review> {
		return reviews.filter(review => {
			return review.resourceSubmission.status == status;
		});
	}
    public listReviewsBySubmitter$(submitterGuid:string, status?:ResourceSubmissionStatus):Observable<Review[]> {
        return this.repository.listReviewsBySubmitter$(submitterGuid);
    }


    /** Get failed reviews that are awaiting submitter changes*/
    /* public getFailedReviewsBySubmitter$(submitterGuid:string):Observable<Review[]> {
         return this.repository.listReviewsBySubmitter$(submitterGuid)
    }*/


    /** Get all reviews for a user (not started, in progress, declines (or back to submitter), ... basically until it has received its final approval */
    public getAllReviewsForUsersOrganizations$(waihonaUser:WaihonaUser):Observable<Review[]> {
        //TODO: Verify with the organization that the user has the rights to review (for now this only really affects view)
        let matchingActiveOrganizations:WaihonaUserOrganization[] = waihonaUser.organizations.filter(organization => {
                return organization.currentlyAt && !organization.pendingApproval;
        });
        let allOrgObservables:Array<Observable<Review[]>> = [];

        for(let i:number = 0; i < matchingActiveOrganizations.length; i++) {
            let iOrg:WaihonaUserOrganization = matchingActiveOrganizations[i];
            let iOrgGuid:string = iOrg.organizationGuid;
            this.repo.getAllReviewsForOrganization$(iOrgGuid);
                allOrgObservables.push();
        }

        let allUsersBelongingToTheSameOrganizationsTheUserIsAPartOf:Observable<Review[]> = forkJoin(...allOrgObservables)
        //Just keeps orgs separated
        return allUsersBelongingToTheSameOrganizationsTheUserIsAPartOf.map((data) => {
            data.sort((a, b) => {
                return a.resourceSubmission.guid < b.resourceSubmission.guid ? -1 : 1;
            });
            return data;
        });
    }

    public getReview$(reviewGuid:string):Observable<Review> {
        //Make sure Guid gets set on review
        if (reviewGuid) {
            return this.repository.get$(reviewGuid).filter(review => review != null).map(review => {
                review.resourceSubmission.guid = review.guid;
                return review;
            });
        }
    }


    public listReviewsByReviewerWithStatus$(reviewerGuid:string, status:ResourceSubmissionStatus):Observable<Review[]> {
        return this.repository.listReviewsByReviewerWithStatus$(reviewerGuid, status);
    }


    public recallSubmission(review:Review) {
        review.resourceSubmission.status = ResourceSubmissionStatus.draft;
	    this.activityStreamService.getActivityStream$(review.guid).subscribe((activityStream:ActivityStream) => {
		   this.activityStreamService.logToActivityStream$(activityStream, review, this.activityStreamActions.logRecalled).take(1).subscribe(() => {
			   review.resourceSubmission.guid = review.guid;
			   this.resourceSubmissionService.save(review.resourceSubmission);
			   this.repository.delete$(review);
		   })
	    });
    }

    public cancelReview(review:Review) {
    //    todo: do we still need this?
    }

    public startReview(review:Review) {
        //todo: throw an error if this.authService.currentUser == null
        review.resourceSubmission.status = ResourceSubmissionStatus.in_review;
        review.startedReview = new Date().getTime();
	    this.activityStreamService.getActivityStream$(review.guid).subscribe((activityStream:ActivityStream) => {
		    this.activityStreamService.logToActivityStream$(activityStream, review, this.activityStreamActions.logSaved).take(1).subscribe(() => {
			    if (review.reviewers.indexOf(this.authService.currentUserRef) == -1) {
				    review.reviewers.push(this.authService.currentUserRef);
			    }

			    review.resourceSubmission.guid = review.guid;
			    this.resourceSubmissionService.save(review.resourceSubmission); //TODO: should we be using the resourceSubmission on review object to update the draft or modify the original draft instead?

			    this.repository.save$(review);
		    });
	    });

    }

    /** Approve a review and make it public */
    public approveReview(review:Review, optionalComments?:string):void {
        review.resourceSubmission.status = ResourceSubmissionStatus.published;
        review.completedReview = new Date().getTime();
        review.resourceSubmission.approved = new Date().getTime();
        review.resourceSubmission.version.nextMajor();
        review.resourceSubmission.guid = review.guid;

        let approvedDetail:string = optionalComments != null ? `${optionalComments}`: "";
        for(let i:number = 0; i < review.reviewFields.length; i++) {
            let iReviewField:ReviewFieldApprovalStatus = review.reviewFields[i];
            if (this.getReviewStatusText(iReviewField)!=ReviewStatus.not_set) {
	            let reviewFieldComment:string = `${this.getReviewStatusText(iReviewField)}`;
	            approvedDetail += reviewFieldComment;
            }
        }

	    this.activityStreamService.getActivityStream$(review.guid).subscribe((activityStream:ActivityStream) => {
		    this.activityStreamService.logToActivityStream$(activityStream, review, this.activityStreamActions.logApproved, optionalComments, approvedDetail).take(1).subscribe(() => {
			    this.publishedLessonService.publishResourceFromReview(review).take(1).subscribe((pubResource:PublishedResource) => {
				    this.activityStreamService.logToActivityStream$(activityStream, review, this.activityStreamActions.logPublished).take(1).subscribe(() => {
					    console.log("about to publish files");
					    this.publishedLessonService.publishFilesFromReview$(pubResource).take(1).subscribe((filePublishComplete) => {
						    console.log("file publish result: " + filePublishComplete);
						    if (filePublishComplete) {
							    console.log("file publish completed successfully");
							    //Ok, now its okay to delete the review...
							    this.repository.delete$(review);
						    }
					    }, (err) => {
						    console.error("There was an error in publishFilesFromReview$: " + err);
					    });

					    //Draft copy goes into a draft version
					    let draftCopy:ResourceSubmission = classToClass(review.resourceSubmission);
					    draftCopy.guid = review.guid;
					    draftCopy.status = ResourceSubmissionStatus.draft;

					    this.resourceSubmissionService.save(draftCopy);
					});
			    });
		    });
	    });
    }

	private getReviewStatusText(reviewField:ReviewFieldApprovalStatus):string {
        if (reviewField == null) {
            return "";
        }

        let approvalStatusIcon:string = "? Not set";
        switch(reviewField.reviewApprovalStatus) {
            case ReviewStatus.approved:
                approvalStatusIcon = "✔ Approved";
                break;
            case ReviewStatus.declined:
                approvalStatusIcon = "✕ Declined";
                break;
        }
        let approvedOrDeclinedText:string = reviewField.reviewerComment != null && reviewField.reviewerComment != "" ? ": " + reviewField.reviewerComment : "";


        return `${approvalStatusIcon}${approvedOrDeclinedText}`;

    }

    public declineReview(review:Review, optionalComments?:string) {
        review.resourceSubmission.status = ResourceSubmissionStatus.in_review_revision;
        review.completedReview = new Date().getTime();
        let reviewTitle = this.localizationService.LocalizeTools.document(review.resourceSubmission, ResourceSubmissionTextContent).title;
        this.logger.info(reviewTitle + " has been declined. New state is " + review.resourceSubmission.status + " and the new version number is " + review.resourceSubmission.version.toString());

        let deniedDetail:string = optionalComments != null ? `${optionalComments}`: "";

        for(let i:number = 0; i < review.reviewFields.length; i++) {
            let iReviewField:ReviewFieldApprovalStatus = review.reviewFields[i];
            let reviewFieldComment:string = `${this.getReviewStatusText(iReviewField)}`;
            deniedDetail += reviewFieldComment
            //code here
        }

	    this.activityStreamService.getActivityStream$(review.guid).subscribe((activityStream) => {
		    this.activityStreamService.logToActivityStream$(activityStream, review, this.activityStreamActions.logDeclined, optionalComments, deniedDetail).take(1).subscribe(() => {
			    //TODO: move reviewers onto draft
			    review.resourceSubmission.guid = review.guid;

			    review.resourceSubmission.guid = review.guid; //TODO: This is an unfortunate thi
			    this.logger.info(`this.resourceSubmissionService==${this.resourceSubmissionService}`);
			    this.resourceSubmissionService.save(review.resourceSubmission); //TODO: should we be using the resourceSubmission on review object to update the draft or modify the original draft instead?

			    //NOTE: We shouldnʻt delete the review because currently the comments and the review fields which got denied are stored here.

			    this.repository.save$(review);  //Update the review object which just changed state
		    });
	    });
    }

    /** NEED TO IMPLEMENT */
    public publishAsClassified(review:Review):void {
        this.logger.info("NEED TO IMPLEMENT!");
        throw new Error("NEED TO IMPLEMENT!");
    }

    public declassify() {
        //TODO:  Push to "/Lessons
        //determine if authorized
        //push to /Lessons/Public
    }

    /** Warning! This is a destructive method! Deletes the draft instance of a resource from the database as well as any associated files in storage.*/
    public delete(review:Review):void {
        console.warn(`deleting review of resource ${review.guid}`);
        this.repository.delete$(review);
    }

}
