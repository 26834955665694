import {
	AbstractFirestoreRepository, FirestoreIndex,
	OrderByCriterion, OrderByDirections, QueryCriteria, QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {AngularFirestore, CollectionReference, QueryFn} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {ConversionService} from "../ConversionService";
import {WaihonaUserRef} from "../../domain/user/WaihonaUserRef";
import {RoleType} from "../../domain/user/Role";
import {RefCachingService} from "../RefCachingService";

@Injectable({
	providedIn:'root',
} as any)
export class WaihonaUserRepository extends AbstractFirestoreRepository<WaihonaUser, WaihonaUserRef> {

	public by = {
		criterion: {
			roleType: (roleType:RoleType) =>
				QueryCriterion.create("@roles" , WhereFilterOperations.ArrayContains, roleType),
		},
		order: {
			lastNameAscending: ()=> OrderByCriterion.create("metadata.lastName", OrderByDirections.Ascending),
			firstNameAscending: ()=> OrderByCriterion.create("metadata.firstName", OrderByDirections.Ascending),
		},
		criteria:{
			all: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.lastNameAscending()).toCriteria(),
			roleType:(roleType:RoleType):QueryCriteria => new QueryCriteriaBuilder()
				.where(this.by.criterion.roleType(roleType))
				.orderBy(this.by.order.lastNameAscending()).toCriteria(),
			name: () => new QueryCriteriaBuilder()
				.orderBy(this.by.order.lastNameAscending())
				.orderBy(this.by.order.firstNameAscending()).toCriteria()
		},
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn(),
			roleType: (roleType:RoleType):QueryFn => new QueryCriteriaBuilder(this.by.criteria.roleType(roleType)).toQueryFn(),
		}
	};
	public indexes = {
/*
		name:():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.name()).toFirestoreIndex(WaihonaUserRepository.PATH),
*/
	};

	public static get PATH():string {
		return "Users";
	}
	public static get REF_PATH():string {
		return "Refs/users/All";
	}

	constructor(protected db:AngularFirestore, protected conversionService:ConversionService, protected refCachingService:RefCachingService) {
		super(WaihonaUser, db, {
			type: db.collection("Users"),
			ref: db.collection("Refs/users/All"),
		}, "guid", conversionService, WaihonaUserRef);

		refCachingService.registerSource(this);

/*		this.settings.caching.cacheObjects = true;
		this.settings.caching.cacheRefs = true;*/
	}



}
