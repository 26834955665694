import {Injectable} from '@angular/core';

import {Framework, FrameworksType} from "../domain/frameworks/Framework";
import {Organization, OrganizationFlags} from "../domain/organization/Organization";
import {ContactInfo, ContactInfoType} from "../domain/organization/ContactInfo";
import {NewUserRegistration} from "../domain/user/NewUserRegistration";
import {HogwartsStubData} from "./HogwartsStubData";
import {DevelopmentTeamStubData} from "./DevelopmentTeamStubData";
import {FrameworkStubData} from "./FrameworkStubData";
import {AinaStubData} from "./AinaStubData";
import {EmailStubData} from "./EmailStubData";
import {BadgesStubData} from "./BadgesStubData";
import {KealaiwikuamooStubData} from "./KealaiwikuamooStubData";

@Injectable({
    providedIn: 'root'
} as any)
export class StubData {

	public hogwarts:HogwartsStubData = new HogwartsStubData();
	public devteam:DevelopmentTeamStubData = new DevelopmentTeamStubData();
	public kealaiwikuamoo:KealaiwikuamooStubData = new KealaiwikuamooStubData();
	public frameworks:FrameworkStubData = new FrameworkStubData();
	public aina:AinaStubData = new AinaStubData();
	public email:EmailStubData = new EmailStubData();
	public badges:BadgesStubData = new BadgesStubData();
	public newUserRegistrations:Array<NewUserRegistration> = [];

	constructor() {
		this.email.stubData = this;
		this.newUserRegistrations.push(... this.getHogwartsNewUserRegistrations());
		this.newUserRegistrations.push(this.devteam.chjones_newUserRegistration, this.devteam.kalani_newUserRegistration, this.devteam.keabad_newUserRegistration, this.devteam.maui_newUserRegistration, this.devteam.rygonzal_newUserRegistration);
		this.newUserRegistrations.push(this.kealaiwikuamoo.chjones_newUserRegistration, this.kealaiwikuamoo.kalani_newUserRegistration, this.kealaiwikuamoo.keabad_newUserRegistration, this.kealaiwikuamoo.maui_newUserRegistration, this.kealaiwikuamoo.rygonzal_newUserRegistration);
	}

	public getNewUserRegistrationFor(guid:string):NewUserRegistration {
		let foundUser:NewUserRegistration = this.newUserRegistrations.find( newUserRegistration => {
			return newUserRegistration.guid == guid;
		});
		return foundUser;
}


	public getHogwartsNewUserRegistrations(): NewUserRegistration[] {
		let hogwarts: Array<NewUserRegistration> = [];

		let severus: NewUserRegistration = new NewUserRegistration();
		severus.organizationGuid = "hogwarts";
		severus.titleAtOrganization = "";
		severus.firstName = "Severus";
		severus.lastName = "Snape";
		severus.professionalName = "Severus Snappy";

		severus.password = "password";
		severus.confirmPassword = "password";
		severus.email = "sesnape@hogwarts.edu";
		severus.bio = "When Severus Snape began his teaching career at Hogwarts in 1981, he initially applied for the position of Defence Against the Dark Arts, but was rejected multiple times.[19] Many students were under the impression that this was because Dumbledore feared Snape might return to his old ways if allowed to teach his favourite subject, but in reality it was because Dumbledore was aware by that point that the job had been jinxed by Voldemort.";
		//"severus-snape.jpg"

		hogwarts.push(severus);

		let alastor: NewUserRegistration = new NewUserRegistration();
		alastor.organizationGuid = "hogwarts";
		alastor.titleAtOrganization = "Defence Against the Dark Arts Teacher";
		alastor.firstName = "Alastor";
		alastor.lastName = "Moody";
		alastor.professionalName = "Alastor Moody";
		alastor.password = "password";
		alastor.confirmPassword = "password";
		alastor.email = "almoody@hogwarts.edu";
		alastor.bio = "Alastor \"Mad-Eye\" Moody (d. 27 July, 1997[3]) was a pure-blood[4] Scottish[2] wizard, considered to be the most famous Auror of all times.[10] He was also a pivotal member of the Order of the Phoenix during the First and Second Wizarding Wars. Moody served with distinction during the first conflict, gaining a considerable reputation, as well as losing an eye, leg, and part of his nose while fighting the Dark Arts. He was also responsible for many of the inhabited cells in Azkaban, having caught innumerable Dark Wizards. As a result, Moody became overly-cautious and paranoid about his security.\n\n" +
			"In 1994, he accepted Albus Dumbledore's offer to teach Defence Against the Dark Arts at Hogwarts, but was kidnapped by Barty Crouch Jr, who disguised himself as Moody.\n\n" +
			"Later, the real Moody was rescued and became a member of the second Order of the Phoenix. He fought in several battles of the war, including the Battle of the Department of Mysteries in 1996 and the Battle of the Seven Potters in 1997, where he was murdered by Lord Voldemort.\n\n" +
			"His magical eye was later used by Dolores Umbridge to spy on her subordinates in the Muggle-Born Registration Commission, but was later retrieved by Harry Potter during the Infiltration of the Ministry of Magic in 1997, and was given a proper burial by Harry in the forest near where the 422nd Quidditch World Cup had been held three years earlier.";

		hogwarts.push(alastor);


		//filius-flickwick

		let filius: NewUserRegistration = new NewUserRegistration();
		filius.organizationGuid = "hogwarts";
		filius.titleAtOrganization = "Charms";
		filius.firstName = "Filius";
		filius.lastName = "Flitwick";
		filius.professionalName = "Filius Flitwick";
		filius.password = "password";
		filius.confirmPassword = "password";
		filius.email = "fiflickwick@hogwarts.edu";
		filius.bio = "Professor Filius Flitwick (b. 17 October[1] 1958 or earlier[3]) was a part-goblin, part-wizard[10] who attended Hogwarts School of Witchcraft and Wizardry and was sorted into Ravenclaw House,[11] being an intelligent young man and a model student.[12] Some time after his graduation, Flitwick returned to Hogwarts and became the Charms Master, as well as the Head of Ravenclaw House.[7]\n\n" +
			"During the First and Second Wizarding Wars, Flitwick opposed Lord Voldemort. In 1995, he also opposed Dolores Umbridge, the High Inquisitor of Hogwarts, and, in 1997, fought at the Battle of the Astronomy Tower, after which he attended the Funeral of Albus Dumbledore. He also protected the students from the Carrows in the 1997-1998 school year. On 2 May 1998, he partook in the Battle of Hogwarts and defeated many Death Eaters.\n\n" +
		"He survived the war, and likely continued to teach Charms at Hogwarts to future generations of students.";

		hogwarts.push(filius);

		let argus: NewUserRegistration = new NewUserRegistration();
		argus.organizationGuid = "hogwarts";
		argus.titleAtOrganization = "Caretaker";
		argus.firstName = "Argus";
		argus.lastName = "Flich";
		argus.professionalName = "Argus Flich";
		argus.password = "password";
		argus.confirmPassword = "password";
		argus.email = "arflich@hogwarts.edu";
		argus.bio = "Argus Filch was a Squib and the caretaker of Hogwarts School of Witchcraft and Wizardry since around 1973. Filch usually wandered around the school corridors with his cat Mrs Norris, trying to catch students breaking the school rules and complaining about Peeves the Poltergeist.\n\n" +
			"In 1992, after Mrs Norris was petrified by Salazar Slytherin's Basilisk, Filch was furious; however, his cat was healed by the end of the year. In 1995, when Dolores Umbridge became High Inquisitor at Hogwarts, Filch gladly supported her. The next year, he was responsible for recognising Dark objects that were brought into Hogwarts.\n\n" +
			"Filch helped the students evacuate during the Battle of Hogwarts in 1998 and participated in the battle himself. He kept his job of caretaker after the Second Wizarding War. Filch is also the only one of the Hogwarts staff to stay at Hogwarts Castle during the summer holidays.";

		hogwarts.push(argus);

		let albus: NewUserRegistration = new NewUserRegistration();
		albus.organizationGuid = "hogwarts";
		albus.titleAtOrganization = "Headmaster";
		albus.firstName = "Albus";
		albus.lastName = "Dumbledore";
		albus.professionalName = "Albus Dumbledore";
		albus.password = "password";
		albus.confirmPassword = "password";
		albus.email = "aldumbledore@hogwarts.edu";
		albus.bio = "Professor Albus Percival Wulfric Brian Dumbledore, O.M. (First Class), Grand Sorc., D. Wiz., X.J. (sorc.), S. of Mag.Q.[13] (c. Summer[1] 1881[2] – 30 June, 1997[2]) was the Defence Against the Dark Arts Professor, later the Transfiguration Professor, and later Headmaster of Hogwarts School of Witchcraft and Wizardry. Professor Dumbledore also served as Supreme Mugwump of the International Confederation of Wizards (?–1995) and Chief Warlock of the Wizengamot (?-1995; 1996-1997). He was a half-blood, Muggle-supporting wizard, the son of Percival and Kendra Dumbledore, and the elder brother of Aberforth and Ariana. His father died in Azkaban when Dumbledore was young, while his mother and sister were later accidentally killed. His early losses greatly affected him early on, even at his death, but, in turn, made him a better person.\n\n" +
		"While initially unable to directly act against Gellert Grindelwald, due to their past Blood pact, the Ministry's surveillance of him, his lingering feelings of love toward Grindelwald, and his lingering guilt over Ariana's death, Dumbledore sent Newt Scamander to New York to release the Thunderbird Frank, and later sent Newt to Paris to rescue Credence Barebone. After the tragic death of Leta Lestrange in Paris, Dumbledore became determined to contribute helpful advice to the resistance against Grindelwald, notably Newt, Theseus, Tina, Jacob, Yusuf, and Nagini in the Global Wizarding War. Initially unbeknownst to Dumbledore however, Grindelwald intended to use the former's alleged Obscurial relative, Aurelius Dumbledore, as a weapon against him.\n\n" +
		"Following the Global Wizarding War, and end of the For the Greater Good revolution, Dumbledore became most famous for his defeat of Gellert Grindelwald, the discovery of the twelve uses of dragon's blood, and his work on alchemy with Nicolas Flamel.";

		hogwarts.push(albus);
		return hogwarts;

	}


}



