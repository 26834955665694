import {RegisterClazz} from "../RegisterType";
import {RefLocalization} from "../ITextContentObject";

@RegisterClazz(BadgeRef)
export class BadgeRef {
    public guid:string = null;

    public localization:RefLocalization = {
        name: {
            en: "",
            haw: ""
        },
        description: {
            en: "",
            haw: ""
        }
    };

    public static create(guid:string, localization:RefLocalization) {
        let badgeRef:BadgeRef = new BadgeRef();
        badgeRef.guid = guid;
        badgeRef.localization = localization;
        return badgeRef;
    }

}
