import {Component, Injector, OnInit} from '@angular/core';
import {CollectionService} from "../../../../services/CollectionService";
import {BaseComponent} from "../../../BaseComponent";
import {Collection} from "../../../../domain/collections/Collection";
import {Localization} from "../../../../data/Localization";
import {CollectionTextContent} from "../../../../domain/collections/CollectionTextContent";
import {WaihonaUser} from "../../../../domain/user/WaihonaUser";
import {Subject, Subscription} from "rxjs";
import {INavState} from "../../../../services/NavigationHistoryService";

@Component({
  selector: 'app-list-collections',
  templateUrl: './list-collections.component.html',
  styleUrls: ['./list-collections.component.scss']
})
export class ListCollectionsComponent extends BaseComponent implements OnInit {

	private cbContext =  Localization.template.collections.list;
	public CB = {
		title: this.cbContext.title,
		createNew: this.cbContext.create_new,
		pill_collectionItems: this.cbContext.pill_collectionItems,
		expand: this.cbContext.expand,
		collapse: this.cbContext.collapse
	};

	public collections:Array<Collection> = [];
	public COLLECTION_TEXT_CONTENT = CollectionTextContent;

	public currentSearchValue:string = "";
	public filteredResultCount:Subject<number> = new Subject<number>();
	public state = {
		isLoading: false,
		isExpanded: false,
	}

	public constructor(protected collectionService:CollectionService,
	                   protected injector:Injector) {
		super(injector);
		this.localizationService.registerAndLocalize("ListOrganizationsComponent", this.CB);
	}

	public ngOnInit():void {
		this.logger.info("ListCollectionsComponent::ngOnInit");
		this.state.isLoading = true;

		if ('ontouchstart' in window) {
			this.state.isExpanded = true;
		}

		this.trackSubscription(this.authService.currentUser$.subscribe((currentUser:WaihonaUser) => {
			this.state.isLoading = true;
			let s1:Subscription = this.collectionService.getAllCollectionsVisibleToUser$(currentUser).subscribe((collectionPageResults:Collection[]) => {
				s1.unsubscribe();
				this.collections = collectionPageResults;
				this.state.isLoading = false;
			});
			this.trackSubscription(s1);
		}));

		// specific to interaction of filter with pagination
		this.trackSubscription(this.filteredResultCount
			.filter((count:number) => {
				return (this.collectionService.paging.list.cursor() == null) ?
					true : count < this.collectionService.paging.list.cursor().resultsPerPage
			}).subscribe(() => this.collectionService.paging.list.next())
		);

		this.navHistoryService.restoredState$.subscribe((restoredState:INavState) => {
			if (document.location.toString() === restoredState?.url) {
				this.currentSearchValue = restoredState?.savedState?.filter;
				console.log(`restored filter is: ${JSON.stringify(this.currentSearchValue)}`);
			}
		});
	}

	public getNextPage() {
		this.collectionService.paging.list.next();
	}
	public isPageLoading():boolean {
		return this.collectionService.paging.list.isLoading().value;
	}

	public setSearchValue(searchString:string):void {
		this.currentSearchValue = searchString;
		this.navHistoryService.saveState({filter: this.currentSearchValue});
	}

	public onClickExpand():void {
		this.state.isExpanded = !this.state.isExpanded;
	}
}
