import {IsNotEmpty, IsOptional} from "class-validator";


export class OrganizationVerificationMessage {

	@IsNotEmpty()
	/** The Id of the current user.  This is populated from auth. It is not provided*/
	public currentUserGuid:string; //User GUID

	@IsNotEmpty()
	/** The Id of the user to add or remove from organizational verification */
	public userToVerifyGuid:string = "";

	@IsNotEmpty()
	/** The Id of the organization */
	public orgGuid:string = "";

	@IsOptional()
	/** The title of the user at the organization, Required only if the user does not already have a title.  This is ignored if a user has a title or the operation is to remove a verification. */
	public userTitle:string = null;

	/** If isVerified is true will add verification to user and org, if false will remove verification from user and org. */
	public isVerified:boolean = false;


	public isAdding():boolean {
		return this.isVerified == true;
	}


	public isRemoving():boolean {
		return this.isVerified == false;
	}

}