import { NgModule } from '@angular/core';
import {ResourceSubmissionMetaComponent} from "./resource-submission-meta/resource-submission-meta.component";
import {LessonContentComponent} from "../live/single/lesson-content/lesson-content.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import { ResourceStatusBoxComponent } from '../../reviews/single/resource-status-box/resource-status-box.component';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		SharedModule

	],
    declarations: [
        ResourceSubmissionMetaComponent,
        // LessonContentComponent
		ResourceStatusBoxComponent
    ],
    exports: [
        ResourceSubmissionMetaComponent,
        // LessonContentComponent
        ResourceStatusBoxComponent
    ]
})
export class ResourcesCommonModule { }
