<div>
    <div class="form-group has-feedback">
        <span class="help-block">{{CB.meta_label_frameworks | localize | async}}</span>
        <ng-select [(ngModel)]="resourceSubmission.frameworks" [items]="availableFrameworks" [multiple]="true"
                   bindLabel="ref.title" class="bootstrap custom" field="frameworks"
                   placeholder="{{CB.meta_placeholder_frameworks | localize | async}}"
                   waih-validation-color>
            <ng-template class="multiple" let-clear="clear" let-item="item" ng-label-tmp>
                <div class="option">
                    <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left">&times;</span>
                    <span class="ng-value-label">
                <span [innerHTML]="item.ref.frameworkType_faIcon"></span>{{item.ref.title}}
                        <a href="javascript:void(0);" (click)="currentSelectableFramework = item;"><i class="fas fa-cogs ml-1"></i></a>
            </span>
                </div>
            </ng-template>
        </ng-select>
        <waih-validation-text field="frameworks"></waih-validation-text>
    </div>
    <table class="table table-striped" *ngIf="resourceSubmission.frameworks.length > 0">
        <thead>
            <tr>
                <th>Title</th>
                <th>#</th>
                <th>Type</th>
                <th>Configure</th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-" *ngFor="let selectedFramework of currentlyChosenFrameworks">
                <td> {{selectedFramework.actual.title}}</td>
                <td> {{selectedFramework.selectedKeys.length}}</td>
                <td>
                    <span class="badge " [ngClass]="badgeClassType(selectedFramework)">{{selectedFramework.actual.type}}</span>
                </td>
                <td> <a href="javascript:void(0);" (click)="currentSelectableFramework = selectedFramework">
                     <i class="fas fa-cogs"></i></a><br/>
                </td>
            </tr>
        </tbody>
    </table>
</div>


