import {Exclude} from "class-transformer";
import {Contains, Equals, IsEmail, IsNotEmpty, IsOptional, MaxLength, MinLength, ValidateIf} from "class-validator";
import {RegisterClazz} from "../RegisterType";
import {HasThisManyLowerCaseLetters} from "../../validators/HasThisManyLowerCaseLetters";
import {HasThisManyUpperCaseLetters} from "../../validators/HasThisManyUpperCaseLetters";
import {HasThisManyNumbers} from "../../validators/HasThisManyNumbers";
import {EqualsOtherProperty} from "../../validators/EqualsOtherProperty";

@RegisterClazz(NewUserRegistration)
export class NewUserRegistration {

	@Exclude()
	public guid:string; //User GUID

	public otherOrganizationInfo:string = "";
	public specialtyAreas:string = "";
	public howFindOut:string = "";

	@Equals(true, {message: "In order to create a waihona account you must accept the Waihona Contributor and User Kuleana Agreement."})
	public acceptsContributorAgreement:boolean = false;

	@Equals(true, {message: "You must first read the Waihona Contributor and User Kuleana Agreement before accepting."})
	public hasReadContributorAgreement:boolean = false;

	public overrideName:boolean = false;
	public invitedByEmail:boolean = null;
	public inviteByEmailCode:string;

	@MinLength(2)
	@IsNotEmpty({message: "This field is required."})
	public firstName:string = "";

	@MinLength(2)
	@IsNotEmpty({message: "This field is required."})
	public lastName:string = "";

	@IsOptional()
	public altFirstName:string = "";

	@MinLength(2)
	@IsNotEmpty()
	@Contains(" ", {message: "Your professional name is required, and it should include at least your first or alternate first name and your last name."})
	public professionalName:string = "";

	public avatarPngBase64:string = "";

	@IsEmail()
	@IsNotEmpty({message: "This field is required."})
	public email:string = "";

	//Name of the school
	//TODO: Remove from model!
	public organizationName:string = "";

	//Guid of the School/Org
	@IsNotEmpty({groups: ["invitecode"]})
	public organizationGuid:string = "";

	@MaxLength(3000)
	/** A long biography of the user */
	public bio:string = "";

	//for initial phase use only: require an invite code from the organization at registration.  Remove before public release.
	@ValidateIf(o => o.invitedByEmail == false)
	@IsNotEmpty({message: "This field is required.", groups: ["invitecode"]})
	public inviteCode:string = "";

	/** The title the individual carries at the organization */
	@IsNotEmpty({message: "This field is required."})
	@MinLength(2)
	public titleAtOrganization:string = "";

	@HasThisManyUpperCaseLetters("password", 1, {message: "Must contain at least one uppercase letter"})
	@HasThisManyLowerCaseLetters("password", 1, {message: "Must contain at least one lowercase letter"})
	@HasThisManyNumbers("password", 1, {message: "Must contain at least one number"})
	@MinLength(8, {message: "Minimum 8 characters"})
	public password:string = "";

	@IsNotEmpty({message: "Your passwords must match."})
	@EqualsOtherProperty("password", {message: "Password confirmation must match the password"})
	public confirmPassword:string = "";

    public passwordsMatch():boolean {
        return this.password == this.confirmPassword;
    }
}
