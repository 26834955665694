import {Component, OnDestroy, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ReviewFieldComponent} from "../../reviews/single/review-field/review-field.component";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";
import {LocalizationService} from "../../../services/LocalizationService";

@Component({
	selector: 'review-comment-modal',
	templateUrl: './review-comment-modal.component.html',
	styleUrls: ['./review-comment-modal.component.scss']
})
export class ReviewCommentModalComponent implements OnInit, OnDestroy {

	private cbContext = Localization.template.modal.review.review_comment;
	public CB = {
		title: this.cbContext.title,
		label: this.cbContext.label,
		placeholder: this.cbContext.placeholder,
		cancel: Localization.template.buttons.cancel,
		save: Localization.template.buttons.save,
		delete: Localization.template.buttons.delete,
	};

	public component:ReviewFieldComponent;

	constructor(public bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("ReviewCommentModalComponent", this.CB);
		this.logger.info("ReviewCommentModalComponent");
	}

	ngOnInit():void {
		this.logger.info('ReviewCommentModalComponent component init');
	}

	ngOnDestroy() {
		this.logger.info('ReviewCommentModalComponent component destroy')
	}

}

