import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../../data/Localization";
import {Subject} from "rxjs";
import {BaseComponent} from "../../../../BaseComponent";
import {INavState, ISavedState} from "../../../../../services/NavigationHistoryService";
import {ICategorizedIOption} from "../../../../../data/LessonData";
import {PublishedResource} from "../../../../../domain/resources/PublishedResource";
import {PublishedResourceService} from "../../../../../services/PublishedResourceService";
import {PermissionType} from "../../../../../domain/user/Permission";
import {FilterComponent} from "../../../../common/filter/filter.component";
import {ResourceSubmissionTextContent} from "../../../../../domain/resources/ResourceSubmissionTextContent";
import {ToastType} from "../../../../../services/common/NotificationService";


@Component({
	selector: 'list-live-resources',
	templateUrl: 'list-published-resources.component.html',
	styleUrls: [
		"./list-published-resources.component.scss",
		'../../../../../../scss/vendors/toastr/toastr.scss'
	],
})
export class ListPublishedResourcesComponent extends BaseComponent implements OnInit {

	public filter:ICategorizedIOption[];
	public exactMatch:boolean = false;

	public cbContext = Localization.template.resource.list_published;
	public CB = {
		title: this.cbContext.title,
		contribute: this.cbContext.new,
		assets: this.cbContext.assets,
		new_item: Localization.template.common.pills.new,
	};

	public publishedResources:Array<PublishedResource> = [];
	public filteredResultCount:Subject<number> = new Subject<number>();


	@ViewChild("filterComponent")
	public filterComponent:FilterComponent;

	public constructor(protected injector:Injector,
	                   protected publishedResourceService:PublishedResourceService,
	                   protected logger:NGXLogger) {
		super(injector);
		this.localizationService.registerAndLocalize("ListPublishedResourcesComponent", this.CB);
	}

	public ngOnInit():void {

		this.trackSubscription(this.publishedResourceService.paging.list.initialize.list$().subscribe((pageResults:PublishedResource[]) => {
			this.publishedResources = pageResults;
		}));

		// for pulling more pages if there is no scrollbar, until there is a scrollbar
		this.trackSubscription(
			this.screenResizeService.fillPage(this.publishedResourceService)
		);

		// specific to interaction of filter with pagination
		this.trackSubscription(this.filteredResultCount
			.filter((count:number) => {
				return (this.publishedResourceService.paging.list.cursor() == null) ?
					true : count < this.publishedResourceService.paging.list.cursor().resultsPerPage
			}).subscribe(() => this.publishedResourceService.paging.list.next())
		);

		this.navHistoryService.restoredState$.subscribe((restoredState:INavState) => {
			if (document.location.toString() === restoredState?.url) {
				this.filter = restoredState?.savedState?.filter;
				this.exactMatch = restoredState?.savedState?.exactMatch;
				console.log(`restored filter is: ${JSON.stringify(this.filter)}`);
			}
		});

	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};
	public get ResourceSubmissionTextContent():(new()=>{}) {
		return ResourceSubmissionTextContent;
	}


	public get isAbleToUnlockResources():boolean {
		return this.roleService.hasPermissionFor(PermissionType.unlock_resources);
	}

	public imageSrcForGuid(resourceGuid:string):string {
		return this.urlService.resourceImage.published(resourceGuid);
	}

	public navigateToDetail(selectedId:string) {
		console.log(`saved filter is: ${JSON.stringify(this.filter)}`);
		let navCommands:any[] = ['/resources', selectedId];
		let state:ISavedState = {selectedId: selectedId, filter: this.filter, exactMatch: this.exactMatch};
		this.navHistoryService.saveStateAndNavigate(state, navCommands);
	}

	public getNextPage() {
		this.publishedResourceService.paging.list.next()
	}
	public dataLoading():boolean {
		return this.publishedResourceService.paging.list.isLoading().value;
	}

	public hasOleloContent(resource:PublishedResource):boolean {
		if (resource.resourceSubmission.documentTextContent['haw']) {
			return true;
		}
	}
	public get TDContext() {
		return this.localizationService.loc.resource.list_published;
	}
	public isNew(publishedResource:PublishedResource):boolean {
		let publishedDate:string = publishedResource.resourceSubmission.getApprovedAsTime();
		let parsedDate:number = Date.parse(publishedDate);
		let today:number = new Date().valueOf();
		let oneDay:number = 86400000;
		let numberOfDaysElapsed:number = 8; //this could be a user-set variable of user preferences in a future state

		//return true if the creation date is greater than today minus the desired number of days.
		return (parsedDate > (today - oneDay * numberOfDaysElapsed));
	}

	public onToggleLockStatusClick(resource:PublishedResource):void {
		let previousValueAllowsPublicAccess:boolean = resource.resourceSubmission.configuration.allowPublicAccess;
		resource.resourceSubmission.configuration.allowPublicAccess = !resource.resourceSubmission.configuration.allowPublicAccess;
		this.logger.info(`An admin set the configuration for resource ${resource.guid} allowPublicAccess: ${resource.resourceSubmission.configuration.allowPublicAccess}`);
		this.publishedResourceService.toggleLockStatus(resource.guid, resource.resourceSubmission.configuration.allowPublicAccess);
		if(resource.resourceSubmission.configuration.allowPublicAccess) {
			this.notificationService.displayToast(this.TDContext.toasts.resource_unlocked, ToastType.success);
		} else {
			this.notificationService.displayToast(this.TDContext.toasts.resource_locked, ToastType.success);
		}
	}

}
