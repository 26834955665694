import {Injectable} from "@angular/core";

@Injectable()
export class PathService {
	public storage = {
		resourceFolder: {
			draft: (resourceGuid:string) => `Drafts/${resourceGuid}`,
			review: (resourceGuid:string) => `Reviews/${resourceGuid}`,
			published: (resourceGuid:string) => `Resources/${resourceGuid}/current`,
			assetsFolder: {
				draft: (resourceGuid:string) => `Drafts/${resourceGuid}/assets`,
				review: (resourceGuid:string) => `Reviews/${resourceGuid}/assets`,
				published: (resourceGuid:string) => `Resources/${resourceGuid}/current/assets`,
			},
			assetsFile: {
				draft: (resourceGuid:string, fileName:string) => `Drafts/${resourceGuid}/assets/${fileName}`,
				review: (resourceGuid:string, fileName:string) => `Reviews/${resourceGuid}/assets/${fileName}`,
				published: (resourceGuid:string, fileName:string) => `Resources/${resourceGuid}/current/assets/${fileName}`,
			},
			image: {
				draft: (resourceGuid:string) => `Drafts/${resourceGuid}/${resourceGuid}.png`, //TODO: reverse this so it is consistent with other patterns draft.image
			}
		},
		fanFolder: (fanContributionGuid:string) => `FanContributions/${fanContributionGuid}/assets`,

		organization: {
			image: (guid:string) => `Organizations/${guid}.png`,
		},
		badge: {
			image: (guid:string) => `Badges/${guid}.png`,
		},
		user: {
			image: (guid:string) => `Users/${guid}.png`
		},
		collection: {
			image: (guid:string) => `Collections/${guid}.png`
		}
	};
}

