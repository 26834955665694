import {escapeRegExp, replace, deburr, kebabCase} from "lodash";

export class NupepafyStringUtils {
	public static makeGuidExtension():string {
		let text:string = "";
		let possible:string = "abcdefghijklmnopqrstuvwxyz0123456789";

		for (let i:number = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return "_" + text;
	}

	public static nupepafy(input:string):string {
		input = input.replace(new RegExp(escapeRegExp("Ā"), "g"), "A");
		input = input.replace(new RegExp(escapeRegExp("Ē"), "g"), "E");
		input = input.replace(new RegExp(escapeRegExp("Ī"), "g"), "I");
		input = input.replace(new RegExp(escapeRegExp("Ō"), "g"), "O");
		input = input.replace(new RegExp(escapeRegExp("Ū"), "g"), "U");
		input = input.replace(new RegExp(escapeRegExp("ā"), "g"), "a");
		input = input.replace(new RegExp(escapeRegExp("ē"), "g"), "e");
		input = input.replace(new RegExp(escapeRegExp("ī"), "g"), "i");
		input = input.replace(new RegExp(escapeRegExp("ō"), "g"), "o");
		input = input.replace(new RegExp(escapeRegExp("ū"), "g"), "u");
		// the next two look similar, but they're actually different characters
		input = input.replace(new RegExp(escapeRegExp("ʻ"), "g"), "");
		input = input.replace(new RegExp(escapeRegExp("‘"), "g"), "");
		input = input.replace(new RegExp(escapeRegExp("'"), "g"), "");
		input = input.replace(new RegExp(escapeRegExp("-"), "g"), "");

		return input;
	}

	/** Get a generated user Id based on a name */
	public static generateGuidFromTwoParts(partOne:string, partTwo:string):string {
		//replace Hawaiian
		let partUno:string = this.nupepafy(partOne);
		let partDos:string =  this.nupepafy(partTwo);
		let firstAndSecond:string = ("" +partUno.trim() + "-" + partDos.trim()).toLowerCase();
		let alpha:RegExp = new RegExp("[^a-z\-]","g");
		let justLowerAlpha = replace(firstAndSecond, alpha, "");

		return justLowerAlpha;
	}

	public static generateGuidFromSentence(input:string):string {
		let parts:Array<string> = input.split(" ");
		let resultParts:Array<string> = [];
		for (let part of parts) {
			resultParts.push(NupepafyStringUtils.generatePart(part));
		}
		return resultParts.join("-");
	}
	public static generatePart(part:string):string {
		let replaced:string = this.nupepafy(part).toLowerCase();
		let alpha:RegExp = new RegExp("[^a-z\-]","g");
		let justLowerAlpha = replace(replaced, alpha, "");
		return justLowerAlpha;
	}

	/** Get a generated user Id based on any string */
	public static generateGuidFromString(string:string):string {
		//deburr chars
		let deburred:string = deburr(string).trim();
		let kebab:string = kebabCase(deburred);
		let alpha:RegExp = new RegExp("[^a-z\-]","g");
		let justAlpha = replace(kebab, alpha, "");
		return justAlpha;
	}
}
