import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {ResourceSubmission, ResourceSubmissionStatus} from "../../domain/resources/ResourceSubmission";
import {RefCachingService} from "../RefCachingService";


@Injectable({
	providedIn: 'root',
} as any)
export class DraftResourceRepository extends AbstractFirestoreRepository<ResourceSubmission> {

	constructor(protected db:AngularFirestore, protected refCachingService:RefCachingService) {
		super(ResourceSubmission, db, db.collection("Drafts"));
		this.refCachingService.registerProcessor(this);
	}

	public byTitleAsc():QueryFn {
		let queryFn:QueryFn  = lessonSubmissions => {
			return lessonSubmissions.orderBy("title", "asc");
		};
		return queryFn;
	}

	public bySubmitterGuid(submitterGuid:string):QueryFn {
		let queryFn:QueryFn  = ref => {
			return ref.where("submitter.guid", '==', submitterGuid);
		};
		return queryFn;
	}

	public bySubmitterWithStatus(submitterGuid:string, status:ResourceSubmissionStatus):QueryFn {
		let queryFn:QueryFn = ref => {
			return ref.where("submitter.guid", '==', submitterGuid)
				.where("status", '==', status)
		};
		return queryFn;
	}

	public byCollaboratorGuid(collaboratorGuid:string):QueryFn {
		let queryFn:QueryFn = ref => {
			return ref.where("@collaborators", 'array-contains', collaboratorGuid);
		};
		return queryFn;
	}

}
