<p>Matches any of the selected nodes: <span class="text-muted">(Selected {{seletedDraftCurrently.length}}.)</span></p>
<tree-root
        class="reverse"
        id="tree2"
        #treeComponent
        [(state)]="state"
        [nodes]="nodes"
        (focus)="onNodeFocus($event)"
        (change)="onNodeChange($event)"
        [options]="options">
</tree-root>
<p class="font-italic text-muted" *ngIf="selectedNodeText != ''">{{selectedNodeText}}</p>
