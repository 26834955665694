import {Injectable} from "@angular/core";
import {IRefType, resolveAllRefTypeFields} from "../domain/RefType";
import {AbstractFirestoreRepository} from "./repository/AbstractFirestoreRepository";

@Injectable({
	providedIn: "root"
})
export class RefCachingService {

	protected typeCache:Map<string, AbstractFirestoreRepository<any, any>> = new Map<string, AbstractFirestoreRepository<any, any>>();
	protected refCache:Map<string, AbstractFirestoreRepository<any, any>> = new Map<string, AbstractFirestoreRepository<any, any>>();
	protected refs:Array<any> = [];
	protected types:Array<any> = [];


	private sources:Array<AbstractFirestoreRepository<any>> = [];
	private resolvers:Array<AbstractFirestoreRepository<any>> = [];

	public registerSource<T,R=any>(repo:AbstractFirestoreRepository<T,R>):void {
		this.sources.push(repo);
		if(repo.refTypeName) {
			this.refCache.set(repo.refTypeName, repo);
			this.refs.push(repo.RefType);

			repo.settings.caching.cacheRefs = true;
		}
		this.typeCache.set(repo.typeName, repo);
		this.types.push(repo.Type);

	}
	public registerProcessor<T,R=any>(repo:AbstractFirestoreRepository<T,R>):void {
		repo.settings.caching.resolveRefs = true;
		let self = this;
		repo.settings.caching.refResolverFunction = (object:T) => {
			self.resolveObject(object);
		};
		this.resolvers.push(repo);
	}
	protected convertToUpdatedRef<R>(ref:R):R {
		//TODO: This doesnʻt handle nested
		if(ref == null) {
			return null;
		} else if(ref.constructor == null) {
			return ref;
		}

		let refTypeName:string = (ref as {}).constructor.name;
		let sourceRepository:AbstractFirestoreRepository<any,R> = this.refCache.get(refTypeName);

		if(sourceRepository != null) {
			let identifier:string = ref[sourceRepository.identifier];

			if(sourceRepository.hasCachedRef(identifier)) {
				ref = sourceRepository.getCachedRef(identifier);
			}
		}

		return ref;
	}
	public isType(t:{}):boolean {
		return this.types.includes(t.constructor);
	}
	public isRefType(t:{}):boolean {
		return this.refs.includes(t.constructor);
	}
	public resolveObject<T>(object:T):void {
		resolveAllRefTypeFields(object, (ref, iRefType:IRefType, extra:{target: object, property: string})=> {
			//TODO: May want to make a copy rather than modifying the object map or array...
			if(ref == null || typeof ref != "object") {
				return ref;
			}
			if(iRefType.isObjectMap) {
				let keys:Array<string> = Object.keys(ref);
				for(let i:number = 0; i < keys.length; i++) {
					let key:string = keys[i];
					let element = ref[key];
					element = this.convertToUpdatedRef(element);
					ref[key] = element;
				}
			} else if(iRefType.isArray) {
				let refAsArray:Array<any> = ref;
				for(let i:number = 0; i < refAsArray.length; i++) {
					let element = refAsArray[i];
					element = this.convertToUpdatedRef(element);
					refAsArray[i] = element;
				}
				extra.target[extra.property] = refAsArray;
			} else if (iRefType.isARefContainerObject) {
			 	this.resolveObject(ref);
			} else {
				extra.target[extra.property] = this.convertToUpdatedRef(ref)
			}
		}); //keep scope via arrow function

	}

}



