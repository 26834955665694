import {Exclude} from "class-transformer";
import {IsNotEmpty, IsOptional} from "class-validator";


export class InvitationAnswerMessage {
	@IsNotEmpty()
	public orgGuid:string;

	@Exclude()
	public userGuid:string;

	public acceptsInvite:boolean = false;

	@IsOptional()
	public userTitle:string;



}