<div *ngIf="currentUser" class="animated fadeIn">
	<!--<toaster-container [toasterconfig]="toasterconfig"></toaster-container>-->
	<h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.header | localize | async}}</h1><br>

	<!--alert div if user has no drafts-->
	<div *ngIf="userHasNoSubmissions" class="alert alert-warning"><i
			class="fas fa-hand-paper fa-2x mx-2"></i>{{CB.no_drafts | localize | async}}
		<a class="alert-link" href="javascript:void(0);" style="text-decoration: none;"
		   [routerLink]="['/resources/create']">{{CB.create_one | localize | async}}</a>
	</div>

	<!--alert div for fan contributions-->
	<div *ngIf="resourcesWithFanContributions.length>0" class="alert alert-danger"><i
			class="fas fa-star mx-2"></i>{{CB.fan_contributions | localize | async}}
		<span *ngFor="let item of resourcesWithFanContributions; let isLast=last">
		    <a href="javascript:void(0);" [routerLink]="[item.guid, 'edit']"
		       style="text-decoration: none;"><strong>{{item.title(this.localizationService.language)}}</strong>{{isLast ? '' : ', '}}</a>

	    </span>
	</div>

	<div *ngIf="!userHasNoSubmissions" id="container-card" class="card">
		<div class="card-header">
			<a class="text-secondary" href="javascript:void(0);" style="text-decoration: none;"
			   [routerLink]="['/resources/create']"><strong>{{CB.create_new | localize | async}}</strong></a>
		</div>

		<!--Table: Reviews Failed/Action Required-->
		<div *ngIf="filteredDrafts.pendingCorrections.length > 0" class="card card-accent-light mx-md-5 mt-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_corrections | localize | async}}</h5>
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_edit_view | localize | async}}</th>
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_collaborators | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_reviewDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let lesson of filteredDrafts.pendingCorrections">
							<tr>
								<th scope="row">
									<span>
									  <a href="javascript:void(0);" [routerLink]="[lesson.guid, 'edit']" style="text-decoration: none;">
										  <i tooltip="{{CB.tooltip_edit | localize | async}}"
											 class="fas fa-2x fa-pencil-alt mr-md-3"></i>
									  </a>
									  <a href="javascript:void(0);" [routerLink]="[lesson.guid]" style="text-decoration: none;">
										  <i tooltip="{{CB.tooltip_view | localize | async}}" class="far fa-2x fa-eye"></i>
									  </a>
									</span>
								</th>
								<td *ngIf="lesson && LocalizeTools.document(lesson, ResourceSubmissionTextContent).title != ''">{{LocalizeTools.document(lesson, ResourceSubmissionTextContent).title | slice: 0:40}}</td>
								<td>
									<span *ngFor="let collaborator of lesson.collaborators" class="align-items-center d-inline-flex mr-2">
										 <avatar-component [userRef]="collaborator" [class]="'select-image'"></avatar-component>
										{{collaborator.fullName}}<br>
									</span>
								</td>
								<td>{{lesson.version}}</td>
								<td>{{lesson.getModifiedAsTime()}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!--End Action Required table-->

		<!--Table: Drafts in Progress-->
		<div *ngIf="filteredDrafts.drafts.length > 0" class="card card-accent-light mx-md-5 mt-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_drafts | localize | async}}</h5>
				<!-- Drafts -->
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_edit_view | localize | async}}</th><!-- Edit | View -->
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_collaborators | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_saveDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let draft of filteredDrafts.drafts">
						<tr>
							<th scope="row">
								<span>
									<a href="javascript:void(0);" [routerLink]="[draft.guid, 'edit']"
									   style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_edit | localize | async}}"
										   class="fas fa-2x fa-pencil-alt mr-md-3"></i>
									</a>
									<a href="javascript:void(0);" [routerLink]="[draft.guid]"
									   style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_view | localize | async}}"
										   class="far fa-2x fa-eye"></i>
									</a>
								</span>
							</th>
							<td>{{LocalizeTools.document(draft, ResourceSubmissionTextContent).title | slice: 0:40}}</td>
							<td>
                      <span *ngFor="let collaborator of draft.collaborators" class="align-items-center d-inline-flex mr-2">
	                      <avatar-component [userRef]="collaborator" [class]="'select-image'"></avatar-component>
	                      {{collaborator.fullName}}<br>
                      </span>
							</td>
							<td>{{draft.version}}</td>
							<td>{{draft.getModifiedAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>

			</div>
		</div>
		<!--End Drafts table-->

		<!--Table: Pending Review-->
		<div *ngIf="filteredReviews.lessonsPendingReview.length > 0" class="card card-accent-light mx-md-5 mt-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_pendingReview | localize | async}}</h5>
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_view_recall | localize | async}}</th>
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_reviewer | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_submitDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let review of filteredReviews.lessonsPendingReview">
						<tr>
							<th scope="row">
								<span>
									<a href="javascript:void(0);" [routerLink]="[review.guid]"
									   style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_view | localize | async}}"
										   class="far fa-2x fa-eye mr-3"></i>
									</a>
									<a role="button" (click)="recallSubmission(review)" href="javascript:void(0);"
									   [routerLink]="[review.guid, 'edit']" style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_recall | localize | async}}"
										   class="fas fa-2x fa-undo-alt"></i>
									</a>
								</span>
							</th>
							<td>{{LocalizeTools.document(review.resourceSubmission, ResourceSubmissionTextContent).title| slice: 0:40}}</td>
							<ng-container *ngIf="review.reviewers.length > 0 ; else noReviewers">
								<td *ngFor="let reviewer of review.reviewers" class="align-items-center d-inline-flex mr-2">
									<avatar-component [userRef]="reviewer" [class]="'select-image'"></avatar-component>
									{{reviewer.fullName}}<br>
								</td>
							</ng-container>
							<ng-template #noReviewers>
								<td>{{CB.td_noReviewers | localize | async}}</td>
							</ng-template>
							<td>{{review.version}}</td>
							<td>{{review.getEpochAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!--End Pending Reviews table-->

		<!--Table: Submissions in Review-->
		<div *ngIf="filteredReviews.lessonsInReview.length > 0" class="card card-accent-light mx-md-5 mt-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_inReview | localize | async}}</h5>
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_view_recall | localize | async}}</th>
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_reviewer | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_startDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let review of filteredReviews.lessonsInReview">
						<tr>
							<th scope="row">
								<span>
									<a href="javascript:void(0);" [routerLink]="[review.guid]"
									   style="text-decoration: none;"><i
											tooltip="{{CB.tooltip_view | localize | async}}"
											class="far fa-2x fa-eye mr-3"></i></a>
									<a href="javascript:void(0);" [routerLink]="[review.guid, 'edit']"
									   style="text-decoration: none;"><i
											tooltip="{{CB.tooltip_recall | localize | async}}"
											class="fas fa-2x fa-undo-alt"></i>
									</a>
								</span>
							</th>
							<td>{{LocalizeTools.document(review.resourceSubmission, ResourceSubmissionTextContent).title| slice: 0:40}}</td>
							<ng-container *ngIf="review.reviewers.length > 0 ; else noReviewers">
								<td *ngFor="let reviewer of review.reviewers" class="align-items-center d-inline-flex mr-2">
									<avatar-component [userRef]="reviewer" [class]="'select-image'"></avatar-component>
									{{reviewer.fullName}}<br>
								</td>
							</ng-container>
							<ng-template #noReviewers>
								<td>{{CB.td_noReviewers | localize | async}}</td>
							</ng-template>
							<td>{{review.version}}</td>
							<td>{{review.getReviewStartAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!--End Review in Progress table-->

		<!--Table: Reviews In Continuation-->
		<div *ngIf="filteredReviews.lessonsInReviewContinuation.length > 0" class="card card-accent-light mx-md-5 mt-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_reviewContinuation | localize | async}}</h5>
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_view | localize | async}}</th>
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_reviewer | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_startDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let review of filteredReviews.lessonsInReviewContinuation">
						<tr>
							<th scope="row"><a href="javascript:void(0);" [routerLink]="[review.guid]"
							                   style="text-decoration: none;"><i
									tooltip="{{CB.tooltip_view | localize | async}}" class="far fa-2x fa-eye"></i></a>
							</th>
							<td>{{LocalizeTools.document(review.resourceSubmission, ResourceSubmissionTextContent).title | slice: 0:40}}</td>
							<ng-container *ngIf="review.reviewers.length > 0 ; else noReviewers">
								<td *ngFor="let reviewer of review.reviewers" class="align-items-center d-inline-flex mr-2">
									<avatar-component [userRef]="reviewer" [class]="'select-image'"></avatar-component>
									{{reviewer.fullName}}<br>
								</td>
							</ng-container>
							<ng-template #noReviewers>
								<td>{{CB.td_noReviewers | localize | async}}</td>
							</ng-template>
							<td>{{review.resourceSubmission.version}}</td>
							<td>{{review.getReviewStartAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!--End Review in Progress table-->

		<!--Table: Collaborations-->
		<div *ngIf="collaborations.length > 0" class="card card-accent-light mx-md-5 mt-md-5 mb-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_collaborations | localize | async}}</h5>
				<!-- Drafts -->
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_edit_view | localize | async}}</th><!-- Edit | View -->
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_submitter | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_updatedDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let draft of collaborations">
						<tr>
							<th scope="row">
								<span>
									<a href="javascript:void(0);" [routerLink]="[draft.guid, 'edit']"
									   style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_edit | localize | async}}"
										   class="fas fa-2x fa-pencil-alt mr-md-3"></i>
									</a>
									<a href="javascript:void(0);" [routerLink]="[draft.guid]"
									   style="text-decoration: none;">
										<i tooltip="{{CB.tooltip_view | localize | async}}"
										   class="far fa-2x fa-eye"></i>
									</a>
								</span>
							</th>
							<td>{{LocalizeTools.document(draft, ResourceSubmissionTextContent).title | slice: 0:40}}</td>
							<td class="align-items-center d-inline-flex mr-2">
								<avatar-component [userRef]="draft.submitter" [class]="'select-image'"></avatar-component>
								{{draft.submitter.fullName}}
							</td>
							<td>{{draft.version}}</td>
							<td>{{draft.getModifiedAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div> <!--End Collaborations table-->

		<!--Table: Review Successful Review-->
		<div *ngIf="publishedLessons.length > 0" class="card card-accent-light mx-md-5 mt-md-5 mb-md-5">
			<div class="card-body">
				<h5 class="card-title text-black font-weight-bold my-3">{{CB.section_published | localize | async}}</h5>
				<div>
					<table class="table table-hover table-responsive-lg">
						<thead>
						<tr>
							<th scope="col">{{CB.th_view | localize | async}}</th>
							<th scope="col">{{CB.th_title | localize | async}}</th>
							<th scope="col">{{CB.th_reviewers | localize | async}}</th>
							<th scope="col">{{CB.th_version | localize | async}}</th>
							<th scope="col">{{CB.th_publishDate | localize | async}}</th>
						</tr>
						</thead>
						<tbody *ngFor="let lesson of publishedLessons">
						<tr>
							<th scope="row"><a href="javascript:void(0);" routerLink="../../{{lesson.guid}}"
							                   style="text-decoration: none;"><i
									tooltip="{{CB.tooltip_view | localize | async}}" class="far fa-2x fa-eye"></i></a>
							</th>
							<td>{{LocalizeTools.document(lesson.resourceSubmission, ResourceSubmissionTextContent).title | slice: 0:40}}</td>
							<ng-container *ngIf="lesson.reviewers.length > 0; else no_reviewer">
								<td *ngFor="let reviewer of lesson.reviewers" class="align-items-center d-inline-flex mr-2">
									<avatar-component [userRef]="reviewer" [class]="'select-image'"></avatar-component>
									{{reviewer.fullName}}<br>
								</td>
							</ng-container>
							<ng-template #no_reviewer>
								<td>{{CB.td_noReviewers | localize | async}}</td>
							</ng-template>
							<td>{{lesson.version}}</td>
							<td>{{lesson.resourceSubmission.getApprovedAsTime()}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!--End Successful Reviews table-->

		<div class="card-footer">
			<a class="text-secondary" href="javascript:void(0);" style="text-decoration: none;"
			   [routerLink]="['/resources/create']"><strong>{{CB.create_new | localize | async}}</strong></a>
		</div>

	</div>
</div>
