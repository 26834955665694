import {Converter} from "../ConversionService";
import {Framework} from "../../domain/frameworks/Framework";
import {SelectableFrameworkRef} from "../../domain/frameworks/SelectableFrameworkRef";
import {FrameworkRef} from "../../domain/frameworks/FrameworkRef";

//TODO: This is probably no longer relevant
export class FrameworkToSelectableFrameworkRefConverter implements Converter {

	public from:typeof Framework;
	public to:typeof SelectableFrameworkRef;

	constructor() {
		this.from = Framework;
		this.to = SelectableFrameworkRef;
	}

	public convert(source:Framework):SelectableFrameworkRef {

		//Create the target object

		let frameworkRef:FrameworkRef = new FrameworkRef(source.guid, source.title);

		let selectableFrameworkRef:SelectableFrameworkRef = new SelectableFrameworkRef();
			selectableFrameworkRef.ref = frameworkRef;
			selectableFrameworkRef.actual = source;


		return selectableFrameworkRef;
	}
}
