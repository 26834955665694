import {
	AbstractFirestoreRepository,
	OrderByCriterion,
	OrderByDirections,
	QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {ResourceSubmissionStatus} from "../../domain/resources/ResourceSubmission";
import {PublishedResource} from "../../domain/resources/PublishedResource";
import {RefCachingService} from "../RefCachingService";
import {PublishedResourceRef} from "../../domain/resources/PublishedResourceRef";
import {ConversionService} from "../ConversionService";


@Injectable({
	providedIn: 'root',
} as any)
export class PublishedResourceRepository extends AbstractFirestoreRepository<PublishedResource, PublishedResourceRef> {

	public static PATH = "Resources";

	public by = {
		criterion:{
			submitter:(submitterGuid:string) =>
				QueryCriterion.create("resourceSubmission.submitter.guid", WhereFilterOperations.EqualTo, submitterGuid),
			status:(status:ResourceSubmissionStatus) =>
				QueryCriterion.create("resourceSubmission.status", WhereFilterOperations.EqualTo, status),
			organization:(orgGuid:string) =>
				QueryCriterion.create("resourceSubmission.organization.guid", WhereFilterOperations.EqualTo, orgGuid),
			hasReviewer:(reviewerGuid:string) =>
				QueryCriterion.create("@reviewers", WhereFilterOperations.ArrayContains, reviewerGuid),
			aina:(ainaGuid:string) =>
				QueryCriterion.create("@aina", WhereFilterOperations.ArrayContains, ainaGuid)
		},
		order: {
			byIndexedTitle: ()=> OrderByCriterion.create("indexedTitle", OrderByDirections.Ascending)
		},
		criteria:{
			all:() => new QueryCriteriaBuilder()
				.orderBy(this.by.order.byIndexedTitle()).toCriteria(),
			aina:(ainaGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.aina(ainaGuid)).toCriteria(),
			submitter:(submitterGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.submitter(submitterGuid)).toCriteria(),
			submitterWithStatus:(submitterGuid:string, status:ResourceSubmissionStatus) => new QueryCriteriaBuilder()
				.where(this.by.criterion.submitter(submitterGuid))
				.where(this.by.criterion.status(status)).toCriteria(),
			orgAndReviewer:(orgGuid:string, reviewerGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.organization(orgGuid))
				.where(this.by.criterion.hasReviewer(reviewerGuid)).toCriteria(),
			organization:(orgGuid:string) => new QueryCriteriaBuilder()
				.where(this.by.criterion.organization(orgGuid)).toCriteria(),
		},
		queryFn: {
			all:  ():QueryFn => new QueryCriteriaBuilder(this.by.criteria.all()).toQueryFn(),
			aina:  (ainaGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.aina(ainaGuid)).toQueryFn(),
			submitter:  (submitterGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.submitter(submitterGuid)).toQueryFn(),
			submitterWithStatus:  (submitterGuid:string, status:ResourceSubmissionStatus):QueryFn => new QueryCriteriaBuilder(this.by.criteria.submitterWithStatus(submitterGuid, status)).toQueryFn(),
			orgAndReviewer:  (orgGuid:string, reviewerGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.orgAndReviewer(orgGuid, reviewerGuid)).toQueryFn(),
			organization:  (orgGuid:string):QueryFn => new QueryCriteriaBuilder(this.by.criteria.organization(orgGuid)).toQueryFn()
		}
	};
	public indexes = {/*
		all:  ():FirestoreIndex => new QueryCriteriaBuilder(this.by.criteria.all()).toFirestoreIndex(PublishedResourceRepository.PATH),
*/	};

	constructor(protected db:AngularFirestore, protected refCachingService:RefCachingService, protected conversionService:ConversionService) {
		super(PublishedResource, db, db.collection(PublishedResourceRepository.PATH), "guid", conversionService, PublishedResourceRef );
		this.refCachingService.registerProcessor(this);
		refCachingService.registerSource(this); //for Collections

		//TODO: Turn back on, after confirming why it was turned off in the first place (weird thing going on)
		this.settings.caching.cacheObjects = true;
		this.settings.caching.cacheRefs = true;
	}

}
