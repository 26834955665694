<div bsModal class="w-100 position-absolute" role="dialog" aria-labelledby="Reviewer comment box" aria-hidden="true" *ngIf="component">
    <div class="modal-dialog myModal modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{CB.title | localize | async}}</h4>
                <button type="button" class="close" (click)="component.onCancelCommentFieldClick()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form class="form-group has-feedback">
                    <span class="help-block">{{CB.label | localize | async}}</span>
                    <textarea [(ngModel)]="component.reviewField.reviewerComment" *ngIf="component && component.reviewField" name="comments" rows="5" class="form-control"
                              placeholder="{{CB.placeholder | localize | async}}"></textarea>
                </form>
            </div>
            <div class="modal-footer">
				<button type="button" class="btn btn-primary" (click)="component.onSaveCommentClick()">{{CB.save | localize | async}}</button>
				<button type="button" class="btn btn-secondary" (click)="component.onCancelCommentFieldClick()">{{CB.cancel | localize | async}}</button>
				<button *ngIf="component.hasComments" type="button" class="btn btn-danger" (click)="component.onDeleteCommentFieldClick()">{{CB.delete | localize | async}}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
