import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../../../BaseComponent";
import {Localization} from "../../../../data/Localization";
import {BadgeService} from "../../../../services/BadgeService";
import {Badge} from "../../../../domain/badges/Badge";
import {SupportedLanguage} from "../../../../domain/SupportedLanguages";
import {BadgeTextContent} from "../../../../domain/badges/BadgeTextContent";

@Component({
	selector: 'app-badge-detail',
	templateUrl: './badge-detail.component.html',
	styleUrls: ['./badge-detail.component.scss']
})
export class BadgeDetailComponent extends BaseComponent implements OnInit {

	public badge:Badge;
	private cbContext = Localization.template.badges.detail;
	public CB = {
		header_criteria: this.cbContext.headers.criteria,
		header_people: this.cbContext.headers.people,
		noUsers: this.cbContext.text.no_users,
		editBtn: this.cbContext.badge_buttons.edit,
		awardBtn: this.cbContext.badge_buttons.award,
	};

	constructor(protected injector:Injector,
	            private badgeService:BadgeService) {
		super(injector);

		this.localizationService.registerAndLocalize("BadgeDetailComponent", this.CB);

		const badgeGuid:string = this.route.snapshot.params['guid'] != "" ? this.route.snapshot.params['guid'] : null;

		if (badgeGuid != null) {
			this.trackSubscription(this.badgeService.getBadgeById$(badgeGuid).subscribe(badge => {
				this.badge = badge;

				//	todo: get a query from BadgeAwardedService for users that have earned this badge

			}));
		}
	}

	ngOnInit() {
		this.logger.log(`BadgeDetailComponent::oninit`);
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};

	public get currentLanguage():SupportedLanguage {
		return this.localizationService.language;
	}

	public get badgeImage():string {
		return this.urlService.imageUrl.badge(this.badge.guid);
	}

	public get badgeContent():BadgeTextContent {
		return this.badge.documentTextContent[this.currentLanguage] ? this.badge.documentTextContent[this.currentLanguage] : this.badge.documentTextContent[SupportedLanguage.en];
	}

	/** user can edit this badge */
	public get canEdit():boolean {
		return this.roleService.hasGeneralOrACLPermissionsFor.badges.edit();
	}

	/** user can award this badge to another user */
	public get canAward():boolean {
		return this.roleService.hasGeneralOrACLPermissionsFor.badges.edit();
	}

}
