import {Injectable} from "@angular/core";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable, Subject} from "rxjs";
import {InviteUserMessage} from "../messages/InviteUserMessage";
import {OrganizationVerificationMessage} from "../messages/OrganizationVerificationMessage";
import {NGXLogger} from "ngx-logger";
import {AbstractFirebaseFunctionsFactory} from "./AbstractFirebaseFunctionsFactory";
import {AbstractFirebaseFunction, IServerError, IWarmUpReturn} from "./AbstractFirebaseFunction";

interface OrganizationFunctionWrappers {
	inviteUserToOrg: AbstractFirebaseFunction<InviteUserMessage>,
	verifyUserWithOrganization: AbstractFirebaseFunction<OrganizationVerificationMessage>
}

@Injectable({
	providedIn: 'root',
} as any)
export class OrganizationFunctions extends AbstractFirebaseFunctionsFactory {

	public methods:OrganizationFunctionWrappers;

	constructor(protected functions:AngularFireFunctions, protected logger:NGXLogger) {
		super(functions, {inviteUserToOrg: InviteUserMessage, verifyUserWithOrganization: OrganizationVerificationMessage});
	}

	public warmUpInviteUserToOrg$() {
		this.logger.info(`RegistrationFunctions is warming up the 'inviteUserToOrg' firebase function on the server.`);
		this.logger.info(`RegistrationFunctions::warmUpInviteUserToOrg$`);
		let response:Observable<IWarmUpReturn> = this.methods.inviteUserToOrg.warmUp();
		this.logger.info(`RegistrationFunctions::warmUpInviteUserToOrg$ done`);
		return response;
	}

	public inviteUserToOrg$(message:InviteUserMessage):Observable<InviteUserMessage|IServerError<InviteUserMessage>> {
		this.logger.info(`OrganizationFunctions::inviteUserToOrg$`);
		let response:Observable<InviteUserMessage|IServerError<InviteUserMessage>> = this.methods.inviteUserToOrg.call(message);
		this.logger.info(`OrganizationFunctions::inviteUserToOrg$ done`);
		return response;
	}

	/** As a organization, verify the user is part of an org, ... or not */
	public verifyUserWithOrg$(message:OrganizationVerificationMessage):Observable<OrganizationVerificationMessage|IServerError<OrganizationVerificationMessage>> {
		this.logger.info(`OrganizationFunctions::verifyUserWithOrg$`);
		let response$:Observable<OrganizationVerificationMessage|IServerError<OrganizationVerificationMessage>> = this.methods.verifyUserWithOrganization.call(message);
		this.logger.info(`OrganizationFunctions::verifyUserWithOrg$ done`);
		return response$;
	}

}
