import {Component, OnInit, ViewChild} from "@angular/core";
import {StubData} from "../../../../data/StubData";
import {Framework, FrameworksType} from "../../../../domain/frameworks/Framework";
import {FrameworkService} from "../../../../services/FrameworkService";
import {ActivatedRoute} from "@angular/router";
import {ValidationException} from "../../../../services/exceptions/ValidationException";
import {NotificationService, ToastType} from "../../../../services/common/NotificationService";
import {ValidationErrorService} from "../../../../services/common/ValidationErrorService";
import {TreeNode, ITreeState, TreeComponent} from "angular-tree-component";
import {LocalizationService} from "../../../../services/LocalizationService";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../data/Localization";
import {
	FrameworkImportExportModalComponent,
	IFrameworkImportExportModalInput
} from "../../../modals/framework-import-export-modal/framework-import-export-modal.component";
import {FrameworkNode} from "../../../../domain/frameworks/FrameworkNode";
import {IFieldMaxLengthMap, ValidationMetadataService} from "../../../../services/common/ValidationMetadataService";
import {merge} from "lodash";

@Component({
	selector: 'framework-edit',
	templateUrl: './framework-edit.component.html',
	styleUrls: ['./framework-edit.component.scss'],
	providers: [FrameworkService, ValidationErrorService]
})
export class FrameworkEditComponent implements OnInit {

	private cbContext = Localization.template.frameworks.edit;
	public CB = {
		header: this.cbContext.header,
		sidebar_title: this.cbContext.sidebar.title,
		addButton: this.cbContext.sidebar.buttons.add,
		deleteButton: this.cbContext.sidebar.buttons.delete,
		upButton: this.cbContext.sidebar.buttons.moveUp,
		downButton: this.cbContext.sidebar.buttons.moveDown,
		importAndExport: this.cbContext.sidebar.buttons.importAndExport,
		form_header: this.cbContext.form.header,
		form_labelTitle: this.cbContext.form.labels.title,
		form_labelSummary: this.cbContext.form.labels.summary,
		form_labelDescription: this.cbContext.form.labels.description,
		form_labelElementName: this.cbContext.form.labels.elementName,
		form_labelElementDescr: this.cbContext.form.labels.elementDescription,
		form_placeholderTitle: this.cbContext.form.placeholders.title,
		form_placeSummary: this.cbContext.form.placeholders.summary,
		form_placeDescr: this.cbContext.form.placeholders.description,
		form_placeElemName: this.cbContext.form.placeholders.elementName,
		form_placeElemDescr: this.cbContext.form.placeholders.description,
		meta_header: this.cbContext.meta.header,
		meta_type: this.cbContext.meta.type,
		meta_place: this.cbContext.meta.placeholder,
		save: this.cbContext.save
	};

	public framework: Framework;
	public frameworkTreeOptions: any = {allowDrag: true, allowDrop: true};
	public activatedNode: TreeNode = null;
	public activatedNodeDesc: string = null;
	public activatedNodeName: string = null;
	public activatedNodeId: string = null;
	public frameworkTreeState: ITreeState;
	public FrameworksType: any;
	public frameworksTypeArray: string[];
	public maxLengths:IFieldMaxLengthMap<Framework | FrameworkNode> = null;

	@ViewChild(TreeComponent)
	private tree: TreeComponent;

	public isSaving:boolean;

	public editorModules = {
		toolbar: [
			[{'align': []}],
			[{'size': ['small', false, 'large', 'huge']}],  // size
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['blockquote', 'code-block'],

			[{'list': 'ordered'}, {'list': 'bullet'}],
			//[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
			[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

			//[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
			['clean'],                                         // remove formatting button

			['link']                         // link
			//['link', 'image']                         // link and image, video
		],
		clipboard: {
			matchVisual: false
		}
	};

	constructor(private frameworkService: FrameworkService,
				private stubData: StubData,
				private route: ActivatedRoute,
				private localizationService: LocalizationService,
				private notificationService: NotificationService,
				private validationErrorService: ValidationErrorService,
				private validationMetadataService:ValidationMetadataService,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("FrameworkEditComponent", this.CB);

		this.FrameworksType = FrameworksType;
		this.frameworksTypeArray = Object.keys(this.FrameworksType).map(key => this.FrameworksType[key as any])

		const frameworkGuid: string = this.route.snapshot.params['guid'] != "" ? this.route.snapshot.params['guid'] : null;

		if (frameworkGuid != null) {
			this.frameworkService.getFrameworkById(frameworkGuid).subscribe(ref => {
				this.framework = ref;
				this.logger.info("new ref " + ref.title);
			});
		} else {
			this.framework = new Framework();
			this.framework.guid = null;
		}

		this.maxLengths = this.validationMetadataService.getMaxLengths(Framework);
		merge(this.maxLengths, this.validationMetadataService.getMaxLengths(FrameworkNode));
	}

	ngOnInit() {
	}


	private flattenNodes(nodes:Array<FrameworkNode>):Array<FrameworkNode> {
		let self = this;
		return nodes.reduce(
			function (flatArray = [], node) {
				if (!node.children.length) {
					return flatArray.concat(node);
				} else {
					return flatArray.concat(self.flattenNodes(node.children)).concat(node);
				}
			},
			[]);
	}

	private removeNodeRecursive(nodes, nodeId) {
		let self = this;

		if (!(nodes.length == 0 || nodes === undefined)) {
			let filteredNodes = self.removeNode(nodes, nodeId);
			return filteredNodes.map(
				function (node) {
					if (!(node.children === undefined || node.children.length == 0)) {
						node.children = self.removeNodeRecursive(node.children, nodeId)
					}
					return node;
				}
			);
		} else {
			return [];
		}
	}

	private removeNode(nodes, nodeId) {
		return nodes.filter((obj) => obj.id !== nodeId);
	}

	private swapNodeRecursive(nodes, nodeId, direction) {
		let self = this;

		let nodeIndex = nodes.findIndex(node => node.id == nodeId);
		if (nodeIndex != -1) {
			self.swapNode(nodes, nodeIndex, direction);
		} else {
			nodes.forEach(function (node) {
				if (!(node.children === undefined || node.children.length == 0)) {
					self.swapNodeRecursive(node.children, nodeId, direction);
				}
			});
		}
	}

	public swapNode(nodes, nodeIndex, direction) {
		if (direction == "up") {
			if (nodeIndex > 0) {
				[nodes[nodeIndex], nodes[nodeIndex - 1]] = [nodes[nodeIndex - 1], nodes[nodeIndex]];
			}
		} else if (direction == "down") {
			if (nodeIndex < (nodes.length - 1)) {
				[nodes[nodeIndex], nodes[nodeIndex + 1]] = [nodes[nodeIndex + 1], nodes[nodeIndex]];
			}
		}
	}

	public displayImportExportModal():void {
		let data:IFrameworkImportExportModalInput = {
			framework: this.framework
		};
		this.notificationService.displayModal(FrameworkImportExportModalComponent, this, data);
	}

	public setActiveNodeData() {
		if (this.activatedNodeId !== null) {
			let activeNodeId = this.activatedNodeId;
			let obj = this.flattenNodes(this.framework.elements).find(node => node.id == activeNodeId);

			if (obj) {
				obj.name = this.activatedNodeName;
				obj.description = this.activatedNodeDesc;
			}
		}
	}

	public onSubmitClick() {
		this.logger.info("Submitting Framework");

		// clear validation errors and warnings
		this.validationErrorService.updateErrors([]);
		this.validationErrorService.updateWarnings([]);

		this.setActiveNodeData();

		try {
			if (this.framework === undefined) {
				this.framework = new Framework();
			}
			this.validationErrorService.validate(this.framework);
			this.isSaving = true;
			this.frameworkService.save(this.framework).subscribe(() => {
				this.notificationService.displayToast(this.cbContext.toast.success, ToastType.success);
				this.isSaving = false;
			});
			this.logger.info("submitted");
		} catch (ex) {
			if (ex instanceof ValidationException) {
				this.validationErrorService.updateErrors(ex.errors);
				//todo: Update toast to error using error text
				this.notificationService.displayToast(this.cbContext.toast.fail, ToastType.warning);
			}
			this.logger.info("failed submitting");
			this.isSaving = false;
		}
	}

	onElementSelected(event) {
		if (this.activatedNode) {
			this.setActiveNodeData();
		}
		this.activatedNode = event.node;
		this.activatedNodeDesc = event.node.data.description;
		this.activatedNodeName = event.node.data.name;
		this.activatedNodeId = event.node.data.id;
	}

	public addNode() {
		let newNodeGuid:string = this.guid();
		let newNode:FrameworkNode = {id: newNodeGuid, name: "New Element", description: "", children: []};
		if(this.activatedNode) {
			let nodes = this.flattenNodes(this.framework.elements);
			let matchingNode:FrameworkNode = nodes.find(node => {
				return node.id == this.activatedNode.id;
			});
			if(matchingNode) {
				matchingNode.children.push(newNode)
				this.framework.elements = [...this.framework.elements];

				if(this.activatedNode.isCollapsed) {
					this.activatedNode.expand();
				}
				setTimeout(()=> {
					let treeNode:TreeNode = this.tree.treeModel.getNodeById(newNodeGuid);
					if(treeNode) {
						treeNode.setActiveAndVisible(); //move into view
					}
				},4);
			} else {
				this.framework.elements = [...this.framework.elements, newNode];
			}
		} else {
			this.framework.elements = [...this.framework.elements, newNode];
		}

	}

	public deleteNode() {
		this.framework.elements = this.removeNodeRecursive(this.framework.elements, this.activatedNodeId);
	}

	public moveNodeUp() {
		this.swapNodeRecursive(this.framework.elements, this.activatedNodeId, "up");
		this.tree.treeModel.update();
	}

	public moveNodeDown() {
		this.swapNodeRecursive(this.framework.elements, this.activatedNodeId, "down");
		this.tree.treeModel.update();
	}

	private guid() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}

		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	public get fullDescriptionPlainText():string {
		return this.validationMetadataService.getPlainText(this.framework.fullDescription);
	}

}

