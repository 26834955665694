import {IsNotEmpty, IsOptional, MaxLength, MinLength} from 'class-validator';
import {RegisterClazz} from "../RegisterType";
import {Exclude} from "class-transformer";
import {SupportedLanguage} from "../SupportedLanguages";
import {ITextContent} from "../ITextContent";
import {MaxPlainTextLength} from "../../validators/MaxPlainTextLength";

@RegisterClazz(ResourceSubmissionTextContent)
export class ResourceSubmissionTextContent implements ITextContent {

	@Exclude()
	public $render:{[key:string]:SupportedLanguage};

	/** The Title for the Resource */
	@MinLength(5, {groups: ['requiredToSave']})
	@MaxLength(48, {groups: ['requiredToSave']})
	@IsNotEmpty({groups: ['requiredToSave']})
	public title:string = "";

	/** A brief summary */
	@MinLength(5)
	@MaxLength(100)
	@IsNotEmpty()
	public summary:string = "";

	/** A full description of the resource */
	@MinLength(10)
	@MaxPlainTextLength(8000)
	@IsNotEmpty()
	public description:string = "";

	/** Sources, Attribution, Usage Restrictions, and other notes    OPTIONAL. */
	@MaxPlainTextLength(1500, {message: "The attribution notes of your resource cannot be more than 1500 characters long"})
	@IsOptional()
	public notes:string = "";

	/** suggestions for ways to modify this lesson.    OPTIONAL. */
	@MaxLength(1500)
	@IsOptional()
	public modifications:string = "";

	constructor() {
		this.$render = {};
	}

}
