import {Injectable} from "@angular/core";
import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {FanContribution} from "../../domain/resources/FanContribution";


@Injectable()
export class FanContributionRepository extends AbstractFirestoreRepository<FanContribution> {

	constructor (protected db:AngularFirestore) {
		super(FanContribution, db, db.collection("FanContributions"));

	}

	public byResource(resourceGuid:string):QueryFn {
		let queryFn:QueryFn  = ref => {
			return ref.where("resourceSubmissionRef.guid", '==', resourceGuid);
		};
		return queryFn;
	}

	public bySubmitter(submitterGuid:string):QueryFn {
		let queryFn:QueryFn  = ref => {
			return ref.where("resourceSubmissionRef.submitter.guid", '==', submitterGuid);
		};
		return queryFn;
	}

	public byFan(fanGuid:string):QueryFn {
		let queryFn:QueryFn  = ref => {
			return ref.where("fanRef.guid", '==', fanGuid);
		};
		return queryFn;
	}

}
