import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FanContribution} from "../../../../domain/resources/FanContribution";
import {Localization} from "../../../../data/Localization";
import {LearningAsset} from "../../../../domain/resources/LearningAsset";
import {NotificationService, ToastType} from "../../../../services/common/NotificationService";
import {ResourceSubmission} from "../../../../domain/resources/ResourceSubmission";
import {ResourceSubmissionService} from "../../../../services/ResourceSubmissionService";
import {SupportedLanguage} from "../../../../domain/SupportedLanguages";
import {SubscriptionCleaner} from "../../../../util/SubscriptionCleaner";
import {UrlService} from "../../../../services/UrlService";
import {DownloadService} from "../../../../services/DownloadService";
import {FileService} from "../../../../services/FileService";

@Component({
  selector: '.app-fan-contribution-review',
  templateUrl: './fan-contribution-review.component.html',
  styleUrls: [
    '../single/edit-resource-submission/edit-resource-submission.component.scss',
    './fan-contribution-review.component.scss']
})
export class FanContributionReviewComponent extends SubscriptionCleaner implements OnInit {


  private toastContext = Localization.template.resource.edit.toasts;
  private commonToasts = Localization.template.resource.common_toasts;
  private cbContext = Localization.template.resource.edit.fan;
  public CB = {
    fan_header: this.cbContext.header,
    fan_text: this.cbContext.text,
    fan_card_header: this.cbContext.card_header,
    fan_card_text: this.cbContext.card_text,
    fan_table_header: this.cbContext.table.header,
    fan_table_filename: this.cbContext.table.filename,
    fan_table_summary: this.cbContext.table.summary,
    fan_table_tag: this.cbContext.table.tags,
    button_accept: Localization.template.buttons.accept,
    button_decline: Localization.template.buttons.decline,
  }

  @Input()
  public fanContributions:Array<FanContribution> = [];
  @Input()
  public resourceSubmission:ResourceSubmission;

  @Input()
  public currentLanguage:SupportedLanguage;

  @Output()
  public accept:EventEmitter<FanContribution> = new EventEmitter<any>();

  @Output()
  public decline:EventEmitter<FanContribution> = new EventEmitter<any>();

  constructor(protected resourceSubmissionService:ResourceSubmissionService, protected notificationService:NotificationService, protected urlService:UrlService,
			  protected fileService:FileService,
              protected downloadService:DownloadService) {
    super();

  }

  ngOnInit() {
  }

  //fan contribution asset download
  public downloadFanAsset(fanContribution:FanContribution, learningAsset:LearningAsset) {
    let fanAssetURL:string = this.urlService.fanContribution.assets.file(fanContribution.guid, learningAsset.fileName);
    try {
      this.trackSubscription(this.downloadService.tryDownload(fanAssetURL).take(1).subscribe((response) => {
        if (response === "File Not Found") {
          this.notificationService.displayToast(this.toastContext.download.problem, ToastType.error);
        }
      }));
    } catch (err) {
      console.error(err);
    }
  }

	// open fan contribution asset
	public openFanAsset(fanContribution:FanContribution, learningAsset:LearningAsset) {
		let fanAssetURL:string = this.urlService.fanContribution.assets.file(fanContribution.guid, learningAsset.fileName);
		try {
			this.trackSubscription(this.fileService.tryOpen(fanAssetURL).take(1).subscribe((response) => {
				if (response === "File Not Found") {
					this.notificationService.displayToast(this.commonToasts.file_open.problem, ToastType.error);
				}
			}));
		} catch (err) {
			console.error(err);
		}
	}

  public acceptFanContribution(fanContribution:FanContribution):void {
    console.info(`"Accept" button was clicked on fan contribution ${fanContribution.guid}`);
    this.resourceSubmissionService.acceptFanContribution(fanContribution, this.resourceSubmission, this.currentLanguage); //This causes an error, its okay...but should upgrade to new functions call
    this.fanContributions.splice(this.fanContributions.indexOf(fanContribution), 1);  //Former Kalani did this...should think about this..
    this.notificationService.displayToast(this.toastContext.fanContribution.accept, ToastType.warning);
    this.accept.emit(fanContribution);
  }

  public declineFanContribution(fanContribution:FanContribution):void {
    console.info(`"Decline" button was clicked on fan contribution ${fanContribution.guid}`);
    this.resourceSubmissionService.declineFanContribution(fanContribution, this.resourceSubmission);//This causes an error, its okay...but should upgrade to new functions call
    this.fanContributions.splice(this.fanContributions.indexOf(fanContribution), 1);//Former Kalani did this...should think about this..
    this.notificationService.displayToast(this.toastContext.fanContribution.decline, ToastType.info);
    this.accept.emit(fanContribution);
  }

}
