import {Component, OnInit} from '@angular/core';
import {Framework} from "../../../../domain/frameworks/Framework";
import {ActivatedRoute} from "@angular/router";
import {FrameworkService} from "../../../../services/FrameworkService";
import {Organization} from "../../../../domain/organization/Organization";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../data/Localization";
import {LocalizationService} from "../../../../services/LocalizationService";


@Component({
	selector: 'framework-detail',
	templateUrl: './framework-detail.component.html',
	styleUrls: ['./framework-detail.component.scss']
})
export class FrameworkDetailComponent implements OnInit {

	private cbContext = Localization.template.frameworks.detail;
	public CB = {
		title: this.cbContext.card_title,
		description: this.cbContext.description,
		element: this.cbContext.element,
		type: this.cbContext.type
	};

	public framework:Framework;
	public frameworkTreeOptions:any = {};
	public activatedNodeDesc = null;
	public activatedNodeName = null;
	public matchingOrganizations:Organization[] = [];

	constructor(private route:ActivatedRoute,
				private frameworkService:FrameworkService,
				private localizationService:LocalizationService,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("FrameworkDetailComponent", this.CB);

		const frameworkGuid:string = this.route.snapshot.params['guid'] != "" ? this.route.snapshot.params['guid'] : null;
		//    todo: check for null

		this.logger.info("loaded");
		this.frameworkService.getFrameworkById(frameworkGuid).subscribe(framework => {
			this.framework = framework;
		});

	}

	ngOnInit() {
	}

	onEvent(event) {
		this.activatedNodeDesc = event.node.data.description;
		this.activatedNodeName = event.node.data.name;
	};

}
