<div>
	<div *ngIf="exists" class="d-flex align-items-center justify-content-center">
		<img [ngClass]="class" [ngStyle]="{'background-color':color}" src="{{src}}" alt="{{initials}}">
	</div>
	<div *ngIf="!exists">
		<div class="d-flex align-items-center justify-content-center" [ngClass]="class" [ngStyle]="{'background-color':color}">
			<span class="avatar-initials">{{initials}}</span>
		</div>
	</div>
</div>
