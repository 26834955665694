import {ValidationError} from "class-validator";

export class ValidationException extends Error {

    public errors:Array<ValidationError> = [];

    constructor($errors:ValidationError[]) {
        super("Validation Error"); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

        for(let i=0; i < $errors.length; i++) {
            const error:ValidationError = $errors[i];
            const errorObj:any = {
                "property": error.property,
                "error": error.constraints
            };

            if(errorObj.error == null) {
                errorObj.error = this.findConstraintChild(error);
            }
            this.errors.push(errorObj);
        }
    }

    //TODO: This only looks at errors on the first sub-child (not everything).
    public findConstraintChild(o:ValidationError):{[type:string]:string;} {
        if(o.constraints != null) {
            return o.constraints;
        } else {
            //contraints is null look further down.
            if(o.children != null && o.children.length > 0) {
                return this.findConstraintChild(o.children[0]);
            }
        }
        return {};
    }
}
