import {Converter} from "../../ConversionService";
import {MappingDestination, MappingDestinationRef} from "../../../areas/admin/email/email-event-mapper/email-domains";

export class MappingDestinationToMappingDestinationRefConverter implements Converter {

	public from:typeof MappingDestination;
	public to:typeof MappingDestinationRef;

	constructor() {
		this.from = MappingDestination;
		this.to = MappingDestinationRef;
	}

	public convert(source:MappingDestination):MappingDestinationRef {
		//Create the target object
		let mappingDestinationRef:MappingDestinationRef = new MappingDestinationRef();
			mappingDestinationRef.guid = source.guid;

		return mappingDestinationRef;
	}
}
