import {classToClass, classToPlain, Exclude, plainToClass, Transform, Type} from "class-transformer";
import {ContentBlockTranslation} from "./ContentBlockTranslation";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {IndexProperty} from "../IndexProperty";

@RegisterClazz(ContentBlock, "ContentBlocks")
export class ContentBlock {
	@Exclude({toPlainOnly: true})
	public guid:string;

	@Transform((value:number, obj, type) => value == null ? null : new Date(value), { toClassOnly: true })
	@Transform((value:Date, obj, type) => value ? value.getTime() : null, { toPlainOnly: true })
	public imported:Date = null;

	@Transform((value:object, obj, type) => {
		//From object to object map of ContentBlockTranslations
		let keys:Array<string> = Object.keys(value);
		// console.log(JSON.stringify(keys));
		let returnObject:{[key:string]:ContentBlockTranslation} = {};

		for (let iKey in keys) {
			let key:string = keys[iKey];
			let contentBlockTranslationPlain:any = value[key];
			let contentBlockTranslation:ContentBlockTranslation = plainToClass(ContentBlockTranslation, contentBlockTranslationPlain);
				returnObject[contentBlockTranslation.language] = contentBlockTranslation;
		}
		return returnObject;

	}, {toClassOnly: true})
	@Transform((value:{[key:string]:ContentBlockTranslation}, obj, type) => {
		//From object map of ContentBlockTranslations to a plain object map
		let keys:Array<string> = Object.keys(value);
		let returnObject:{[key:string]:{}} = {};

		for (let iKey in keys) {
			let key:string = keys[iKey];
			let contentBlockTranslation:ContentBlockTranslation = value[key];
			let contentBlockTranslationPlain:any = classToPlain(contentBlockTranslation);
			returnObject[contentBlockTranslation.language] = contentBlockTranslationPlain;
		}
		return returnObject;
	}, {toPlainOnly: true})
	@IndexProperty({isObjectMap: true, possibilities: ["en","haw"]})
	public content:{[key:string]:ContentBlockTranslation} = {};
	public contentType:ContentBlockContentType = ContentBlockContentType.text;

	constructor() {

	}

	/** Returns a deep copy of the object */
	public duplicate():ContentBlock {
		return classToClass(this);
	}

}

export enum ContentBlockContentType {
	"html"="html",
	"text"="text",
	"image"="image",
    "mustache"="mustache"
}
