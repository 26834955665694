import {RegisterClazz} from "../../RegisterType";

@RegisterClazz(Moku)
export class Moku {
	public mokupuni:string = "";
	public moku:string = "";
	public color:string = null;



	constructor(mokupuni:string = "", moku:string = "", color?:IslandColor) {
		if(color != null) {
			this.color = color;
		}

		this.mokupuni = mokupuni;
		this.moku = moku;
	}

	public get label() {
		return this.moku ? `${this.mokupuni}: ${this.moku}` : this.mokupuni;
	}
	public output() {
		return "-----------";
	}

}

export enum IslandColor {
	niihau = "island-white",
	kauai = "island-purple",
	oahu = "island-yellow",
	molokai = "island-green",
	lanai = "island-orange",
	kahoolawe = "island-gray",
	maui = "island-pink",
	hawaiiIsland = "island-red"
}
