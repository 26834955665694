import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {Organization, OrganizationFlags} from "../domain/organization/Organization";
import {ContactInfo, ContactInfoType} from "../domain/organization/ContactInfo";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserOrganization} from "../domain/user/WaihonaUserOrganization";
import {Role, RoleType} from "../domain/user/Role";
import {NewUserRegistration} from "../domain/user/NewUserRegistration";

export class DevelopmentTeamStubData {

	public static first_kabright:string = "Kalani";
	public static altFirstName_kabright:string = "";
	public static last_kabright:string = "Bright";
	public static uid_kabright:string = "kalani-bright";
	public static bio_kabright:string = "Kalani grew up on Kauaʻi in rural Wailua Home working on his family’s bird farm raising parrots and other birds. From a very young age (while still spending lots of time playing outdoors) he developed an interest in computer games from his families first Tandy 2000 Computer in the early 80’s. Kalani grew up teaching himself programming, game development, and web design from reading books he would pick up from trips to Honolulu and California. Kalani stayed in Hawaiʻi working in web design then left in 2003 to study Video Game Art & Design in San Diego; having an interest in educational games, he followed with a second degree in Learning Design & Technology. Kalani worked in the tech sector developing apps, software, and services for high tech businesses. He is experienced in art and software development. Kalani’s passion and mission revolves around developing apps, software, games, and cloud services centered around Hawaiian Language and Culture. He has recently returned to Hawaiʻi after 14 years away and is now employed in his dream job building apps, games, and server side technologies for Hawaiian Audiences. Its Kalani’s hope that Hawaiʻi’s youth can grow up into a thriving tech industry here which promotes authentic Hawaiian values and culture, that creates a sense of place and identity, and increases our sustainability and connectedness with each other, while strengthening our independence.";

	public static first_chjones:string = "Chryssa";
	public static altFirstName_chjones:string = "";
	public static last_chjones:string = "Jones";
	public static uid_chjones:string = "chryssa-jones";
	public static bio_chjones:string = "<p>Chryssa Keʻalaokamaile Jones is the shape-shifter of the development team.  Her broad career experience includes writing and editing, marketing, manufacturing and operations, retail management, community development, public speaking, travel and event planning,  theatrical production, college-level teaching as adjunct faculty, and university administration as a registrar, an applications reader, and a student affairs director.   After all that and still unable to decide what she wants to do when she grows up, Chryssa learned some new skills and joined the the creative folks on the Kealaiwikuamoʻo team.</p><p>Chryssa earned her B.S. in Business Management from Brigham Young University Hawaiʻi with dual minors in Hawaiian Language and Economics, and her M.B.A. degree in Management, with honors, from Hawaiʻi Pacific University.  Formerly an ʻAha Pūnana Leo scholar and mākua as well as a Kamehameha Schools scholar, Chryssa is grateful for the opportunity to be reconnected to the Hawaiian community and put her long-forgotten ʻōlelo skills to use.</p><p>Chryssa can fly airplanes and loves to travel, enjoys cooking and gardening, volunteering in her Koʻolauloa community, making messes with paint and clay, skiing and ice skating, horseback riding, and spending time with her family.</p>";

	public static first_wybartle:string = "Wyatt";
	public static altFirstName_wybartle:string = "Maui";
	public static last_wybartle:string = "Bartlett";
	public static uid_wybartle:string = "wyatt-bartlett";
	public static bio_wybartle:string = "<p>Aloha kākou. ʻO au ʻo Maui Bartlett, no nā Hono a Piʻilani mai, no Nāpili ua pulu ʻelo i ka ua Nōweoʻula. He pua nō au a Pauahi, a ʻo ʻAʻapueo kahi i mohala maila. Ma ka ʻāina uluwehi i ka ua Tuahine i loaʻa mai ai ke kēkelē laepua ma ka ʻEpekema Lolouila. ʻO ka ʻōlelo Hawaiʻi kaʻu māina. Ke kākoʻo nei nō au i ke Kime Duolingo ma ka hoʻonohonoho ʻana i nā hopuna ʻōlelo a me nā kani leo i loko o ka ʻōnaehana ʻo Duolingo.</p><p>Maui Bartlett provides technical support and feedback to the Duolingo Development Team. He received his B.S. in Computer Science from the University of Hawaiʻi at Mānoa, while also minoring in Hawaiian Language. He hails from Nāpili on the island of Maui, and is a proud graduate of the Kamehameha Schools Maui campus</p>";

	public static first_rygonzal:string = "Ryan";
	public static altFirstName_rygonzal:string = "Gonzo";
	public static last_rygonzal:string = "Gonzalez";
	public static uid_rygonzal:string = "ryan-gonzalez";
	public static bio_rygonzal:string = "Ryan “Gonzo” Gonzalez loves amplifying mo‘olelo through different forms of media in an effort to influence and impact how systems think and operate. He has spent the past 22 years working with and for Hawaiian communities in various roles. He is a digital artist, an award winning musician and composer, a director and producer of video things, and an old school web designer and developer. Mostly he tinkers. He is currently the Network Engagement Director in Kealaiwikuamo’o, a department within Kamehameha Schools that supports Kanaeokana, a network of over 60 schools and organizations committed to growing the next generations of aloha ‘āina leaders. As the director of Network Engagement, he has successfully surrounded himself with smarter and much more talented people and somehow tricked them into thinking that he’s not an idiot. Together, they create, build, and run all the communications and tech stuff for Kanaeokana. He also loves mud pie ice cream.";

	public static first_keabad:string = "Kēhaunani";
	public static altFirstName_keabad:string = "Kēhau";
	public static last_keabad:string = "Abad";
	public static uid_keabad:string = "kehaunani-abad";
	public static bio_keabad:string = "Kēhaunani Abad has spent years as a Hawaiian history and culture teacher wishing there were more engaging teaching resources for haumāna, trying to create some herself with meagre outcomes. Since then she’s had the great fortune of being a member of teams comprised of talented, tech-savvy folks working for visionary Hawaiian education leaders who as a collective are on the brink of bringing both old and new dreams to reality. She currently leads the Kealaiwikuamoʻo Department within Kamehameha Schools’ Kūamahi Community Education Division. Kealaiwikuamoʻo is dedicated to facilitating collaboration among the Kanaeokana network of P-20+ Hawaiian-focused schools and organizations whose vision is to grow and sustain the next generations of aloha ʻāina leaders.";

	public get kalani_waihonaUserReference(): WaihonaUserRef {
		return WaihonaUserRef.create(DevelopmentTeamStubData.uid_kabright, DevelopmentTeamStubData.first_kabright, DevelopmentTeamStubData.last_kabright, DevelopmentTeamStubData.altFirstName_kabright);
	}
	public get chjones_waihonaUserReference(): WaihonaUserRef {
		return WaihonaUserRef.create(DevelopmentTeamStubData.uid_chjones, DevelopmentTeamStubData.first_chjones, DevelopmentTeamStubData.last_chjones, DevelopmentTeamStubData.altFirstName_chjones);
	}
	public get wybartle_waihonaUserReference(): WaihonaUserRef {
		return WaihonaUserRef.create(DevelopmentTeamStubData.uid_wybartle, DevelopmentTeamStubData.first_wybartle, DevelopmentTeamStubData.last_wybartle, DevelopmentTeamStubData.altFirstName_wybartle);
	}
	public get rygonzal_waihonaUserReference(): WaihonaUserRef {
		return WaihonaUserRef.create(DevelopmentTeamStubData.uid_rygonzal, DevelopmentTeamStubData.first_rygonzal, DevelopmentTeamStubData.last_rygonzal, DevelopmentTeamStubData.altFirstName_rygonzal);
	}
	public get keabad_waihonaUserReference(): WaihonaUserRef {
		return WaihonaUserRef.create(DevelopmentTeamStubData.uid_keabad, DevelopmentTeamStubData.first_keabad, DevelopmentTeamStubData.last_keabad, DevelopmentTeamStubData.altFirstName_keabad);
	}

	public get chjones_waihonaUser(): WaihonaUser {
		let chjones: WaihonaUser = new WaihonaUser();
		chjones.guid = DevelopmentTeamStubData.uid_chjones;
		chjones.firstName = DevelopmentTeamStubData.first_chjones;
		chjones.altFirstName = DevelopmentTeamStubData.altFirstName_chjones;
		chjones.lastName = DevelopmentTeamStubData.last_chjones;
		chjones.professionalName = "Chryssa Jones";
		chjones.documentTextContent.en.bio = DevelopmentTeamStubData.bio_chjones;

		chjones.roles.push(
			new Role(RoleType.registered),
			new Role(RoleType.waihonaAdmin)
		);



		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "devteam";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Code Wrangler";
			org.organizationName = "Development Team";

		chjones.organizations.push(org);

		return chjones;
	}

	public get kabright_waihonaUser(): WaihonaUser {
		let kabright: WaihonaUser = new WaihonaUser();
		kabright.guid = DevelopmentTeamStubData.uid_kabright;
		kabright.firstName = DevelopmentTeamStubData.first_kabright;
		kabright.altFirstName = DevelopmentTeamStubData.altFirstName_kabright;
		kabright.lastName = DevelopmentTeamStubData.last_kabright;
		kabright.professionalName = "Kalani Bright";
		kabright.documentTextContent.en.bio = DevelopmentTeamStubData.bio_kabright;


		kabright.roles.push(
			new Role(RoleType.registered),
			new Role(RoleType.waihonaAdmin)
		);

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "devteam";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Bringer of Edamame";
			org.organizationName = "Development Team";

		kabright.organizations.push(org);
		return kabright;
	}

	public get wybartle_waihonaUser(): WaihonaUser {
		let wybartle: WaihonaUser = new WaihonaUser();
		wybartle.guid = DevelopmentTeamStubData.uid_wybartle;
		wybartle.firstName = DevelopmentTeamStubData.first_wybartle;
		wybartle.altFirstName = DevelopmentTeamStubData.altFirstName_wybartle;
		wybartle.lastName = DevelopmentTeamStubData.last_wybartle;
		wybartle.professionalName = "Maui Bartlett";
		wybartle.documentTextContent.en.bio = DevelopmentTeamStubData.bio_wybartle;


		wybartle.roles.push(
			new Role(RoleType.registered),
			new Role(RoleType.waihonaAdmin)
		);

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
		org.currentlyAt = true;
		org.organizationGuid = "devteam";
		org.pendingApproval = false;
		org.default = true;
		org.userTitle = "Grammar Programmer";
		org.organizationName = "Development Team";

		wybartle.organizations.push(org);
		return wybartle;
	}

	public get rygonzal_waihonaUser():WaihonaUser {
		let rygonzal: WaihonaUser = new WaihonaUser();
		rygonzal.guid = DevelopmentTeamStubData.uid_rygonzal;
		rygonzal.firstName = DevelopmentTeamStubData.first_rygonzal;
		rygonzal.altFirstName = DevelopmentTeamStubData.altFirstName_rygonzal;
		rygonzal.lastName = DevelopmentTeamStubData.last_rygonzal;
		rygonzal.professionalName = "Ryan Gonzalez";
		rygonzal.documentTextContent.en.bio = DevelopmentTeamStubData.bio_rygonzal;


		rygonzal.roles.push(
			new Role(RoleType.registered),
			new Role(RoleType.waihonaAdmin)
		);

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
			org.currentlyAt = true;
			org.organizationGuid = "devteam";
			org.pendingApproval = false;
			org.default = true;
			org.userTitle = "Lead Goofball Not in Charge";
			org.organizationName = "Development Team";

		rygonzal.organizations.push(org);
		return rygonzal;
	}


	public get keabad_waihonaUser():WaihonaUser {
		let keabad: WaihonaUser = new WaihonaUser();
		keabad.guid = DevelopmentTeamStubData.uid_keabad;
		keabad.firstName = DevelopmentTeamStubData.first_keabad;
		keabad.altFirstName = DevelopmentTeamStubData.altFirstName_keabad;
		keabad.lastName = DevelopmentTeamStubData.last_keabad;
		keabad.professionalName = "Kēhaunani Abad";
		keabad.documentTextContent.en.bio = DevelopmentTeamStubData.bio_keabad;


		keabad.roles.push(
			new Role(RoleType.registered),
			new Role(RoleType.waihonaAdmin)
		);

		let org:WaihonaUserOrganization = new WaihonaUserOrganization();
		org.currentlyAt = true;
		org.organizationGuid = "devteam";
		org.pendingApproval = false;
		org.default = true;
		org.userTitle = "Director of Kealaiwikuamoʻo";
		org.organizationName = "Development Team";

		keabad.organizations.push(org);
		return keabad;
	}

	public get kalani_newUserRegistration():NewUserRegistration {
		let newUserRegistration: NewUserRegistration = new NewUserRegistration();
		newUserRegistration.guid = null;
		newUserRegistration.firstName = DevelopmentTeamStubData.first_kabright;
		newUserRegistration.altFirstName = DevelopmentTeamStubData.altFirstName_kabright;
		newUserRegistration.lastName = DevelopmentTeamStubData.last_kabright;
		newUserRegistration.email = "kabright@ksbe.edu";
		newUserRegistration.bio = "";
		newUserRegistration.professionalName = "Kalani Bright";
		newUserRegistration.organizationGuid = "devteam";
		newUserRegistration.titleAtOrganization = "Bringer of Edamame";
		newUserRegistration.password = "Password1";
		newUserRegistration.confirmPassword = "Password1";
		newUserRegistration.inviteCode = "sudo!!";

		return newUserRegistration;
	}

	public get chjones_newUserRegistration():NewUserRegistration {
		let newUserRegistration: NewUserRegistration = new NewUserRegistration();
		newUserRegistration.guid = null;
		newUserRegistration.firstName = DevelopmentTeamStubData.first_chjones;
		newUserRegistration.altFirstName = DevelopmentTeamStubData.altFirstName_chjones;
		newUserRegistration.lastName = DevelopmentTeamStubData.last_chjones;
		newUserRegistration.email = "chjones@ksbe.edu";
		newUserRegistration.bio = "";
		newUserRegistration.professionalName = "Chryssa Jones";
		newUserRegistration.organizationGuid = "devteam";
		newUserRegistration.titleAtOrganization = "Code Wrangler";
		newUserRegistration.password = "Password1";
		newUserRegistration.confirmPassword = "Password1";
		newUserRegistration.inviteCode = "sudo!!";

		return newUserRegistration;
	}

	public get maui_newUserRegistration():NewUserRegistration {
		let newUserRegistration: NewUserRegistration = new NewUserRegistration();
		newUserRegistration.guid = null;
		newUserRegistration.firstName = DevelopmentTeamStubData.first_wybartle;
		newUserRegistration.altFirstName = DevelopmentTeamStubData.altFirstName_wybartle;
		newUserRegistration.lastName = DevelopmentTeamStubData.last_wybartle;
		newUserRegistration.email = "wybartle@ksbe.edu";
		newUserRegistration.bio = "";
		newUserRegistration.professionalName = "Maui Bartlett";
		newUserRegistration.organizationGuid = "devteam";
		newUserRegistration.titleAtOrganization = "Grammar Programmer";
		newUserRegistration.password = "Password1";
		newUserRegistration.confirmPassword = "Password1";
		newUserRegistration.inviteCode = "sudo!!";

		return newUserRegistration;
	}

	public get keabad_newUserRegistration():NewUserRegistration {
		let newUserRegistration: NewUserRegistration = new NewUserRegistration();
		newUserRegistration.guid = null;
		newUserRegistration.firstName = DevelopmentTeamStubData.first_keabad;
		newUserRegistration.altFirstName = DevelopmentTeamStubData.altFirstName_keabad;
		newUserRegistration.lastName = DevelopmentTeamStubData.last_keabad;
		newUserRegistration.email = "keabad@ksbe.edu";
		newUserRegistration.bio = "";
		newUserRegistration.professionalName = "Kēhaunani Abad";
		newUserRegistration.organizationGuid = "devteam";
		newUserRegistration.titleAtOrganization = "Director of Kealaʻiwikuamoʻo";
		newUserRegistration.password = "Password1";
		newUserRegistration.confirmPassword = "Password1";
		newUserRegistration.inviteCode = "sudo!!";

		return newUserRegistration;
	}

	public get rygonzal_newUserRegistration():NewUserRegistration {
		let newUserRegistration: NewUserRegistration = new NewUserRegistration();
		newUserRegistration.guid = null;
		newUserRegistration.firstName = DevelopmentTeamStubData.first_rygonzal;
		newUserRegistration.altFirstName = DevelopmentTeamStubData.altFirstName_rygonzal;
		newUserRegistration.lastName = DevelopmentTeamStubData.last_rygonzal;
		newUserRegistration.email = "rygonzal@ksbe.edu";
		newUserRegistration.bio = "";
		newUserRegistration.professionalName = "Ryan Gonzalez";
		newUserRegistration.organizationGuid = "devteam";
		newUserRegistration.titleAtOrganization = "Lead Goofball Not in Charge";
		newUserRegistration.password = "Password1";
		newUserRegistration.confirmPassword = "Password1";
		newUserRegistration.inviteCode = "sudo!!";

		return newUserRegistration;
	}

	public getDevelopmentTeam(): Organization {
		let devteam: Organization = new Organization();
		devteam.guid = "devteam";
		devteam.title = "Development Team";
		devteam.documentTextContent.en.description = "The Development team creates the tool.  We're not really good at creating the content like this paragraph.";

		devteam.contactInfo.push(new ContactInfo(ContactInfoType.island, "Oʻahu"));
		devteam.contactInfo.push(new ContactInfo(ContactInfoType.city, "Honolulu"));
		devteam.contactInfo.push(new ContactInfo(ContactInfoType.address, "567 S. King Street, Suite #304, Honolulu HI  96814"));
		devteam.contactInfo.push(new ContactInfo(ContactInfoType.website, "www.kanaeokana.net"));
		devteam.contactInfo.push(new ContactInfo(ContactInfoType.email, "kabright@ksbe.edu"));
		devteam.contactInfo.push(new ContactInfo(ContactInfoType.leader, "Kalani Bright"));

		devteam.flags = [OrganizationFlags.kanaeokanaMember, OrganizationFlags.team];

		devteam.admins = [this.kalani_waihonaUserReference, this.chjones_waihonaUserReference, this.wybartle_waihonaUserReference, this.keabad_waihonaUserReference, this.rygonzal_waihonaUserReference];
		devteam.kahu = [this.chjones_waihonaUserReference, this.kalani_waihonaUserReference, this.wybartle_waihonaUserReference, this.keabad_waihonaUserReference, this.rygonzal_waihonaUserReference];
		devteam.contributors = [this.chjones_waihonaUserReference, this.kalani_waihonaUserReference, this.wybartle_waihonaUserReference, this.keabad_waihonaUserReference, this.rygonzal_waihonaUserReference];

		return devteam;
	}

	public getDevTeamNewUserRegistrations():Array<NewUserRegistration> {
		return [this.kalani_newUserRegistration, this.chjones_newUserRegistration, this.maui_newUserRegistration, this.keabad_newUserRegistration, this.rygonzal_newUserRegistration];
	}




}
