import {Type} from "class-transformer";
import {IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(FrameworkNode)
export class FrameworkNode {

  @IsNotEmpty()
  public id:string = "";

  @MinLength(1)
  @MaxLength(60)
  @IsNotEmpty()
  public name:string = "";

  @MaxLength(800)
  public description:string = "";

  @Type(() => FrameworkNodeClass)
  @RegisterType({clazz: FrameworkNode, isArray: true})
  public children:Array<FrameworkNode> = [];

}

export const FrameworkNodeClass = FrameworkNode;
