import {
	KnownSpecificationType,
	KnownSpecificationTypeRef,
	NodeRelationshipDefinition,
	NodeRelationshipDefinitionRef
} from "./specification-domains";
import {Converter} from "../../../services/ConversionService";

export class KnownSpecificationTypeToKnownSpecificationTypeRefConverter implements Converter {

	public from:typeof KnownSpecificationType;
	public to:typeof KnownSpecificationTypeRef;

	constructor() {
		this.from = KnownSpecificationType;
		this.to = KnownSpecificationTypeRef;
	}

	public convert(source:KnownSpecificationType):KnownSpecificationTypeRef {
		//Create the target object
		let knownSpecificationTypeRef:KnownSpecificationTypeRef = new KnownSpecificationTypeRef();
			knownSpecificationTypeRef.guid = source.guid;

		return knownSpecificationTypeRef;
	}
}


export class NodeRelationshipDefinitionToNodeRelationshipDefinitionRefConverter implements Converter {

	public from:typeof NodeRelationshipDefinition;
	public to:typeof NodeRelationshipDefinitionRef;

	constructor() {
		this.from = NodeRelationshipDefinition;
		this.to = NodeRelationshipDefinitionRef;
	}

	public convert(source:NodeRelationshipDefinition):NodeRelationshipDefinitionRef {
		//Create the target object
		let nodeRelationshipDefinitionRef:NodeRelationshipDefinitionRef = new NodeRelationshipDefinitionRef();
		nodeRelationshipDefinitionRef.guid = source.guid;

		return nodeRelationshipDefinitionRef;
	}
}
