import {RegisterClazz, RegisterType} from "../RegisterType";
import {BadgeRef} from "./BadgeRef";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {Exclude, Type} from "class-transformer";
import {IndexProperty} from "../IndexProperty";
import {RefType} from "../RefType";

@RegisterClazz(BadgeAward)
export class BadgeAward {

	@Exclude({toPlainOnly: true})
	public guid:string;

	@IndexProperty({isArray: false})
	@Type(() => BadgeRef)
	@RegisterType({clazz: BadgeRef, isArray: false})
	@RefType({Clazz: BadgeRef, isArray: false})
	public badgeRef:BadgeRef;

	/*check for issues before deleting this
	@IndexProperty({isArray: false})
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: false})
	@RefType({Clazz: WaihonaUserRef, isArray: false})
	public waihonaUserRef:WaihonaUserRef;*/

	public awardLevel:number = 1;
	public awardDate:string;

}