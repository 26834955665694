import {Component, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {OrganizationService} from "../../../services/OrganizationService";
import {IModal} from "../IModal";
import {AuthService} from "../../../services/AuthService";
import {WaihonaUser} from "../../../domain/user/WaihonaUser";
import {IWaihonaUserAffiliation} from "../../../domain/IWaihonaUserAffiliation";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";

@Component({
	selector: 'verify-user-modal',
	templateUrl: './verify-user-modal.component.html',
	styleUrls: ['./verify-user-modal.component.scss']
})

export class VerifyUserModalComponent implements OnInit, IModal {

	private cbContext = Localization.template.modal.organization.verify_user_with_organization;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body,
		yes: Localization.template.buttons.yes,
		no: Localization.template.buttons.no,
		cancel: Localization.template.buttons.cancel,
	};

	public isLoadingYes:boolean = false;
	public isLoadingNo:boolean = false;
	public component:any;
	public content:{identity:IWaihonaUserAffiliation};

	constructor(public bsModalRef:BsModalRef,
				private authService:AuthService,
				private localizationService:LocalizationService,
				private organizationService:OrganizationService, protected logger:NGXLogger ) {
		this.localizationService.registerAndLocalize("VerifyUserModalComponent", this.CB);
	}

	public get currentUser():WaihonaUser {
		return this.authService.currentUser;
	}

	public ngOnInit():void {

	}
	public onProperties(properties:object):void {
		this.content = properties as {identity:IWaihonaUserAffiliation};
	}

	public onVerifyClick(isVerified:boolean):void {

		//setting the function params
		let submitterGuid:string = 	this.content.identity.userGuid;
		let orgGuid:string = this.content.identity.orgGuid;
		this.logger.info(`${this.currentUser.firstName} has set ${submitterGuid}' verification status with ${orgGuid} as ${isVerified}`);

		//calling the service
		this.logger.info(`verify-user-modal component is calling OrganizationService to verify the user`);
		this.organizationService.verifyUser$(orgGuid, submitterGuid, isVerified)
			.filter(triState => triState.pending == false) //watching "pending" for a status change
			.subscribe(result => {
				let successString:string = result.wasSuccessful ? "was successful" : "failed"; //sets the response message
				this.logger.info(`verifyUser ${successString}!`);
				this.isLoadingYes = false; this.isLoadingNo = false; //ends the loader after the promise is resolved
				this.bsModalRef.hide();
			});
		this.logger.info(`verify-user-modal component's call is complete. Awaiting response.`);
	};


}
