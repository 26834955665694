import {Exclude, Type} from "class-transformer";
import {IslandColor, Moku} from "./Moku";

export class Mokupuni {

	@Exclude({ toPlainOnly: true })
	public guid:string = null;


	public color:string = null;
	public sortOrder:number = 0;ʻ

	constructor(color?:IslandColor, order?:number) {
		if(color != null) {
			this.color = color;
		}
		if(!isNaN(order)) {
			this.sortOrder = order;
		}
	}
}
