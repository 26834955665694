import {RegisterClazz} from "../RegisterType";

@RegisterClazz(ResourceVersion)
export class ResourceVersion {
	protected _major:number = 0;
	protected _minor:number = 0;
	protected _tertiary:number = 0;

	constructor() {

	}

	public get major():number {
		return this._major;
	}
	public get minor():number {
		return this._minor;
	}
	public get tertiary():number {
		return this._tertiary;
	}

	/** Brand spanking new ... has never even been saved, submitted, or published! */
	public get isNew():boolean {
		return this._major == 0 && this._minor == 0 && this._tertiary == 0;
	}
	public get currentMajorVersionHasBeenSubmitted():boolean {
		return this.minor > 0;
	}
	/** Has been saved but never submitted or published */
	public get hasBeenSavedNotSubmitted():boolean {
		return this.major == 0 && this.minor == 0 && this.tertiary > 0;
	}
	/** This has been submitted previously but has never, ever been published */
	public get hasBeenSubmittedNotPublished():boolean {
		return this.major == 0 && this.minor > 0;
	}

	/** Has been published previously */
	public get hasBeenPublishedPreviously():boolean {
		return this.major > 0;
	}

	/** This resource has either been published previously, or if never published, at least its been submitted */
	public get hasBeenSubmittedOrPublishedPreviously():boolean {
		return this.major > 0 || this.minor > 0
	}

	public toString():string {
		return this._major + "." + this._minor + "." + this._tertiary;
	}
	public clone():ResourceVersion {
		return new ResourceVersion().set(this._major, this._minor, this._tertiary);
	}
	public set(major?:number | string, minor?:number, tertiary?:number):ResourceVersion {
		if(major != null && typeof major == "string") {
			let majorString:string = major;
			let majorArray:Array<string> = majorString.split(".");
			if(majorArray.length == 3) {
				this._major = Number(majorArray[0]);
				this._minor = Number(majorArray[1]);
				this._tertiary = Number(majorArray[2]);
			} else if(majorArray.length == 2) {
				this._major = Number(majorArray[0]);
				this._minor = Number(majorArray[1]);
				this._tertiary = 0;
			} else if(majorArray.length == 1) {
				this._major = Number(majorArray[0]);
				this._minor = 0;
				this._tertiary = 0;
			}
		} else {
			if(major != null) {
				this._major = major as number;
			}
			if(minor != null) {
				this._minor = minor;
			}
			if(tertiary != null) {
				this._tertiary = tertiary;
			}
		}
		return this;
	}
	/** Increments the major version while resetting the minor and tertiary */
	public nextMajor():ResourceVersion {
		this._major++;
		this._minor = 0;
		this._tertiary = 0;
		return this;
	}
	/** Increments the major version while resetting the tertiary */
	public nextMinor():ResourceVersion {
		this._minor++;
		this._tertiary = 0;
		return this;
	}
	/** Increments the major version while resetting the tertiary */
	public nextTertiary():ResourceVersion {
		this._tertiary++;
		return this;
	}

}
