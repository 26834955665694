import { NgModule } from '@angular/core';
import {Routes, RouterModule, Router, Scroll, NavigationEnd, NavigationStart} from '@angular/router';

import {LogoutComponent} from "./areas/auth/logout/logout.component";
import {FullLayoutComponent} from "./areas/shared/full-layout";
import {AuthenticatedUsersOnlyModalBlockGuard} from "./guards/AuthenticatedUsersOnlyModalBlockGuard";
import {SiteIsUndergoingMaintenanceModalBlockGuard} from "./guards/SiteIsUndergoingMaintenanceModalBlockGuard";

import {SetupComponent} from "./areas/admin/setup/setup.component";
import {AuthenticationCallbackGuard} from "./guards/AuthenticationCallbackGuard";
import {CallbackComponent} from "./areas/auth/callback/callback.component";
import {HomeComponent} from "./areas/home/home.component";
import {ViewportScroller} from "@angular/common";
import {filter} from "rxjs/operators";
import {NavigationHistoryService} from "./services/NavigationHistoryService";


export const routes:Routes = [
    {
        path: '',
        canActivate: [SiteIsUndergoingMaintenanceModalBlockGuard],
        children: [
            {
                path: '',
                component: FullLayoutComponent,
                data: {
                    title: 'Home'
                },
                children: [
                    /* {
                        path: 'code',
                        canActivate: [AuthenticationCallbackGuard],
                        component: HomeComponent
                    },*/
                    {
                        path: 'callback',
                        component: CallbackComponent
                    },
                    {
                        path: 'logout',
                        component: LogoutComponent
                    },
                    {
                        path: '',
                        component: HomeComponent, //this is the current home page
                        data: {
                            title: ''}
                    }

                ]
            },
            {
                path: 'setup',
                component: SetupComponent
            },
            {
                path: 'admin',
                component: FullLayoutComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./areas/admin/admin.module').then(m => m.AdminModule)
                    }
                ]
            },
            {
                path: 'users',
                component: FullLayoutComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./areas/waihona-users/waihona-users.module').then(m => m.WaihonaUsersModule)
                    }
                ]
            },
            {
                path: 'organizations',
                component: FullLayoutComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./areas/organizations/organizations.module').then(m => m.OrganizationsModule)
                    }
                ]
            },
            {
              path:   'collections',
                component: FullLayoutComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./areas/collections/collections.module').then(m => m.CollectionsModule)
                    }
                ]
            },
            {
                path: 'more',
                component: FullLayoutComponent,
                children: [
                	{
                        path: 'frameworks',
                        loadChildren: () => import('./areas/frameworks/frameworks.module').then(m => m.FrameworksModule)
                    },
                    {
                        path: 'badges',
                        loadChildren: () => import('./areas/badges/badges.module').then(m => m.BadgesModule)
                    }
                ]
            },
            {
                path: 'resources',
                component: FullLayoutComponent,
                children: [{
                    path: '',
                    loadChildren: () => import('./areas/resources/resources.module').then(m => m.ResourcesModule)
                }]
            },
            {
                path: 'reviews',
                component: FullLayoutComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
                children: [{
                    path: '',
                    loadChildren: () => import('./areas/reviews/reviews.module').then(m => m.ReviewsModule)
                }]
            },
            {
                path: 'user',
                component: FullLayoutComponent,
/*                canActivate: [AuthenticatedUsersOnlyModalBlockGuard],*/
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./areas/auth/auth.module').then(m => m.AuthModule),
                    }
                ]
            },
	        {
		        path: 'inbox',
		        component: FullLayoutComponent,
		        canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
		        children: [
			        {
				        path: '',
				        loadChildren: () => import('./areas/inbox/inbox.module').then(m => m.InboxModule),
			        }
		        ]
	        }
        ]
    }
];
@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
	constructor(router: Router, viewportScroller: ViewportScroller, private navHistoryService:NavigationHistoryService) {

		window.addEventListener('popstate', (event:PopStateEvent) => {
			console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
			this.navHistoryService.restoredState$.next({url: document.location.toString(), savedState: event.state});
		});

	}
}
