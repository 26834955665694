import {Component, OnDestroy, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {AuthenticatedUsersOnlyModalBlockGuard} from "../../../guards/AuthenticatedUsersOnlyModalBlockGuard";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";
import {PublicAccessBlockGuard} from "../../../guards/PublicAccessBlockGuard";


@Component({
	selector:    'authenticated-users-only-modal',
	templateUrl: './authenticated-users-only-modal.component.html'
})
export class AuthenticatedUsersOnlyModalComponent implements OnInit, OnDestroy {

	private cbContext = Localization.template.modal.auth.site_locked_authenticated_users_only;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body,
		login: Localization.template.buttons.login,
		cancel: Localization.template.buttons.cancel,
		contact: this.cbContext.contact,
		register: this.cbContext.register,
	};

	public component:AuthenticatedUsersOnlyModalBlockGuard|PublicAccessBlockGuard;

	constructor(public bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("AuthenticatedUsersOnlyModalComponent", this.CB);
		this.logger.info("BSModalRef ran");
	}

	public ngOnDestroy() {
		this.logger.info('component destroy')
	}

	public ngOnInit():void {
		this.logger.info('component init');
	}

	public getContent(pathObjectOrString:any | string):string {
		return this.localizationService.get(pathObjectOrString);
	}
}
