import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {SiteConfigurationService} from "../../services/SiteConfigurationService";
import {Localization} from "../../data/Localization";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {BaseComponent} from "../BaseComponent";
import {DeviceDetectorService} from "ngx-device-detector";
import {ScrollingService} from "../../services/common/ScrollingService";
import {Browsers} from "../../common/Browsers";

@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseComponent implements OnInit {

	private cbContext =  Localization.template.homepage;
	public CB = {
		tagline: this.cbContext.tagline,
		version: this.cbContext.version,
		inDevelopment: this.cbContext.in_development,
		register: Localization.template.buttons.register,
		login: Localization.template.buttons.login,
		get_started: this.cbContext.buttons.get_started,
		roadmap: this.cbContext.buttons.roadmap,
		updates: this.cbContext.buttons.updates,
		phase: this.cbContext.headers.phase,
		roadmap_header: this.cbContext.headers.roadmap,
		features_title: this.cbContext.features.title,
		features_subtitle: this.cbContext.features.subtitle,
		features_first_header: this.cbContext.features.body.first.header,
		features_first_body: this.cbContext.features.body.first.body,
		features_second_header: this.cbContext.features.body.second.header,
		features_second_body: this.cbContext.features.body.second.body,
		features_third_header: this.cbContext.features.body.third.header,
		features_third_body: this.cbContext.features.body.third.body,
		features_fourth_header: this.cbContext.features.body.fourth.header,
		features_fourth_body: this.cbContext.features.body.fourth.body,
		articles_first_title: this.cbContext.articles.first.title,
		articles_first_subtitle: this.cbContext.articles.first.subtitle,
		articles_first_body: this.cbContext.articles.first.body,
		articles_second_title: this.cbContext.articles.second.title,
		articles_second_subtitle: this.cbContext.articles.second.subtitle,
		articles_second_body: this.cbContext.articles.second.body,
		articles_third_title: this.cbContext.articles.third.title,
		articles_third_subtitle: this.cbContext.articles.third.subtitle,
		articles_third_body: this.cbContext.articles.third.body,
		footer_title: this.cbContext.footer.title,
		footer_org_admin: this.cbContext.footer.org_admin,
		footer_updates: this.cbContext.footer.updates,
	};

	public roadmapUrl:string;

	constructor(protected injector:Injector,
				protected deviceDetectorService:DeviceDetectorService,
				protected siteConfigurationService:SiteConfigurationService,
				protected scrollingService:ScrollingService,
	            private activatedRoute:ActivatedRoute) {
		super(injector);
		this.localizationService.registerAndLocalize("HomeComponent", this.CB, this.router.url);
		this.roadmapUrl = this.urlService.site.roadmap();
	}

	ngOnInit() {

	}

	public get shouldTryToDisplayLottieAnimation():boolean {
		console.log("isMobile: " + this.deviceDetectorService.isMobile());
		console.log("browser: " + this.deviceDetectorService.browser);
		let browser:string = this.deviceDetectorService.browser;
		let isMobile:boolean = this.deviceDetectorService.isMobile();

		if(browser == Browsers.Chrome && !isMobile) {
			return true;
		} else if (isMobile  || [Browsers.Firefox, Browsers.IE].includes(browser as any)) {
			return false;
		}
		return true; //edit...Chrome desktop, browsers except firefox and ie, or any non-mobile device
	}

	public get isLoggedIn():BehaviorSubject<boolean> {
		return this.authService.isAuthenticated$;
	}
	public get version():string {
		return this.siteConfigurationService.siteConfiguration.version;
	}
	public get updatesDoc():string {
		return this.siteConfigurationService.siteConfiguration.links.update_document;
	}
	public get orgRegistrationFormUrl():string {
		return this.siteConfigurationService.siteConfiguration.links.org_registration;
	}

	public wasRedirectedFromRegistration():BehaviorSubject<boolean> {
		let response:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
		this.activatedRoute.queryParams.subscribe(params => {
			let source = params['redirect'];
			if (source == "fromRegistration") {
				response.next(true);
			}
		});
		return response;
	}

	public login(): void {
		this.authService.login();
	}

	public scrollTo(id:string) {
		this.scrollingService.scrollTo(id);
	}

}
