import {Converter} from "../ConversionService";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {OrganizationRef} from "../../domain/organization/OrganizationRef";

//TODO: This is probably no longer relevant
export class WaihonaUserOrganizationToOrganizationRefConverter implements Converter {

    public from:typeof WaihonaUserOrganization;
    public to:typeof OrganizationRef;

    constructor() {
        this.from = WaihonaUserOrganization;
        this.to = OrganizationRef;
    }

    public convert(source:WaihonaUserOrganization):OrganizationRef {

        //Create the target object
        let organizationRef:OrganizationRef = new OrganizationRef();
            organizationRef.guid = source.organizationGuid;
            organizationRef.title = source.organizationName;

        return organizationRef;
    }
}
