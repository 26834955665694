import {classToClass, Transform, Type} from "class-transformer";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {OrganizationRef} from "../organization/OrganizationRef";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {RefLocalization} from "../ITextContentObject";


@RegisterClazz(PublishedResourceRef)
export class PublishedResourceRef  {
    public static localize = {title: "title"};

    public guid:string = null;
    public localization:RefLocalization = {
        title: {
            haw: "",
            en: "",
        },
        summary: {
            haw: "",
            en: "",
        }
    };

    public configuration:IPublishedResourceRefConfig = {
        hasImage: false,
        hideContributorName: false,
    };

    @Type(() => WaihonaUserRef)
    @RegisterType({clazz: WaihonaUserRef})
    public submitter:WaihonaUserRef;

    @Type(() => WaihonaUserRef)
    @RegisterType({clazz: WaihonaUserRef, isArray: true})
    public reviewers:Array<WaihonaUserRef> = [];

    @Type(() => OrganizationRef)
    @RegisterType({clazz: OrganizationRef})
    public organization:OrganizationRef;

    public static create(guid: string, title: { [key: string]: string }, summary:{[key:string]:string}, submitter: WaihonaUserRef, reviewers: Array<WaihonaUserRef>, organization: OrganizationRef, configuration:IPublishedResourceRefConfig):PublishedResourceRef {
        let publishedResourceRef:PublishedResourceRef = new PublishedResourceRef();
            publishedResourceRef.guid = guid;
            publishedResourceRef.localization.title = title;
            publishedResourceRef.localization.summary = summary;
            publishedResourceRef.submitter = classToClass(submitter);
            publishedResourceRef.reviewers = classToClass(reviewers);
            publishedResourceRef.organization = classToClass(organization);
            publishedResourceRef.configuration = configuration;

        return publishedResourceRef;
    }

}

export interface IPublishedResourceRefConfig {
	hasImage:boolean,
	hideContributorName:boolean,
}
