import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {HogwartsStubData} from "./HogwartsStubData";
import {Review} from "../domain/resources/review/Review";
import {ReviewFieldApprovalStatus} from "../domain/resources/review/ReviewFieldApprovalStatus";
import {Observable} from "rxjs";
import {classToClass, classToPlain, plainToClass} from "class-transformer";

export class HogwartsReviewStubData {

	public hogwartsStubData: HogwartsStubData;


	constructor() {

	}

	public get polyjuicePotionReview(): Review {
		let review: Review = new Review();
		review.guid = this.hogwartsStubData.lessons.polyjuiceHogwartsLesson.guid;
		review.reviewers = [this.hogwartsStubData.minervaRef];
		review.reset();

		let reviewTitleBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewTitleBlock.field = "titleBlock";

		let reviewMetaBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewMetaBlock.field = "metaBlock";

		let reviewSummaryBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewSummaryBlock.field = "summaryBlock";

		let reviewDescriptionBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewDescriptionBlock.field = "descriptionBlock";

		let reviewSourcesBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewSourcesBlock.field = "sourcesBlock";

		let reviewModificationsBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewModificationsBlock.field = "modificationsBlock";

		let reviewCoverImgBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewCoverImgBlock.field = "coverImgBlock";

		/*let reviewLearningAssetsBlock:ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewLearningAssetsBlock.field = "learningAssetsBlock";
			reviewLearningAssetsBlock.*/

		review.reviewFields = [reviewTitleBlock, reviewMetaBlock, reviewSummaryBlock, reviewDescriptionBlock, reviewSourcesBlock, reviewModificationsBlock, reviewCoverImgBlock];

		let resourceSubmission:ResourceSubmission = classToClass<ResourceSubmission>(this.hogwartsStubData.lessons.polyjuiceHogwartsLesson);
			resourceSubmission.guid = this.hogwartsStubData.lessons.polyjuiceHogwartsLesson.guid;
			resourceSubmission.status = ResourceSubmissionStatus.in_review;
		review.resourceSubmission = resourceSubmission;

		return review;

	}

	public get dragonCareReview(): Review {
		let review: Review = new Review();
		review.guid = this.hogwartsStubData.lessons.dragonCareHogwartsLesson.guid;
		review.reviewers = [this.hogwartsStubData.minervaRef];
		review.reset();

		let reviewTitleBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewTitleBlock.field = "titleBlock";
		let reviewMetaBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewMetaBlock.field = "metaBlock";
		let reviewSummaryBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewSummaryBlock.field = "summaryBlock";
		let reviewDescriptionBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewDescriptionBlock.field = "descriptionBlock";
		let reviewSourcesBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewSourcesBlock.field = "sourcesBlock";
		let reviewModificationsBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewModificationsBlock.field = "modificationsBlock";
		let reviewCoverImgBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewCoverImgBlock.field = "coverImgBlock";
		/*let reviewLearningAssetsBlock:ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewLearningAssetsBlock.field = "learningAssetsBlock";
			reviewLearningAssetsBlock.*/

		review.reviewFields = [reviewTitleBlock, reviewMetaBlock, reviewSummaryBlock, reviewDescriptionBlock, reviewSourcesBlock, reviewModificationsBlock, reviewCoverImgBlock];

		//review.resourceSubmission = this.hogwartsStubData.resources.dragonCareHogwartsLesson;

		let plain:any = classToPlain<ResourceSubmission>(this.hogwartsStubData.lessons.dragonCareHogwartsLesson);
		let resourceSubmission:ResourceSubmission = plainToClass<ResourceSubmission, any>(ResourceSubmission, plain) as any;
		resourceSubmission.status = ResourceSubmissionStatus.in_review_continuation;
		resourceSubmission.version.set(0,2);
		review.resourceSubmission = resourceSubmission;

		return review;

	}

	public get levitationReview(): Review {
		let review: Review = new Review();
		review.guid = this.hogwartsStubData.lessons.levitationHogwartsLesson.guid;
		review.reviewers = [this.hogwartsStubData.minervaRef];
		review.reset();

		let reviewTitleBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewTitleBlock.field = "titleBlock";
		let reviewMetaBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewMetaBlock.field = "metaBlock";
		let reviewSummaryBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewSummaryBlock.field = "summaryBlock";
		let reviewDescriptionBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewDescriptionBlock.field = "descriptionBlock";
		let reviewSourcesBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewSourcesBlock.field = "sourcesBlock";
		let reviewModificationsBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewModificationsBlock.field = "modificationsBlock";
		let reviewCoverImgBlock: ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
		reviewCoverImgBlock.field = "coverImgBlock";
		/*let reviewLearningAssetsBlock:ReviewFieldApprovalStatus = new ReviewFieldApprovalStatus();
			reviewLearningAssetsBlock.field = "learningAssetsBlock";
			reviewLearningAssetsBlock.*/

		review.reviewFields = [reviewTitleBlock, reviewMetaBlock, reviewSummaryBlock, reviewDescriptionBlock, reviewSourcesBlock, reviewModificationsBlock, reviewCoverImgBlock];

		//review.resourceSubmission = this.hogwartsStubData.resources.levitationHogwartsLesson;

		//TODO: Need deep clone
		let plain:any = classToPlain<ResourceSubmission>(this.hogwartsStubData.lessons.levitationHogwartsLesson);
		let resourceSubmission:ResourceSubmission = plainToClass<ResourceSubmission, any>(ResourceSubmission, plain) as any;
		resourceSubmission.status = ResourceSubmissionStatus.submitted;

		review.resourceSubmission = resourceSubmission;

		return review;
	}

	public get hogwartsReviewsPending(): Review[] {
		return [this.levitationReview, this.dragonCareReview, this.polyjuicePotionReview];
	}

	public get hogwartsReviewsPending$(): Observable<Review[]> {
		return Observable.of(this.hogwartsReviewsPending);
	}
}
