import {Component, Injector, OnInit} from '@angular/core';
import {Framework} from "../../../../domain/frameworks/Framework";
import {FrameworkService} from "../../../../services/FrameworkService";
import {RoleService} from "../../../../services/common/RoleService";
import {PermissionType} from "../../../../domain/user/Permission";
import {Localization} from "../../../../data/Localization";
import {BaseComponent} from "../../../BaseComponent";


@Component({
	selector: 'list-frameworks',
	templateUrl: './list-frameworks.component.html',
	styleUrls: ['./list-frameworks.component.scss']
})
export class ListFrameworksComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.frameworks.list;
	public CB = {
		header: this.cbContext.header,
		create: this.cbContext.create,
		edit: this.cbContext.edit,
		view: this.cbContext.view,
	};

	public frameworks:Framework[] = [];

	constructor(private frameworkService:FrameworkService,
				protected injector:Injector) {
		super(injector);
		this.localizationService.registerAndLocalize("ListFrameworksComponent", this.CB);

		this.logger.info("list-frameworks.component [constructor]");
		this.frameworkService.list.frameworks.relativeVisibility.full$().subscribe(frameworks => {
			this.frameworks = frameworks;
		});
	}

	ngOnInit() {

	}

	public saveStateAndNavigate(selectedId:string, navCommands:any[]) {
		this.navHistoryService.saveStateAndNavigate({selectedId: selectedId}, navCommands);
	}

	public role():RoleService {
		return this.roleService;
	}

	public canCreateFramework():boolean {
		return this.roleService.hasPermissionFor(PermissionType.create_framework);
	}

	public canEditFramework(orgGuid:string):boolean {
		return this.roleService.hasPermissionFor(PermissionType.edit_framework, orgGuid);
	}
}
