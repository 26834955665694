import {IndexProperty} from "../../IndexProperty";
import {Type} from "class-transformer";

export class AinaRef {
	public guid:string = null;
	public label:string = "";

	@IndexProperty({isArray: true, itemIdentifier: "guid"})
	@Type(()=>AinaRef)
	public children:Array<AinaRef> = [];

}




