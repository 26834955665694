import {ArrayNotEmpty, IsNotEmpty, ValidateNested} from 'class-validator';
import {classToPlain, Exclude, plainToClass, Transform, Type} from 'class-transformer';
import {LearningAsset} from './LearningAsset';
import {IOption} from "ng-select";
import {OrganizationRef} from "../organization/OrganizationRef";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {SelectableFrameworkRef} from "../frameworks/SelectableFrameworkRef";
import {AinaRef} from "./aina/AinaRef";
import {ResourceVersion} from "./ResourceVersion";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {ResourceConfiguration} from "./ResourceConfiguration";
import {IndexProperty} from "../IndexProperty";
import {DocumentTextContent} from "../DocumentTextContent";
import {ResourceSubmissionTextContent} from "./ResourceSubmissionTextContent";
import {ITextContentObject, RefLocalization} from "../ITextContentObject";
import {RefType} from "../RefType";
import {toClassDocTextTransformFactory, toPlainDocTextTransform} from "../../helper/DocTextContentHelper";
import {PublishedResourceRef} from "./PublishedResourceRef";

@RegisterClazz(ResourceSubmission, "Drafts")
export class ResourceSubmission {

	@Exclude({toPlainOnly: true})
	public guid:string = null;

	public data:RefLocalization = {
		title: {
			haw: "",
			en: "",
		}
	};

	/** Where this must get submitted to */
	@Type(() => OrganizationRef)
	@RegisterType({clazz: OrganizationRef})
	@IsNotEmpty({groups: ['requiredToSave']})
	public organization:OrganizationRef;

	//document text content transformer
	@Transform(toClassDocTextTransformFactory(ResourceSubmissionTextContent), {toClassOnly: true})
	@Transform(toPlainDocTextTransform, {toPlainOnly: true})
	public documentTextContent:DocumentTextContent<ResourceSubmissionTextContent> = new DocumentTextContent<ResourceSubmissionTextContent>(ResourceSubmissionTextContent);


	@ArrayNotEmpty()
	public gradeLevels:Array<IOption> = [];

	@Type(() => SelectableFrameworkRef)
	@RegisterType({clazz: SelectableFrameworkRef, isArray: true})
	public frameworks:Array<SelectableFrameworkRef> = [];

	@ArrayNotEmpty()
	public subjectCategories:Array<IOption> = [];

	public specialIndicators:Array<IOption> = [];

	public hawaiianCategories:Array<IOption> = [];

	public learningModalities:Array<IOption> = [];

	public tags:Array<IOption> = [];

	@Type(() => AinaRef)
	@RegisterType({clazz: AinaRef, isArray: true})
	@IndexProperty({isArray: true})
	@RefType({Clazz: AinaRef, isArray: true})
	public aina:Array<AinaRef> = [];

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef})
	@RefType({Clazz: WaihonaUserRef, isProperty: true})
	public submitter:WaihonaUserRef = null;

	@Type(() => ResourceConfiguration)
	@RegisterType({clazz: ResourceConfiguration})
	public configuration:ResourceConfiguration;

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@IndexProperty({isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public collaborators:Array<WaihonaUserRef> = [];

	public hasImage:boolean = false;

	@Type(() => LearningAsset)
	@RegisterType({clazz: LearningAsset, isArray: true})
	public files:Array<LearningAsset> = [];

	@IndexProperty({isArray: true})
	public links:Array<string> = [];

	@IndexProperty({isArray: true})
	public relatedResources:Array<PublishedResourceRef> = [];

	public status:ResourceSubmissionStatus = ResourceSubmissionStatus.draft; //Enum

	@Type(() => ResourceVersion)
	@RegisterType({clazz: ResourceVersion})
	public version:ResourceVersion;

	public created:number = 0;
	public modified:number = 0;
	public approved:number = 0; //todo: move to review
	public epochLastSubmissionDate:number = 0;

	public constructor() {
		this.created = new Date().getTime();
		this.version = new ResourceVersion();
		this.configuration = new ResourceConfiguration();
	}

	public get lastSubmissionDate():Date {
		let d:Date = new Date(0); // The 0 there is the language, which sets the date to the epoch
		d.setUTCSeconds(this.epochLastSubmissionDate);

		return d;
	}

	public get text():string {
		let dtc:DocumentTextContent<ResourceSubmissionTextContent> = this.documentTextContent;
		let hasHawaiian:boolean = !!dtc.haw;
		let hasEnglish:boolean = !!dtc.en;

		let allTextForResourceArray:Array<string> = [];
		if (hasHawaiian) {
			if (!!dtc.haw.title) { allTextForResourceArray.push(`\n${dtc.haw.title}`); }
			if (!!dtc.haw.modifications) { allTextForResourceArray.push(`\n${dtc.haw.modifications}`); }
			if (!!dtc.haw.description) { allTextForResourceArray.push(`\n${dtc.haw.description}`); }
			if (!!dtc.haw.summary) { allTextForResourceArray.push(`\n${dtc.haw.summary}`); }
			if (!!dtc.haw.notes) { allTextForResourceArray.push(`\n${dtc.haw.notes}`); }
		}
		if (hasEnglish) {
			if (!!dtc.en.title) { allTextForResourceArray.push(`\n${dtc.en.title}`); }
			if (!!dtc.en.modifications) { allTextForResourceArray.push(`\n${dtc.en.modifications}`); }
			if (!!dtc.en.description) { allTextForResourceArray.push(`\n${dtc.en.description}`); }
			if (!!dtc.en.summary) { allTextForResourceArray.push(`\n${dtc.en.summary}`); }
			if (!!dtc.en.notes) { allTextForResourceArray.push(`\n${dtc.en.notes}`); }
		}

		return allTextForResourceArray.join("");
	}



	public getEpochAsTime():string {
		let newDate:Date = new Date(this.epochLastSubmissionDate);
		return (newDate.getMonth() + 1) +
			"/" + newDate.getDate() +
			"/" + newDate.getFullYear();
	}

	public getCreatedAsTime():string {
		let newDate:Date = new Date(this.created);
		return (newDate.getMonth() + 1) +
			"/" + newDate.getDate() +
			"/" + newDate.getFullYear();
	}

	public getModifiedAsTime():string {
		let newDate:Date = new Date(this.modified);
		return (newDate.getMonth() + 1) +
			"/" + newDate.getDate() +
			"/" + newDate.getFullYear();
	}

	public getApprovedAsTime():string {
		let newDate:Date = new Date(this.approved);
		return (newDate.getMonth() + 1) +
			"/" + newDate.getDate() +
			"/" + newDate.getFullYear();
	}

}

export enum ResourceSubmissionStatus {
	draft = "draft",      //"Not yet submitted" lesson is being drafted or edited.  Not yet submitted for the first time
	submitted = "submitted",//"Submitted and awaiting Review" kahu has not yet been assigned, no review started
	in_review = "in-review", //"In Review" kahu assigned, and in review
	in_review_revision = "in-review-revision",//"Modifications Requested" kahu declined the review and it is being modified by user
	in_review_continuation = "in-review-continuation", //"Reviewing Modifications" back to kahu for re-review after modifications by user submitted
	published = "published" //lesson review was successful and the lesson has been published. Subsequent revisions will be assigned a new MajorVersion number.
}
