import {RegisterClazz, RegisterType} from "../RegisterType";
import {Exclude, Type} from "class-transformer";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {ResourceSubmissionRef} from "./ResourceSubmissionRef";
import {LearningAsset} from "./LearningAsset";
import {ArrayNotEmpty, IsAlpha, IsEmail, IsNotEmpty, IsOptional, MaxLength, MinLength} from "class-validator";

/**
 * Validation Groups:
 *  isUser: A waihona user doing a fan contribution on behalf of themselves
 *  isAnon: An anonymous user doing a fan contribution on behalf of themselves (or anyone really)
 *  isProxy: A waihona user doing a fan contribution on behalf of someone else
 */
@RegisterClazz(FanContribution)
export class FanContribution {

	@Exclude({toPlainOnly: true})
	public guid:string = null;

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz:WaihonaUserRef})
	public fanRef:WaihonaUserRef;

	//these are for proxy/anon submissions
	// @IsAlpha({message: "Your name must only contain letters.", groups:["isProxy", "isAnon"]})
	@IsNotEmpty({message: "Your name is required.", groups:["isProxy", "isAnon"]})
	public name:string = "";

	@IsEmail({}, {groups:["isProxy", "isAnon"]})
	@IsNotEmpty({message: "Your email address is required.", groups:["isProxy", "isAnon"]})
	public email:string = "";

	public isProxy:boolean = false;

	@Type(() => ResourceSubmissionRef)
	@RegisterType({clazz:ResourceSubmissionRef})
	public resourceSubmissionRef:ResourceSubmissionRef;

	@IsNotEmpty({message: "A note is required for the author to understand the context of your contribution.", groups:["isProxy", "isAnon", "isUser"]})
	@MinLength(3, {message: "Please enter a message between 3 and 500 characters.", groups:["isProxy", "isAnon", "isUser"]})
	@MaxLength(500, {message: "Please enter a message between 3 and 500 characters.", groups:["isProxy", "isAnon", "isUser"]})
	public comment:string = "";

	@Type(() => LearningAsset)
	@RegisterType({clazz:LearningAsset, isArray: true})
	@ArrayNotEmpty({message: "You must upload at least 1 asset.", groups:["isProxy", "isAnon", "isUser"]})
	public files:Array<LearningAsset> = [];

	public timestamp:string = "";
	public time:number = 0;
	public constructor() {
		this.time = new Date().getTime();
	}

	public getTimestamp():string {
		let newDate:Date = new Date(this.time);
		return (newDate.getMonth() + 1) +
			"/" + newDate.getDate() +
			"/" + newDate.getFullYear();
	}

	public getName():string {
		if (this.fanRef && !this.isProxy) {
			return this.fanRef.fullName;
		} else {
			return this.name;
		}
	}

}
