<ng-container *ngIf="content">
    <div bsModal #modal="bs-modal" class="modal-content custom-modal" style="width: 60vw; border: none">
        <div class="modal-header bg-info" style="width: 100%;">
            <h4 class="modal-title" data-localize="Contribute Resource Content">{{CB.title | localize:{frameworkName: content.framework.actual.title, 'no-cache':true} | async}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" *ngIf="content">
            <app-framework-select #frameworkSelect *ngIf="content.framework != null"  [selectableFrameworkRef]="content.framework"></app-framework-select>
        </div>

        <div class="modal-footer border-0">
            <button type="button" class="btn btn-light" (click)="onCancel()">{{CB.cancel | localize | async}}</button>
            <button type="button" class="btn btn-primary" (click)="onSetSearchSpecs()">{{CB.setSearchSpecs | localize | async }}</button>
        </div>
    </div>
</ng-container>