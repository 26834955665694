<footer class="app-footer border-0 d-flex justify-content-between">
	<span id="footer-links">{{currentYear}} |
		<a href="https://kanaeokana.net" target="_blank">Kanaeokana</a> |
		<a href="{{siteConfiguration.links.contributor_agreement}}" target="_blank">{{CB.legal | localize | async}}</a> |
		<a href="{{siteConfiguration.links.update_document}}" target="_blank">{{CB.updates | localize | async}}</a> |
		<a href="https://kaiaulu.ksbe.edu" target="_blank">Powered by KS Kaiāulu</a>
	</span>
	<span id="footer-btns" [hidden]="isCollapsed && smallScreen">
		<a role="button" id="slack-btn" class="btn custom-button" href="{{siteConfiguration.slackUrl}}" target="_blank">{{CB.slack | localize | async}} &emsp;<i class="fab fa-slack"></i></a>
		<a role="button" class="btn custom-button" data-toggle="modal" (click)="onClickSubmitFeedback()">{{CB.feedback | localize | async}}</a>
  	</span>
	<span class="hamburger-btn-section">
		<!--hamburger icon-->
		<button class="navbar-toggler" type="button" data-toggle="collapse" aria-label="Toggle navigation"
				[attr.aria-expanded]="!isCollapsed"
				(click)="isCollapsed = !isCollapsed">
			<i class="fas fa-bars"></i>
		</button>
	</span>
</footer>


