import {ResourceSubmission} from "../../domain/resources/ResourceSubmission";
import {AngularFireStorage, AngularFireUploadTask} from "@angular/fire/storage";
import {FirebaseFileUpload} from "./FirebaseFileUpload";
import {BehaviorSubject, Observable} from "rxjs";
import {SettableMetadata, UploadMetadata} from "@angular/fire/storage/interfaces";

export abstract class AbstractFirebaseStorage {



	constructor(protected storage:AngularFireStorage) {

	}
	/** This is the newer version up uploadFile instead using a LearningAssetUpload */
	public upload(upload:FirebaseFileUpload): AngularFireUploadTask {
		const task:AngularFireUploadTask = this.storage.upload(upload.toPath, upload.file);
			upload.start(task);

		return task;
	}

	public generateFileUploads(...files:File[]):FirebaseFileUpload[] {
		let fileUploads:Array<FirebaseFileUpload> = [];
		for (let file of files) {
			fileUploads.push(new FirebaseFileUpload(file));
		}
		return fileUploads;
	}

	public uploadAll(lessonSubmission: ResourceSubmission, uploads: FirebaseFileUpload[]):void {
		console.info("Uploading # files");
		for (let i = 0; i < uploads.length; i++) {
			const upload: FirebaseFileUpload = uploads[i];
			this.upload(upload);
		}
	}

	public saveBase64AsFile(path:string, base64File:string, metadata:UploadMetadata= {}):BehaviorSubject<string> {
		let observable:BehaviorSubject<string> = new BehaviorSubject<string>(null);
		this.storage.ref(path).putString( base64File, 'data_url', metadata).task.then(upload => {
				upload.ref.getDownloadURL().then(downloadUrl => {
					observable.next(downloadUrl);
				}
			)
		});

		return observable;
	}

	public updateFileMetadata(path:string, metadata:SettableMetadata = {}):Observable<any> {
		return this.storage.ref(path).updateMetadata(metadata);
	}

	public copy():void{
		console.info("Need to write this.");
	}
}
