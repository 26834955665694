import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../BaseComponent";
import {Localization} from "../../../../data/Localization";
import {Collection} from "../../../../domain/collections/Collection";
import {CollectionService} from "../../../../services/CollectionService";
import {CollectionOrderType, CollectionVisibilityTypeIcon, CollectionVisibilityTypePretty,} from "../../../../domain/collections/CollectionConfigurationEnums";
import {PublishedResourceRef} from "../../../../domain/resources/PublishedResourceRef";
import {SupportedLanguage} from "../../../../domain/SupportedLanguages";
import {CollectionTextContent} from "../../../../domain/collections/CollectionTextContent";
import {ViewType} from "../../../common/view-switcher/view-switcher.component";
import {sort} from "../../../../helper/SortTools";

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.scss']
})
export class CollectionDetailComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.collections.detail;
	public CB = {
		visibility: this.cbContext.labels.visibility,
		curated_by: this.cbContext.labels.curated,
		owner: this.cbContext.labels.owner,
		collaborators: this.cbContext.labels.collaborators,
		description: this.cbContext.labels.description,
		header_resources: this.cbContext.labels.header_resources,
		button_edit: this.cbContext.buttons.button_edit,
		table_header: this.cbContext.labels.table_header,
		add_or_edit: this.cbContext.labels.add_or_edit,
	};

	public localize = {
		collection: ()=> this.LocalizeTools.document(this.collection, CollectionTextContent, this.currentLanguage),
		resourceTitle: (ref:PublishedResourceRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "title"),
		resourceSummary: (ref:PublishedResourceRef)=> this.localizationService.LocalizeTools.ref(ref.localization, this.localizationService.language, "summary"),
	};

	@Input()
	public collectionGuid:string;

	@Input()
	public collection:Collection;

	public visual = {
		canEdit:() => this.roleService.hasGeneralOrACLPermissionsFor.collections.edit(this.collection, this.currentUser?.guid),
		collectionImage: ():string => this.urlService.imageUrl.collection(this.collection.guid),
		resourceImage: (publishedResourceRef:PublishedResourceRef):string => this.urlService.resourceImage.published(publishedResourceRef.guid)
	};

	public collectionViewMode:ViewType = ViewType.list;
	public currentSearchValue:string = "";

	public sortedResources:Array<PublishedResourceRef> = [];

	constructor(protected injector:Injector,
	            private collectionService:CollectionService) {
		super(injector);
		this.localizationService.registerAndLocalize("CollectionDetailComponent", this.CB);
		this.collectionGuid = this.route.snapshot.params['collectionId'];
	}

	ngOnInit(): void {
		if(this.collectionGuid == null) {
			console.error("guid was not passed")
			return;
		} else if(this.collection == null) {
			this.trackSubscription(this.collectionService.store.get$(this.collectionGuid).subscribe((collection:Collection) => {
				this.collection = collection;
				if (this.collection.configuration.orderType === CollectionOrderType.alphabetical) {
					this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
						this.collection.items = sort(this.collection.items, "title", {nupepafy: true, localizedField: true, ref: true, lang: newLang}).ascending();
					});
				}
			}));
		}
	}
	public get CollectionVisibilityTypePretty() { return CollectionVisibilityTypePretty }
	public get CollectionVisibilityTypeIcon() { return CollectionVisibilityTypeIcon; }

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}

	public get currentLanguage():SupportedLanguage {
		return this.localizationService.language;
	}

	public get ViewType() {return ViewType};

	public resourceRefSubmitterName(resourceRef:PublishedResourceRef):string {
		if (resourceRef.configuration?.hideContributorName) {
			return resourceRef.organization.title;
		} else {
			return (resourceRef.submitter.altFirstName ? resourceRef.submitter.altFirstName : resourceRef.submitter.firstName) + " " + resourceRef.submitter.lastName;
		}
	}

	public setSearchValue(searchString:string):void {
		this.currentSearchValue = searchString;
		console.log(`filtering for ${this.currentSearchValue}`);
	}

}
