import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PermissionType} from "../../domain/user/Permission";
import {RoleGuard} from "../../guards/RoleGuard";
import {ListBadgesComponent} from "./multiple/list-badges/list-badges.component";
import {BadgeEditComponent} from "./single/badge-edit/badge-edit.component";
import {BadgeDetailComponent} from "./single/badge-detail/badge-detail.component";
import {AuthenticatedUsersOnlyModalBlockGuard} from "../../guards/AuthenticatedUsersOnlyModalBlockGuard";
import {DomainType, UserAction} from "../../services/common/RoleService";


const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Badges'
        },
        children: [
            {
                path: '',
                component: ListBadgesComponent
            },
            {
                path: ':guid/edit',
                component: BadgeEditComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard, RoleGuard],
                data: {
	                guid: "guid",
	                domainType: DomainType.badges,
					action: UserAction.edit,
                    permission: PermissionType.edit_badge
                }
            },
            {
                path: ':guid',
                component: BadgeDetailComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BadgesRoutingModule { }
