import {Component, Injector, Input, OnInit} from '@angular/core';
import {ResourceSubmission} from "../../../../domain/resources/ResourceSubmission";
import {ActivityStream, ActivityType} from "../../../../domain/resources/ActivityStream";
import {ResourceChatService} from "../../../../services/ResourceChatService";
import {ResourceChat} from "../../../../domain/resources/chat/ResourceChat";
import {ReplaySubject} from "rxjs";
import {BaseComponent} from "../../../BaseComponent";
import {ActivityEntry} from "../../../../domain/resources/ActivityEntry";
import {Localization} from "../../../../data/Localization";
import {ActivityStreamService} from "../../../../services/ActivityStreamService";

@Component({
	selector: 'review-aside',
	templateUrl: './review-aside.component.html'
})
export class ReviewAsideComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.resource.review_aside;
	public CB = {
		settings: this.cbContext.settings,
		version: this.cbContext.version,
		auto_publish_label: this.cbContext.auto_publish_label,
		auto_publish_description: this.cbContext.auto_publish_description,
		allow_feedback_label: this.cbContext.allow_feedback_label,
		allow_feedback_description: this.cbContext.allow_feedback_description,
		allow_public_comments_label: this.cbContext.allow_public_comments_label,
		allow_public_comments_description: this.cbContext.allow_public_comments_description,
		changing_settings_no_effect: this.cbContext.changing_settings_no_effect,
		on: this.cbContext.on,
		off: this.cbContext.off,
	};

	@Input()
	public resourceSubmission:ResourceSubmission;
	public resourceChat:ResourceChat;
	public activityEntriesByVersion$:ReplaySubject<Array<ActivityEntry[]>> = new ReplaySubject();

	constructor(protected injector:Injector,
	            private resourceChatService:ResourceChatService,
	            private activityStreamService:ActivityStreamService) {
		super(injector);
		this.localizationService.registerAndLocalize("ReviewAsideComponent", this.CB);

		this.logger.info("inf review-aside component and resourceChatService ==" + resourceChatService);
	}

	public isImportantActivityEntry(activityEntry:ActivityEntry):boolean {
		let isAnImportantActivityEntry:boolean =
			activityEntry != null &&
			(activityEntry.type == ActivityType.CREATED ||
				activityEntry.type == ActivityType.SUBMITTED ||
				activityEntry.type == ActivityType.USER_COMMENT ||
				activityEntry.type == ActivityType.REVIEWER_COMMENT ||
				activityEntry.type == ActivityType.APPROVED ||
				activityEntry.type == ActivityType.DECLINED ||
				activityEntry.type == ActivityType.PUBLISHED ||
				activityEntry.type == ActivityType.FAN_CONTRIBUTION ||
				activityEntry.type == ActivityType.RENAMED ||
				activityEntry.type == ActivityType.BANNED ||
				activityEntry.type == ActivityType.UNBANNED);

		return isAnImportantActivityEntry;
	}

	public ngOnInit():void {
		this.logger.info("review-aside ngOnInit()");
		this.logger.info("this.resourceSubmission.guid: " + this.resourceSubmission.guid);

		if (this.resourceSubmission && this.resourceSubmission.guid) {
			this.logger.info("View now has value");
			let self:ReviewAsideComponent = this;
			this.resourceChatService.getChat$(this.resourceSubmission.guid).subscribe(resourceChat => {
				this.logger.info("got answer from resourcechat: " + resourceChat);
				self.resourceChat = resourceChat;
			});

			this.activityStreamService.watchActivityStream$(this.resourceSubmission.guid).subscribe((activityStream:ActivityStream) => {
				let byVersionNumber:Array<ActivityEntry[]> = [activityStream.entries];
				this.activityEntriesByVersion$.next(byVersionNumber.reverse());
			});
		}

	}

	public splitAtMajorVersion(entries:Array<ActivityEntry>):Array<ActivityEntry[]> {
		let arrayOfArrays:Array<ActivityEntry[]> = [];
		//TODO: This whole thing needs to get refactored since we introduced the version:ResourceVersion property.

		let currentVersion:number = -1;
		let lastMatchedIndex:number = 0;
		let currentSection:Array<ActivityEntry> = [];
		for (let i:number = 0; i < entries.length; i++) {
			let activityEntry:ActivityEntry = entries[i];
			if (currentVersion == -1) {
				currentVersion = activityEntry.version.major;
			}
			if (currentVersion != activityEntry.version.major) {
				currentVersion = activityEntry.version.major;

				currentSection = entries.slice(lastMatchedIndex, i); //note slice doesnʻt include the end Index..since version went up we dont want current index in calculation
				lastMatchedIndex = i;
				arrayOfArrays.push(currentSection);
				currentSection = [];
			}
		}
		if (currentSection.length > 0) {
			arrayOfArrays.push(currentSection);
			currentSection = [];
		}
		this.logger.info("Found " + arrayOfArrays.length + " major versions");
		return arrayOfArrays;
	}

	public entriesInReverseOrder(entries):Array<ActivityEntry> {
		let newarray:ActivityEntry[] = entries.slice().reverse();
		return newarray;
	}


}
