import {Converter} from "../ConversionService";
import {Framework} from "../../domain/frameworks/Framework";
import {FrameworkRef} from "../../domain/frameworks/FrameworkRef";

//TODO: This is probably no longer relevant

export class FrameworkToFrameworkRefConverter implements Converter {

    public from:typeof Framework;
    public to:typeof FrameworkRef;

    constructor() {
        this.from = Framework;
        this.to = FrameworkRef;
    }

    public convert(source:Framework):FrameworkRef {

        //Create the target object
        let frameworkRef:FrameworkRef = new FrameworkRef(source.guid, source.title);
        
        return frameworkRef;
    }
}