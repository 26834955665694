import {IsEmail, IsNotEmpty} from "class-validator";
import {RegisterClazz} from "../RegisterType";
import {startCase} from "lodash";

@RegisterClazz(UserOrgInvite)
export class UserOrgInvite {

	@IsNotEmpty({
		groups: ["email","user"]
	})
	public invitedBy:string;

	/** The guid of the organization */
	@IsNotEmpty()
	public organization:string;

	@IsNotEmpty({
		groups: ["email", "local-email"]
	})
	@IsEmail({
		allow_display_name: false,
		allow_utf8_local_part: false,
		require_tld: false
	}, {
		groups: ["email"]
	})
	public emailAddress:string;

	@IsNotEmpty({
		groups: ["user", "local-user"]
	})
	public userGuid:string;

	public uniqueCode:string;

	/**
	 * Creates a new UserOrgInvite
	 * @param {string} value
	 * @throws {Error} when value is null
	 */
	public constructor(invitedBy?:string, value?:string) {
		this.invitedBy = invitedBy;

		if(value != null ) {
			if(value && value.indexOf("@") != -1) {
				this.emailAddress = value;
			} else {
				this.userGuid = value;
			}
		}
	}

	public matches(value:string):boolean {
		let matchesEmailAddress:boolean =
			this.emailAddress != null &&
			this.emailAddress == value;

		let matchesUserGuid:boolean =
			this.userGuid != null &&
			this.userGuid == value;

		return matchesEmailAddress || matchesUserGuid;
	}

	public isEmail():boolean {
		return this.emailAddress != null;
	}
	public isUserGuid():boolean {
		return this.userGuid != null;
	}
	public toFriendly():string {
		if (this.userGuid) {
			return this.guidToName(this.userGuid);
		} else {
			return `${this.emailAddress}`;
		}
	}
	public guidToName(userGuid:string):string {
		return startCase(userGuid.split("_")[0].split("-").join(" "));
	}

}
