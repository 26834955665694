import {WaihonaUserRef} from "../../user/WaihonaUserRef";
import {RegisterClazz, RegisterType} from "../../RegisterType";
import {Type} from "class-transformer";

@RegisterClazz(ReviewFieldApprovalStatus)
export class ReviewFieldApprovalStatus {
	/** Approval Status (an Enum) */
	public reviewApprovalStatus:ReviewStatus = ReviewStatus.not_set;

	/** User Acceptance Status (an Enum) */
	public userAcceptanceStatus:UserAcceptanceStatus = UserAcceptanceStatus.not_set;

	/* Version of the review */
	public reviewVersion:number = 0;

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz:WaihonaUserRef})
	public reviewer:WaihonaUserRef; //TODO: Make sure we set this

	/** Field which is being reviewed */
	public field:string;
	/** If the field is an array or other collection type, the guid or unique identifier to the item in the array which is of concern */
	public indexGuid:string;
	/** If the field is an array or other collection type, the friendly label text which is of concern */
	public indexLabel:string;
	/** If there is a comment */
	public reviewerComment:string = "";
	public formerReviewerComment:string = ""; //prior to this last one what was the former review comment?
	/** This is the user comment */
	public userComment:string = "";
	public formerUserComment:string = ""; //prior to this last one what was the former comment?

	public get hasComment() {
		return this.reviewerComment != null && this.reviewerComment != "";
	}
}

export enum ReviewStatus {
	"not_set"="not_set",
	"approved"="approved",
	"declined"="declined"
}

export enum UserAcceptanceStatus {
	"not_set"="not_set",
	"agree"="agree",
	"disagree"="disagree"
}
