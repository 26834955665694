import {OrganizationRef} from "../organization/OrganizationRef";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(InvitedUser)
export class InvitedUser {
	@RegisterType({clazz: OrganizationRef})
	@Type(() => OrganizationRef)
	public orgRef:OrganizationRef;
	@RegisterType({clazz: WaihonaUserRef})
	@Type(() => WaihonaUserRef)
	public userRef:WaihonaUserRef;

	constructor() {

	}
}
