import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UrlService} from "../../../services/UrlService";
import {Subscription} from "rxjs";
import {WaihonaUserRef} from "../../../domain/user/WaihonaUserRef";
import {WaihonaUser} from "../../../domain/user/WaihonaUser";
import {ConversionService} from "../../../services/ConversionService";
import {HttpHelperUtils} from "../../../services/HttpHelperUtils";
import {OrganizationRef} from "../../../domain/organization/OrganizationRef";

@Component({
	selector: 'avatar-component',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

	@Input() public userRef:WaihonaUserRef;
	public _user:WaihonaUser;
	@Input() public orgRef:OrganizationRef = null;
	@Input() public class:string = "";

	@Output() public updateExists:EventEmitter<boolean> = new EventEmitter();

	public src:string = "";
	public initials:string = "";
	public color:string;
	public exists:boolean = null;

	constructor(private urlService:UrlService,
	            private httpHelperUtils:HttpHelperUtils,
	            private conversionService:ConversionService) {

	}

	ngOnInit():void {
		this.loadUser();
		this.loadOrg();
	}

	ngOnChanges(value:SimpleChanges):void {

	}

	get user(): WaihonaUser {
		return this._user;
	}
	@Input() set user(value: WaihonaUser) {
		if (this._user?.guid !== value?.guid) {
			this._user = value;
			this.loadUser();
		} else {
			this._user = value;
		}
	}

	public get AvailableOptions():Array<AvatarMode> {
		return Object.values(AvatarMode);
	}

	public loadUser():void {
		if (this.user) {
			this.userRef = this.conversionService.convert(this.user, WaihonaUserRef);
		}

		if (this.userRef) {
			this.exists = this.userRef.avatar.exists;
			this.color = this.userRef.avatar.bgColor;
			this.initials = this.userRef.twoInitials;
			this.src = this.urlService.imageUrl.avatar(this.userRef.guid);
		}

		if (this.exists === null || this.exists === undefined) {
			let s:Subscription = this.httpHelperUtils.fileExists(this.src).filter(result => (result != null)).subscribe(result => {
				s.unsubscribe();
				this.exists = result;
				this.updateExists.emit(this.exists);
			});
		}
	}

	public loadOrg():void {
		if (this.orgRef) {
			this.exists = true;
			this.src = this.urlService.imageUrl.organization(this.orgRef.guid);
		}
	}

}

export enum AvatarMode {
	"avatarInitials"="avatar-initials",
	"detailImage"="detail-image",
	"cardImgSide"="card-img-side",
	"thumbImage"="thumb-image",
	"filterSelectImage"="filter-select-image",
	"selectImage"="select-image"
}
