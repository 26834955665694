import {Injectable} from "@angular/core";
import {AuthService} from "../AuthService";
import {Role, RoleType} from "../../domain/user/Role";
import {Permission, PermissionType} from "../../domain/user/Permission";
import {OrgTypeDefinition, RoleDefinition} from "../../domain/user/RoleDefinition";
import {WaihonaUser} from "../../domain/user/WaihonaUser";
import {PublishedResource} from "../../domain/resources/PublishedResource";
import {ResourceSubmission} from "../../domain/resources/ResourceSubmission";
import {WaihonaUserOrganization} from "../../domain/user/WaihonaUserOrganization";
import {Collection} from "../../domain/collections/Collection";
import {WaihonaUserRef} from "../../domain/user/WaihonaUserRef";
import {CollectionService} from "../CollectionService";
import {CollectionVisibilityType} from "../../domain/collections/CollectionConfigurationEnums";

@Injectable ({
	"providedIn": "root",
} as any)
export class RoleService {

	public applicationPermissions:Array<Permission> = [];

	constructor(private authService:AuthService,
	            private collectionService:CollectionService) {
		let contentOwner:RoleDefinition = this.contentOwner();

		let waihonaAdmin:RoleDefinition = this.waihonaAdmin();
		let anyFrameworkAdmin:RoleDefinition = this.anyFrameworkAdmin();
		let anyOrgAdmin:RoleDefinition = this.anyOrgAdmin();
		let anyReviewer:RoleDefinition = this.anyReviewer();
		let waihonaLocalizer:RoleDefinition = this.waihonaLocalizer();
		let badgeAdmin:RoleDefinition = this.badgeAdmin();

		let orgAdmin:RoleDefinition = this.orgAdmin();
		let orgReviewer:RoleDefinition = this.orgReviewer();


		this.generatePermission(PermissionType.content_owner, "Ability to edit content as the content owner", [contentOwner]);
		this.generatePermission(PermissionType.edit_resource, "Ability to edit a resource as an editor or admin", [waihonaAdmin]);

		//Admin related----
		this.generatePermission(PermissionType.site_access_while_shutdown, "Ability to view the admin dashboard", [waihonaAdmin, waihonaLocalizer]);
		this.generatePermission(PermissionType.view_admin_dashboard, "Ability to view the admin dashboard", [waihonaAdmin, waihonaLocalizer]);
		this.generatePermission(PermissionType.can_provision, "Ability to provision in the admin dashboard", [waihonaAdmin]);
		this.generatePermission(PermissionType.view_analytics, "Ability to view analytics section in the admin dashboard", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_specifications, "Ability to edit specifications for the input options in the admin dashboard", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_event_mappings, "Ability to edit event mappings in the admin dashboard", [waihonaAdmin]);
		this.generatePermission(PermissionType.access_datastore, "Ability to access the datastore activities", [waihonaAdmin]);


		this.generatePermission(PermissionType.can_unpublish, "Ability to unpublish a resource", [waihonaAdmin, orgAdmin, contentOwner]);

		this.generatePermission(PermissionType.delete_published_resource, "Ability to permanently delete a published resource", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_localization, "Ability to edit a localization", [waihonaAdmin, waihonaLocalizer]);

		//Framework Related
		this.generatePermission(PermissionType.create_framework, "Ability to create Frameworks", [waihonaAdmin, anyFrameworkAdmin]);
		this.generatePermission(PermissionType.view_hidden_frameworks, "Ability to see/use Frameworks which have been hidden from the public.", [waihonaAdmin, anyFrameworkAdmin]);


		this.generatePermission(PermissionType.create_invitation_code, "Ability to create Waihona Invitation Codes", [waihonaAdmin, orgAdmin]);
		this.generatePermission(PermissionType.create_organization, "Ability to create Organizations", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_framework, "Ability to edit a framework", [waihonaAdmin, orgAdmin]);
		this.generatePermission(PermissionType.create_badge, "Ability to create Badges", [waihonaAdmin, badgeAdmin]);
		this.generatePermission(PermissionType.edit_badge, "Ability to edit a badge", [waihonaAdmin, badgeAdmin]);
		this.generatePermission(PermissionType.create_collection, "Ability to create Collections", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_collection, "Ability to edit a collection", [waihonaAdmin]);
		this.generatePermission(PermissionType.view_collection, "Ability to view a collection", [waihonaAdmin]);
		this.generatePermission(PermissionType.edit_organization, "Ability to edit an organization", [waihonaAdmin, orgAdmin]);
		this.generatePermission(PermissionType.edit_org_config, "Ability to edit an organization's configuration property (settings for org)", [waihonaAdmin, orgAdmin]);
		this.generatePermission(PermissionType.see_ability_to_review, "Can see the option to review", [waihonaAdmin, anyOrgAdmin, anyReviewer]);
		this.generatePermission(PermissionType.review_submission, "Ability to review a submission", [waihonaAdmin, orgAdmin, orgReviewer]);
		this.generatePermission(PermissionType.review_count, "Ability to see the # of reviews for an organization", [orgReviewer]);
		
		this.generatePermission(PermissionType.unlock_resources, "Ability to lock or unlock individual resources to bypass the auth-guard", [waihonaAdmin]);

	}

	private waihonaAdmin():RoleDefinition {
		return new RoleDefinition(RoleType.waihonaAdmin, OrgTypeDefinition.any_organization);
	}
	private waihonaLocalizer():RoleDefinition {
		return new RoleDefinition(RoleType.waihonaLocalizer, OrgTypeDefinition.any_organization);
	}
	private anyOrgAdmin():RoleDefinition {
		return new RoleDefinition(RoleType.organizationAdmin, OrgTypeDefinition.any_organization);
	}
	private anyFrameworkAdmin():RoleDefinition {
		return new RoleDefinition(RoleType.frameworkAdmin, OrgTypeDefinition.any_organization);
	}
	private anyReviewer():RoleDefinition {
		return new RoleDefinition(RoleType.reviewer, OrgTypeDefinition.any_organization);
	}
	private contentOwner():RoleDefinition {
		return new RoleDefinition(RoleType.contentOwner, OrgTypeDefinition.any_organization);
	}
	private orgAdmin():RoleDefinition {
		return new RoleDefinition(RoleType.organizationAdmin, OrgTypeDefinition.part_of_organization);
	}
	private orgReviewer():RoleDefinition {
		return new RoleDefinition(RoleType.reviewer, OrgTypeDefinition.part_of_organization);
	}
	private badgeAdmin():RoleDefinition {
		return new RoleDefinition(RoleType.badgeAdmin, OrgTypeDefinition.any_organization);
	}

	private generatePermission(type:string | PermissionType, description:string, roles:Array<RoleDefinition>):void {
		this.applicationPermissions.push(new Permission(type, description, roles))
	}

	public domainToServiceMap = {
		[DomainType.collections]: this.collectionService,
	}

	public hasGeneralOrACLPermissionsFor = {
		resources: {
			resourceSubmission: {
				edit: (resourceSubmission:ResourceSubmission, userGuid?:string):boolean => {
					//  Who is allowed to edit a resource?
					//      - Submitter (Owner)
					//      - Collaborators
					//      - WaihonaAdmins
					// TODO: Discuss future Editor role who can unpublish and edit but arenʻt listed as collaborators

					//General setup
					// ---------------------------------------------------------------------
					let genericPermissions:boolean = this.hasPermissionFor(PermissionType.edit_resource);
					if(genericPermissions) {
						return true;
					}

					if(userGuid == null) {
						userGuid = this.authService.currentUser.guid;
					}

					if (resourceSubmission == null) {
						return false;
					}

					//Are they a submitter?
					// ---------------------------------------------------------------------
					//if their guids match on the submitter property they are...
					if(userGuid == resourceSubmission.submitter.guid) {
						return true;
					}

					//Are they a collaborator?
					// ---------------------------------------------------------------------
					//If we find them in the collaborators array by their guid
					return resourceSubmission.collaborators.find(collaborator => {
						return collaborator.guid == userGuid;
					}) != null;
				},
			},
			publishedResource: {
				unpublish: (publishedResource:PublishedResource, userGuid?:string):boolean  => {
					let orgGuid:string = publishedResource.resourceSubmission.organization.guid;
					let genericPermissions:boolean = this.hasPermissionFor(PermissionType.can_unpublish, orgGuid);

					if(genericPermissions) {
						return true;
					}

					let submitter:string = publishedResource.resourceSubmission.submitter.guid;
					if(userGuid == null) {
						userGuid = this.authService.currentUser.guid;
					}
					return submitter == userGuid;
				},


			}
		},
		badges: {
			create: (userGuid?:string):boolean => {
				if(userGuid == null) {
					userGuid = this.authService.currentUser.guid;
				}
				let genericPermissions:boolean = this.hasPermissionFor(PermissionType.create_badge, userGuid);
				if (genericPermissions) {
					return true;
				}
			},
			edit: (userGuid?:string):boolean => {
				if(userGuid == null) {
					userGuid = this.authService.currentUser.guid;
				}
				let genericPermissions:boolean = this.hasPermissionFor(PermissionType.edit_badge, userGuid);
				if (genericPermissions) {
					return true;
				}
			}
		},
		collections: {
			create: (userGuid?:string):boolean => {
				if(userGuid == null) {
					userGuid = this.authService.currentUser.guid;
				}
				let genericPermissions:boolean = this.hasPermissionFor(PermissionType.create_collection, userGuid);
				if (genericPermissions) {
					return true;
				}
			},
			edit: (collection:Collection, userGuid?:string):boolean => {
				//  Who is allowed to edit a collection?
				//      - Owner
				//      - Collaborators
				//      - WaihonaAdmins

				//General setup
				// ---------------------------------------------------------------------
				let genericPermissions:boolean = this.hasPermissionFor(PermissionType.edit_collection);
				if(genericPermissions) {
					return true;
				}

				if(userGuid == null) {
					userGuid = this.authService?.currentUser?.guid;
				}

				if (collection == null) {
					return false;
				}

				//Are they an owner?
				// ---------------------------------------------------------------------
				let orgsUserIsAdminFor:string[] = this.authService?.currentUser?.organizations
					.filter((userOrg:WaihonaUserOrganization) => this.isOrgAdminFor(userOrg.organizationGuid))
					.map((userOrg:WaihonaUserOrganization) => userOrg.organizationGuid);

				//if their guids match on the owner property they are...
				if ( userGuid == collection.owner.guid || orgsUserIsAdminFor?.includes(collection.owner.guid) ) {
					return true;
				}

				//Are they a collaborator?
				// ---------------------------------------------------------------------
				//If we find them in the collaborators array by their guid
				return collection.collaborators?.find(collaborator => {
					return collaborator.guid == userGuid;
				}) != null;
			},
			view: (collection:Collection, userGuid?:string):boolean => {
				//  Who is allowed to view a collection?
				//      - Owner
				//      - Collaborators
				//      - WaihonaAdmins
				//      - SharedTo
				// And any public collection is viewable by anyone

				//General setup
				// ---------------------------------------------------------------------
				let genericPermissions:boolean = this.hasPermissionFor(PermissionType.view_collection);
				if(genericPermissions) {
					return true;
				}

				if(userGuid == null) {
					userGuid = this.authService?.currentUser?.guid;
				}

				if (collection == null) {
					return false;
				}

				//Is the collection public?
				// ---------------------------------------------------------------------
				if (collection.configuration.visibilityType == CollectionVisibilityType.public) {
					return true;
				}

				//Are they an owner?
				// ---------------------------------------------------------------------
				let orgsUserIsAdminFor:string[] = this.authService?.currentUser?.organizations
					?.filter((userOrg:WaihonaUserOrganization) => this.isOrgAdminFor(userOrg.organizationGuid))
					.map((userOrg:WaihonaUserOrganization) => userOrg.organizationGuid);

				//if their guids match on the owner property they are...
				if ( userGuid == collection.owner.guid || orgsUserIsAdminFor?.includes(collection.owner.guid) ) {
					return true;
				}

				let collaboratorFound:boolean = null;
				//Are they a collaborator?
				// ---------------------------------------------------------------------
				//If we find them in the collaborators array by their guid
				collaboratorFound = collection?.collaborators?.find((collaborator:WaihonaUserRef) => {
					return collaborator.guid == userGuid;
				}) != null;

				if (collaboratorFound) {
					return true;
				} else {
					//Are they a sharedTo user?
					// ---------------------------------------------------------------------
					//If we find them in the sharedTo array by their guid
					return collection?.sharedTo?.find(user => {
						return user.guid == userGuid;
					}) != null;
				}
			},
		}
	};

	/** Whether the current user has permissions to perform a specific task */
	public hasPermissionFor(type:string | PermissionType, organizationGuid?:string):boolean {

		const currentUser:WaihonaUser = this.authService.currentUser;
		if (currentUser == null) {
			return false;
		}

		//Get the permission that matches the passed permission type
		let permission:Permission = this.applicationPermissions.find(appPermission => {
			return appPermission.keyMatches(type);
		});

		if(permission == null) {
			return false;
		}

		let usersMatchingRoles:Array<Role> = []; //To hold the users matching roles which match the permission
		//The permission will have many allowed roles, some may allow any organization, some will allow many
		//AllowedRole is a RoleDefinition object (which can be flexible on the org)

		for (const allowedRole of permission.allowedRoles) {
			let userRolesWhichMatchOneOfTheAllowedRoles:Array<Role> = this.getPassingUserRolesForTypeAndGuid(allowedRole, currentUser.roles, organizationGuid);
			usersMatchingRoles.push(...userRolesWhichMatchOneOfTheAllowedRoles); //push the specific types matching roles onto an overall matching roles (all types)
		}
		/* TODO: Maybe eventually we want to use this method for something other than a yes/no response, which is the reason why we still care for and use arrays. */
		return usersMatchingRoles.length > 0;
	}

	public getPassingUserRolesForTypeAndGuid(roleDefinition:RoleDefinition, userRoles:Array<Role>, orgGuid?:string):Array<Role> {
		let passingUserRoles:Array<Role> = []; //To hold user roles which pass the Role Definitions requirements
		//Iterate through the user roles
		for(let i = 0; i < userRoles.length; i++) {
			const userRole:Role = userRoles[i];

			//Basic match matches the type
			const basicMatch:boolean   = (userRole.type == roleDefinition.type);
			//match guid if it is passed, otherwise it passes by default.

			let passAnyOrg:boolean = roleDefinition.organization == OrgTypeDefinition.any_organization;
			let orgGuidMatch:boolean = (orgGuid != null) ?
											(orgGuid == userRole.organization) || passAnyOrg :
											true;
			
			const passes:boolean = basicMatch && orgGuidMatch;

			if(passes) {
				passingUserRoles.push(userRole)
			}
		}

		return passingUserRoles;
	}

	public isOrgAdminFor(orgGuid:string):boolean {
		return this.authService.currentUser.isRoleTypeForOrg(RoleType.organizationAdmin, orgGuid);
	}

}

export enum DomainType {
	"collections" = "collections",
	"badges" = "badges",
	"resources" = "resources"
}

export enum UserAction {
	"create" = "create",
	"edit" = "edit",
	"view" = "view",
	"delete" = "delete"
}
