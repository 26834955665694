<div class="animated fadeIn horizontal-card-deck">
  <h1 class="display-5 text-primary font-weight-bold mt-4">{{CB.header | localize | async}}</h1><br>

  <!--iterated card deck-->
  <div class="card-deck">

    <!--create new badge card-->
    <div *ngIf="canCreateBadge">
      <div class="card my-lg-3 my-sm-1 bg-transparent border-0 p-3" style="display: block;" >
        <a href="javascript:void(0);" style="text-decoration: none;" [routerLink]="['/more/badges', '', 'edit']">
          <div class="card-body text-center">
            <i class="fas fa-plus-circle fa-6x center-plus-button"></i>
            <h3>{{CB.create | localize | async}}</h3>
          </div>
        </a>
      </div>
    </div>
    <!--end create new badge card-->

    <!--iterated card deck-->
    <div *ngFor = "let badge of badges">
      <a href="javascript:void(0);" [routerLink]="['/more/badges', badge.guid]" style="text-decoration: none;">
        <div class="card my-lg-3 my-sm-1">
          <div>
	          <img class="badge-img card-img-side" src="{{getBadgeArt(badge)}}" alt="badge art" />
          </div>
          <div class="card-body">
            <h4 class="card-title font-weight-bold">{{LocalizeTools.document(badge, BADGE_TEXT_CONTENT).name}}</h4>
            <p class="card-text text-black-50" [innerHTML]="LocalizeTools.document(badge, BADGE_TEXT_CONTENT).description"></p>
          </div>
        </div>
      </a>
    </div>
    <!--end of iterated deck-->

  </div>
</div>
