import {AnalyticsCategory, AnalyticsEvent} from "../AnalyticsService";
import {Injectable} from "@angular/core";


@Injectable({
	providedIn: 'root',
} as any)
export class AnalyticsData {

	public organization = {
		create: {
			click: ():AnalyticsEvent => { return {
				name: "create-organization",
				category: AnalyticsCategory.organization,
				action: "create",
				label: "click-submit",
				value: "click"
			}}
		}
	};

	public resource = {
		create: {
			click: ():AnalyticsEvent => { return {
				name: "create-resource",
				category: AnalyticsCategory.resource,
				action: "create",
				label: "click-submit",
				value: "click"
			}}
		},
		revise: {
			click:():AnalyticsEvent => { return {
				name: "revise-resource",
				category: AnalyticsCategory.resource,
				action: "revise",
				label: "click-submit",
				value: "click"
			}}
		}
	};




}
