import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CommunicationService,} from "../../../services/CommunicationService";
import {WaihonaUser} from "../../../domain/user/WaihonaUser";
import {AuthService} from "../../../services/AuthService";
import {LogMonitoringService} from "../../../services/logging/LogMonitoringService";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";
import {LocalizationService} from "../../../services/LocalizationService";
import {SiteConfigurationService} from "../../../services/SiteConfigurationService";
import {NotificationService} from "../../../services/common/NotificationService";
import {FeedbackModalComponent} from "../../modals/feedback-modal/feedback-modal.component";

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {

	private cbContext =  Localization.template.app_footer;
	public CB = {
		legal: this.cbContext.legal,
		slack: this.cbContext.slack,
		feedback: this.cbContext.feedback,
		updates: Localization.template.homepage.buttons.updates,
		modal_title: this.cbContext.modal.title,
		modal_body: this.cbContext.modal.body,
		modal_placeholder: this.cbContext.modal.placeholder,
		modal_send: this.cbContext.modal.send,
		modal_cancel: Localization.template.buttons.cancel
	};

	public currentUser:WaihonaUser;

	@ViewChild("feedbackModal")
	public feedbackModal: BsModalRef;

	public currentYear:number = new Date().getFullYear();

	public isCollapsed:boolean = true;
	public smallScreen:boolean = null;

	public constructor(private communicationService: CommunicationService,
					   private authService:AuthService,
	                   private logMonitoringService:LogMonitoringService,
	                   private localizationService:LocalizationService,
	                   private siteConfigurationService:SiteConfigurationService,
	                   private notificationService:NotificationService,
	                   protected logger:NGXLogger
	                   ) {
		this.localizationService.registerAndLocalize("AppFooterComponent", this.CB);
	}
	public get siteConfiguration() {
		return this.siteConfigurationService.siteConfiguration;
	}

	public ngOnInit(): void {
		const mqLarge  = window.matchMedia( '(max-width: 800px)' );
		// media query handler function
		let self = this;
		function mqHandler(e) {
			e.matches ? self.smallScreen = true : self.smallScreen = false;
		}
		mqHandler(mqLarge);
		mqLarge.addEventListener('change', mqHandler);
	}

	onClickSubmitFeedback() {
		//open feedback modal
		this.feedbackModal = this.notificationService.displayModal(FeedbackModalComponent, self);
	}

}
