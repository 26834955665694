import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentTextContent} from "../../../domain/DocumentTextContent";
import {LocalizationService} from "../../../services/LocalizationService";
import {Localization} from "../../../data/Localization";
import {SupportedLanguage} from "../../../domain/SupportedLanguages";

@Component({
	selector: 'doc-language-toggle',
	templateUrl: './doc-language-toggle.component.html',
	styleUrls: ['./doc-language-toggle.component.scss']
})
export class DocLanguageToggleComponent implements OnInit {

	public ConfirmContext = Localization.template.resource.edit.language.confirm;

	public CB = {
		confirm_haw_title: this.ConfirmContext.haw_title,
		confirm_en_title: this.ConfirmContext.en_title,
		confirm_yes: this.ConfirmContext.yes,
		confirm_no: this.ConfirmContext.no
	};

	@Input()
	public documentTextContent:DocumentTextContent<any>;

	@Input()
	public selectedLanguage:SupportedLanguage;


	@Output()
	public languageChange:EventEmitter<SupportedLanguage> = new EventEmitter<SupportedLanguage>();

	@Input()
	public DocumentTextContentClass:(new()=>{});

	constructor(protected localizationService:LocalizationService) {

	}

	public get supportedLanguages():Array<SupportedLanguage> {
		return Object.keys(SupportedLanguage).map(key => {
			return SupportedLanguage[key];
		});
	}

	ngOnInit():void {
	}

	public get SupportedLanguage() {
		return SupportedLanguage;
	}


	public delete(language:SupportedLanguage) {
		delete this.documentTextContent[language];
		this.selectedLanguage = this.supportedLanguages.find(iLanguage => {
			return iLanguage != language;
		})
		this.languageChange.emit(this.selectedLanguage);
	}

	public create(language:SupportedLanguage) {
		this.documentTextContent[language] = new this.DocumentTextContentClass();
		this.selectedLanguage = language;
		this.languageChange.emit(language);

	}

	public changeTo(language:SupportedLanguage) {
		this.selectedLanguage = language;
		this.languageChange.emit(language);
	}
}
