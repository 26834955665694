import {NgModule} from "@angular/core";
import {AsideToggleDirective} from "../../directives/aside";
import { SearchComponent } from './search/search.component';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ContributorDropDownComponent} from "./contributor-drop-down/contributor-drop-down/contributor-drop-down.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {SharedModule} from "../shared/shared.module";
import { CropperComponent } from './cropper/cropper.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { SelectResourceRefsComponent } from './select-resource-refs/select-resource-refs.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {ViewSwitcherComponent} from "./view-switcher/view-switcher.component";
import { SelectCollectionComponent } from './select-collection/select-collection.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgSelectModule,
		SharedModule,
		ImageCropperModule,
		TooltipModule,
	],
	declarations: [
		AsideToggleDirective,
		SearchComponent,
		ContributorDropDownComponent,
		SearchComponent,
		CropperComponent,
		SelectResourceRefsComponent,
		ViewSwitcherComponent,
		SelectCollectionComponent,
	],
	providers: [

	],
	exports:[
		AsideToggleDirective,
		SearchComponent,
		ContributorDropDownComponent,
		SearchComponent,
		CropperComponent,
		SelectResourceRefsComponent,
		ViewSwitcherComponent,
		SelectCollectionComponent,
	]
})
export class ComponentCommonModule { }
