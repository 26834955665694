import {RegisterClazz, RegisterType} from "../RegisterType";
import {classToPlain, Exclude, plainToClass, Transform, Type} from "class-transformer";
import {DocumentTextContent} from "../DocumentTextContent";
import {BadgeTextContent} from "./BadgeTextContent";
import {ITextContentObject} from "../ITextContentObject";
import {toClassDocTextTransformFactory, toPlainDocTextTransform} from "../../helper/DocTextContentHelper";
import {OrganizationTextContent} from "../organization/OrganizationTextContent";

@RegisterClazz(Badge, "Badges")
export class Badge implements ITextContentObject<BadgeTextContent> {

	@Exclude({toPlainOnly: true})
	public guid:string = null;

	/*@Type(() => BadgeType)
	@RegisterType({clazz: BadgeType})*/
	public type:BadgeType; /** type of badge for future use */

	//document text content transformer
	@Transform(toClassDocTextTransformFactory(BadgeTextContent), {toClassOnly: true})
	@Transform(toPlainDocTextTransform, {toPlainOnly: true})
	public documentTextContent:DocumentTextContent<BadgeTextContent> = new DocumentTextContent<BadgeTextContent>(BadgeTextContent);

}

export enum BadgeType {
	individual = "individual",
	organization = "organization"
}
