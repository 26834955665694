import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {PagingService} from "./PageListService";
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
} as any)
export class ScreenResizeService {

	public currentRatio:number;
	public hasVerticalScrollbar$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public resizeObservable:Observable<any>
	public delayedResizeObservable:Observable<any>

	constructor() {
		this.currentRatio = window.devicePixelRatio

		window.addEventListener('resize', () => {
			this.checkZooming();
		})

		window.addEventListener('zoom', () => {
			console.log('zoomed!')
		});

		this.resizeObservable = fromEvent(window, 'resize');
		this.delayedResizeObservable = this.resizeObservable.pipe(debounceTime(1000));
		this.delayedResizeObservable.subscribe(() => this.checkVerticalScrollBar());
	}

	public checkVerticalScrollBar() {
		const root = document.getElementsByTagName("html")[0];
		const fullSite = document.getElementsByClassName("full-site")[0];

		const hasVerticalScrollbar = fullSite.clientHeight > root.clientHeight;
		this.hasVerticalScrollbar$.next(hasVerticalScrollbar);
		console.log("hasVerticalScrollbar: " + hasVerticalScrollbar);
	}

	public checkZooming() {
		const zoomEvent = new Event('zoom')

		if (this.currentRatio !== window.devicePixelRatio) {
			window.dispatchEvent(zoomEvent);
			this.currentRatio = window.devicePixelRatio
		}
	}

	// for pulling more pages if there is no scrollbar, until there is a scrollbar
	public fillPage(pagingService:PagingService<any>):Subscription {
		return this.hasVerticalScrollbar$.filter((isVerticalScrollbar:boolean) => isVerticalScrollbar === false).subscribe(() => {
			if (pagingService.paging.list.nextPageExists()) {
				console.log("getting next page");
				pagingService.paging.list.next();

				pagingService.paging.list.isLoading().filter((isLoading:boolean) => isLoading === false).take(1).subscribe(() => {
					this.checkVerticalScrollBar();
				});
			}
		})
	}

}

