import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import {AngularFirestoreModule} from "@angular/fire/firestore";

import {NgSelectModule} from "@ng-select/ng-select";

import {ListCollectionsComponent} from "./multiple/list-collections/list-collections.component";
import {CollectionDetailComponent} from './single/collection-detail/collection-detail.component';
import {CollectionEditComponent} from './single/collection-edit/collection-edit.component';
import {CollectionsRoutingModule} from "./collections-routing.module";
import {QuillModule} from "ngx-quill";
import {ImageCropperModule} from "ngx-image-cropper";
import {SharedModule} from "../shared/shared.module";
import {ComponentCommonModule} from "../common/component-common.module";
import {ToasterModule} from "angular2-toaster";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {ModalModule} from "ngx-bootstrap/modal";
import {PopoverModule} from "ngx-bootstrap/popover";
import {ClickOutsideModule} from "ng-click-outside";
import {SortablejsModule} from "ngx-sortablejs";
import { CollectionListCardComponent } from './single/collection-list-card/collection-list-card.component';
import { ListUserCollectionsComponent } from './multiple/list-user-collections/list-user-collections.component';
import { UserCollectionsTableItemComponent } from './multiple/list-user-collections/user-collections-table/user-collections-table-item.component';
import { OrgCollectionsTableComponent } from './multiple/list-user-collections/org-collections-table/org-collections-table.component';
import {CarouselModule} from "ngx-bootstrap/carousel";


@NgModule({
	imports: [
		CommonModule,
		QuillModule,
		ImageCropperModule,
		CollectionsRoutingModule,
		NgSelectModule,
		FormsModule,
		AngularFirestoreModule,
		SharedModule,
		ComponentCommonModule,
		ToasterModule.forChild(),
		TooltipModule,
		ModalModule,
		SortablejsModule,
		PopoverModule,
		ClickOutsideModule,
		CarouselModule.forRoot(),
	],
	declarations: [
		ListCollectionsComponent,
		CollectionDetailComponent,
		CollectionEditComponent,
		ListUserCollectionsComponent,
		UserCollectionsTableItemComponent,
		OrgCollectionsTableComponent,
		CollectionEditComponent,
		CollectionListCardComponent,
	],
	providers: [
	]
})

export class CollectionsModule { }
