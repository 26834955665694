import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListFilterPipe} from "./list-filter.pipe";
import {LocalizePipe} from "./LocalizePipe";
import {FileSizePipe} from "./file-size.pipe";
import {ReversePipe} from "./reverse.pipe";
import {PublishedResourceFilterPipe} from "./PublishedResourceFilterPipe";

@NgModule({
	declarations: [
		FileSizePipe,
		ReversePipe,
		ListFilterPipe,
		LocalizePipe,
		PublishedResourceFilterPipe,
	],
	imports: [
		CommonModule,

	],
	exports: [
		FileSizePipe,
		ReversePipe,
		ListFilterPipe,
		LocalizePipe,
		PublishedResourceFilterPipe,
	]
})
export class PipesModule {
}
