import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Framework, FrameworksType} from "../../../../../../../domain/frameworks/Framework";
import {SelectableFrameworkRef} from "../../../../../../../domain/frameworks/SelectableFrameworkRef";
import {ResourceSubmission} from "../../../../../../../domain/resources/ResourceSubmission";
import {FrameworkSelectComponent} from "../framework-select/framework-select.component";
import {Localization} from "../../../../../../../data/Localization";
import {LocalizationService} from "../../../../../../../services/LocalizationService";
import {NotificationService} from "../../../../../../../services/common/NotificationService";
import {
    IFrameworkSpecsModalInput,
    ResourceFrameworkSpecsModalComponent
} from "../../../../../../modals/resource-framework-specs-modal/resource-framework-specs-modal.component";

@Component({
    selector: 'app-framework-chooser',
    templateUrl: './framework-chooser.component.html',
    styleUrls: ['./framework-chooser.component.scss']
})
export class FrameworkChooserComponent implements OnInit {


    @Input()
    public availableFrameworks: Array<SelectableFrameworkRef>;

    @Input()
    public resourceSubmission: ResourceSubmission;

    public currentSelectableFrameworkRef: SelectableFrameworkRef;


    @ViewChild('frameworkSelect')
    public frameworkSelect: FrameworkSelectComponent;

    private cbContext = Localization.template.resource.edit;
    public CB = {
        meta_label_frameworks: this.cbContext.meta.labels.frameworks,
        meta_placeholder_frameworks: this.cbContext.meta.placeholders.frameworks,
        meta_tooltip_frameworks: this.cbContext.meta.tooltips.frameworks,
    };

    constructor(private localizationService:LocalizationService, private notificationService:NotificationService) {
        this.localizationService.registerAndLocalize("FrameworkChooserComponent", this.CB);
    }

    ngOnInit(): void {

    }

    public badgeClassType(selectableFrameworkRef:SelectableFrameworkRef):string {
        let sfrat:FrameworksType = selectableFrameworkRef.actual.type;
        return sfrat == FrameworksType.framework ? "badge-success" :
                    sfrat == FrameworksType.assessment ? "badge-warning" :
                        sfrat == FrameworksType.standard ? "badge-secondary" :  "badge-primary";

    }

    public set currentSelectableFramework(item:SelectableFrameworkRef) {
        this.currentSelectableFrameworkRef = item;

        this.notificationService.displayModal<IFrameworkSpecsModalInput>(ResourceFrameworkSpecsModalComponent, this, {framework: item, resourceSubmission: this.resourceSubmission});
        //this.frameworkSelect.reset();
        //this.frameworkSelect.show();
    }

    public get currentlyChosenFrameworks():Array<SelectableFrameworkRef> {
        return this.availableFrameworks.filter(availableFramework => {
            return this.resourceSubmission.frameworks.find(framework => {
                return framework.ref.guid == availableFramework.ref.guid;
            });
        });

    }
}
