import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {InvitationCodeRepository} from "./repository/InvitationCodeRepository";
import {RegistrationInviteCode} from "../domain/invites/RegistrationInviteCode";
import {RoleService} from "./common/RoleService";
import {PermissionType} from "../domain/user/Permission";
import {InviteMessage} from "../domain/invites/InviteMessage";
import {ValidationException} from "./exceptions/ValidationException";
import {validateSync, ValidationError} from "class-validator";
import {NGXLogger} from "ngx-logger";
import {chain} from "lodash";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";


@Injectable()
export class InvitationCodeService {

	private inviteCode:RegistrationInviteCode;

	constructor (private repo:InvitationCodeRepository,
				 private roleService:RoleService, protected logger:NGXLogger) {

	}

	public createCode(inviteMessage:InviteMessage):Observable<RegistrationInviteCode> {
		let createdRegistrationCode$:Subject<RegistrationInviteCode> = new Subject<RegistrationInviteCode>();

		//validation
		let coffeeCup = inviteMessage.inviteCode;
		inviteMessage.inviteCode = inviteMessage.inviteCode.replace(new RegExp("-", 'g'), "");
		try {
			this.validate(inviteMessage); //check for isPositive and isAlphaNumeric
			inviteMessage.inviteCode = coffeeCup;
		} catch(error) {
			inviteMessage.inviteCode = coffeeCup;
			throw (error);
		}

		if (inviteMessage.inviteNumber > 1000) {
			let error = new ValidationError();
				error.target = inviteMessage;
				error.property = "inviteNumber";
				error.constraints = {maxNumber: "Invite number must be less than 1000!"};
			throw new ValidationException([error]);
		}

		if (this.roleService.hasPermissionFor(PermissionType.create_invitation_code)) {
			this.logger.info(`permission OK - creating a new invite code for ${inviteMessage.inviteCode}`);

			this.inviteCode = new RegistrationInviteCode();
			this.inviteCode.code = inviteMessage.inviteCode;
			this.inviteCode.guid = inviteMessage.inviteCode;
			this.inviteCode.maxAllowed = inviteMessage.inviteNumber;
			this.inviteCode.isActive = true;
			this.inviteCode.usedCodes = [];

			if (inviteMessage.inviteOrg) {
				this.inviteCode.orgGuid = inviteMessage.inviteOrg.guid;
				this.inviteCode.shouldAutoAddToOrg = inviteMessage.shouldAutoAddToOrg;
			}

			try {
				this.saveCode$(this.inviteCode).filter(code => code != null).subscribe(code => {
					createdRegistrationCode$.next(code);
					createdRegistrationCode$.complete();

				});
			} catch (err) {
				this.logger.error(`we have errors: ${err}`)
			}
		} else {
			this.logger.error(`insufficient permission to create an invite code!`);
		}
		return createdRegistrationCode$;
	}

	public saveCode (code:RegistrationInviteCode):void {
		this.logger.info(`saving invite code ${code.guid}`);
		this.repo.save$(code);
	}

	public saveCode$(code:RegistrationInviteCode):Observable<RegistrationInviteCode> {
		return this.repo.save$(code);
	}

	public listCodes$(): Observable<RegistrationInviteCode[]> {
		this.logger.info(`fetching list of invite codes`);
		return this.repo.list$();
	}

	/** Get all User Created Resources */
	public getCodeByOrgGuid$(orgGuid:string):Observable<RegistrationInviteCode[]> {
		return this.repo.query$(this.repo.byOrganizationGuid(orgGuid));
	}

	public getCodeById(guid: string):Observable<RegistrationInviteCode> {
		this.logger.info(`fetching code ${guid}`);
		return this.repo.get$(guid);
	}

	public validate(inviteMessage:InviteMessage) {
		let errors = validateSync(inviteMessage);
		if (errors.length > 0) {
			throw new ValidationException(errors);
		}
	}

	public sanitizeInviteCode(invite:InviteMessage):void {
		//sanitizing temp string
		let cleanString:string = chain(invite.inviteCode).deburr().trim().replace(" ", "-").toLower().value();
		let sanitizedString:string = cleanString.replace(/[^a-z0-9\-]/g, '');
		this.logger.info(`newly sanitized string: ${sanitizedString}`);
		invite.inviteCode = sanitizedString;
	}

}
