<div class="form-group">
    <label for="resources">{{CB.label | localize | async}}
        <i *ngIf="cbTooltip" class="fas fa-question-circle text-secondary mx-1" placement="right" tooltip="{{cbTooltip | localize | async}}"></i>
    </label>
    <ng-select id="resources"
               [(ngModel)]="selectedCollections"
               [items]="selectableCollections"
               [multiple]="true"
               [searchFn]="collectionsSearchFn"
               (change)="emitSelectedCollections()"
               class="bootstrap custom"
               placeholder="{{CB.placeholder | localize | async}}">
        <ng-template let-clear="clear" let-item="item" let-index="index" ng-label-tmp>
            <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">&times;</span>
            <span class="ng-value-label">{{localize.collection(item).title}}</span>
        </ng-template>
        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
            <div class="option">{{localize.collection(item).title}}</div>
        </ng-template>
    </ng-select>
</div>
