import {Converter} from "../ConversionService";
import {Collection} from "../../domain/collections/Collection";
import {CollectionRef} from "../../domain/collections/CollectionRef";
import {RefLocalization} from "../../domain/ITextContentObject";
import {CollectionOwner} from "../../domain/collections/CollectionOwner";
import {CollectionConfiguration} from "../../domain/collections/CollectionConfiguration";

export class CollectionToCollectionRefConverter implements Converter {

	public from:typeof Collection;
	public to:typeof CollectionRef;

	constructor() {
		this.from = Collection;
		this.to = CollectionRef;
	}

	public convert(source:Collection) {
		let guid:string = source.guid;
		let owner:CollectionOwner = source.owner;
		let configuration:CollectionConfiguration = source.configuration;

		//localized content
		let en = source.documentTextContent.en;
		let haw = source.documentTextContent.haw;
		let refLocalization:RefLocalization = {
			title:{
				en:en?.title,
				haw:haw?.title,
			},
			summary: {
				en: en?.summary,
				haw: en?.summary
			}
		};
		return CollectionRef.create(guid, owner, configuration, refLocalization);
	}
}
