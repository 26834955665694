import {RegisterClazz, RegisterType} from "../RegisterType";
import {Exclude, Transform, Type} from "class-transformer";
import {DocumentTextContent} from "../DocumentTextContent";
import {ITextContentObject} from "../ITextContentObject";
import {toClassDocTextTransformFactory, toPlainDocTextTransform} from "../../helper/DocTextContentHelper";
import {CollectionTextContent} from "./CollectionTextContent";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {RefType} from "../RefType";
import {IndexProperty} from "../IndexProperty";
import {CollectionConfiguration} from "./CollectionConfiguration";
import {CollectionOwner} from "./CollectionOwner";
import {TagRef} from "../tags/Tag";
import {firestore} from 'firebase';
import {PublishedResourceRef} from "../resources/PublishedResourceRef";

@RegisterClazz(Collection, "Collections")
export class Collection implements ITextContentObject<CollectionTextContent> {

	@Exclude({toPlainOnly: true})
	public guid:string = null

	// This field is used for indexing the Collections for paging. It doesn't use a decorator like the other domains, because LocalizeTools (inside LocalizationService) is needed to determine which title (en or haw) to use for the index.
	public indexedTitle:string = null;

	@Type(() => CollectionConfiguration)
	@RegisterType({clazz: CollectionConfiguration})
	public configuration:CollectionConfiguration = new CollectionConfiguration();

	// Who the owner is, whether a user or an organization
	@Type(() => CollectionOwner)
	@RegisterType({clazz: CollectionOwner})
	public owner:CollectionOwner = null;

	//document text content transformer
	@Transform(toClassDocTextTransformFactory(CollectionTextContent), {toClassOnly: true})
	@Transform(toPlainDocTextTransform, {toPlainOnly: true})
	public documentTextContent:DocumentTextContent<CollectionTextContent> = new DocumentTextContent<CollectionTextContent>(CollectionTextContent);

	@IndexProperty({isArray: true})
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public collaborators:Array<WaihonaUserRef> = [];

	@IndexProperty({isArray: true})
	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef, isArray: true})
	@RefType({Clazz: WaihonaUserRef, isArray: true})
	public sharedTo:Array<WaihonaUserRef> = [];

	@IndexProperty({isArray: true})
	@Type(() => PublishedResourceRef)
	@RegisterType({clazz: PublishedResourceRef, isArray: true})
	@RefType({Clazz: PublishedResourceRef, isArray: true})
	public items:Array<PublishedResourceRef> = [];

	// the guid of the user who last saved this list
	public lastSavedBy:string = null;

	public tags:Array<TagRef> = [];

	constructor(owner?:CollectionOwner) {
		if (owner) {
			this.owner = owner;
		}
	}

}
