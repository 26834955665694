import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Collection} from "../../../../domain/collections/Collection";
import {SubscriptionCleaner} from "../../../../util/SubscriptionCleaner";
import {ISavedState, NavigationHistoryService} from "../../../../services/NavigationHistoryService";
import {UrlService} from "../../../../services/UrlService";
import {LocalizationService} from "../../../../services/LocalizationService";
import {CollectionTextContent} from "../../../../domain/collections/CollectionTextContent";
import {
	CollectionOwnerType,
	CollectionType,
	CollectionVisibilityType,
	CollectionVisibilityTypeIcon
} from "../../../../domain/collections/CollectionConfigurationEnums";
import {SupportedLanguage} from "../../../../domain/SupportedLanguages";
import {ViewType} from "../../../common/view-switcher/view-switcher.component";
import {Localization} from "../../../../data/Localization";
import {CarouselComponent} from "ngx-bootstrap/carousel";

@Component({
	selector:'app-collection-list-card',
	templateUrl:'./collection-list-card.component.html',
	styleUrls:['./collection-list-card.component.scss']
})
export class CollectionListCardComponent extends SubscriptionCleaner implements OnInit {

	@Input()
	public collection:Collection;
	@Input()
	public isExpanded:boolean = false;
	@Input()
	public currentSearchValue:string;

	@ViewChild(CarouselComponent, {read: ElementRef}) carousel:ElementRef;

	public localize = {
		collection: (collection:Collection)=> this.LocalizeTools.document(collection, CollectionTextContent, this.currentLanguage)
	};

	private commonContext = Localization.template.common;
	private collectionCommonContext = Localization.template.collections.common;
	public CB = {
		resources: this.commonContext.pills.resources,
		collection: this.collectionCommonContext.types.collection.down,
		series: this.collectionCommonContext.types.series.down,
		private: this.collectionCommonContext.types.private.down,
		public: this.collectionCommonContext.types.public.down,
	}

	public visual = {
		collectionImage: ()=> this.urlService.imageUrl.collection(this.collection.guid),
		resourceImageSrc: (resourceGuid:string):string => this.urlService.resourceImage.published(resourceGuid),
		visibilityIcon: (iconType:CollectionVisibilityType)=> CollectionVisibilityTypeIcon[iconType],
		publicityLabel: () => {
			let thisCollection = (this as CollectionListCardComponent).collection;
			if (thisCollection) {
				let visibilityType:CollectionVisibilityType = thisCollection.configuration.visibilityType;
				return visibilityType == CollectionVisibilityType.privateToUser ?
					this.CB.private :
					visibilityType == CollectionVisibilityType.privateToOrg ?
						this.CB.private :
						this.CB.public;
			} else {
				return null;
			}
		},
		publicityClass: () => {
			let thisCollection = (this as CollectionListCardComponent).collection;
			if (thisCollection) {
				//run through LocalizeService Later
				let visibilityType:CollectionVisibilityType = thisCollection.configuration.visibilityType;

				return visibilityType == CollectionVisibilityType.privateToUser ?
					"badge-gray-400" :
					visibilityType == CollectionVisibilityType.privateToOrg ?
						"badge-warning" :
						"badge-primary";
			} else {
				return null;
			}
		},
		CollectionTypeLabel: () => {
			let thisCollection = (this as CollectionListCardComponent).collection;
			if (thisCollection) {
				let collectionType:CollectionType = thisCollection.configuration.collectionType;
				return collectionType == CollectionType.collection ? this.CB.collection : this.CB.series;
			} else {
				return null;
			}
		},
	}

	public is = {
		userOwned: ()=> this.collection.configuration.ownerType == CollectionOwnerType.user,
	}

	constructor(
		protected renderer:Renderer2,
		protected urlService:UrlService,
		protected navHistoryService:NavigationHistoryService,
		protected localizationService:LocalizationService) {
		super();
		this.localizationService.registerAndLocalize("CollectionListCardComponent", this.CB);
	}

	ngOnInit():void {
	}

	ngAfterViewInit():void {
		if (this.carousel) {
			if (this.carousel.nativeElement.children[0].children[2]) {
				const newElement = this.renderer.createElement('span');
				this.renderer.addClass(newElement, "arrow-background");
				const newElement2 = this.renderer.createElement('span');
				this.renderer.addClass(newElement2, "arrow-background");
				this.renderer.appendChild(this.carousel.nativeElement.children[0].children[1], newElement2);
				this.renderer.appendChild(this.carousel.nativeElement.children[0].children[2], newElement);
			}
		}
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}
	public get currentLanguage():SupportedLanguage {
		return this.localizationService.language;
	}
	public get ViewType() {return ViewType};

	public navigateToDetail(selectedId:string) {
		let navCommands = ['collections', selectedId];
		let state:ISavedState = {selectedId: selectedId, filter: this.currentSearchValue};
		this.navHistoryService.saveStateAndNavigate(state, navCommands);
	}
}
