<!--grade levels-->
<div *ngIf="resourceSubmission.gradeLevels.length > 0">
	<h6><strong>{{CB.grades | localize | async}} </strong>
		<span *ngFor="let gradeLevel of resourceSubmission.gradeLevels; let isLast=last">{{gradeLevel.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--subject categories-->
<div *ngIf="resourceSubmission.subjectCategories.length > 0">
	<h6><strong>{{CB.subjects | localize | async}} </strong>
		<span *ngFor="let category of resourceSubmission.subjectCategories; let isLast=last">{{category.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--Indicators-->
<div *ngIf="resourceSubmission.specialIndicators.length > 0">
	<h6><strong>{{CB.flags | localize | async}} </strong>
		<span *ngFor="let category of resourceSubmission.specialIndicators; let isLast=last">{{category.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--Learning Modalities-->
<div *ngIf="resourceSubmission.learningModalities.length > 0">
	<h6><strong>{{CB.modalities | localize | async}} </strong>
		<span *ngFor="let modality of resourceSubmission.learningModalities; let isLast=last">{{modality.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--cultural perspectives-->
<div *ngIf="resourceSubmission.hawaiianCategories.length > 0">
	<h6><strong>{{CB.perspectives | localize | async}} </strong>
		<span *ngFor="let category of resourceSubmission.hawaiianCategories; let isLast=last">{{category.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--aina-->
<div *ngIf="resourceSubmission.aina.length > 0">
	<h6><strong>{{CB.aina | localize | async}} </strong>
		<span *ngFor="let moku of resourceSubmission.aina; let isLast=last">{{moku.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--tags-->
<div class="hidden" *ngIf="resourceSubmission.tags.length > 0">
	<h6><strong>{{CB.tags | localize | async}} </strong>
		<span *ngFor="let tag of resourceSubmission.tags; let isLast=last">{{tag.label}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>

<!--Frameworks-->
<div *ngIf="resourceSubmission.frameworks.length > 0">
	<h6><strong>{{CB.frameworks | localize | async}}: </strong>
		<span *ngFor="let framework of resourceSubmission.frameworks; let isLast=last">{{framework.ref.title}}{{isLast ? '' : ', '}}</span>
	</h6>
</div>
