// noinspection SpellCheckingInspection
import {MinLength, MaxLength, IsNotEmpty, NotContains, IsDefined, ArrayNotEmpty} from 'class-validator';
import {FirebaseFileInfo} from '../../services/storage/FirebaseFileInfo';
import {Exclude, Type} from "class-transformer";
import {IOption} from "ng-select";
import {RegisterClazz, RegisterType} from "../RegisterType";

@RegisterClazz(LearningAsset)
export class LearningAsset {

    public id:Number; //Sort language within a lesson

    /** The Title for the LearningAsset */
    @MinLength(5)
    @MaxLength(32)
    @IsNotEmpty()
    public title:string;//	1		REQUIRED, NOT_BLANK, NOT_NULL, MIN(10), MAX (64)

    @Exclude()
    public beingOverwritten:boolean = false;

	public hasConflictWith:string = "";

    @IsDefined()
    @Type(() => FirebaseFileInfo)
    @RegisterType({clazz:FirebaseFileInfo})
    public fileInfo:FirebaseFileInfo;

    @IsNotEmpty()
    public fileName:string;//	1	The on-disk file name	REQUIRED.  (obtained from file itself [multi-part form upload)

    /* The file extension	The file extension to use */
    @NotContains(".htaccess")
    public extension:string;
    public encoding:string;

    /** The mime type (OPTIONAL) */
    public mimeType:string;
    @ArrayNotEmpty()
    public tags:Array<IOption> = []; //[{value: "Activity" label: "Activity"}]

	public assetSource:AssetSource;

    /** The date the record was created */
    @Type(() => Date)
    public created:Date;
    /** The date the record was modified */
    @Type(() => Date)
    public modified:Date;


    constructor(fileName?:string) {
      if(fileName != null) {
        this.fileName = fileName;
      }
    }


}

/**
 Must be One of the following:
 "updating": LearningResource is being published or updated (files are uploading) and not yet ready to be reviewed.
 "unreviewed": The files/LearningResource are ready to be reviewed but not yet reviewed"
 "reviewed": "The files have been reviewed but have not yet been published (perhaps creator wants to wait to publish?).
 "published": The files are live and published"
 "inappropriate": The LearningResource has been flagged for inappropriate content.
 "disabled": Although the content is reviewed and approved its access has been disabled. */
export enum LearningAssetStatus {
    waiting = "waiting",          // Not yet uploaded
    reserved = "reserved",        // lesson is being edited and the item is a placeholder.
    uploading = "uploading",      // lesson is being published or updated (files are uploading) and not yet ready to be reviewed.
    transcoding = "transcoding",  // The files/lesson are ready to be reviewed but not yet reviewed
    ready = "ready",              // The file is ready
    error = "error",              // An error occured
}

export enum FriendlyFileTypes {
    "audio" = "audio",
    "video" = "video",
    "image" = "image",
    "document" = "document",
    "spreadsheet" = "spreadsheet",
    "presentation" = "presentation",
    "compressed" = "compressed",
    "unknown" = "unknown"
}

export enum AssetSource {
	"userUpload" = "userUpload",
	"fanContribution" = "fanContribution",
	"googleDrive" = "googleDrive"
}
