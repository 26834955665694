import {RegisterClazz} from "../RegisterType";

@RegisterClazz(ResourceConfiguration)
export class ResourceConfiguration {
	public allowFanContributions:boolean = false;
	public allowPrivateMessages:boolean = false;
	public allowPublicComments:boolean = false;
	public allowPublicAccess:boolean = false;
	public hideSubmitterName:boolean = false;
}
