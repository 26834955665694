import {IsNotEmpty, MaxLength, MinLength} from 'class-validator';
import {RegisterClazz} from "../RegisterType";
import {Exclude} from "class-transformer";
import {SupportedLanguage} from "../SupportedLanguages";
import {MaxPlainTextLength} from "../../validators/MaxPlainTextLength";

@RegisterClazz(BadgeTextContent)
export class BadgeTextContent {

	@Exclude()
	public $render:{[key:string]:SupportedLanguage};

	@MinLength(2)
	@MaxLength(17)
	@IsNotEmpty()
	public name:string = "";

	/** previously called 'info' */
	@MinLength(10)
	@MaxPlainTextLength(500)
	@IsNotEmpty()
	public description:string = "";

	@MinLength(10)
	@MaxPlainTextLength(500)
	@IsNotEmpty()
	public criteria:string = "";

	constructor() {
		this.$render = {};
	}

}
