import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {Observable, Subject} from "rxjs";
import {HttpHelperUtils} from "./HttpHelperUtils";

@Injectable({
	providedIn:'root',
} as any)
export class DownloadService {

	constructor(protected logger:NGXLogger,
				protected httpHelperUtils:HttpHelperUtils) {
	}

	public tryDownload(url):Observable<string> {
		let downloadObservable:Subject<string> = new Subject<string>();
		this.httpHelperUtils.fileExists(url).take(1).subscribe((fileExists) => {
			if (fileExists) {
				// actually download the file
				window.open(url, '_self');
				downloadObservable.next("Download beginning!");
			} else {
				downloadObservable.next("File Not Found");
			}
		});
		return downloadObservable;
	}

	public downloadPlainTextFile(filename:string, text:string):void {
		let element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
		element.setAttribute('download', filename);
		element.style.display = 'none';

		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

}

