import {Pipe, PipeTransform} from "@angular/core";
import {CategoryForIOption, ICategorizedIOption} from "../data/LessonData";
import {LearningAsset} from "../domain/resources/LearningAsset";
import {IOption} from "ng-select";
import * as getValue from 'get-value';
import {uniq} from "lodash";
import {findBestMatch} from "../helper/TextMatchUtils";
import {SelectableFrameworkRef} from "../domain/frameworks/SelectableFrameworkRef";
import {Subject} from "rxjs";
import {PublishedResource} from "../domain/resources/PublishedResource";
import {NupepafyStringUtils} from "../util/NupepafyStringUtils";

export interface IScore {
	target:string, rating:number
}

export interface IScores {
    ratings:Array<IScore>,
	bestMatch:IScore
	bestMatchIndex: number
}

@Pipe({
	name: 'publishedResourceFilter',
})
export class PublishedResourceFilterPipe implements PipeTransform  {

	private exactMatch:boolean;

	constructor() {

	}


	/**
	 * Filters published resources and chooses only those which match the filter
	 * @param publishedResources    The publishedResources to filter
	 * @param filters               The filters (built up from the UI) to filter against
	 * @param filterMetadata        Extra data about the filter and results
	 * @param resultCount           An Observable of the number of results after filtering
	 * @param exactMatch            Whether the filter will search for only exact matches or not (if not, will match strings where search string is included within it)
	 */
	public transform(publishedResources:Array<PublishedResource>, filters:Array<ICategorizedIOption> = [], resultCount:Subject<number>, exactMatch:boolean):any {
		console.log(`Filtering ${publishedResources.length} against ${filters.length} filters.`);
		if (publishedResources == null || publishedResources.length == 0) {
			resultCount.next(0);
			return [];
		}
		if (filters.length == 0) {
			console.info(`No filters.`);
			resultCount.next(publishedResources.length);
			return publishedResources;
		}

		let remaining:Array<PublishedResource> = publishedResources.slice(); //Make a copy..we will whittle it away
		this.exactMatch = exactMatch;

		//combine filters by their type

		// All related items (same category) will be combined with an OR clause;
		//   and unrelated items (different category) will be combined with an AND clause
		let filtersInCategories = {
			[CategoryForIOption.GradeOptions]:       filters.filter((cat => cat.category == CategoryForIOption.GradeOptions)),
			[CategoryForIOption.AssetTypes]:         filters.filter((cat => cat.category == CategoryForIOption.AssetTypes)),
			[CategoryForIOption.SubjectCategories]:  filters.filter((cat => cat.category == CategoryForIOption.SubjectCategories)),
			[CategoryForIOption.SpecialIndicators]:  filters.filter((cat => cat.category == CategoryForIOption.SpecialIndicators)),
			[CategoryForIOption.HawaiianCategories]: filters.filter((cat => cat.category == CategoryForIOption.HawaiianCategories)),
			[CategoryForIOption.LearningModalities]: filters.filter((cat => cat.category == CategoryForIOption.LearningModalities)),
			[CategoryForIOption.Languages]:          filters.filter((cat => cat.category == CategoryForIOption.Languages)),
			[CategoryForIOption.Framework]:          filters.filter((cat => cat.category == CategoryForIOption.Framework)),
			[CategoryForIOption.Contributors]:       filters.filter((cat => cat.category == CategoryForIOption.Contributors)),
			[CategoryForIOption.Text]:               filters.filter((cat => cat.category == CategoryForIOption.Text)),
			[CategoryForIOption.Aina]:               filters.filter((cat => cat.category == CategoryForIOption.Aina)),
		};
		let filtersInCategoriesKeys:Array<string> = Object.keys(filtersInCategories);
		//let filtersInCategoriesAsArray:Array<ICategorizedIOption>[] = Object.values(filtersInCategories);

		let categories:Array<CategoryForIOption|string> = [];

		// START::Console Info about filters -------------------------------------------------------
		let combinedSearchQueryString:string = "";
		for(let k=0; k < filtersInCategoriesKeys.length; k++) {
			let key:string = filtersInCategoriesKeys[k];
			let value:Array<ICategorizedIOption> = filtersInCategories[key];
			let length:number = value.length;
			if (length > 0) {
				categories.push(key); //categories used to get data later
				combinedSearchQueryString += `${length} search filters for ${key}`;
				if (k + 1 < filtersInCategoriesKeys.length) {
					combinedSearchQueryString += ", ";
				}
			}
		}
		if (combinedSearchQueryString == "") {
			combinedSearchQueryString = "No filters.";
		}
		console.log(`${combinedSearchQueryString}`);
		// END::Console Info about filters -------------------------------------------------------

		let allTextFilters:Array<ICategorizedIOption> = [];
		//Go category by category (ands) and within the category (ors)
		for(let category of categories) {
			let filtersInCategory:Array<ICategorizedIOption> = filtersInCategories[category];
			let currentCategory:Array<PublishedResource> = []; //These are OR's

			for(let filter of filtersInCategory) {
				//This should be unused and grey! or commented out (better)
				let pathHelper:PublishedResource; //use for codehinting while generating path strings
				switch (filter.category) {
					case CategoryForIOption.AssetTypes:
						currentCategory.push(...this.filterByAssetType(remaining, filter));
						break;
					case CategoryForIOption.SubjectCategories:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.subjectCategories", filter));
						break;
					case CategoryForIOption.SpecialIndicators:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.specialIndicators", filter));
						break;
					case CategoryForIOption.HawaiianCategories:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.hawaiianCategories", filter));
						break;
					case CategoryForIOption.LearningModalities:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.learningModalities", filter));
						break;
					case CategoryForIOption.Framework:
						currentCategory.push(...this.filterByFrameworks(remaining, filter));
						//
						break;
					case CategoryForIOption.GradeOptions:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.gradeLevels", filter));
						break;
					case CategoryForIOption.Languages:
						currentCategory.push(...this.filterByLanguage(remaining, filter));
						break;
					case CategoryForIOption.Contributors:
						currentCategory.push(...this.filterByPropertyOrOptionArray(remaining, "resourceSubmission.submitter", filter, "guid"));
						break;
					case CategoryForIOption.Text:
						currentCategory.push(...this.filterByText(remaining, filter));
						allTextFilters.push(filter);
						break;
					case CategoryForIOption.Aina:
						currentCategory.push(...this.filterByAina(remaining, filter));
						break;
				}
			}
			//We have all of the ors now...
			let noDuplicateArrayForCategory:Array<PublishedResource> = uniq(currentCategory); //Remove duplicates
			remaining = noDuplicateArrayForCategory;
		}
		if(remaining.length == 0 || allTextFilters.length == 0) {
			//no need to go further because there is no scoring/sorting to do
			resultCount.next(remaining.length);
			return remaining;
		}

		//Score any text
		// ---------------------------------------------------------------------
		let allRemainingJustText:Array<string> = remaining.map(publishedResource => {
			return publishedResource.resourceSubmission.text.toLowerCase();
		});

		let allTextFilterScores:Array<IScores> = [];
		for(let textFilter of allTextFilters) {
			let scoresForThisTextFilter:IScores = findBestMatch(textFilter.value.toLowerCase(), allRemainingJustText);
			allTextFilterScores.push(scoresForThisTextFilter);
		}
		let averageTextFilterScores:Array<{publishedResource:PublishedResource, score:number}> = [];
		let totalTextFilters:number = allTextFilterScores.length;

		for(let i:number = 0; i < remaining.length; i++) {
			let publishedResource:PublishedResource =  remaining[i];
			let averageFilterScore:number = 0;
			let averageScores:Array<number> = [];
			for(let j:number = 0; j < totalTextFilters; j++) {
				let currentTextFilterScores:IScores = allTextFilterScores[j];
				let filterScoreRatingForThisResource:number = currentTextFilterScores.ratings[i].rating;
				averageScores.push(filterScoreRatingForThisResource);
			}
			//average filter score among all the text filters
			averageFilterScore =  averageScores.reduce((a,b) => a + b, 0) / averageScores.length;
			averageTextFilterScores.push({publishedResource: publishedResource, score: averageFilterScore}); //numbers stay exact order because it was empty
		}
		let sortedTextFilterScores:Array<{publishedResource: PublishedResource, score: number}> = averageTextFilterScores.slice().sort((a,b) => {
			if(a.score < b.score){
				return 1;
			} else if(a.score > b.score){
				return -1;
			} else{
				return 0;
			}
		});

		//just the resource..dont need the scores
		remaining = sortedTextFilterScores.map(scoredResource => {
			return scoredResource.publishedResource;
		});

		resultCount.next(remaining.length);
		return remaining;
	}


	private filterByAssetType(publishedResources:Array<PublishedResource>, filter:ICategorizedIOption):Array<PublishedResource> {
		let containsAssetType:Array<PublishedResource> = publishedResources.filter(publishedResource => {
			let learningAsset:LearningAsset =
				publishedResource.resourceSubmission.files.find(file => {
					let matchingTag:IOption = file.tags.find(tag => {
						return tag.value == filter.value;
					});
					return matchingTag != null;
				});
			return learningAsset != null;
		});
		return containsAssetType;
	}
	private filterByLanguage(publishedResources:Array<PublishedResource>, filter:ICategorizedIOption):Array<PublishedResource> {
		let containsLanguage:Array<PublishedResource> = publishedResources.filter(publishedResource => {
				return publishedResource.resourceSubmission.documentTextContent[filter.value] != null;
		});
		return containsLanguage;
	}

	private filterByAina(publishedResources:Array<PublishedResource>, filter:ICategorizedIOption):Array<PublishedResource> {
		let containsAina:Array<PublishedResource> = publishedResources.filter(publishedResource => {
			return publishedResource.resourceSubmission.aina.find(aina=> {
				return aina.guid == filter.value;
			});
		});
		return containsAina;
	}

	private filterByFrameworks(publishedResources:Array<PublishedResource>, filter:ICategorizedIOption):Array<PublishedResource> {

		let frameworkSelectableFrameworkRef:SelectableFrameworkRef = filter.data as SelectableFrameworkRef;
		let resourcesContainingFramework:Array<PublishedResource> = publishedResources.filter(publishedResource => {
			if (!(frameworkSelectableFrameworkRef && frameworkSelectableFrameworkRef.ref)) {
				return;
			}

			let frameworksContainedOnResource:Array<SelectableFrameworkRef> = publishedResource.resourceSubmission.frameworks.filter(resourceSubmissionSref => {
				let matches:boolean = resourceSubmissionSref.ref.guid == frameworkSelectableFrameworkRef.ref.guid;
				if (!matches) {
					return false;
				}
				let noDetailUsed:boolean = frameworkSelectableFrameworkRef.selectedKeys.length == 0;
				if (noDetailUsed) {
					return true;
				}
				for (let key of frameworkSelectableFrameworkRef.selectedKeys) {
					let containsKey:boolean = resourceSubmissionSref.selectedKeys.indexOf(key) != -1;
					if(containsKey) {
						return containsKey;  //dont need to go to next
					}
				}

				return false;

			});

			return frameworksContainedOnResource.length > 0;
		});

		return resourcesContainingFramework;
	}

	private filterByText(publishedResources:Array<PublishedResource>, filter:ICategorizedIOption):Array<PublishedResource> {
		let containsLanguage:Array<PublishedResource> = publishedResources.filter(publishedResource => {
			//lets put all the text fields together:
			let allTextForResourceArray:Array<string> = [];

			let hasHawaiian:boolean = !!publishedResource.resourceSubmission.documentTextContent.haw;
			let hasEnglish:boolean = !!publishedResource.resourceSubmission.documentTextContent.en;
			if (hasHawaiian) {
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.haw.title);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.haw.modifications);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.haw.description);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.haw.summary);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.haw.notes);
			}
			if (hasEnglish) {
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.en.title);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.en.modifications);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.en.description);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.en.summary);
				allTextForResourceArray.push(publishedResource.resourceSubmission.documentTextContent.en.notes);
			}
			let filterValue:string = filter.value;
			let doesContain:boolean;
			let stringToSearch = publishedResource.resourceSubmission.text;
			let regex = new RegExp(`\\b${filterValue}\\b`, 'g');
			if (this.exactMatch) {
				doesContain = regex.test(stringToSearch);
			} else {
				filterValue = NupepafyStringUtils.nupepafy(filter.value.toLowerCase());
				doesContain = NupepafyStringUtils.nupepafy(stringToSearch.toLowerCase()).indexOf(filterValue) != -1;
			}
			return doesContain;
		});
		return containsLanguage;
	}

	/**
	 * Filter by an Option Array
	 * @param publishedResources an Array of PublishedResource
	 * @param path A path like "resourceSubmission.gradeLevels" which refers to "publishedResource.resourceSubmission.gradeLevels"
	 * @param filter a ICategorizedIOption that was chosen as a filters
	 */
	private filterByPropertyOrOptionArray(publishedResources:Array<PublishedResource>, path:string, filter:ICategorizedIOption, property?:string):Array<PublishedResource> {
		let whatMatches:Array<PublishedResource> = publishedResources.filter(publishedResource => {
			if(!!property) {
				let object:any = getValue(publishedResource, path);
				return object[property] == filter.value;
			}
			let optionsArray:Array<IOption> = getValue(publishedResource, path);
			let matchedThing:IOption = optionsArray.find(option => {
				return option.value == filter.value;
			});
			return matchedThing != null;
		});
		return whatMatches;
	}
}
