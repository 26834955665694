import "reflect-metadata";
import {Registry} from "./Registry";

export interface IType {
	clazz:(new()=>{})|any;
	isArray?:boolean;
	isObjectMap?:boolean;
}

export interface ITypeInformation {
	forClazz:new()=>{};
	forClazzName:string,
	property:string,
	propertyClazz:new ()=>{};
	propertyClazzName:string;
	isArray:boolean;
//	isIndexedArray:boolean;
}

export function RegisterClazz(Clazz:(new()=>{}) | any, collectionPath?:string) { //any is needed because of constructors with non-optional arguments
	Registry.registerClazz(Clazz, collectionPath);
	return function (constructor: Function) {
	}
}
export function RegisterType(typeInformation:IType):any {
	if(typeInformation.isArray != true) {
		typeInformation.isArray = false;
	}
	return (target:Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
		//this.logger.info(`target.constructor.name: ${target.constructor.name}`);
		let Clazz:new()=>{} = Registry.getClazz(target.constructor.name);

		//this.logger.info(`Clazz undefined?: ${Clazz == undefined}`);
		let o:ITypeInformation = {
			forClazz: Clazz,
			forClazzName: target.constructor.name,
			property:propertyKey,
			propertyClazz:typeInformation.clazz,
			propertyClazzName:typeInformation.clazz.name,
			isArray:typeInformation.isArray
		};

		Reflect.defineMetadata("custom:annotations:register", o, target, propertyKey);
		Registry.set(o);
		return descriptor;
	};
}

export function GetRegisteredType(Target:new()=>{}):Array<string[]> {
	// get info about keys that used in current property
	let allDecorators = [];
/*	this.logger.info("Foo2: " + JSON.stringify(Object.getOwnPropertyNames(Target), null, 2));*/
	let target = new Target();
	let allKeys:string[] = Object.getOwnPropertyNames(target);
	//this.logger.info("Foo: " + JSON.stringify(allKeys, null, 2));

	for(const propertyName in allKeys) {

		const keys: any[] = Reflect.getMetadataKeys(Target, propertyName);
		//console.log("---" + JSON.stringify(keys,null,2))
/*		this.logger.info("property: " + propertyName + Target[propertyName]);
		this.logger.info("propertyKeys: " + JSON.stringify(Reflect.getMetadataKeys(target, propertyName), null, 2));*/

		const decorators = keys
		// filter your custom decorators
			.filter(key => key.toString().startsWith("custom:annotations"))
			.reduce((values, key) => {
				// get metadata value.
				const currValues = Reflect.getMetadata(key, Target, propertyName);
				return values.concat(currValues);
			}, []);
		allDecorators.push(decorators);
	}
	return allDecorators;
}
