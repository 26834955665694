import {Injectable} from "@angular/core";
import {AbstractOldFirebaseFunctions} from "./AbstractOldFirebaseFunctions";
import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable, Subject} from "rxjs";
import {PublishedResource} from "../../domain/resources/PublishedResource";
import {NGXLogger} from "ngx-logger";

@Injectable({
	providedIn: 'root',
} as any)
export class ResourceFunctions extends AbstractOldFirebaseFunctions<PublishedResource> {

	public readonly uris = {
		"deletePublishedResourceFromStorage":null,
		"acceptFanContribution":null,
		"deleteFanContribution":null,
		"renameFanContributionFile":null
	};

	constructor(protected functions:AngularFireFunctions,
	            protected logger:NGXLogger) {
		super(PublishedResource, functions, "guid");
		this.initUris(this.uris);
	}

	/** Delete a published resource folder from storage */
	public deletePublishedResourceFromStorage(guid:string):Observable<PublishedResource> {
		return this.callDirect(this.ref(this.uris.deletePublishedResourceFromStorage), {guid: guid});
	}

	/** Delete those FanContribution files */
	public deleteFanContribution(fanContributionGuid:string,):Observable<any> {
		console.info(`deleteFanContribution calling server-side function to delete files`);
		return this.callDirect(this.ref(this.uris.deleteFanContribution), {fanContributionGuid:fanContributionGuid});
	}


	/** Renames a file and returns the new file */
	public renameFanContributionFile(copyInstructions:Array<ICopyInstruction>):Observable<any> {
		console.info(`ResourceFunctions is calling server-side renameFanContributionFile`);
		let message:ICopyInstructionMessage = {
			instructions: copyInstructions
		};
		return this.callDirect(this.ref(this.uris.renameFanContributionFile), message);
	}

	/** Copy assets from fan contribution in storage to draft resource assets folder in storage and delete those files */
	public acceptFanContribution(copyInstructions:Array<ICopyInstruction>):Observable<any> {
		console.info("ResourceFunctions:acceptFanContribution");

		let message:ICopyInstructionMessage = {
			instructions: copyInstructions
		};
		return this.callDirect(this.ref(this.uris.acceptFanContribution), message);
	}


}

export interface ICopyInstructionMessage {
	instructions:Array<ICopyInstruction>;
}

export interface ICopyInstruction {
	source:string;
	destination:string;
}
