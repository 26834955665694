import {Injectable} from "@angular/core";
import {NGXLoggerMonitor, NGXLogInterface} from "ngx-logger";

@Injectable({
	providedIn: 'root'
})
export class LogMonitoringService implements NGXLoggerMonitor {

	public stack:Array<NGXLogInterface> = [];

	public onLog(log:NGXLogInterface):void {
		this.stack.unshift(log);

		//Avoid this from taking up too much memory
		while(this.stack.length > 100) {
			this.stack.pop();
		}
	}
	public getLogs():string {
		let logs:string = "";
		let i:number = 0;
		for(let log of this.stack) {
			let isMultipleArguments:boolean = log.additional.length > 0;
			let message:string = isMultipleArguments ? log.message + " " + log.additional.join(' ') : log.message;
			logs += `${log.timestamp} ${this.getLogLevelName(log.level)} [${log.fileName}:${log.lineNumber}] ${message}`;
			if(i+1 < this.stack.length) {
				logs += "\n";
			}
			i++;
		}
		return logs;
	}
	protected getLogLevelName(level:number):string {
		return ['TRACE','DEBUG','INFO','LOG','WARN','ERROR','FATAL','OFF'][level];
	}
}
