import {Injectable} from '@angular/core';
import {PopupConfigOptions, PopupLoginOptions, RedirectLoginResult} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {from, of, Observable, BehaviorSubject, combineLatest, Subscription, Subject} from 'rxjs';
import {tap, concatMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import * as firebase from "firebase/app";
import {User} from "firebase";
import {AngularFireAuth} from "@angular/fire/auth";
import {Router} from "@angular/router";
import {ConversionService} from "./ConversionService";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserService} from "./WaihonaUserService";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {AuthFunctions, IGetCustomTokenResponse} from "./functions/AuthFunctions";
import {NGXLogger} from "ngx-logger";
import {DeviceDetectorService} from "ngx-device-detector";
import {CookieService} from "ngx-cookie-service";
import {NgZone} from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public isHandlingLocalSelfAuth$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	// Define observables for SDK methods that return promises by default
	// For each Auth0 SDK method, first ensure the client instance is ready
	// concatMap: Using the client instance, call SDK method; SDK returns a promise
	// from: Convert that resulting promise into an observable
	public isAuthenticated_b$:Observable<boolean> = this.authFunctions.auth0Client$.pipe(
		concatMap((client:Auth0Client) => {
			return from(client.isAuthenticated());
		}),
		tap(res => this.isAuthenticated$.next(res))
	);
	public handleRedirectCallback$ = this.authFunctions.auth0Client$.pipe(
		concatMap((client:Auth0Client) => from(client.handleRedirectCallback()))
	);

	private loggingInOnMobile:boolean = null;

	public get isAuthenticatingCurrently$():Observable<boolean> {
		return this.authFunctions.isInTokenAuth$();
	}
	public get isAuthenticatingCurrently():boolean {
		return this.authFunctions.isInTokenAuth();
	}

	public constructor(private router:Router,
					   private ngZone:NgZone,
					   private authFunctions:AuthFunctions,
					   private waihonaUserService:WaihonaUserService,
					   private angularFireAuth:AngularFireAuth,
					   private conversionService:ConversionService,
					   protected deviceDetectorService:DeviceDetectorService,
					   private cookieService:CookieService,
					   protected logger:NGXLogger) {

		this.isAuthenticated_b$.subscribe(authenticated => {
			this.isAuthenticated$.next(authenticated);
		});

		this.authFunctions._isHandlingLocalSelfAuth$.subscribe((newValue:boolean) => {
			this.isHandlingLocalSelfAuth = newValue;
		});

		firebase.auth().onAuthStateChanged((state: any) => {
			this.authFunctions.prevAuthState = this.authFunctions.currentAuthState;
			this.authFunctions.currentAuthState = state;
			this.authFunctions.firebaseAuthUser.next(firebase.auth().currentUser);

			this.afterFirebaseAuthStateChange();
		});

		this.localAuthSetup();
	}

	private afterFirebaseAuthStateChange() {
		this.logger.info("AuthService::afterFirebaseAuthStateChange");
		// if we just logged out of firebase, then log out of auth0 as well.
		if (this.authFunctions.currentAuthState == null) {
			if (!!this.authFunctions.prevAuthState) {
				this.authFunctions.auth0Client$.take(1).subscribe((authClient:Auth0Client) => {
					authClient.getUser().then((user) => {
						if (user) {
							this.authFunctions.auth0Logout();
						}
					});
				});
			}
		}

		if (firebase.auth().currentUser && !this.authFunctions.prevAuthState?.uid) {
			if (!this.authFunctions.freshLogin) {
				console.log("running session update");
				this.authFunctions.refreshToken().take(1).subscribe((tokenRefreshed:boolean) => {
					if (tokenRefreshed) {
						this.isAuthenticated$.next(true);
						this.authFunctions.loggedInFirebase = true;
						this.authFunctions.scheduleFirebaseRenewal();
						this.authFunctions.refreshTokenOnUserRoleUpdate(firebase.auth().currentUser.uid);
						this.authFunctions.updatesScheduled = true;
					}
					this.authFunctions.freshLogin = false;
				});
			} else if (this.isAuthenticated && !this.isHandlingLocalSelfAuth && !this.authFunctions.updatesScheduled) {
				this.authFunctions.scheduleFirebaseRenewal();
				this.authFunctions.refreshTokenOnUserRoleUpdate(firebase.auth().currentUser.uid);
				this.authFunctions.updatesScheduled = true;
			}
		}
	}

	public get authState():AuthState {
		let quickLoader:boolean = this.currentUser == null && this.isHandlingLocalSelfAuth;
		let slowLoader:boolean = this.currentUser == null && !quickLoader && this.authFunctions._isAuthenticatingCurrently$.getValue();
		let other:boolean = this.currentUser == null && this.isAuthenticated;
		if(this.currentUser != null && this.isAuthenticated) {
			return AuthState.loggedIn;
		} else if(quickLoader || slowLoader || other) {
			return AuthState.loggingIn;
		} else if(this.currentUser) {
			return AuthState.loggedIn;
		}
		return AuthState.loggedOut;
	}
	public get isAuthenticated():boolean {
		return this.authFunctions._isAuthenticated$.getValue();
	}

	public get isAuthenticated$():BehaviorSubject<boolean> {
		return this.authFunctions._isAuthenticated$;
	}

	public get currentUser$():Observable<WaihonaUser> {
		return this.authFunctions._currentUser$;
	}

	public get currentUser():WaihonaUser {
		return this.authFunctions._currentUser$.getValue();
	}
	public get isHandlingLocalSelfAuth():boolean {
		return this.isHandlingLocalSelfAuth$.getValue();
	}

	public set isHandlingLocalSelfAuth(value:boolean) {
		//Only update if the value is changing
		if(value != this.isHandlingLocalSelfAuth$.getValue()) {
			console.info("setting isHandlingLocalSelfAuth: " + value);
			this.isHandlingLocalSelfAuth$.next(value);
		}
	}

	public get currentUserRef():WaihonaUserRef {
		if(this.currentUser == null) {
			return null;
		}
		return this.conversionService.convert(this.currentUser, WaihonaUserRef);
	}

	// When calling, options can be passed if desired
	// https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
	public getUser$(options?):Observable<any> {
		let self = this;
		return this.authFunctions.auth0Client$.pipe(
			concatMap((client:Auth0Client) => from(client.getUser(options))),
			tap(user => {
				self.authFunctions._isAuthenticatingCurrently$.next(true);

				self.authFunctions.setAuth0Session({}, user).subscribe((res:IGetCustomTokenResponse) => {
					self.authFunctions._currentUser$.next(res.token.waihonaUser);
					self.authFunctions._isAuthenticatingCurrently$.next(false);
				});
				//self.logger.info(`Got user: ${JSON.stringify(user, null, 2)}`);
				return self.authFunctions.userProfileSubject$.next(user)
			})
		);
	}

	// This should only be called on app initialization
	// Set up local authentication streams
	public localAuthSetup() {
		this.isHandlingLocalSelfAuth = true;
		//Detect if there is currently a firebaseAuthUser
		let s:Subscription = this.authFunctions.firebaseAuthUser.take(1).subscribe((user:User) => {

			if (user) {
				console.log(`There was previously a current user - already logged in so no need to wait for slow function call. uid: ${user?.uid}`);
				this.authFunctions.loggedInFirebase = true;
				console.log("loggedIn: " + !!user);
				this.authFunctions.refreshToken().take(1).subscribe((loggedIn:boolean) => {
					if (loggedIn) {
						console.log("signed in with new token: " + loggedIn);
						this.afterFirebaseAuthStateChange();
					}
				});
				this.authFunctions.freshLogin = true;
				setTimeout(()=> {
					if(this.currentUser == null && this.isHandlingLocalSelfAuth) {
						console.log("login timed out");
						this.isHandlingLocalSelfAuth = false;
					}
				}, 10000);
			} else {
				this.handlePopupLoginComplete();
			}
		}, err => {
			s.unsubscribe();
		});
	}

	protected handlePopupLoginComplete() {
		const checkAuth$ = this.isAuthenticated_b$.pipe(
			concatMap((loggedIn:boolean) => {
				if (loggedIn) {
					// If authenticated, get user and set in app
					// NOTE: you could pass options here if needed
					this.logger.info("logging user in");
					return this.authFunctions.refreshToken();
				}
				this.isHandlingLocalSelfAuth = false;
				// If not authenticated, return stream that emits 'false'
				return of(loggedIn);
			})
		);
		checkAuth$.subscribe((response:{ [key:string]:any } | boolean) => {
			// If authenticated, response will be user object
			// If not authenticated, response will be 'false'
			this.isAuthenticated$.next(!!response);
			console.log("logged in?: " + response);
			if (!!response) {
				this.ngZone.run(()=> {
					console.log("navigating to initial destination...");
					let initialDestination:string = this.cookieService.get("loginDestination");
					console.log(initialDestination);
					this.router.navigate([initialDestination]).then(() => {
						this.cookieService.delete("loginDestination");
					});
				});
				console.log("fresh login");
				this.authFunctions.freshLogin = true;
				this.authFunctions.loggedInFirebase = true;
				this.isHandlingLocalSelfAuth = false;
			}
		});
	}

	public login(redirectPath:string = '/') {

		// A desired redirect path can be passed to login method
		// (e.g., from a route guard)
		// Ensure Auth0 client instance exists
		let self = this;
		this.authFunctions.auth0Client$.subscribe((client:Auth0Client) => {
			// Call method to log in
			let popupOptions:PopupLoginOptions = {
				prompt: "login",
				audience: this.authFunctions._options.audience,
				//TODO: there are more options to use
			};

			let popupConfigOptions:PopupConfigOptions = {
				timeoutInSeconds: 120
			};
			this.logger.info(`Login.  DEVICE INFO\n ${JSON.stringify(this.deviceDetectorService.getDeviceInfo())}`);
			if(this.deviceDetectorService.isDesktop()) {
				this.logger.info("Opening in popup.  Desktop.");
				client.loginWithPopup(popupOptions, popupConfigOptions).then(doneLogin => {
					self.authFunctions._isAuthenticatingCurrently$.next(true);
					self.handlePopupLoginComplete();
					this.authFunctions.freshLogin = true;
				}, error => {
					console.log("Error logging in through popup: ");
					console.log(error);
				});
			} else { //is mobile or tablet
				this.loggingInOnMobile = true;
				this.authFunctions.freshLogin = true;
				this.logger.info(`Opening with tab.  Mobile? ${this.deviceDetectorService.isMobile()}, Tablet? ${this.deviceDetectorService.isTablet()} `);
				this.authFunctions._isAuthenticatingCurrently$.next(true);
				client.loginWithRedirect({
					redirect_uri: `${this.authFunctions._options.redirect_uri}`,
					appState: {target: redirectPath}
				});
			}
		});
	}

	// Only the callback component should call this method
	// Call when app reloads after user logs in with Auth0
	public handleAuthCallback() {
		// Only the callback component should call this method
		// Call when app reloads after user logs in with Auth0
		let targetRoute:string; // Path to redirect to after login processsed
		const authComplete$ = this.handleRedirectCallback$.pipe(
			// Have client, now call method to handle auth callback redirect
			tap(cbRes => {
				// Get and set target redirect route from callback results
				targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
			}),
			concatMap(() => {
				// Redirect callback complete; get user and login status
				return this.isAuthenticated_b$;
			})
		);
		// Subscribe to authentication completion observable
		// Response will be an array of user and login status
		authComplete$.subscribe((result:boolean) => {
			// Redirect to target route after callback processing
			//this.logger.info(`user: ${JSON.stringify(user, null, 2)}`);
			if (result) {
				console.log("logging in on mobile");
			}

			window.location.href = `/#/`;
			this.router.navigate([targetRoute]);
		});
		return authComplete$;
	}

	public logout() {
		this.authFunctions.logout();
	}

	public async checkIfLoggedIn() {
		let subscriptions:Array<Subscription> = [];
		function clearSubscriptions():void {
				while(subscriptions.length > 0) {
				let s:Subscription = subscriptions.pop();
				s.unsubscribe();
			}
		}

		console.info("isAuthenticatingCurrently: " + this.isAuthenticatingCurrently);
		console.info("isHandlingLocalSelfAuth: " + this.isHandlingLocalSelfAuth);
		let isAUserWaitingForLogin:boolean = this.isAuthenticatingCurrently || this.isHandlingLocalSelfAuth;

		if (isAUserWaitingForLogin) {
			console.info("Waiting for login process to complete");
			let waitForLoginPromise:Promise<boolean> = new Promise((resolve, reject) => {
				let didResolve:boolean = false;

				setTimeout(() => {
					if (!didResolve) {
						console.info("guard timed out");
						clearSubscriptions();
						resolve(false)
					}

				}, 10000);

				let wasHandlingLocalSelfAuth:boolean = this.isHandlingLocalSelfAuth;
				let wasHandlingNormalAuthenticating:boolean = this.isAuthenticatingCurrently;

				if (this.isAuthenticatingCurrently) {
					subscriptions.push(this.isAuthenticatingCurrently$.subscribe(isLoggingIn => {
						console.info("Logging in process data has been changed" + isLoggingIn);
						clearSubscriptions();
						if (isLoggingIn == false && wasHandlingNormalAuthenticating) {
							console.info("Completed login process");
							didResolve = true;
							clearSubscriptions();
							resolve(true);
						}
					}));
				} else if (this.isHandlingLocalSelfAuth) {
					subscriptions.push(this.isHandlingLocalSelfAuth$.subscribe(isHandlingLocalSelfAuth => {
						if (isHandlingLocalSelfAuth == false && wasHandlingLocalSelfAuth) {
							console.info("Completed local auth process");
							didResolve = true;
							clearSubscriptions();
							resolve(true);
						}
					}));
				}
			});

			let successfullyLoggedIn:boolean = await waitForLoginPromise;

			console.info("Waited for login process to complete succesfully, isLoggingIn==" + this.isAuthenticatingCurrently + ", successfullyLoggedIn: " + successfullyLoggedIn);
			return;

		} else {
			return;
		}
	}

}
export enum AuthState {
	"loggedOut"="loggedOut",
	"loggingIn"="loggingIn",
	"loggedIn"="loggedIn",
	/*"loggingOut"="loggingOut",*/
}
