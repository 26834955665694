import {Injectable} from '@angular/core';
import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {ContentBlock} from "../../domain/content/ContentBlock";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";

@Injectable()
export class ContentBlockRepository extends AbstractFirestoreRepository<ContentBlock> {

	constructor(protected db:AngularFirestore) {
		super(ContentBlock, db, db.collection("ContentBlocks"));
	}

	public byMissingData(language:string):QueryFn {
		let queryFn:QueryFn = ref => {
			return ref.where(`@content.${language}`, '==', false);
		};
		return queryFn;
	}

	public byOutdated(language:string):QueryFn {
		let queryFn:QueryFn = ref => {
			return ref.where(`content.${language}.isPossiblyOutdated`, '==', true);
		};
		return queryFn;
	}

	public byLimit():QueryFn {
		let queryFn:QueryFn = ref => {
			return ref.limit(10);
		};
		return queryFn;
	}

}
