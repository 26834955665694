import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Localization} from "../../../../data/Localization";
import {UploadService} from "../../../../services/UploadService";
import {BadgeService} from "../../../../services/BadgeService";
import {Badge} from "../../../../domain/badges/Badge";
import {BaseComponent} from "../../../BaseComponent";
import {SupportedLanguage, SupportedLanguages} from "../../../../domain/SupportedLanguages";
import {ReplaySubject, Subscription} from "rxjs";
import {BadgeTextContent} from "../../../../domain/badges/BadgeTextContent";
import {ToastType} from "../../../../services/common/NotificationService";
import {CropperComponent, ImageType} from "../../../common/cropper/cropper.component";
import {chain} from "lodash";
import {validateSync, ValidationError} from "class-validator";
import {ValidationException} from "../../../../services/exceptions/ValidationException";
import {IFieldMaxLengthMap, ValidationMetadataService} from "../../../../services/common/ValidationMetadataService";

@Component({
	selector: 'app-badge-edit',
	templateUrl: './badge-edit.component.html',
	styleUrls: ['./badge-edit.component.scss']
})
export class BadgeEditComponent extends BaseComponent implements OnInit  {

	private _currentTextLanguage:SupportedLanguage;
	private currentTextLanguage$:ReplaySubject<SupportedLanguage> = new ReplaySubject<SupportedLanguage>();
	private currentTextLanguagePretty$:ReplaySubject<string> = new ReplaySubject<string>();
	public maxLengths:IFieldMaxLengthMap<BadgeTextContent> = null;
	private tempImage:string = null;

	public badge:Badge;
	public loaders = {
		isLoading: false,
		isSaving: false,
	};

	@ViewChild("cropperComponent")
	public cropperComponent:CropperComponent;

	protected mode:BadgeEditMode;

	private cbContext = Localization.template.badges.edit;
	public CB = {
		required: Localization.template.common.required,
		header: this.cbContext.header,
		image_header: this.cbContext.form.headers.badge_art,
		form_id_label: this.cbContext.form.headers.guid,
		save_and_continue: Localization.template.buttons.save_and_continue,
		english_header: this.cbContext.form.headers.english,
		hawaiian_header: this.cbContext.form.headers.hawaiian,
		add_english: this.cbContext.custom_buttons.add_english,
		add_hawaiian: this.cbContext.custom_buttons.add_hawaiian,
		name_label: this.cbContext.form.content.name.label,
		name_placeholder: this.cbContext.form.content.name.placeholder,
		description_label: this.cbContext.form.content.description.label,
		description_placeholder: this.cbContext.form.content.description.placeholder,
		criteria_label: this.cbContext.form.content.criteria.label,
		criteria_placeholder: this.cbContext.form.content.criteria.placeholder,
		save_content: this.cbContext.custom_buttons.save_content,
		remove_content: this.cbContext.custom_buttons.remove_content,
		saving: Localization.template.buttons.saving,
		loading: Localization.template.buttons.loading,
		save_badge: this.cbContext.custom_buttons.save_badge,
		view_badge: this.cbContext.custom_buttons.view_badge,
		return_to_list: Localization.template.buttons.return_to_list,
	};

	public editorModules = {
		toolbar: [
			[{'size': ['small', false, 'large', 'huge']}],  // size
			['bold', 'italic', 'underline'],        // toggled buttons
			[{'list': 'ordered'}, {'list': 'bullet'}],
			[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
		],
		clipboard: {
			matchVisual: true
		}
	};

	constructor (protected injector:Injector,
	             private uploadService:UploadService,
	             private validationMetadataService:ValidationMetadataService,
	             private badgeService:BadgeService) {
		super(injector);

		this.maxLengths = this.validationMetadataService.getMaxLengths(BadgeTextContent);
		this.localizationService.registerAndLocalize("BadgeEditComponent", this.CB);
		const badgeGuid:string = this.route.snapshot.params['guid'] != "" ? this.route.snapshot.params['guid'] : null;

		if (badgeGuid) {
			console.info(`this is an existing badge: ${badgeGuid}`);
			this.mode = BadgeEditMode.edit;
			this.trackSubscription(this.badgeService.getBadgeById$(badgeGuid).subscribe(result => {
				this.badge = result;
			}));

		} else {
			this.mode = BadgeEditMode.new;
			this.badge = new Badge();
			this.currentTextLanguage = this.localizationService.language;
			this.badge.documentTextContent.generate(this.currentTextLanguage); //generate default language
			this.badge.guid = null;
		}
	}

	ngOnInit() {
		this.logger.info(`BadgeEditComponent::ngOnInit`);
	}

	public get BadgeEditMode() {
		return BadgeEditMode;
	}

	public get ImageType() {
		return ImageType;
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}
	public get SupportedLanguage() {
		return SupportedLanguage;
	}

	public get currentTextLanguage():SupportedLanguage {
		return this._currentTextLanguage;
	}
	public set currentTextLanguage(target:SupportedLanguage) {
		this._currentTextLanguage = target;
		if (!this.badge.documentTextContent[target]) {
			this.badge.documentTextContent[target] = new BadgeTextContent();
		}
		this.currentTextLanguage$.next(this._currentTextLanguage);
		this.currentTextLanguagePretty$.next(SupportedLanguages.toPretty(this._currentTextLanguage));
	}

	public getPlainDescriptionText(lang:SupportedLanguage) {
		return this.validationMetadataService.getPlainText(this.badge.documentTextContent[lang].description);
	}
	public getPlainCriteriaText(lang:SupportedLanguage) {
		return this.validationMetadataService.getPlainText(this.badge.documentTextContent[lang].criteria);
	}

	public saveBadge() {
		// clear validation errors and warnings
		this.validationErrorService.updateErrors([]);
		this.validationErrorService.updateWarnings([]);

		//mode-specific handling
		if (this.mode == BadgeEditMode.new) {
			console.error(`The mode is new and cannot be saved. This should never be possible. Ignoring.`);
			return
		} else if (this.mode == BadgeEditMode.create) {
			const errors:ValidationError[] = validateSync(this.badge);
			if(errors.length > 0 ) {
				console.error("We had some errors");
				this.notificationService.displayToast(this.cbContext.toast.save.fail, ToastType.error);
				return;
			}
			let isOkayToCreate:boolean = this.tempImage != null && this.badge.guid != null && errors.length == 0;
			if (isOkayToCreate) {
				this.loaders.isLoading = true;
				this.logger.info(`saving the new badge...`);

				try {
					this.trackSubscription(this.badgeService.save$(this.badge).subscribe(badge => {
						if (badge) {
							this.mode = BadgeEditMode.edit;
							this.loaders.isLoading = false;
							this.updateImage(this.tempImage);
							this.notificationService.displayToast(this.cbContext.toast.save.first_time, ToastType.success);
						}
					}));
				} catch(err) {
					this.loaders.isLoading = false;
					this.logger.error("failed to save" + err);
					this.notificationService.displayToast(this.cbContext.toast.save.fail, ToastType.error);
				}
			}
		} else if (this.mode == BadgeEditMode.edit) {
			try {
				this.loaders.isSaving = true;
				this.validationErrorService.validateTextContentObject(this.badge);
				this.trackSubscription(this.badgeService.save$(this.badge).subscribe(badge => {
					if (badge) {
						this.mode = BadgeEditMode.edit;
						this.loaders.isSaving = false;
						this.notificationService.displayToast(this.cbContext.toast.save.success, ToastType.success);
					}
				}));
			} catch (err) {
				this.logger.error("failed to save: " + err);
				this.loaders.isSaving = false;
				if (err instanceof ValidationException) {
					this.validationErrorService.updateErrors(err.errors);
					this.notificationService.displayToast(this.cbContext.toast.save.fail, ToastType.error);
				}
			}
		}
	}

	public saveGuid(tempGuid:string):void {
		this.logger.info(`User clicked on saveGuid (create org)`);
		this.loaders.isSaving = true;
		if (tempGuid.trim().length > 0) {
			this.logger.info(`temp guid found; sanitizing and checking for uniqueness`);

			//sanitizing temp guid
			let cleanGuid:string = chain(tempGuid).deburr().trim().replace(" ", "-").toLower().value();
			let sanitizedGuid:string = cleanGuid.replace(/[^a-z0-9\-]/g, '');
			this.logger.info(`newly sanitized guid: ${sanitizedGuid}`);

			//checking to see if sanitizedGuid already exists
			this.trackSubscription(this.badgeService.getBadgeById$(sanitizedGuid).subscribe(result => {
				if (result != null) {
					this.logger.error(`Sanitized guid ${sanitizedGuid} already exists. Aborting save and returning toast.`);
					this.notificationService.displayToast(this.cbContext.toast.save.duplicate_guid, ToastType.error);
					this.loaders.isSaving = false;
				} else {
					this.logger.info(`No badge was found for the sanitized guid ${sanitizedGuid} (good)! Continuing to create mode!`);
					this.loaders.isSaving = false;
					this.badge.guid = sanitizedGuid;
					this.mode = BadgeEditMode.create;
					this.notificationService.displayToast(this.cbContext.toast.save.success, ToastType.success);
				}
			}));
		} else {
			this.loaders.isSaving = false;
			this.logger.error("No tempGuid passed in.  Ignoring request to create a badge.");
			this.notificationService.displayToast(Localization.template.common.toasts.checkInput, ToastType.error);
		}
	}

	public updateImage(base64PngImage:string):void {
		console.info(`cropper component is emitting a new image to be saved`);

		if (this.mode == BadgeEditMode.edit) {
			this.loaders.isSaving = true;
			//Upload the image
			let s:Subscription = this.trackSubscription(this.uploadService.uploadCropperImage(ImageType.badge, base64PngImage, this.badge).filter(downloadUrl => !!downloadUrl).subscribe(downloadUrl => {
				s.unsubscribe();
				this.cropperComponent.url = downloadUrl;
				this.loaders.isSaving = false;
			}));
		} else {
			//save the image as temporary and upload it when everything is ready to go
			this.tempImage = base64PngImage;
		}
	}

	public notifyOnFail():void {
		this.notificationService.displayToast(Localization.template.common.cropper.upload.fail, ToastType.error);
	}

	public onAddLanguageContent(target:SupportedLanguage):void {
		if (!this.badge.documentTextContent[target]) {
			this.currentTextLanguage = target;
		}
	}
	public onDeleteLanguageContent(target:SupportedLanguage):void {
		if (this.badge.documentTextContent[target]) {
			delete this.badge.documentTextContent[target];
			this.saveBadge();
		}
	}
	public onSaveContent(language:SupportedLanguage) {
		if (this.mode ==  BadgeEditMode.edit) {
			this.logger.info(`saving ${language.valueOf()} document text content!`);
			this.saveBadge();
		} else {
			this.logger.info(`saving ${language.valueOf()} document text content until the badge is ready to save.`)
		}
	}

}

export enum BadgeEditMode {
	/** When the user is creating a badge but a guid has not yet been set */
	new="new",
	/** When the user is creating a badge, has a decent guid, but the badge does not yet exist in the database */
	create="create",
	/** When the badge exists in the database and we're making updates to the information */
	edit="edit"
}
