import {ResourceVersion} from "./ResourceVersion";
import {Exclude, Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {ActivityEntry} from "./ActivityEntry";

@RegisterClazz(ActivityStream)
export class ActivityStream {
	// same guid as resource
	@Exclude({toPlainOnly: true})
	public guid:string = null;

	@Type(() => ActivityEntry)
	@RegisterType({clazz:ActivityEntry, isArray: true})
	public entries:Array<ActivityEntry> = [];

	public constructor() {

	}

	public addEntry(type:ActivityType, detail:string, version:ResourceVersion):ActivityEntry {
		let activityEntry:ActivityEntry = new ActivityEntry(type, detail, new Date(), version);
		this.entries.push(activityEntry);
		return activityEntry;
	}
	public addEntryStart(type:ActivityType, detail:string, version:ResourceVersion):ActivityEntry {
		let activityEntry:ActivityEntry = new ActivityEntry(type, detail, new Date(), version);
		this.entries.unshift(activityEntry);
		return activityEntry;
	}
}

export enum ActivityType {
	NONE = 'NONE', /* Not yet initialized */
	CREATED = 'CREATED',
	SAVED = 'SAVED',
	SUBMITTED = 'SUBMITTED',
	RENAMED = "RENAMED",
	REVIEWING = 'REVIEWING',
	METADATA = 'METADATA',
	APPROVED = 'APPROVED',
	RECALLED_TO_DRAFT = 'RECALLED_TO_DRAFT',
	DECLINED = 'DECLINED',
	PUBLISHED = 'PUBLISHED',
	OFFENSIVE_CONTENT = 'OFFENSIVE_CONTENT',
	MISUSED_PLAGIARIZED_CONTENT = 'MISUSED_PLAGIARIZED_CONTENT',
	INAPPROPRIATE_CONTENT = 'INAPPROPRIATE_CONTENT',
	ISSUE_REPORTED = 'ISSUE_REPORTED',
	PASSED_BAN_REVIEW = 'PASSED_BAN_REVIEW',
	BANNED = "BANNED",
	UNBANNED = "UNBANNED",
	USER_COMMENT = 'USER_COMMENT',
	REVIEWER_COMMENT = 'REVIEWER_COMMENT',
	FAN_CONTRIBUTION = "FAN_CONTRIBUTION"
}

export enum ActivityTypeIcon {
	CREATED = '<i class="fas fa-plus-circle"></i>',
	RENAMED = "<i class=\"fas fa-edit\"></i>",
	SAVED = '<i class="fas fa-save"></i>',
	SUBMITTED = '<i class="fas fa-sign-in-alt"></i>',
	REVIEWING = '<i class="far fa-eye"></i>',
	METADATA = '<i class="fas fa-bookmark"></i>',
	APPROVED = '<i class="far fa-smile"></i>',
	DECLINED = '<i class="far fa-frown"></i>',
	PUBLISHED = '<i class="fas fa-broadcast-tower"></i>',
	USER_COMMENT = '<i class="far fa-user"></i>',
	REVIEWER_COMMENT = '<i class="far fa-user"></i>',
	FAN_CONTRIBUTION = "<i class=\"fas fa-cloud-upload-alt\"></i>"
}

export enum ActivityTypeColors {
	CREATED = 'callout-primary',
	SAVED = 'callout-warning',
	SUBMITTED = 'callout-warning',
	REVIEWING = 'callout-warning',
	METADATA = 'callout-warning',
	APPROVED = 'callout-success',
	DECLINED = 'callout-danger',
	PUBLISHED = 'callout-primary',
	USER_COMMENT = 'callout-secondary',
	REVIEWER_COMMENT = 'callout-secondary',
	FAN_CONTRIBUTION = "callout-info",
	RENAMED= "callout-info"
}

export enum ActivityTemplate {
	CREATED = 'Created Lesson',
	SAVED = 'Saved Lesson',
	SUBMITTED = 'Lesson Submitted at ${timestamp}',
	REVIEWING = 'Lesson in Review at ${timestamp}',
	METADATA = 'Metadata Updated',
	PUBLISHED = 'Lesson Published Live at ${timestamp}',
	APPROVED = 'Lesson Approved',
	DECLINED = 'Lesson Declined',

	USER_COMMENT = '${comment}',
	REVIEWER_COMMENT = '${comment}'
}
