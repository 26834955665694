import {Component, ElementRef, OnDestroy, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {ResourceSubmissionDraftGuard} from "../../../guards/ResourceSubmissionDraftGuard";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../data/Localization";

@Component({
	selector: 'not-authorized-access-resource-modal',
	templateUrl: './not-authorized-access-resource-modal.component.html'
})
export class NotAuthorizedAccessResourceModalComponent implements OnInit, OnDestroy {

	private cbContext = Localization.template.modal.resource.not_authorized_edit;
	public CB = {
		title: this.cbContext.title,
		body: this.cbContext.body,
		ok: this.cbContext.ok,
	};

	public component:ResourceSubmissionDraftGuard;

	constructor(public bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				protected logger:NGXLogger) {

		this.localizationService.registerAndLocalize("NotAuthorizedAccessResourceModalComponent", this.CB);
		this.logger.info("BSModalRef ran");
	}

	public ngOnInit():void {
		this.logger.info('component init');
	}

	public ngOnDestroy() {
		this.logger.info('component destroy')
	}

}
