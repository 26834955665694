import {RegisterClazz} from "../../RegisterType";

@RegisterClazz(MokuRef)
export class MokuRef {
	public guid:string;

	public mokupuni:string = "";
	public moku:string = "";
	public color:string = null;
	public sortOrder:number = 0;

	public get label() {
		return this.moku ? `${this.mokupuni}: ${this.moku}` : this.mokupuni;
	}

}

export enum IslandColor {
	niihau = "island-white",
	kauai = "island-purple",
	oahu = "island-yellow",
	molokai = "island-green",
	lanai = "island-orange",
	kahoolawe = "island-gray",
	maui = "island-pink",
	hawaiiIsland = "island-red"
}
