import {registerDecorator, ValidationArguments, ValidationOptions} from "class-validator";

export function EqualsOtherProperty(otherProperty:string,  validationOptions?: ValidationOptions) {
	return function (object: Object, propertyName: string) {
		registerDecorator({
			name: "EqualsOtherProperty",
			target: object.constructor,
			propertyName: propertyName,
			constraints: [otherProperty],
			options: validationOptions,
			validator: {
				validate(value: any, args: ValidationArguments) {
					const [relatedPropertyName] = args.constraints;
					const otherPropertyValue:any = (args.object as any)[relatedPropertyName];
					return value == otherPropertyValue;
				}
			}
		});
	};
}
