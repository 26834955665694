import {Converter} from "../ConversionService";
import {classToClass} from "class-transformer";
import {Aina} from "../../domain/resources/aina/Aina";
import {AinaRef} from "../../domain/resources/aina/AinaRef";


export class AinaToAinaRefConverter implements Converter {

	public from:typeof Aina;
	public to:typeof AinaRef;

	constructor() {
		this.from = Aina;
		this.to = AinaRef;
	}

	public convert(source:Aina):AinaRef {
		//Create the target object
		let ainaRef:AinaRef = new AinaRef();
		ainaRef.guid = source.guid;
		ainaRef.label = source.label;
		ainaRef.children = classToClass(source.children);

		return ainaRef;
	}
}
