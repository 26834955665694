import {IsNotEmpty, MaxLength, MinLength} from 'class-validator';
import {RegisterClazz} from "../RegisterType";
import {Exclude} from "class-transformer";
import {SupportedLanguage} from "../SupportedLanguages";
import {ITextContent} from "../ITextContent";
import {MaxPlainTextLength} from "../../validators/MaxPlainTextLength";

@RegisterClazz(OrganizationTextContent)
export class OrganizationTextContent implements ITextContent {


	@Exclude()
	public $render:{[key:string]:SupportedLanguage};

	/** previously called 'info' */
	@MinLength(10)
	@MaxPlainTextLength(4000)
	@IsNotEmpty()
	public description:string = "";

	constructor() {
		this.$render = {};
	}


}
