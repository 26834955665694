import {RegisterClazz} from "../RegisterType";

@RegisterClazz(OrganizationConfiguration)
export class OrganizationConfiguration {

	/** This property affects whether an Approve All or Decline All setting is available on the Review page */
	public allowReviewersToApproveOrDeclineEntireReview:boolean = true;
	public autoAddMembersAsContributors:boolean = false;   //TODO: Implement in server
	public autoPublishResources:boolean = false;
	public allowUnlockedResources:boolean = false;

	/** This property controls whether or not a user from this organization is allowed to create a resource as a ghostwriter ("anonymously") */
	public allowGhostwriters:boolean = false;

	//TODO: ADD ALL THIS OTHER STUFF TO THIS CLASS

	//PUBLISHING OPTIONS
	//publish to org only
	//publish to waihona

	//COPYRIGHT OPTIONS
	//content creator retains copyright
	//organization retains copyright

	//Auto-add members as contributors
	//YES
	//NO

/*
	//https://docs.google.com/document/d/1zmNVXwgFKqNpqj1yYdHRcE9xoRq5GehHMQKJZwvLMoo/edit

	public allowSchoolToConfigureOwnership:boolean = false;
	public allowValidationBySchoolEmailDomain:boolean = false;
	public allowValidationByEmailWhitelist:boolean = false;


	public lessonSubmissionOption:LessonSubmissionOption = LessonSubmissionOption.submit_to_public;
	public reviewOptions:Array<ReviewOption> = [ReviewOption.kahu_review];




	Curriculum options
	Publishing options
	Allow Validation by school email domain (@ksbe.edu); * note does not separate teachers by faculty.
	Resource Submission Type
	All Public all the time
	Restricted/School Level (default) but individual resources can be made public by designated individuals (continue conversations)
	Kahu Options
	Peer Review
	Definition in configuration of how many people need to review the work (anyone in the School Adminl or 2+ reviewers on the same resource)
	Someone at that school needs to review the work
	(Dropdown would include contributors at a school)
	*Q: Should the peer review user able to verify the user as a member of the school.  Probably yes.
	Departmental Model
	School Admin defines the departments
	For each defined department School Admin designate a kahu for that department.
	School can designate Kahu outside of the organization
	One Defined Kahu
	Possibly same data structure/model as departmental model except that General/All is defined and Kahu is designated to that (UI may be different).]
	School can designate Kahu outside of the organization
	Legal Stuff
	Contributor:
		School can choose to place rules/terms/conduct that define what the contributor is agreeing to (in addition to whatever Waihona general requirements are).
	Downloader:
		Legal Information to display to the downloader before they are allowed to download. (Terms of use agreement)

	Ownership.  Who owns the content.
	School describes ownership.  That should be displayed to the Kahu before they are reviewing.



•	Curriculum options
•	Publishing options
•	Allow Validation by school email domain (@ksbe.edu); * note does not separate teachers by faculty.
•	Resource Submission Type
•	All Public all the time
•	Restricted/School Level (default) but individual resources can be made public by designated individuals (continue conversations)
•	Kahu Options
•	Peer Review
•	Definition in configuration of how many people need to review the work (anyone in the School Adminl or 2+ reviewers on the same resource)
•	Someone at that school needs to review the work
•	(Dropdown would include contributors at a school)
•	*Q: Should the peer review user able to verify the user as a member of the school.  Probably yes.
•	Departmental Model
•	School Admin defines the departments
•	For each defined department School Admin designate a kahu for that department.
•	School can designate Kahu outside of the organization
•	One Defined Kahu
•	Possibly same data structure/model as departmental model except that General/All is defined and Kahu is designated to that (UI may be different).]
•	School can designate Kahu outside of the organization
•	Legal Stuff
•	Contributor:  School can choose to place rules/terms/conduct that define what the contributor is agreeing to (in addition to whatever Waihona general requirements are).
•	Downloader:
•	Legal Information to display to the downloader before they are allowed to download. (Terms of use agreement)

•	Ownership.  Who owns the content.
•	School describes ownership.  That should be displayed to the Kahu before they are reviewing.
*/

}

export enum LessonSubmissionOption {
	"submit_to_public" = "submit_to_public",
	"submit_to_next_stage" = "sumbit_to_next_stage"
}

export enum ReviewOption {
	"peer_review" = "peer_review",
	"departmental" = "departmental",
	"kahu_review" = "kahu_review"
}
