<form autocomplete="off">
	<label class="sr-only" for="searchFilter">Search</label>
	<div class="input-group" id="search-bar-container">
		<div class="input-group-append">
			<div class="input-group-text">
				<i class="fas fa-search"></i>
			</div>
		</div>
		<span class="input-wrapper"><input type="text" class="form-control" name="searchFilter" id="searchFilter" style="border-radius:0;" placeholder="{{CB.placeholder | localize | async}}" (keyup)="updateSearch($event.target.value)" [ngModel]="[searchTerm]"><span *ngIf="searchTerm" class="ng-value-icon right" (click)="clear()" aria-hidden="true">&times;</span></span>
	</div>
</form>
