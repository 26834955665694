import {Injectable} from "@angular/core";
import {AbstractCookieRepository} from "./AbstractCookieRepository";
import {CookieService} from "ngx-cookie-service";
import {LocalUserSettings} from "../../domain/user/LocalUserSettings";

@Injectable()
export class LocalUserSettingsRepository extends AbstractCookieRepository<LocalUserSettings> {

	public value:LocalUserSettings = new LocalUserSettings();

	constructor(cookieService:CookieService) {
		super(LocalUserSettings, cookieService,null,"localUserSettings");
		this.value = super.get();
		if(this.value == null) {
			this.value = new LocalUserSettings();
			this.set(this.value);
		}
	}



}

