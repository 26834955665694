import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from "../services/AuthService";
import {Observable, Subscription} from "rxjs";
import {NotificationService} from "../services/common/NotificationService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthenticatedUsersOnlyModalComponent} from "../areas/modals/authenticated-users-only-modal/authenticated-users-only-modal.component";
import {SiteConfigurationService} from "../services/SiteConfigurationService";
import {NGXLogger} from "ngx-logger";
import {CookieService} from "ngx-cookie-service";
import {Action} from "@angular/fire/firestore";
import {UrlService} from "../services/UrlService";


@Injectable({
	providedIn: 'root',
})
/**
 * Specifies whether a Draft can be edited or not
 */
export class AuthenticatedUsersOnlyModalBlockGuard implements CanActivate {

	private bsModalRef:BsModalRef;
	private next:ActivatedRouteSnapshot;
	private state:RouterStateSnapshot;

	constructor(private authService:AuthService,
	            private notificationService:NotificationService,
	            private siteConfigurationService:SiteConfigurationService,
	            private router:Router,
				private urlService:UrlService,
	            protected logger:NGXLogger) {
	}

	public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		this.logger.info("AuthenticatedUsersOnlyModalBlockGuard::canActivate");
		this.next = next;
		this.state = state;
		let self = this;
		let router = this.router;
		return Observable.defer(async function () {
			console.info("AuthenticatedUsersOnlyModalBlockGuard::canActivate::async function ()");
			let shouldBlockAccess:boolean = self.siteConfigurationService.siteConfiguration.loginRequiredForSiteAccess;
			if(!shouldBlockAccess) {
				console.info("Not blocking access based on beta phase");
				return true;
			}
			await self.authService.checkIfLoggedIn();

			return self.finishUpEitherWay();
		});
	}

	public finishUpEitherWay():boolean {
		let isAUser:boolean = this.authService.currentUser != null;
		if (isAUser) {
			console.info(`Welcome in ${this.authService.currentUser.guid}!`);
		} else {
			this.urlService.saveAttemptedResourceRoute(this.next, this.state);
			console.error("It is invalid for anonymous user to access the site during the development phase.");
			this.bsModalRef = this.notificationService.displayModal(AuthenticatedUsersOnlyModalComponent, this);
		}
		return isAUser;
	}


	public destroyModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onClickLogin():void {
		console.info("Clicked Login");
		this.authService.login();
		this.destroyModal();
	}

	public onClickRegistration():void {
		console.info("Clicked Registration");
		this.router.navigate(["/user/register"]);
		this.destroyModal();
	}

	public onClickContact():void {
		console.info("Click Contact");
		window.open(this.siteConfigurationService.siteConfiguration.links.contact_form, "_blank");
		this.destroyModal();

	}

	public onClickCancel():void {
		console.info("Clicked Cancel");
/*
		this.router.navigate(["/"]);
*/
		this.destroyModal();
	}
}
