import {Component, Input, OnInit} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from "../../../../base/AbstractValueAccessor";
import {WaihonaUserRef} from "../../../../domain/user/WaihonaUserRef";
import {UrlService} from "../../../../services/UrlService";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'contributor-drop-down',
  templateUrl: './contributor-drop-down.component.html',
  styleUrls: ['./contributor-drop-down.component.scss'],
  providers: [MakeProvider(ContributorDropDownComponent)] //Allows ngModel
})
export class ContributorDropDownComponent extends AbstractValueAccessor implements OnInit {

	@Input()
	public placeholder:string;

    @Input()
    public userOptions:WaihonaUserRef[];

    /** Name of the field to validate **/
    @Input() field:string = "";
    @Input()
    public showMultiple:boolean;

    constructor(private urlService:UrlService, protected logger:NGXLogger) {
        super();
    }


    ngOnInit() {
        console.info(`${this.placeholder} showMultiple is ${this.showMultiple}`);
    }
}
