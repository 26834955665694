<div class="row">

	<!--mode: no image -->
	<div *ngIf="(cropperMode == CropperMode.no_image)" class="col-auto">
		<!--upload-->
		<div class="card border-0 bg-light rounded uploadCard">
			<h6 class="text-black-50 font-weight-bold">{{CB.upload | localize | async}}:</h6>
			<input type="file" (change)="onFileChangeEvent($event)"/>
		</div>
	</div>

	<!--mode: original image-->
	<div *ngIf="(cropperMode == CropperMode.original_image)" class="col-auto">
		<!--image-->
		<div class="imageCard card bg-light border-0">
			<h6 class="text-black-50 font-weight-bold">{{CB.current | localize | async}}:</h6>
			<div class="border border-dark bg-white rounded">
				<a href="{{cropper.imageUrl}}" target="_blank">
					<img src="{{cropper.imageUrl}}" class="card-img-top rounded" alt="current image" />
				</a>
			</div>
		</div>
		<!--upload-->
		<div class="card border-0 bg-light rounded uploadCard">
			<h6 class="text-black-50 font-weight-bold">{{CB.upload | localize | async}}:</h6>
			<input type="file" (change)="onFileChangeEvent($event)"/>
		</div>
	</div>

	<!--mode: new_image -->
	<div *ngIf="(cropperMode == CropperMode.new_image)" class="col-auto">
		<!--image-->
		<div class="imageCard card bg-light border-0">
			<h6 class="text-black-50 font-weight-bold">{{CB.current | localize | async}}:</h6>
			<div class="border border-dark rounded">
				<img src="{{cropper.croppedImage}}" class="card-img-top rounded" alt="new image" />
			</div>
		</div>
		<!--upload-->
		<div class="card border-0 bg-light rounded uploadCard">
			<h6 class="text-black-50 font-weight-bold">{{CB.upload | localize | async}}:</h6>
			<input type="file" (change)="onFileChangeEvent($event)"/>
		</div>
	</div>

	<!--mode: cropping image -->
	<div *ngIf="(cropperMode == CropperMode.cropping_image)" class="col-auto">
		<div class="card border-0 bg-light rounded uploadCard">
			<!--cropper container-->
			<div class="card-body bg-light">
				<h6 class="text-black-50 font-weight-bold">{{CB.crop | localize | async}}:</h6>
				<image-cropper *ngIf="cropperMode == 'cropping_image'"
							   [imageChangedEvent]="cropper.file"
							   maintainAspectRatio="true"
							   aspectRatio="1"
							   resizeToWidth="596"
							   onlyScaleDown="true"
							   format="png"
							   (imageCropped)="onImageCropped($event)"
							   (loadImageFailed)="onLoadImageFailed()">
				</image-cropper>
				<div class="mt-2">
					<button (click)="onSaveClick()" class="btn btn-primary">{{CB.save_image | localize | async}}</button>
					<button (click)="onCancel()" class="ml-2 btn btn-dark">{{CB.cancel | localize | async}}</button>
				</div>
			</div>
		</div>
	</div>

</div>
