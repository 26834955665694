<div class="modal-header bg-info">
  <h4 class="modal-title pull-left">{{CB.title | localize | async}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="this.onClickNo()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{CB.body | localize | async}}
</div>
<div class="modal-footer border-0">
  <button type="button" class="btn btn-warning" (click)="onClickYes()">{{CB.yes | localize | async}}</button>
  <button type="button" class="btn btn-primary" (click)="onClickNo()">{{CB.no | localize | async}}</button>
</div>
