import {AngularFirestore} from "@angular/fire/firestore";
import {ResourceChat} from "../../domain/resources/chat/ResourceChat";
import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root',
} as any)
export class ResourceChatRepository extends AbstractFirestoreRepository<ResourceChat> {


	constructor(protected db:AngularFirestore) {
		super(ResourceChat, db, db.collection("Chats").doc("resource").collection("Chats"), "resourceGuid");
	}

}
