import {ITypeInformation} from "./RegisterType";

export interface IClazzRegistrationOptions {
	derivedFrom?:(new()=>{})|any;
}

export class Registry {

	private static registry:Array<ITypeInformation> = [];
	private static clazzMap:{[key:string]:new()=>{}} = {};
	private static clazzOptions:{[key:string]:IClazzRegistrationOptions} = {};
	private static firestoreMap:{[key:string]:string} = {};

	public static set(metadata:ITypeInformation) {
		Registry.registry.push(metadata);
	}
	public static registerClazz(Clazz:new()=>{}, firestorePath?:string, options?:IClazzRegistrationOptions) {
		Registry.clazzMap[Clazz.name] = Clazz;
		Registry.clazzOptions[Clazz.name] = options == null ? {} : options;

		if(firestorePath != null) {
			Registry.firestoreMap[Clazz.name] = firestorePath;
		} else {
			Registry.firestoreMap[Clazz.name] = null;
		}


		this.getAllRegisteredTypesForClazz(Clazz).map(typeInfo => {
			typeInfo.forClazz = Clazz;
		});

	}
	public static getClazz(name:string):new()=>{} {
		return Registry.clazzMap[name];
	}
	/** Gets all the top level items (items that are stored as Documents in Firestore) if they directly contain a specific class) */
	public static getAllTopLevelClazzesWhichContainPropertyClazz(PropertyClazz:new()=>{}):Array<ITypeInformation> {
		let matches:Array<ITypeInformation> =
			Registry.registry.filter(info => {
				let propertyMatches:boolean = info.propertyClazz == PropertyClazz;
				const isTopLevel:boolean = Registry.firestoreMap[info.forClazzName] != null;
				return propertyMatches && isTopLevel;
			});
		return matches;

	}
	public static isThisADerivedClazz(Clazz:new()=>{}):void {
		this.clazzOptions[Clazz.name] != null && this.clazzOptions[Clazz.name].derivedFrom != null;
	}
	public static getClassThatThisIsDerivedFrom(Clazz:new()=>{}):new()=>{} {
		return this.clazzOptions[Clazz.name].derivedFrom;
	}

	public static getAllTopLevelClazzes():Array<ITypeInformation> {
		let matches:Array<ITypeInformation> =
			Registry.registry.filter(info => {
				const isTopLevel:boolean = Registry.firestoreMap[info.forClazzName] != null;
				return isTopLevel;
			});
		return matches;
	}
	public static getFirestorePathForClazz(clazzName:string):string {
		return Registry.firestoreMap[clazzName];
	}
	public static getAllRegisteredTypesForClazz(Clazz:new()=>{}):Array<ITypeInformation> {
		return Registry.registry.filter(typeInfo => {
			return typeInfo.forClazzName == Clazz.name;
		});
	}
	public static findAllInClassIfContainsPropertyOfClass(OwnerClass:new()=>{},PropertyClass:new()=>{}):Array<ITypeInformation> {
		let matches:Array<ITypeInformation> =
			Registry.registry.filter(info => {
				let ownerMatches:boolean = info.forClazzName == OwnerClass.name;
				let propertyMatches:boolean = info.propertyClazz == PropertyClass;
				return ownerMatches && propertyMatches;
			});
		//this.logger.info(`Found ${matches.length} instances of ${OwnerClass.name}`)
		return matches;
	}

	public static doesClassDirectlyOrIndirectlyIncludeAnotherClass(ClassLookingThrough:(new()=>{})|any, ClassSearchingFor:(new()=>{})|any):void {
		let topLevelClazzes:Array<ITypeInformation> = Registry.getAllTopLevelClazzes();
		let topLevelClazzesWhichContainTheOtherClazzInSomeWay:Array<(new()=>{})|any> = [];

		for(let i:number = 0; i < topLevelClazzes.length; i++) {
			let currentClazzToSearch:(new()=>{})|any = topLevelClazzes[i];
			let properlySearched:Array<(new()=>{})|any> = [];
			let classezLeftToSearch:Array<(new()=>{})|any> = [];
		}

	}


	/** The basic idea of this is from the single class source look up and see what other classes include this.
	 * If it includes more than one track up those tracks are added into a new array.
	 * It stops when it reaches a top level item.
	 * @param SubjectClass
	 */
	public static buildHierarchyUpForClass(SubjectClass:(new()=>{})|any):Array<ITypeInformation[]> {


		let vettedTracks:Array<ITypeInformation[]> = [];
		let pendingTracks:Array<IPendingTrack> = [];
		let currentTrack:Array<ITypeInformation> = [Registry.registry[SubjectClass.name]]
		let tracks:Array<ITypeInformation[]> = [];
		let foundItems:Array<ITypeInformation> = Registry.findAllAnywhereIfContainsPropertyOfClass(SubjectClass);

		if(foundItems.length > 1) {
			let iPendingTrack:IPendingTrack = {
				path: [],
				pathsToCheck: foundItems
			};
			pendingTracks.push(iPendingTrack);
		}

		while(pendingTracks.length > 0) {

			let pendingTrack:IPendingTrack = pendingTracks.pop();

			for(let i:number = 0; i < pendingTrack.pathsToCheck.length; i++) {
				let currentPath:ITypeInformation = pendingTrack.pathsToCheck[i];
				const isTopLevel:boolean = Registry.firestoreMap[currentPath.forClazzName] != null;

				if(isTopLevel) {

					let vettedTrack:ITypeInformation[] = pendingTrack.path.slice(0);
					vettedTrack.push(currentPath);
					vettedTracks.push(vettedTrack);
				} else {
					//More pending tracks to do.
					let foundItems:Array<ITypeInformation> = Registry.findAllAnywhereIfContainsPropertyOfClass(currentPath.forClazz);

					let clone:ITypeInformation[] = pendingTrack.path.slice(0);

					let iPendingTrackToAdd:IPendingTrack = {
						path: [...pendingTrack.path, currentPath],
						pathsToCheck: foundItems
					};
					pendingTracks.push(iPendingTrackToAdd);
				}
			}
		}
		return vettedTracks;
	}

	public static buildPropertyListFor(SubjectClass:(new()=>{})|any):Array<RegisteredLocation> {
		let registeredLocations:Array<RegisteredLocation> = [];
		let arrayOfPropertyPaths:Array<ITypeInformation[]> = Registry.buildHierarchyUpForClass(SubjectClass);
		for (const arrayOfPropertyPath of arrayOfPropertyPaths) {

			let occuredAfterArray:boolean = false;
			let path:Array<ITypeInformation> = arrayOfPropertyPath.reverse();
			let firstElement:ITypeInformation = path[0];

			let nextToLastElement:ITypeInformation;
			if(path.length-2 > 1) {
				nextToLastElement = path[path.length -2 ];
			}

			let lastElement:ITypeInformation;//; = path[path.length-1];

			let fullPropertyPath:string = path.map((item) => {
				if(item == nextToLastElement && item.isArray) {
					return "@" + item.property; //arrays get the ʻ@ʻ prepended to them
				} else if(item == lastElement && occuredAfterArray) {
					return ""; //dont use it since we're searching for the thing in the array
				}

				return item.property;
			}).join(".");

			//Turn the whole thing into a object.object.property string where arrays get @ prepended (as they are indexed in firestore)
			let property:string = "";
			let numberOfArraysFound:number = 0;
			for(let i:number = 0; i < path.length; i++) {
				let iPathElement:ITypeInformation = path[i];
				let isLast:boolean = i+1 >= path.length;

				lastElement = iPathElement;

				if(iPathElement.isArray) {
					numberOfArraysFound++;
					property += iPathElement.property;
				} else {
					property += iPathElement.property;
				}
				if(!isLast) {
					property += ".";
				}

			}
			let rl:RegisteredLocation = {
				path: property,
				pathDetail: path,
				isArray: lastElement.isArray,
				TopDomain: firstElement.forClazz,
				PropertyDomain: lastElement.propertyClazz,
				isQueryable: numberOfArraysFound <= 1
			};
			console.info(`\n\n`);

			registeredLocations.push(rl);
		}

		return registeredLocations;
	}
	public static buildGroupedPropertyListFor(SubjectClass:(new()=>{})|any):Array<RegisteredLocation[]> {
		let registeredLocations:Array<RegisteredLocation> = Registry.buildPropertyListFor(SubjectClass);

		let groupedLocations:{} = Registry.groupBy(registeredLocations, "TopDomain");
		let fullyGroupedLocations:Array<RegisteredLocation[]> = [];
		Object.keys(groupedLocations).forEach(function(key) {
			let value:Array<RegisteredLocation> = groupedLocations[key];
			fullyGroupedLocations.push(value);
		});

		return fullyGroupedLocations;
	}

	public static findAllAnywhereIfContainsPropertyOfClass(PropertyClass:new()=>{}):Array<ITypeInformation> {
		let matches:Array<ITypeInformation> =
			Registry.registry.filter(info => {
				let propertyMatches:boolean = info.propertyClazz == PropertyClass;
				return propertyMatches;
			});
		return matches;
	}

	public static log(items: Array<ITypeInformation> ):void {
		for(let i:number = 0; i < items.length; i++) {
			let r:ITypeInformation = items[i];
			console.info(JSON.stringify(r, null, 2));
			console.info("---------------------");
		}
	}
	private static groupBy(xs:Array<any>, prop:string) {
		var grouped = {};
		for (var i=0; i<xs.length; i++) {
			var p = xs[i][prop];
			if (!grouped[p]) { grouped[p] = []; }
			grouped[p].push(xs[i]);
		}
		return grouped;
	}


}

export interface IClassNode {
	clazzInfo:ITypeInformation;
	children:Array<ITypeInformation>;
}

export interface IPendingTrack {
	path:Array<ITypeInformation>;
	pathsToCheck:Array<ITypeInformation>
}


export class RegisteredLocation {
	public TopDomain:new()=>{} | any;
	public PropertyDomain:new()=>{} | any;
	public path:string = "";
	public pathDetail:Array<ITypeInformation> = [];
	public isArray:boolean = false;
	public isQueryable:boolean = false;
}
