import {Injectable} from "@angular/core";
import {FriendlyFileTypes, LearningAsset} from "../domain/resources/LearningAsset";

@Injectable()
export class FileTypeService {

	private friendlyFileType:string = "";

	constructor () {}

	public getFriendlyFileType(learningAsset:LearningAsset):string {
		let ext = learningAsset.extension;
		let documentTypes = ['doc', 'docx', 'dot', 'dotx', 'epub', 'gdoc', 'pdf', 'rft', 'odt', 'ott', 'txt', 'pages', 'wpd', 'wps', 'wri'];
		let imageTypes = ['ai', 'bmp', 'gif', 'ico', 'jpg', 'jpeg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff'];
		let audioTypes = ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wpl'];
		let videoTypes = ['3g2', '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4','mpg', 'mpeg', 'rm', 'swf', 'vob', 'wmv'];
		let spreadsheetTypes = ['ods', 'xlr', 'xls', 'xlsx'];
		let presentationTypes = ['language', 'odp', 'pps', 'ppt', 'pptx'];
		let compressedTypes = ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'tar', 'z', 'zip'];
		switch (true) {
			case documentTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.document;
				break;
			case imageTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.image;
				break;
			case audioTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.audio;
				break;
			case videoTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.video;
				break;
			case spreadsheetTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.spreadsheet;
				break;
			case presentationTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.presentation;
				break;
			case compressedTypes.includes(ext):
				this.friendlyFileType = FriendlyFileTypes.compressed;
				break;
			default:
				this.friendlyFileType = FriendlyFileTypes.unknown;
		}
		return this.friendlyFileType;
	}
}
