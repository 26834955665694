import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/AuthService";
import {Location} from '@angular/common';
import {Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";

@Component({
	selector: 'app-callback',
	template: ``
})
export class CallbackComponent implements OnInit {

	constructor(private router:Router, private location:Location, private authService:AuthService, protected logger:NGXLogger) {

	}
	ngOnInit() {

		// You may want to make a more robust check here
		let isAuthenticationCallback = window.location.search.indexOf("code") !== -1;

		this.logger.info(`CallbackComponent isAuthenticationCallback: ${isAuthenticationCallback}`);
		if (isAuthenticationCallback) {
			this.authService.handleAuthCallback();
		}
	}

	protected parseHash(value:string):string {
		let shouldProcess: boolean = value && value.length >= 2 && value.slice(0, 2) == "#/";
		if (shouldProcess) {
			return value.slice(2);
		}
		return value;
	}
}
