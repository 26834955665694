
<div class="modal-header bg-secondary">
	<h4 class="modal-title text-white font-weight-bold">{{CB.modal_addUser_title | localize | async}}</h4>
	<button type="button" class="close" (click)="this.hide()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form autocomplete="off">
		<div class="form-group"> <!-- enter user email -->
			<p>{{CB.modal_addUser_enterEmail | localize | async}}</p>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="selectSemicolon" id="selectSemicolon" [(ngModel)]="delimiterType" [value]="DelimiterType.semicolon">
				<label class="form-check-label" for="selectSemicolon">Semicolon;</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="selectComma" id="selectComma" [(ngModel)]="delimiterType" [value]="DelimiterType.comma">
				<label class="form-check-label" for="selectComma">Comma,</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="selectNewLine" id="selectNewLine" [(ngModel)]="delimiterType" [value]="DelimiterType.newline">
				<label class="form-check-label" for="selectNewLine">New line (return)</label>
			</div>
			<textarea [(ngModel)]="emailArrayString" type="email" class="form-control mt-3" id="email" name="email" #emailEntry rows="3"
			          placeholder="{{CB.modal_addUser_emailPlaceholder[this.delimiterType] | localize | async}}">
			</textarea>
		</div>
	</form>
	<div class="card border border-light" style="border-radius: 5px;" *ngIf="results.length > 0">
		<div id="resultsDiv" class="card-body">
			<h4 class="text-primary">Results:</h4>
			<p style="white-space: pre-wrap">{{results.join("\r\n")}}</p>
			<button type="button" class="btn btn-secondary" (click)="results.length = 0">Clear</button>
		</div>
	</div>
</div>
<div class="modal-footer border-0">
	<div>
		<button [disabled]="isLoading" type="submit" class="btn btn-primary mr-2" (click)="addUserByEmail()">
		<span *ngIf="isLoading" class="spinner-border spinner-border-sm mx-4" role="status">
			<span class="sr-only">{{CB.modal_addUser_loading | localize | async}}</span>
		</span>{{CB.modal_addUser_sendInvite | localize | async}}
		</button>
		<button type="button" class="btn btn-light" [disabled]="isLoading" (click)="this.hide();">{{CB.modal_addUser_cancel | localize | async}}</button>
	</div>

</div>
