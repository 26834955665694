import {Injectable} from "@angular/core";
import {AngularFirestore, QueryFn} from "@angular/fire/firestore";
import {
	AbstractFirestoreRepository,
	OrderByCriterion, OrderByDirections, QueryCriteria, QueryCriteriaBuilder,
	QueryCriterion,
	WhereFilterOperations
} from "./AbstractFirestoreRepository";
import {Aina} from "../../domain/resources/aina/Aina";
import {AinaRef} from "../../domain/resources/aina/AinaRef";
import {ConversionService} from "../ConversionService";
import {RefCachingService} from "../RefCachingService";
import {InboxFilterType} from "../../domain/inbox/InboxFilterType";
import {MessageRef} from "../../domain/inbox/MessageRef";
import {Observable} from "rxjs";

@Injectable()
export class AinaRepository extends AbstractFirestoreRepository<Aina, AinaRef> {

	constructor (protected db:AngularFirestore, protected conversionService:ConversionService, protected refCachingService:RefCachingService) {
		super(Aina, db,
			{
				type: db.collection("Aina"),
				ref: db.collection("Refs/aina/All"),
			}, "guid", conversionService, AinaRef);
		this.refCachingService.registerSource(this);
	}

	public doList$():Observable<Aina[]> {
		return this.list$().map(naAina => {
			let map:Map<string, Aina> = new Map<string, Aina>();

			let sorted:Array<Aina> = [];
			naAina.map(aina => {
				map.set(aina.guid, aina);
				sorted.push(aina);
			});
			//Sort the arrays on the Aina...
			naAina.map(aina => {
				aina.children = aina.children.sort((a:AinaRef, b:AinaRef):number => {
					return map.get(a.guid).order - map.get(b.guid).order;
				});
				for(let child of aina.children) {
					let childAina:Aina = map.get(child.guid);
					aina.actualChildren.push(childAina);
					childAina.actualParent = aina;
				}
			});

			for(let i:number = 0; i < sorted.length; i++) {
				let sortedItem:Aina = sorted[i];
				let earliestIndex:number = i;
				for(let iChildItem:number = 0; iChildItem < sortedItem.actualChildren.length; iChildItem++) {
					let childItem:Aina = sortedItem.actualChildren[iChildItem];
					let indexOf:number = sorted.indexOf(childItem);
					if(indexOf < i || indexOf < earliestIndex) {
						earliestIndex = indexOf;
					}
					sorted.splice(indexOf, 1); //delete
				}
				let updatedIndexOfSortedItem:number = sorted.indexOf(sortedItem);
				if(sortedItem.actualChildren.length>0) { //insert at the new location
					sorted.splice(updatedIndexOfSortedItem+1,0,...sortedItem.actualChildren);
				}
				if(earliestIndex < i) {
					i = earliestIndex; //reset backwards
				}
			}
			return sorted;
		});
	}

}
