import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {IModal} from "../IModal";
import {LocalizationService} from "../../../services/LocalizationService";
import {IResourceFanMessage} from "../../../domain/resources/IResourceFanMessage";
import {ResourceSubmission} from "../../../domain/resources/ResourceSubmission";
import {PathService} from "../../../services/PathService";
import {FanContribution} from "../../../domain/resources/FanContribution";
import {ConversionService} from "../../../services/ConversionService";
import {ResourceSubmissionRef} from "../../../domain/resources/ResourceSubmissionRef";
import {FanContributionService} from "../../../services/FanContributionService";
import {ValidationException} from "../../../services/exceptions/ValidationException";
import {BehaviorSubject} from "rxjs";
import {ValidationErrorService} from "../../../services/common/ValidationErrorService";
import {NotificationService, ToastType} from "../../../services/common/NotificationService";
import {Localization} from "../../../data/Localization";

@Component({
	selector: 'fan-contribution-modal',
	templateUrl: './fan-contribution-modal.component.html',
	styleUrls: ['./fan-contribution-modal.component.scss'],
	providers: [ValidationErrorService]
})
export class FanContributionModalComponent implements OnInit, IModal {

	private cbContext =  Localization.template.modal.resource.contribute_fan_content;
	public CB = {
		title: this.cbContext.title,
		mahalo: this.cbContext.body.mahalo,
		proxy: this.cbContext.body.form.proxy,
		name: this.cbContext.body.form.name,
		name_placeholder: this.cbContext.body.form.name_placeholder,
		email: this.cbContext.body.form.email,
		email_placeholder: this.cbContext.body.form.email_placeholder,
		comment: this.cbContext.body.form.message,
		uploader: this.cbContext.body.uploader,
		cancel: Localization.template.buttons.cancel,
		submit: Localization.template.buttons.submit,
		toast_success: this.cbContext.toast.success,
		toast_fail: this.cbContext.toast.fail
	};

	public component:any;
	public content:IResourceFanMessage;
	public fanContribution:FanContribution;
	public resourceSubmission:ResourceSubmission;
	public errors:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);
	public warnings:BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);

	@ViewChild('fanContributionModal') fanContributionModal:BsModalRef;

	public static makeGuidExtension():string {
		let text:string = "";
		let possible:string = "abcdefghijklmnopqrstuvwxyz0123456789";

		for (let i:number = 0; i < 7; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return "-" + text;
	}

	constructor(private bsModalRef:BsModalRef,
				private localizationService:LocalizationService,
				private conversionService:ConversionService,
				private fanContributionService:FanContributionService,
				private validationErrorService:ValidationErrorService,
				private notificationService:NotificationService,
				protected pathService:PathService) {

		this.localizationService.registerAndLocalize("FanContributionModalComponent", this.CB);

		this.fanContribution = new FanContribution();
	}

	ngOnInit() {
	}

	public onProperties(properties:object):void {
		this.content = properties as IResourceFanMessage;
		this.resourceSubmission = this.content.resourceSubmission;
		this.fanContribution.fanRef = this.content.fanRef;

		//create guid
		this.fanContribution.guid = this.resourceSubmission.guid + FanContributionModalComponent.makeGuidExtension();
		console.info(`Fan contribution guid was created! The new guid is ${this.fanContribution.guid}`);
	}

	public get assetsFolder():string {
		return this.pathService.storage.fanFolder(this.fanContribution.guid);
	}

	public onCancel() {
		console.info(`user clicked on cancel button`);
		this.bsModalRef.hide();
		this.fanContributionService.cancelFanContribution(this.fanContribution);
	}

	public onSubmit() {
		console.info(`user clicked on submit button`);

		// clear validation errors and warnings
		this.validationErrorService.updateErrors([]);
		this.validationErrorService.updateWarnings([]);

		//set values
		this.fanContribution.resourceSubmissionRef = this.conversionService.convert(this.resourceSubmission, ResourceSubmissionRef);
		this.fanContribution.timestamp = this.fanContribution.getTimestamp();

		//check values in console
		console.info(`fanContribution.fanRef = ${this.fanContribution.fanRef}`);
		console.info(`fanContribution.isProxy = ${this.fanContribution.isProxy}`);
		console.info(`fanContribution.name = ${this.fanContribution.name}`);
		console.info(`fanContribution.email = ${this.fanContribution.email}`);
		console.info(`fanContribution.comment = ${this.fanContribution.comment}`);
		console.info(`fanContribution.files = ${this.fanContribution.files}`);
		console.info(`fanContribution.resourceSubmissionRef = ${this.fanContribution.resourceSubmissionRef}`);

		//try save contribution and return toasts in either case todo: add toasts!
		try {
			this.fanContributionService.validate(this.fanContribution);
			this.fanContributionService.saveFanContribution(this.fanContribution);
			this.notificationService.displayToast(this.CB.toast_success, ToastType.success);
			this.bsModalRef.hide();

		} catch (ex) {
			if (ex instanceof ValidationException) {
				console.info(`validation errors caught`);
				this.validationErrorService.updateErrors(ex.errors);
				this.notificationService.displayToast(this.CB.toast_fail, ToastType.error)

			} else {
				console.error(ex);
			}
		}

	}

	public onAssetsChanged() {
		console.log("Fan assets were changed");
	}


}
