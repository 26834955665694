import {IslandColor, Moku} from "./Moku";

export class MokupuniRef {

	public guid:string = null;

	public color:string = null;
	public sortOrder:number = 0;

	constructor(color?:IslandColor, order?:number) {
		if(color != null) {
			this.color = color;
		}
		if(!isNaN(order)) {
			this.sortOrder = order;
		}
	}
}