<div *ngIf="collection" class="card my-sm-1 my-lg-3">
    <ng-container *ngIf="collection.configuration.hasImage; else noImage">
        <a href="javascript:void(0);" (click)="navigateToDetail(collection.guid)"  class="text-decoration-none" [id]="collection.guid">
            <img [src]="this.visual.collectionImage()" class="card-img-top bg-light" alt="collection cover">
        </a>
    </ng-container>
    <ng-template #noImage>
        <i *ngIf="collection.items.length <= 1" class="noImage fad fa-album-collection fa-10x text-primary p-4" role="button" (click)="navigateToDetail(collection.guid)"></i>
        <carousel *ngIf="collection.items.length > 1" [showIndicators]="false" [isAnimated]="true">
            <ng-container *ngFor="let item of collection.items">
                <slide *ngIf="item.configuration.hasImage">
                    <a href="javascript:void(0);" (click)="navigateToDetail(collection.guid)"  class="text-decoration-none" [id]="collection.guid">
                        <img class="card-img-top resource-img" [src]="visual.resourceImageSrc(item.guid)" alt="resource cover">
                    </a>
                </slide>
            </ng-container>
        </carousel>
    </ng-template>
    <div class="card-body" [class.expand-on-hover]="!isExpanded">
        <a href="javascript:void(0);" (click)="navigateToDetail(collection.guid)"  class="text-decoration-none" [id]="collection.guid">
            <h5 [class.smaller]="localize.collection(collection).title.length > 17" class="card-title text-primary font-weight-bold">{{localize.collection(collection).title}}</h5>
            <p class="card-text text-black-50">{{localize.collection(collection).summary}}</p>
            <p class="badge badge-pill badge-secondary mt-2">{{collection.items.length}} {{CB.resources | localize: {count: collection.items.length} | async}}</p>
        </a>
    </div>
    <div class="card-footer">
        <small class="text-black-50">{{visual.publicityLabel() | localize | async}} {{visual.CollectionTypeLabel() | localize | async}}</small>
        <span *ngIf="collection.owner.isUser()" class="float-right">
            <avatar-component [userRef]="collection.owner.userRef" [class]="'select-image outline'" [tooltip]="collection.owner.name"></avatar-component>
        </span>
        <span *ngIf="collection.owner.isOrg()" class="float-right">
            <avatar-component [orgRef]="collection.owner.orgRef" [class]="'select-image outline'" [tooltip]="collection.owner.name"></avatar-component>
        </span>
    </div>
</div>