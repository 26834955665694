import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {Localization} from "../../../data/Localization";
import {BsModalRef} from "ngx-bootstrap/modal";
import {LocalizationService} from "../../../services/LocalizationService";
import {NGXLogger} from "ngx-logger";
import {InviteUserMessage} from "../../../services/messages/InviteUserMessage";
import {IModal} from "../IModal";
import {Validator, isEmail} from "class-validator";
import {OrganizationService} from "../../../services/OrganizationService";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";
import {IServerError, ServerError} from "../../../services/functions/AbstractFirebaseFunction";
import {NotificationService, ToastType} from "../../../services/common/NotificationService";
/*
import extractEmail from "extract-email-address";
*/
declare var require;
const addrs = require("email-addresses");

@Component({
	selector: 'app-invite-user-by-email-modal',
	templateUrl: './invite-user-by-email-modal.component.html',
	styleUrls: ['./invite-user-by-email-modal.component.scss']
})
export class InviteUserByEmailModalComponent extends SubscriptionCleaner implements OnInit, IModal{

	public component:{ userSaysItsOkayToLeaveSubject$:Subject<boolean> };

	public isLoading:boolean = false;
	public results:Array<string> = [];
	public emailArrayString:string;
	public delimiterType:DelimiterType = DelimiterType.semicolon;

	@ViewChild("emailEntry")
	public emailEntry:ElementRef;

	protected _properties:{orgGuid:string};

	private cbContext = Localization.template.organization.edit.modal.addUser;

	public CB = {
		modal_addUser_title: this.cbContext.title,
		modal_addUser_enterEmail: this.cbContext.form.label_enterEmail,
		modal_addUser_emailPlaceholder: this.cbContext.form.placeholder_enterEmail,
		modal_addUser_sendInvite: this.cbContext.buttons.sendInvite,
		modal_addUser_cancel: Localization.template.buttons.cancel,
		modal_addUser_loading: Localization.template.buttons.loading,
	};


	constructor(public bsModalRef:BsModalRef,
	            private localizationService:LocalizationService,
	            private organizationService:OrganizationService,
	            private notificationService:NotificationService,
				private ref:ChangeDetectorRef,
	            protected logger:NGXLogger) {
		super();
		this.localizationService.registerAndLocalize("InviteUserByEmailModalComponent", this.CB);
	}

	ngOnInit() {
		this.organizationService.warmUpInviteUserToOrg();
	}
	public get DelimiterType() {
		return DelimiterType;
	}

	public isEmailValid(emailAddress:string):boolean {
		console.log(`validating ${emailAddress}`);
		const validator:Validator = new Validator();
		let isAnEmail:boolean = isEmail(emailAddress, {allow_display_name: true});
		if (!isAnEmail) {
			let validationError:string = `Error: ${emailAddress} is an invalid email address.`;
			this.results.push(validationError);
		}
		console.log(`emailAddress is valid: ${isAnEmail}`);
		return isAnEmail;
	}

	public hide():void {
		this.isLoading = false;
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onProperties(properties:{orgGuid:string}):void {
		this._properties = properties;
		if(this.emailEntry) {
			let emailEntryInput:HTMLInputElement = this.emailEntry.nativeElement;
			emailEntryInput.focus({preventScroll:true});
		}
	}

	public addUserByEmail():void {
		this.results = [];
		this.isLoading = true;
		this.ref.detectChanges();

		if(!this.emailArrayString) {
			this.isLoading = false;
			this.logger.warn(`The parsing function found no email addresses in the string`);
			this.notificationService.displayToast(this.cbContext.toast.fail.invalid_input, ToastType.warning);
			return;
		}
		this.logger.info(`user-inputted email string attempting to parse: ${this.emailArrayString}`);

		let parsedEmails:Array<string> = [];
		let invalidEmails:Array<string> = [];

		let replacementString:string = this.emailArrayString;

		if(this.delimiterType == DelimiterType.semicolon) {
			let emails:Array<string> = replacementString.split(";").map(item => item.trim()).filter(foo => !!foo);
			parsedEmails = emails.map(emailString => {
				let parse = addrs.parseOneAddress(emailString);
				if(parse == null) {
					invalidEmails.push(emailString);
					return null;
				}
				let emailAddressOnly:string = parse.address;
				let isAnEmail:boolean = isEmail(emailAddressOnly, {allow_display_name: false});
				if(!isAnEmail) {
					invalidEmails.push(emailAddressOnly);
				}
				return isAnEmail ? emailAddressOnly : null;
			}).filter(value => value != null);

			invalidEmails.map(email => {
				this.results.push(`${email} is not a valid email address.`)
			});
		} else if(this.delimiterType == DelimiterType.newline) {
			replacementString = replacementString.replace(/[\n\r]/g, ',');
		}

		//This one handles either newline or comma since newline was replaced with comma above.
		if([DelimiterType.newline, DelimiterType.comma].includes(this.delimiterType)) {
			let parse:Array<{ address:string}> = addrs.parseAddressList(replacementString.trim());
			if(parse == null) {
				this.results.push(`${this.emailArrayString} is not parsable with ${this.delimiterType}.`)
			} else {
				parsedEmails=parse.map(parsed=>parsed.address);
			}
		}

		if(parsedEmails.length == 0) {
			this.isLoading = false;
			this.logger.warn(`The parsing function found no email addresses in the string`);
			this.notificationService.displayToast(this.cbContext.toast.fail.invalid_input, ToastType.warning);
			return;
		}

		try {
			let s:Subscription = this.organizationService.inviteUserToOrgByEmail$(this._properties.orgGuid, parsedEmails).subscribe((message) => {
				s.unsubscribe();

				if (message instanceof ServerError) {
					let error:string = (message as IServerError<any>).message;
					this.notificationService.displayToast(this.cbContext.toast.fail.other_error, ToastType.error);
					this.results.push(error);
					this.logger.error(error);
					this.ref.detectChanges();
				} else {
					let inviteUserMessage:InviteUserMessage = (message as InviteUserMessage);
					if (inviteUserMessage.success) {
						let success:string = `Successfully invited \r\n${inviteUserMessage.userEmails.join("\r\n")}.`;
						this.results.push(success);
					}
					this.logger.info(`Finished inviting \r\n${inviteUserMessage.userEmails.join("\r\n")} to ${inviteUserMessage.orgGuid}. Success? ${inviteUserMessage.success}`);
				}
				this.isLoading = false;
			});
			this.trackSubscription(s);
		} catch (ex) {
			this.logger.error(ex);
			this.notificationService.displayToast(this.cbContext.toast.fail.other_error, ToastType.error);
			this.isLoading = false;
		}

		this.notificationService.displayToast(this.cbContext.toast.success, ToastType.success);
	}

}


export enum DelimiterType {
	comma="comma",
	semicolon="semicolon",
	newline="newline"
}
