import {registerDecorator, ValidationArguments, ValidationOptions} from "class-validator";

export function countPlainTextChars(str:string):number {
	if (!str) {
		return 0;
	} else {
		let domParser = new DOMParser();
		let doc:Document = domParser.parseFromString(str, 'text/html');
		return doc.documentElement.innerText.length;
	}
}

export function MaxPlainTextLength(maxLength:number, validationOptions?: ValidationOptions) {
	return function (object: Object, propertyName: string) {
		registerDecorator({
			name: "MaxPlainTextLength",
			target: object.constructor,
			propertyName: propertyName,
			constraints: [maxLength],
			options: validationOptions,
			validator: {
				validate(value: any, args: ValidationArguments) {
					const propertyValue:string = (args.object as any)[propertyName];

					return countPlainTextChars(propertyValue) <= maxLength; // you can return a Promise<boolean> here as well, if you want to make async validation
				},
				defaultMessage(validationArguments?: ValidationArguments): string {
					return `${propertyName} cannot be more than ${maxLength} characters`;
				}
			}
		});
	};
}

