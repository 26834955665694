import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ListFrameworksComponent} from "./multiple/list-frameworks/list-frameworks.component";
import {FrameworkEditComponent} from "./single/framework-edit/framework-edit.component";
import {FrameworkDetailComponent} from "./single/framework-detail/framework-detail.component";
import {PermissionType} from "../../domain/user/Permission";
import {RoleGuard} from "../../guards/RoleGuard";
import {AuthenticatedUsersOnlyModalBlockGuard} from "../../guards/AuthenticatedUsersOnlyModalBlockGuard";


const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Frameworks'
        },
        children: [
            {
                path: '',
                component: ListFrameworksComponent
            },
            {
                path: ':guid/edit',
                component: FrameworkEditComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard, RoleGuard],
                data: {
                    permission: PermissionType.edit_framework
                }
            },
            {
                path: ':guid',
                component: FrameworkDetailComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class FrameworksRoutingModule { }
