import {Directive, Input, HostBinding, OnChanges} from '@angular/core';
import {ValidationErrorService} from "../../services/common/ValidationErrorService";
import {Subscription} from "rxjs"
import {ValidationError} from "class-validator";

@Directive({
  selector: '[waih-validation-color]'
})
export class ValidationColorDirective {

  /** Name of the field to validate **/
  @Input() field:string = "";
  /** Applies the class specific to <input> elements if true, otherwise uses has-warning and has-danger **/
  @Input() isInput:boolean;

  private errorsSubscription: Subscription;
  private warningsSubscription: Subscription;

  /** The array of errors for the form **/
  private validationErrors:ValidationError[];
  /** The array of warnings for the form **/
  private validationWarnings:ValidationError[];


  /** Bindings to the host element's classes. Allows you to apply or remove them by setting to true or false. **/
  @HostBinding('class.has-danger') danger_color;
  @HostBinding('class.has-warning') warning_color;
  @HostBinding('class.form-control-danger') control_danger_color;
  @HostBinding('class.form-control-warning') control_warning_color;

  constructor(private validationErrorService:ValidationErrorService) {
    this.errorsSubscription = validationErrorService.errors$.subscribe(
        errors => {
          this.validationErrors = errors;

          if (this.isInput == true) {
            this.control_danger_color = this.hasErrorFor(this.field);
          } else {
            this.danger_color = this.hasErrorFor(this.field);
          }

        });

    this.warningsSubscription = validationErrorService.warnings$.subscribe(
        warnings => {
          this.validationWarnings = warnings;

          if (this.isInput == true) {
            this.control_warning_color = this.hasWarningFor(this.field) && !this.hasErrorFor(this.field);
          } else {
            this.warning_color = this.hasWarningFor(this.field) && !this.hasErrorFor(this.field);
          }

        });
  }


  public hasErrorFor(name:string):boolean {
    return this.validationErrorService.hasProblem(name, this.validationErrors);
  }
  public hasWarningFor(name:string):boolean {
    return this.validationErrorService.hasProblem(name, this.validationWarnings);
  }

}


