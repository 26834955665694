import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Localization} from "../../../data/Localization";
import {LocalizationService} from "../../../services/LocalizationService";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent extends SubscriptionCleaner implements OnInit, OnDestroy {

	private cbContext = Localization.template.common.search;
	public CB = {
		placeholder: this.cbContext.placeholder,
	};

	private searchSubject = new Subject<string>();

	@Output()
	searchValue: EventEmitter<string> = new EventEmitter();

	@Input()
	public searchTerm:string = null;

	constructor (private localizationService:LocalizationService) {
		super();
		this.localizationService.registerAndLocalize("SearchComponent", this.CB);
		this.setSearchSubscription();
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.searchSubject.unsubscribe();
	}

	private setSearchSubscription() {
		this.trackSubscription(
			this.searchSubject.pipe(
				debounceTime(500), //wait 500 ms for the user to pause typing
				distinctUntilChanged() //ignore until it changes
			).subscribe((searchValue:string) => {
				this.searchValue.emit(searchValue); //emit the new search terms back to the parent
				this.searchTerm = searchValue;
			}));
		}

	public updateSearch(searchTextValue:string) {
		this.searchSubject.next(searchTextValue);
	}

	public clear() {
		this.searchSubject.next("");
		this.searchTerm = "";
	}

}
