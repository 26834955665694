
<aside class="aside-menu" *ngIf="resourceSubmission != null">
    <!--<div class="bump-left-or-right"><span id="goLeft">&#10562;</span><span id="goRight">&#10564;</span></div>-->
    <tabset>
        <tab>
            <ng-template tabHeading><i class="icon-list"></i></ng-template>
            <ng-container *ngFor="let section of this.activityEntriesByVersion$ | async">
                <div class="callout m-0 py-2 text-muted text-center bg-light text-uppercase">
                    <small><b>{{CB.version | localize | async}} {{section[0].version.major}}</b></small>
                </div>
                <ng-container *ngFor="let entry of section">
                    <ng-container *ngIf="isImportantActivityEntry(entry)">
                        <hr class="transparent mx-3 my-0">
                        <div class="callout m-0 py-3" [ngClass]="entry.callOutColor">
                            <div class="avatar float-right">
                                <avatar-component [userRef]="entry.waihonaUserRef" [class]="'select-image'"></avatar-component>
                            </div>
                            <div><div [innerHtml]="entry.icon" class="d-inline"></div> {{entry.type}}</div>
                            <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;{{entry.timeStampAsMMDDYYYY}}</small>
                            <small class="text-muted"><i class="icon-location-pin"></i>&nbsp; {{entry.detail}}</small>
                        </div>
                        <hr class="mx-3 my-0">
                    </ng-container>
                </ng-container>
            </ng-container>
        </tab>
        <tab>
            <ng-template tabHeading><i class="icon-speech"></i></ng-template>
            <review-chat [chat]="resourceChat"></review-chat>
        </tab>
        <tab>
            <ng-template tabHeading><i class="icon-settings"></i></ng-template>
            <div class="p-3">
                <h6>{{CB.settings | localize | async}}</h6>

                <div class="aside-options">
                    <div class="clearfix mt-4">
                        <small><b>{{CB.auto_publish_label | localize | async}}</b></small>
                        <label class="switch switch-text switch-pill switch-success switch-sm float-right">
                            <input type="checkbox" class="switch-input" checked>
                            <span class="switch-label" [attr.data-on]="CB.on | localize | async" [attr.data-off]="CB.off | localize | async"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div>
                        <small class="text-muted">{{CB.auto_publish_description | localize | async}}</small>
                    </div>
                </div>

                <div class="aside-options">
                    <div class="clearfix mt-3">
                        <small><b>{{CB.allow_feedback_label | localize | async}}</b></small>
                        <label class="switch switch-text switch-pill switch-success switch-sm float-right">
                            <input type="checkbox" class="switch-input" checked>
                            <span class="switch-label" [attr.data-on]="CB.on | localize | async" [attr.data-off]="CB.off | localize | async"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div>
                        <small class="text-muted">{{CB.allow_feedback_description | localize | async}}</small>
                    </div>
                </div>
                <div class="aside-options">
                    <div class="clearfix mt-3">
                        <small><b>{{CB.allow_public_comments_label | localize | async}}</b></small>
                        <label class="switch switch-text switch-pill switch-success switch-sm float-right">
                            <input type="checkbox" class="switch-input">
                            <span class="switch-label" [attr.data-on]="CB.on | localize | async" [attr.data-off]="CB.off | localize | async"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div>
                        <small class="text-muted">{{CB.allow_public_comments_description | localize | async}}</small>
                    </div>
                </div>
                <hr>
                <h6 [innerHTML]="CB.changing_settings_no_effect | localize | async"><!--*The above options toggles currently have no effect.<br/><br/>Changing their settings at this current moment does nothing (but will).--></h6>
            </div>
        </tab>
    </tabset>
</aside>
