import {Exclude, Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {InvitedUser} from "./InvitedUser";

@RegisterClazz(RegistrationInviteCode, "Invites")
export class RegistrationInviteCode {
	@Exclude()
	public guid:string = "";
	public code:string = "";
	public orgGuid:string = "";
	public maxAllowed:number = 0;
	public isActive:boolean = false;
	public shouldAutoAddToOrg:boolean = false;

	@Type(() => InvitedUser)
	@RegisterType({clazz: InvitedUser})
	public usedCodes:Array<InvitedUser> = [];

}
