import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {ResourcePublishStatus} from "../../domain/resources/ResourcePublishStatus";

@Injectable({
	providedIn: 'root',
} as any)
export class ResourcePublishStatusRepository extends AbstractFirestoreRepository<ResourcePublishStatus> {
	constructor(protected db:AngularFirestore) {
		super(ResourcePublishStatus, db, db.collection("ResourcePublishStatuses"), "guid");
	}

}
