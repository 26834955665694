import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject, Subscription} from "rxjs";
import {NavigateAwayConfirmationModalComponent} from "../areas/modals/navigate-away-confirmation-modal/navigate-away-confirmation-modal.component";
import {NotificationService} from "../services/common/NotificationService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";

export interface CanComponentDeactivate {
	canDeactivate: () =>  boolean | Observable<boolean> | Promise<boolean>
}
@Injectable()  /*implements CanDeactivate<CanComponentDeactivate>*/
export class NavigatingComponentDeactivationGuard extends SubscriptionCleaner implements CanDeactivate<CanComponentDeactivate> {

	private bsModalRef:BsModalRef;
	public userSaysItsOkayToLeaveSubject$:Subject<boolean> = new Subject<boolean>();

	constructor(private notificationService:NotificationService) {
		super();
	}

	public canDeactivate(component:{canDeactivate:()=>boolean}):Observable<boolean>|boolean {
		let canDeactivate:boolean = component.canDeactivate();

		if(canDeactivate) {
			return true;
		} else {
			let self = this;
			return Observable.defer(async function () {
				let waitForModalPromise:Promise<boolean> = new Promise((resolve, reject) => {
					self.showModal();
					let s:Subscription = self.userSaysItsOkayToLeaveSubject$.subscribe( isOkay => {
						if(isOkay) {
							s.unsubscribe();
							console.log("User says it is okay to leave despite possibly losing stuff");
							resolve(true);
						} else {
							console.log("User says it is NOT okay.  Returning to page.");
							resolve(false);
						}
					})
				});
				return await waitForModalPromise;
			});
		}
		return true;

	}
	ngOnDestroy():void {
		super.ngOnDestroy();
		this.hideModal();
	}

	public showModal():void {
		this.bsModalRef = this.notificationService.displayModal(NavigateAwayConfirmationModalComponent, this); //TODO: the passing of component should NOT be this
	}
	public hideModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

}
