import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {ResourceSubmissionService} from '../../../../../services/ResourceSubmissionService';
import {ResourceSubmission, ResourceSubmissionStatus} from '../../../../../domain/resources/ResourceSubmission';
import {Review} from "../../../../../domain/resources/review/Review";
import {ReviewService} from "../../../../../services/ReviewService";
import {PublishedResourceService} from "../../../../../services/PublishedResourceService";
import {BaseComponent} from "../../../../BaseComponent";
import {ToastType} from "../../../../../services/common/NotificationService";
import {PublishedResource} from "../../../../../domain/resources/PublishedResource";
import {FanContributionService} from "../../../../../services/FanContributionService";
import {FanContribution} from "../../../../../domain/resources/FanContribution";
import {ResourceSubmissionRef} from "../../../../../domain/resources/ResourceSubmissionRef";
import {Localization} from "../../../../../data/Localization";
import {Observable, Subscription} from "rxjs";
import {ResourceSubmissionTextContent} from "../../../../../domain/resources/ResourceSubmissionTextContent";

@Component({
	templateUrl: 'list-resource-submissions.component.html',
	styleUrls: [
		"./list-resource-submissions.component.scss",
		'../../../../../../scss/vendors/toastr/toastr.scss'
	],
	encapsulation: ViewEncapsulation.None,
	providers: [ResourceSubmissionService]
})
export class ListResourceSubmissionsComponent extends BaseComponent implements OnInit {

	private cbContext = Localization.template.resource.list_contributions;

	//<editor-fold defaultstate="collapsed" desc="Content Blocks">
	public CB = {
		header: this.cbContext.header,
		no_drafts: this.cbContext.alerts.no_drafts,
		create_one: this.cbContext.alerts.create_one,
		create_new: this.cbContext.links.create_new,
		fan_contributions: this.cbContext.alerts.fan_contributions,
		section_corrections: this.cbContext.table.section_headers.corrections,
		section_drafts: this.cbContext.table.section_headers.drafts,
		section_pendingReview: this.cbContext.table.section_headers.pending_review,
		section_inReview: this.cbContext.table.section_headers.in_review,
		section_reviewContinuation: this.cbContext.table.section_headers.review_continuation,
		section_collaborations:this.cbContext.table.section_headers.collaborations,
		section_published: this.cbContext.table.section_headers.published,
		th_edit_view: this.cbContext.table.table_headers.edit_view,
		th_view_recall: this.cbContext.table.table_headers.view_recall,
		th_view: this.cbContext.table.table_headers.view,
		th_title: this.cbContext.table.table_headers.title,
		th_collaborators: this.cbContext.table.table_headers.collaborators,
		th_reviewer: this.cbContext.table.table_headers.reviewer,
		th_reviewers: this.cbContext.table.table_headers.reviewers,
		th_submitter: this.cbContext.table.table_headers.submitter,
		th_version: this.cbContext.table.table_headers.version,
		th_reviewDate: this.cbContext.table.table_headers.review_date,
		th_saveDate: this.cbContext.table.table_headers.save_date,
		th_submitDate: this.cbContext.table.table_headers.submit_date,
		th_startDate: this.cbContext.table.table_headers.start_date,
		th_publishDate: this.cbContext.table.table_headers.publish_date,
		th_updatedDate: this.cbContext.table.table_headers.updated_date,
		td_noReviewers: this.cbContext.table.table_data.no_reviewers,
		tooltip_edit: Localization.template.tooltips.edit,
		tooltip_view: Localization.template.tooltips.view,
		tooltip_recall: Localization.template.tooltips.recall,
	};
	//</editor-fold>

	public guid:string;
	public collaborations:ResourceSubmission[] = [];
	public publishedLessons:Array<PublishedResource> =[];
	public resourcesWithFanContributions:Array<ResourceSubmissionRef>;
	public userHasNoSubmissions:boolean = false;

	public filteredDrafts:{drafts:Array<ResourceSubmission>, pendingCorrections:Array<ResourceSubmission>} = {
		drafts: [],
		pendingCorrections: []
	};

	public filteredReviews:{lessonsPendingReview:Array<Review>,lessonsInReview:Array<Review>,lessonsInReviewContinuation:Array<Review>} = {
		lessonsPendingReview: [],
		lessonsInReview: [],
		lessonsInReviewContinuation: []
	};


	public constructor(protected injector:Injector,
	                   private resourceSubmissionService:ResourceSubmissionService,
	                   private publishedResourceService:PublishedResourceService,
	                   private fanContributionService:FanContributionService,
	                   private reviewService:ReviewService) {
		super(injector);
		this.localizationService.registerAndLocalize("ListResourceSubmissionsComponent", this.CB);

		this.logger.info("init:ListResourceSubmissionsComponent");

		const guid:string = this.route.snapshot.params['guid'] != "" ? this.route.snapshot.params['guid'] : null;
		this.guid = guid;
		this.logger.info(`viewing list-contributions page for user ${guid}`);

		//redirect if URL is not current user (edge case)
		if (guid != this.currentUser.guid) {
			this.logger.warn(`URL mismatch detected! Redirecting to current user URL params`);
			this.router.navigate(['/resources/users', this.currentUser.guid], {replaceUrl: true});
		}

		if (this.currentUser) {
			this.fetchDrafts();
			this.fetchReviews();
			this.fetchPublishedResources();
			this.fetchResourcesWithFanContributions();
			this.fetchCollaborations();
		}
	}

	public ngOnInit():void {
		console.info("OnInit");
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};
	public get ResourceSubmissionTextContent():(new()=>{}) {
		return ResourceSubmissionTextContent;
	}

	public fetchDrafts():void {
		let s:Subscription = this.trackSubscription(this.resourceSubmissionService.getAllResourcesBySubmitterId$(this.currentUser.guid).subscribe(drafts => {
			s.unsubscribe();
			this.filteredDrafts.drafts = this.resourceSubmissionService.filterByStatus(drafts, ResourceSubmissionStatus.draft);
			this.filteredDrafts.pendingCorrections = this.resourceSubmissionService.filterByStatus(drafts, ResourceSubmissionStatus.in_review_revision);
			console.info(`fetchDrafts: drafts: ${this.filteredDrafts.drafts.length}, pendingCorrections: ${this.filteredDrafts.pendingCorrections.length}`);
		}));
	}

	public fetchCollaborations():ResourceSubmission[] {
		this.trackSubscription(this.resourceSubmissionService.getAllResourcesByCollaboratorId$(this.currentUser.guid).subscribe (items => {
			this.collaborations = items;
			console.info(`method fetchCollaborations returned ${this.collaborations.length} collaborations.`);
			return this.collaborations;
		}));
		return null;
	}

	public fetchReviews():void {
		let s:Subscription = this.trackSubscription(this.reviewService.listReviewsBySubmitter$(this.currentUser.guid).subscribe(reviews => {
			s.unsubscribe();
			this.filteredReviews.lessonsPendingReview = this.reviewService.filterByStatus(reviews, ResourceSubmissionStatus.submitted);
			this.filteredReviews.lessonsInReview = this.reviewService.filterByStatus(reviews, ResourceSubmissionStatus.in_review);
			this.filteredReviews.lessonsInReviewContinuation = this.reviewService.filterByStatus(reviews, ResourceSubmissionStatus.in_review_continuation);

			console.info(`fetchReviews: lessonsPendingReview: ${this.filteredReviews.lessonsPendingReview.length}, lessonsInReview: ${this.filteredReviews.lessonsInReview.length}, lessonsInReviewContinuation: ${this.filteredReviews.lessonsInReviewContinuation.length}`);
		}));
	}

	public fetchPublishedResources():Array<PublishedResource> {
		this.trackSubscription(this.publishedResourceService.getPublishedResourcesBySubmitter$(this.currentUser.guid).subscribe(contributions => {
			this.publishedLessons = contributions;
		}));
		console.info(`method fetchPublishedResources returned ${this.publishedLessons.length} published lessons.`);
		return this.publishedLessons;
	}

	public fetchResourcesWithFanContributions():Array<ResourceSubmissionRef> {
		this.resourcesWithFanContributions = [];
		this.trackSubscription(this.fanContributionService.listBySubmitterId$(this.currentUser.guid).subscribe(contributions => {
			let fanContributions:Array<FanContribution> = contributions;
			for (let i:number = 0; i<fanContributions.length; i++) {
				this.resourcesWithFanContributions.push(fanContributions[i].resourceSubmissionRef)
			}
		}));
		return this.resourcesWithFanContributions;
	}

	public recallSubmission(review:Review):void {
		this.logger.info(`${review.resourceSubmission.guid} was recalled from review to a draft state`);
		this.reviewService.recallSubmission(review);
		this.notificationService.displayToast(this.cbContext.toast.recall, ToastType.warning);
	}

}
