import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {DocumentTextContent} from "../../../domain/DocumentTextContent";
import {LocalizationService} from "../../../services/LocalizationService";
import {PopoverDirective} from "ngx-bootstrap/popover";
import {Localization} from "../../../data/Localization";
import {LocalizePipe} from "../../../pipes/LocalizePipe";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";
import {SupportedLanguage, SupportedLanguages} from "../../../domain/SupportedLanguages";

@Component({
	selector: 'doc-language-button',
	templateUrl: './doc-language-button.component.html',
	styleUrls: ['./doc-language-button.component.scss']
})
export class DocLanguageButtonComponent implements OnInit {

	public ConfirmContext = Localization.template.resource.edit.language.confirm;

	public CB = {
		confirm_haw_title: this.ConfirmContext.haw_title,
		confirm_en_title: this.ConfirmContext.en_title,
		confirm_yes: this.ConfirmContext.yes,
		confirm_no: this.ConfirmContext.no
	};


	//View Components
	@ViewChild("buttonPopover", {read: PopoverDirective}) public buttonPopover:PopoverDirective;
	@ViewChild("createPopoverTemplate") public createPopoverTemplate:TemplateRef<any>;
	@ViewChild("deletePopoverTemplate") public deletePopoverTemplate:TemplateRef<any>;


	@Input()
	public title:string;

	@Input()
	public documentTextContent:DocumentTextContent<any>;

	@Input()
	public language:SupportedLanguage;

	@Input()
	public selected:boolean = false;

	@Output()
	public changeToEvent:EventEmitter<SupportedLanguage> = new EventEmitter<SupportedLanguage>();

	@Output()
	public languageNewEvent:EventEmitter<SupportedLanguage> = new EventEmitter<SupportedLanguage>();

	@Output()
	public confirmDeleteEvent:EventEmitter<SupportedLanguage> = new EventEmitter<SupportedLanguage>();

	constructor(private localizationService:LocalizationService) {

	}

	ngOnInit():void {


	}

	public get languageLabel():string {
		return SupportedLanguages.toPretty(this.language);
	}

	public popover = {
		hide: () => {
			this.buttonPopover.hide();
		},
		create: {
			show: () => {
				this.buttonPopover.popover = this.createPopoverTemplate;
				this.buttonPopover.show();
			},
			hide: () => {
				this.buttonPopover.popover = this.createPopoverTemplate;
				this.buttonPopover.hide();
			}
		},
		delete: {
			show: () => {
				this.buttonPopover.popover = this.deletePopoverTemplate;
				this.buttonPopover.show();
			},
			hide: () => {
				this.buttonPopover.popover = this.deletePopoverTemplate;
				this.buttonPopover.hide();
			}
		}
	};

	public get languageExists():boolean {
		return this.documentTextContent[this.language] != null;
	}
	public get hasOtherLanguages():boolean {
		return Object.keys(this.documentTextContent).filter(key => key != 'Clazz').length > 1;
	}
	public click():void {
		if(!this.selected && !this.languageExists) {
			this.popover.create.show();
		} else if (this.selected && this.hasOtherLanguages) {
			this.popover.delete.show();
		} else if(!this.selected && this.languageExists) {
			this.changeToEvent.emit(this.language);
		}
	}


	public create():void {
		if (this.documentTextContent[this.language] == null) {
			this.languageNewEvent.emit(this.language);
			this.popover.create.hide();
		}
	}

	public changeTo():void {
		this.languageNewEvent.emit(this.language);
	}

	public delete():void {
		if (this.documentTextContent[this.language] != null) {
			this.confirmDeleteEvent.emit(this.language);
			this.popover.delete.hide();
		}
	}

}
