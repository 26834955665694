import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Framework} from "../../domain/frameworks/Framework";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {FrameworkRef} from "../../domain/frameworks/FrameworkRef";
import {ConversionService} from "../ConversionService";
import {RefCachingService} from "../RefCachingService";

@Injectable({
	providedIn: 'root',
} as any)
export class FrameworkRepository extends AbstractFirestoreRepository<Framework, FrameworkRef> {

	constructor(protected db:AngularFirestore, protected conversionService:ConversionService, protected refCachingService:RefCachingService) {
		super(Framework, db,
			{
				type: db.collection("Frameworks"),
				ref: db.collection("Refs/frameworks/All"),
			}, "guid", conversionService, FrameworkRef);

		this.refCachingService.registerSource(this);
	}

}
