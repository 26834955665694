import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from "../services/AuthService";
import {defer, Observable, Subscription} from "rxjs";
import {NotificationService} from "../services/common/NotificationService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthenticatedUsersOnlyModalComponent} from "../areas/modals/authenticated-users-only-modal/authenticated-users-only-modal.component";
import {SiteConfigurationService} from "../services/SiteConfigurationService";
import {NGXLogger} from "ngx-logger";
import {PublishedResourceRepository} from "../services/repository/PublishedResourceRepository";
import {RoutingService} from "../services/RoutingService";
import {CookieService} from "ngx-cookie-service";
import {UrlService} from "../services/UrlService";
@Injectable({
	providedIn: 'root',
})
/**
 * Specifies whether a Draft can be edited or not
 */
export class PublicAccessBlockGuard implements CanActivate {

	private bsModalRef:BsModalRef;
	private subscriptions:Array<Subscription> = [];
	private next:ActivatedRouteSnapshot;
	private state:RouterStateSnapshot;

	constructor(private authService:AuthService,
				private notificationService:NotificationService,
				private publishedResourceRepository:PublishedResourceRepository,
				private router:Router,
				private routingService:RoutingService,
				private urlService:UrlService,
				private siteConfigurationService:SiteConfigurationService,
				protected logger:NGXLogger) {
	}

	public canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean> {
		console.info("PublicAccessBlockGuard::canActivate");
		this.next = next;
		this.state = state;
		let self = this;
		return defer(async function () {
			console.info("PublicAccessBlockGuard::canActivate::async function ()");
			let shouldBlockAccess:boolean = self.siteConfigurationService.siteConfiguration.loginRequiredForSiteAccess;
			if (!shouldBlockAccess) {
				console.info("Not blocking access based on site access variable");
				return true;
			}

			console.info("self.authService.isAuthenticatingCurrently: " + self.authService.isAuthenticatingCurrently);
			let isAUserWaitingForLogin:boolean = self.authService.isAuthenticatingCurrently || self.authService.isHandlingLocalSelfAuth;

			if (isAUserWaitingForLogin) {
				console.info("Waiting for login process to complete");
				let waitForLoginPromise:Promise<boolean> = new Promise((resolve, reject) => {
					let didResolve:boolean = false;


					setTimeout(() => {
						if (!didResolve) {
							console.info("PublicAccessBlockGuard timed out");
							self.clearSubscriptions();
							resolve(false)
						}


					}, 10000);

					let wasHandlingLocalSelfAuth:boolean = self.authService.isHandlingLocalSelfAuth;
					let wasHandlingNormalAuthenticating:boolean = self.authService.isAuthenticatingCurrently;

					if (self.authService.isAuthenticatingCurrently) {
						self.subscriptions.push(self.authService.isAuthenticatingCurrently$.subscribe(isLoggingIn => {
							console.info("Logging in process data has been changed" + isLoggingIn);
							self.clearSubscriptions();
							if (isLoggingIn == false && wasHandlingNormalAuthenticating) {
								console.info("Completed login process");
								didResolve = true;
								self.clearSubscriptions();
								resolve(true);
							}
						}));
					} else if (self.authService.isHandlingLocalSelfAuth) {
						self.subscriptions.push(self.authService.isHandlingLocalSelfAuth$.subscribe(isHandlingLocalSelfAuth => {
							if (isHandlingLocalSelfAuth == false && wasHandlingLocalSelfAuth) {
								console.info("Completed local auth process");
								didResolve = true;
								self.clearSubscriptions();
								resolve(true);
							}
						}));
					} else {
						// dont know all the cases
						resolve(true);
					}
				});

				let successfullyLoggedIn:boolean = await waitForLoginPromise;
				let isAPublicResource:boolean = false;

				if (successfullyLoggedIn) {
					//No need to further wait
				} else {
					//We need to load up the resource and determine if its public access
					//TODO: Ensure route is valid
					let resourceGuid:string = next.params['resourceId']
					let waitForResourceLoad:Promise<boolean> = self.checkPublicResource(resourceGuid);
					isAPublicResource = await waitForResourceLoad; //Resource says its okay
				}

				console.info("Waited for login process to complete succesfully, isLoggingIn==" + self.authService.isAuthenticatingCurrently + ", successfullyLoggedIn: " + successfullyLoggedIn);
				return self.finishUpEitherWay(isAPublicResource, next, state);

			} else {
				//Is not a user currently WAITING for login
				let resourceGuid:string = next.params['resourceId']
				let isAPublicResource:boolean = await self.checkPublicResource(resourceGuid);

				return self.finishUpEitherWay(isAPublicResource, next, state);
			}
		});
	}

	public finishUpEitherWay(isAPublicResource:boolean, next:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {
		let isAUser:boolean = this.authService.currentUser != null;
		let self = this;
		if (isAUser) {
			console.info(`Welcome in ${this.authService.currentUser.guid}!`);
		} else if (!isAUser && isAPublicResource) {
			console.error("Hawt dog.  You got some access to a waihona resource without registering or logging in.");
		} else {
			console.error("It is invalid for anonymous user to access the resource without being registered.");
			if (this.routingService.getPreviousUrl() == undefined) {
				this.router.navigateByUrl("/resources", {skipLocationChange:false});
				console.info("First navigation and not allowed..so navigating to location page.");
				console.log("This: " + this + ", self: " + self);
				this.urlService.saveAttemptedResourceRoute(this.next, this.state);
			}
			console.log("this: " + this.constructor.name);
			this.bsModalRef = this.notificationService.displayModal(AuthenticatedUsersOnlyModalComponent, this);
		}

		return isAUser || isAPublicResource;
	}

	public checkPublicResource(resourceGuid:string):Promise<boolean> {
		let self = this;
		return new Promise((resolve, reject) => {
			let s:Subscription = self.publishedResourceRepository.get$(resourceGuid).subscribe(resource => {
				if (resource == null) {
					resolve(false);
					return; //not sure if these returns are necessary or not
				}
				if (resource.resourceSubmission.configuration.allowPublicAccess) {
					resolve(true);
					return;
				} else {
					resolve(false);
					return;
				}
			});
			self.subscriptions.push(s);
		});
	}

	public clearSubscriptions():void {
		while (this.subscriptions.length > 0) {
			let s:Subscription = this.subscriptions.pop();
			s.unsubscribe();
		}

	}
	public destroyModal():void {
		this.bsModalRef.hide();
		delete this.bsModalRef;
	}

	public onClickLogin():void {
		console.info("Clicked Login");
		this.authService.login();
		this.destroyModal();
	}
	public onClickRegistration():void {
		console.info("Clicked Registration");
		this.router.navigate(["/user/register"]);
		this.destroyModal();
	}
	public onClickCancel():void {
		console.info("Clicked Cancel");
		this.destroyModal();
	}
}
