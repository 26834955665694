import {AbstractFirestoreRepository} from "./AbstractFirestoreRepository";
import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {ActivityStream} from "../../domain/resources/ActivityStream";
import {RefCachingService} from "../RefCachingService";


@Injectable({
	providedIn: 'root',
} as any)
export class ActivityStreamRepository extends AbstractFirestoreRepository<ActivityStream> {

	constructor(protected db:AngularFirestore, protected refCachingService:RefCachingService) {
		super(ActivityStream, db, db.collection("ActivityStreams"), "guid");
		this.refCachingService.registerProcessor(this);
	}

}
