import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Localization} from "../../../data/Localization";
import {LocalizationService} from "../../../services/LocalizationService";

@Component({
  selector: 'view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss']
})
export class ViewSwitcherComponent implements OnInit {

  @Input()
  public views:Array<ViewType> = [];

  @Input()
  public currentView?:ViewType = ViewType.list;

  @Input()
  public activeClassString?:string = "";

  @Input()
  public inactiveClassString?:string = "";

  @Input()
  public extraClassString?:string = "";

  @Output()
  public isActive:EventEmitter<ViewType> = new EventEmitter<ViewType>();

  private cbContext = Localization.template.common.view_switcher;

  constructor (private localizationService:LocalizationService) {
    this.localizationService.registerAndLocalize("ViewSwitcherComponent", this.cbContext);
  }

  ngOnInit(): void {
  }

  public getClassString(view:ViewType):string {
    return `${this.getIcon(view)} ${this.extraClassString}`
  }

  public getIcon(view:ViewType):FAIcon {
    switch (view) {
      case ViewType.list: return FAIcon.list;
      case ViewType.grid: return FAIcon.grid;
    }
  }

  public getCB(view:ViewType) {
    switch (view) {
      case ViewType.list: return this.cbContext.list;
      case ViewType.grid: return this.cbContext.grid;
    }
  }

  public onClick(view:ViewType):void {
    this.currentView = view;
    this.isActive.emit(this.currentView);
  }

}

export enum ViewType {
  list = "list",
  grid = "grid"
}

export enum FAIcon {
  list = "fas fa-list",
  grid = "fas fa-th"
}
