import {ResourceVersion} from "./ResourceVersion";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {ActivityType, ActivityTypeColors, ActivityTypeIcon} from "./ActivityStream";
import {Type} from "class-transformer";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {RefType} from "../RefType";

@RegisterClazz(ActivityEntry)
export class ActivityEntry {
	public type:ActivityType = ActivityType.NONE;
	public detail:string = "";
	public epochTimestamp:number = 0;

	@Type(()=>ResourceVersion)
	@RegisterType({clazz:ResourceVersion})
	public version:ResourceVersion;

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef})
	@RefType({Clazz: WaihonaUserRef})
	public waihonaUserRef:WaihonaUserRef;
	
	public get callOutColor():string {
		return ActivityTypeColors[this.type];
	}

	public get icon():string {
		return ActivityTypeIcon[this.type];
	}

	constructor(type?:ActivityType, detail?:string, timestamp:Date = new Date(), version?:ResourceVersion) {
		if (type != null) {
			this.type = type;
		}
		if (detail != null) {
			this.detail = detail;
		}
		this.epochTimestamp = timestamp.getTime();
		if (version == null) {
			this.version = new ResourceVersion();
		} else {
			this.version = version.clone();
		}

	}

	public get timestamp():Date {
		let ts:Date = new Date();
		ts.setTime(this.epochTimestamp);
		return ts;
	}

	public get timeStampAsMMDDYYYY():string {
		if (this.epochTimestamp == 0) { return ""; }
		return this.timestamp.getMonth() + 1 + "/" + this.timestamp.getDate() + "/" + this.timestamp.getFullYear();
	}

	public with(waihonaUserRef:WaihonaUserRef):ActivityEntry {
		this.waihonaUserRef = waihonaUserRef;
		return this;
	}

	public at(date:Date = new Date()):ActivityEntry {
		this.epochTimestamp = date.getTime();
		return this;
	}

	public using(version:ResourceVersion | string):ActivityEntry {

		//If string convert to Array first (handled later)
		if (typeof version == "string") {
			// @ts-ignore
			let versionArray:Array<number> = <any>version.split(".");
			this.version = new ResourceVersion().set(versionArray[0], versionArray[1], versionArray[2]);
		} else {
			this.version = <any>version;
		}

		return this;
	}

}
