import {Injectable} from "@angular/core";
import {FanContribution} from "../domain/resources/FanContribution";
import {FanContributionRepository} from "./repository/FanContributionRepository";
import {ValidationException} from "./exceptions/ValidationException";
import {validateSync, ValidationError, Validator} from "class-validator";
import {Observable} from "rxjs";
import {ResourceFunctions} from "./functions/ResourceFunctions";
import {AssetSource, LearningAsset} from "../domain/resources/LearningAsset";
import {DefaultStorageBucket} from "./storage/DefaultStorageBucket";
import {ResourceSubmission} from "../domain/resources/ResourceSubmission";
import {PathService} from "./PathService";

@Injectable()
export class FanContributionService {

	private copyInstructions:Array<{source:string, destination:string}> = [];

	constructor (private repo:FanContributionRepository,
				 private functions:ResourceFunctions,
				 private defaultStorageBucket:DefaultStorageBucket,
				 private pathService:PathService
				 ) {}

	public validate(fanContribution:FanContribution) {
		console.info(`validating fanContribution: ${fanContribution.guid}`);

		const validator = new Validator();
		let errors:Array<ValidationError> = [];

		let isUserContribution:boolean = fanContribution.fanRef && !fanContribution.isProxy;
		let isProxyContribution:boolean = fanContribution.fanRef && fanContribution.isProxy;
		let isAnonContribution:boolean = fanContribution.fanRef == null;

		if(isAnonContribution) {
			console.log("Validating as Anon");
			errors = validateSync(fanContribution, {groups: ["isAnon"]})
		} else if(isProxyContribution) {
			console.log("Validating as Proxy");
			errors = validateSync(fanContribution, {groups: ["isProxy"]});
		} else {//Assuming user..
			console.log("Validating as User");
			errors = validateSync(fanContribution, {groups: ["isUser"]})
		}

		console.info(`there are ${errors.length} errors`);
		if (errors.length > 0) {
			console.error(`validation failed. Errors: `, errors);
			throw new ValidationException(errors);
		} else {
			console.info(`validation passed!`);
		}

	}

	public saveFanContribution(fanContribution:FanContribution) {
		console.info(`saving the contribution`);
		this.repo.save$(fanContribution);
	}

	public cancelFanContribution(fanContribution:FanContribution) {
		let hasFiles:boolean = fanContribution.files.length > 0;
		if(hasFiles) {
			console.info("Found some files and deleting out of storage");
			for(let i:number = 0; i < fanContribution.files.length; i++) {
				let iFile:LearningAsset = fanContribution.files[i];
				console.info(`Removing ${iFile.fileInfo.path}`);
				this.defaultStorageBucket.delete(iFile.fileInfo.path);
			}
		} else {
			console.log("Didn't find any files");
		}
	}


	public listByResourceId$(resourceGuid:string):Observable<FanContribution[]> {
		console.info(`fetching any fan contributions for resource guid: ${resourceGuid}`);
		return this.repo.query$(this.repo.byResource(resourceGuid));
	}

	public listBySubmitterId$(submitterGuid:string):Observable<FanContribution[]> {
		console.info(`fetching any pending fan contributions for user ${submitterGuid} to review.`);
		return this.repo.query$(this.repo.bySubmitter(submitterGuid));
	}


	public acceptFanContribution(fanContribution:FanContribution, resourceSubmission:ResourceSubmission):void {
		console.info(`Fan Contribution Service is handling the acceptance of fan contribution ${fanContribution.guid}`);

		try {
			// handle the new learning assets
			this.updateFanContributionPaths(fanContribution, resourceSubmission);

			// update the database
			console.info(`saving the updated files`);
			this.repo.save$(fanContribution);

			// delete the old database object from FanContributions
			console.info(`removing the object from the fan contribution repository`);
			this.repo.delete$(fanContribution.guid);

			// move the associated files in storage
			this.functions.acceptFanContribution(this.copyInstructions).subscribe( resolved => {
				if (resolved) {
					console.info(`File move is complete!}`)
				}
			});

			// Add Activity Stream Entry with Fan Contribution Details
			// Add attribution to file source (do we need to prevent attribution from being deleted by the submitter?)

			} catch(error) {
				console.error(error);
				throw error;
				//todo: handle throw
			}
	}


	public declineFanContribution(fanContribution:FanContribution):void {
		console.info(`Fan Contribution Service is handling the declination of fan contribution ${fanContribution.guid}`);

		console.warn(`deleting the object ${fanContribution.guid} from the repository`);
		this.repo.delete$(fanContribution.guid);

		console.info(`deleting the files from storage`);
		this.functions.deleteFanContribution(fanContribution.guid);

		// Add Activity Stream Entry with Fan Contribution Details

	}


	private updateFanContributionPaths(fanContribution:FanContribution, resourceSubmission:ResourceSubmission):void {
		console.info(`updating the fan contribution asset files`);

		const fanContributionFiles:Array<LearningAsset> = fanContribution.files;
		const resourceSubmissionFiles:Array<LearningAsset> = resourceSubmission.files;
		let fileNamePrefix:string = fanContribution.guid.split("-").pop();
		this.copyInstructions = [];

		for (let i:number = 0; i < fanContributionFiles.length; i++) {
			let fanFile:LearningAsset = fanContributionFiles[i];

			//update file source
			fanFile.assetSource = AssetSource.fanContribution;
			//TODO: Uploader needs to support setting this during the upload process

			//check for name conflicts
			let hasConflict:boolean = resourceSubmissionFiles.find(resourceFile => resourceFile.fileName == fanFile.fileName) != null;

			if (hasConflict) {

				//add conflict marker
				fanFile.hasConflictWith = fanFile.fileName;

				//give it a new name
				let newFileName:string = fileNamePrefix + "-" + fanFile.fileName;
				console.info(`${fanFile.fileName} has a conflict with a resource. Renaming to ${newFileName},`);

				//create the copy instructions
				let copyInstruction = {source: fanFile.fileInfo.path, destination: this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, newFileName)};
				this.copyInstructions.push(copyInstruction);

				//change the filename and update the file path
				fanFile.fileName = newFileName;
				fanFile.fileInfo.name = newFileName;
				fanFile.fileInfo.path = this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, newFileName);

			} else {
				//create the copy instructions
				let copyInstruction = {source: fanFile.fileInfo.path, destination: this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, fanFile.fileName)};
				this.copyInstructions.push(copyInstruction);
				//update the file path
				fanFile.fileInfo.path = this.pathService.storage.resourceFolder.assetsFile.draft(resourceSubmission.guid, fanFile.fileName);
			}
		}
	}


}
