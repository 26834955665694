import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ResourceSubmission} from "../../../../../domain/resources/ResourceSubmission";
import {LearningAsset} from "../../../../../domain/resources/LearningAsset";
import {SupportedFileTypes} from "../../../../../helper/SupportedFileTypes";
import {ResourceSubmissionService} from "../../../../../services/ResourceSubmissionService";
import {WaihonaUser} from "../../../../../domain/user/WaihonaUser";
import {PublishedResourceService} from "../../../../../services/PublishedResourceService";
import {PublishedResource} from "../../../../../domain/resources/PublishedResource";
import {WaihonaUserRef} from "../../../../../domain/user/WaihonaUserRef";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastType} from "../../../../../services/common/NotificationService";
import {FanContributionModalComponent} from "../../../../modals/fan-contribution-modal/fan-contribution-modal.component";
import {IResourceFanMessage} from "../../../../../domain/resources/IResourceFanMessage";
import {DownloadService} from "../../../../../services/DownloadService";
import {Localization} from "../../../../../data/Localization";
import {BaseComponent} from "../../../../BaseComponent";
import {ResourcePublishStatus} from "../../../../../domain/resources/ResourcePublishStatus";
import {PublishState} from "../../../../../domain/resources/PublishState";
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, NavigationExtras} from "@angular/router";
import {ComposeMessageData} from "../../../../../domain/inbox/ComposeMessageData";
import {AddToCollectionModalComponent} from "../../../../modals/add-to-collection-modal/add-to-collection-modal.component";
import {ResourceSubmissionTextContent} from "../../../../../domain/resources/ResourceSubmissionTextContent";
import {Collection} from "../../../../../domain/collections/Collection";
import {CollectionService} from "../../../../../services/CollectionService";
import {CollectionRef} from "../../../../../domain/collections/CollectionRef";
import {ClipboardService} from "ngx-clipboard";
import {PermissionType} from "../../../../../domain/user/Permission";

@Component({
	selector: 'view-published-resource',
	templateUrl: './view-published-resource.component.html',
	styleUrls: ['./view-published-resource.component.scss'],
})
export class ViewPublishedResourceComponent extends BaseComponent implements OnInit {

	PublishState = PublishState;
	public cbContext = Localization.template.resource.detail;
	public CB = {
		not_yet_published: this.cbContext.publish_status.not_yet_published,
		version: this.cbContext.version,
		favorite: this.cbContext.buttons.favorite,
		unlock:this.cbContext.buttons.unlock,
		lock: this.cbContext.buttons.lock,
		edit: this.cbContext.buttons.edit,
		add_to_collection: this.cbContext.buttons.add_to_collection,
		collect: this.cbContext.buttons.collect,
		share: this.cbContext.buttons.share,
		download: this.cbContext.buttons.download,
		unpublish: this.cbContext.buttons.unpublish,
		contribute: this.cbContext.buttons.contribute,
		question: this.cbContext.buttons.question,
		related_header: this.cbContext.related.header,
	};

	public publishedResource:PublishedResource;
	public usersVersionOfResourceSubmission:ResourceSubmission;
	public isActivityStreamVisible:boolean = false;
	public fanContributionModal:BsModalRef;

	protected publishedResourceGuid:string = null;
	public publishStatus:ResourcePublishStatus = null;
	public _resourceNotYetPublishedToDatabase:boolean = null;
	public collections:Array<CollectionRef> = [];


	@ViewChild("addToCollectionModal")
	public addToCollectionModal: BsModalRef;

	constructor(protected injector:Injector,
	            private resourceSubmissionService:ResourceSubmissionService,
	            private publishedResourceService:PublishedResourceService,
	            private activatedRoute:ActivatedRoute,
	            private collectionService:CollectionService,
				private clipboardService:ClipboardService,
				protected downloadService:DownloadService) {
		super(injector);
		this.publishedResourceGuid = this.route.snapshot.params['resourceId'] != "" ? this.route.snapshot.params['resourceId'] : null;

		//get all collections that this resource belongs to and are visible by the user.
		if (this.publishedResourceGuid) {
			const s1:Subscription = this.trackSubscription(this.collectionService.getAllCollectionsContainingAResourceAndUserCanView$(this.publishedResourceGuid, this.currentUser)
				.subscribe((collectionPageResults:Collection[]) => {
					s1.unsubscribe();
					if (collectionPageResults) {
						this.collections = this.collectionService.convertCollectionToCollectionRefs(collectionPageResults);
						this.logger.log(`this resource belongs to ${this.collections.length} user-visible collections.`);
					}
				}));
		}

	}
	private updateUrlIfPublicIsNotAccurate():void {
		let isAPublicResource:boolean = this.publishedResource.resourceSubmission.configuration.allowPublicAccess;
		let currentUrl:string = this.router.url.toString();
		let isAtAPublicUrl:boolean = currentUrl.substring(0, "/resources/public/".length) == "/resources/public/";


		//If this is a public resource but our url doesnʻt indicate that...
		if(isAPublicResource && !isAtAPublicUrl) {
			let replacementUrl:string = currentUrl.replace("/resources/", "/resources/public/");
			console.log(`Replacing current url of ${currentUrl} with ${replacementUrl} because it is a public resource.`);
			this.router.navigate([replacementUrl], { replaceUrl: true });
		} else if(!isAPublicResource && isAtAPublicUrl) {
			let replacementUrl:string = currentUrl.replace("/resources/public/", "/resources/");
			console.log(`Replacing current url of ${currentUrl} with ${replacementUrl} because it is NOT a public resource.`);
			this.router.navigate([replacementUrl], {replaceUrl:true});
		}
	}

	public refreshComponent():void {
		this.cleanUpSubscriptions();
		this.publishedResource = null;
		this.publishedResourceGuid = this.route.snapshot.params['resourceId'] != "" ? this.route.snapshot.params['resourceId'] : null;

		this.ngOnInit();
	}
	ngOnInit() {

		this.getPublishedResource();
		this.setResourceNotYetPublishedAlert();
	}

	public get resourceNotYetPublishedToDatabase(): boolean {
		return this._resourceNotYetPublishedToDatabase;
	}

	public set resourceNotYetPublishedToDatabase(newValue:boolean) {
		this._resourceNotYetPublishedToDatabase = newValue;
		console.log("resourceNotYetPublishedToDatabase: " + this.resourceNotYetPublishedToDatabase);
	}
	public get isAbleToUnlockResources():boolean {
		return this.roleService.hasPermissionFor(PermissionType.unlock_resources);
	}

	private getPublishedResource() {
		let pubResourceSub:Subscription = this.trackSubscription(this.publishedResourceService.watch$(this.publishedResourceGuid).filter(publishedResource => publishedResource != null).subscribe(publishedResource => {
			this.publishedResource = publishedResource;
			this.updateUrlIfPublicIsNotAccurate();

			this.setResourceNotYetPublishedAlert();
			this.usersVersionOfResourceSubmission = publishedResource.resourceSubmission;

			let pubStatusSub:Subscription = this.publishedResourceService.watchPublishStatus$(publishedResource.resourceSubmission).subscribe((pubStatus:ResourcePublishStatus) => {
				if (pubStatus) {
					this.publishStatus = pubStatus;
					let status = pubStatus.status;
					if (status.files === PublishState.success && this.resourceNotYetPublishedToDatabase === false) {
						console.log("getting updated cover image");
					}
					if (status.document === PublishState.success
						&& status.files === PublishState.not_started
						&& status.archive === PublishState.not_started) {
						this.resourceNotYetPublishedToDatabase = false;
						pubResourceSub.unsubscribe();
					} else if (
						// if every subStatus is success, or if any subStatus has an error, then publish is finished, can unsubscribe from publish status
						Object.values(status).every((subStatus) => subStatus === PublishState.success) ||
						Object.values(status).includes(PublishState.error)
					) {
						pubStatusSub.unsubscribe();
					}
				} else {
					pubStatusSub.unsubscribe();
				}
			});
			this.trackSubscription(pubStatusSub);
		}));
	}

	private setResourceNotYetPublishedAlert() {
		let pendingPubs = this.publishedResourceService.pendingPublishes ? this.publishedResourceService.pendingPublishes : null;
		if (pendingPubs) {
			let resourcePubsInProgress = pendingPubs[this.publishedResourceGuid];
			if (resourcePubsInProgress) {
				this.resourceNotYetPublishedToDatabase = true;
			} else {
				this.resourceNotYetPublishedToDatabase = false;
			}
		} else {
			this.resourceNotYetPublishedToDatabase = false;
		}
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	};
	public get ResourceSubmissionTextContent():(new()=>{}) {
		return ResourceSubmissionTextContent;
	}

	public get currentUser():WaihonaUser {
		return this.authService.currentUser;
	}

	public get submitter():WaihonaUserRef {
		return this.publishedResource.resourceSubmission.submitter;
	}

	/** returns true if a user can unpublish a resource */
	public get canUnpublish():boolean {
		if(!this.currentUser) {
			return false;
		}
		return this.roleService.hasGeneralOrACLPermissionsFor.resources.publishedResource.unpublish(this.publishedResource);
	}

	public get canEdit():boolean {
		if(!this.currentUser) {
			return false;
		}
		return this.roleService.hasGeneralOrACLPermissionsFor.resources.resourceSubmission.edit(this.publishedResource.resourceSubmission);
	}

	public get isOwner():boolean {
		return this.currentUser ? (this.currentUser.guid == this.submitter.guid) : false;
	}

	public get TDContext() {
		return this.localizationService.loc.resource.view;
	}

	public get isCollaborator():boolean {
		if(!this.currentUser) {
			return false;
		}
		return this.publishedResource.resourceSubmission.collaborators.find(collaborator => {
			return this.currentUser.guid == collaborator.guid;
		}) != null;
	}

	public get isReviewer():boolean {
		if(!this.currentUser) {
			return false;
		}
		return this.publishedResource.reviewers.find(reviewer => {
			return this.currentUser.guid == reviewer.guid;
		}) != null;
	}

	/**  */
	public getFileName(learningAsset:LearningAsset):string {
		const path:string = "/assets/img/fileTypeIcons/";
		if (SupportedFileTypes.supportedIcons.indexOf(learningAsset.extension) != -1) {
			const extension:string = learningAsset.extension.toLowerCase();
			return path + extension + ".png";
		} else {
			return path + "_blank.png";
		}
	}

	//TODO: put this function in download service or something so code is not duplicated
	public downloadResourceZip($event) {
		$event.preventDefault();
		let url = this.getZipLocation();
		try {
			this.downloadService.tryDownload(url).take(1).subscribe((response) => {
				if (response === "File Not Found") {
					this.notificationService.displayToast(Localization.template.resource.common_toasts.download.problem, ToastType.error);
				}
			});
		} catch (err) {
			console.error(err);
		}
	}

	public getZipLocation():string {
		return this.urlService.resourceZip.published(this.publishedResource.guid, this.publishedResource.version.major.toString());
	}

	public openFanContributionModal():void {
		console.info(`opening the fan contribution modal`);

		//set interface message
		let user:WaihonaUserRef;
		if (this.currentUser != null) {
			user = this.authService.currentUserRef;
		} else {
			user = null;
		}
		let identity:IResourceFanMessage = {
			resourceSubmission: this.publishedResource.resourceSubmission,
			fanRef: user
		};
		//open modal with interface message
		this.fanContributionModal = this.notificationService.displayModal(FanContributionModalComponent, self, identity);
	}

	public unpublish() {
		this.publishedResourceService.unpublish(this.publishedResource);
		this.notificationService.displayToast(Localization.template.resource.common_toasts.unpublish, ToastType.info);

		this.router.navigate(["/resources"]);
	}

	public onSendMessageClick() {
		//todo: use the ComposeComponentHelperService instead of specifying the state here
		let url:string = this.urlService.resourcePage.published(this.publishedResource.guid);
		let data:ComposeMessageData = new ComposeMessageData();
			data.to = [this.publishedResource.resourceSubmission.submitter];

			let resourceLocatization:ResourceSubmissionTextContent = this.localizationService.LocalizeTools.document(this.publishedResource.resourceSubmission, ResourceSubmissionTextContent);
			data.subject = `Your resource: ${resourceLocatization.title}`;
			data.body = `Aloha, I have a question or comment about your resource at ${url}:\n\n`;
			data.redirectParams = ["/resources", this.publishedResource.guid];
		let  navigationExtras:NavigationExtras = {
				state: {
					composeMessageData: data
				}
			};
		this.router.navigate(["/inbox/compose"], navigationExtras);
	}

	public onRelatedResourceClick(resourceGuid:string):void {
		this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.refreshComponent();
			}
		});

		let navCommands = ['/resources', resourceGuid];
		let navExtras = { relativeTo: this.activatedRoute };
		this.navHistoryService.saveStateAndNavigate({selectedId: resourceGuid}, navCommands, navExtras);
	}
	public generateShareLink():void {
		console.info(`Generating share link.`);
		//is it a public resource?
		let url:string = `#${this.router.url}`;
		let baseUrl:string = this.urlService.baseUrl();
		let shareUrl = new URL(url, baseUrl);
		shareUrl.protocol = window.location.protocol;

		this.clipboardService.copy(shareUrl.toString());

		if(this.publishedResource.resourceSubmission.configuration.allowPublicAccess) {
			this.notificationService.displayToast(this.TDContext.share_clipboard_copy.toast.acknowledgement, ToastType.success);
		} else {
			this.notificationService.displayToast(this.TDContext.share_clipboard_copy.toast.acknowledgement_not_public, ToastType.success);
		}

	}



	public onShareLinkClick(event:MouseEvent):void {
		this.generateShareLink();
	}

	public onClickAddToCollection() {
		//open "add to collection" modal
		this.addToCollectionModal = this.notificationService.displayModal(AddToCollectionModalComponent, self, {resourceToAdd: this.publishedResource});
	}
	public get ToastContext() {
		return this.localizationService.loc.resource.list_published.toasts;
	}

	public onToggleLockStatusClick(resource:PublishedResource):void {
		let previousValueAllowsPublicAccess:boolean = resource.resourceSubmission.configuration.allowPublicAccess;
		resource.resourceSubmission.configuration.allowPublicAccess = !resource.resourceSubmission.configuration.allowPublicAccess;
		this.logger.info(`An admin set the configuration for resource ${resource.guid} allowPublicAccess: ${resource.resourceSubmission.configuration.allowPublicAccess}`);
		this.publishedResourceService.toggleLockStatus(resource.guid, resource.resourceSubmission.configuration.allowPublicAccess);
		if(resource.resourceSubmission.configuration.allowPublicAccess) {
			this.notificationService.displayToast(this.ToastContext.resource_unlocked, ToastType.success);
		} else {
			this.notificationService.displayToast(this.ToastContext.resource_locked, ToastType.success);
		}
	}

}

