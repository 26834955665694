<ng-container *ngIf="content">
	<div bsModal #fanContributionModal="bs-modal" class="modal-content custom-modal" style="width: 60vw; border: none">
		<div class="modal-header bg-info" style="width: 100%;">
			<h4 class="modal-title" data-localize="Contribute Resource Content">{{CB.title | localize | async}}</h4>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{CB.mahalo | localize | async}}</p>
			<form>
				<div *ngIf="fanContribution.fanRef" class="form-group form-check">
					<input type="checkbox" class="form-check-input" id="proxyCheckbox" name="proxyCheckbox" [(ngModel)]="fanContribution.isProxy">
					<label class="form-check-label" for="proxyCheckbox">{{CB.proxy | localize | async}}</label>
				</div>

				<div *ngIf="fanContribution.isProxy || fanContribution.fanRef == null">
					<div class="row">
						<div class="col-sm-6" waih-validation-color field="name">
							<label for="anonName" class="sr-only">Name</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">{{CB.name | localize | async}}</div>
								</div>
								<input type="text" [(ngModel)]="fanContribution.name"
									   class="col-auto form-control" id="anonName" name="anonName" data-localize="name"
									   placeholder="{{CB.name_placeholder | localize | async}}">
								<waih-validation-text field="name"></waih-validation-text>
							</div>
						</div>
						<div class="col-sm-6" waih-validation-color field="email">
							<label for="email" class="sr-only">Email</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">{{CB.email | localize | async}}</div>
								</div>
								<input type="email" [(ngModel)]="fanContribution.email" class="col-auto form-control"
									   id="email" name="email"
									   placeholder="{{CB.email_placeholder | localize | async}}">
								<waih-validation-text field="email"></waih-validation-text>
							</div>
						</div>
					</div>
				</div>
				<br>
				<div class="form-group" waih-validation-color field="comment">
					<label for="comment">{{CB.comment | localize | async}}</label>
					<textarea [(ngModel)]="fanContribution.comment"
							  class="form-control" id="comment" name="comment"
							  waih-validation-color field="comment"
							  rows="2"></textarea>
					<waih-validation-text field="comment"></waih-validation-text>
				</div>
				<br>
			</form>

			<!--uploader component-->
			<div waih-validation-color field="files">
				<h6>{{CB.uploader | localize | async}}</h6>
				<lesson-assets-component
						[assets]="fanContribution.files"
						[resourceSubmission]="resourceSubmission"
						[uploadPath]="assetsFolder"
						(onFileChange)="onAssetsChanged()">
				</lesson-assets-component>
				<waih-validation-text field="files"></waih-validation-text>
			</div>
		</div>

		<div class="modal-footer border-0">
			<button type="button" class="btn btn-light" (click)="onCancel()">{{CB.cancel | localize | async}}</button>
			<button type="button" class="btn btn-primary" (click)="onSubmit()">{{CB.submit | localize | async }}</button>
		</div>
	</div>
</ng-container>
