import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import {AngularFirestoreModule} from "@angular/fire/firestore";

import {TreeModule} from "angular-tree-component";
import {NgSelectModule} from "@ng-select/ng-select";

import {ListBadgesComponent} from "./multiple/list-badges/list-badges.component";
import {BadgeDetailComponent} from "./single/badge-detail/badge-detail.component";
import {BadgeEditComponent} from "./single/badge-edit/badge-edit.component";
import {BadgesRoutingModule} from "./badges-routing.module";
import {BadgeService} from "../../services/BadgeService";
import {QuillModule} from "ngx-quill";
import {ImageCropperModule} from "ngx-image-cropper";
import {SharedModule} from "../shared/shared.module";
import {ComponentCommonModule} from "../common/component-common.module";
import { BadgeAwardDisplayComponent } from './badge-award-display/badge-award-display.component';

@NgModule({
	imports:[
		CommonModule,
		QuillModule,
		ImageCropperModule,
		BadgesRoutingModule,
		NgSelectModule,
		FormsModule,
		TreeModule.forRoot(),
		AngularFirestoreModule,
		SharedModule,
		ComponentCommonModule,
	],
	declarations:[
		ListBadgesComponent,
		BadgeDetailComponent,
		BadgeEditComponent,
		BadgeAwardDisplayComponent
	],
	exports:[
		BadgeAwardDisplayComponent
	],
	providers:[
		BadgeService
	]
})

export class BadgesModule { }
