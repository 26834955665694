<div class="mt-3">
	<form class="form-inline p-0" autocomplete="off">
		<label for="box" class="sr-only">{{CB.prompt | localize | async}}</label>
		<input #box type="text" class="form-control ml-1" id="box"
			   [attr.placeholder]="CB.placeholder | localize | async">
		<button type="submit" class="bg-primary m-0" style="padding: 6px"
				(click)="onEnter(box.value); box.form.reset()">{{CB.send | localize | async}}</button>
	</form>
</div>
<div class="p-2">
	<ng-container *ngIf="chat != null && chat.messages.length > 0; else no_chat_content">
		<ng-container *ngFor="let message of chat.messages | reverse; let isLast=last ">
			<div class="message clearfix">
				<div class="mt-3 mb-2 mr-2 float-left">
					<div class="avatar">
						<avatar-component [userRef]="message.from" [class]="'select-image'"></avatar-component>
						<span class="avatar-status badge-success"></span>
					</div>
				</div>
				<div>
					<small class="text-truncate font-weight-bold">{{message.from.altFirstName ? message.from.altFirstName : message.from.firstName}}</small>
					<small class="text-muted float-right mt-1">{{toFancyDateString(message.created)}}</small>
				</div>
				<p class="small">{{message.text}}</p>
			</div>
			<hr *ngIf="!isLast" class="mb-0 mt-0">
		</ng-container>
	</ng-container>
	<ng-template #no_chat_content>
		<small class="text-muted">{{CB.none | localize | async}}</small>
	</ng-template>
</div>
