import {Badge} from "../domain/badges/Badge";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {BadgesRepository} from "./repository/BadgesRepository";
import {LocalizationService} from "./LocalizationService";
import {NGXLogger} from "ngx-logger";
import {BadgeRef} from "../domain/badges/BadgeRef";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {BadgeAward} from "../domain/badges/BadgeAward";
import {WaihonaUserService} from "./WaihonaUserService";

@Injectable()
export class BadgeService {

	constructor(public repo:BadgesRepository,
	            private localizationService:LocalizationService,
	            private waihonaUserService:WaihonaUserService,
	            protected logger:NGXLogger) {
	}

	public get store() {
		return this.repo.accessors();
	}

	public get LocalizeTools() {
		return this.localizationService.LocalizeTools;
	}

	/** Save a Badge */
	public save$(badge:Badge):Observable<Badge> {
		return this.repo.save$(badge);
	}

	/** List all Badges */
	public listBadges$(limit?:number):Observable<Badge[]> {
		return this.repo.list$();
	}

	/** List all Badges as Refs */
	public listBadgeRefs$(limit?:number):Observable<BadgeRef[]> {
		return this.repo.listAsRef$();
	}

	/** Get a single Badge */
	public getBadgeById$(guid: string):Observable<Badge> {
		return this.repo.get$(guid);
	}

	public awardBadgeToUser$(badgeRef:BadgeRef, waihonaUserRef:WaihonaUserRef, awardLevel?:number):Observable<WaihonaUser> {
		let badgeAward:BadgeAward = new BadgeAward;
		badgeAward.badgeRef = badgeRef;
		awardLevel ? badgeAward.awardLevel = awardLevel : badgeAward.awardLevel = 1;
		badgeAward.awardDate = new Date().toLocaleDateString();
		return this.waihonaUserService.addBadgeAwardToUser$(badgeAward, waihonaUserRef.guid);
	}

}
