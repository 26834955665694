import {Converter} from "../../ConversionService";
import {EventMapping, EventMappingRef} from "../../../areas/admin/email/email-event-mapper/email-domains";

export class EventMappingToEventMappingRefConverter implements Converter {

	public from:typeof EventMapping;
	public to:typeof EventMappingRef;

	constructor() {
		this.from = EventMapping;
		this.to = EventMappingRef;
	}

	public convert(source:EventMapping):EventMappingRef {
		//Create the target object
		let eventMappingRef:EventMappingRef = new EventMappingRef();
			eventMappingRef.guid = source.guid;

		return eventMappingRef;
	}
}
