import {Toast, ToasterService} from "angular2-toaster";
import {EventEmitter, Injectable} from "@angular/core";
import {LocalizationService} from "../LocalizationService";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Subscription} from 'rxjs';
import {IModal} from "../../areas/modals/IModal";
import {NGXLogger} from "ngx-logger";

@Injectable({
    providedIn: 'root',
} as any)
export class NotificationService {

    public static MODAL_DEFAULT_INITIAL_STATE = {
        backdrop: 'static',
        animated: true
    };

    public modalRef: BsModalRef;
    public subscriptions: Subscription[] = [];
    public messages: string[] = [];

    // @ViewChild()
    constructor(private localizationService: LocalizationService,
                private toasterService: ToasterService,
                private modalService:BsModalService,
                protected logger:NGXLogger) {


    }

    public onHide():EventEmitter<any> {
      return this.modalService.onHide;
    }

    /** Displays a component using a Modal Class, the component, and any properties to set the component with, by default has a pretty good initial state ... or you can pass one in */
    public displayModal<PropertyType=any>(ComponentClass:any, componentHandler:any, properties?:PropertyType, initialState:any = NotificationService.MODAL_DEFAULT_INITIAL_STATE):BsModalRef {
        if(properties == null) {
            properties = {} as any;
        }
        this.subscriptions.push(
            this.modalService.onShow.subscribe((reason: string) => {
                this.messages.push(`onShow event has been fired`);
                this.logger.info(`onShow event has been fired`);
            })
        );
        this.subscriptions.push(
            this.modalService.onShown.subscribe((reason: string) => {
                this.messages.push(`onShown event has been fired`);
                this.logger.info(`onShown event has been fired`);
            })
        );
        this.subscriptions.push(
            this.modalService.onHide.subscribe((reason: string) => {
                const _reason = reason ? `, dismissed by ${reason}` : '';
                this.messages.push(`onHide event has been fired${_reason}`);
                this.logger.info(`onHide event has been fired${_reason}`);
            })
        );
        this.subscriptions.push(
            this.modalService.onHidden.subscribe((reason: string) => {
                const _reason = reason ? `, dismissed by ${reason}` : '';
                this.messages.push(`onHidden event has been fired${_reason}`);
                this.logger.info(`onHidden event has been fired${_reason}`);
                this.unsubscribe();
            })


        );
        let modalRef:BsModalRef = this.modalService.show(ComponentClass, initialState);

        //Set the properties on the modal instance the same as what was sent in in object form
        let copy:any = {};
        let keys:Array<string> = Object.keys(properties);
        let hasProperties:boolean = keys.length > 0;

        if(hasProperties) {
            keys.forEach(
                key => {
                    let value:any = properties[key];
                    copy[key] = value;
                }
            );

            try {
                (modalRef.content as IModal).onProperties(copy);
            } catch(error) {
                this.logger.error("NotificationService had properties but did not implement IModal")
            }
        }

        //set the component handler
        modalRef.content.component = componentHandler;
        return modalRef;
    }

    public hideAll():void {
        this.modalService.hide(0);
    }

    public displayToast(pathObject:IPathObject, type:ToastType, params = {}) {
        let title = this.localizationService.get(pathObject.title, null, params);
        let body = this.localizationService.get(pathObject.body, null, params);

        const toast:Toast = {
            type: type,
            title: title,
            body: body,
            showCloseButton: true
        };

        this.toasterService.pop(toast);
        return toast;
    }

    unsubscribe() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }


}
export interface IPathObject {
    title:{en?:string|((any)=>string), haw?:string|((any)=>string)};
    body:{en?:string|((any)=>string), haw?:string|((any)=>string)};
}

export enum ToastType {
    "warning"="warning",
    "error"="error",
    "success"="success",
    "info"="info"
}
