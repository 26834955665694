import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";

@Injectable({
    providedIn: 'root',
} as any)
export class ScrollingService {

    constructor(protected logger:NGXLogger) {
    }

	public scrollTo(id:string, scrollOptions?:ScrollIntoViewOptions) {
		let defaultScrollOptions:ScrollIntoViewOptions = {behavior: 'smooth', block: 'center', inline: 'center'};
		scrollOptions = Object.assign(defaultScrollOptions, scrollOptions);
		document.getElementById(id).scrollIntoView(scrollOptions);
	}

}
