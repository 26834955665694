import {IOption} from 'ng-select';
export enum CategoryForIOption {
	AssetTypes = 'Asset',
	SubjectCategories = 'Subject',
	SpecialIndicators = 'Special',
	HawaiianCategories = 'Hawaiian',
	LearningModalities = 'Modality',
	GradeOptions = 'Grade',
	Framework = 'Framework',
	Languages = 'Language',
	Contributors = 'Contributor',
	Text = 'Text',
	Aina = 'Aina',

}
export interface ICategorizedIOption extends IOption {
	category:CategoryForIOption;
	searchLabel?:string;
	data?:any;
}

export let LanguageTypes:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.Languages, label: "English", value: "en"},
	{category: CategoryForIOption.Languages, label: "ʻŌlelo Hawaiʻi", value: "haw"}
];

export let AssetTypes:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.AssetTypes, label: "Activity Guide", value: "Activity Guide"},
	{category: CategoryForIOption.AssetTypes, label: "Assessment", value: "Assessment"},
	{category: CategoryForIOption.AssetTypes, label: "Audio", value: "Audio"},
	{category: CategoryForIOption.AssetTypes, label: "Chart", value: "Chart"},
	{category: CategoryForIOption.AssetTypes, label: "Diagram", value: "Diagram"},
	{category: CategoryForIOption.AssetTypes, label: "Digital Tool", value: "Digital Tool"},
	{category: CategoryForIOption.AssetTypes, label: "Exam/Quiz", value: "Exam/Quiz"},
	{category: CategoryForIOption.AssetTypes, label: "Flash Cards", value: "Flash Cards"},
	{category: CategoryForIOption.AssetTypes, label: "Game", value: "Game"},
	{category: CategoryForIOption.AssetTypes, label: "Graphic Organizer", value: "Graphic Organizer"},
	{category: CategoryForIOption.AssetTypes, label: "Handout", value: "Handout"},
	{category: CategoryForIOption.AssetTypes, label: "Homework", value: "Homework"},
	{category: CategoryForIOption.AssetTypes, label: "Image", value: "Image"},
	{category: CategoryForIOption.AssetTypes, label: "Independent Practice", value: "Independent Practice"},
	{category: CategoryForIOption.AssetTypes, label: "Lesson Plan", value: "Lesson Plan"},
	{category: CategoryForIOption.AssetTypes, label: "Map", value: "Map"},
	{category: CategoryForIOption.AssetTypes, label: "Presentation", value: "Presentation"},
	{category: CategoryForIOption.AssetTypes, label: "Project Based Learning", value: "Project Based Learning"},
	{category: CategoryForIOption.AssetTypes, label: "Rubric", value: "Rubric"},
	{category: CategoryForIOption.AssetTypes, label: "Student Sample", value: "Student Sample"},
	{category: CategoryForIOption.AssetTypes, label: "Study Guide", value: "Study Guide"},
	{category: CategoryForIOption.AssetTypes, label: "Teacher Instructions", value: "Teacher Instructions"},
	{category: CategoryForIOption.AssetTypes, label: "Team-based Learning", value: "Team-based Learning"},
	{category: CategoryForIOption.AssetTypes, label: "Test Prep/Review", value: "Test Prep/Review"},
	{category: CategoryForIOption.AssetTypes, label: "Text/Publication", value: "Text/Publication"},
	{category: CategoryForIOption.AssetTypes, label: "Unit Plan", value: "Unit Plan"},
	{category: CategoryForIOption.AssetTypes, label: "Video", value: "Video"},
	{category: CategoryForIOption.AssetTypes, label: "Video Guide", value: "Video Guide"},
	{category: CategoryForIOption.AssetTypes, label: "Vocabulary", value: "Vocabulary"}
];

export let SubjectCategories:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.SubjectCategories, label: "Arts", value: "Arts"},
	{category: CategoryForIOption.SubjectCategories, label: "Career and Technical Education", value: "Career and Technical Education"},
	{category: CategoryForIOption.SubjectCategories, label: "Guidance and Counseling", value: "Guidance and Counseling"},
	{category: CategoryForIOption.SubjectCategories, label: "Health", value: "Health"},
	{category: CategoryForIOption.SubjectCategories, label: "Language Arts", value: "Language Arts"},
	{category: CategoryForIOption.SubjectCategories, label: "Math", value: "Math"},
	{category: CategoryForIOption.SubjectCategories, label: "Physical Education", value: "Physical Education"},
	{category: CategoryForIOption.SubjectCategories, label: "Science", value: "Science"},
	{category: CategoryForIOption.SubjectCategories, label: "Social and Emotional Learning", value: "Social and Emotional Learning"},
	{category: CategoryForIOption.SubjectCategories, label: "Social Studies", value: "Social Studies"}
];

export let LanguageOptions:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.SubjectCategories, label: "English", value: "English"},
	{category: CategoryForIOption.SubjectCategories, label: "Career and Technical Education", value: "Career and Technical Education"},
	{category: CategoryForIOption.SubjectCategories, label: "Guidance and Counseling", value: "Guidance and Counseling"},
	{category: CategoryForIOption.SubjectCategories, label: "Health", value: "Health"},
	{category: CategoryForIOption.SubjectCategories, label: "Language Arts", value: "Language Arts"},
	{category: CategoryForIOption.SubjectCategories, label: "Math", value: "Math"},
	{category: CategoryForIOption.SubjectCategories, label: "Physical Education", value: "Physical Education"},
	{category: CategoryForIOption.SubjectCategories, label: "Science", value: "Science"},
	{category: CategoryForIOption.SubjectCategories, label: "Social and Emotional Learning", value: "Social and Emotional Learning"},
	{category: CategoryForIOption.SubjectCategories, label: "Social Studies", value: "Social Studies"}
];

export let SpecialIndicators:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.SpecialIndicators, label: "ʻŌlelo Hawaiʻi", value: "ʻŌlelo Hawaiʻi"},
	{category: CategoryForIOption.SpecialIndicators, label: "GT Adapted", value: "GT Adapted"},
	{category: CategoryForIOption.SpecialIndicators, label: "SPED Adapted", value: "SPED Adapted"}
];

export let HawaiianCategories:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.HawaiianCategories, label: "Hana Noʻeau/ʻOihana", value: "Hana Noʻeau/ʻOihana"},
	{category: CategoryForIOption.HawaiianCategories, label: "Honua/Lani", value: "Honua/Lani"},
	{category: CategoryForIOption.HawaiianCategories, label: "Kuana ʻIke", value: "Kuana ʻIke"},
	{category: CategoryForIOption.HawaiianCategories, label: "Lawena/Loina", value: "Lawena/Loina"},
	{category: CategoryForIOption.HawaiianCategories, label: "Mauli Ola", value: "Mauli Ola"},
	{category: CategoryForIOption.HawaiianCategories, label: "Moʻolelo/Mele", value: "Moʻolelo/Mele"},
	{category: CategoryForIOption.HawaiianCategories, label: "Nohona", value: "Nohona"},
	{category: CategoryForIOption.HawaiianCategories, label: "ʻŌlelo", value: "ʻŌlelo"}
];

export let LearningModalities:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.LearningModalities, label: "Kino", value: "Kino"},
	{category: CategoryForIOption.LearningModalities, label: "Lolo", value: "Lolo"},
	{category: CategoryForIOption.LearningModalities, label: "Maka", value: "Maka"},
	{category: CategoryForIOption.LearningModalities, label: "Pepeiao", value: "Pepeiao"},
	{category: CategoryForIOption.LearningModalities, label: "Waha", value: "Waha"},
	{category: CategoryForIOption.LearningModalities, label: "Ihu", value: "Ihu"},
	{category: CategoryForIOption.LearningModalities, label: "ʻAlelo", value: "ʻAlelo"},
	{category: CategoryForIOption.LearningModalities, label: "Naʻau", value: "Naʻau"}

];


export let GradeOptions:Array<ICategorizedIOption> = [
	{category: CategoryForIOption.GradeOptions, label: "Not Grade Specific", value: "Not Grade Specific"},
	{category: CategoryForIOption.GradeOptions, label: "Preschool", value: "Preschool"},
	{category: CategoryForIOption.GradeOptions, label: "Kindergarten", value: "Kindergarten"},
	{category: CategoryForIOption.GradeOptions, label: "First Grade", value: "First Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Second Grade", value: "Second Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Third Grade", value: "Third Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Fourth Grade", value: "Fourth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Fifth Grade", value: "Fifth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Sixth Grade", value: "Sixth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Seventh Grade", value: "Seventh Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Eighth Grade", value: "Eighth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Ninth Grade", value: "Ninth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Tenth Grade", value: "Tenth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Eleventh Grade", value: "Eleventh Grade"},
	{category: CategoryForIOption.GradeOptions, label: "Twelfth Grade", value: "Twelfth Grade"},
	{category: CategoryForIOption.GradeOptions, label: "College/University", value: "College/University"},
	{category: CategoryForIOption.GradeOptions, label: "Professional Development", value: "Professional Development"}
];

export interface IOptionPlus extends IOption {
	value:string;
	label:string;
	disabled?:boolean;
	color:string;
}
