import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({providedIn: "root"})
export class HttpHelperUtils {

	public fileExists(url:string):Observable<boolean> {
		let fileExistsObservable:Subject<boolean> = new Subject<boolean>();
		fetch(url, {
			method: 'HEAD'
		}).then((response) => {
			if (response.status >= 200 && response.status < 300 && response.ok) {
				fileExistsObservable.next(true);
			} else {
				fileExistsObservable.next(false);
			}
		}).catch((error) => Error(error));
		return fileExistsObservable;
	}

}