<div class="loading-items-card card text-primary my-lg-3 my-sm-1 bg-transparent border-0">
	<ng-container *ngIf="itemsLoading; else noItems">
		<div class="card-body">
			<div class="spinner-container">
				<span class="spinner-border mx-5" role="status"></span>
			</div>
			<h4 class="card-title font-weight-bold">{{CB.loading | localize | async}}</h4>
		</div>
	</ng-container>
	<ng-template #noItems>
		<div id="no-items-found">
			<h5 class="card-title font-weight-bold">{{CB.no_items | localize:{'listType': listType} | async}}</h5>
		</div>
	</ng-template>
</div>
