import { Component, OnInit } from '@angular/core';
import {Localization} from "../../../data/Localization";
import {CommunicationService} from "../../../services/CommunicationService";
import {AuthService} from "../../../services/AuthService";
import {LogMonitoringService} from "../../../services/logging/LogMonitoringService";
import {BsModalRef} from "ngx-bootstrap/modal";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {

	private cbContext =  Localization.template.app_footer;
	public CB = {
		modal_title: this.cbContext.modal.title,
		modal_body: this.cbContext.modal.body,
		modal_placeholder: this.cbContext.modal.placeholder,
		modal_send: this.cbContext.modal.send,
		modal_cancel: Localization.template.buttons.cancel
	};

	public feedback:string = "";

    constructor(public bsModalRef:BsModalRef,
    	private communicationService: CommunicationService,
	    private authService:AuthService,
	    private logMonitoringService:LogMonitoringService,
	    protected logger:NGXLogger
    ) { }

	ngOnInit(): void {
	    this.feedback = "";
    }

	public onCancel():void {
		console.info(`user clicked on cancel button`);
		this.bsModalRef.hide();
	}

	public onSubmitFeedback():void {
		let logs:string = this.logMonitoringService.getLogs();
		let userGuid:string = (this.authService.currentUser == null) ? "Anonymous User" :  this.authService.currentUser.guid;

		this.logger.info("Just Got these logs: " + logs);
		if (this.feedback != "" && this.feedback != null) {
			this.communicationService.sendFeedback(userGuid, this.feedback, logs);
			this.feedback = "" //clear the form
		} else {
			console.warn("an empty message was submitted but not posted");
		}

		this.bsModalRef.hide();
	}

}
