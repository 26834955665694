import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {Localization} from "../../../data/Localization";
import {ImageCroppedEvent, ImageCropperComponent} from "ngx-image-cropper";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";
import {LocalizationService} from "../../../services/LocalizationService";
import {Observable, Subject} from "rxjs";
import {NGXLogger} from "ngx-logger";

@Component({
	selector: 'app-cropper',
	templateUrl: './cropper.component.html',
	styleUrls: ['./cropper.component.scss']
})
export class CropperComponent extends SubscriptionCleaner implements OnInit, OnChanges {

	private cbContext = Localization.template.common.cropper;
	public CB = {
		current: this.cbContext.headers.current_image,
		upload: this.cbContext.headers.upload_image,
		crop: this.cbContext.headers.crop_image,
		saving: Localization.template.buttons.saving,
		loading: Localization.template.buttons.loading,
		save_image: Localization.template.buttons.save_Image,
		cancel: Localization.template.buttons.cancel,
	};

	@ViewChild(ImageCropperComponent)
	public imageCropper:ImageCropperComponent;

	public cropper= {
		hasImage: false, //whether or not the object has an existing image in storage
		imageUrl: "", //the stored image url
		file: null, //the user-selected picture file
		croppedImage: null, //current base64 image in the cropper
	};

	@Output()
	public imageUpdated:EventEmitter<number> = new EventEmitter<number>();

	@Output() /** When the user clicks the save button emit the base 64 value of the cropper component */
	public save:EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public failed:EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public imageExists:EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	public url:string = "";

	@Input()
	public cropperMode:CropperMode = CropperMode.no_image;

	constructor(private logger:NGXLogger,
				protected localizationService:LocalizationService) {
		super();
		this.localizationService.registerAndLocalize("CropperComponent", this.CB);
	}

	ngOnInit():void {
		if(this.url) {
			this.loadImage();
		}
	}
	ngOnChanges(changes:SimpleChanges):void {
		this.loadImage()
	}

	public get CropperMode() {
		return CropperMode;
	}

	protected loadImage():void {
		this.trackSubscription(this.fileExists(this.url).filter(result => result).subscribe(result => {
			this.logger.info(`Cropper component has verified the existing image and is loading the cropper component.`);
			this.cropperMode = CropperMode.original_image;
			this.cropper.imageUrl = this.url;
			this.cropper.hasImage= true;
			this.imageExists.emit(true);
		}));
	}

	protected fileExists(url:string):Observable<boolean> {
		let fileExistsObservable:Subject<boolean> = new Subject<boolean>();
		fetch(url, {
			method: 'HEAD'
		}).then((response) => {
			if (response.status >= 200 && response.status < 300 && response.ok) {
				fileExistsObservable.next(true);
				fileExistsObservable.complete();
			} else {
				fileExistsObservable.next(false);
				fileExistsObservable.complete();
			}
		}).catch((error) => Error(error));
		return fileExistsObservable;
	}
	
	public onCancel():void {
		this.logger.info(`the user cancelled the image`);
		this.cropper.file = null; //we don't need a file because we are cancelling
		this.cropper.croppedImage = null; //we don't need a base64 either
		if(this.cropper.hasImage) {
			this.cropperMode = CropperMode.original_image;
		} else {
			this.cropperMode = CropperMode.no_image;
		}
	}

	public onFileChangeEvent(event:any):void {
		this.logger.info(`the user selected an image to crop`);
		this.cropper.file = event;
		this.cropperMode = CropperMode.cropping_image;
	}

	public onSaveClick():void {
		this.logger.info(`emitting save message`);
		this.save.emit(this.cropper.croppedImage);
		this.cropperMode = CropperMode.new_image;
	}

	public onImageCropped(event:ImageCroppedEvent) {
		this.logger.info(`cropping...`);
		this.cropper.croppedImage = event.base64;
	}

	public onLoadImageFailed() {
		this.logger.info(`image load failed`);
		this.failed.emit(true);
	}

}

export enum ImageType {
	badge = "badge",
	organization = "organization",
	collection = "collection",
	draft = "draft",
	avatar = "avatar"
}
export enum CropperMode {
	no_image = "no_image",
	original_image = "original_image",
	new_image = "new_image",
	cropping_image = "cropping_image",
}
