import {Injectable} from "@angular/core";
import {InboxStubService} from "./data/InboxStub";
import {combineLatest, Observable, Subject} from "rxjs";
import {InboxRepository} from "./repository/InboxRepository";
import {AuthService} from "./AuthService";
import {classToClass} from "class-transformer";
import {WaihonaUserRef} from "../domain/user/WaihonaUserRef";
import {SentRepository} from "./repository/SentRepository";
import {MessageRef} from "../domain/inbox/MessageRef";
import {InboxFilterType} from "../domain/inbox/InboxFilterType";
import {PageListService} from "./PageListService";


@Injectable({
	providedIn: "root"
})
export class InboxService {


	private _paging:{
		inbox:PageListService<MessageRef>,
		sent:PageListService<MessageRef>
	};

	public paging = {
		pageSize: 5,
		inbox: {
			cursor: () => {
				return this._paging.inbox.pageCursor;
			},
			initialize: {
				specify$:(byType:InboxFilterType) =>  this._paging.inbox.initializePaging$(this.inboxRepository.by.criteria.filterType(byType), this.paging.pageSize, byType, this.authService.currentUser.guid),
				all$: ()=> this._paging.inbox.initializePaging$(this.inboxRepository.by.criteria.all(), this.paging.pageSize, null, this.authService.currentUser.guid),
				//by filter types
				regular$: () => this._paging.inbox.initializePaging$(this.inboxRepository.by.criteria.regular(), this.paging.pageSize, null, this.authService.currentUser.guid),
				important$: ()=> this.paging.inbox.initialize.specify$(InboxFilterType.important),
				trash$: ()=> this.paging.inbox.initialize.specify$(InboxFilterType.trash),
				unread$: ()=> this._paging.inbox.initializePaging$(this.inboxRepository.by.criteria.unread(), this.paging.pageSize, null, this.authService.currentUser.guid),
				starred$: ()=> this.paging.inbox.initialize.specify$(InboxFilterType.starred)
			},
			next:() => {
				this._paging.inbox.getNextPage(this.authService.currentUser.guid);
			},
			hasMore:() => {
				return this._paging.inbox.pageCursor.nextPageExists;
			},
			isLoading: ()=> {
				return this._paging.inbox.pageLoading$.value;
			}
		},
		sent:{
			cursor: () => {
				return this._paging.sent.pageCursor;
			},
			initialize:{
				specify$:(byType:InboxFilterType) => this._paging.sent.initializePaging$(this.sentRepository.by.criteria.filterType(byType), this.paging.pageSize, byType, this.authService.currentUser.guid),
				all$:	() => this._paging.sent.initializePaging$(this.sentRepository.by.criteria.all(), this.paging.pageSize, null, this.authService.currentUser.guid),
				trash$:	() => this.paging.sent.initialize.specify$(InboxFilterType.trash),
				starred$: () => this.paging.sent.initialize.specify$(InboxFilterType.starred),
			},
			next:() => {
				this._paging.sent.getNextPage(this.authService.currentUser.guid);
			},
			isLoading: ()=> {
				return this._paging.sent.pageLoading$.value;
			}
		}
	}
	public incoming = {
		save$: (messageRef:MessageRef) => {
			return this.inboxRepository.save$(messageRef, this.authService.currentUser.guid);
		}
	}
	public outgoing = {
		save$: (messageRef:MessageRef) => {
			return this.sentRepository.save$(messageRef, this.authService.currentUser.guid);
		}
	}
	public by = () => {
		return {
			inbox: this.inboxRepository.by,
			sent: this.sentRepository.by
		};
	}


	constructor(private inboxStubService:InboxStubService,
	            protected inboxRepository:InboxRepository,
	            private authService:AuthService,
	            protected sentRepository:SentRepository) {

		this._paging = {
			inbox: new PageListService<MessageRef>(inboxRepository),
			sent: new PageListService<MessageRef>(sentRepository)
		}
		//Watch unread (TODO: Dont expand this whole thing/system is temporary)
		this.authService.currentUser$.filter(user => user != null).subscribe(user => {
			console.log("currentUser$ updated")
			this.inboxRepository.watchCounts$(user.guid).subscribe(countUnread => {
				console.log("InboxService update")
			});
		});

	}
	public get inboxStore() {
		return this.inboxRepository.compoundAccessors();
	}
	public get inboxStoreBy() {
		return this.inboxRepository.by;
	}
	public get sentStore() {
		return this.sentRepository.compoundAccessors();
	}
	public get sentStoreBy() {
		return this.sentRepository.by;
	}
	public get inboxCounts() {
		return this.inboxRepository.counts;
	}


	public sendMessageFromRef$(messageRef:MessageRef):Observable<MessageRef[]> {
		let messageRefs$:Subject<MessageRef[]> = new Subject();

		//TODO: Need validation here

		messageRef.from = this.authService.currentUserRef;
		messageRef.sent = new Date();
		messageRef.body = messageRef.body.split("\n").join("\n<br/>");

		let messageRefs:Array<Observable<MessageRef>> = [];
		for (const waihonaUserRef of messageRef.to) {
			let obs:Observable<MessageRef> = this.inboxRepository.save$(messageRef, waihonaUserRef.guid); //Every one going into a brand new inboxRepository location, gets their own message
			messageRefs.push(obs);
		}

		let sentMessage:MessageRef = classToClass<MessageRef>(messageRef);
			sentMessage.opened = new Date();
			sentMessage.sortingTags.push(InboxFilterType.sent);

		let sentMessage$:Observable<MessageRef> = this.sentRepository.save$(sentMessage, this.authService.currentUser.guid);
			messageRefs.push(sentMessage$);

		combineLatest(messageRefs).subscribe(messageRefsPau => {
			console.log("All messages have been sent");
			messageRefs$.next(messageRefsPau);
		});

		return messageRefs$.take(1);
	}

	public sendMessage(to:Array<WaihonaUserRef>, subject:string, text:string):Observable<MessageRef[]> {
		//TODO: Do validation...
		if (to == null || text == null || this.authService.currentUser == null) {
			console.error("Problem! Abort!");
		}
		let messageRef$:Subject<MessageRef[]> = new Subject();

		let messageRef:MessageRef = new MessageRef();
			messageRef.from = this.authService.currentUserRef;
			messageRef.to = to;
			messageRef.sent = new Date();
			messageRef.subject = subject;
			messageRef.body = text.split("\n").join("<br/>");


		let messageRefs:Array<Observable<MessageRef>> = [];
		for (const waihonaUserRef of to) {
			let obs:Observable<MessageRef> = this.inboxRepository.save$(messageRef, waihonaUserRef.guid); //Every one going into a brand new inboxRepository location, gets their own message
			messageRefs.push(obs);
		}

		let sendingMessage:MessageRef = classToClass<MessageRef>(messageRef);
			sendingMessage.opened = new Date();
			sendingMessage.sortingTags.push(InboxFilterType.sent);

		let sentMessage$:Observable<MessageRef> = this.sentRepository.save$(sendingMessage, this.authService.currentUser.guid);
			messageRefs.push(sentMessage$);

		combineLatest(messageRefs).subscribe(messageRefsPau => {
			console.log("All messages have been sent");
			messageRef$.next(messageRefsPau);
		});

		return messageRef$;
	}

	public updatePartial$(value:object):Observable<MessageRef> {
		return this.inboxRepository.updatePartial$(value, null, this.authService.currentUser.guid);
	}

	public getMessage$(guid:string):Observable<MessageRef> {
		return this.inboxRepository.get$(guid, false, this.authService.currentUser.guid);
	}

	public getSentMessage$(guid:string):Observable<MessageRef> {
		return this.sentRepository.get$(guid, false, this.authService.currentUser.guid);
	}

	/** Removes an item from trash */
	public untrashMessage(messageRef:MessageRef):void {
		console.log(`Inbox Service is restoring ${messageRef.guid} from trash`);
		messageRef.trashed = null;
		messageRef.sortingTags = messageRef.sortingTags.filter(tag => tag != InboxFilterType.trash);

		let isSentItem:boolean = messageRef.sortingTags.find(messageTag => messageTag == InboxFilterType.sent) != null;

		if(isSentItem) {
			this.sentRepository.save$(messageRef, this.authService.currentUser.guid);
		} else {
			this.inboxRepository.save$(messageRef, this.authService.currentUser.guid);
		}
	}

	/** Adds an item to trash */
	public trashMessage(messageRef:MessageRef):void {
		let hasMatch:boolean = messageRef.sortingTags.find(messageTag => {
			return messageTag == InboxFilterType.trash
		}) != null;

		if(!hasMatch) {
			console.log(`Inbox Service is trashing ${messageRef.guid}`);
			messageRef.trashed = new Date();
			messageRef.sortingTags.push(InboxFilterType.trash);
			let isSentItem:boolean = messageRef.sortingTags.find(messageTag => messageTag == InboxFilterType.sent) != null;

			if(isSentItem) {
				this.sentRepository.save$(messageRef, this.authService.currentUser.guid);
			} else {
				this.inboxRepository.save$(messageRef, this.authService.currentUser.guid);
			}
		}
	}
	/** In order to delete the message must have previously been sent to Trash */
	public delete$(messageRef:MessageRef):Observable<MessageRef> {
		let hasMatch:boolean = messageRef.sortingTags.find(messageTag => {
			return messageTag == InboxFilterType.trash
		}) != null;

		if(hasMatch) {
			let isSentItem:boolean = messageRef.sortingTags.find(messageTag => messageTag == InboxFilterType.sent) != null;

			if(isSentItem) {
				return this.sentRepository.delete$(messageRef, this.authService.currentUser.guid);
			} else {
				return this.inboxRepository.delete$(messageRef, this.authService.currentUser.guid);
			}
		}
		return Observable.of(null);
	}

}
