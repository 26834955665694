import {classToPlain, plainToClass, Transform, Type} from "class-transformer";
import {IsNotEmpty, MinLength} from "class-validator";
import {Role, RoleType} from "./Role";
import {WaihonaUserRef} from "./WaihonaUserRef";
import {OrganizationRef} from "../organization/OrganizationRef";
import {PublishedResourceRef} from "../resources/PublishedResourceRef";
import {Avatar} from "./Avatar";
import {RegisterClazz, RegisterType} from "../RegisterType";
import {WaihonaUserOrganization} from "./WaihonaUserOrganization";
import {IndexProperty} from "../IndexProperty";
import {WaihonaUserTextContent} from "./WaihonaUserTextContent";
import {ITextContentObject} from "../ITextContentObject";
import {DocumentTextContent} from "../DocumentTextContent";
import {IWaihonaUserMetadata} from "./IWaihonaUserMetadata";
import {generateTextIndex} from "../IndexProperty";
import {toClassDocTextTransformFactory, toPlainDocTextTransform} from "../../helper/DocTextContentHelper";
import {BadgeAward} from "../badges/BadgeAward";

function getPreferredFirstNameOf(target:any):string {
	return target["altFirstName"] ? "altFirstName" : "firstName";
}


@RegisterClazz(WaihonaUser, "Users")
export class WaihonaUser implements ITextContentObject<WaihonaUserTextContent> {

	public guid:string = null;

	public metadata:IWaihonaUserMetadata;

	@IndexProperty({isString:true, transform:generateTextIndex({propResolveFunc:getPreferredFirstNameOf as (target:any) => string}) as Function})
	@MinLength(2)
	@IsNotEmpty()
	public firstName:string;

	public altFirstName:string;

	@IndexProperty({isString:true, transform:generateTextIndex({textProp: 'lastName'}) as Function})
	@MinLength(2)
	@IsNotEmpty()
	public lastName:string;

	@IsNotEmpty()
	@MinLength(2)
	public professionalName:string;

	//Trigger update
	@Type(() => Avatar)
	@RegisterType({clazz: Avatar})
	public avatar:Avatar = new Avatar();

	//document text content transformer
	@Transform(toClassDocTextTransformFactory(WaihonaUserTextContent), {toClassOnly: true})
	@Transform(toPlainDocTextTransform, {toPlainOnly: true})
    public documentTextContent:DocumentTextContent<WaihonaUserTextContent> = new DocumentTextContent<WaihonaUserTextContent>(WaihonaUserTextContent);


	@Type(() => WaihonaUserOrganization)
	@RegisterType({clazz:WaihonaUserOrganization, isArray:true})
	public organizations:Array<WaihonaUserOrganization> = [];

	/** Guids of Users the user is following */
	@Type(() => OrganizationRef)
	@RegisterType({clazz:OrganizationRef, isArray:true})
	public followingOrganizations:Array<OrganizationRef> = [];

	/** Guids of Users the user is following */


	@Type(() => WaihonaUserRef)
	@RegisterType({clazz:WaihonaUserRef, isArray:true})
	@IndexProperty({isArray:true})
	public followingUsers:Array<WaihonaUserRef> = [];

	/** Guids of Lessons the user is following */
	@Type(() => PublishedResourceRef)
	@RegisterType({clazz:PublishedResourceRef, isArray:true})
	@IndexProperty({isArray:true})
	public followingLessons:Array<PublishedResourceRef> = [];


	/** Guids of Lessons that the user created and published */
	@Type(() => PublishedResourceRef)
	@RegisterType({clazz:PublishedResourceRef, isArray:true})
	@IndexProperty({isArray:true})
	public publishedLessons:Array<PublishedResourceRef> = [];

	@Type(() => Role)
	@RegisterType({clazz:Role, isArray:true})
	@IndexProperty({isArray:true, itemIdentifier:"type"})
	public roles:Array<Role> = [];

	//todo: remove badgeRef when we have the new BadgesAwarded collection
	@Type(() => BadgeAward)
	@RegisterType({clazz:BadgeAward, isArray:true})
	public badges:Array<BadgeAward> = [];

	//see todo below
	// public userCounter:UserCounter;

	constructor() {

	}

	public get twoInitials():string {
		let firstChar:string = (this.altFirstName ? this.altFirstName : this.firstName).trim().charAt(0);
		let secondChar:string = (this.lastName).trim().charAt(0);

		return `${firstChar} ${secondChar}`.toUpperCase();
	}

	public get defaultUserTitle():string {
		const org:WaihonaUserOrganization = this.getDefaultOrganization();
		if (org != null) {
			return org.userTitle;
		}
		return "";
	}

	public get fullName():string {
		return (this.altFirstName ? this.altFirstName : this.firstName) + " " + this.lastName;
	}

	public get preferredFirstName():string {
		return (this.altFirstName ? this.altFirstName : this.firstName);
	}

	public toString():string {
		return `${this.guid}`;
	}

	public isRoleTypeForAny(roleType:RoleType):boolean {
		return this.roles.find(item => {
			return item.type == roleType;
		}) != null;
	}

	public isRoleTypeForOrg(roleType:RoleType, orgGuid:string) {
		return this.roles.find(item => {
			return item.type == roleType && item.organization == orgGuid;
		}) != null;
	}

	public getDefaultOrganization():WaihonaUserOrganization {
		return this.organizations.find(item => {
			return item.default;
		});
	}
}

//todo: aggregate counts of documents in collections for things like badge-pills in UI
/*export class UserCounter {
	public inbox:{
		unread:number;
		total:number;
	};
	public contributions:{
		submitted:number;
		inReview:number;
		inReviewRevision:number;
		inReviewContinuation:number;
		published:number;
		unpublished:number;
	};
	public reviews:{
		pendingReview:number;
		inReviewContinuation:number;
		reviewsCompleted:number;
	};
	public organizations:{
		pendingAcceptInvite:number;
		awaitingApproval:number;
		memberOf:number;
	};
	public profile:{
		itemsMissingData:number;
		badgesReceived:number;
	};

	public getTotalPendingActions():number {
		return this.inbox.unread + this.contributions.inReviewRevision + this.reviews.pendingReview + this.reviews.inReviewContinuation + this.organizations.pendingAcceptInvite + this.profile.itemsMissingData;
	}

}*/

