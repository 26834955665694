import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalizationService} from "../../../../../../../services/LocalizationService";
import {LearningAssetUpload, UploadStatus} from "../../../../../../../services/objects/LearningAssetUpload";
import {NGXLogger} from "ngx-logger";
import {Localization} from "../../../../../../../data/Localization";
import {ResourceSubmissionService} from "../../../../../../../services/ResourceSubmissionService";

@Component({
	selector: 'tr.app-lesson-asset-upload',
	templateUrl: './lesson-asset-upload.component.html',
	styleUrls: ['./lesson-asset-upload.component.scss']
})
export class LessonAssetUploadComponent implements OnInit {

	private cbContext = Localization.template.resource.lesson_asset_upload;
	public CB = {
		filename_conflict: this.cbContext.filename_conflict,
		new_name: this.cbContext.new_name,
		new_file: this.cbContext.new_file,
		ready: this.cbContext.ready,
		confirm_overwrite: this.cbContext.confirm_overwrite,
		confirm_discard: this.cbContext.confirm_discard,
		button_keep: this.cbContext.custom_buttons.keep,
		button_confirm_overwrite: this.cbContext.custom_buttons.overwrite,
		button_confirm_delete: this.cbContext.custom_buttons.delete,
		button_rename: Localization.template.buttons.rename,
		button_overwrite: Localization.template.buttons.overwrite,
		button_discard: Localization.template.buttons.discard_plain,
		button_cancel: Localization.template.buttons.cancel,
	};

	public newFileName:string = "";
	public mode:LearningAssetUploadComponentMode = LearningAssetUploadComponentMode.regular;

	@Output()
	public onUploadRemoved:EventEmitter<LearningAssetUpload> = new EventEmitter();
	@Output()
	public onUploadCanceled:EventEmitter<LearningAssetUpload> = new EventEmitter();

	@Output()
	public onChoiceConfirmed:EventEmitter<LearningAssetUploadComponentMode> = new EventEmitter();


	@Input()
	public learningAssetUpload:LearningAssetUpload;

	constructor(private localizationService:LocalizationService,
				protected logger:NGXLogger) {
		this.localizationService.registerAndLocalize("LessonAssetUploadComponent", this.CB);
	}

	ngOnInit() {
		this.newFileName = this.learningAssetUpload.name;
	}

	public get UploadStatus() {
		return UploadStatus;
	}

	public onRenameClick() {
		this.mode = LearningAssetUploadComponentMode.rename;
	}

	public onOverwriteClick() {
		this.mode = LearningAssetUploadComponentMode.overwrite;
	}

	public onDiscardClick() {
		this.mode = LearningAssetUploadComponentMode.discard;
	}

	public get originalFileName():string {
		return this.learningAssetUpload.name;
	}

	public get fileName():string {
		return this.learningAssetUpload.learningAsset.fileName;
	}

	public onUploadRemove():void {
		this.onUploadRemoved.emit(this.learningAssetUpload);
	}
	public onUploadCancel():void {
		this.onUploadCanceled.emit(this.learningAssetUpload);
	}
	public get LearningAssetUploadComponentMode() {
		return LearningAssetUploadComponentMode;
	}
	public isActive(mode:LearningAssetUploadComponentMode) {
		return this.mode == mode;
	}

	/** Confirm the rename */
	public onRenameConfirm():void {
		let toName:string = this.newFileName;
		this.logger.info("Confirming change to " + toName);
		if(toName != this.learningAssetUpload.file.name) {
			this.logger.error("Must change the file name...cant keep the same thing.");
		}
		this.learningAssetUpload.name = toName;
		this.learningAssetUpload.learningAsset.fileName = toName;
		this.learningAssetUpload.status = UploadStatus.waiting;
		this.onChoiceConfirmed.emit(this.mode);
	}
	/** Confirm the discard */
	public onDiscardConfirm():void {
		this.learningAssetUpload.status = UploadStatus.waiting;
		this.onChoiceConfirmed.emit(this.mode);
	}

	/** Confirm the overwrite */
	public onOverwriteConfirm() {
		this.learningAssetUpload.status = UploadStatus.waiting;
		this.learningAssetUpload.overwrite = true;
		this.onChoiceConfirmed.emit(this.mode);
	}

	public onCancel() {
		this.mode = LearningAssetUploadComponentMode.regular;
	}
}

export enum LearningAssetUploadComponentMode {
	"regular"="regular",
	"rename"="rename",
	"overwrite"="overwrite",
	"discard"="discard"
}
