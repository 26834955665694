import { NgModule } from '@angular/core';

//Routing
import {CommonModule} from "@angular/common";

import {BreadcrumbsComponent} from "./breadcrumbs/breadcrumbs.component";
import {AppHeaderComponent} from "./app-header/app-header.component";
import {AppFooterComponent} from "./app-footer";
import {FullLayoutComponent} from "./full-layout";
import {SimpleLayoutComponent} from "./simple-layout";
import {DisplayUserComponent} from "./display-user/display-user.component";
import {RouterModule, Scroll} from "@angular/router";
import {AppSidebarComponent} from "./app-sidebar";
import {AppAsideComponent} from "./app-aside";
import {AppSidebarMinimizerComponent} from "./app-sidebar-minimizer";
import {AppSidebarFooterComponent} from "./app-sidebar-footer";
import {AppSidebarHeaderComponent} from "./app-sidebar-header";
import {AppSidebarFormComponent} from "./app-sidebar-form";
import {TabsModule} from "ngx-bootstrap/tabs";
import {
    AppSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent, AppSidebarNavTitleComponent
} from "./app-sidebar-nav";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {ModalModule} from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import {ValidationTextComponent} from "./validation-text";
import {ValidationColorDirective} from "../../directives/validation-color";
import {FormsModule} from "@angular/forms";
import {YesNoPromptComponent} from "../common/yes-no-prompt/yes-no-prompt.component";
import {CommunicationService} from "../../services/CommunicationService";
import {QuillModule} from "ngx-quill";
import {LessonAssetComponent} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-asset/lesson-asset.component";
import {LessonAssetsComponent} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-assets/lesson-assets.component";
import {AssetMenuDropdownComponent} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-assets/asset-menu-dropdown/asset-menu-dropdown.component";
import {LoadingButtonComponent} from "../resources/create/single/edit-resource-submission/subcomponents/loading-button/loading-button.component";
import {FileUploadComponent} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-assets/file-upload/file-upload.component";
import {DropZoneDirective} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-assets/file-upload/drop/drop-zone.directive";
import {NgSelectModule} from "@ng-select/ng-select";
import {LessonAssetUploadComponent} from "../resources/create/single/edit-resource-submission/subcomponents/lesson-asset-upload/lesson-asset-upload.component";
import {CommonDirectivesModule} from "./CommonDirectivesModule";
import {NgbCollapseModule} from "@ng-bootstrap/ng-bootstrap";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {NaeLocalizationComponent} from "./nae-localization/nae-localization.component";
import {NaeLocPseudoInputDirective} from "../../directives/nae-loc-pseudo-input.directive";
import {NaeLocalizationDirective} from "../../directives/nae-localization.directive";
import {PipesModule} from "../../pipes/pipes.module";
import {PopOverConfirmationComponent} from "../../common/pop-over-confirmation/pop-over-confirmation.component";
import { DocLanguageToggleComponent } from './doc-language/doc-language-toggle.component';
import { DocLanguageButtonComponent } from './doc-language-button/doc-language-button.component';

let ConfiguredLoggerModule = LoggerModule.forRoot({
	enableSourceMaps: true,
	timestampFormat: "full",
	level: NgxLoggerLevel.DEBUG
}/*{serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR}*/);

import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import {LanguageMismatchAlertComponent} from "../common/language-mismatch-alert/language-mismatch-alert.component";
import {NavigateAwayConfirmationModalComponent} from "../modals/navigate-away-confirmation-modal/navigate-away-confirmation-modal.component";
import {DeviceDetectorModule} from "ngx-device-detector";
import {FilterComponent} from "../common/filter/filter.component";
import {ClickOutsideModule} from "ng-click-outside";
import { LoadingCardComponent } from './loading-card/loading-card.component';
import {SwitchWithTextComponent} from "../../common/switch-with-text/switch-with-text.component";
import {ClipboardModule} from "ngx-clipboard";
import {InfiniteScrollDirective, InfiniteScrollModule} from "ngx-infinite-scroll";
import {CropperComponent} from "../common/cropper/cropper.component";
import {AvatarComponent} from "../common/avatar/avatar.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { CharCountComponent } from './char-count/char-count.component';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,/** Used by breadcrumb */
		TabsModule,
		FormsModule,

		QuillModule.forRoot(),
		NgSelectModule,
		BsDropdownModule,
		// NgbCollapseModule,
		CollapseModule.forRoot(),
		PopoverModule.forRoot(),
		ModalModule.forRoot(),
		DeviceDetectorModule.forRoot(),
		AccordionModule.forRoot(),
		TabsModule.forRoot(),
		ConfiguredLoggerModule,
		CommonDirectivesModule,
		PipesModule,
		TooltipModule,
		ClickOutsideModule,
		AngularStickyThingsModule,
		InfiniteScrollModule,
		MatCheckboxModule
	],
    declarations: [
        YesNoPromptComponent,
        BreadcrumbsComponent,
        AppFooterComponent,
        AppHeaderComponent,
        FullLayoutComponent,
        SimpleLayoutComponent,
        DisplayUserComponent,
        AppSidebarComponent,
        AppAsideComponent,
        AppSidebarMinimizerComponent,
        AppSidebarFooterComponent,
        AppSidebarHeaderComponent,
        AppSidebarFormComponent,
        AppSidebarNavComponent,
        AppSidebarNavItemComponent,
        AppSidebarNavLinkComponent,
        AppSidebarNavDropdownComponent,
        AppSidebarNavTitleComponent,
        ValidationTextComponent,
        ValidationColorDirective,
        LessonAssetComponent,
        LessonAssetsComponent,
        AssetMenuDropdownComponent,
        LoadingButtonComponent,
        FileUploadComponent,
        LessonAssetUploadComponent,
        DropZoneDirective,
        NaeLocalizationComponent,
        NaeLocPseudoInputDirective,
        NaeLocalizationDirective,
	    DocLanguageButtonComponent,
	    DocLanguageToggleComponent,
	    PopOverConfirmationComponent,
	    LoadingCardComponent,
	    CharCountComponent,

	    ...[/* Components used in several place */
		    LanguageMismatchAlertComponent,
		    FilterComponent,
		    AvatarComponent,
			SwitchWithTextComponent,
	    ],
    ],
    entryComponents: [NaeLocalizationComponent, NavigateAwayConfirmationModalComponent],
    providers: [
        CommunicationService
    ],
	exports: [
		BreadcrumbsComponent,
		AppFooterComponent,
		AppHeaderComponent,
		FullLayoutComponent,
		SimpleLayoutComponent,
		DisplayUserComponent,
		AppSidebarComponent,
		AppAsideComponent,
		AppSidebarMinimizerComponent,
		AppSidebarFooterComponent,
		AppSidebarHeaderComponent,
		AppSidebarFormComponent,
		AppSidebarNavComponent,
		AppSidebarNavItemComponent,
		AppSidebarNavLinkComponent,
		AppSidebarNavDropdownComponent,
		AppSidebarNavTitleComponent,
		AccordionModule,
		ValidationTextComponent,
		ValidationColorDirective,
		InfiniteScrollDirective,
		YesNoPromptComponent,
		AvatarComponent,
		LessonAssetComponent,
		LessonAssetsComponent,
		AssetMenuDropdownComponent,
		LoadingButtonComponent,
		FileUploadComponent,
		LessonAssetUploadComponent,
		DropZoneDirective,
		NaeLocalizationComponent,
		NaeLocPseudoInputDirective,
		NaeLocalizationDirective,
		PipesModule,
		PopOverConfirmationComponent,
		DocLanguageToggleComponent,
		LoadingCardComponent,
		ClipboardModule,
		CharCountComponent,
		...[/* Components used in several place */
			LanguageMismatchAlertComponent,
			FilterComponent,
			AvatarComponent,
			SwitchWithTextComponent
		],
	]
})
export class SharedModule {
	constructor() {
	}
}
