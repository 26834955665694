<ng-template #createPopoverTemplate>
	Create {{languageLabel}} version?
	<div>
		<span class="btn btn-primary" (click)="this.create()">{{CB.confirm_yes | localize | async}}</span>
		<span class="btn btn-danger" (click)="this.popover.create.hide()">{{CB.confirm_no | localize | async}}</span>
	</div>
</ng-template>

<ng-template #deletePopoverTemplate>
	Delete {{languageLabel}} version?
	<div>
		<span class="btn btn-primary" (click)="this.delete()">{{CB.confirm_yes | localize | async}}</span>
		<span class="btn btn-danger" (click)="this.popover.delete.hide()">{{CB.confirm_no | localize | async}}</span>
	</div>
</ng-template>

<a class="badge language-badge text-white" #buttonPopover [popover]="createPopoverTemplate" placement="bottom" triggers="" outsideClick="true" container="body" adaptivePosition="false" containerClass="olelo-popover"
   popoverTitle="{{this.title}}" [ngClass]="{
   	'badge-light': !this.selected,
   	'badge-secondary': this.selected,
   	'selected-lang': this.selected,
	'unactivated-lang': !this.languageExists
   }"
   (clickOutside)="this.popover.hide()"
   (click)="this.click()">{{languageLabel}}
	<i class="fas fa-cog" *ngIf="languageExists && selected && hasOtherLanguages"></i>
</a>
