import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Localization} from "../../../data/Localization";
import {LocalizationService} from "../../../services/LocalizationService";
import {SubscriptionCleaner} from "../../../util/SubscriptionCleaner";
import {IOption} from "ng-select";
import {
	AssetTypes,
	CategoryForIOption,
	GradeOptions,
	HawaiianCategories,
	ICategorizedIOption,
	LanguageTypes,
	LearningModalities,
	SpecialIndicators,
	SubjectCategories
} from "../../../data/LessonData";
import {NgSelectComponent} from "@ng-select/ng-select";
import {WaihonaUserService} from "../../../services/WaihonaUserService";
import {WaihonaUserRef} from "../../../domain/user/WaihonaUserRef";
import {UrlService} from "../../../services/UrlService";
import {AinaService} from "../../../services/AinaService";
import {NupepafyStringUtils} from "../../../util/NupepafyStringUtils";
import {FrameworkService} from "../../../services/FrameworkService";
import {SelectableFrameworkRef} from "../../../domain/frameworks/SelectableFrameworkRef";
import {NotificationService} from "../../../services/common/NotificationService";
import {
	IFrameworkSearchSpecsModalInput,
	SearchFrameworkSpecsModalComponent
} from "../../modals/search-framework-specs-modal/search-framework-specs-modal.component";
import {AvatarMode} from "../avatar/avatar.component";
import {RenderLabelStyle} from "../../../domain/resources/aina/Aina";

export enum FilterType {
	AssetTypes = 'Asset',
	SubjectCategories = 'Subject',
	SpecialIndicators = 'Special',
	HawaiianCategories = 'Hawaiian',
	LearningModalities = 'Modality',
	GradeOptions = 'Grade',

	Frameworks="Frameworks",
	Aina="Aina",
	Contributors = 'Contributor',
	Title="Title",
	Text="Text",
	Language="Language"
}

export interface IFilterTypeSource {
	type:FilterType|ICategorizedIOption,
	placeholder:string,
	label:string,
	options:Array<ICategorizedIOption>;
	isOpen:boolean,
	default?:boolean,
	addTag?:boolean,
	addTagText?:string
}

export const colorSet:Array<string> = [
	"#BDE2FF","#D2B5FF","#E3B4FE","#FECEDA","#FEC2C0","#FEE6B6","#E0CFC2","#FFFAB6","#B8F7B1","#C0FEF0","#D2F0FE",
];



@Component({
	selector: 'filter-search',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends SubscriptionCleaner implements OnInit, OnDestroy {

	/*
$blue:                            #5393C0; //changed per Gonzo's palette
$indigo:                          #6610f2 !default; //------> not set
$purple:                          #9444C9 !default; //changed per Gonzo's palette
$pink:                            #F86A84 !default; //changed per Gonzo's palette
$red:                             #F14436; //changed per Gonzo's palette
$orange:                          #FDAE26; //changed per Gonzo's palette
$brown:                           #795d49; //changed per Gonzo's palette
$yellow:                          #f8e500 !default; //changed per Gonzo's palette
$green:                           #3aa038; //changed per Gonzo's palette
$teal:                            #54c3ae !default; //changed per Gonzo's palette
$cyan:                             #67caf7; //------> not set

	 */
	public filterType:CategoryForIOption = null;
	public filterTypeListReady$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public filterTypeList:Array<IFilterTypeSource> = [
		{type:FilterType.AssetTypes, label: "Asset Type", options: AssetTypes, isOpen: true, placeholder: "Choose asset type(s)"},
		{type:FilterType.GradeOptions, label: "Grade", options: GradeOptions, isOpen: true, placeholder: "Choose grade(s)"},
		{type:FilterType.Language, label: "Language", options: LanguageTypes, isOpen: true, placeholder: "Choose language(s)"},
		{type:FilterType.SubjectCategories, label: "Subject", options: SubjectCategories, isOpen: true, placeholder: "Choose subject(s)"},
		{type:FilterType.Text, label: "Text", options: [], isOpen: false, placeholder: "Enter text to search for", addTag: true, addTagText:"Contains ", default: true},
		{type:FilterType.HawaiianCategories, label: "Cultural", options: HawaiianCategories, isOpen: false, placeholder: "Choose Cultural Perspective(s)"},
		{type:FilterType.LearningModalities, label: "Modalities", options: LearningModalities, isOpen: false, placeholder: "Choose Learning Modality/Modalities"},
	];
	//Frameworks

	public currentFilterSource:IFilterTypeSource;
	public ngSelectItemChoices:Array<IOption> = [];

	@Output()
	public afterFilter:EventEmitter<ICategorizedIOption[]> = new EventEmitter<ICategorizedIOption[]>();

	public gradeOptions:Array<ICategorizedIOption> = GradeOptions;
	public assetTypes:Array<ICategorizedIOption> = AssetTypes;
	public subjectCategories:Array<ICategorizedIOption> = SubjectCategories;
	public hawaiianCategories:Array<ICategorizedIOption> = HawaiianCategories;
	public learningModalities:Array<ICategorizedIOption> = LearningModalities;
	public specialIndicators:Array<ICategorizedIOption> = SpecialIndicators;
	public allSelectableFrameworks:Array<SelectableFrameworkRef> = [];
	public aina:Array<ICategorizedIOption> = [];
	public filterProperties = {
		addTag: (term:string)=> {
			let s:ICategorizedIOption = {
				category: CategoryForIOption.Text, disabled: false, label: term, value: term
			};
			return s;
		},
		addTagText: ""
	}

	public finalFilter$:BehaviorSubject<ICategorizedIOption[]> = new BehaviorSubject<ICategorizedIOption[]>([]);

	@ViewChild(NgSelectComponent)
	public searchFilter:NgSelectComponent;

	private _exactMatch:boolean = false;

	private cbContext = Localization.template.common.search;
	public CB = {
		placeholder: this.cbContext.placeholder,
		exactMatch: this.cbContext.exactMatch,
	};

	public contributors:Array<ICategorizedIOption> = [];
	public contributorsWaihonaUserRefs:{[key:string]:WaihonaUserRef} = {};
	private searchSubject = new Subject<string>();

	@Output()
	searchValue: EventEmitter<string> = new EventEmitter();

	@Output()
	finalFilterChange:EventEmitter<ICategorizedIOption[]> = new EventEmitter<ICategorizedIOption[]>();

	@Output()
	exactMatchChange:EventEmitter<boolean> = new EventEmitter<boolean>();

	public get FilterType() {
		return CategoryForIOption;
	}
	public get AvatarMode() {
		return AvatarMode;
	}
	constructor (
			protected localizationService:LocalizationService,
			protected waihonaUserService:WaihonaUserService,
			protected ainaService:AinaService,
			public urlService:UrlService,
			public notificationService:NotificationService,
			protected frameworkService:FrameworkService) {
		super();
		this.localizationService.registerAndLocalize("FilterComponent", this.CB);
		this.setSearchSubscription();
		this.currentFilterSource = this.filterTypeList.find(el=>el.default); //None
		this.sortFilterTypeList();

		//Fill in the contributor options for the drop down
		let s:Subscription = this.trackSubscription(this.waihonaUserService.watchListAsRef$().subscribe((waihonaUserRefs:Array<WaihonaUserRef>) => {
			if(waihonaUserRefs.length != 1) {
				s.unsubscribe();
			} else {
				return;
			}
			for(let waihonaUserRef of waihonaUserRefs) {
				let contributorOption:ICategorizedIOption = {
					category: CategoryForIOption.Contributors, disabled: false, label: waihonaUserRef.fullName, value: waihonaUserRef.guid
				};
				this.contributors.push(contributorOption);
				this.contributorsWaihonaUserRefs[waihonaUserRef.guid] = waihonaUserRef;
			}
			this.filterTypeList.push(
				{type:FilterType.Contributors, label: "Contributor", options: this.contributors, isOpen: true, placeholder: "Choose contributors(s)"});
			this.sortFilterTypeList();
			this.filterTypeListReady$.next(true);

		}));

		let j:Subscription = this.trackSubscription(this.ainaService.getAllAina$().subscribe(ainaList => {
			let allAinaOptions:Array<ICategorizedIOption> = ainaList.map(aina => {
				let ainaOption:ICategorizedIOption = {
					category: CategoryForIOption.Aina, disabled: false, label: aina.renderLabel(RenderLabelStyle.hyphens,RenderLabelStyle.parent), searchLabel: aina.renderLabel(RenderLabelStyle.parent), value: aina.guid
				};
				return ainaOption;
			})
			this.aina = [...allAinaOptions];
			this.filterTypeList.push(
				{type:FilterType.Aina, label: "ʻĀina", options: this.aina, isOpen: true, placeholder: "Choose ʻĀina/Moku"});
			this.sortFilterTypeList();
			this.filterTypeListReady$.next(true);
			j.unsubscribe();
		}));

		let fSub:Subscription = this.trackSubscription(this.frameworkService.list.frameworks.searchable.refs$().subscribe(allSelectableFrameworks => {
			this.allSelectableFrameworks = allSelectableFrameworks;
			let allFrameworkOptions:Array<ICategorizedIOption> = allSelectableFrameworks.map(selectableFramework => {
				return {
					category:CategoryForIOption.Framework,
					disabled:false,
					label:selectableFramework.ref.title,
					value:selectableFramework.ref.guid,
					data: selectableFramework,
				} as ICategorizedIOption;
			});

			this.filterTypeList.push(
				{type:FilterType.Frameworks, label: "Frameworks", options: allFrameworkOptions, isOpen: true, placeholder: "Choose Frameworks, Standards, Outcomes, & More..."});
			this.sortFilterTypeList();
			this.filterTypeListReady$.next(true);

			fSub.unsubscribe();
		}));
	}
	public wur(guid:string):WaihonaUserRef {
		return this.contributorsWaihonaUserRefs[guid];
	}
	public asOption(item:IOption):ICategorizedIOption {
		return item as ICategorizedIOption;
	}
	public get CategoryForIOption() {
		return CategoryForIOption;
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.searchSubject.unsubscribe();
	}

	public get exactMatch() {
		return this._exactMatch;
	}
	@Input()
	public set exactMatch(value:boolean) {
		console.log("setting exact match: " + value);
		this._exactMatch = value;
		this.exactMatchChange.emit(this.exactMatch);
	}

	@Input()
	public get finalFilter():Array<ICategorizedIOption> {
		return this.finalFilter$.getValue();
	}
	public set finalFilter(value:Array<ICategorizedIOption>) {
		this.finalFilter$.next(value);
		this.finalFilterChange.emit(this.finalFilter);
	}
	private setSearchSubscription() {
		this.trackSubscription(
			this.searchSubject.pipe(
				debounceTime(300), //wait 300 ms for the user to pause typing
				distinctUntilChanged() //ignore until it changes
			).subscribe((searchValue:string) => {
				console.log(`Search component is emitting search term: ${searchValue}`);
				this.searchValue.emit(searchValue); //emit the new search terms back to the parent
			}));
		}

	public setActiveFilterType(filterType:IFilterTypeSource) {

		if(filterType != this.currentFilterSource) {
			this.searchFilter.close();
			this.currentFilterSource = filterType;
			setTimeout(()=> {
				this.searchFilter.open();
			}, 1);
		} else {
			if(!this.searchFilter.isOpen) {
				this.searchFilter.open();
			}
		}





		//this.filterProperties.addTag = !!filterType.addTag;
		//this.filterProperties.addTagText = !!filterType.addTagText ? filterType.addTagText : "";

	}
	public closeFilter():void {
		this.searchFilter.close();
	}
	public lengthOfCategory(item:ICategorizedIOption):number {
		return this.finalFilter.slice(0).filter(option => {
			return option.category == item.category
		}).length;
	}
	public isFirstInCategory(item:ICategorizedIOption):boolean {
		let index:number = this.finalFilter.indexOf(item);
		let isFirstOne:boolean = this.finalFilter.slice(0, index).find(option => {
			return option.category == item.category
		}) == null;

		return isFirstOne;
	}
	public isLastInCategory(item:ICategorizedIOption):boolean {
		let index:number = this.finalFilter.indexOf(item);
		let isLastOne:boolean = this.finalFilter.slice(index+1).find(option => {
			return option.category == item.category
		}) == null;

		return isLastOne;

	}
	public sortFilterTypeList():void {
		this.filterTypeList = [...this.filterTypeList.sort((a,b)=> {
			let aLabel:string = NupepafyStringUtils.nupepafy(a.label);
			let bLabel:string = NupepafyStringUtils.nupepafy(b.label);
			if(aLabel.toLowerCase() < bLabel.toLowerCase()) { return -1; }
			if(aLabel.toLowerCase() > bLabel.toLowerCase()) { return  1; }
			return 0;
		})];
	}
	public getSelectedFilterLabel(item:ICategorizedIOption) {
		let isFirstOne:boolean = this.isFirstInCategory(item);
		let isLastOne:boolean = this.isLastInCategory(item);
		let lengthOfCategory:number = this.lengthOfCategory(item);

		let labelToUse:string = item.searchLabel == null ? item.label : item.searchLabel;

		if(isFirstOne && isLastOne) {
			return ` ${item.category} is ${labelToUse}.`;
		} else if(isFirstOne && !isLastOne) {
			return ` ${item.category} is ${labelToUse}`;
		} else if(lengthOfCategory == 2 && !isFirstOne && isLastOne) {
			return ` or ${labelToUse}.`;
		} else if(!isFirstOne && isLastOne) {
			return `, or ${labelToUse}.`;
		} else if(!isFirstOne && !isLastOne) {
			return `, ${labelToUse}`;
		}
		console.error("getSelectedFilterLabel: should not get here");
	}

	public sortTheFilterByCategory():Array<ICategorizedIOption> {
		return this.finalFilter.sort( (a, b) => {
			if(a.category.toLowerCase() < b.category.toLowerCase()) { return -1; }
			if(a.category.toLowerCase() > b.category.toLowerCase()) { return 1; }
			return 0;

		})
	}
	public onSearchFilterChanged($event:{}) {
		console.log("search filter changed");
		let result:Array<ICategorizedIOption> = this.sortTheFilterByCategory();
		this.finalFilter = [...result];
		this.searchFilter.open();
		this.afterFilter.emit(this.finalFilter);
	}

	onModelChange($event:any) {
		console.log("model has changed");
	}

	onAdded(addedItem:IOption) {
		console.log(`onTextSearch ${addedItem}: \n${JSON.stringify(addedItem,null,2)}`);
	}

	public asSFRef(item:ICategorizedIOption):SelectableFrameworkRef {
		return (item.data as SelectableFrameworkRef);
	}

	public configureFramework(framework:ICategorizedIOption) {
		console.info("trying to configure " + framework.label);
		let matchingSelectableFramework:SelectableFrameworkRef = this.allSelectableFrameworks.find(selectableFramework => {
			return selectableFramework.ref.guid == framework.value;
		});
		if(matchingSelectableFramework) {
			let searchSpecs:IFrameworkSearchSpecsModalInput = {
				framework: matchingSelectableFramework
			};
			this.notificationService.displayModal(SearchFrameworkSpecsModalComponent, this, searchSpecs)
		}
		else {
			console.error(`Did not find a match for ${framework.label}`)
		}

	}
}
