import {Injectable} from "@angular/core";
import {AuthService} from "./AuthService";
import {Framework} from "../domain/frameworks/Framework";
import {AngularFireStorage} from "@angular/fire/storage";

import {AngularFirestore} from "@angular/fire/firestore";
import {FrameworkRepository} from "./repository/FrameworkRepository";
import {Observable, Subject, Subscription} from "rxjs";
import {SelectableFrameworkRef} from "../domain/frameworks/SelectableFrameworkRef";
import {ConversionService} from "./ConversionService";
import {RoleService} from "./common/RoleService";
import {PermissionType} from "../domain/user/Permission";
import {map} from "rxjs/operators";


@Injectable({
	providedIn: 'root',
} as any)
export class FrameworkService {
	constructor (private authService: AuthService,
				 private storage: AngularFireStorage,
				 private db:AngularFirestore,
				 private repository:FrameworkRepository,
				 private roleService:RoleService,
				 private conversionService:ConversionService
				 ) {
	}

	public save(framework:Framework):Observable<Framework> {
		return this.repository.save$(framework);
	}

	/** Get a Framework */
	public getFrameworkById(frameworkGuid: string):Observable<Framework> {
		return this.repository.get$(frameworkGuid);
	}

/*	/!** Get all Framework *!/
	public getAllFrameworks$():Observable<Framework[]> {
		console.log("FrameworkService::getAllFrameworks");
		let subject:Subject<Framework[]> = new Subject<Framework[]>();
		let subscription:Subscription = this.repository.list$().subscribe(frameworks => {
			subscription.unsubscribe();

			let canSeeHidden:boolean = this.roleService.hasPermissionFor(PermissionType.view_hidden_frameworks)
			if(canSeeHidden) {
				subject.next(frameworks);
			} else {
				//cant see
				let visibleFrameworks:Array<Framework> = frameworks.filter(framework => framework.visible);
				subject.next(visibleFrameworks);
			}


			subject.complete();
		});
		return subject;
	}*/

	public list = {
		frameworks: {
			searchable: {
				full$: () => {
					return this.repository.list$().pipe(
						map(frameworks => {
							return frameworks.filter(framework => framework.searchable);
						})
					);
				},
				refs$: () => {
					return this.list.frameworks.searchable.full$().pipe(
						map(frameworks => {
							return frameworks.map(framework => {
								return this.asSelectableFrameworkRef(framework);
							});
						})
					)
				}
			},
			relativeVisibility: {
				full$: () => {
					return this.repository.list$().pipe(
						map(frameworks => {
							if(this.roleService.hasPermissionFor(PermissionType.view_hidden_frameworks)) {
								return frameworks;
							} else {
								return frameworks.filter(framework => framework.visible); 							//cant see..
							}

						})
					);
				},
				refs$: () => {
					return this.list.frameworks.relativeVisibility.full$().pipe(
						map(frameworks => {
							return frameworks.map(framework => {
								return this.asSelectableFrameworkRef(framework);
							});
						})
					)
				}
			},
			selectable: {
				full$: () => {
					return this.repository.list$().pipe(
						map(frameworks => {
							return frameworks.filter(framework => framework.selectable);
						})
					);
				},
				refs$: () => {
					return this.list.frameworks.selectable.full$().pipe(
						map(frameworks => {
							return frameworks.map(framework => {
								return this.asSelectableFrameworkRef(framework);
							});
						})
					)
				}
			}
		}
	}


	/** Get all Framework */
/*	public getAllSearchableFrameworks$():Observable<Framework[]> {
		console.log("FrameworkService::getAllSearchableFrameworks$");
		let subject:Subject<Framework[]> = new Subject<Framework[]>();
		let subscription:Subscription = this.repository.list$().subscribe(frameworks => {
			subscription.unsubscribe();

			let searchableFrameworks:Array<Framework> = frameworks.filter(framework => framework.searchable);
			subject.next(searchableFrameworks);
			subject.complete();
		});
		return subject;
	}*/

/*	public getAllSelectableFrameworks$():Observable<Framework[]> {
		console.log("FrameworkService::getAllSelectableFrameworks$");
		let subject:Subject<Framework[]> = new Subject<Framework[]>();

		let subscription:Subscription = this.repository.list$().subscribe(frameworks => {
			subscription.unsubscribe();

			let selectableFrameworks:Array<Framework> = frameworks.filter(framework => framework.selectable);
			subject.next(selectableFrameworks);
			subject.complete();
		});

		return subject;
	}*/

/*	public getAllSelectableFrameworkRefs$():Observable<SelectableFrameworkRef[]> {
		let self = this;
		return this.getAllFrameworks$().map(frameworks => {
			return frameworks.map(framework => {
				return self.asSelectableFrameworkRef(framework);
			});
		})
	}*/

	public asSelectableFrameworkRef(framework:Framework, frameworks:Array<SelectableFrameworkRef> = []):SelectableFrameworkRef {
		//Wasn't already in the data model; return the existing one
		let foundAlreadySelectedFrameworkRef:SelectableFrameworkRef = frameworks.find(item => {
			return item.ref.guid == framework.guid;
		});

		if (foundAlreadySelectedFrameworkRef != null) {
			return foundAlreadySelectedFrameworkRef;
		}

		//Wasnt already in the data model; return the existing one

		let selectableFrameworkRef:SelectableFrameworkRef = this.conversionService.convert(framework, SelectableFrameworkRef);
		return selectableFrameworkRef;
	}

	private flattenNodes(nodes) {
		let self = this;
		return nodes.reduce(
			function (flatArray = [], node) {
				if (!node.children.length) {
					return flatArray.concat(node);
				} else {
					return flatArray.concat(self.flattenNodes(node.children)).concat(node);
				}
			},
			[]);
	}
}
