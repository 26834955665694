import {Component, NgZone, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {AnimationItem} from "lottie-web";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  public animationItem: AnimationItem;
  public options: AnimationOptions = {
        path: '/assets/lottie/Words_wBirds_Rebuild_3.json',
        loop: false,
	    renderer: 'svg',
	    autoplay: true
  };
  public styles:Partial<CSSStyleDeclaration> = {
    width: '550px',
    height: '150px'
  };

  constructor(private ngZone: NgZone) {

  }

  onAnimationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    this.animationItem = animationItem;
  }

  stop(): void {
    this.ngZone.runOutsideAngular(() => {
      console.log("stopping logo animation");
      return this.animationItem.stop()
    });
  }

  play(): void {
    this.ngZone.runOutsideAngular(() => {
      console.log("playing logo animation");

      this.animationItem.play()
    });
  }

}

