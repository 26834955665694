import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'char-count',
  templateUrl: './char-count.component.html',
  styleUrls: ['./char-count.component.scss']
})
export class CharCountComponent implements OnInit {

	@Input()
	public fieldLength:number;

	@Input()
	public maxLength:number;

	constructor(
	) { }

	ngOnInit(): void {
	}

}
