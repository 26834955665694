import { NgModule } from '@angular/core';
import {HtmlReplaceDirective} from "../../directives/html-replace/HtmlReplaceDirective";

@NgModule({
	imports: [

	],
    declarations: [
        HtmlReplaceDirective
	],
    providers: [

    ],
    exports: [
        HtmlReplaceDirective

    ]
})
export class CommonDirectivesModule { }
