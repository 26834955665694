import {Injectable} from "@angular/core";
import {AbstractOldFirebaseFunctions} from "./AbstractOldFirebaseFunctions";
import {WaihonaUser} from "../../domain/user/WaihonaUser";

import {AngularFireFunctions} from "@angular/fire/functions";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {UserProfileMessage} from "../messages/UserProfileMessage";
import {classToPlain} from "class-transformer";
import {InvitationAnswerMessage} from "../messages/InvitationAnswerMessage";
import {NGXLogger} from "ngx-logger";
import {Organization} from "../../domain/organization/Organization";
import {WaihonaUserRef} from "../../domain/user/WaihonaUserRef";

@Injectable({
	providedIn: 'root',
} as any)
export class WaihonaUserFunctions extends AbstractOldFirebaseFunctions<WaihonaUser> {

	public readonly uris = {
		"favoriteUser": null,
		"unfavoriteUser": null,
		"getUsersByGuids": null,
		"saveProfile": null,
		"answerOrgInvite":null,
	};

	constructor(protected functions:AngularFireFunctions, protected logger:NGXLogger) {
		super(WaihonaUser, functions, "guid");
		this.initUris(this.uris);
	}
	/** Favorite a user */
	public favoriteUser(guid:string):Observable<WaihonaUser> {
		return this.callDirect(this.ref(this.uris.favoriteUser), {favoriteUserGuid: guid});
	}
	/** Unfavorite a user */
	public unfavoriteUser(guid:string):Observable<WaihonaUser> {
		return this.callDirect(this.ref(this.uris.unfavoriteUser), {unfavoriteUserGuid: guid});
	}
	/** Get Waihona Users for Ids */
	public getWaihonaUsersForIds(guids: Array<string>): Observable<WaihonaUser[]> {
		return this.callDirectToArray(this.ref(this.uris.getUsersByGuids),{guids: guids});
	}

	public saveProfile(profile:UserProfileMessage):Observable<WaihonaUser> {
		this.logger.info("WaihonaUserFunctions is calling saveProfile on the server");
		let message = classToPlain<UserProfileMessage>(profile);
		this.logger.info(`message: ${JSON.stringify(message)}`);
		return this.callDirect(this.ref(this.uris.saveProfile), message);
	}

	public answerOrgInvite(message:InvitationAnswerMessage):Observable<WaihonaUser> {
		this.logger.info("using answerOrgInvite from WaihonaUserFunctions (client-side) to call the server");
		return this.callDirect(this.ref(this.uris.answerOrgInvite), classToPlain<InvitationAnswerMessage>(message));
	}

	public requestToJoinOrg(organization:Organization, waihonaUserRef:WaihonaUserRef):Observable<boolean> {
		this.logger.info("using requestToJoinOrg from WaihonaUserFunctions (client-side) to call the server");
		this.logger.info("calling receiveRequestToJoinOrganization firebase function");
		let register_callableFunction = this.functions.httpsCallable('receiveRequestToJoinOrganization');
		let response:ReplaySubject<boolean> = new ReplaySubject<boolean>();
		let data:{org:Organization, waihUserRef:WaihonaUserRef} =
			{
				org:organization,
				waihUserRef:waihonaUserRef
			};

		register_callableFunction(data).take(1).subscribe((result) => {
			if (result) {
				response.next(true);
				this.logger.info(`request to join org ${organization.guid} received`);
			}
		});
		return response;
	}

}
