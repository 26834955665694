import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EditResourceSubmissionComponent} from "./create/single/edit-resource-submission/edit-resource-submission.component";
import {ViewPublishedResourceComponent} from "./create/single/view-published-resource/view-published-resource.component";
import {ListPublishedResourcesComponent} from "./live/multiple/list-live-resources/list-published-resources.component";


import {ResourceSubmissionPreviewComponent} from "./create/single/resource-submission-preview/resource-submission-preview.component";
import {ListResourceSubmissionsComponent} from "./create/multiple/list-resource-submissions/list-resource-submissions.component";
import {ResourceSubmissionDraftGuard} from "../../guards/ResourceSubmissionDraftGuard";
import {NavigatingComponentDeactivationGuard} from "../../guards/NavigatingComponentDeactivationGuard";
import {AuthenticatedUsersOnlyModalBlockGuard} from "../../guards/AuthenticatedUsersOnlyModalBlockGuard";
import {PublicAccessBlockGuard} from "../../guards/PublicAccessBlockGuard";
import {
	PublicAccessPreferResourceCheckFirstBlockGuard
} from "../../guards/PublicAccessPreferResourceCheckFirstBlockGuard";

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Resources'
        },
        children: [
	        /* Live ----------------------------------- */
	        {
		        path: '',
		        component: ListPublishedResourcesComponent,
		        data: {
			        title: 'Resources',
			        action: 'view'
		        }
	        },

            /* Create ---------------------------------------------------------- */
            {
                path: 'create',
                component: EditResourceSubmissionComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
	            canDeactivate: [NavigatingComponentDeactivationGuard],
                data: {
                    title: 'Create Resource',
                    action: 'create'
                }
            },
            { //Edit an existing resource component
                path: 'users/:guid/:resourceId/edit',
                component: EditResourceSubmissionComponent,
                canActivate: [AuthenticatedUsersOnlyModalBlockGuard, ResourceSubmissionDraftGuard],
	            canDeactivate: [NavigatingComponentDeactivationGuard],
                data: {
                    description: "This component lists submissions of yours or contributions that you have access to",
                    title: 'Edit',
                    action: 'edit'
                }
            },
            { //Preview an existing resource component
                path: 'users/:userGuid/:resourceId',
                component: ResourceSubmissionPreviewComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
                data: {
                    description: "This component allows preview capability",
                    title: 'Resource Preview',
                    action: 'view'
                }
            },
            { // List a user's resource submissions
                path: 'users/:guid',
                component: ListResourceSubmissionsComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
                data: {
                    description: "This component lists submissions of yours or contributions that you have access to",
                    title: 'My Contributions',
                    action: 'list'
                }
                /* canActivate: [LoggedInGuard], */
            },

            { //Preview a lesson submission as if it were live
                path: 'users/:guid/resources/:resourceId',
                component: ResourceSubmissionPreviewComponent,
	            canActivate: [AuthenticatedUsersOnlyModalBlockGuard],
                data: {
                    description: "This component allows previewing of a what a lesson would look like if submitted.",
                    title: 'Resource Preview',
                    action: 'view'
                }
            },



            {
                path: ':resourceId',
                component: ViewPublishedResourceComponent,
	            canActivate: [PublicAccessBlockGuard],
	            data: {
                    title: "Resource Detail"
                }
            },
			{
				path: 'public/:resourceId',
				component: ViewPublishedResourceComponent,
				canActivate: [PublicAccessPreferResourceCheckFirstBlockGuard],
				data: {
					title: "Resource Detail"
				}
			},


        ]
    }
	/*{
          path: 'planner',
          loadChildren: './multiple/list-resources/list-resources.module#ListLessonsModule'
      },
      {
          path: 'lesson',
          loadChildren: './single/lesson/lesson.module#LessonModule'
      },

      {
          path: 'detail',
          loadChildren: './single/lesson-detail/lesson-detail.module#LessonDetailModule'
      }*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule {}
