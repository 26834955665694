import * as stringDiff from 'dice-coefficient';

/**
 * Functions from the string-similarity library. Code uses a single arrow function (inside of areArgsValid function).
 * This made it es5 incompatible. Copied here by Maui so it will be included in our codebase and transpiled to es5.
 */

export function findBestMatch(mainString, targetStrings) {
    if (!areArgsValid(mainString, targetStrings)) { throw new Error('Bad arguments: First argument should be a string, second should be an array of strings'); }

    const ratings = [];
    let bestMatchIndex = 0;

    for (let i = 0; i < targetStrings.length; i++) {
        const currentTargetString = targetStrings[i];
        const currentRating = stringDiff(mainString, currentTargetString);
        ratings.push({target: currentTargetString, rating: currentRating});
        if (currentRating > ratings[bestMatchIndex].rating) {
            bestMatchIndex = i
        }
    }

    const bestMatch = ratings[bestMatchIndex];

    return { ratings, bestMatch, bestMatchIndex };
}

function areArgsValid(mainString, targetStrings) {
    if (typeof mainString !== 'string') { return false; }
    if (!Array.isArray(targetStrings)) { return false; }
    if (!targetStrings.length) { return false; }
    if (targetStrings.find(s => typeof s !== 'string')) { return false; }
    return true;
}