import {Injectable} from "@angular/core";
import {ITextContentObject} from "../../domain/ITextContentObject";
import {LocalizationService} from "../LocalizationService";
import {SupportedLanguage, SupportedLanguages} from "../../domain/SupportedLanguages";

@Injectable({
    providedIn: 'root',
} as any)
/** Performs processing of a domain object and handles errors */
export class DocumentTextContentService {

	constructor(private localizationService:LocalizationService) {
	}

	public createDefaultLanguage(document:ITextContentObject<any>):SupportedLanguage {
		let hasChoiceLanguage:boolean = document.documentTextContent[this.localizationService.language] != null;
		return this.localizationService.language;
	}

	public defaultLanguage(document:ITextContentObject<any>):SupportedLanguage {
		let hasChoiceLanguage:boolean = document.documentTextContent[this.localizationService.language] != null;
		if (!hasChoiceLanguage) {
			if(this.localizationService.language != SupportedLanguage.en && document.documentTextContent[SupportedLanguage.en]) {
				//English is the preferred default
				return SupportedLanguage.en;
			}
			//If English isn't available find something else
			let decentDefault:SupportedLanguage = SupportedLanguages.list.find(lang => {
				if(lang == this.localizationService.language || lang == SupportedLanguage.en) {
					//if its the one that is already missing or english which is also missing (may be the same thing) .. then its not the one we want
					//Note: in a two language environment these two checks might both evaluate to english.
					return false;
				}
				return document.documentTextContent[lang] != null; //Its a good language if its not null.
			});
			if(decentDefault == null) {
				return SupportedLanguage.en; //This isn't good but there are no good languages..so this is the best we got
			}
			return decentDefault;

		}
		return this.localizationService.language;
	}

}
