import {IsNotEmpty, MaxLength, MinLength} from 'class-validator';
import {RegisterClazz} from "../RegisterType";
import {ITextContent} from "../ITextContent";
import {Exclude} from "class-transformer";
import {SupportedLanguage} from "../SupportedLanguages";

@RegisterClazz(CollectionTextContent)
export class CollectionTextContent implements ITextContent {
	@Exclude()
	public $render:{[key:string]:SupportedLanguage};

	@MinLength(1)
	@MaxLength(35)
	@IsNotEmpty()
	public title:string = "";

	@MinLength(1)
	@MaxLength(250)
	@IsNotEmpty()
	public summary:string = "";

	constructor() {
		this.$render = {};
	}

}
