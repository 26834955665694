import {ValidateNested} from 'class-validator';
import {RegisterClazz} from "./RegisterType";
import {Exclude} from "class-transformer";
import {SupportedLanguage} from "./SupportedLanguages";

@RegisterClazz(DocumentTextContent)
export class DocumentTextContent<T> {
	@ValidateNested()
	en?:T;

	@ValidateNested()
	haw?:T;

	@Exclude()
	private Clazz:(new()=>T)

	constructor(Clazz?:(new()=>T), startingLanguage?:SupportedLanguage) {
		if(Clazz) { //Needs a default constructor for class-transformer
			this.Clazz = Clazz;
		}
		if(startingLanguage) {
			this.generate(startingLanguage);
		}
	}

	public generate(key:string):T {
		console.log(`generating content`);
		this[key] = new this.Clazz();
		return this[key];
	}

}
