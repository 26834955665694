import {Converter} from "../../ConversionService";
import {EventContext, EventContextRef} from "../../../areas/admin/email/email-event-mapper/email-domains";

export class EventContextToEventContextRefConverter implements Converter {

	public from:typeof EventContext;
	public to:typeof EventContextRef;

	constructor() {
		this.from = EventContext;
		this.to = EventContextRef;
	}

	public convert(source:EventContext):EventContextRef {
		//Create the target object
		let eventContextRef:EventContextRef = new EventContextRef();
			eventContextRef.guid = source.guid;

		return eventContextRef;
	}
}
