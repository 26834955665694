import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {AuthService} from "app/services/AuthService";
import {ReviewService} from "./ReviewService";
import {ResourceSubmission, ResourceSubmissionStatus} from "../domain/resources/ResourceSubmission";
import {Subject, Subscription} from "rxjs";
import {LocalizationService} from "./LocalizationService";
import {OrganizationService} from "./OrganizationService";
import {WaihonaUserService} from "./WaihonaUserService";
import {ConversionService} from "./ConversionService";
import {OrganizationRef} from "../domain/organization/OrganizationRef";
import {ResourceSubmissionService} from "./ResourceSubmissionService";
import {DocumentTextContentService} from "./common/DocumentTextContentService";
import {FrameworkService} from "./FrameworkService";
import {ResourceVersion} from "../domain/resources/ResourceVersion";
import {EditResourceDataProvider} from "../domain/resources/EditResourceDataProvider";
import {PathService} from "./PathService";
import {UrlService} from "./UrlService";
import {ResourceSubmissionTextContent} from "../domain/resources/ResourceSubmissionTextContent";
import {DocumentTextContent} from "../domain/DocumentTextContent";
import {SelectableFrameworkRef} from "../domain/frameworks/SelectableFrameworkRef";
import {Framework} from "../domain/frameworks/Framework";
import {ActivityStreamService} from "./ActivityStreamService";
import {AinaRepository} from "./repository/AinaRepository";
import {PublishedResourceRef} from "../domain/resources/PublishedResourceRef";
import {SupportedLanguage} from "../domain/SupportedLanguages";
import {PublishedResourceRepository} from "./repository/PublishedResourceRepository";
import {WaihonaUser} from "../domain/user/WaihonaUser";
import {Collection} from "../domain/collections/Collection";
import {CollectionTextContent} from "../domain/collections/CollectionTextContent";
import {CollectionService} from "./CollectionService";
import {CollectionRepository} from "./repository/CollectionRepository";
import {CollectionRef} from "../domain/collections/CollectionRef";
import {collectExternalReferences} from "@angular/compiler";
import {SubscriptionCleaner} from "../util/SubscriptionCleaner";
import {sort} from "../helper/SortTools";

@Injectable()
/** Represents the user's role in the lesson submission */
export class EditResourceSubmissionHelperService extends SubscriptionCleaner implements OnDestroy {

	public dataProvider$:Subject<EditResourceDataProvider> = new Subject<EditResourceDataProvider>();
	public _dataProvider:EditResourceDataProvider;
	private orgSub:Subscription;

	constructor(private organizationService:OrganizationService,
	            private waihonaUserService:WaihonaUserService,
	            private conversionService:ConversionService,
	            private resourceSubmissionService:ResourceSubmissionService,
	            private documentTextContentService:DocumentTextContentService,
	            private reviewService:ReviewService,
	            private frameworkService:FrameworkService,
	            private activityStreamService:ActivityStreamService,
	            private collectionService:CollectionService,
	            private collectionRepository:CollectionRepository,
	            private ainaRepository:AinaRepository,
	            private publishedResourceRepository:PublishedResourceRepository,
	            private localizationService:LocalizationService,
	            private pathService:PathService,
	            private urlService:UrlService,
	            private authService:AuthService) {
		super();
		this.trackSubscription(this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
			if (this._dataProvider?.resourceRefs) {
				this.sortAndFilterResourceRefs(newLang);
			}
			}));
	}

	/** initializes the data provider object for the component to use */
	public initialize(guid?:string):Observable<EditResourceDataProvider> {
		console.info(`Initializing a resource: ${guid ? guid : "new resource"}`);
		this._dataProvider = new EditResourceDataProvider();

		//step 1: create or get the resource
		if (guid == null) {
			//the user is creating a new resource.  Create a resourceSubmission object!
			this._dataProvider.resourceSubmission = new ResourceSubmission();
			this._dataProvider.resourceSubmission.submitter = this.authService.currentUserRef;

			console.info(`we have a new resource for user: ${this._dataProvider.resourceSubmission.submitter.guid}!`);

			//set the initial organization
			let userDefaultOrganization = this.waihonaUserService.getUserDefaultOrganization(this.authService.currentUser);
			if (userDefaultOrganization) {
				this._dataProvider.resourceSubmission.organization = this.conversionService.convert(userDefaultOrganization, OrganizationRef);
				this.getConfig();
			} else {
				console.error(`the user has no default organization!`);
				// todo: throw error in a user-friendly way
			}

			//create text content based on the currently-selected language
			this._dataProvider.resourceSubmission.documentTextContent = new DocumentTextContent<ResourceSubmissionTextContent>(ResourceSubmissionTextContent, this.localizationService.language);
			console.info(`we have document text content in ${this.localizationService.language}`);
		} else {
			//the user is editing an existing resource.  Get the existing resourceSubmission object!
			let s1:Subscription = this.resourceSubmissionService.getResourceSubmission$(guid).subscribe(resource => {
				s1.unsubscribe();
				this._dataProvider.resourceSubmission = resource;
				console.info(`Existing resource ${this._dataProvider.resourceSubmission.guid} is loaded`);

				//get the org configuration
				this.getConfig();

				//set the folder path
				this._dataProvider.assetsFolderPath = this.pathService.storage.resourceFolder.assetsFolder.draft(this._dataProvider.resourceSubmission.guid);
				console.info(`The folder path is ready!`);

				//set the imageSource Url
				this._dataProvider.imageSource = this.urlService.resourceImage.draft(this._dataProvider.resourceSubmission.guid, this._dataProvider.resourceSubmission.version.toString());
				console.info(`ImageSource is ready!`);

				//order related resources and subscribe to language switch
				this.trackSubscription(this.localizationService.language$.subscribe((newLang:SupportedLanguage) => {
					if(this._dataProvider.resourceSubmission.relatedResources?.length > 0) {
						this._dataProvider.resourceSubmission.relatedResources = sort(this._dataProvider.resourceSubmission.relatedResources, "title", {nupepafy: true, localizedField: true, ref: true, lang: newLang}).ascending();
					}
				}));

				// review if one should exist
				if (this._dataProvider.resourceSubmission.status == ResourceSubmissionStatus.in_review_revision) {
					let s3:Subscription = this.reviewService.getReview$(this._dataProvider.resourceSubmission.guid).subscribe(review => {
						s3.unsubscribe();
						this._dataProvider.review = review;
						console.info(`This resource is back from review and the review object is ready!`);
						this.broadcastIfComplete();
					});
				}

				// fan contributions and collection data if previously published
				if (this._dataProvider.resourceSubmission.version.hasBeenPublishedPreviously) {
					let s5:Subscription = this.resourceSubmissionService.getFanContributions$(this._dataProvider.resourceSubmission.guid).subscribe(items => {
						s5.unsubscribe();
						this._dataProvider.fanContributions = items;
						console.info(`Fan contributions are ready! There are ${this._dataProvider.fanContributions.length} items.`);
						this.broadcastIfComplete();
					});

					//get all selected collections that the user can edit which contains this resource. This array could be empty.
					this.collectionService.getAllCollectionsContainingAResourceAndUserCanEdit$(this._dataProvider.resourceSubmission.guid, this.authService.currentUser).subscribe(items => {
						this._dataProvider.selectedCollections = items;
						console.info(`Selected collections are ready! Found ${this._dataProvider.selectedCollections.length} collection(s).`);
					});

					//get all selectable collections to populate ng-select for user to choose from, and remove any previously-selected items. This array could be empty.
					this.collectionService.getAllCollectionsUserCanEdit$(this.authService.currentUser).subscribe((items => {
						this._dataProvider.selectableCollections = items;
						this._dataProvider.filteredCollections = this._dataProvider.selectableCollections.filter(collection => (this._dataProvider.selectedCollections.find(collection2 => collection2.guid == collection.guid) == null));
					}));

				}

				// activity stream
				this.activityStreamService.watchActivityStream$(guid).subscribe((activityStream) => {
					this._dataProvider.activityStream = activityStream;
					this.broadcastIfComplete();
				});

			});
		}

		//step2: get all the other stuff that the component will need

		// list of user orgs to select from
		this._dataProvider.listOfSelectableOrgRefs = this.authService.currentUser.organizations.filter(org => org.currentlyAt).map(org => this.conversionService.convert(org, OrganizationRef));
		console.info(`The listOfSelectableOrgRefs is ready!  This user has ${this._dataProvider.listOfSelectableOrgRefs.length} orgs to select from.`);

		// aina
		let s2:Subscription = this.ainaRepository.doList$().subscribe(aina => {
			s2.unsubscribe();
			this._dataProvider.ainaList = aina;
			this._dataProvider.ainaRefList = this.ainaRepository.convertToArrayOfRefs(aina);
			console.info(`The Aina list is ready!`);
			this.broadcastIfComplete();
		});

		//frameworks
		let s3:Subscription = this.frameworkService.list.frameworks.selectable.full$().subscribe(frameworks => {
			s3.unsubscribe();
			console.info(`EditResourceSubmissionHelperService::getAllFrameworks::subscribe  - Frameworks have loaded`);
			this._dataProvider.frameworksList = frameworks;
			for(let framework of frameworks) {
				//Selectable frameworks are the user's copy of the framework that contains their preferences for it.
				this._dataProvider.selectableFrameworks.push(this.asSelectableFrameworkRef(framework));

			}
			this.broadcastIfComplete();
		});

		//get all resource refs for user to populate ng-select for user to choose from
		this.publishedResourceRepository.listAsRef$(false).subscribe((resourceRefs:PublishedResourceRef[]) => {
			console.log("Getting resource refs");
			// filter so that resourceRefs does not contain the current resource being edited
			this._dataProvider.resourceRefs = resourceRefs.filter(ref => ref.guid != this._dataProvider?.resourceSubmission?.guid);

			this.sortAndFilterResourceRefs(this.localizationService.language);

			console.info(`We have resources for the user to select related items!  There are ${this._dataProvider.selectableResourceRefs.length} items in this list.`);

			this.broadcastIfComplete();
		});

		//return the observable to the component
		return this.dataProvider$;
	}

	/** Updates the observable only if all conditions is true */
	public broadcastIfComplete():void {

		let isComplete:boolean;

		let hasFrameworksLoaded:boolean = this._dataProvider.frameworksList != null;

		let hasResourceRefsLoaded:boolean = this._dataProvider.resourceRefs.length > 0;

		let resourceSubmission:ResourceSubmission = this._dataProvider.resourceSubmission;
		if (resourceSubmission) {
			let version:ResourceVersion = resourceSubmission?.version;
			let isBackFromReview:boolean = resourceSubmission?.status == ResourceSubmissionStatus.in_review_revision;
			let passesReview:boolean = !isBackFromReview || (isBackFromReview && this._dataProvider.review != null);
			let passesFanContribution:boolean = !version.hasBeenPublishedPreviously || (version.hasBeenPublishedPreviously && this._dataProvider.fanContributions != null);

			isComplete =
				(this._dataProvider.resourceSubmission != null && //We have a resource submission
					this._dataProvider.orgConfig != null && //we have an org config
					this._dataProvider.ainaList != null && // Aina list is populated
					passesReview && // We have a review object, if there is one
					passesFanContribution && // We have fan contributions if there are any
					hasFrameworksLoaded && //We have frameworks
					hasResourceRefsLoaded //we have resource refs for related links (may want to load this later instead...)
				);
		}

		if(!this._dataProvider.ready && hasFrameworksLoaded && resourceSubmission) {
			//Add the full framework to the reference object
			for(let selectedFramework of resourceSubmission.frameworks) {
				let fullFramework:Framework = this._dataProvider.frameworksList.find(framework => {
					return framework.guid == selectedFramework.ref.guid;
				})
				selectedFramework.actual = fullFramework;
			}
		}

		console.log("isComplete? " + isComplete);
		if(isComplete) {
			console.warn(`Red leader, this is gold leader. All systems are "go" and this data provider object is ready to launch.`);
			this._dataProvider.ready = true;
			this.dataProvider$.next(this._dataProvider);
		}
	}


	public asSelectableFrameworkRef(framework:Framework):SelectableFrameworkRef {

		//Wasn't already in the data model; return the existing one
		let foundAlreadySelectedFrameworkRef:SelectableFrameworkRef = this._dataProvider.resourceSubmission.frameworks.find(item => {
			return item.ref.guid == framework.guid;
		});

		if (foundAlreadySelectedFrameworkRef != null) {
			return foundAlreadySelectedFrameworkRef;
		}

		//Wasnt already in the data model; return the existing one
		let selectableFrameworkRef:SelectableFrameworkRef = this.conversionService.convert(framework, SelectableFrameworkRef);
		return selectableFrameworkRef;
	}
	/** Gets the config object of an organization, either based on the data provider org or an optional parameter guid */
	public getConfig(orgGuid?:string):void {
		if (this.orgSub) {
			this.orgSub.unsubscribe();
		}
		let guid = orgGuid ? orgGuid : this._dataProvider.resourceSubmission.organization.guid;
		this.orgSub = this.trackSubscription(
			this.organizationService.watchOrganization$(guid).filter(org => org != null).subscribe(org => {
			this._dataProvider.orgConfig = org.configuration;
			console.info(`Org config is ready! DirectPublish for ${org.guid} is ${org.configuration.autoPublishResources}`);
			this.broadcastIfComplete();
			})
		);
	}

	public sortAndFilterResourceRefs(lang:SupportedLanguage) {
		//sort the array based on the localized title
		this._dataProvider.resourceRefs = sort(this._dataProvider.resourceRefs, "title", {nupepafy:true, localizedField: true, lang: lang, ref: true}).ascending();

		// filter so that selectableResourceRefs does not contain resources that have already been selected
		this._dataProvider.selectableResourceRefs = this._dataProvider.resourceRefs.filter(ref => (this._dataProvider?.resourceSubmission?.relatedResources?.find(ref2 => ref2.guid == ref.guid) == null));
	}

	public ngOnDestroy():void {
		super.ngOnDestroy();
	}
}
