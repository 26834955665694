import {RegisterClazz, RegisterType} from "../RegisterType";
import {WaihonaUserRef} from "../user/WaihonaUserRef";
import {OrganizationRef} from "../organization/OrganizationRef";
import {Type} from "class-transformer";
import {RefType} from "../RefType";

@RegisterClazz(CollectionOwner)
export class CollectionOwner {

	@Type(() => WaihonaUserRef)
	@RegisterType({clazz: WaihonaUserRef})
	@RefType({Clazz: WaihonaUserRef, isProperty: true})
	public userRef?:WaihonaUserRef = null;

	@Type(() => OrganizationRef)
	@RegisterType({clazz: OrganizationRef})
	@RefType({Clazz: OrganizationRef, isProperty: true})
	public orgRef?:OrganizationRef = null;

	constructor(userOrOrgRef?:WaihonaUserRef|OrganizationRef) {
		if(userOrOrgRef instanceof WaihonaUserRef) {
			this.userRef = userOrOrgRef;
		} else if (userOrOrgRef instanceof OrganizationRef) {
			this.orgRef = userOrOrgRef;
		}
	}
	public isUser():boolean {
		return this.userRef != null;
	}
	public isOrg():boolean {
		return this.orgRef != null;
	}
	public get guid():string {
		return (this.userRef) ? this.userRef.guid :
			this.orgRef ? this.orgRef.guid : "";
	}
	public get name():string {
		return (this.userRef) ? this.userRef.fullName :
			this.orgRef ? this.orgRef.title : "";
	}

	public get ref():WaihonaUserRef|OrganizationRef {
		return (this.orgRef != null) ? this.orgRef : this.userRef;

	}

}
